import { useCallback, useEffect } from 'react';
import { Button, Radio } from 'antd';
import BaseModal from 'components/modules/BaseModal';
import { useFeatures } from 'constants/CustomHooks/useFeatures';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;

const INIT_FORM_VALUE = Object.freeze({
    isResetClass: false,
    isResetName: false,
});

type FormValue = Writeable<
    typeof INIT_FORM_VALUE,
    keyof typeof INIT_FORM_VALUE
>;

export type MultipleResetFormValue = FormValue;

interface Props {
    loading: boolean,
    visible: boolean,
    onOk: (values: FormValue) => void,
    onCancel: () => void,
}

export const MultipleResetModal = (props: Props) => {
    const { loading, onOk, onCancel, visible } = props;
    const [form] = Form.useForm<FormValue>();
    const { hideUserName } = useFeatures();

    const resetAllState = useCallback(() => {
        // form が Form エレメントと接続しているかを確認する
        if(!form.getFieldInstance("isResetClass")) return;

        form.resetFields();
        form.setFieldsValue(INIT_FORM_VALUE);
    }, [form]);

    useEffect(() => {
        if (!visible) return;

        resetAllState();
    }, [resetAllState, visible]);

    return (
        <BaseModal
            title={<span>クラス/名前の再入力</span>}
            visible={visible}
            closable={false}
            okText='保存'
            cancelText='キャンセル'
            maskClosable={false}
            footer={[
                <Button key='back' size='large' loading={loading} onClick={onCancel}>キャンセル</Button>,
                <Button key='save' size='large' loading={loading} type='primary' onClick={form.submit}>保存</Button>,
            ]}
        >
            <Form
                {...layout}
                form={form}
                onFinish={onOk}
            >
                <Form.Item label='次回サインイン時にクラスを追加する画面を表示する'
                    name={'isResetClass'}
                    rules={[{ required: true, message: 'クラスを追加する画面を表示するか選択してください' }]}
                >
                    <Radio.Group>
                        <Radio value={true}>する</Radio>
                        <Radio value={false}>しない</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label='次回サインイン時に名前を再設定する画面を表示する'
                    name={'isResetName'}
                    rules={[{ required: true, message: '名前を再設定する画面を表示するか選択してください' }]}
                >
                    <Radio.Group disabled={hideUserName}>
                        <Radio value={true}>する</Radio>
                        <Radio value={false}>しない</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </BaseModal>
    );
};

const layout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 24 },
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 24 },
    }
};
import {getMoment} from '../../../../constants/GlobalConfig';
const moment = getMoment();

/***
 * アンケート管理画面の検索フォームの入力を、サーバで受け取れる形式に変換する
 * @param values
 * @return {*&{
 *      perform_start_at: (number|undefined),
 *      publish_end_at: (number|undefined),
 *      publish_start_at: (number|undefined),
 *      publish_result_start_at: (number|undefined),
 *      perform_end_at: (number|undefined),
 *      publish_result_end_at: (number|undefined)
 * }}
 */
export const convertQuestionnaireTabSearchToSubmittable = (values) => {
    return {
        ...values,
        publish_start_at: values?.publish_start_at ? moment(values.publish_start_at).unix() : undefined,
        publish_end_at: values?.publish_end_at ? moment(values.publish_end_at).unix() : undefined,
        perform_start_at: values?.perform_start_at ? moment(values.perform_start_at).unix() : undefined,
        perform_end_at: values?.perform_end_at ? moment(values.perform_end_at).unix() : undefined,
        publish_result_start_at: values?.publish_result_start_at ? moment(values.publish_result_start_at).unix() : undefined,
        publish_result_end_at: values?.publish_result_end_at ? moment(values.publish_result_end_at).unix() : undefined
    };
}

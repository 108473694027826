import { useEffect, useState, VFC } from 'react';
import { Button, Col, Popconfirm, Row, Tooltip } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import AddModal from './AddModal';
import { getMoment } from 'constants/GlobalConfig';
import { BaseTable } from 'components/modules/BaseTable';
import { ColumnsType } from 'antd/lib/table';

const moment = getMoment();

type Props = {
    currentGroupList: SchoolClass[],
    onSave: (classList: SchoolClass[]) => void,
}

const GroupTab: VFC<Props> = ({
    currentGroupList,
    onSave,
}) => {
    const [selectedList, setSelectedList] = useState(currentGroupList);
    const [isEditOpen, setIsEditOpen] = useState(false);

    useEffect(() => {
        setSelectedList(currentGroupList);
    }, [currentGroupList]);

    const columns: ColumnsType<SchoolClass> = [
        {
            title: 'クラス',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '学年',
            dataIndex: 'grade',
            key: 'grade',
            render: (grade) => {
                return grade.name;
            }
        },
        {
            title: '年度',
            dataIndex: 'term',
            key: 'term',
            render: (term) => (
                <>
                    <span>{term.name}</span>
                    <br />
                    <span>{'(' + moment.unix(term.start_at).format('YYYY/MM/DD HH:mm') + 'から'}</span>
                    <br />
                    <span>{moment.unix(term.end_at).format('YYYY/MM/DD HH:mm') + 'まで)'}</span>
                </>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            fixed: 'right',
            ellipsis: true,
            render: (_, record) => (
                <Row justify='space-around' align='middle'>
                    <Col>
                        <Tooltip title='除外'>
                            <Popconfirm
                                okText='除外'
                                cancelText='キャンセル'
                                onConfirm={() => {
                                    updateSelectList(
                                        selectedList
                                            .slice()
                                            .filter(e => e.uuid !== record.uuid)
                                    );
                                }}
                                title={
                                    <>
                                        <span>対象からクラスを除外します</span>
                                        <br />
                                    </>
                                }
                            >
                                <Button
                                    className='mr-2'
                                    shape='circle'
                                    size='small'
                                    danger
                                    icon={<CloseOutlined />}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>
            ),
        },
    ];

    function updateSelectList(newValue: SchoolClass[]) {
        setSelectedList(newValue);
        onSave(newValue);
    }

    function mergeSelectList(newValue: SchoolClass[]) {
        const tmp = [...newValue, ...selectedList]
            .reduce<Record<string, SchoolClass>>((obj, it) => {
                obj[it.uuid] = it;
                return obj;
            }, {});
        return Object.values(tmp);
    }

    function handleAddClass(values: SchoolClass[]) {
        const newValues = mergeSelectList(values);
        updateSelectList(newValues);
        setIsEditOpen(false);
    }

    function handleEditCancel() {
        setIsEditOpen(false)
    }

    return (
        <div className='group-list container'>
            <div className='flex-right-container gutter-bottom'>
                <Button
                    className='mb-4'
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={() => {
                        setIsEditOpen(true)
                    }}
                >
                    追加
                </Button>
            </div>
            <BaseTable
                emptyDescription='設定されたクラスはまだありません'
                columns={columns}
                dataSource={selectedList}
            />
            <AddModal
                visible={isEditOpen}
                onOk={handleAddClass}
                onCancel={handleEditCancel}
            />
        </div>
    );
};

export default GroupTab;

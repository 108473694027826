import { useEffect } from 'react';
import * as React from 'react';
import { Button, Radio, Input } from 'antd';
import BaseModal from 'components/modules/BaseModal';
import BaseForm from 'components/modules/BaseForm';
import { formRules } from 'constants/GlobalConfig';
const Form = BaseForm.ModalForm;

const layout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 24 },
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 24 },
    },
};

type Props = {
    onCancel: React.MouseEventHandler<HTMLElement>
    onOk: ((values: any) => void)
    visible: boolean
}

export const PasswordModal: React.VFC<Props> = ({
    onCancel,
    onOk,
    visible,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (!visible) return;

        form.setFieldsValue({
            password: '',
            is_forced_change_password: undefined,
        });
    }, [form, visible]);

    return (
        <BaseModal
            title={<span>パスワードリセット</span>}
            visible={visible}
            closable={false}
            okText='保存'
            cancelText='キャンセル'
            maskClosable={false}
            footer={[
                <Button key='back' size='large' onClick={onCancel}>
                    キャンセル
                </Button>,
                <Button key='create' type='primary' size='large' onClick={form.submit}>
                    保存
                </Button>,
            ]}
        >
            <div className='modal'>
                <div>
                    <p>ユーザーのパスワードをリセットします</p>
                    <p>初期パスワードおよびパスワードリセットの要否を選択してください</p>
                    <div style={{ padding: '10px 0px' }} />
                    <Form
                        {...layout}
                        form={form}
                        onFinish={onOk}
                        onFinishFailed={errorInfo => console.log('Failed:', errorInfo)}
                    >
                        <Form.Item
                            label='初期パスワード'
                            name='password'
                            rules={[
                                { required: true, message: '初期パスワードを入力してください' },
                                formRules.passwordLengthValidator(),
                                formRules.passwordCharsetValidator(),
                            ]}
                        >
                            <Input placeholder='初期パスワードを入力' />
                        </Form.Item>
                        <Form.Item
                            label='次回サインイン時パスワード変更要求'
                            name='is_forced_change_password'
                            rules={[{ required: true, message: '次回サインイン時パスワード変更要求を選択してください' }]}
                        >
                            <Radio.Group>
                                <Radio value={true}>要求する</Radio>
                                <Radio value={false}>要求しない</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </BaseModal>
    );
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
    Tooltip,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import isTouchDevice from 'is-touch-device';

import BaseForm from 'components/modules/BaseForm';
import { getDefaultLayout, getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import {
    useUsageData,
    TARGET,
    TERM_TYPE,
    createInitDisplayValues,
    createInitFormValues,
} from '../../Common/UsageDataProvider';
import { createActionLogTotalQuery, createActionLogDetailQuery } from '../../Common/utils';
import DetailTargetSelect from './DetailTargetSelect';


const Form = BaseForm;

const moment = getMoment();
const { Option } = Select;
const layout = getDefaultLayout();

type Props = {
    loading: boolean;
    onSearch: () => void;
};

const Search: React.VFC<Props> = ({
    loading,
    onSearch,
}) => {
    const [form] = Form.useForm();
    const [source, setSource] = useUsageData();
    // form 関連の処理でコンポーネントを更新したい場合があるので,
    // 無理矢理 state を変更して更新している.
    // 可能であれば, もっと適切な方法で更新したい.
    const [, updater] = useState(false);
    const dispatch = useDispatch();

    const forceUpdate = () => updater(e => !e);

    const user = useAppSelector(state => state.user);

    useEffect(() => {
        form.setFieldsValue({ term: source.term });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source.term]);

    const onReset = () => {
        form.resetFields();
        // form リセット時に DetailTargetSelect が更新されず,
        // 追加された Select が残ることがあるので,
        // state を変更することで強制的に rerender をかける.
        forceUpdate();
    };

    // Form.Item の dependencies だと, DatePicker が更新されず,
    // 選択方法が変更されないため, state を変更して, 強制的に rerender をかける.
    const onChangeTermType = () => {
        const term = getBeginningOfTerm(form.getFieldsValue().termType);
        form.setFieldsValue({ term: term });
        forceUpdate();
    };

    const onChangeTarget = (value: string) => {
        form.setFieldsValue({ targetDetails: [] });
        switch (value) {
            case TARGET.USE_APP:
                if (source.organizationList.length > 0) break;
                if (!source.isControllableUser) break;
                dispatch(Actions.http.connection.organization.search({ page_size: 5000 }, undefined, ''));
                break;

            case TARGET.EDUMALL:
                // EduMall アプリは件数が多いため,
                // 検索した結果を表示させるので, ここでは表示させない.
                break;

            default:
                break;
        }

        // target 変更時に DetailTargetSelect が更新されないので,
        // state を変更することで強制的に rerender をかける.
        forceUpdate();

        if (value !== TARGET.USE_APP) return;
        if (source.isControllableUser) return;

        dispatch(Actions.http.connection.apps.search({
            organization_uuid: user.payload.organization.uuid,
            page_size: 50,
        }));
    };

    const onChangeDisplayDataWithoutHistory = (e: RadioChangeEvent) => {
        form.setFieldsValue({ responseAll: e.target.value });
    };

    const search = () => {
        onSearch();
        const formValues = form.getFieldsValue();
        setSource({
            ...source,
            ...createInitDisplayValues(),
            ...formValues,
            pagination: {
                current: 1,
                pageSize: source.pagination.pageSize,
            },
        });

        const totalQueries = createActionLogTotalQuery({
            formValues,
            hierarchy: source.hierarchy,
            source,
        });
        dispatch(Actions.http.connection.usage.total(totalQueries));

        if ((source.isControllableUser && source.hierarchy?.length === 4) ||
            (!source.isControllableUser && source.hierarchy?.length === 3)) {
            const detailQueries = createActionLogDetailQuery({
                formValues,
                hierarchy: source.hierarchy,
                page: source.pagination.current,
                pageSize: source.pagination.pageSize,
                source,
            });
            dispatch(Actions.http.connection.usage.detail(detailQueries));
        }
    };

    const disabledDate = (current: moment.Moment) => {
        return current <= moment('2020/01/01').subtract(1, 'days').endOf('day') || moment().endOf('day') <= current;
    };

    return (
        <Card className='search'>
            <Form
                {...layout}
                form={form}
                initialValues={createInitFormValues()}
                name='control-hooks-usage-search'
                onFinish={search}
            >
                <Form.Item label='利用履歴表示期間'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name='termType'>
                                <Select
                                    disabled={loading}
                                    loading={loading}
                                    onChange={onChangeTermType}
                                    placeholder='表示期間を選択してください'
                                >
                                    {termTypesList.map(term => {
                                        return <Option key={term.value} value={term.value}>{term.text}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                dependencies={['termType']}
                                name='term'
                            >
                                <DatePicker
                                    allowClear={false}
                                    disabled={loading}
                                    disabledDate={disabledDate}
                                    format={formatFuncCreator(form.getFieldsValue().termType)}
                                    inputReadOnly={isTouchDevice()}
                                    locale={jaJP}
                                    name='term'
                                    picker={form.getFieldsValue().termType}
                                    placeholder='日付を選択してください'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label={<div style={{ marginRight: '0.25rem' }}>対象</div>}>
                    <Row align='middle' gutter={8}>
                        <Col span={12}>
                            <Form.Item name='target'>
                                <Select
                                    disabled={loading}
                                    loading={loading}
                                    onChange={onChangeTarget}
                                    placeholder='対象を選択してください'
                                >
                                    {Object.entries(source.featureOptions).map(value => (
                                        <Option key={value[0]} value={value[0]}>{value[1]}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col style={{ marginBottom: '8px' }}>
                            {form.getFieldsValue().target === TARGET.EDUMALL &&
                                <Tooltip title={EduMallTooltipMessage}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            }
                            {form.getFieldsValue().target === TARGET.USE_TAO_LTI &&
                                <Tooltip overlayStyle={{ maxWidth: '300px' }} title={ExamTooltipMessage}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            }
                        </Col>
                    </Row>
                </Form.Item>

                <DetailTargetSelect
                    form={form}
                    source={source}
                    target={form.getFieldsValue().target}
                    user={user}
                />

                <Form.Item label='履歴のないデータ' name='responseAll'>
                    <Radio.Group
                        disabled={loading}
                        onChange={onChangeDisplayDataWithoutHistory}
                    >
                        <Radio value={1}>表示</Radio>
                        <Radio value={0}>非表示</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className='search-buttons_container'>
                    <Button
                        disabled={loading}
                        htmlType='button'
                        loading={loading}
                        onClick={onReset}
                    >
                        絞り込み条件をクリア
                    </Button>
                    <Button
                        disabled={loading}
                        htmlType='submit'
                        icon={<SearchOutlined />}
                        loading={loading}
                        type='primary'
                    >
                        絞り込み
                    </Button>
                </div>
            </Form>
        </Card>
    );
};

export default Search;


const EduMallTooltipMessage = (
    <span>
        【EduMallの履歴について】<br />
        L-Gateと共通のアカウントを用いたシングルサインオンでEduMallを利用されているお客様が対象です。<br />履歴データは利用した2日～数日後に表示されます。<br />
        ※ EduMallについて
        <a
            href='https://www.edumall.jp/'
            rel='noreferrer'
            style={{ color: 'white', marginLeft: '1rem' }}
            target='_blank'
        >
            https://www.edumall.jp/
        </a>
    </span>
);

const ExamTooltipMessage = (
    <span>
        【MEXCBTテストの受検の履歴について】<br />
        文部科学省CBTシステム（MEXCBT）を利用されているお客様が対象です。
    </span>
);

const termTypesList = [
    {
        text: '年度',
        value: TERM_TYPE.YEAR,
    },
    {
        text: '月',
        value: TERM_TYPE.MONTH,
    },
    {
        text: '週',
        value: TERM_TYPE.WEEK,
    },
    {
        text: '日',
        value: TERM_TYPE.DATE,
    },
];

const formatFuncCreator = (termType: typeof TERM_TYPE[keyof typeof TERM_TYPE]) => (value: moment.Moment) => {
    switch (termType) {
        case TERM_TYPE.YEAR:
            return value.format('YYYY年度');
        case TERM_TYPE.MONTH:
            return value.format('YYYY年MM月');
        case TERM_TYPE.WEEK: {
            const start = moment(value).startOf(TERM_TYPE.WEEK).format('YYYY/MM/DD');
            const end = moment(value).endOf(TERM_TYPE.WEEK).format('YYYY/MM/DD');
            return `${start} ~ ${end}`;
        }
        case TERM_TYPE.DATE:
            return value.format('YYYY/MM/DD');
        case undefined:
            return '';
        default:
            throw new Error(`予期されない期間の種類が ${termType} が渡されました.`);
    }
};

/**
 * 起点となる日時を元に, 年度, 週, 日が始まる日時を取得する
 * @param {'year' | 'week' | 'date'} termType 期間の種類
 * @param {moment.Moment} date 起点となる日時(デフォルトは今日)
 * @returns 指定された期間の開始日時
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBeginningOfTerm = (termType: any, date = undefined) => {
    const originDate = date ?? moment();

    switch (termType) {
        case TERM_TYPE.YEAR: {
            const year = originDate.subtract({ months: 3 }).year();
            // moment の月は 0 から始まる
            return moment([year, 3, 1]);
        }
        case TERM_TYPE.MONTH: {
            return originDate.startOf('month');
        }
        case TERM_TYPE.WEEK: {
            return originDate.startOf('week');
        }
        case TERM_TYPE.DATE: {
            return originDate.startOf('day');
        }
        default:
            throw new Error(`予期されない期間の種類が ${termType} が渡されました.`);
    }
};
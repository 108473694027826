import { Dispatch, SetStateAction, useEffect, useState, VFC } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppSelector } from 'flex/utils';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 24,
    },
};

export type OrganizationSearch = {
    has_school_code?: number,
    name?: string,
    page?: number,
    page_size: number,
}

type Props = {
    isLoading: boolean,
    search: OrganizationSearch
    onSearch: Dispatch<SetStateAction<OrganizationSearch>>
}

const OrganizationsAddSearch: VFC<Props> = ({
    isLoading,
    search,
    onSearch
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);

    useEffect(() => {
        setLoading(organizationSearchConnection.meta.fetch)
    }, [organizationSearchConnection])

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(search);
    }, [form, search]);

    function onFinish(values: { name: string }) {
        onSearch(search => ({ ...search, ...values, page: 1 }));
    }

    function onReset() {
        form.resetFields();
    }

    return (
        <div className='card general-search notification-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-notification-search' onFinish={onFinish}>
                <Form.Item name='name' label='学校名'>
                    <Input
                        disabled={isLoading}
                        placeholder='学校名を入力してください' />
                </Form.Item>
                <div className='submit-container'>
                    <Button
                        className='mr-4'
                        disabled={isLoading}
                        htmlType='button'
                        loading={loading || isLoading}
                        onClick={onReset}
                    >
                        リセット
                    </Button>
                    <Button
                        disabled={isLoading}
                        htmlType='submit'
                        icon={<SearchOutlined />}
                        loading={loading || isLoading}
                        type='primary'
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default OrganizationsAddSearch;

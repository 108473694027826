import React, { useCallback, useEffect } from 'react';

import { Button, Col, Row, Select } from 'antd';

import BaseModal from 'components/modules/BaseModal';
import { usePagination } from 'components/presentational/pages/Assessment/hooks/usePagination';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { DistributionGraphState } from '../../hooks/useDistributionGraph';
import { ExamWithInfoState } from '../../hooks/useGraphExam';
import { ReportDetailState } from '../../hooks/useReportDetail';
import { ResultItemState } from '../../hooks/useResultItem';
import { SaveCommentState } from '../../hooks/useSaveComment';
import { SearchExamCategoryWithResultState } from '../../hooks/useSearchWithResultExamCategory';
import { UserItemResultState } from '../../hooks/useUserItemResult';
import { AnswerStatusTable } from './AnswerStatusTable';
import { ClassFilter } from './ClassFilter';
import { ExamsGraph } from './ExamsGraph';
import { Header } from './Header';
import { InfoTable } from './InfoTable';
import { ScoreDistributionGraph } from './ScoreDistributionGraph';
import { ALL_CLASS_VALUE, ALL_SCHOOL_VALUE, FormValues, SearchForm } from './SearchForm';
import { TeachersComment } from './TeachersComment';
import { TotalTable } from './TotalTable';
import { UserDetailTable } from './UserDetailTable';


type ExamWithResult = SearchExamCategoryWithResultState['items'][number]['exams'][number];

type User = NonNullable<ResultItemState['result']>['users'][number];

type ModalSearchParam = {
    type: 'question' | 'user' | '',
    uuid: string,
    target1Param: { organizationId: string, classId: string } | { classId: string },
    target2Param?: { organizationId: string, classId: string } | { classId: string },
    page?: number,
    pageSize?: number
};

type Props = {
    commentResponse: SaveCommentState
    currentTabKey: TabKeys | ''
    distributeGraph: DistributionGraphState
    distributeGraph2?: DistributionGraphState
    exam: ExamWithResult | undefined
    graph: ExamWithInfoState
    graph2?: ExamWithInfoState
    isLoadingComment: boolean
    isOpen: boolean
    onChangeGraphType: (
        type: 'question' | 'user',
        uuid: string,
        targetParam: { organizationId: string, classId: string } | { classId: string },
        page: number,
        pageSize?: number
    ) => void
    onClickSaveComment: (
        examUuid: string,
        userUuid: string,
        historyUuid: string,
        data: { comment: string | undefined },
        tab: TabKeys | ''
    ) => void
    onClickUserName: (examUuid: string, userUuid: string, historyUuid: string, tab: TabKeys | '', schoolClassUuid?: string) => void
    onClose: () => void
    onSearch: (obj: ModalSearchParam) => void
    reportDetail: ReportDetailState
    reportDetail2?: ReportDetailState
    resultItem: ResultItemState
    userResultItemState: UserItemResultState
}


export const ViewAllTestResultModal: React.VFC<Props> = ({
    commentResponse,
    currentTabKey,
    distributeGraph,
    distributeGraph2,
    exam,
    graph,
    graph2,
    isLoadingComment,
    isOpen,
    onChangeGraphType,
    onClickSaveComment,
    onClickUserName,
    onClose,
    onSearch,
    reportDetail,
    reportDetail2,
    resultItem,
    userResultItemState,
}) => {
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const [user, setUser] = React.useState<User>();
    const [listViewType, setListViewType] = React.useState<'question' | 'user'>('question');
    const [searchValue, setSearchValue] = React.useState<FormValues>({
        indicatorType: isControllableUser ? 'school' : 'class',
        target1: isControllableUser ? undefined : ALL_CLASS_VALUE,
        viewType: 'one',
    });
    const [pagination, setPagination] = usePagination({
        current: 1,
        onChange: (page: number, pageSize: number) => {
            setPagination(pagination => ({ ...pagination, current: page, pageSize }));
            handleChangePagination(page, pageSize);
        },
        pageSize: 10,
    });

    const ref = React.useRef<HTMLDivElement>(null);

    const handleChangePagination = useCallback((page: number, pageSize: number) => {
        // ページネーションがあるのは 対象表示 のときの ユーザーごとの解答状況 のときのみ
        if ('target2' in searchValue || 'target2_school' in searchValue) return;

        const params = 'target1' in searchValue ?
            { classId: searchValue.target1 ?? '' } :
            'target1_school' in searchValue ?
                { classId: searchValue.target1_class ?? '', organizationId: searchValue.target1_school ?? '' } :
                { classId: '' };

        if (exam === undefined) return;

        onSearch({
            page,
            pageSize,
            target1Param: params,
            type: listViewType,
            uuid: exam.uuid,
        });
        setPagination(pagination => ({ ...pagination, current: page, pageSize }));
    }, [exam, listViewType, onSearch, searchValue, setPagination]);

    useEffect(() => {
        if (!isOpen) return;
        setPagination(pagination => ({
            ...pagination,
            current: 1,
            pageSize: 10,
        }));
        if (isControllableUser) {
            setSearchValue({
                indicatorType: 'school',
                target1_class: undefined,
                target1_school: ALL_SCHOOL_VALUE,
                viewType: 'one',
            });
        } else {
            setSearchValue({
                indicatorType: 'class',
                target1: ALL_CLASS_VALUE,
                viewType: 'one',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            onChange: (page: number, pageSize: number) => {
                setPagination(pagination => ({ ...pagination, current: page, pageSize }));
                handleChangePagination(page, pageSize);
            },
            total: resultItem?.result?.itemCount ?? 0,
        }));
    }, [handleChangePagination, resultItem?.result?.itemCount, setPagination]);


    if (exam === undefined) return null;

    const toTop = () => {
        ref.current?.parentElement?.parentElement?.scrollTo(0, 0);
    };

    const handleFinish = (value: FormValues) => {
        setSearchValue(value);

        const params = 'target1' in value ?
            { classId: value.target1 ?? '' } :
            'target1_school' in value ?
                { classId: value.target1_class ?? '', organizationId: value.target1_school ?? '' } :
                { classId: '' };

        const params2 = 'target2' in value ?
            { classId: value.target2 ?? '' } :
            'target2_school' in value ?
                { classId: value.target2_class ?? '', organizationId: value.target2_school ?? '' } :
                { classId: '' };

        if (value.viewType === 'two') {
            setListViewType('question');
            onSearch({
                target1Param: params,
                target2Param: params2,
                type: listViewType,
                uuid: exam.uuid,
            });
            setPagination(pagination => ({ ...pagination, current: 1 }));
        } else {
            onSearch({
                target1Param: params,
                type: listViewType,
                uuid: exam.uuid,
            });
            setPagination(pagination => ({ ...pagination, current: 1 }));
        }
    };

    const handleChangeListViewType = (value: 'question' | 'user') => {
        setListViewType(value);

        if (value === 'user') {
            setSearchValue(search => {

                if (search.viewType === 'one') return search;

                if ('target1' in search) {
                    return {
                        indicatorType: search.indicatorType1,
                        target1: search.target1,
                        viewType: 'one',
                    };
                }
                if ('target1_school' in search) {
                    return {
                        indicatorType: search.indicatorType1,
                        target1_class: search.target1_class,
                        target1_school: search.target1_school,
                        viewType: 'one',
                    };
                }
                return search;
            });
        }

        const params = 'target1' in searchValue ?
            { classId: searchValue.target1 ?? '' } :
            'target1_school' in searchValue ?
                { classId: searchValue.target1_class ?? '', organizationId: searchValue.target1_school ?? '' } :
                { classId: '' };

        onChangeGraphType(
            value,
            exam.uuid,
            params,
            1
        );
    };

    const handleClose = () => {
        onClose();
        setSearchValue({
            indicatorType: isControllableUser ? 'school' : 'class',
            target1: isControllableUser ? undefined : ALL_CLASS_VALUE,
            viewType: 'one',
        });
        setListViewType('question');
        setUser(undefined);
    };

    const handleClickSaveComment = (comment: string | undefined) => {
        onClickSaveComment(
            exam.uuid,
            user?.uuid ?? '',
            user?.resultHistories[0]?.uuid ?? '',
            { comment },
            currentTabKey
        );
    };

    const handleClickUserName = (user: User, historyUuid: string, schoolClassUuid?: string) => {
        const classId = 'target1' in searchValue ? searchValue.target1 : searchValue.target1_class;
        const classUuid = classId === ALL_CLASS_VALUE ? undefined : classId;

        onClickUserName(exam.uuid, user.uuid, historyUuid, currentTabKey, schoolClassUuid ?? classUuid);
        setUser(user);
        toTop();
    };

    const handleClickTestName = () => {
        setUser(undefined);

        const params = 'target1' in searchValue ?
            { classId: searchValue.target1 ?? '' } :
            'target1_school' in searchValue ?
                { classId: searchValue.target1_class ?? '', organizationId: searchValue.target1_school ?? '' } :
                { classId: '' };
        onSearch({
            page: 1,
            pageSize: pagination.pageSize,
            target1Param: params,
            type: listViewType,
            uuid: exam.uuid,
        });
        if (listViewType === 'user') {
            setPagination(pagination => ({ ...pagination, current: 1 }));
        }
        toTop();
    };

    const handleFilter = (uuid: string) => {
        onSearch({
            page: 1,
            pageSize: pagination.pageSize,
            target1Param: { classId: uuid },
            type: '',
            uuid: exam.uuid,
        });

        if (user === undefined) return;
        handleClickUserName(user, user.resultHistories[0]?.uuid ?? '', uuid);
    };

    const classUuid = 'target1' in searchValue ? searchValue.target1 : searchValue.target1_class;

    return (
        <BaseModal
            className='common-modal non-padding-modal full-size-modal view-all-test-result-modal'
            footer={
                <Button onClick={handleClose} type='primary'>
                    閉じる
                </Button>
            }
            onCancel={handleClose}
            title='テスト全体の結果を見る'
            visible={isOpen}
        >
            <Header
                onClickTestName={handleClickTestName}
                testName={exam?.title}
                userName={user?.displayName}
            />

            <div
                ref={ref}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // 100vh - モーダルのバー - ヘッダー - フッター
                    height: graph.result.length === 0 ?
                        // グラフの分?が少しはみ出るので、x軸のラベル分と+α
                        'calc(100vh - 55px - 64px - 53px - 36px - 10px)' :
                        'calc(100vh - 55px - 64px - 53px )',
                    padding: '1rem',
                }}
            >

                <div style={{ marginBottom: '1rem' }}>
                    {user === undefined && (
                        <SearchForm
                            currentTabKey={currentTabKey}
                            examUuid={exam.uuid}
                            isControllableUser={isControllableUser}
                            onFinish={handleFinish}
                            value={searchValue}
                        />
                    )}
                    {user !== undefined && (
                        <ClassFilter
                            currentTabKey={currentTabKey}
                            onChange={handleFilter}
                            uuid={exam.uuid}
                            value={classUuid}
                        />
                    )}
                </div>

                <div style={{ marginBottom: '1rem' }}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <ScoreDistributionGraph
                                distributeGraph={distributeGraph}
                                distributeGraph2={searchValue.viewType === 'two' ? distributeGraph2 : undefined}
                                height={260}
                            />
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <InfoTable reportDetail={reportDetail} />
                                </Col>
                                <Col span={12}>
                                    {user === undefined && (
                                        <TotalTable
                                            reportDetail={reportDetail}
                                            reportDetail2={reportDetail2}
                                        />
                                    )}
                                    {user !== undefined && (
                                        <TeachersComment
                                            comment={commentResponse.result ?? userResultItemState.result?.comment}
                                            isLoading={isLoadingComment || userResultItemState.isLoading}
                                            onClickSaveComment={handleClickSaveComment}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                {user === undefined && (
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                        <div style={{ margin: 'auto 1rem auto 0' }}>
                            表示形式
                        </div>
                        <div style={{ alignSelf: 'center', flexGrow: 1 }}>
                            <Select
                                defaultValue='question'
                                disabled={isControllableUser}
                                onChange={handleChangeListViewType}
                                style={{ width: '100%' }}
                                value={listViewType}
                            >
                                <Select.Option value='question'>設問ごとの正答率</Select.Option>
                                <Select.Option value='user'>ユーザーごとの解答状況</Select.Option>
                            </Select>
                        </div>
                    </div>
                )}

                <div style={{ flexGrow: 1, marginBottom: '0' }}>
                    {user === undefined && (
                        listViewType === 'question' ?
                            (
                                <ExamsGraph
                                    graph={graph}
                                    graph2={searchValue.viewType === 'two' ? graph2 : undefined}
                                    height={400}
                                    isAllClass={
                                        !('target2' in searchValue) && (
                                            ('target1' in searchValue && searchValue.target1 === ALL_CLASS_VALUE) ||
                                            ('target1_class' in searchValue && searchValue.target1_class === ALL_CLASS_VALUE)
                                        )
                                    }
                                />
                            ) :
                            (
                                <AnswerStatusTable
                                    onClickUserName={handleClickUserName}
                                    pagination={pagination}
                                    resultItemState={resultItem}
                                />
                            )
                    )}
                    {user !== undefined && (
                        <UserDetailTable
                            userResultItemState={userResultItemState}
                        />
                    )}
                </div>

            </div>
        </BaseModal>
    );
};
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { convertAdminQuestionnaire, convertQuestionnaire } from '../convertQuestionnaire';
import { getMoment } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';
const moment = getMoment();

export type ResultSubItemsResult = {
    isLoading: boolean
    questionnaire: EverydayNote.Questionnaire | undefined
    tableSource: {
        answer: string
        answeredAt: string
        children: {
            answer: string
            answeredAt: string
            name: string
            uuid: string
        }[]
        name: string
        uuid: string
    }[]
    numberOfAllItems: number
    name: string
}

const INIT_STATE: ResultSubItemsResult = {
    isLoading: false,
    name: '',
    numberOfAllItems: 0,
    questionnaire: undefined,
    tableSource: [],
};

export const useResultSubItems = () => {
    const [question, setQuestion] = useState<EverydayNote.Question>();
    const [result, setResult] = useState<ResultSubItemsResult>({ ...INIT_STATE });
    // resultSubItems は scope が 'school-class' までしか選択できないため、
    // user の回答結果の詳細を得るためには scope を 'school-class' としてデータを取得し、
    // そのデータを user の uuid フィルタリングする必要がある
    const [userUuid, setUserUuid] = useState<string>();

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const controlEverydayNoteResultSubItemsConnection = useAppSelector(state => state.controlEverydayNoteResultSubItemsConnection);
    const adminEverydayNoteControlResultSubItemsConnection = useAppSelector(state => state.adminEverydayNoteControlResultSubItemsConnection);
    const adminEverydayNoteResultSubItemsConnection = useAppSelector(state => state.adminEverydayNoteResultSubItemsConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => {
        setQuestion(undefined);
        setResult({ ...INIT_STATE });
        setUserUuid(undefined);
    }, []);

    /** 通信を行う */
    const request = useCallback(
        (
            questionnaireUuid: string,
            question: EverydayNote.Question,
            data: Http.Connection.Request.Parameter.EverydayNote.ResultSubItems,
            userUuid: string | undefined,
            page: number,
            tabName?: 'board' | 'school'
        ) => {
            const resultSubItemsAction = isControllableUser ?
                Actions.http.connection.everydayNote.control.resultSubItems :
                tabName === 'board' ?
                    Actions.http.connection.everydayNote.admin.controlResultSubItems :
                    Actions.http.connection.everydayNote.admin.resultSubItems;

            dispatch(resultSubItemsAction(questionnaireUuid, question.uuid, data, page));
            setQuestion(question);
            setResult(state => ({ ...state, isLoading: true }));
            setUserUuid(userUuid);
        },
        [dispatch]// eslint-disable-line
    );

    type Connections = EverydayNoteResultSubItemsConnection | EverydayNoteControlResultSubItemsConnection | AdminEverydayNoteResultSubItemsConnection;
    const connectionEffect = <T extends Connections>(
        connection: T,
        converter: (result: T['payload']['result']['note']) => EverydayNote.Questionnaire,
    ) => () => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;
                if (!question) break;

                const tableSource = payloadResult.items
                    .filter(userInfo => !userUuid || userInfo.uuid === userUuid)
                    .map(userInfo => {

                        const useName = userInfo.name === '' ?
                            userInfo.login_id :
                            userInfo.name;

                        return {
                            name: useName,
                            uuid: userInfo.uuid,
                            answer: `${userInfo.items.length} 件`,
                            answeredAt: '',
                            key: userInfo.uuid,
                            children: userInfo.items.map(answer => {
                                // resultSubItems はすべての設問の回答を返してくるので、
                                // 表示すべき設問の回答をフィルタリングして取得する必要がある
                                const targetAnswerContent = answer.items
                                    .filter(answer => answer.everyday_note_item_uuid === question.uuid)[0]?.content;

                                return {
                                    name: useName,
                                    uuid: answer.uuid,
                                    answer: targetAnswerContent ?? '',
                                    answeredAt: moment.unix(answer.created_at).format('YYYY/MM/DD HH:mm'),
                                    key: answer.created_at.toString(),
                                };
                            }),
                        };
                    });

                const name = userUuid === undefined ?
                    payloadResult.hierarchy[0].slice(-1)[0].name :
                    tableSource[0].name;

                setResult({
                    isLoading: false,
                    name,
                    numberOfAllItems: payloadResult.item_count,
                    questionnaire: converter(payloadResult.note),
                    tableSource,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
    };

    useEffect(
        connectionEffect(controlEverydayNoteResultSubItemsConnection, convertQuestionnaire),
        [controlEverydayNoteResultSubItemsConnection]
    );

    useEffect(
        connectionEffect(adminEverydayNoteControlResultSubItemsConnection, convertQuestionnaire),
        [adminEverydayNoteControlResultSubItemsConnection]
    );

    useEffect(
        connectionEffect(adminEverydayNoteResultSubItemsConnection, convertAdminQuestionnaire),
        [adminEverydayNoteResultSubItemsConnection]
    );

    return [result, request, reset] as const;
};

import { createAction } from 'redux-actions';
import {
    API_CLASS,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/CLASSES';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page) => {
        const pageNum = page === undefined ? '' : page;
        return ({ method: 'get', data, api: `${API_CLASS}/${pageNum}` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const searchForListMap = createAction(
    createActionType('SEARCH_FOR_LIST_MAP' as const),
    (data, page, mapKey, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return ({ method: 'get', data, api: `${API_CLASS}/${pageNum}` })
    },
    (data, page, mapKey, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey: mapKey, data, viewName })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_CLASS + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_CLASS + '/update/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_CLASS + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deleteAction as delete };

const importAction = createAction(
    createActionType('IMPORT' as const),
    (query, contentType, data) => ({
        method: 'import',
        api: `${API_CLASS}/import-csv?${query}`,
        data,
        contentType: contentType
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { importAction as import };

const exportAction = createAction(
    createActionType('EXPORT' as const),
    (data) => {
        return ({ method: 'get', data, api: `${API_CLASS}/download-csv` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { exportAction as export };

export const view = createAction(
    createActionType('VIEW' as const),
    (uuid) => ({
        method: 'get',
        api: `${API_CLASS}/view/${uuid}`,
    }),
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export module copyClassSetting {
    export const view = createAction(
        createActionType('COPY_CLASS_SETTING/VIEW' as const),
        () => ({ method: 'get', api: `/copy-school-class-setting/view` }),
        () => ({ fetch: true, status: StatusEnum.REQUEST })
    );

    export const update = createAction(
        createActionType('COPY_CLASS_SETTING/UPDATE' as const),
        (data) => ({ method: 'post', api: `/copy-school-class-setting/update`, data }),
        () => ({ fetch: true, status: StatusEnum.REQUEST })
    );
}
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    Alert,
    Button,
    Input,
    Radio,
    Select,
    Tooltip,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import { allowedExtensions, FileUploader } from 'components/modules/FileUploader';
import Spin from 'components/modules/Spin';
import {
    DatePickerRangesIncludeInfinite,
    formRules,
    getDisabledDate,
    getMoment,
    validations,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import { modalBaseLayoutWithoutLabel, modalBaseLayout } from '../../../AppTemplate/EditAppTemplate';
import { EditUrlAndParam } from '../../../AppTemplate/Forms/EditUrlAndParam';
import { createInitialValues } from '../../../AppTemplate/Forms/editUrlAndParamUtils';
import AutoCompleteUrl from '../../AutoCompleteUrl';
import { source as AutoCompleteUrlSource } from '../../AutoCompleteUrl/source';
import { applicationUtils } from '../ApplicationUtils';
import { useSource } from '../SourceProvider';
import { createSubmit } from './appModalUtils';

import './AppModal.scss';

const Form = BaseForm.ModalForm;
const { Option } = Select;
const moment = getMoment();

/** 連携手法 */
const CooperationMethods = Object.freeze({
    APP: 'APP',
    URL: 'URL',
});

const DefaultValue = applicationUtils.initialValueOfApplication;

const AppModal = ({
    app,
    categoryUuid,
    isUseDeepLinking,
    order,
    onCancel,
    onOk,
    visible
}) => {
    const [cooperationMethod, setCooperationMethod] = useState(CooperationMethods.URL);
    const [selectAppTemplate, setSelectAppTemplate] = useState();
    const [isExistConnectedApp, setIsExistConnectedApp] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPresetFlags, setSelectedPresetFlags] = useState(undefined);
    const isControllableUser = useSelector(state => state.isControllableUser.payload);
    const appsTemplateSearchForAdminConnection = useSelector(state => state.appsTemplateSearchForAdminConnection);
    const appsTemplateViewForAdminConnection = useSelector(state => state.appsTemplateViewForAdminConnection);
    const appsEditConnection = useSelector(state => state.appsEditConnection);
    const appsControlViewConnection = useSelector(state => state.appsControlViewConnection);
    const [form] = Form.useForm();
    const [source] = useSource();
    const dispatch = useDispatch();
    const fileUploaderRef = useRef(null);

    const isCreate = !app;
    const isUpdate = !isCreate;
    const isAutoCompleteUrl = AutoCompleteUrlSource.filter(
        e => e.url === app?.url && e.image.name === app?.files?.[0]?.file_name
    ).length > 0;

    // isUpdate && cooperationMethod === CooperationMethods.APP => isExistConnectedApp
    const isEditable = !(isUpdate && cooperationMethod === CooperationMethods.APP) || isExistConnectedApp;

    const closedModalEffect = () => {
        if (appsEditConnection.meta.status === Actions.statusEnum.SUCCESS) {
            onOk();
            form.resetFields();
            setCooperationMethod(CooperationMethods.URL);
        }

        setIsLoading(appsEditConnection.meta.fetch);
    };

    const openModalEffect = () => {
        if (!visible) return;

        dispatch(Actions.http.connection.apps.template.searchForAdmin(
            {
                is_active: 1,
                page_size: 500,
                use_deep_linking: 0,
            },
            1
        ));

        form.resetFields();
        setIsLoading(true);
        setSelectAppTemplate(undefined);

        if (isCreate) {
            form.setFieldsValue(DefaultValue);
            setCooperationMethod(CooperationMethods.URL);
            setIsExistConnectedApp(false);
            setSelectedPresetFlags(undefined);
            return;
        }

        const value = {
            control_application_template_uuid: app.control_application_template_uuid ?? '',
            description: app.description,
            duration: [moment.unix(app.publish_start_at), moment.unix(app.publish_end_at)],
            files: app.files.filter(e => e),
            isCooperationUrl: !app.control_application_template_uuid,
            openNewWindow: app.open_new_window,
            title: app.title,
            url: app.url ?? '',
        };

        form.setFieldsValue(value);
        setCooperationMethod(value.isCooperationUrl ? CooperationMethods.URL : CooperationMethods.APP);
        // ローディング中は警告を出さないようにするために、この時点では true とする
        setIsExistConnectedApp(true);
        setSelectedPresetFlags(undefined);
    };

    const changeAppTemplateEffect = () => {
        if (!visible) return;

        form.setFieldsValue(
            createInitialValues(selectAppTemplate, isControllableUser)
        );
    };

    const fetchedAppsTemplateSearchForAdminEffect = () => {
        if (!visible) return;

        const { meta, payload } = appsTemplateSearchForAdminConnection;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                const isExistConnectedAppState = !!payload
                    .result
                    .items
                    .map(e => e.uuid)
                    .includes(app?.control_application_template_uuid);

                setIsExistConnectedApp(isExistConnectedAppState);

                if (isExistConnectedAppState && app.control_application_template_uuid) {
                    dispatch(Actions.http.connection.apps.template.viewForAdmin(app.control_application_template_uuid));
                } else {
                    setIsLoading(false);
                }
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
    };

    const fetchedAppsTemplateViewEffect = () => {
        if (!visible || isCreate) return;

        switch (appsTemplateViewForAdminConnection.meta.status) {
            case Actions.statusEnum.SUCCESS:
                setSelectAppTemplate(
                    mergeParamAndUrl(appsTemplateViewForAdminConnection.payload.result, app)
                );

                dispatch(Actions.http.connection.apps.controlView(app.uuid));
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
    };

    const fetchedMaterialApplicationViewEffect = () => {
        if (!visible || isCreate) return;

        setIsLoading(appsControlViewConnection.meta.fetch);
        switch (appsControlViewConnection.meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const url = selectAppTemplate.url;
                if (!url.is_choices) return;

                // 固定のパラメータのテンプレートを選択するような構成になっている場合かつ、編集不可で非表示になっている場合、
                // 初期値が設定されていないと保存が行えないため、defaultとなっている選択肢をセットする

                const selectedCandidateClientId = appsControlViewConnection.payload.result
                    .material_application_url
                    .param_json
                    ?.client_id ??
                    'UNDEFINED_CLIENT_ID';

                const selectedCandidateUUid = url.candidates
                    .find(e => e.param_json?.client_id === selectedCandidateClientId)
                    ?.uuid;

                const defaultCandidateUuid = url.candidates.find(e => e.is_default)?.uuid;

                form.setFieldsValue({ 'ltiUrlCandidateUuid': selectedCandidateUUid ?? defaultCandidateUuid });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
    };

    useEffect(
        closedModalEffect,
        // onOk を含めると, 2度目から開けなくなる
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appsEditConnection, form]
    );

    useEffect(
        openModalEffect,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [visible]
    );

    useEffect(
        changeAppTemplateEffect,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [visible, selectAppTemplate, isControllableUser]
    );

    useEffect(
        fetchedAppsTemplateSearchForAdminEffect,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appsTemplateSearchForAdminConnection]
    );

    useEffect(
        fetchedAppsTemplateViewEffect,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appsTemplateViewForAdminConnection]
    );

    useEffect(
        fetchedMaterialApplicationViewEffect,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appsControlViewConnection]
    );

    const onFinish = (values) => {
        if (!validations.isValidUrl('url', 'URL', 1999, form, values.url)) {
            return false;
        }
        if (!validations.isValidUrl('ltiUrlUrl', 'URL', 1999, form, values.ltiUrlUrl)) {
            return false;
        }

        const postBody = createSubmit(
            values,
            app,
            order,
            categoryUuid,
            values.isCooperationUrl,
            selectAppTemplate
        );

        if (isCreate) {
            dispatch(Actions.http.connection.apps.controlCreate(postBody, categoryUuid));
        } else {
            dispatch(Actions.http.connection.apps.controlUpdate(app.uuid, postBody, categoryUuid));
        }
        setIsLoading(true);
    };

    const handleChangeIsUrl = (e) => {
        setCooperationMethod(e.target.value ? CooperationMethods.URL : CooperationMethods.APP);
    };

    const handleSelectAppTemplate = (appTemplateUuid) => {
        setSelectAppTemplate(source.appTemplatesForAdmin.data.items.find(v => v.uuid === appTemplateUuid));
    };

    return (
        <BaseModal
            className='common-modal apps-modal edit-modal'
            footer={[
                <Button
                    key='back'
                    loading={source.appTemplatesForAdmin.loading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    disabled={!isEditable}
                    key='create'
                    loading={source.appTemplatesForAdmin.loading}
                    onClick={() => { form.submit(); }}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={isCreate ? <span>教材・アプリの新規作成</span> : <span>教材・アプリの編集</span>}
            visible={visible}
        >
            <Spin spinning={isLoading}>

                {!isEditable && (
                    <Alert
                        message='配信アプリが削除もしくは無効化されているため編集できません'
                        showIcon
                        type='error'
                    />
                )}

                <div>
                    <Form {...modalBaseLayout} form={form} name='control-hooks-edit-apps' onFinish={onFinish}>
                        <Form.Item
                            label='タイトル'
                            name='title'
                            rules={[
                                formRules.required({ label: '教材・アプリ名称' }),
                                formRules.range({ label: '教材・アプリ名称', max: 128 }),
                            ]}
                        >
                            <Input
                                disabled={!isEditable || !!selectedPresetFlags?.title}
                                placeholder='教材・アプリ名称を入力してください'
                            />
                        </Form.Item>
                        <Form.Item
                            label='概要'
                            name='description'
                            rules={[formRules.range({ label: '概要', max: 128 })]}
                        >
                            <Input
                                disabled={!isEditable || !!selectedPresetFlags?.description}
                                placeholder='概要を入力してください'
                            />
                        </Form.Item>

                        <Form.Item
                            label='連携方式'
                            name='isCooperationUrl'
                            rules={[{ message: '必須項目です', required: true }]}
                        >
                            <Radio.Group
                                disabled={isUpdate || !isEditable || !!selectedPresetFlags?.isCooperationUrl}
                                onChange={handleChangeIsUrl}
                            >
                                <Radio value={true} >URL</Radio>
                                <Radio value={false} >アプリ配信</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {cooperationMethod === CooperationMethods.APP && (
                            <>
                                <Form.Item
                                    name='control_application_template_uuid'
                                    {...modalBaseLayoutWithoutLabel}
                                    rules={[{ message: '必須項目です', required: true }]}
                                >
                                    <Select
                                        disabled={isUpdate || !isEditable}
                                        onChange={handleSelectAppTemplate}
                                        placeholder='利用する配信アプリを選択してください'
                                    >
                                        {source.appTemplatesForAdmin.data.items.map((appTemplate, index) => (
                                            <Option key={index} value={appTemplate.uuid}>{appTemplate.name}</Option>)
                                        )}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    hidden={selectAppTemplate?.type === 2 && !selectAppTemplate?.url?.is_choices}
                                    wrapperCol={{ lg: { offset: 0, span: 24 }, xl: { offset: 0, span: 24 } }}
                                >
                                    <EditUrlAndParam
                                        appTemplate={selectAppTemplate}
                                        context='App'
                                        disabled={!isEditable}
                                        isControllableUser={isControllableUser}
                                        isCreate={isCreate}
                                        layout={modalBaseLayout}
                                        layoutWithoutLabel={modalBaseLayoutWithoutLabel}
                                    />
                                </Form.Item>
                            </>
                        )}

                        {cooperationMethod === CooperationMethods.URL && (
                            <Form.Item
                                disabled={!isEditable}
                                label='URL'
                                name='url'
                                rules={[
                                    formRules.required({ label: '遷移先URL' }),
                                    formRules.range({ label: '遷移先URL', max: 1999 }),
                                ]}
                            >
                                <AutoCompleteUrl
                                    addFiles={(file) => fileUploaderRef.current?.add?.(file)}
                                    buttonDisable={isUpdate || !isEditable}
                                    disabled={!isEditable || !!selectedPresetFlags?.url || isUseDeepLinking}
                                    form={form}
                                    name='url'
                                    onReset={() => setSelectedPresetFlags({ files: false, url: false })}
                                    onSelect={() => setSelectedPresetFlags({ files: true, url: true })}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            disabled={!isEditable || !!selectedPresetFlags?.openNewWindow}
                            label={
                                <span>新規タブ/ウィンドウ設定
                                    <Tooltip title='クリック時に新規タブで開くか現在のタブで開くかを選択できます'>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            name='openNewWindow'
                        >
                            <Radio.Group {...modalBaseLayout} disabled={!isEditable}>
                                <Radio
                                    style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                                    value={true}
                                >
                                    新規タブ/ウィンドウで開く
                                </Radio>
                                <Radio
                                    style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                                    value={false}
                                >
                                    現在のタブ/ウィンドウで開く
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label='公開期間'
                            name='duration'
                            rules={[formRules.requiredDuration({ label: '公開期間' })]}
                        >
                            <CustomRangePicker
                                disabled={!isEditable || !!selectedPresetFlags?.duration}
                                disabledDate={getDisabledDate}
                                form={form}
                                format='YYYY/MM/DD HH:mm'
                                locale={jaJP}
                                name='duration'
                                ranges={DatePickerRangesIncludeInfinite()}
                                showTime={{ format: 'HH:mm' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='アイコン画像'
                            name='files'
                        >
                            <FileUploader
                                allowedExtension={allowedExtensions.image}
                                disabled={!isEditable || !!selectedPresetFlags?.files || isAutoCompleteUrl}
                                ref={fileUploaderRef}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </BaseModal>
    );
};

export default AppModal;

/**
 * AppTemplate の Param と URL の情報に app の Param と URL の情報をマージします.
 */
const mergeParamAndUrl = (appTemplate, app) => {
    const applicationParamObj = app.material_application_param &&
        Object.fromEntries(app.material_application_param.map(param => {
            return [param.control_application_param_uuid, param];
        }));

    const margedParams = appTemplate.params.map(param => {
        const editedParam = applicationParamObj[param.uuid];
        return {
            ...param,
            uuid: (editedParam ?? param).uuid,
            value: (editedParam ?? param).value,
        };
    });

    const margedUrl = mergeUrl(appTemplate, app);

    return {
        ...appTemplate,
        params: margedParams,
        url: margedUrl,
    };
};

const mergeUrl = (appTemplate, app) => {
    switch (appTemplate.type) {
        case 1: {
            const { key, secret, url, uuid } = app.material_application_url;
            return {
                ...appTemplate.url,
                key,
                secret,
                url,
                uuid,
            };
        }
        case 2: {
            const { param_json, uuid } = app.material_application_url;
            return {
                ...appTemplate.url,
                param_json,
                uuid,
            };
        }
        default:
            break;
    }
};
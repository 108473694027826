import { createAction } from 'redux-actions';
import {
    API_JOB,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/JOB';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page) => {
        const pageNum = page === undefined ? '' : page;
        return ({ method: 'get', data, api: `${API_JOB}/${pageNum}` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const view = createAction(
    createActionType('VIEW' as const),
    (uuid) => ({ method: 'get', api: API_JOB + '/view/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

import { useEffect, useState } from 'react';
import * as React from 'react';
import {Radio, Button, Popconfirm, RadioChangeEvent} from 'antd';
import {CloudUploadOutlined} from '@ant-design/icons';

const RadioGroup = Radio.Group;

const IMPORT_TYPE_CREATE_OR_UPDATE = 'create';
const IMPORT_TYPE_DELETE = 'delete';

type ImportType = typeof IMPORT_TYPE_CREATE_OR_UPDATE | typeof IMPORT_TYPE_DELETE

type Props = {
    className?: string,
    disabled?: boolean,
    handleOk: (importType: ImportType) => void,
    handleCancel?: () => void
}

const SyncServerConfirm: React.VFC<Props> = props => {
    const {className, disabled, handleOk, handleCancel} = props;
    const [importType, setImportTYpe] = useState<ImportType>(IMPORT_TYPE_CREATE_OR_UPDATE);
    const [enableAutoAdjust, setEnableAutoAdjust] = useState(window.innerWidth >= 1000);

    const handleWindowResize = () => {
        setEnableAutoAdjust(window.innerWidth >= 1000);
    }

    const handleChangeEncodeValue = (e: RadioChangeEvent) => {
        setImportTYpe(e.target.value);
    }

    useEffect( () => {
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <Popconfirm
            disabled={disabled}
            placement='bottomRight'
            title={
                <div style={ enableAutoAdjust ? {} : {width: '500px'}}>
                    <p>ユーザ同期を行います。</p>
                    <p>同期種別を選択してください。</p>
                    <div style={{padding: '10px 0px'}}>
                        <RadioGroup defaultValue={importType} onChange={handleChangeEncodeValue}>
                            <Radio value={IMPORT_TYPE_CREATE_OR_UPDATE}>追加・編集同期</Radio>
                            <Radio value={IMPORT_TYPE_DELETE}>削除同期</Radio>
                        </RadioGroup>
                    </div>
                </div>
            }
            onConfirm={() => handleOk(importType)}
            onCancel={handleCancel}
            autoAdjustOverflow={enableAutoAdjust}
            okText='同期'
            cancelText='キャンセル'
        >
            <Button
                disabled={disabled}
                className={className}
                type='primary'
                icon={<CloudUploadOutlined/>}
            >
                サーバへ同期
            </Button>
        </Popconfirm>
    )
}

export default SyncServerConfirm;

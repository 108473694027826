import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    Alert,
    Button,
    Input,
    Radio,
    Select,
    Tooltip,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { RadioChangeEvent } from 'antd/lib/radio';
import { UploadFile } from 'antd/lib/upload/interface';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import {
    allowedExtensions,
    FileUploader,
    FileUploaderHandler,
} from 'components/modules/FileUploader';
import Spin from 'components/modules/Spin';
import {
    DatePickerRangesIncludeInfinite,
    formRules,
    getDisabledDate,
    getMoment,
    validations,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { modalBaseLayoutWithoutLabel, modalBaseLayout } from '../AppTemplate/EditAppTemplate';
import { EditUrlAndParam } from '../AppTemplate/Forms/EditUrlAndParam';
import { createInitialValues } from '../AppTemplate/Forms/editUrlAndParamUtils';
import AutoCompleteUrl from './AutoCompleteUrl';
import { source as AutoCompleteUrlSource } from './AutoCompleteUrl/source';
import { findCandidate } from './utils/findCandidate';

import './EditApp.scss';

const Form = BaseForm.ModalForm;
const { statusEnum } = Actions;
const { Option } = Select;

const moment = getMoment();

type File = UploadFile & { uuid?: string };

type AppTemplate = Http.Connection.Response.AppTemplate.Search['result']['items'][0];
type App = Http.Connection.Response.Apps.View['result'];
type AppCategory = App.AppsCategory & { use_deep_linking: boolean | null };

type Props = {
    applications: App[];
    category: AppCategory;
    onCancel: () => void;
    onOk: () => void;
    record: App | null;
    shouldCreate: boolean;
    useDeepLinking: boolean;
    visible: boolean;
};

type FormValues = {
    description: string;
    duration: [moment.Moment, moment.Moment | undefined];
    files: File[];
    isCooperationUrl: boolean;
    open_new_window: boolean;
    title: string;
    url: string;
    params: FormParams[];
    ltiUrlCandidateUuid: string;
    ltiUrlUrl: string;
    ltiUrlKey: string;
    ltiUrlSecret: string;
    control_application_template_uuid?: string;
    param_json?: {
        client_id: string
        deployment_id: string
        initiate_login_url: string
        is_send_user_info: boolean
        login_hint: string
        public_keyset: string
        redirect_uris: string[]
        tool_url: string
    };
};

type FormParams = {
    ltiParamCandidateUuid: string | undefined
    ltiParamUuid: string
    ltiParamValue: string
}

/**
 * アプリの編集
 * - 全体管理者から配信されたアプリ(is_published_item=true)の場合は、各項目は変更できない
 */
const EditApp: React.VFC<Props> = ({
    applications,
    category,
    onCancel,
    onOk,
    record,
    shouldCreate,
    useDeepLinking,
    visible,
}) => {
    const [form] = Form.useForm<FormValues>();
    const dispatch = useDispatch();
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const appsEditConnection = useAppSelector(state => state.appsEditConnection);
    const appsViewConnection = useAppSelector(state => state.appsViewConnection);
    const appsTemplateSearchConnection = useAppSelector(state => state.appsTemplateSearchConnection);
    const [loading, setLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);
    const [isCooperationUrl, setIsCooperationUrl] = useState(true);
    const [selectAppTemplate, setSelectAppTemplate] = useState<AppTemplate | null>(null);
    const [selectedPresetFlags, setSelectedPresetFlags] = useState<{ files: boolean, url: boolean } | undefined>(undefined);
    const [isExistConnectedApp, setIsExistConnectedApp] = useState(false);
    const [isActiveForApp, setIsActiveForApp] = useState(false);
    const [appTemplates, setTemplates] = useState<AppTemplate[]>([]);
    const disabledForPublishedItem = !!(record && 'is_published_item' in record && record.is_published_item);
    const isFirstRender = useRef(true);
    const fileUploaderRef = useRef<FileUploaderHandler>(null);

    const isCreate = !record;
    const isUpdate = !isCreate;
    const isAutoCompleteUrl = AutoCompleteUrlSource.filter(
        e => e.url === record?.url && 'files' in record && e.image.name === record?.files?.[0]?.file_name
    ).length > 0;

    // isUpdate && !isCooperationUrl => isExistConnectedApp && isActiveForApp
    const isEditable = !(isUpdate && !isCooperationUrl) || (isExistConnectedApp && isActiveForApp);

    useEffect(() => {
        if (!visible) return;

        // 表示内容の初期化
        form.resetFields();
        const values = {
            description: '',
            duration: [
                // eslint-disable-next-line sort-keys
                moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
                undefined,
            ],
            files: [],
            isCooperationUrl: true,
            open_new_window: true,
        };
        setSelectAppTemplate(null);
        setIsCooperationUrl(values.isCooperationUrl);
        setSelectedPresetFlags(undefined);
        form.setFieldsValue(values);

        // 表示の準備
        dispatch(Actions.http.connection.apps.template.search({ page_size: 500, use_deep_linking: 0 }, 1));
        // TODO: template 一覧の通信が発生しているので、detailLoading を true にしたいが、
        // すると、全体がローディングになってしまい、 URL でアプリを作成する場合に待ちが発生してしまう。
        // 可能であれば、template 一覧部分のみのローディングにしたい。
        isUpdate && setDetailLoading(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, visible]);

    useEffect(() => {
        if (isFirstRender.current) return;
        const { meta, payload } = appsTemplateSearchConnection;

        setLoading(meta.fetch);
        if (meta.status !== statusEnum.SUCCESS) return;
        if (!payload.result?.items) return;

        setTemplates(payload.result.items);

        isUpdate && dispatch(Actions.http.connection.apps.view(record.uuid));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsTemplateSearchConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;

        setDetailLoading(appsViewConnection.meta.fetch);

        if (appsViewConnection.meta.status !== Actions.statusEnum.SUCCESS) return;

        const useDeepLinking = category?.use_deep_linking ?? false;

        const newRecord = appsViewConnection.payload.result;

        const { url, ...rest } = newRecord;

        const params = 'params' in newRecord ? newRecord.params : [];

        const ltiAppTemplate = 'lti_app_template' in newRecord ? newRecord.lti_app_template : null;
        const ltiUrl = 'lti_url' in newRecord ? newRecord.lti_url : null;
        const paramJson = ltiUrl?.param_json ?? undefined;
        const lti11Values = {
            ltiUrlKey: ltiUrl?.key ?? '',
            ltiUrlSecret: ltiUrl?.secret ?? '',
            ltiUrlUrl: ltiUrl?.url ?? '',
        };

        const value = {
            ...rest,
            ...lti11Values,
            control_application_template_uuid: ltiAppTemplate?.uuid,
            duration: [moment.unix(newRecord.publish_start_at), moment.unix(newRecord.publish_end_at)],
            files: newRecord.files,
            isCooperationUrl: useDeepLinking ? true : ltiAppTemplate === null,
            ltiUrlCandidateUuid: findCandidate(newRecord)?.uuid,
            param_json: paramJson ?
                {
                    ...paramJson,
                    deployment_id: paramJson?.deployment_id ?? '',
                } :
                undefined,
            params: params.map(param => ({
                ltiParamCandidateUuid: param.candidates?.find(c => c.is_default)?.uuid,
                ltiParamUuid: param.uuid,
                ltiParamValue: param.value,
            })),
            url: url ?? undefined,
        };
        form.setFieldsValue(value);
        setSelectAppTemplate(getAppTemplateWithDefaultValue(newRecord));
        setIsCooperationUrl(value.isCooperationUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsViewConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;

        setLoading(appsEditConnection.meta.fetch);
        if (appsEditConnection.meta.status === statusEnum.SUCCESS) {
            //作成完了
            onOk();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsEditConnection]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, []);

    useEffect(() => {
        // modalを開くタイミングでsetIsExistConnectedAppが行われておらず、
        // 初期値のfalseとなってしまっているため visible をトリガーで行う
        if (!visible) return;

        const ltiAppTemplate = record && ('lti_app_template' in record ? record.lti_app_template : null);

        setIsExistConnectedApp(
            appTemplates
                .map(e => e.uuid)
                ?.includes(ltiAppTemplate?.uuid ?? '')
        );
        setIsActiveForApp(
            !!appTemplates.find(e => e.uuid === ltiAppTemplate?.uuid)?.is_active
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, appTemplates]);

    const onFinish = (values: FormValues) => {
        if (!validations.isValidUrl('url', 'URL', 1999, form, values.url)) {
            return false;
        }
        if (!validations.isValidUrl('ltiUrlUrl', 'URL', 1999, form, values.ltiUrlUrl)) {
            return false;
        }

        const postBody = createPostBody(
            category.uuid,
            record,
            values,
            applications.length,
            isCooperationUrl,
            selectAppTemplate,
            shouldCreate,
        );

        if (shouldCreate) {
            dispatch(Actions.http.connection.apps.create(postBody, category.uuid));
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(Actions.http.connection.apps.update(record!.uuid, postBody, category.uuid));
        }
    };

    const handleChangeIsUrl = (e: RadioChangeEvent) => {
        setIsCooperationUrl(e.target.value);
    };

    const handleSelectAppTemplate = (appTemplateUuid: string) => {
        const newSelectAppTemplate = appTemplates.find(e => e.uuid === appTemplateUuid);
        newSelectAppTemplate && setSelectAppTemplate(newSelectAppTemplate);

        const initialValues = createInitialValues(newSelectAppTemplate, isControllableUser);
        initialValues && form.setFieldsValue(initialValues);
    };

    return (
        <BaseModal
            className='common-modal apps-modal edit-modal'
            footer={[
                <Button key='back' loading={loading} onClick={onCancel} size='large'>キャンセル</Button>,
                <Button
                    disabled={disabledForPublishedItem || !isEditable}
                    key='create'
                    loading={loading}
                    onClick={() => form.submit()}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={shouldCreate ? <span>教材・アプリの新規作成</span> : <span>教材・アプリの編集</span>}
            visible={visible}
        >
            <Spin spinning={detailLoading}>
                {!disabledForPublishedItem && !isEditable && (
                    <Alert
                        message={isExistConnectedApp ? '配信アプリが無効化されているため編集できません' : '削除されている・もしくは配信対象校でないため編集できません'}
                        showIcon
                        style={{ marginBottom: '1rem' }}
                        type='error'
                    />
                )}

                <PublishedItemMessage disabledForPublishedItem={disabledForPublishedItem} />

                <div>
                    <Form {...modalBaseLayout} form={form} name='control-hooks-edit-apps' onFinish={onFinish}>
                        <Form.Item
                            label='タイトル'
                            name='title'
                            rules={[
                                formRules.required({ label: '教材・アプリ名称' }),
                                formRules.range({ label: '教材・アプリ名称', max: 128 }),
                            ]}
                        >
                            <Input
                                disabled={disabledForPublishedItem || !isEditable}
                                placeholder='教材・アプリ名称を入力してください'
                            />
                        </Form.Item>

                        <Form.Item
                            label='概要'
                            name='description'
                            rules={[formRules.range({ label: '概要', max: 128 })]}
                        >
                            <Input
                                disabled={disabledForPublishedItem || !isEditable}
                                placeholder='概要を入力してください'
                            />
                        </Form.Item>

                        <Form.Item
                            label='連携方式'
                            name='isCooperationUrl'
                            rules={[{ message: '必須項目です', required: true }]}
                        >
                            <Radio.Group
                                disabled={disabledForPublishedItem || isUpdate || !isEditable}
                                onChange={handleChangeIsUrl}
                            >
                                <Radio value={true}>URL</Radio>
                                <Radio value={false}>アプリ配信</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {/*連携方式がアプリの場合*/}
                        {!isCooperationUrl && (
                            <>
                                <Form.Item
                                    name='control_application_template_uuid'
                                    {...modalBaseLayoutWithoutLabel}
                                    rules={[{ message: '必須項目です', required: true }]}
                                >
                                    <Select
                                        disabled={disabledForPublishedItem || isUpdate || !isEditable}
                                        onChange={handleSelectAppTemplate}
                                        placeholder='利用する配信アプリを選択してください'
                                        value={selectAppTemplate?.uuid}
                                    >
                                        {appTemplates.filter(elm => elm.is_active).map((appTemplate, index) => (
                                            <Option key={index} value={appTemplate.uuid}>{appTemplate.name}</Option>)
                                        )}
                                    </Select>
                                </Form.Item>

                                <Form.Item wrapperCol={{ lg: { offset: 0, span: 24 }, xl: { offset: 0, span: 24 } }}>
                                    <EditUrlAndParam
                                        appTemplate={selectAppTemplate || record}
                                        context='App'
                                        disabled={disabledForPublishedItem || !isEditable}
                                        isControllableUser={isControllableUser}
                                        isCreate={isCreate}
                                        layout={modalBaseLayout}
                                        layoutWithoutLabel={modalBaseLayoutWithoutLabel}
                                    />
                                </Form.Item>
                            </>
                        )}

                        {/*連携方式がURLの場合*/}
                        {isCooperationUrl && (
                            <Form.Item
                                label='URL'
                                name='url'
                                rules={[
                                    formRules.required({ label: '遷移先URL' }),
                                    formRules.range({ label: '遷移先URL', max: 1999 }),
                                ]}
                            >
                                <AutoCompleteUrl
                                    addFiles={(file) => fileUploaderRef.current?.add?.(file)}
                                    buttonDisable={disabledForPublishedItem || isUpdate || !isEditable}
                                    disabled={disabledForPublishedItem || !isEditable || !!selectedPresetFlags?.url || useDeepLinking}
                                    form={form}
                                    name='url'
                                    onReset={() => setSelectedPresetFlags({ files: false, url: false })}
                                    onSelect={() => setSelectedPresetFlags({ files: true, url: true })}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            label={
                                <span>
                                    新規タブ/ウィンドウ設定
                                    <Tooltip title='クリック時に新規タブで開くか現在のタブで開くかを選択できます'>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            name='open_new_window'
                        >
                            <Radio.Group
                                {...modalBaseLayout}
                                disabled={!isEditable}
                            >
                                <Radio
                                    disabled={disabledForPublishedItem}
                                    style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                                    value={true}
                                >新規タブ/ウィンドウで開く
                                </Radio>
                                <Radio
                                    disabled={disabledForPublishedItem}
                                    style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                                    value={false}
                                >現在のタブ/ウィンドウで開く
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label='公開期間'
                            name='duration'
                            rules={[formRules.requiredDuration({ label: '公開期間' })]}
                        >
                            <CustomRangePicker
                                disabled={disabledForPublishedItem || !isEditable}
                                disabledDate={getDisabledDate}
                                form={form}
                                format='YYYY/MM/DD HH:mm'
                                locale={jaJP}
                                name='duration'
                                ranges={DatePickerRangesIncludeInfinite()}
                                showTime={{ format: 'HH:mm' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label='アイコン画像'
                            name='files'
                        >
                            <FileUploader
                                allowedExtension={allowedExtensions.image}
                                disabled={
                                    disabledForPublishedItem ||
                                    !isEditable ||
                                    !!selectedPresetFlags?.files ||
                                    isAutoCompleteUrl
                                }
                                ref={fileUploaderRef}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <hr />
            </Spin>
        </BaseModal>
    );
};

/***
 * 全体管理者から配信されたアプリでのメッセージ
 */
const PublishedItemMessage: React.VFC<{ disabledForPublishedItem: boolean }> = ({ disabledForPublishedItem }) => {
    if (!disabledForPublishedItem) return null;

    return (
        <div className='mb-8'>
            <Alert message={<span>全体管理者で管理されています。閲覧のみ可能です。</span>} showIcon type='warning' />
        </div>
    );
};

const convertUrlToSubmittable = (selectAppTemplate: AppTemplate, values: FormValues, uuidKeyName: string) => {
    const url = selectAppTemplate.url;

    if (selectAppTemplate.type === 1) {
        const { ltiUrlCandidateUuid, ltiUrlUrl, ltiUrlKey, ltiUrlSecret } = values;
        // TODO: TypeScript 5.2.2 でキャストする必要がなくなる
        const candidate = (url.candidates as (typeof url.candidates)[0][]).find(c => c?.uuid === ltiUrlCandidateUuid);
        return {
            key: candidate ? candidate.key : ltiUrlKey,
            secret: candidate ? candidate.secret : ltiUrlSecret,
            url: candidate ? candidate.url : ltiUrlUrl,
            [uuidKeyName]: url.uuid,
        };
    } else if (selectAppTemplate.type === 2) {
        if (url.is_choices) {
            const { ltiUrlCandidateUuid } = values;
            const candidate = ltiUrlCandidateUuid ?
                // TODO: TypeScript 5.2.2 でキャストする必要がなくなる
                (url.candidates as (typeof url.candidates)[0][]).find(c => c?.uuid === ltiUrlCandidateUuid) :
                (url.candidates as (typeof url.candidates)[0][]).find((c: NonNullable<typeof url.candidates>[0]) => c.is_default);
            return {
                param_json: candidate?.param_json,
                [uuidKeyName]: url.uuid,
            };
        } else {
            return {
                param_json: values.param_json,
                [uuidKeyName]: url.uuid,
            };
        }
    }
};

const convertParamToSubmittable = (editableParams: AppTemplate['params'], paramValue: FormParams, uuidKeyName: string) => {
    const { ltiParamUuid, ltiParamCandidateUuid, ltiParamValue } = paramValue;
    const param = editableParams?.find(v => v.uuid === ltiParamUuid);
    const candidate = ltiParamCandidateUuid &&
        param?.candidates?.find((c: NonNullable<typeof param['candidates']>[0]) => c?.uuid === ltiParamCandidateUuid);

    return {
        key: param?.key ?? '',
        [uuidKeyName]: ltiParamUuid,
        value: candidate ? candidate.value : ltiParamValue,
    };
};

/***
 * 学校管理者で保存済みの値と、アプリテンプレートを統合する
 */
const getAppTemplateWithDefaultValue = (newRecord: App): AppTemplate | null => {
    if (!('lti_app_template' in newRecord) || !newRecord.lti_app_template) return null;

    return Object.assign(
        {},
        newRecord.lti_app_template,
        {
            lti_app_template: newRecord.lti_app_template,
            // TypeScript のエラーになるので、一旦展開している
            params: [...newRecord.lti_app_template.params].map((tempParam) => {
                const paramWithDefaultValue = newRecord.params?.find(param => param.key === tempParam.key) ?? {};
                return Object.assign({}, tempParam, paramWithDefaultValue);
            }),
            url: Object.assign({}, newRecord.lti_app_template.url, newRecord.lti_url),
            // AppTemplate と App のもつ app_template の型が異なるので、一旦 any で対応
        } as any
    );
};

const createPostBody = (
    categoryUuid: string,
    record: App | null,
    formValues: FormValues,
    newOrder: number,
    isCooperationUrl: boolean,
    selectAppTemplate: AppTemplate | null,
    shouldCreate: boolean,
): Http.Connection.Request.Parameter.App.Create | undefined => {
    const postBody = {
        category_uuid: categoryUuid,
        description: formValues.description,
        files: formValues.files.map(file => file?.uuid ?? file.uid).filter(e => e),
        open_new_window: formValues.open_new_window,
        order: record && record.order !== null ? record.order : newOrder,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        publish_end_at: formValues.duration[1]!.unix(),
        publish_start_at: formValues.duration[0].unix(),
        title: formValues.title,
    };

    if (isCooperationUrl) {
        // URL連携
        return {
            ...postBody,
            url: formValues.url,
        };
    } else {
        if (selectAppTemplate === null) {
            throw new Error('AppTemplate is null.');
        }
        // アプリ連携
        const urlUuidKeyName = shouldCreate ? 'control_application_url_uuid' : 'uuid';
        const paramUuidKeyName = shouldCreate ? 'control_application_param_uuid' : 'uuid';

        return {
            ...postBody,
            control_application_template_uuid: formValues.control_application_template_uuid ?? '',
            lti_url: convertUrlToSubmittable(selectAppTemplate, formValues, urlUuidKeyName),
            params: formValues.params && Array.isArray(formValues.params) && selectAppTemplate ?
                formValues.params.map(param =>
                    convertParamToSubmittable(selectAppTemplate.params, param, paramUuidKeyName)
                ) :
                undefined,
        };
    }

};

export default EditApp;

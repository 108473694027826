import AttachedFileView from './AttachedFileView';
import classNames from 'classnames';

const QuestionItemCard = props => {
    const {
        children,
        detail,
        error,
        file,
        isRequired,
        title,
        type,
    } = props;

    return (
        <div className={classNames(
            'bg-gray-100 rounded-md p-4',
            error && 'border border-red-400'
        )}>
            <h3 className='text-gray-600 font-bold mb-1 break-all'> {title}</h3>
            <p className='text-sm text-gray-600 mb-2 break-all'>{detail}</p>

            {file && file.name !== undefined && (
                <AttachedFileView className='mb-4' fileName={file.name} fileUuid={file.uuid} />
            )}

            <div className='text-sm mb-4'>
                {isRequired && <span className='text-red-600'> * </span>}
                <span className='text-steel-600'>{type}</span>
            </div>

            <div className='w-full mb-2'>
                {children}
            </div>

            {error &&
                <div className='text-sm mb-4 text-red-600'>
                    {error}
                </div>
            }
        </div>
    );
};

export default QuestionItemCard;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { convertAdminQuestionnaire, convertQuestionnaire } from '../convertQuestionnaire';
import { convertSubmittableSearchConditions } from '../convertSubmittableSearchConditions';

type Result = {
    isLoading: boolean,
    itemCount: number,
    items: EverydayNote.ControlQuestionnaire[] | EverydayNote.AdminQuestionnaire[],
    pageSize: number,
    pageTotal: number,
}

const INIT_STATE = {
    isLoading: false,
    itemCount: 0,
    items: [],
    pageSize: 0,
    pageTotal: 0,
};

export const useSearchQuestionnaire = (tabName?: 'board' | 'school') => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const controlEverydayNoteSearchConnection = useAppSelector(state => state.controlEverydayNoteSearchConnection);
    const adminEverydayNoteControlSearchConnection = useAppSelector(state => state.adminEverydayNoteControlSearchConnection);
    const adminEverydayNoteSearchConnection = useAppSelector(state => state.adminEverydayNoteSearchConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback((condition?: EverydayNote.SearchConditions, tabName?: 'board' | 'school') => {
        const {
            page,
            ...restCondition
        } = convertSubmittableSearchConditions(condition);

        const searchAction = isControllableUser ?
            Actions.http.connection.everydayNote.control.search :
            tabName === 'board' ?
                Actions.http.connection.everydayNote.admin.controlSearch :
                Actions.http.connection.everydayNote.admin.search;

        dispatch(searchAction(restCondition, page));
        setResult(state => ({ ...state, isLoading: true }));
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = controlEverydayNoteSearchConnection;

        if (!isControllableUser) return;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;

                setResult({
                    isLoading: false,
                    itemCount: payloadResult.item_count,
                    items: payloadResult.items.map(convertQuestionnaire),
                    pageSize: payloadResult.page_size,
                    pageTotal: payloadResult.page_total,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlEverydayNoteSearchConnection]);

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = adminEverydayNoteControlSearchConnection;

        if (tabName !== 'board') return;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;

                setResult({
                    isLoading: false,
                    itemCount: payloadResult.item_count,
                    items: payloadResult.items.map(convertQuestionnaire),
                    pageSize: payloadResult.page_size,
                    pageTotal: payloadResult.page_total,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminEverydayNoteControlSearchConnection]);

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = adminEverydayNoteSearchConnection;

        if (tabName !== 'school') return;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;

                setResult({
                    isLoading: false,
                    itemCount: payloadResult.item_count,
                    items: payloadResult.items.map(convertAdminQuestionnaire),
                    pageSize: payloadResult.page_size,
                    pageTotal: payloadResult.page_total,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminEverydayNoteSearchConnection]);

    return [result, request, reset] as const;
};

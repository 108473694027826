import * as GlobalConfig from '../../../../constants/GlobalConfig'

export const appTemplateTabDefaultState = {
    type: 'appTemplateTab',
    // システム管理者が作成したアプリテンプレート
    appTemplateList: [],
    appTemplateListItemCount: 0,
    appTemplateListLoading: false,
    // 全体管理者が継承して作成したアプリテンプレート
    appTemplateForAdminList: [],
    appTemplateForAdminListItemCount: 0,
    appTemplateForAdminListLoading: false,
    pagination: {
        total: 0 ,
        position: ["topRight", "bottomRight"],
        showSizeChanger: true,
        pageSizeOptions: GlobalConfig.PageSizeOptions,
        pageSize: 50,
        current: 1,
    },
    search: {
        authentication: undefined,
        name: undefined,
    },
    modal: {
        isEditOpen: false,
        shouldCreate: false,
        record: null,
    },
}

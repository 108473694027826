import {
    Button,
    Popconfirm,
} from 'antd';
import {
    checkEnvironmentForControllable,
    generateFileDomain,
} from 'constants/GlobalConfig';
import {
    BACKGROUND_JOB_TYPE,
    BACKGROUND_JOB_TYPE_GROUPS as JOB_TYPE_GROUPS,
} from '../BackgroundJobProvider';
import { BackgroundJobStatuses, getJobStatus } from '../BackgroundJobUtils';


const StopTaskButton = ({onStop, loading, disabled}) => {
    return (
        <Popconfirm
            placement='topLeft'
            title={
                <div style={{maxWidth: '300px'}}>
                    タスクの確認画面を閉じてもよろしいですか？画面を閉じてもタスクの処理は実行されますが、タスクの進行状況を確認できなくなります。
                </div>
            }
            onConfirm={onStop}
            okText='はい'
            cancelText='いいえ'
        >
            <Button
                size='small'
                loading={loading}
                disabled={disabled}
            >
                閉じる
            </Button>
        </Popconfirm>
    );
};

/** ユーザー関連のみ通常同期を行なう場合にコントロールするボタンを生成する */
const createSyncUserOnlyFooterButton = ({
    jobInfo,
    onCancel,
    executeUser,
}) => {
    const job = jobInfo.job;
    const loadingAndDisabled = { loading: jobInfo.loading, disabled: jobInfo.loading };
    const closeButtonForDeadJob = jobInfo.showClose ?
        <StopTaskButton onStop={onCancel} loading={jobInfo.loading} /> :
        null;

    switch (job.type) {
        case BACKGROUND_JOB_TYPE.SYNC_GROUP_DRYRUN:
            return null;
        case BACKGROUND_JOB_TYPE.SYNC_GROUP:
            return null;
        case BACKGROUND_JOB_TYPE.SYNC_USER_DRYRUN:
            return !job.done_at
                // sync step:5
                ? closeButtonForDeadJob
                // sync step:6
                : (<>
                    <Button key='back' size='small' onClick={onCancel} {...loadingAndDisabled}>キャンセル</Button>
                    <Button key='create' type='primary' size='small' onClick={executeUser} {...loadingAndDisabled}>同期する</Button>
                </>);
        case BACKGROUND_JOB_TYPE.SYNC_USER:
            return !job.done_at
                // sync step:7
                ? closeButtonForDeadJob
                // sync step:8
                : (
                    <Button key='back' size='small' onClick={onCancel} {...loadingAndDisabled}>閉じる</Button>
                );
        case BACKGROUND_JOB_TYPE.SYNC_MEMBER_DRYRUN:
            return null;
        case BACKGROUND_JOB_TYPE.SYNC_MEMBER:
            return null;
        default:
            return null;
    }
}

/** すべての通常同期を行なう場合にコントロールするボタンを生成する */
const createSyncAllFooterButton = ({
    jobInfo,
    onCancel,
    executeGroup,
    dryRunUser,
    executeUser,
    dryRunGroupMember,
    executeGroupMember
}) => {
    const job = jobInfo.job;
    const loadingAndDisabled = { loading: jobInfo.loading, disabled: jobInfo.loading };
    const closeButtonForDeadJob = jobInfo.showClose ?
        <StopTaskButton onStop={onCancel} {...loadingAndDisabled} /> :
        null;

    switch (job.type) {
        case BACKGROUND_JOB_TYPE.SYNC_GROUP_DRYRUN:
            return !job.done_at
                // sync step:1
                ? closeButtonForDeadJob
                // sync step:2
                : (<>
                    <Button key='back' size='small' onClick={onCancel} {...loadingAndDisabled} >キャンセル</Button>
                    <Button key='create' type='primary' size='small' onClick={executeGroup} {...loadingAndDisabled}>同期する</Button>
                </>);
        case BACKGROUND_JOB_TYPE.SYNC_GROUP:
            return !job.done_at
                // sync step:3
                ? closeButtonForDeadJob
                // sync step:4
                : <Button key='back' size='small' onClick={dryRunUser} {...loadingAndDisabled}>次へ</Button>;
        case BACKGROUND_JOB_TYPE.SYNC_USER_DRYRUN:
            return !job.done_at
                // sync step:5
                ? closeButtonForDeadJob
                // sync step:6
                : (<>
                    <Button key='back' size='small' onClick={onCancel} {...loadingAndDisabled}>キャンセル</Button>
                    <Button key='create' type='primary' size='small' onClick={executeUser} {...loadingAndDisabled}>同期する</Button>
                </>);
        case BACKGROUND_JOB_TYPE.SYNC_USER:
            return !job.done_at
                // sync step:7
                ? closeButtonForDeadJob
                // sync step:8
                : (
                    <Button key='back' size='small' onClick={dryRunGroupMember} {...loadingAndDisabled}>次へ</Button>
                );
        case BACKGROUND_JOB_TYPE.SYNC_MEMBER_DRYRUN:
            return !job.done_at
                // sync step:9
                ? closeButtonForDeadJob
                // sync step:10
                : (<>
                    <Button key='back' size='small' onClick={onCancel} {...loadingAndDisabled}>キャンセル</Button>
                    <Button key='create' type='primary' size='small' onClick={executeGroupMember} {...loadingAndDisabled}>同期する</Button>
                </>);
        case BACKGROUND_JOB_TYPE.SYNC_MEMBER:
            return !job.done_at
                // sync step:11
                ? closeButtonForDeadJob
                // sync step12
                : (
                    <Button key='back' size='small' onClick={onCancel} {...loadingAndDisabled}>閉じる</Button>
                );
        default:
            return null;
    }
}

const createNomalSyncFooterButton = ({
    isSyncGroup,
    jobInfo,
    onCancel,
    executeGroup,
    dryRunUser,
    executeUser,
    dryRunGroupMember,
    executeGroupMember
}) => {
    if(isSyncGroup) {
        return createSyncAllFooterButton({
            jobInfo,
            onCancel,
            executeGroup,
            dryRunUser,
            executeUser,
            dryRunGroupMember,
            executeGroupMember
        });
    } else {
        return createSyncUserOnlyFooterButton({
            jobInfo,
            onCancel,
            executeUser,
        });
    }
}

/** 同期をコントロールするボタン*/
export const createJobFooterButton = ({
    isSyncGroup,
    jobInfo,
    onCancel,
    executeGroup,
    dryRunUser,
    executeUser,
    dryRunGroupMember,
    executeGroupMember,
    executeDeleteUser
}) => {
    const job = jobInfo.job;

    if (jobInfo.isError || (getJobStatus(job) === BackgroundJobStatuses.error)) {
        return [<Button key='back' size='small' onClick={onCancel}>キャンセル</Button>];
    }

    const loadingAndDisabled = { loading: jobInfo.loading, disabled: jobInfo.loading }
    const closeButtonForDeadJob = jobInfo.showClose ?
        <StopTaskButton onStop={onCancel} loading={jobInfo.loading} /> :
        null;

    const nomalSyncButton = createNomalSyncFooterButton({
        isSyncGroup,
        jobInfo,
        onCancel,
        executeGroup,
        dryRunUser,
        executeUser,
        dryRunGroupMember,
        executeGroupMember
    });

    if(nomalSyncButton !== null) {
        return nomalSyncButton;
    }

    switch (job.type) {
        ///////////
        // 削除同期
        ///////////
        case BACKGROUND_JOB_TYPE.SYNC_DELETED_USER_DRYRUN:
            return !job.done_at
                // sync delete step:1
                ? closeButtonForDeadJob
                // sync delete step:2
                : (<>
                    <Button
                        key='close'
                        size='small'
                        onClick={onCancel}
                        {...loadingAndDisabled}
                    >
                        閉じる
                    </Button>
                    <Button
                        key='sync'
                        size='primary'
                        onClick={executeDeleteUser}
                        {...loadingAndDisabled}
                    >
                        同期する
                    </Button>
                </>);
        case BACKGROUND_JOB_TYPE.SYNC_DELETED_USER:
            return !job.done_at ?
                // sync delete step:3
                closeButtonForDeadJob :
                // sync delete step:4
                (

                    <Button
                        key='close'
                        size='small'
                        onClick={onCancel}
                        {...loadingAndDisabled}
                    >
                        閉じる
                    </Button>
                );

        ///////////////////////////////
        // ユーザ・クラス・学校 CSVインポート
        ///////////////////////////////
        // あんまり良い実装ではない気がするけど、 Switch 文で使うために find を使っている
        case JOB_TYPE_GROUPS.CSV_IMPORT_TYPES.find(type => type === job.type):
            return !job.done_at ?
                closeButtonForDeadJob :
                (
                    <Button
                        key='close'
                        size='small'
                        onClick={onCancel}
                        {...loadingAndDisabled}
                    >
                        閉じる
                    </Button>
                );

        ///////////////////////////////
        // 保存系
        ///////////////////////////////
        // あんまり良い実装ではない気がするけど、 Switch 文で使うために find を使っている
        case JOB_TYPE_GROUPS.SAVE_TYPES.find(type => type === job.type):
            return !job.done_at ?
                closeButtonForDeadJob :
                (
                    <Button
                        key='close'
                        size='small'
                        onClick={onCancel}
                        {...loadingAndDisabled}
                    >
                        閉じる
                    </Button>
                );

        /////////////////////////////////
        // CSVエクスポート
        /////////////////////////////////
        // あんまり良い実装ではない気がするけど、 Switch 文で使うために find を使っている
        case JOB_TYPE_GROUPS.CSV_EXPORT_TYPES.find(type => type === job.type):
            return !job.done_at ?
                closeButtonForDeadJob :
                (<>
                    <Button
                        key='close'
                        size='small'
                        onClick={onCancel}
                        {...loadingAndDisabled}
                    >
                        閉じる
                    </Button>
                    <Button
                        key='download'
                        type='primary'
                        size='small'
                        onClick={() => {
                            const urlString = checkEnvironmentForControllable() ?
                                `${generateFileDomain()}/file/view/${job.result.file_uuid}` :
                                `${generateFileDomain()}/admin/user/view-file/${job.result.file_uuid}`;
                            window.open(urlString);
                        }}
                        {...loadingAndDisabled}
                    >
                        ダウンロード
                    </Button>
                </>);
        default:
            return null;
    }
}

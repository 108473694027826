import * as React from 'react';

import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin, Tooltip, Typography } from 'antd';

import { getMoment } from 'constants/GlobalConfig';

const moment = getMoment();

type Props = {
    title: string,
    publishStart: number,
    publishEnd: number,
    fullScore: number,
    isLoading: boolean,
    isUsable: boolean,
    maxAttempts: number,
}

/***
 * テーブルのヘッダが横に表示されるテーブル
 * @param props
 * @constructor
 */
export const TestInformationTable: React.VFC<Props> = (props) => {
    const isIndefinitePeriod = props.publishEnd >= 2147483647;
    const publishStart = moment.unix(props.publishStart).format('YYYY/MM/DD HH:mm');
    const publishEnd = isIndefinitePeriod ? '' : moment.unix(props.publishEnd).format('YYYY/MM/DD HH:mm');
    return (
        <Spin
            indicator={<LoadingOutlined style={{ fontSize: '35px' }} />}
            spinning={props.isLoading}
        >
            <div>
                <Typography.Text style={{ margin: '0.5rem' }}>
                    テストの情報
                </Typography.Text>
                <Tooltip
                    title='MEXCBTで公開されているこのテストの情報です。'
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <table className='test-info-table'>
                <tbody>
                    <tr>
                        <th colSpan={2}>概要</th>
                    </tr>
                    <tr>
                        <th>タイトル</th>
                        <td>
                            {props.title}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <span style={{ marginRight: '0.5em' }}>
                                公開期間
                            </span>
                            <Tooltip
                                title='このテストがMEXCBTで公開されている期間です。'
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </th>
                        <td>
                            {`${publishStart} ~ ${publishEnd}`}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <span style={{ marginRight: '0.5em' }}>
                                受検可能回数
                            </span>
                            <Tooltip
                                title='1人のユーザーがMEXCBTでこのテストを受検できる回数です。'
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </th>
                        <td>
                            {props.maxAttempts ? `${props.maxAttempts} 回` : '制限回数なし'}
                        </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <th colSpan={2}>採点情報</th>
                    </tr>
                    <tr>
                        <th>満点</th>
                        <td>
                            {`${props.fullScore} 点`}
                        </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <th colSpan={2}>解答結果の確認ページ</th>
                    </tr>
                    <tr>
                        <th>
                            <span style={{ marginRight: '0.5em' }}>
                                利用可否
                            </span>
                            <Tooltip
                                title='「利用可能」となっている場合、解答終了後にL-GateからMEXCBTの答案確認ページを起動できます。'
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </th>
                        <td>
                            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                {props.isUsable ?
                                    <>
                                        <div
                                            style={{
                                                border: 'green solid 5px',
                                                borderRadius: '50%',
                                                height: '0px',
                                                margin: '0.5rem',
                                                width: '0px',
                                            }}
                                        />
                                        <span>利用可能</span>
                                    </> :
                                    <>
                                        <div
                                            style={{
                                                border: 'red solid 5px',
                                                borderRadius: '50%',
                                                height: '0px',
                                                margin: '0.5rem',
                                                width: '0px',
                                            }}
                                        />
                                        <span>利用不可</span>
                                    </>
                                }
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Spin>
    );
};

import * as React from 'react';

import { Tag } from 'antd';

type Props = {
    status: Exam.History['grading_progress'] | undefined;
}

/***
 * 採点前 or 採点完了を きれいなタグとして表示する
 * @param props
 * @constructor
 */
export const ScoringStatusTag: React.VFC<Props> = (props) => {
    switch (props.status) {
        case 'PendingManual':
            return <Tag color='#AAAAAA' style={{ borderRadius: '5px', textAlign: 'center', width: '75px' }}>採点待ち</Tag>;
        case 'FullyGraded':
            return <Tag color='#118584' style={{ borderRadius: '5px', textAlign: 'center', width: '75px' }}>採点完了</Tag>;
        default:
            return null;
    }
};

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TeamOutlined } from '@ant-design/icons';
import ReadOutlined from '@ant-design/icons/lib/icons/ReadOutlined';
import {
    Button,
    Checkbox,
    Col,
    Input,
    Radio,
    Row,
    Select,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { Moment } from 'moment';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import Spin from 'components/modules/Spin';
import Targets from 'components/presentational/pages/Targets';
import {
    formRules,
    defaultGradePreset,
    getDefaultLayout,
    getDisabledDate,
    getMoment,
    checkEnvironmentForControllable,
    DatePickerRangesIncludeInfinite,
} from 'constants/GlobalConfig';
import { texts } from 'constants/texts';
import { useAppSelector } from 'flex/utils';


const Form = BaseForm.ModalForm;
const { Option } = Select;

const moment = getMoment();
const layout = getDefaultLayout(false);

const AdminInitFormValue: ExamCategory.FormValueForAdmin = Object.freeze({
    description: '',
    // eslint-disable-next-line sort-keys
    duration: [moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })] as [Moment],
    is_controllable: false,
    is_required: true,
    target: {
        target_roles: [],
        target_school_classes: [],
        target_users: [],
    },
    target_me: [false] as [boolean],
    title: '',
});

const ControlInitFormValue: ExamCategory.FormValueForControl = Object.freeze({
    description: '',
    // eslint-disable-next-line sort-keys
    duration: [moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })] as [Moment],
    is_controllable: true,
    is_designated_term: true,
    is_required: true,
    publish_to_all_organizations: true,
    target: {
        target_organizations: [],
    },
    target_grades: [] as string[],
    target_permissions: [],
    target_term: '' as string,
    title: '',
});

type Target = ExamCategory.EditInput['target'];

type Props = {
    islLoading: boolean
    onCancel: () => void
    onOk: (values: ExamCategory.EditInput, shouldCreate: boolean, isCopy: boolean) => void
    record: ExamCategory | undefined
    visible: boolean
    isCopy: boolean
}

export const ExamCategoryModal: React.VFC<Props> = (props) => {
    const { islLoading, isCopy, onCancel, onOk, record, visible } = props;
    const isControllableUser = useAppSelector(state => state.isControllableUser);
    const user = useAppSelector(state => state.user);
    const { payload: currentUser } = useAppSelector(state => state.user);
    const globalSelector = useAppSelector(state => state.globalState);
    const initialValues: ExamCategory.EditInput = useMemo(() => {
        return isControllableUser.payload ? ControlInitFormValue : AdminInitFormValue;
    }, [isControllableUser.payload]);
    const [form] = Form.useForm<ExamCategory.EditInput>();
    const target = Form.useWatch('target', form);
    // Targets が各 key がないとエラーになるので, つけておく
    const currentTarget = {
        target_organizations: [],
        target_roles: [],
        target_school_classes: [],
        target_users: [],
        ...target,
    };
    const [isPublishToAllOrganizations, setIsPublishToAllOrganizations] = useState(
        ControlInitFormValue.publish_to_all_organizations
    );
    const [isSelectTargetOpen, setIsSelectTargetOpen] = useState(false);
    const [isDesignatedTerm, setIsDesignatedTerm] = useState(ControlInitFormValue.is_designated_term);

    /**
     * state の値をすべて初期値にリセットする
     */
    const resetAllState = useCallback(() => {
        form.resetFields();
        form.setFieldsValue(initialValues);
        setIsPublishToAllOrganizations(
            ControlInitFormValue.publish_to_all_organizations
        );
        setIsSelectTargetOpen(false);
        setIsDesignatedTerm(ControlInitFormValue.is_designated_term);
    }, [form, initialValues]);

    useEffect(() => {
        if (visible) return;

        resetAllState();
    }, [resetAllState, visible]);

    useEffect(() => {
        if (!visible || !record || islLoading) return;

        // TODO: ここらへんの処理を saveingData とかそのへんに持っていきたい
        if ('target_permissions' in record) {
            const {
                is_designated_term,
                publish_start_at,
                publish_end_at,
                publish_to_all_organizations,
                target_grades,
                target_organizations,
                target_permissions,
                target_term,
                title,
                ...rest
            } = record;
            const target: Target = {
                target_organizations: publish_to_all_organizations ? [] : target_organizations,
            };
            // is_designated_term は現状送られてこないので,target_termの有無で判断
            const tempIsDesignatedTerm = ('is_designated_term' in record) ?
                is_designated_term :
                !!target_term;

            form.setFieldsValue({
                ...rest,
                duration: [moment.unix(publish_start_at), moment.unix(publish_end_at)],
                is_controllable: true,
                is_designated_term: tempIsDesignatedTerm,
                publish_to_all_organizations,
                target,
                target_grades: target_grades.map(v => v.code),
                target_permissions: target_permissions.map(v => v.code),
                target_term: target_term,
                title: isCopy ? `${title}のコピー` : title,
            });
            setIsPublishToAllOrganizations(publish_to_all_organizations);
            setIsDesignatedTerm(tempIsDesignatedTerm);
        } else {
            const {
                description,
                publish_start_at,
                publish_end_at,
                target_users,
                target_school_classes,
                target_roles,
                title,
                is_active,
                ...rest
            } = record;
            const target: Target = {
                target_roles: target_roles,
                target_school_classes: target_school_classes,
                target_users: target_users,
            };
            form.setFieldsValue({
                ...rest,
                description: description ?? undefined,
                duration: [moment.unix(publish_start_at), moment.unix(publish_end_at)],
                is_controllable: false,
                target,
                target_me: [target_users.map(user => user.uuid).includes(currentUser.uuid)],
                title: isCopy ? `${title}のコピー` : title,
            });
        }
    }, [form, initialValues, record, currentUser.uuid, visible, islLoading, isCopy]);

    const onFinish = (values: ExamCategory.EditInput) => {
        onOk(values, !record, isCopy);
    };

    /**
     * target が学校管理者用で target_organizations を持っているかを確認する
     */
    const hasTargetOrganizations = (target: Target): target is {
        target_organizations: Organization[];
    } => {
        return isControllableUser.payload;
    };

    const handleSelectTargetSubmit = (selectTarget: Partial<{
        target_users: User[];
        target_school_classes: SchoolClass[];
        target_roles: Role[];
        target_organizations: Organization[];
    }>) => {
        const values = isControllableUser.payload ?
            {
                target_organizations: selectTarget.target_organizations ?? [],
            } :
            {
                target_roles: selectTarget.target_roles ?? [],
                target_school_classes: selectTarget.target_school_classes ?? [],
                target_users: selectTarget.target_users ?? [],
            };

        setIsSelectTargetOpen(false);
        if (hasTargetOrganizations(values)) {
            form.setFieldsValue({
                target: {
                    target_organizations: values.target_organizations,
                },
            });
        } else {
            form.setFieldsValue({
                target: {
                    target_roles: values.target_roles,
                    target_school_classes: values.target_school_classes,
                    target_users: values.target_users,
                },
                target_me: [values.target_users.map(user => user.uuid).includes(currentUser.uuid)],
            });
        }
    };

    const handleSelectTargetCancel = () => {
        setIsSelectTargetOpen(false);
    };

    const handleChangeTargetMe = (e: CheckboxChangeEvent) => {
        const values = form.getFieldsValue().target;
        if ('target_organizations' in values) {
            // 「自分へ配信」は学校管理者でしか表示されないので, 実行されないはず
            console.error('予期せぬ処理が実行されました.');
            return;
        }

        const newUsers = e.target.checked ?
            values.target_users.concat([currentUser]) :
            values.target_users.filter(user => user.uuid !== currentUser.uuid);

        const newTarget = {
            target_roles: values.target_roles,
            target_school_classes: values.target_school_classes,
            target_users: newUsers,
        };

        form.setFieldsValue({ target: newTarget });
    };

    const modalTitle = (() => {
        if (isCopy) return 'テストグループをコピーして作成';

        return !record ? 'テストグループの新規作成' : 'テストグループの編集';
    })();

    // TODO: 修正.
    // 以下の処理, 特に固定値は, 本当はどこか別の場所に置くべきだが, 適切な場所がないので直書きする.
    // Backend src/models/infrastructures/Organization.php 参考
    const TYPE_BOARD_OF_EDUCATION = '1';
    const belongBoardOfEducation = user.payload.belongs.filter(belong => belong.role.organization.type === TYPE_BOARD_OF_EDUCATION).length > 0;
    const isAgencyLogin = belongBoardOfEducation && !checkEnvironmentForControllable();

    return (
        <BaseModal
            className='common-modal'
            footer={[
                <Button
                    disabled={islLoading}
                    key='back'
                    loading={islLoading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    disabled={islLoading}
                    key='create'
                    loading={islLoading}
                    onClick={() => { form.submit(); }}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><ReadOutlined style={{ marginRight: '1rem' }} />{modalTitle}</span>}
            visible={visible}
        >

            <Spin spinning={props.islLoading}>
                <Form<ExamCategory.EditInput>
                    {...layout}
                    form={form}
                    id='editTest'
                    initialValues={initialValues}
                    name='control-hooks-edit-tests'
                    onFinish={onFinish}
                >
                    {/*編集時にpostする用*/}
                    <Form.Item hidden name='uuid'><Input /></Form.Item>
                    {/** View Logic 内で型推論が良く行われるようために用意 */}
                    <Form.Item hidden name='is_controllable'><Input /></Form.Item>
                    <Form.Item
                        label='テストグループ名称'
                        name='title'
                        rules={[
                            { message: 'テストグループ名称を入力してください', required: true },
                            {
                                max: 255,
                                message: 'テストグループ名称は255文字以下で入力してください',
                            },
                        ]}
                    >
                        <Input placeholder='テストグループ名称を入力してください' />
                    </Form.Item>
                    <Form.Item
                        label='概要'
                        name='description'
                        rules={[
                            { required: false },
                            { max: 128, message: '129文字以上入力できません' },
                        ]}
                    >
                        <Input placeholder='概要を入力してください' />
                    </Form.Item>

                    <Form.Item
                        label='解答期間'
                        name='duration'
                        rules={[formRules.requiredDuration({ label: '解答期間' })]}
                    >
                        <CustomRangePicker
                            disabledDate={getDisabledDate}
                            form={form}
                            format='YYYY/MM/DD HH:mm'
                            locale={jaJP}
                            name='duration'
                            ranges={DatePickerRangesIncludeInfinite()}
                            showTime={{ format: 'HH:mm' }}
                        />
                    </Form.Item>

                    {isControllableUser.payload && (
                        <Form.Item label='対象学校' name='publish_to_all_organizations'>
                            <Radio.Group
                                onChange={e => { setIsPublishToAllOrganizations(e.target.value); }}
                                value={isPublishToAllOrganizations}
                            >
                                <Radio value={true}>すべて</Radio>
                                <Radio value={false}>個別選択</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <Form.Item
                            hidden={isPublishToAllOrganizations}
                            label={<span>配信対象学校</span>}
                            name='target'
                            rules={isPublishToAllOrganizations ?
                                [] :
                                [
                                    { message: '配信対象学校を選択してください', required: true },
                                    formRules.targetValidator('配信対象学校を選択してください'),
                                ]}
                        >
                            <div>
                                <Button
                                    icon={<TeamOutlined />}
                                    onClick={() => setIsSelectTargetOpen(true)}
                                    type='primary'
                                >
                                    配信対象学校を選択
                                </Button>
                                <Targets.Detail currentTarget={currentTarget} />
                            </div>
                        </Form.Item>
                    )}

                    {!isControllableUser.payload && (
                        <Form.Item
                            label={<span>配信対象者</span>}
                            required
                        >
                            <Row>
                                <Col lg={6} md={8} sm={10} xl={6} xs={24}>
                                    <Form.Item
                                        name='target'
                                        rules={[
                                            { message: '配信対象者を選択してください', required: true },
                                            formRules.targetValidator('配信対象者を選択してください'),
                                        ]}
                                    >
                                        <Button
                                            icon={<TeamOutlined />}
                                            onClick={() => setIsSelectTargetOpen(true)}
                                            type='primary'
                                        >
                                            配信対象者を選択
                                        </Button>
                                        <Targets.Detail currentTarget={currentTarget} />
                                    </Form.Item>
                                </Col>
                                {!isAgencyLogin && (
                                    <Col lg={4} md={6} sm={8} xl={4} xs={24}>
                                        <Form.Item name='target_me'>
                                            {/**
                                             * 「自分に配信」しかないので Checkbox.Group である必要はないが,
                                             * Checkbox.Group をつけないと form に値が入らない? ので仕方なく Checkbox.Group をつけている.
                                             * つけなくても問題ないなら消す.
                                             */}
                                            <Checkbox.Group >
                                                <Checkbox onChange={handleChangeTargetMe} value={true}>自分に配信</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <Form.Item
                            label={texts.targetPermissionsTitle}
                            name='target_permissions'
                            rules={[{ message: `${texts.targetPermissionsTitle}を選択してください`, required: true }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    <Col span={4}>
                                        <Checkbox value='administrator'>{texts.targetPermissionsCheckbox.administrator}</Checkbox>
                                    </Col>
                                    <Col span={4}>
                                        <Checkbox value='teacher'>{texts.targetPermissionsCheckbox.teacher}</Checkbox>
                                    </Col>
                                    <Col span={4}>
                                        <Checkbox value='student'>{texts.targetPermissionsCheckbox.student}</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <Form.Item
                            label='公開対象学年コード'
                            name='target_grades'
                            rules={[
                                { message: '公開対象学年コードを選択してください', required: true },
                                formRules.targetGradesValidator,
                            ]}
                        >
                            <Select
                                allowClear
                                mode='multiple'
                                onChange={() => { form.validateFields(['target_grades']); }}
                                placeholder='学年コードを選択してください'
                                showSearch={false}
                            >
                                {defaultGradePreset.map((value, index) => (
                                    <Option
                                        key={index}
                                        value={value.code}
                                    >
                                        {value.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <Form.Item label='年度指定' name='is_designated_term'>
                            <Radio.Group
                                onChange={e => { setIsDesignatedTerm(e.target.value); }}
                                value={isDesignatedTerm}
                            >
                                <Radio value={true}>あり</Radio>
                                <Radio value={false}>なし</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <Form.Item
                            hidden={!isDesignatedTerm}
                            label='年度選択'
                            name='target_term'
                            rules={isDesignatedTerm ?
                                [{ message: '年度を選択してください', required: true }] :
                                []
                            }
                        >
                            <Select
                                allowClear
                                placeholder='年度を選択してください'
                                showSearch={false}
                            >
                                {globalSelector.termList.map((value, index) => (
                                    <Option key={index} value={value.uuid}>
                                        {value.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {!isControllableUser.payload && (
                        <Form.Item
                            label={
                                <span>テスト種別</span>
                            }
                            name='is_required'
                            rules={[{ message: 'テスト種別を入力してください', required: true }]}
                        >
                            <Radio.Group>
                                <Radio value={true}>必修</Radio>
                                <Radio value={false}>自習</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                </Form>
            </Spin>

            <Targets
                currentTarget={currentTarget}
                onCancel={handleSelectTargetCancel}
                onSubmit={handleSelectTargetSubmit}
                onlyHasSchoolCode
                showClass={!isControllableUser.payload}
                showOrganization={isControllableUser.payload}
                showRole={!isControllableUser.payload}
                showUser={!isControllableUser.payload}
                visible={isSelectTargetOpen}
            />

        </BaseModal>
    );
};
import { createSelector } from 'reselect'


const  questionnaireCategoryEditConnectionSelector = state => state.questionnaireCategoryEditConnection
const  questionnaireCategorySearchSelector = state => state.questionnaireCategorySearchConnection


export const questionnaireCategoryTabLoadingSelector = createSelector(
    [questionnaireCategorySearchSelector, questionnaireCategoryEditConnectionSelector],
    (questionnaireCategorySearchConnection, questionnaireCategoryEditConnection) => (
        {
            tableLoading: questionnaireCategorySearchConnection.meta.fetch || questionnaireCategoryEditConnection.meta.fetch,
            buttonLoading: questionnaireCategorySearchConnection.meta.fetch || questionnaireCategoryEditConnection.meta.fetch,
        }
    )
)

import { Input, Button, Checkbox } from 'antd';
import * as Actions from "../../../../flex/Actions";
import {useDispatch, useSelector} from "react-redux";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;



export default function  LoginForm() {
    const dispatch = useDispatch();
    const loginConnection = useSelector(state => state.loginConnection);

    const onFinish = values => {
        dispatch(Actions.http.connection.authentication.login(values))
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                name="login_id"
                rules={[{ required: true, message: '必須項目です' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="サインインIDを入力" />

            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: '必須項目です' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="パスワード入力"
                />
            </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit" className={"login-form-button"} loading={loginConnection.meta.fetch}>
                    サインイン
                </Button>
            </Form.Item>
        </Form>
    );
}

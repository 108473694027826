import { useState, useEffect } from 'react';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

const INIT_STATE: AppStatusModal.ApplicationView = {
    application: undefined,
    loading: false,
    users: [],
    pagination: {
        total: 0,
        pageSize: 20,
        current: 1,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: false
    }
};

export const useApplicationViewData = (applicationUuid: string) => {
    const [applicationView, setApplicationView] = useState<AppStatusModal.ApplicationView>({ ...INIT_STATE });
    const appsCategoryResult = useAppSelector(state => state.appsCategoryResultConnection);

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = appsCategoryResult;
        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                setApplicationView({
                    ...INIT_STATE,
                    loading: true,
                });
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                const total = payloadResult?.item_count ? payloadResult?.item_count : 0;
                const applications = payloadResult?.items.length !== 0 && payloadResult
                    ? payloadResult.items[0].applications
                    : undefined;
                const application = applications
                    ? applications.find(application => {
                        return application.uuid === applicationUuid;
                    })
                    : undefined;
                const users = payloadResult?.items.map(item => {
                    const latestHistory = item.applications.find(application => application.uuid === applicationUuid)?.result_histories[0];
                    const userName = item.first_name !== '' || item.last_name !== '' ?
                        `${item.last_name} ${item.first_name}` :
                        item.login_id;
                    const agsMaxScore = latestHistory?.max_score ?? 0;
                    const dlMaxScore = application?.max_score ?? 0;
                    const user = {
                        name: userName,
                        loginId: item.login_id,
                        uuid: item.uuid,
                        gradingProgress: latestHistory?.grading_progress,
                        score: latestHistory?.score ?? 0,
                        maxScore: latestHistory ? agsMaxScore : dlMaxScore,
                        createdAt: latestHistory?.created_at,
                        resultUrl: item.applications
                            ? item.applications.find(application => application.uuid === applicationUuid)?.result_url ?? ''
                            : undefined,
                    };
                    const children = item.applications
                        .find(application => application.uuid === applicationUuid)?.result_histories
                        .slice(1)
                        .map(history => {
                            return {
                                activityProgress: history!.activity_progress,
                                agsTimestamp: history!.ags_timestamp,
                                comment: history!.comment,
                                createdAt: history!.created_at,
                                applicationResultUuid: history!.application_result_uuid,
                                gradingProgress: history!.grading_progress,
                                maxScore: history ? history!.max_score : dlMaxScore,
                                score: history!.score ?? 0,
                            };
                        }
                        );
                    return {
                        ...user,
                        children
                    }
                })

                setApplicationView({
                    ...applicationView,
                    pagination: {
                        ...applicationView.pagination,
                        total: total
                    },
                    application: application,
                    loading: false,
                    users: users ?? [],
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setApplicationView({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsCategoryResult]);

    return [applicationView] as const;
};
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnGroupType } from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/Table';

import { BaseTable } from 'components/modules/BaseTable';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import { generateMngDomain, openWindowWithReferer } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import { examCategoryTabFlex, TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import 'styles/modules/test_status_modal.scss';
import { usePagination } from '../../hooks/usePagination';

import type {
    ResultExamCategoryState,
    RequestResultExamCategory,
} from '../../hooks/useResultExamCategory';

type Exams = { [K in keyof ResultExamCategoryState]: NonNullable<ResultExamCategoryState[K]> }['result']['exams'];

type Props = {
    breadCrumb: ExamCategoryTabState.BreadCrumb
    examCategoryStatus: ResultExamCategoryState
    isStatusOpen: boolean
    record: ExamCategory | undefined
    requestResult: RequestResultExamCategory
    tabName: 'board' | 'school' | undefined
}

export const OrganizationList: React.VFC<Props> = ({
    breadCrumb,
    examCategoryStatus: { isLoading, result },
    isStatusOpen,
    record,
    requestResult,
    tabName,
}) => {
    const requestType = tabName === undefined ?
        'control' :
        tabName === TAB_NAMES.SCHOOL ?
            'default' :
            'control';

    const [pagination, setPagination, resetPagination] = usePagination({
        onChange: (page, pageSize) => {
            setPagination(pagination => ({ ...pagination, current: page, pageSize }));

            requestResult(
                record?.uuid ?? '',
                { page, page_size: pageSize },
                requestType,
            );
        },
        pageSize: 50,
    });

    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const disabledCsvExport = useAppSelector(examCategoryTabFlex.selectors.disabledCsvExport);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!isStatusOpen) return;
        if (!record?.uuid) return;

        resetPagination();
        requestResult(record.uuid, { page_size: 50 }, requestType);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStatusOpen, record?.uuid]);

    useEffect(() => {
        if (isLoading) return;

        setPagination(pagination => ({
            ...pagination,
            onChange: (page, pageSize) => {
                if (!record?.uuid) return;

                setPagination(pagination => ({ ...pagination, current: page, pageSize }));

                requestResult(
                    record.uuid,
                    { page, page_size: pageSize },
                );
            },
            total: result && 'organizationsListItemCount' in result ?
                result.organizationsListItemCount :
                0,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, record?.uuid, result]);


    const isShowList = breadCrumb.type === 'List';

    const handleAgencyLoginClick = (uuid: string) => {
        dispatch(Actions.http.connection.organization.agencyLogin(uuid));
        openWindowWithReferer(
            `${generateMngDomain()}/assessment?ct=db&tg=${record?.uuid}`,
            '_blank'
        );
    };

    const columns: ColumnsType<ExamCategory.ControlResult> = [
        {
            children: [
                {
                    fixed: 'left',
                    key: 'name',
                    render: (_, record) => {
                        return (
                            <Tooltip title={<span>{record.organization.name}へサインインして結果を確認する</span>}>
                                <button
                                    className='text-button'
                                    onClick={() => {
                                        handleAgencyLoginClick(record.organization.uuid);
                                    }}
                                >
                                    {record.organization.name}
                                </button>
                            </Tooltip>
                        );

                    },
                    title: '学校名',
                    width: 200,
                },
            ],
            key: 'target',
            title: '配信対象校',
        },
        {
            align: 'center',
            children: generateChildren(isControllableUser, result),
            key: 'result',
            title: 'テスト結果',
            width: 250 * (result ? result.exams.length : 0),
        },
    ];

    const calculateScrollWidth = () => {
        return 550 + (100 * (result ? result.exams.length : 0));
    };

    // 検索結果をCSVでダウンロードさせる
    const handleCsvDownload = (encoding: string) => {
        if (!record?.uuid) return;
        if (tabName === TAB_NAMES.BOARD_OF_EDUCATION || isControllableUser) {
            dispatch(Actions.http.connection.tao.examCategory.controlDownloadCsv(
                record.uuid,
                encoding,
                {}
            ));
        } else {
            dispatch(Actions.http.connection.tao.examCategory.downloadCsv(
                record.uuid,
                encoding,
                {}
            ));
        }
    };

    const dataSource = result && 'organizationsList' in result ?
        result.organizationsList :
        [];

    if (!isStatusOpen) return null;

    return (
        <div>
            {isShowList && (
                <>
                    <div className='flex-right-container gutter-bottom mt-4 mb-4'>
                        <DownloadCSVConfirm
                            disabled={isLoading || disabledCsvExport}
                            disabledMessage={<p>CSVエクスポートはすでに実行済みです。<br />CSVのダウンロード状況については、画面左下からご確認ください</p>}
                            handleOk={(encoding) => handleCsvDownload(encoding)}
                            loading={isLoading}
                            title='テスト配信対象 CSV エクスポート'
                        />
                    </div>

                    <BaseTable
                        className='all-white-table'
                        columns={columns}
                        dataSource={dataSource}
                        emptyDescription='対象のテスト結果が存在しません'
                        loading={isLoading}
                        pagination={pagination}
                        rowKey={record => record.organization.name}
                        scroll={{ x: calculateScrollWidth() }}
                        size='middle'
                    />
                </>
            )}
        </div>
    );
};


/**
 * exams が全体管理者用かを確認する
 */
const isControlResultExam = (isControllableUser: boolean, exams: Exams): exams is Exam.ControlResult[] => {
    return isControllableUser;
};

const generateChildren = (
    isControllableUser: boolean,
    result: ResultExamCategoryState['result']
): ColumnGroupType<ExamCategory.ControlResult>[] => {
    if (result === undefined) return [];

    // このコンポーネントは全体管理者用であり、全体管理者であれば必ず organizationsListItemCount が含まれる
    if (!('organizationsListItemCount' in result)) return [];

    if (result.organizationsListItemCount === 0) return [];

    const exams = result.exams;
    if (!isControlResultExam(isControllableUser, exams)) return [];

    return exams.map((value, index) => ({
        children: [
            {
                align: 'center',
                key: `progress_rate.${index}`,
                render: (_, record) => {
                    const answeredUserCount = record.exams[index].answeredUserCount;
                    const targetUsers = record.exams[index].targetUsers;

                    const progressTate = answeredUserCount !== undefined && targetUsers !== 0 ?
                        Math.ceil(100 * answeredUserCount / targetUsers) :
                        0;

                    return `${progressTate} % 解答完了`;
                },
                title: '進捗率',
                width: 100,
            },
            {
                align: 'center',
                key: `average_score.${index}`,
                render: (_, record) => {
                    const averageScore = record.exams[index].averageScore ?? '-';
                    const maxScore = record.exams[index].maxScore ?? '-';

                    return `${averageScore} / ${maxScore} 点`;
                },
                title: (
                    <>
                        <span>平均点/ 満点</span>
                        <Tooltip title='テスト配信対象者が0名の場合「平均点/満点」はハイフン表示「- / - 点」になります。'>
                            <QuestionCircleOutlined className='icon-l' />
                        </Tooltip>
                    </>
                ),
                width: 110,
            },
        ],
        key: `${index}`,
        title: value.title,
    }));
};
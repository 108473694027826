import { Col, Row, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    additionDisplayName,
    getMoment,
} from 'constants/GlobalConfig';
import CircleButton from 'components/modules/CircleButton';
const moment = getMoment();


export const createColumns = (
    hasTouchScreen = false,
    onEditClick,
    onDeleteClick,
    isControl = false,
) => {
    return [
        {
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
                return (
                    <Tooltip mouseEnterDelay={1} title={`UUID:${record.uuid}`}>
                        {name}
                    </Tooltip>
                );
            },
            title: 'クラス',
            width: isControl ? 30 : 40,
        },
        ...(isControl)// 全体管理者の場合必要なCell
            ? [{
                dataIndex: 'organization',
                key: 'organization',
                render: (organization) => organization.name,
                title: '学校名',
                width: 40,
            }]
            : [],
        {
            dataIndex: 'grade',
            key: 'grade',
            render: (grade) => grade.name,
            title: '学年',
            width: 30,
        },
        {
            dataIndex: 'class_type',
            key: 'class_type',
            render: (classType) => classType === 1 ? '学籍クラス' : classType === 2 ? 'その他' : '不明',
            title: 'クラス種別',
            width: 30,
        },
        {
            dataIndex: 'main_teacher',
            key: 'main_teacher',
            render: (mainTeacher) => additionDisplayName(mainTeacher).display_name,
            title: '主担任',
            width: 30,
        },
        {
            dataIndex: 'term',
            key: 'term',
            render: (term) => (
                <>
                    <span>{term.name}</span>
                    <br />
                    <span>{moment.unix(term.start_at).format('(YYYY/MM/DD HH:mmから')}</span>
                    <br />
                    <span>{moment.unix(term.end_at).format('YYYY/MM/DD HH:mmまで)')}</span>
                </>
            ),
            title: '年度',
            width: 30,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: 20,
        },
        {
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (_, record) => (
                <Row align='middle' justify='space-around' type='flex'>
                    <Col>
                        <CircleButton
                            className='mr-2'
                            disabled={!record.is_class}
                            icon={<EditOutlined />}
                            onClick={() => onEditClick(record)}
                            size='small'
                            tooltipProps={{
                                title: hasTouchScreen ?
                                    undefined :
                                    record.is_class ? '編集' : '編集不可',
                            }}
                        />
                    </Col>
                    <Col>
                        <CircleButton danger
                            className='mr-2'
                            disabled={!record.is_class}
                            icon={<DeleteOutlined />}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                okText: '削除',
                                onConfirm: () => onDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>クラスを削除します。よろしいですか。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                ),
                            }}
                            size='small'
                            tooltipProps={{
                                title: hasTouchScreen ?
                                    undefined :
                                    record.is_class ? '削除' : '削除不可',
                            }}
                        />
                    </Col>
                </Row>
            ),
            title: '',
            width: 10,
        },
    ];
};

import { combineActions, handleAction, handleActions } from 'redux-actions';
import * as Actions from 'flex/Actions';
import * as GlobalConfig from 'constants/GlobalConfig';
import { getDefaultFeatures } from 'constants/GlobalConfig';
import { userManagementTabFlex } from './view/UsersManagement/UserManagementTab';
import { appTemplateTabDefaultState } from './view/AppTemplate/AppTemplateTab/defaultState';
import { appTemplateTabReducer } from './view/AppTemplate/AppTemplateTab/reducer';
import { examCategoryTabFlex, examControlCategoryTabFlex } from './view/Assessment/examCategoryTabFlex';

export * from 'flex/reducers/apps';
export * from 'flex/reducers/everydayNote';
export * from 'flex/reducers/notification';
export * from 'flex/reducers/ogp';
export * from 'flex/reducers/questionnaire';
export * from 'flex/reducers/security';
export * from 'flex/reducers/tao';
export * from 'flex/reducers/user';


const defaultState = ({
    payload: {},
    meta: {
        fetch: false,
        status: '',
        viewName: ''
    },
});

const defaultReducer = {
    next: (_, action) => {
        return { ...action }
    },
    throw: (_, action) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE
            }
        }))
    }
};

export const loginConnection = handleAction(
    Actions.http.connection.authentication.login,
    defaultReducer,
    defaultState
);

export const logoutConnection = handleAction(
    Actions.http.connection.authentication.logout,
    defaultReducer,
    defaultState
);

export const checkLoginConnection = handleAction(
    Actions.http.connection.authentication.check,
    defaultReducer,
    defaultState
);

export const tenantConnection = handleAction(
    Actions.http.connection.authentication.tenant,
    defaultReducer,
    defaultState
);

// EduMall
export const edumallSearchConnection = handleAction(
    Actions.http.connection.edumall.search,
    defaultReducer,
    defaultState
);


// アンケートカテゴリ
export const questionnaireCategorySearchConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaireCategory.search,
        Actions.http.connection.questionnaireCategory.controlSearch,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireCategoryEditConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaireCategory.delete,
        Actions.http.connection.questionnaireCategory.create,
        Actions.http.connection.questionnaireCategory.update,
        Actions.http.connection.questionnaireCategory.controlDelete,
        Actions.http.connection.questionnaireCategory.controlCreate,
        Actions.http.connection.questionnaireCategory.controlUpdate,
    ),
    defaultReducer,
    defaultState
);

//役割管理
export const roleSearchConnection = handleAction(
    Actions.http.connection.role.search,
    defaultReducer,
    defaultState
);

export const roleEditConnection = handleAction(
    combineActions(
        Actions.http.connection.role.delete,
        Actions.http.connection.role.create,
        Actions.http.connection.role.update,
    ),
    defaultReducer,
    defaultState
);

//クラス管理
export const classSearchConnection = handleAction(
    combineActions(
        Actions.http.connection.classes.search,
        Actions.http.connection.classes.searchForListMap
    ),
    defaultReducer,
    defaultState
);

export const classEditConnection = handleAction(
    combineActions(
        Actions.http.connection.classes.create,
        Actions.http.connection.classes.update,
    ),
    defaultReducer,
    defaultState
);

export const classDeleteConnection = handleAction(
    Actions.http.connection.classes.delete,
    defaultReducer,
    defaultState
);

export const classImportConnection = handleAction(
    Actions.http.connection.classes.import,
    defaultReducer,
    defaultState
);

export const classExportConnection = handleAction(
    Actions.http.connection.classes.export,
    defaultReducer,
    defaultState
);
export const classViewConnection = handleAction(
    Actions.http.connection.classes.view,
    defaultReducer,
    defaultState
);

export const classCopySettingConnection = handleAction(
    combineActions(
        Actions.http.connection.classes.copyClassSetting.view,
        Actions.http.connection.classes.copyClassSetting.update
    ),
    defaultReducer,
    defaultState
);

//学年取得
export const gradeSearchConnection = handleAction(
    Actions.http.connection.grade.search,
    defaultReducer,
    defaultState
);

//学校管理
export const organizationSearchConnection = handleAction(
    Actions.http.connection.organization.search,
    defaultReducer,
    defaultState
);

export const organizationEditConnection = handleAction(
    combineActions(
        Actions.http.connection.organization.delete,
        Actions.http.connection.organization.create,
        Actions.http.connection.organization.update,
    ),
    defaultReducer,
    defaultState
);

export const organizationImportConnection = handleAction(
    Actions.http.connection.organization.import,
    defaultReducer,
    defaultState
);

export const organizationAgencyConnection = handleAction(
    Actions.http.connection.organization.agencyLogin,
    defaultReducer,
    defaultState
);

export const termSearchConnection = handleAction(
    Actions.http.connection.term.search,
    defaultReducer,
    defaultState
);

export const fileConnection = handleAction(
    Actions.http.connection.file.upload,
    defaultReducer,
    defaultState
);

// 利用履歴の取得
export const usageTotalConnection = handleAction(
    Actions.http.connection.usage.total,
    defaultReducer,
    defaultState
);

export const usageDetailConnection = handleAction(
    Actions.http.connection.usage.detail,
    defaultReducer,
    defaultState
);

export const usageExportTotalConnection = handleAction(
    Actions.http.connection.usage.export.total,
    defaultReducer,
    defaultState
);

// メンテナンス・緊急情報取得
export const announcementGetConnection = handleAction(
    Actions.http.connection.announcement.get,
    defaultReducer,
    defaultState
);

//同期処理部分のreducer 便宜上複数に分けた
export const dryRunGroupConnection = handleAction(
    Actions.http.connection.sync.dryRunGroup,
    defaultReducer,
    defaultState
);

export const executeGroupConnection = handleAction(
    Actions.http.connection.sync.executeGroup,
    defaultReducer,
    defaultState
);

export const dryRunUserConnection = handleAction(
    Actions.http.connection.sync.dryRunUser,
    defaultReducer,
    defaultState
);

export const executeUserConnection = handleAction(
    Actions.http.connection.sync.executeUser,
    defaultReducer,
    defaultState
);

export const dryRunGroupMemberConnection = handleAction(
    Actions.http.connection.sync.dryRunGroupMember,
    defaultReducer,
    defaultState
);

export const executeGroupMemberConnection = handleAction(
    Actions.http.connection.sync.executeGroupMember,
    defaultReducer,
    defaultState
);

export const dryRunDeleteUserConnection = handleAction(
    Actions.http.connection.sync.dryRunDeleteUser,
    defaultReducer,
    defaultState
);

export const executeDeleteUserConnection = handleAction(
    Actions.http.connection.sync.executeDeleteUser,
    defaultReducer,
    defaultState
);

export const jobSearchConnection = handleAction(
    Actions.http.connection.job.search,
    defaultReducer,
    defaultState
);

export const jobViewConnection = handleAction(
    Actions.http.connection.job.view,
    defaultReducer,
    defaultState
);

export const manualGetConnection = handleAction(
    Actions.http.connection.manual.get,
    defaultReducer,
    defaultState
);


const defaultAuthState = ({
    payload: {
        is_checked: false,
        ia_authenticated: false,
        is_required_session: process.env.REACT_APP_HAVE_SESSION === 'true'
    }
});

const defaultIsAdminState = ({
    payload: false
});

export const session = handleAction(
    Actions.data.session,
    (_, action) => {
        return action
    },
    defaultAuthState
);

export const user = handleAction(
    Actions.data.user,
    (_, action) => {
        const user = action.payload;
        if (user === null) return null;

        const userName = user.first_name !== '' || user.last_name !== '' ?
            `${user.last_name} ${user.first_name}` :
            user.login_id;
        const newAction = { ...action, payload: { ...action.payload, user_name: userName } };
        return newAction;
    },
    defaultAuthState
);

const defaultTenantState = ({
    payload: {
        domain: '',
        municipality_id: '',
        name: '',
        type: '',
        features: getDefaultFeatures()
    }
});

export const tenant = handleAction(
    Actions.data.tenant,
    (_, action) => {
        return action
    },
    defaultTenantState
);

const defaultJobState = ({
    payload: {
        uuid: null,
        type: 1,
        type_name: 'Job処理',
        is_success: true,//デフォルトではtrueにしておく
        message: 'メッセージ',
        result: {
            total_count: 0,
            processed_count: 0
        },
        done_at: null
    },
    meta: {
        jobWaiting: false
    }
});

export const jobData = handleAction(
    Actions.data.jobData,
    (_, action) => {
        return action
    },
    defaultJobState
);

const defaultJobCallback = ({
    payload: {
        success: () => { },
        failure: () => { },
    }
});

export const jobCallback = handleAction(
    Actions.data.jobCallback,
    (_, action) => {
        return action;
    },
    defaultJobCallback
);

export const jobInfoMap = handleActions(
    {
        [Actions.data.jobInfoMap.add]: (state, action) => {
            console.log(action)
            const uuid = action.payload.jobResult.uuid;
            return {
                ...state,
                [uuid]: {
                    job: action.payload.jobResult,
                    gmt: action.payload.gmt,
                    isError: action.payload.isError,
                    loading: false,
                    someUuid: action.payload.someUuid ? action.payload.someUuid : state[uuid]?.someUuid,
                    showClose: action.payload.showClose !== undefined ? action.payload.showClose : state[uuid]?.showClose,
                }
            }
        },
        [Actions.data.jobInfoMap.setMap]: (_, action) => {
            return action.payload;
        },
        [Actions.data.jobInfoMap.remove]: (state, action) => {
            const uuid = action.payload.uuid;
            const newValue = Object.assign({}, state);
            delete newValue[uuid]
            return newValue;
        },
        [Actions.data.jobInfoMap.setStatus]: (state, action) => {
            const uuid = action.payload.uuid;
            const status = action.payload.status;
            const newValue = Object.assign({}, state)
            if (Object.keys(newValue).includes(uuid)) {
                newValue[uuid] = {
                    ...newValue[uuid],
                    ...status
                }
                console.log(newValue[uuid])
            }
            return newValue;
        }
    },
    {}
);

export const isControllableUser = handleAction(
    Actions.data.isControllableUser,
    (_, action) => {
        return action
    },
    defaultIsAdminState
);

const defaultFailureState = ({
    payload: {},
    meta: { isShow: false }
});

export const failure = handleAction(
    Actions.data.failure,
    (_, action) => {
        return action
    },
    defaultFailureState
);

export const globalState = handleAction(
    'globalState',
    defaultReducer,
    {
        termList: [],
        termListItemCount: 0,
        currentTerm: undefined,
    }
);

export const appTemplateTab = handleActions(
    appTemplateTabReducer,
    appTemplateTabDefaultState
);

export const userManagementTab = handleActions(
    userManagementTabFlex.reducers,
    userManagementTabFlex.defaultState
);

export const examCategoryTab = handleActions(
    examCategoryTabFlex.reducer,
    examCategoryTabFlex.defaultState
);

export const examControlCategoryTab = handleActions(
    examControlCategoryTabFlex.reducerForControl,
    examControlCategoryTabFlex.defaultStateForControl
);

export const editUser = handleAction(
    combineActions(
        'editUser',
        Actions.view.viewLogic.editUser.openView,
        Actions.view.viewLogic.editUser.selectOrganization,
        Actions.view.viewLogic.editUser.selectTerms,
        Actions.view.viewLogic.editUser.resetCache,
        Actions.view.viewLogic.editUser.searchRole,
        Actions.view.viewLogic.editUser.setIsLocal,
        Actions.view.viewLogic.editUser.submit,
    ),
    defaultReducer,
    {
        classListMap: {},
        classListMapItemCount: {},
        classListMapListCount: 0,
        roleList: [],
        roleListItemCount: 0,
        selectTerms: [],
        selectOrganization: null,
        isLocal: false,
    }
);

export const questionnaireTab = handleAction(
    combineActions(
        'questionnaireTab',
        Actions.view.viewLogic.questionnaireTab.prepareView,
        Actions.view.viewLogic.questionnaireTab.submitSearch,
        Actions.view.viewLogic.questionnaireTab.submitPage,
        Actions.view.viewLogic.questionnaireTab.savePage,
        Actions.view.viewLogic.questionnaireTab.submitDelete,
        Actions.view.viewLogic.questionnaireTab.openEdit,
        Actions.view.viewLogic.questionnaireTab.openCreate,
        Actions.view.viewLogic.questionnaireTab.openCopy,
        Actions.view.viewLogic.questionnaireTab.openResult,
        Actions.view.viewLogic.questionnaireTab.openTarget,
        Actions.view.viewLogic.questionnaireTab.cancelModal,
        Actions.view.viewLogic.questionnaireTab.submitEdit,
    ),
    defaultReducer,
    {
        search: {
            category: undefined,
            title: undefined,
            src: undefined, // 名前は変える
            type: undefined,
            is_anonymous: undefined, // 匿名か否か
            can_be_answered_only_once: undefined, // 一回のみ回答可か
            publish_start_at: undefined,
            publish_end_at: undefined,
            perform_start_at: undefined,
            perform_end_at: undefined,
            publish_result_start_at: undefined,
            publish_result_end_at: undefined,
            is_public: undefined, // 公開中か否か
            is_public_result: undefined, // 結果を公開するか否か
            is_forced: undefined, //  強制回答か否か
            is_deleted: undefined, // 削除フラグ
            memo: undefined, // メモ
            created_by: undefined, // 作成者
            updated_by: undefined, // 更新者
            status: undefined, // 未回答等の条件
        },
        pagination: {
            total: 0,
            position: ['topRight', 'bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: GlobalConfig.PageSizeOptions,
            pageSize: 50,
            current: 1, // antd 現在のテーブルのページ
        },
        modal: {
            isEditOpen: false,
            shouldCreate: false,
            uuid: null,
            isTargetOpen: false,
            isResultOpen: false,
            isFullScreenResultOpen: false,
            isAnonymous: undefined,
        },
        questionnaireList: [],
        questionnaireListItemCount: 0,
        questionnaireCategoryList: [],
        questionnaireCategoryListItemCount: 0,
    }
);

export const editQuestionnaire = handleAction(
    combineActions(
        'editQuestionnaire',
        Actions.view.viewLogic.editQuestionnaire.prepareView,
        Actions.view.viewLogic.editQuestionnaire.selectCategory,
        Actions.view.viewLogic.editQuestionnaire.submitEdit,
        Actions.view.viewLogic.editQuestionnaire.cancelEdit,
        Actions.view.viewLogic.editQuestionnaire.openTarget,
        Actions.view.viewLogic.editQuestionnaire.submitTarget,
        Actions.view.viewLogic.editQuestionnaire.cancelTarget,
        Actions.view.viewLogic.editQuestionnaire.openQuestion,
        Actions.view.viewLogic.editQuestionnaire.cancelQuestion,
        Actions.view.viewLogic.editQuestionnaire.prepareViewAfter,
        Actions.view.viewLogic.editQuestionnaire.moveQuestion,
        Actions.view.viewLogic.editQuestionnaire.copyQuestion,
        Actions.view.viewLogic.editQuestionnaire.deleteQuestion,
        Actions.view.viewLogic.editQuestionnaire.deleteFile,
        Actions.view.viewLogic.editQuestionnaire.toggleExpandQuestion, // 展開する
    ),
    defaultReducer,
    {
        isTargetOpen: false,
        editQuestion: {
            isEditOpen: false,
            shouldCreate: false,
            question: null,
            parentTmpItemId: null,
            parentTmpCandidateId: null,
        },
        selectCategory: null,
        isPreviewOpen: false,
        questionnaire: null,
        isExpandQuestionMap: {},
        editableItems: [],
        questionnaireCategoryList: [],
        questionnaireCategoryListItemCount: 0
    }
);

export const editQuestion = handleAction(
    combineActions(
        'editQuestion',
        Actions.view.viewLogic.editQuestion.prepareView,
        Actions.view.viewLogic.editQuestion.selectParent,
    ),
    defaultReducer,
    {
        selectParent: null,
        selectParentCandidateOptions: [],
    }
);

export const resultQuestionnaire = handleAction(
    combineActions(
        'resultQuestionnaire',
        Actions.view.viewLogic.resultQuestionnaire.prepareView,
        Actions.view.viewLogic.resultQuestionnaire.openDetail,
        Actions.view.viewLogic.resultQuestionnaire.closeDetail,
        Actions.view.viewLogic.resultQuestionnaire.downloadCsv,
    ),
    defaultReducer,
    {
        modal: {
            item: null,
            type: null,
            isOpen: false,
        },
        questionnaire: null,
    }
);

export const answerResultModal = handleAction(
    combineActions(
        'answerResultModal',
        Actions.view.viewLogic.answerResultModal.prepareView,
        Actions.view.viewLogic.answerResultModal.cancelDetail,
        Actions.view.viewLogic.answerResultModal.submitPage,
        Actions.view.viewLogic.answerResultModal.savePage,
    ),
    defaultReducer,
    {
        pagination: {
            total: 0,
            position: ['topRight', 'bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: GlobalConfig.PageSizeOptions,
            pageSize: 50,
            current: 1, // antd 現在のテーブルのページ
        },
        questionnaire: null,
        resultList: [],
        resultListItemCount: 0
    }
);

export const questionnaireCategoryTab = handleAction(
    combineActions(
        'questionnaireCategoryTab',
        Actions.view.viewLogic.questionnaireCategoryTab.prepareView,
        Actions.view.viewLogic.questionnaireCategoryTab.submitSearch,
        Actions.view.viewLogic.questionnaireCategoryTab.submitPage,
        Actions.view.viewLogic.questionnaireCategoryTab.savePage,
        Actions.view.viewLogic.questionnaireCategoryTab.submitDelete,
        Actions.view.viewLogic.questionnaireCategoryTab.submittedEdit,
        Actions.view.viewLogic.questionnaireCategoryTab.openEdit,
        Actions.view.viewLogic.questionnaireCategoryTab.openCreate,
        Actions.view.viewLogic.questionnaireCategoryTab.cancelEdit,
        Actions.view.viewLogic.questionnaireCategoryTab.submitEdit,
    ),
    defaultReducer,
    {
        search: {
            name: null, // アンケート名部分一致
            description: null, // 概要部分一致
        },
        pagination: {
            total: 0,
            position: ['topRight', 'bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: GlobalConfig.PageSizeOptions,
            pageSize: 50,
            current: 1, // antd 現在のテーブルのページ
        },
        editQuestionnaireCategory: {
            isEditOpen: false,
            shouldCreate: false,
            record: null,
        },
        questionnaireCategoryList: [],
        questionnaireCategoryListItemCount: 0,
    }
);

import { CloseCircleOutlined } from '@ant-design/icons';
import {
    Image,
    FormInstance,
    AutoComplete,
    Row,
    Col,
    Button,
} from 'antd';
import { useEffect, useMemo, useState, ComponentProps, VFC } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { source } from './source';
import OgpViewer from './OgpViewer';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;

type OgpData = {
    description: string | undefined
    image: string | undefined
    siteTitle: string | undefined
    url: string | undefined
};

type PresetData = {
    url: string
    image: {
        url: string
        name: string
    }
}

type Props = {
    addFiles: (file: File) => void
    buttonDisable: boolean
    disabled: boolean
    form: FormInstance
    name: string
    onSelect: (preset: PresetData) => void
    onReset: () => void
};

const AutoCompleteUrl: VFC<Props> = (
    {
        addFiles,
        buttonDisable,
        disabled,
        form,
        name,
        onSelect: onCustomSelect,
        onReset: onCustomReset,
    }
) => {
    const [isErrorGettingOgpImg, setIsErrorGettingOgpImg] = useState(false);
    const [ogpState, setOpgState] = useState<OgpData>();
    const [isLoadingOgp, setIsLoadingOgp] = useState(false);
    const url = Form.useWatch(name, form) ?? '';
    const dispatch = useDispatch();
    const ogpViewDataConnection = useAppSelector(state => state.ogpViewDataConnection);

    useEffect(() => {
        if (!isLoadingOgp) return;

        const { payload, meta } = ogpViewDataConnection;

        setIsLoadingOgp(meta.fetch);
        if (meta.status === Actions.statusEnum.SUCCESS) {
            const result = payload.result;

            if (Array.isArray(result)) {
                setOpgState({
                    description: undefined,
                    image: undefined,
                    siteTitle: '',
                    url: undefined,
                });
            } else {
                setOpgState({
                    description: result.description ?? undefined,
                    image: result.image ?? undefined,
                    siteTitle: getSiteTitle(result.title, result.site_name),
                    url: result.url ?? undefined,
                });
                // 画像が取得できるかのチェック
                result.image && fetch(result.image)
                    .then()
                    .catch(() => setIsErrorGettingOgpImg(true));
            }
        } else {
            setOpgState(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ogpViewDataConnection]);

    const options = useMemo<{ value: string, label: any }[]>(() => {
        return source.map((item) => ({
            value: item.url,
            label: <Option url={item.url} imageSrc={item.image.url} />,
        }));
    }, []);

    const onChange = (value: string) => {
        form.setFieldsValue({ url: value });
        if (value === '') {
            setOpgState(undefined);
        };
    };

    const onSelect: ComponentProps<typeof AutoComplete>['onSelect'] = (value: any, _: any) => {
        const record = source.find(data => data.url === value);

        if (record === undefined) return;

        onCustomSelect(record);

        form.resetFields(['files']);
        form.setFieldsValue({ url: record.url });

        fetch(record.image.url)
            .then(response => response.blob())
            .then(blob => convertToFileFromBlob(blob, record.image.name))
            .then(file => addFiles(file));
    };

    const onReset = () => {
        form.resetFields(['url', 'files']);
        onCustomReset();
    };

    const onSetIconFromOgp = () => {
        if (!ogpState || !ogpState.image) return;

        const imageName = ogpState.image.substring(ogpState.image.lastIndexOf('/') + 1);

        fetch(ogpState.image)
            .then(response => response.blob())
            .then(blob => convertToFileFromBlob(blob, imageName))
            .then(file => addFiles(file));
    };

    const fetchOgpData = () => {
        if (url === '' || ogpState?.url?.includes(url)) return;

        setIsLoadingOgp(true);
        setIsErrorGettingOgpImg(false);
        dispatch(Actions.http.connection.ogp.viewData(url));
    };

    const filterOption = (inputValue: string, option: any) => {
        return inputValue.length === 0 || option.value.indexOf(inputValue) >= 0
    };

    const isShowOgp = (ogpState: OgpData | undefined, isLoadingOgp: boolean) => {
        return (!!ogpState && Object.values(ogpState).some(e => e)) || isLoadingOgp;
    };

    return (
        <div>
            <Row style={{ marginBottom: isShowOgp(ogpState, isLoadingOgp) ? '1rem' : 0 }}>
                <Col span={23}>
                    <AutoComplete
                        disabled={disabled}
                        filterOption={filterOption}
                        onBlur={fetchOgpData}
                        onChange={onChange}
                        onSelect={onSelect}
                        options={options}
                        placeholder='遷移先URLを入力してください'
                        value={form.getFieldValue('url')}
                    />
                </Col>
                <Col span={1}>
                    <Button
                        shape='circle'
                        type='text'
                        icon={<CloseCircleOutlined />}
                        onClick={onReset}
                        disabled={buttonDisable}
                    />
                </Col>
            </Row>

            {isShowOgp(ogpState, isLoadingOgp) && (
                <Row>
                    <Col span={23}>
                        <OgpViewer
                            description={ogpState?.description}
                            hiddenSetIcon={isErrorGettingOgpImg}
                            image={ogpState?.image}
                            isLoading={isLoadingOgp}
                            onSetIcon={onSetIconFromOgp}
                            siteTitle={ogpState?.siteTitle}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default AutoCompleteUrl;


const Option = ({ url, imageSrc }: Record<'url' | 'imageSrc', string>) => {
    return (
        <Row align='middle' justify='start'>
            <Col span={20} style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                {url}
            </Col>
            <Col span={4} style={{ display: 'flex', height: '100px', justifyContent: 'center' }}>
                <Image
                    preview={false}
                    src={imageSrc}
                    wrapperStyle={{ maxHeight: '100px', maxWidth: '100px' }}
                />
            </Col>
        </Row>
    );
};


/**
 * サイト名を取得する。
 *
 * OGP のデータは title と siteName のどちらに「サイト名」と「サイト名と説明」が入っているのかわからないので、
 * とりあえず、文字数が少ない方を「サイト名」として返す。
 */
const getSiteTitle = (title: string | null, siteName: string | null): string => {
    if (title === null && siteName === null) {
        return 'No Title Site';
    }

    if (xor(title !== null, siteName !== null)) {
        return title ?? siteName!;
    }

    return title!.length > siteName!.length ?
        siteName! :
        title!;
};

const xor = (a: boolean, b: boolean) => {
    return (a || b) && !(a && b);
};


const convertToFileFromBlob = (blob: Blob, imageName: string) => {
    const newFileName = imageName.match(/(.jpg|.jpeg|.png|.gif|.bmp)$/) ?
        imageName :
        `${imageName}.${blob.type.split('image/')[1]}`;

    return new File([blob], newFileName);
};

import { Button, Skeleton } from 'antd';
import React from 'react';
import './OgpViewer.css';

type Props = {
    description: string | undefined
    hiddenSetIcon: boolean
    image: string | undefined
    isLoading?: boolean
    onSetIcon?: () => void
    siteTitle: string | undefined
};

const OgpViewer: React.VFC<Props> = ({
    description = 'No description.',
    hiddenSetIcon,
    image,
    isLoading = false,
    onSetIcon = () => ({}),
    siteTitle = 'No Title Site',
}) => {
    if (isLoading) return <OgpSkelton />;

    return (
        <div className='ogp-viewer'>
            <OgpImage
                image={image}
                isLoading={isLoading}
                siteTitle={siteTitle}
                onSetIcon={onSetIcon}
                hiddenSetIcon={hiddenSetIcon}
            />

            <div className='contents'>
                <div className='title'>{siteTitle}</div>
                <div className='description'>{description}</div>
            </div>
        </div>
    );
};

export default OgpViewer;



const OgpImage: React.VFC<Omit<Props, 'description'>> = ({
    hiddenSetIcon,
    image,
    onSetIcon,
    siteTitle,
}) => {
    if (image) {
        return (
            <div className='images'>
                <a href={image} target='_blank' rel='noreferrer'>
                    <img
                        className='image'
                        src={image}
                        alt={`${siteTitle}'s OGP`}
                    />
                </a>
                {!hiddenSetIcon && (
                    <Button onClick={onSetIcon}>アイコン画像に使う</Button>
                )}
            </div>
        );
    } else {
        return (
            <div className='images'>
                <div className='dummy-image'>
                    <span>No Image</span>
                </div>
            </div>
        );
    }
};

const OgpSkelton: React.VFC = () => {
    return (
        <div className='ogp-viewer'>
            <div className='images'>
                <Skeleton.Image className='skelton' />
            </div>

            <div className='contents'>
                <Skeleton active paragraph={{ rows: 2 }} />
            </div>
        </div>
    );
};
import * as React from 'react';

import { Tabs } from 'antd';

import { useFeatures } from 'constants/CustomHooks/useFeatures';
import { useTabManagement } from 'constants/CustomHooks/useTabManagement';

import ExamDeliveryTemplate from '../../templates/ExamDeliveryTemplate';
import { ExamResultTemplate } from '../../templates/ExamResultTemplate';

const { TabPane } = Tabs;


const ControlContents: React.VFC = () => {
    const [tab, setTabActiveKey] = useTabManagement(
        { delivery: 'delivery', result: 'result' },
        'delivery'
    );
    const { studylog: enabledStudyLog } = useFeatures();

    if (!enabledStudyLog) {
        return (
            <ExamDeliveryTemplate
                currentTabKeys={[tab, '']}
                isControllableUser
                isSchoolContents={true}
                onOpenTabCondition={(ck) => ck[0] === 'delivery'}
            />
        );
    }

    return (
        <Tabs
            activeKey={tab}
            onChange={setTabActiveKey}
            type='card'
        >
            <TabPane
                key='delivery'
                tab='テスト配信'
            >
                <ExamDeliveryTemplate
                    currentTabKeys={[tab, '']}
                    isControllableUser
                    isSchoolContents={true}
                    onOpenTabCondition={(ck) => ck[0] === 'delivery'}
                />
            </TabPane>
            <TabPane
                key='result'
                tab='まなびログ'
            >
                <ExamResultTemplate currentTabKey='' />
            </TabPane>
        </Tabs>
    );
};

export default ControlContents;

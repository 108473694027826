import * as React from 'react';
import {
    Breadcrumb as AntdBreadcrumb,
    Button,
    Typography,
} from 'antd';
const { Text } = Typography;

type Props = {
    hierarchy: { uuid: string | undefined, name: string }[],
    style?: React.CSSProperties
    onSearch: (hierarchy: { uuid: string | undefined, name: string }[]) => void
};

const Breadcrumb: React.VFC<Props> = ({
    hierarchy,
    style,
    onSearch,
}) => {
    const Separator = <Text>{'>'}</Text>;
    const breadcrumbItems = hierarchy
        .map((elm, idx) => {
            const isLast = idx === hierarchy.length - 1;
            return (
                <AntdBreadcrumb.Item key={idx}>
                    {isLast ?
                        <div style={{ padding: '5px 16px' }}>{elm.name}</div> :
                        <Button onClick={() => onSearch(hierarchy.slice(0, idx + 1))} type='link'>{elm.name}</Button>
                    }
                </AntdBreadcrumb.Item>
            );
        });

    return (
        <AntdBreadcrumb separator={Separator} style={style}>
            {breadcrumbItems}
        </AntdBreadcrumb>
    );
}

export default Breadcrumb;
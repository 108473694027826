
/***
 * 文字の表現の揺れが多いので、ここでまとめて定義する。コメントには表示される文字を記載している。
 * 以下、オブジェクトの構造。
 * texts.{viewName}. → 例: texts.usersManagement
 * texts.{itemName}.  → 例: texts.targetPermissionsCheckbox
 */
export const texts = {
    // 公開対象権限
    targetPermissionsTitle: '公開対象権限',
    // 公開対象権限のチェックボック名
    targetPermissionsCheckbox: {
        // 学校管理者権限
        administrator: '学校管理者権限',
        // 教員権限
        teacher: '教員権限',
        // 児童生徒権限
        student: '児童生徒権限'
    }
}

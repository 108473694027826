import { VFC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

const COLOR_OF_GRID = '#E5E5E5';
const COLOR_OF_AXIS = '#A6A6A6';
const COLOR_OF_BAR = '#16C2C2';
const COLOR_OF_LOADING = '#004C97';
const LABEL_HEIGHT = 80;
const LOADING_FONT_SIZE = 35;


type Props = {
    dataSources: {
        count: number,
        name: string,
    }[],
    isLoading: boolean
}

export const Graph: VFC<Props> = ({
    dataSources,
    isLoading,
}) => {

    const data = isLoading ? [] : dataSources;
    const isMonthView = dataSources.length > 7;

    const isEmpty = data.filter(e => e.count !== 0).length === 0;

    if (!isLoading && isEmpty) {
        return (
            <div
                style={{
                    alignItems: 'center',
                    color: 'rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    height: '400px',
                    justifyContent: 'center',
                    opacity: 0.75,
                }}
            >
                <Empty
                    description={(
                        <div>
                            <div>データがありません。</div>
                            <div>条件を変えて検索してください。</div>
                        </div>
                    )}
                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                />
            </div >
        );
    }

    return (
        <div
            className='graph'
            style={{
                height: '400px',
                position: 'relative',
            }}
        >
            <ResponsiveContainer>
                { /* eslint-disable sort-keys */}
                <BarChart
                    data={data}
                    // right は月表示のときにラベルが外にはみ出ないように
                    // left は余分なスペースができないように
                    margin={{ top: 5, right: 30, bottom: 5, left: -30 }}
                    style={{ opacity: isLoading ? 0.6 : 1 }}
                >
                    <CartesianGrid stroke={COLOR_OF_GRID} vertical={false} />
                    <XAxis
                        angle={isMonthView ? 45 : 0}
                        axisLine={false}
                        dataKey='name'
                        height={LABEL_HEIGHT}
                        stroke={COLOR_OF_AXIS}
                        textAnchor={isMonthView ? 'start' : 'middle'}
                        tickLine={false}
                    />
                    <YAxis
                        axisLine={false}
                        dataKey='count'
                        minTickGap={1}
                        stroke={COLOR_OF_AXIS}
                        tickLine={false}
                        width={80}
                    />
                    <Bar dataKey='count' fill={COLOR_OF_BAR} />
                </BarChart>
                { /* eslint-enable sort-keys */}
            </ResponsiveContainer>

            {isLoading && <LoadingOutlined
                style={{
                    color: COLOR_OF_LOADING,
                    fontSize: `${LOADING_FONT_SIZE}px`,
                    left: '50%',
                    position: 'absolute',
                    top: `calc(50% - ${LABEL_HEIGHT}px + ${LOADING_FONT_SIZE}px / 2)`,
                }}
            />}
        </div>
    );
};
import React from 'react';

import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

import { ExamWithInfoState } from '../../hooks/useGraphExam';


const gridColor = '#E5E5E5';
const axisColor = '#A6A6A6';
const barColor = '#16C2C2';
const bar2Color = '#FDC63A';
const lineColor = '#004C97';

type Props = {
    height: number
    graph: ExamWithInfoState
    graph2?: ExamWithInfoState
    isAllClass: boolean
}

export const ExamsGraph: React.VFC<Props> = ({
    graph,
    graph2,
    height,
}) => {
    const isSingleView = graph2 === undefined;
    const isLoading = graph.isLoading || (!isSingleView && graph2.isLoading);

    const data = isLoading ?
        [] :
        isSingleView ?
            graph.result.map(({ graph }, i) => {
                return {
                    class: graph.value,
                    name: i + 1,
                    total: graph.total,
                };
            }) :
            graph.result.map(({ graph }, i) => {

                if (graph2.result[i] === undefined) {
                    return {
                        class1: 0,
                        class2: 0,
                        name: i + 1,
                        total: 0,
                    };
                }

                return {
                    class1: graph.value,
                    class2: graph2.result[i].graph.value,
                    name: i + 1,
                    total: graph.total,
                };
            });

    return (
        <div style={{ height: '100%', minHeight: `${height}px`, width: '100%' }}>
            <ResponsiveContainer height='100%' width='100%'>
                <ComposedChart
                    data={data}
                    height={height}
                    // eslint-disable-next-line sort-keys
                    margin={{ top: 30, right: 5, bottom: 5, left: 5 }}
                >
                    <CartesianGrid stroke={gridColor} vertical={false} />
                    <XAxis
                        axisLine={false}
                        dataKey='name'
                        stroke={axisColor}
                        tickLine={false}
                    />
                    <YAxis
                        axisLine={false}
                        domain={[0, 100]}
                        stroke={axisColor}
                        tickLine={false}
                    />


                    {isSingleView ?
                        (
                            <Bar dataKey='class' fill={barColor} name='クラス平均' />
                        ) :
                        (
                            <>
                                <Bar dataKey='class1' fill={barColor} name='対象クラス1' />
                                <Bar dataKey='class2' fill={bar2Color} name='対象クラス2' />
                            </>
                        )
                    }
                    <Line
                        dataKey='total'
                        name='全配信対象者平均'
                        stroke={lineColor}
                        type='monotone'
                    />

                    <Legend
                        formatter={renderBlackLegendText}
                        height={36}
                        iconType='circle'
                        verticalAlign='bottom'
                        wrapperStyle={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};


const renderBlackLegendText = (value: string) => {
    return <span style={{ color: 'black' }}>{value}</span>;
};

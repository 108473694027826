import {
    additionDisplayName,
    generateFileDomain,
    getMoment,
} from 'constants/GlobalConfig'
const moment = getMoment();

export const applicationUtils = {
    convertResponseToApplication: (app) => {
        const files = app.files && app.files.map(file => {
            let thumbUrl = '';
            if (file.hasOwnProperty('mime_type') && file.mime_type.match('image')) {
                thumbUrl = `${generateFileDomain()}/file/view/${file.uuid}`;
            } else {
                thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
            }
            return {
                uuid: file.uuid,
                name: file.file_name,
                file_name: file.file_name, // FileUploaderコンポーネント用
                mimeType: file.mime_type,
                mime_type: file.mime_type, // FileUploaderコンポーネント用
                createdAt: file.created_at,
                uid: Math.floor(Math.random() * 10000),
                status: 'done',
                url: `${generateFileDomain()}/file/view/${file.uuid}`,
                thumbUrl,
            }
        });

        return {
            ...app,
            uuid: app.uuid,
            title: app.title,
            url: app.url,
            description: app.description,
            duration: [
                moment.unix(app.publish_start_at),
                moment.unix(app.publish_end_at),
            ],
            order: app.order,
            openNewWindow: app.open_new_window,
            files: files,
            publishEndAt: app.publish_end_at,
            publishStartAt: app.publish_start_at,
            createdAt: app.created_at,
            updatedAt: app.updated_at,
            isDeliveryTargetsAll: [].length === 0, // TODO: Dummy
        }
    },

    convertResponseToApplicationCategory: (applicationCategoryResponseFormat) => {

        const created_by = additionDisplayName(applicationCategoryResponseFormat.created_by);
        const updated_by = applicationCategoryResponseFormat.updated_by ?
            additionDisplayName(applicationCategoryResponseFormat.updated_by) :
            created_by;

        return {
            uuid: applicationCategoryResponseFormat.uuid,
            order: applicationCategoryResponseFormat.order,
            title: applicationCategoryResponseFormat.title,
            titleForLower: applicationCategoryResponseFormat.title_for_lower,
            description: applicationCategoryResponseFormat.description,
            duration: [
                moment.unix(applicationCategoryResponseFormat.publish_start_at),
                moment.unix(applicationCategoryResponseFormat.publish_end_at),
            ],
            createdAt: applicationCategoryResponseFormat.created_at,
            updatedAt: applicationCategoryResponseFormat.updated_at,
            applications: applicationCategoryResponseFormat.applications
                ? applicationCategoryResponseFormat.applications.map(app => applicationUtils.convertResponseToApplication(app))
                : [],
            publishEndAt: applicationCategoryResponseFormat.publish_end_at,
            publishStartAt: applicationCategoryResponseFormat.publish_start_at,
            targetGrades: applicationCategoryResponseFormat.target_grades?.map(grade => grade.code),
            targetPermissions: applicationCategoryResponseFormat.target_permissions,
            targetOrganizations: applicationCategoryResponseFormat.target_organizations,
            isPublishToAllOrganizations: applicationCategoryResponseFormat.publish_to_all_organizations,
            useDeepLinking: applicationCategoryResponseFormat.use_deep_linking,
            useAgs: applicationCategoryResponseFormat.use_ags,
            canAdminSetPublic: applicationCategoryResponseFormat.can_admin_set_public,
            ltiTemplate: applicationCategoryResponseFormat.lti_template,
            created_by,
            updated_by,
        }
    },

    convertApplicationCategoryToPostBody: (applicationCategory, initialValue, order, target) => {
        return {
            application_template_uuid: applicationCategory.applicationTemplateUuid,
            application_url_value_candidate_uuid: applicationCategory.applicationUrlValueCandidateUuid,
            description: applicationCategory.description,
            is_display_required: applicationCategory.isDisplayRequired,
            order: (initialValue && initialValue.order) ? initialValue.order : order,
            publish_end_at: applicationCategory.duration[1].unix(),
            publish_start_at: applicationCategory.duration[0].unix(),
            roles: applicationCategory.roles,
            target_grades: applicationCategory.targetGrades || [],
            target_organizations: target.target_organizations?.map((value) => value.uuid) || [],
            target_permissions: applicationCategory.targetPermissions?.map((value) => value) || [],
            can_admin_set_public: applicationCategory.canAdminSetPublic,
            publish_to_all_organizations: applicationCategory.isPublishToAllOrganizations,
            title: applicationCategory.title,
            title_for_lower: applicationCategory.titleForLower,
            use_deep_linking: applicationCategory.useDeepLinking,
            use_ags: applicationCategory.useAgs,
        }
    },

    initialValueOfApplicationCategory: {
        applicationTemplateUuid: undefined,
        applicationUrlValueCandidateUuid: undefined,
        canAdminSetPublic: true,
        description: '',
        duration: [
            moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            undefined,
        ],
        isPublishToAllOrganizations: true,
        publishEndAt: null,
        publishStartAt: null,
        targetGrades: [],
        targetOrganizations: [],
        targetPermissions: ['administrator', 'teacher', 'student'],
        title: '',
        titleForLower: '',
        useDeepLinking: false,
        useAgs: false,
    },

    initialValueOfApplication: {
        title: '',
        description: '',
        url: '',
        isCooperationUrl: true,
        duration: [
            moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            undefined,
        ],
        openNewWindow: true,
        files: [],
    }

}

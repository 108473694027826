import { createAction } from 'redux-actions';
import {
    API_USER
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/USERS';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

type DefaultPayload = { method: HttpMethods, api: string };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

type Payload<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultPayload
        : Expand<DefaultPayload & EXTRA>;
type Meta<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultMeta
        : Expand<DefaultMeta & EXTRA>;


type SearchParameter = Http.Connection.Request.Parameter.User.Search;
export const search = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.User.Search,
    Meta<{ viewName: string }>,
    SearchParameter,
    number,
    string
>(
    createActionType('SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '1' : page;
        return ({ method: 'get', data, api: `${API_USER}/${pageNum}` })
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName, data, page })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_USER + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_USER + '/update/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_USER + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deleteAction as delete };

export const deleteFromCsv = createAction(
    createActionType('DELETE_FROM_CSV' as const),
    (query, contentType, data) => ({
        method: 'import',
        api: API_USER + `/delete-from-csv?${query}`,
        data,
        contentType: contentType,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const importAction = createAction(
    createActionType('IMPORT' as const),
    (query, contentType, data) => ({
        method: 'import',
        api: `${API_USER}/import-csv?${query}`,
        data,
        contentType: contentType
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { importAction as import };

const exportAction = createAction(
    createActionType('EXPORT' as const),
    (data) => {
        return ({ method: 'get', data, api: `${API_USER}/download-csv` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { exportAction as export };

export const syncPassword = createAction(
    createActionType('SYNC_PASSWORD' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_USER + '/sync-password/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const updateRenewClass = createAction(
    createActionType('UPDATE_RENEW_CLASS' as const),
    (term_uuid, data) => ({
        method: 'post',
        api: API_USER + '/update-renew-class',
        data: {
            ...data,
            term_uuid,
        }
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const multipleUpdateFlag = createAction(
    createActionType('MULTIPLE_UPDATE_FLAG' as const),
    (data: Http.Connection.Request.Parameter.User.MultipleUpdateFlag) => ({
        method: 'post',
        api: `${API_USER}/multiple-update-flag`,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const accountUnlock = createAction(
    createActionType('ACCOUNT_UNLOCK' as const),
    (data: Http.Connection.Request.Parameter.User.AccountUnlock) => ({
        method: 'post',
        api: API_USER + '/account-unlock/' ,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const view = createAction<
    Payload | Http.Connection.Response.User.View,
    Meta<{ viewName: string }>,
    string,
    string
>(
    createActionType('VIEW' as const),
    (uuid: string, _viewName) => ({
        method: 'get',
        api: `${API_USER}/view/${uuid}`,
    }),
    (_uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const downloadSimpleCsv = createAction<
    Payload,
    Meta
>(
    createActionType('DOWNLOAD_SIMPLE_CSV' as const),
    (data) => ({
        method: 'get',
        data,
        api: `${API_USER}/download-simple-csv`,
    }),
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const unsetPlannedToDelete = createAction(
    createActionType('UNSET_PLANNED_TO_DELETE' as const),
    (uuid: string) => ({
        api: `${API_USER}/unset-planned-to-delete`,
        data: { uuid },
        method: 'post',
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
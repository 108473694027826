import { useEffect, useState } from 'react';
import * as React from 'react';

import { statusEnum } from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import Template from '../Template';
import ConfirmAnswerResultModal from '../Template/Modals/ConfirmAnswerResultModal';
import QuestionnaireModal from '../Template/Modals/QuestionnaireModal';
import { convertControlFormValue } from '../utils/convertFormValue';
import { genConvertRequestableSearchCondition } from '../utils/convertRequestableSearchCondition';
import { useDeleteQuestionnaire } from '../utils/CustomHooks/useDeleteQuestionnaire';
import { useEditQuestionnaire } from '../utils/CustomHooks/useEditQuestionnaire';
import { useSearchQuestionnaire } from '../utils/CustomHooks/useSearchQuestionnaire';
import { useViewQuestionnaire } from '../utils/CustomHooks/useViewQuestionnaire';
import { convertSubmittableValue } from './utils/convertSubmittableValue';

const INIT_SEARCH_CONDITION = {
    name: '',
    page: 1,
    pageSize: 50,
};

const ControlContents: React.VFC = () => {
    const [
        searchQuestionnaire,
        fetchSearchQuestionnaire,
    ] = useSearchQuestionnaire();
    const [
        viewQuestionnaire,
        fetchViewQuestionnaire,
        resetViewQuestionnaire,
    ] = useViewQuestionnaire();
    const [
        deleteResult,
        deleteQuestionnaire,
        resetDeleteResult,
    ] = useDeleteQuestionnaire();
    const [
        editQuestionnaire,
        createQuestionnaire,
        updateQuestionnaire,
        resetEditQuestionnaire,
    ] = useEditQuestionnaire();
    const [isCopy, setIsCopy] = useState(false);
    const [searchCondition, setSearchCondition] = useState<EverydayNote.SearchConditions>(INIT_SEARCH_CONDITION);
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const [visibleResultModal, setVisibleResultModal] = useState(false);
    const [questionnaire, setQuestionnaire] = useState<EverydayNote.ControlQuestionnaire>();
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const tenant = useAppSelector(state => state.tenant.payload);

    const handleAnswerResultClick = (questionnaire: EverydayNote.ControlQuestionnaire) => {
        setVisibleResultModal(true);
        setQuestionnaire(questionnaire);
    };

    const handleChangeSearchMode = () => {
        setSearchCondition(condition => {
            const { name, page, pageSize } = condition;
            return {
                name,
                page,
                pageSize,
            };
        });
    };

    const handleAnswerResultClose = () => {
        setVisibleResultModal(false);
    };

    const handleCopyClick = (questionnaire: EverydayNote.ControlQuestionnaire) => {
        fetchViewQuestionnaire(questionnaire);
        setIsCopy(true);
    };

    const handleCreateClick = () => setVisibleEditModal(true);

    const handleDeleteClick = (uuid: string) => {
        deleteQuestionnaire(uuid);
    };

    const handleEditCancel = () => {
        setVisibleEditModal(false);
        setIsCopy(false);
        resetViewQuestionnaire();
    };

    const handleOk = (values: EverydayNote.FormValue) => {
        if (!('canAdminSetPublic' in values)) return;

        // 型推論がうまくいかないので、 submitValue を完全に分離している。
        const submitValue = convertSubmittableValue(values, isCopy);

        if ('uuid' in submitValue && submitValue.uuid !== undefined) {
            updateQuestionnaire(submitValue);
        } else {
            createQuestionnaire(submitValue);
        }
    };

    const handleSearch = (condition?: Partial<EverydayNote.SearchConditions>) => {
        const newCondition = {
            ...searchCondition,
            ...condition,
        };

        setSearchCondition(newCondition);
        fetchSearchQuestionnaire(newCondition);
    };

    const handleUpdateClick = fetchViewQuestionnaire;

    useEffect(() => {
        fetchSearchQuestionnaire(INIT_SEARCH_CONDITION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!viewQuestionnaire.isLoading) return;

        setVisibleEditModal(true);
    }, [viewQuestionnaire.isLoading]);

    useEffect(() => {
        if (!deleteResult.item) return;

        handleSearch({ page: 1 });
        resetDeleteResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteResult, resetDeleteResult]);

    useEffect(() => {
        const result = editQuestionnaire.result;

        if (result?.status !== statusEnum.SUCCESS) return;

        if (result?.isSuccessful) {
            setVisibleEditModal(false);
            setIsCopy(false);
            resetViewQuestionnaire();
            resetEditQuestionnaire();
            handleSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editQuestionnaire]);

    const isModalLoading = viewQuestionnaire.isLoading || editQuestionnaire.isLoading;

    const modalFormValue = React.useMemo(() => {
        if (viewQuestionnaire.isLoading) return undefined;
        if (!viewQuestionnaire.item) return undefined;
        if (!('canAdminSetPublic' in viewQuestionnaire.item)) return undefined;

        return convertControlFormValue(viewQuestionnaire.item, isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewQuestionnaire.isLoading, visibleEditModal]);

    const convertRequestableSearchCondition = genConvertRequestableSearchCondition(isControllableUser);

    return (
        <>
            <Template<EverydayNote.ControlQuestionnaire>
                currentPage={searchCondition.page}
                // 一旦、型を適切に表現するのが難しいので型アサーションで対応
                dataSource={searchQuestionnaire.items as EverydayNote.ControlQuestionnaire[]}
                initialPagination={{
                    current: INIT_SEARCH_CONDITION.page,
                    pageSize: INIT_SEARCH_CONDITION.pageSize,
                }}
                isControllableUser={isControllableUser}
                loading={searchQuestionnaire.isLoading}
                onAnswerResultClick={handleAnswerResultClick}
                onChangeSearchMode={handleChangeSearchMode}
                onCopyClick={handleCopyClick}
                onCreateClick={handleCreateClick}
                onDeleteClick={handleDeleteClick}
                onSearch={handleSearch}
                onUpdateClick={handleUpdateClick}
                totalDataCount={searchQuestionnaire.itemCount}
            />

            <QuestionnaireModal
                isAnswered={isCopy ? false : !!viewQuestionnaire.item?.isLocked}
                isCopy={isCopy}
                loading={isModalLoading}
                onCancel={handleEditCancel}
                onOk={handleOk}
                questionnaire={modalFormValue}
                visible={visibleEditModal}
            />

            <ConfirmAnswerResultModal
                convertRequestableSearchCondition={convertRequestableSearchCondition}
                loading={false}
                onClose={handleAnswerResultClose}
                questionnaire={questionnaire}
                tenant={tenant}
                visible={visibleResultModal}
            />
        </>
    );
};

export default ControlContents;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { additionDisplayName } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

export type SearchConditions = Expand<PartOfRequired<
    Http.Connection.Request.Parameter.ExamCategory.Search,
    'page_size'
> & {
    mode: 'simple' | 'full'
    page?: number
}>;

export type SearchExamCategoryState = {
    itemCount: number
    items: Admin.ExamCategory[] | Control.ExamCategory[]
    pageSize: number
    pageTotal: number
    isLoading: boolean
};

export const useSearchExamCategory = () => {
    const [state, setState] = useState<SearchExamCategoryState>({
        isLoading: false,
        itemCount: 0,
        items: [],
        pageSize: 10,
        pageTotal: 0,
    });

    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const taoExamCategorySearchConnection = useAppSelector(state => state.taoExamCategorySearchConnection);
    const taoExamCategoryControlSearchConnection = useAppSelector(state => state.taoExamCategoryControlSearchConnection);

    const dispatch = useDispatch();


    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategorySearchConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const examCategoryList = result.items.map(convertToAdminExamCategoryFormat);

                setState({
                    isLoading: false,
                    itemCount: result.item_count,
                    items: examCategoryList,
                    pageSize: result.page_size,
                    pageTotal: result.page_total,
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategorySearchConnection]);

    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryControlSearchConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const examCategoryList = result.items.map(convertControlExamCategoryFormat);

                setState({
                    isLoading: false,
                    itemCount: result.item_count,
                    items: examCategoryList,
                    pageSize: result.page_size,
                    pageTotal: result.page_total,
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlSearchConnection]);


    const fetchData = useCallback((
        search: SearchConditions,
        requestType?: 'control' | 'default'
    ) => {
        const { mode, page, ...restSearch } = search;

        setState({
            isLoading: true,
            itemCount: 0,
            items: [],
            pageSize: restSearch.page_size,
            pageTotal: 0,
        });

        const data = mode === 'simple' ?
            { page_size: restSearch.page_size, title: restSearch.title } :
            restSearch;

        const searchAction = isControllableUser || requestType === 'control' ?
            Actions.http.connection.tao.examControlCategory.controlSearch :
            Actions.http.connection.tao.examCategory.search;

        dispatch(searchAction(data, page, ''));

    }, [dispatch, isControllableUser]);

    return [state, fetchData] as const;
};

type ResponseControlExamCategory = Http.Connection.Response.ExamCategory.ControlSearch['result']['items'][number];
type ResponseExamCategory = Http.Connection.Response.ExamCategory.Search['result']['items'][number];
type ResponseExam = ResponseExamCategory['exams'][number];


/**
 * レスポンスに含まれる Exam を
 * プログラムで使いやすい形式に変換する
 */
const convertToExamFormat = (exam: ResponseExam): Exam => ({
    ...exam,
    created_by: additionDisplayName(exam.created_by),
    max_attempts: exam.max_attempts ?? 0,
});

/**
 * レスポンスに含まれる ExamCategory を
 * プログラムで使いやすい形式に変換する
 */
const convertToAdminExamCategoryFormat = (examCategory: ResponseExamCategory) => {
    const exams = examCategory.exams.map(convertToExamFormat);
    const created_by = additionDisplayName(examCategory.created_by);
    const updated_by = examCategory.updated_by ?
        additionDisplayName(examCategory.updated_by) :
        created_by;

    return {
        ...examCategory,
        created_by,
        exams,
        updated_by,
    };
};

/**
 * レスポンスに含まれる ControlExamCategory を
 * プログラムで使いやすい形式に変換する
 */
const convertControlExamCategoryFormat = (examCategory: ResponseControlExamCategory) => {
    const exams = examCategory.exams.map(convertToExamFormat);
    const created_by = additionDisplayName(examCategory.created_by);
    const updated_by = examCategory.updated_by ?
        additionDisplayName(examCategory.updated_by) :
        created_by;

    return {
        ...examCategory,
        created_by,
        exams,
        target_organizations: examCategory.target_organizations ?? [],
        target_term: examCategory.target_term?.uuid ?? '',
        updated_by,
    };
};

import { StatusEnum } from 'flex/actions/http/StatusEnum';


/**
 * 文字列型に含まれるハイフンをアンダースコアに変換する
 */
type HyphenToUnderScore<S extends string> = S extends `${infer F}-${infer R}` ?
    `${F}_${HyphenToUnderScore<R>}` :
    S;

/**
 * 先頭のスラッシュを削除した型に変換する
 */
type PickHeadSlash<S extends string> = S extends `/${infer T}` ?
    HyphenToUnderScore<T> :
    S;

/**
 * kebabCase を ScreamingSnakeCase に変換する
 */
const kebabToScreamingSnakeCase = <S extends string>(s: S) => {
    return s.replace(/-/g, '_').toUpperCase() as Uppercase<HyphenToUnderScore<S>>;
};

/**
 * endpoint の形式の文字列を ScreamingSnakeCase に変換する
 */
const endpointToScreamingSnakeCase = <S extends string>(s: S) => {
    return kebabToScreamingSnakeCase(s.replace('/', '')  as PickHeadSlash<S>);
};

/**
 * Prefix 付きの ActionType を作成する
 */
// 本来はすべてカリー化された状態にしたかったが、
// カーソルを当てた際の型推論が中途半端になるため、一部のみカリー化している
const actionTypeCreator =
    <Category extends string>(category: Category) =>
        <Permission extends 'CONTROL' | 'ADMIN'>(permission: Permission) =>
            <API extends `/${string}`, Action extends string>(api: API, action: Action) => {
                const feature = endpointToScreamingSnakeCase(api);
                const action2 = kebabToScreamingSnakeCase(action);

                type ActionType = `${Category}/${Permission}/${typeof feature}/${typeof action2}`;

                return `${category}/${permission}/${feature}/${action2}` as ActionType;
            };

/**
 * prefix `CONNECTION/CONTROL/` 付きの ActionType を作成する
 */
export const controlActionTypeCreator = actionTypeCreator('CONNECTION')('CONTROL');
/**
 * prefix `CONNECTION/ADMIN/` 付きの ActionType を作成する
 */
export const adminActionTypeCreator = actionTypeCreator('CONNECTION')('ADMIN');

export const metaCreator = () => ({
    fetch: true,
    status: StatusEnum.REQUEST,
});

type DefaultPayload = { api: string, method: HttpMethods };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

export type Payload<EXTRA extends Record<string, unknown> = Record<string, never>> =
    EXTRA extends Record<string, never> ?
        DefaultPayload :
        Expand<DefaultPayload & EXTRA>;
export type Meta<EXTRA extends Record<string, unknown> = Record<string, never>> =
    EXTRA extends Record<string, never> ?
        DefaultMeta :
        Expand<DefaultMeta & EXTRA>;

import { reducer } from './reducer';
import { savingDataForConnection } from './savingDataForConnection';
import * as selectors from './selectors';
import { viewLogic } from './viewLogic';
import {
    PageSizeOptions,
} from 'constants/GlobalConfig';

export const DEFAULT_STATE = {
    classListMap: {},
    classListMapItemCount: {},
    classListMapListCount: 0,
    editUser: {
        loading: true,
        isEditOpen: false,
        shouldCreate: false,
        userRecord: null,
    },
    pagination: {
        total: 0,
        position: ["topRight", "bottomRight"],
        showSizeChanger: true,
        pageSizeOptions: PageSizeOptions,
        pageSize: 50,
        current: 1,
    },
    roleList: [],
    roleListItemCount: 0,
    search: { term_uuid: '', school_class_uuid: undefined, role_uuid: undefined },
    selectOrganization: undefined,
    selectTerm: undefined,
    /** 選択されているすべての行 */
    selectedRows: [],
    /** 直近で選択されている行 */
    currentSelectedRows: [],
    type: 'userManagementTab',
    userListLoading: false,
    usersList: [],
    usersListItemCount: 0,
    multipleReset: {
        isResetClass: undefined,
        isResetName: undefined,
    },
} as UserManagementTabState;

export const userManagementTabFlex = {
    reducers: reducer,
    savingDataForConnection,
    selectors,
    viewLogic,
    defaultState: DEFAULT_STATE,
};
import React, { useState, useEffect, useMemo } from 'react';

import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';

import BaseModal from 'components/modules/BaseModal';
import { getIndefiniteTerm } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';

import UsersTab from './UserTab';


/** 対象選択モーダルのタブ キー */
const TabKeyEnum = {
    USER: '/user',
};

type Props = {
    currentTarget: User[],
    onCancel: () => void,
    onSubmit: (values: User[]) => void,
    organizationUuid?: string,
    visible: boolean,
};

const MainTeacher: React.VFC<Props> = ({
    currentTarget,
    onCancel,
    onSubmit,
    organizationUuid,
    visible = false,
}) => {
    const [selectedList, setSelectedList] = useState<User[]>([]);

    const termList = useAppSelector(state => state.globalState.termList);
    const byTerm = useMemo(() => createFilterByTerm(termList), [termList]);

    useEffect(() => {
        if (!visible) return;

        setSelectedList(currentTarget);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (visible) return;

        setSelectedList([]);
    }, [visible]);

    function handleSubmit() {
        onSubmit(selectedList);
    }

    /**
     * 主担任選択では、
     * * 管理者でも教員でもないユーザー、
     * * 無効なユーザー
     * * 削除予定のユーザー
     *
     * を選択できないようにする
     */
    function getCheckboxDisabled(user: User, selectTerm: string) {
        const isDisabledPermission = user.belongs
            .filter(byTerm(selectTerm))
            ?.every(areNotPermissions(['administrator', 'teacher']));

        const isUseless = !user.is_active || user.is_planned_to_delete;

        return isDisabledPermission || isUseless;
    }

    return (
        <BaseModal
            className='common-modal'
            footer={[
                <Button key='back' onClick={onCancel} size='large'>キャンセル</Button>,
                <Button
                    key='create'
                    onClick={handleSubmit}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><TeamOutlined /> 対象を選択</span>}
            visible={visible}
        >
            <Tabs activeKey={TabKeyEnum.USER} size='small'>
                <Tabs.TabPane
                    key={TabKeyEnum.USER}
                    tab={<span><UserOutlined /> 主担任</span>}
                >
                    <UsersTab
                        currentUserList={currentTarget}
                        getCheckboxDisabled={getCheckboxDisabled}
                        onSave={setSelectedList}
                        organizationUuid={organizationUuid}
                        selectLimit={1}
                    />
                </Tabs.TabPane>
            </Tabs>
        </BaseModal>
    );
};

export default MainTeacher;

/***
 * 検索対象の年度もしくは、無期限の年度の所属で filter する
 */
const createFilterByTerm = (termList: Term[]) => (term: string | undefined) => {
    const infiniteTerm = getIndefiniteTerm(termList).uuid;
    return (val: UserBelong) => term === undefined || term === '' ?
        true :
        val.school_class.term.uuid === term || val.school_class.term.uuid === infiniteTerm;
};

/**
 * すべてが administrator でも teacher でもないことを every で確認するための関数を返す
 */
const areNotPermissions = (permissions: Permission['code'][]) => (belong: UserBelong) => {
    return !permissions.includes(belong.role.permission_code as Permission['code']);
};
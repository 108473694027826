import * as Actions from 'flex/Actions';

export const questionnaireCategoryTabViewLogic = (store, next, action) => {
    const tabState = store.getState()['questionnaireCategoryTab'];
    const pagination = tabState['pagination'];
    const search = tabState['search'];
    const isControllableUser = store.getState().isControllableUser;

    switch (action.type) {
        case Actions.view.viewLogic.questionnaireCategoryTab.prepareView.toString():
            store.dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submitSearch({
                name: undefined,
                description: undefined,
            }))

            next({
                ...tabState,
                pagination: {
                    ...pagination,
                    current: 1,
                    page_size: 50,
                },
                type: 'questionnaireCategoryTab'
            });
            break;
        case Actions.view.viewLogic.questionnaireCategoryTab.submitSearch.toString():
            next(action)
            const values = action.payload.values
            let page = pagination.current

            // 前回と検索内容が異なった場合は、ページを1に変更する
            if(values && (values.name !== search.name || values.description !== search.description)){
                page = 1
                next(Actions.view.viewLogic.questionnaireCategoryTab.savePage(1, pagination.pageSize))
            }

            const searchAction = isControllableUser.payload ?
                Actions.http.connection.questionnaireCategory.controlSearch :
                Actions.http.connection.questionnaireCategory.search;

            next(searchAction(
                {
                    ...search,
                    ...values,
                    page_size: pagination.pageSize
                },
                page,
                'questionnaireCategoryTab'
            ));
            break;
        case Actions.view.viewLogic.questionnaireCategoryTab.submitPage.toString(): {
            next(Actions.view.viewLogic.questionnaireCategoryTab.savePage(action.payload.page,  action.payload.page_size))

            const searchAction = isControllableUser.payload ?
                Actions.http.connection.questionnaireCategory.controlSearch :
                Actions.http.connection.questionnaireCategory.search;

            next(searchAction(
                {
                    ...search,
                    page_size: action.payload.page_size || 50
                },
                action.payload.page,
                'questionnaireCategoryTab'
            ));
            break;
        }
        case Actions.view.viewLogic.questionnaireCategoryTab.submitDelete.toString():
            const deleteAction = isControllableUser.payload ?
                Actions.http.connection.questionnaireCategory.controlDelete :
                Actions.http.connection.questionnaireCategory.delete;

            next(deleteAction(action.payload.uuid))
            break;
        case Actions.view.viewLogic.questionnaireCategoryTab.submittedEdit.toString():
            store.dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submitSearch())
            break;
        default:
            next(action)
            break;
    }
}

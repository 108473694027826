import {
    getMoment,
} from 'constants/GlobalConfig';
const moment = getMoment();

/**
 * 毎日の記録で使う term をすべて持つオブジェクト
 */
export const TERM_TYPE = {
    Month: 'month' as TermType.Month,
    WEEK: 'week' as TermType.Week,
    DATE: 'date' as TermType.Date,
};

/**
 * 毎日の記録で使う term とその日本語表現を持つオブジェクトのリスト
 */
export const TERM_TYPE_LIST = [
    {
        value: TERM_TYPE.Month,
        text: '月',
    },
    {
        value: TERM_TYPE.WEEK,
        text: '週',
    },
    {
        value: TERM_TYPE.DATE,
        text: '日',
    },
];

/**
 * 毎日の記録で使う TermType の Union Type
 */
type TermTypes = (typeof TERM_TYPE)[keyof typeof TERM_TYPE];

/**
 * termType を moment で使える形式の変換する
 */
const convertTermTypeToMomentTermType = (termType: TermTypes) => {
    return termType === TERM_TYPE.Month ? 'months' :
        termType === TERM_TYPE.WEEK ? 'weeks' :
            termType === TERM_TYPE.DATE ? 'days' :
                undefined;
};

/**
 * term の 前 termType の日付(week なら前週)を取得する
 */
export const getPrevTerm = (term: moment.Moment, termType: TermTypes) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    return moment(term).clone().subtract(1, momentTermType);
};

/**
 * term の翌 termType の日付(week なら翌週)を取得する
 */
export const getNextTerm = (term: moment.Moment, termType: TermTypes) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    return moment(term).clone().add(1, momentTermType);
};

/**
 * term の 前 termType の日付(week なら前週)が、2020/01/01 を超えないか確認する
 */
export const canGetPrevTerm = (term: moment.Moment, termType: TermTypes) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    if (momentTermType === undefined) return false;
    const prevTerm = getPrevTerm(term, termType);
    const inf = moment('2020/1/1', 'YYYY/M/D').subtract(1, momentTermType).startOf(momentTermType);
    return inf < prevTerm;
};

/**
 * term の翌 termType の日付(week なら翌週)が、当日の翌 termType を超えないか確認する
 */
export const canGetNextTerm = (term: moment.Moment, termType: TermTypes) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    if (momentTermType === undefined) return false;
    const nextTerm = getNextTerm(term, termType);
    const sup = moment().add(1, momentTermType).startOf(momentTermType);
    return nextTerm < sup;
};

/**
 * value を含む 1 termType で表現した文字列を返す関数を作成する
 */
export const formatFuncCreator = (termType: TermTypes) => (term: moment.Moment) => {
    const value = moment(term).clone();

    switch (termType) {
        case TERM_TYPE.Month:
            return value.format('YYYY/MM');
        case TERM_TYPE.WEEK:
            const start = moment(value).startOf(TERM_TYPE.WEEK).format('YYYY/MM/DD');
            const end = moment(value).endOf(TERM_TYPE.WEEK).format('YYYY/MM/DD');
            return `${start} ~ ${end}`;
        case TERM_TYPE.DATE:
            return value.format('YYYY/MM/DD');
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = termType;
            return '';
    }
};

/**
 * よりユーザーフレンドリーな形式で value を含む 1 termType で表現した文字列を返す関数を作成する
 *
 * 関数を返す形になっているのは、近くに定義された関数と似た形にするため
 */
export const formatUserFriendlyFuncCreator = (termType: TermTypes) => (term: moment.Moment) => {
    const value = moment(term).clone();

    switch (termType) {
        case TERM_TYPE.Month:
            return value.format('YYYY年MM月');
        case TERM_TYPE.WEEK:
            const startAt = moment(value).startOf(TERM_TYPE.WEEK);
            const endAt = moment(value).endOf(TERM_TYPE.WEEK);

            const endOfYear = startAt.format('YYYY') === endAt.format('YYYY') ? '' : endAt.format('YYYY年');
            const endOfMonth = startAt.format('MM') === endAt.format('MM') ? '' : endAt.format('MM月');

            const startTerm = startAt.format('YYYY年MM月DD日');
            const endTerm = `${endOfYear}${endOfMonth}${endAt.format('DD日')}`;

            return `${startTerm} - ${endTerm}`;
        case TERM_TYPE.DATE:
            return value.format('YYYY年MM月DD日');
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = termType;
            return '';
    }
}
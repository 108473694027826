import 'moment/locale/ja'
import moment from 'moment';
import { API_LTI_LAUNCH } from './endpoints';

export const getMoment = () => {
    moment.updateLocale('ja', {
        week: {
            dow: 1,
        }
    });
    return moment;
};

export const getDefaultLayout = (isLonglabel, isVertical) => {
    if (isVertical) {
        return {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };
    } else {
        return {
            labelCol: {
                sm: { span: 24 },
                md: { span: 24 },
                xl: { span: isLonglabel ? 8 : 4 },
            },
            wrapperCol: {
                sm: { span: 24 },
                md: { span: 24 },
                xl: { span: isLonglabel ? 16 : 20 },
            },
        };
    }
};

/***
 * AntdのForm.Itemのruleを作成する
 ***/
export const formRules = {
    range: ({ label = '', max = 0, min = 0 }) => {
        return (label && max && min) ? { message: `${label}は、${min}文字以上${max}文字以下で入力してください`, max, min }
            : (label && max) ? { message: `${label}は、${max}文字以下で入力してください`, max }
                : (label && min) ? { message: `${label}は、${min}文字以上で入力してください`, min }
                    : (max && min) ? { message: `${min}文字以上${max}文字以下で入力してください`, max, min }
                        : (max) ? { message: `${max}文字以下で入力してください`, max }
                            : (min) ? { message: `${min}文字以上で入力してください`, min }
                                : {}
    },
    required: ({ label = '', isSelect = false }) => {
        return label ? { message: `${label}を${isSelect ? '選択' : '入力'}してください`, required: true } : { message: `必須項目です`, required: true };
    },
    requiredDuration: ({ label }) => ({
        required: true,
        validator: (_, duration) => {
            if (!duration || duration.length < 2 || !duration[0] || !duration[1]) {
                return Promise.reject(new Error(`${label}を入力してください`));
            }
            return Promise.resolve();
        },
    }),
    targetValidator: (message = '配信対象を選択してください', fieldName = 'target') => (form) => ({
        validator: (_, value) => {
            /*** @type Target */
            const target = value;
            if (target?.length > 0 || target.target_organizations?.length > 0 || target.target_roles?.length > 0 ||
                target.target_school_classes?.length > 0 || target.target_users?.length > 0) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error(message));
            }
        },
    }),
    targetGradesValidator: (form) => ({
        validator: (_, targetGrades) => {
            if (targetGrades.every(grade => typeof grade === 'string')) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error('公開対象学年コードを選択してください'));
            }
        },
    }),
    typeUrl: label => ({
        pattern: /https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/,
        message: `正しい${label ?? 'URL'}を入力してください`,
    }),
    createUrlSizeValidator: (label) => () => ({
        validator: (_, value) => {
            if (value && encodeURI(value).length > 1999) {
                const encodedUrlSize = encodeURI(value).length;
                return Promise.reject(new Error(
                    `${label}は、エンコード後も1999文字以下になるよう入力してください。(現在:${encodedUrlSize}文字)`
                ));
            }
            return Promise.resolve();
        },
    }),
    passwordCharsetValidator: () => ({
        pattern: /^[a-zA-Z\d!?_+*'"`#$%&\-^\\@;:./=~|[\](){}<>]+$/,
        message: 'パスワードに使用できない文字が含まれています',
    }),
    passwordLengthValidator: () => ({
        message: 'パスワードは8~72文字で入力してください',
        max: 72,
        min: 8
    }),
};

export const applicationFormRules = {
    applicationCategoryTitleRange: () => {
        return { message: '129文字以上入力できません', max: 128 };
    },
    applicationCategoryDescriptionRange: () => {
        return { message: '129文字以上入力できません', max: 128 };
    },
};

export const validations = {
    /***
     * エンコード後も指定の文字数以内になるかチェックし、文字数を超えればエラーをセットする
     * @param {string} name
     * @param {string} label
     * @param {number} max
     * @param {object} form
     * @param {string} url
     * @return {boolean}
     */
    isValidUrl: (name, label, max, form, url) => {
        if (url && encodeURI(url).length > max) {
            const encodedUrlSize = encodeURI(url).length;
            form.setFields([{ name: name, errors: [`${label}は、エンコード後も${max}文字以下になるよう入力してください。(現在:${encodedUrlSize}文字)`] }]);
            return false;
        }
        return true;
    }
};

export const getDefaultFeatures = () => {
    return {
        'action-log': false,
        application: true,
        'everyday-note': true,
        'hide-user-name': false,
        information: true,
        mexcbt: false,
        questionnaire: false,
        'studylog': false,
        sync: true,
        'sync-group': true,
        tao: false,
    };
};

export const sanitizeSpecialCharacter = (str) => {
    //「<」→「&lt;」 「>」→「&gt;」 「&」→「&amp;」 「'」→「&quot;」 「'」→「&#39;」
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/'/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
};

export const getExtension = (filename) => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
};

export const getNameFromGradeCode = (code) => {
    switch (code) {
        case 'P':
            return '小学校'
        case 'J':
            return '中学校';
        case 'PJ':
            return '義務教育学校';
        case 'JH':
            return '中等教育学校';
        case 'H':
            return '高等学校（学年制）';
        case 'U':
            return '高等学校（単位制）';
        case 'S':
            return '特別支援学校';
        case 'A':
            return 'その他';
        default:
            return '';
    }
};

export const defaultGradePreset = [
    {
        'code': '01',
        'name': '単位制高等学校第1年次',
        'is_lower': false
    },
    {
        'code': '02',
        'name': '単位制高等学校第2年次',
        'is_lower': false,
    },
    {
        'code': '03',
        'name': '単位制高等学校第3年次',
        'is_lower': false
    },
    {
        'code': '04',
        'name': '単位制高等学校第4年次',
        'is_lower': false
    },
    {
        'code': '05',
        'name': '単位制高等学校第5年次',
        'is_lower': false
    },
    {
        'code': '06',
        'name': '単位制高等学校第6年次',
        'is_lower': false
    },
    {
        'code': '07',
        'name': '単位制高等学校第7年次',
        'is_lower': false
    },
    {
        'code': '08',
        'name': '単位制高等学校第8年次',
        'is_lower': false
    },
    {
        'code': '09',
        'name': '単位制高等学校第9年次',
        'is_lower': false
    },
    {
        'code': '10',
        'name': '単位制高等学校第10年次',
        'is_lower': false
    },
    {
        'code': 'H1',
        'name': '学年制高等学校第1年生',
        'is_lower': false
    },
    {
        'code': 'H2',
        'name': '学年制高等学校第2年生',
        'is_lower': false
    },
    {
        'code': 'H3',
        'name': '学年制高等学校第3年生',
        'is_lower': false
    },
    {
        'code': 'H4',
        'name': '学年制高等学校第4年生',
        'is_lower': false
    },
    {
        'code': 'J1',
        'name': '中学第1年生',
        'is_lower': false
    },
    {
        'code': 'J2',
        'name': '中学第2年生',
        'is_lower': false,
    },
    {
        'code': 'J3',
        'name': '中学第3年生',
        'is_lower': false,
    },
    {
        'code': 'zz',
        'name': '学年無し',
        'is_lower': false,
    },
    {
        'code': 'P1',
        'name': '小学第1年生',
        'is_lower': true,
    },
    {
        'code': 'P2',
        'name': '小学第2年生',
        'is_lower': true,
    },
    {
        'code': 'P3',
        'name': '小学第3年生',
        'is_lower': false,
    },
    {
        'code': 'P4',
        'name': '小学第4年生',
        'is_lower': false,
    },
    {
        'code': 'P5',
        'name': '小学第5年生',
        'is_lower': false,
    },
    {
        'code': 'P6',
        'name': '小学第6年生',
        'is_lower': false,
    },
];

/***
 * permission_codeから、権限名を返します。
 * @param {string} permissionCode
 * @return {string}
 */
export const getPermissionNameFromCode = (permissionCode) => {
    switch (permissionCode) {
        case 'student': return '児童生徒権限';
        case 'teacher': return '教員権限';
        case 'administrator': return '学校管理者権限'
        default:
            return '権限不明';
    }
};

export const getCurrentTerm = (termList) => {
    if (termList.length) {
        return termList.filter(v => v.is_current)[0];
    } else {
        return { uuid: undefined };
    }
};

/** 無期限の年度かどうか  */
export const isInfiniteTerm = (term) => term ? term.name === '無期限' : false;

export const getIndefiniteTerm = (termList) => {
    if (termList.length) {
        return termList.filter(term => isInfiniteTerm(term))[0];
    } else {
        return { uuid: undefined };
    }
};

// すべての年度を選択したときに使われるUUID
export const ALL_TERM_UUID = '';
export const ALL_TERM_MAP_KEY = 'all_term_map_key';

/***
 * 与えた年度リストに、一番目に「すべての年度」を付け加え、二番目に「無期限」を移動して返す
 * @param {{
 *           'uuid': string,
 *           'name': string,
 *           'start_at': number,
 *           'end_at': number,
 *           'created_at': number,
 *           'updated_at': number
 *       }[]} termList
 */
export const addAllTermAndSort = (termList) => {
    if (termList.length === 0) return termList;
    const compareInfiniteHead = (a, b) => {
        if (a.name === '無期限') return -1
        if (b.name === '無期限') return 1
        return 0;
    };
    const newTermList = termList.slice();

    newTermList.sort(compareInfiniteHead);
    newTermList.unshift({ uuid: ALL_TERM_UUID, name: 'すべての年度', created_at: 0, start_at: 0, end_at: 0, updated_at: 0 });
    return newTermList;
};

/***
 * term_uuidをClassListMapのためのmapKeyに変換する。
 * すべての年度が空のため、その対策
 * @param {string} term_uuid
 * @return {*|string}
 */
export const toClassListMapKey = (term_uuid) => {
    return term_uuid !== ALL_TERM_UUID ? term_uuid : ALL_TERM_MAP_KEY;
};

export const Validation = {
    NotificationTitleMaxCount: 30,
    NotificationTitleMessage: 'お知らせのタイトルを３０文字以内で入力してください'
};

export const PageSizeOptions = ['50', '100', '200'];

/**
 * 日時選択時の共通選択肢
 * @return {{
        '今日まで': [moment.Moment, moment.Moment],
        '明日まで': [moment.Moment, moment.Moment],
        '7日間': [moment.Moment, moment.Moment],
        '30日間': [moment.Moment, moment.Moment],
        '今月末まで': [moment.Moment, moment.Moment],
        '来月末まで': [moment.Moment, moment.Moment],
    }}
 */
export const DatePickerRanges = () => {
    return ({
        '今日まで': [moment(), moment().endOf('day')],
        '明日まで': [moment(), moment().add(1, 'days').endOf('day')],
        '7日間': [moment(), moment().add(7, 'days').endOf('day')],
        '30日間': [moment(), moment().add(30, 'days').endOf('day')],
        '今月末まで': [moment(), moment().endOf('month')],
        '来月末まで': [moment(), moment().add(1, 'months').endOf('month')],
    });
};

// 日時選択の共通選択肢 for アプリケーション
export const DatePickerRangesForApp = () => {
    return ({
        '今日まで': [moment(), moment().endOf('day')],
        '明日まで': [moment(), moment().add(1, 'days').endOf('day')],
        '7日間': [moment(), moment().add(7, 'days').endOf('day')],
        '30日間': [moment(), moment().add(30, 'days').endOf('day')],
        '今月末まで': [moment(), moment().endOf('month')],
        '来月末まで': [moment(), moment().add(1, 'months').endOf('month')],
        '年度末まで': [moment(), getQuarter().quarter4.end]
    });
};

/** 無期限を含む 日時選択の共通選択肢
* @return {{
    '今日まで': [moment.Moment, moment.Moment],
    '明日まで': [moment.Moment, moment.Moment],
    '7日間': [moment.Moment, moment.Moment],
    '30日間': [moment.Moment, moment.Moment],
    '今月末まで': [moment.Moment, moment.Moment],
    '来月末まで': [moment.Moment, moment.Moment],
    '年度末まで': [moment.Moment, moment.Moment],
    '無期限': [moment.Moment, moment.Moment],
}}
*/
export const DatePickerRangesIncludeInfinite = () => {
    return ({
        '今日まで': [moment(), moment().endOf('day')],
        '明日まで': [moment(), moment().add(1, 'days').endOf('day')],
        '7日間': [moment(), moment().add(7, 'days').endOf('day')],
        '30日間': [moment(), moment().add(30, 'days').endOf('day')],
        '今月末まで': [moment(), moment().endOf('month')],
        '来月末まで': [moment(), moment().add(1, 'months').endOf('month')],
        '年度末まで': [moment(), getQuarter().quarter4.end],
        '無期限': getInfiniteSpan()
    });
};

/***
 * 無期限の開始日時と終了日時を配列で返す
 * @return {[moment.Moment, moment.Moment]}
 */
export const getInfiniteSpan = () => {
    return [
        moment('1970-01-01 00:00', 'YYYY-MM-DD HH:mm'),
        moment('2037-12-31 23:59', 'YYYY-MM-DD HH:mm')
    ];
};

export const getDisabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment('2038-01-01', 'YYYY-MM-DD');
};

const getQuarter = () => {
    const nendo = moment().subtract(3, 'months').year();
    const nextNendo = nendo + 1;
    let obj = {};
    obj.quarter1 = { start: moment(nendo, 'YYYY').quarter(2).startOf('quarter'), end: moment(nendo, 'YYYY').quarter(2).endOf('quarter') }
    obj.quarter2 = { start: moment(nendo, 'YYYY').quarter(3).startOf('quarter'), end: moment(nendo, 'YYYY').quarter(3).endOf('quarter') }
    obj.quarter3 = { start: moment(nendo, 'YYYY').quarter(4).startOf('quarter'), end: moment(nendo, 'YYYY').quarter(4).endOf('quarter') }
    obj.quarter4 = { start: moment(nextNendo, 'YYYY').quarter(1).startOf('quarter'), end: moment(nextNendo, 'YYYY').quarter(1).endOf('quarter') }
    return obj;
};

//Froalaのリンクのデフォルトリスト
export const FroalaLinkList = [
    {
        text: 'Google',
        href: 'http://google.com',
        target: '_blank',
        rel: 'nofollow'
    },
];

//wild card用のunlimitedに変更
export const FroalaEditorLicenseKey = 'oc1F2vB2H1G1C3B1E7mEZXQUVJb1EZf1IWIAUKLJZMBQuD3E2D1F2C4G1F4F1C11C6==';

/**
 * 管理画面の上部分のタイトルを自身のwebのpathから生成
 * @param pathname
 * @returns {{subTitle: string, title: string}}
 */
export const generateTitle = (pathname) => {
    let path = pathname.replace(/\/$/, '');
    path = path.substr(path.lastIndexOf('/') + 1);
    const titleObject = { title: '', subTitle: '' };
    switch (path) {
        case 'app-templates':
            titleObject.title = 'アプリ配信管理';
            titleObject.subTitle = '連携しているアプリケーションのテンプレートの管理・編集・削除を設定';
            break;
        case 'assessment':
            titleObject.title = 'MEXCBTテスト管理';
            titleObject.subTitle = 'MEXCBTテスト配信の管理・編集・削除';
            break;
        case 'everyday-note':
            titleObject.title = '毎日の記録';
            titleObject.subTitle = '毎日の記録の管理・編集・削除';
            break;
        case 'notifications':
            //お知らせ情報
            titleObject.title = 'お知らせ管理';
            titleObject.subTitle = 'お知らせ情報の管理・編集・削除';
            break;
        case 'questionnaires':
            titleObject.title = 'アンケート';
            titleObject.subTitle = 'アンケート情報の管理・編集・削除';
            break;
        case 'apps':
            titleObject.title = '教材・アプリ管理';
            titleObject.subTitle = 'アプリ情報の管理・編集・削除';
            break;
        case 'users':
            titleObject.title = '利用者管理';
            titleObject.subTitle = '利用者情報の管理・編集・削除';
            break;
        case 'maintenance':
            titleObject.title = 'メンテナンス';
            titleObject.subTitle = '';
            break;
        case 'organization':
            titleObject.title = '学校管理';
            titleObject.subTitle = '学校情報の管理・編集・削除';
            break;
        case 'security':
            titleObject.title = 'テナント設定';
            titleObject.subTitle = 'IPアドレス制限';
            break;
        case 'usage':
            titleObject.title = '利用履歴の取得';
            titleObject.subTitle = '';
            break;
        case 'worker':
            titleObject.title = 'ワーカー閲覧';
            titleObject.subTitle = '現在の自治体でのワーカー一覧';
            break;
        default: {
            const title = checkEnvironmentIsTao() ? '管理メニュー' : 'L-Gate 管理メニュー'
            titleObject.title = title;
            titleObject.subTitle = '';
            break;
        }
    }
    return titleObject;
};

/***
 * JsonObjectからURL query形式に変更
 * @param obj
 * @returns {string}
 */
export const querySerialize = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p) && obj[p] !== undefined && obj[p] !== null) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
};

/**
 * 教育委員会用かどうかの判別
 * @returns {number | boolean}
 * 教育委員会であればtrue
 */
// isControllableUser と同様なので, 基本的に isControllableUser を使う
export const checkEnvironmentForControllable = () => {
    if (process.env.REACT_APP_LOGIN_ROLE === 'ADM') {
        return true;
    } else {
        const hostname = window.location.hostname;
        const regexRelease = /-adm\./;
        const regexStg = /-control/;
        const regexDev = 'localhaost';
        return hostname.search(regexRelease) !== -1 || hostname.search(regexStg) !== -1 || hostname.search(regexDev) !== -1;
    }
};

/**
 * API 用ドメイン
 */
export const apiDomainName = () => {
    const hostName = process.env.REACT_APP_API_HOST;

    if (hostName === undefined) {
        throw Error('No environment variables set.');
    }

    return hostName;
}

/**
 * API 用ドメインを元にユーザー用ドメインを生成する
 */
const userDomainName = () => {
    return apiDomainName().replace(
        /^(.*?)-api(\..*?)$/,
        (match, p1, p2) => `${p1}${p2}`,
    );
};

/**
 * APIが 用ドメインを元に学校管理者用ドメインを生成する
 */
const adminDomainName = () => {
    return apiDomainName().replace(
        /^(.*?)-api(\..*?)$/,
        (match, p1, p2) => `${p1}-mng${p2}`,
    );
};

/***
 * TAO環境であるかどうかの判別
 * @returns {boolean}
 * TAO環境であればtrue
 */
export const checkEnvironmentIsTao = () => {
    if (process.env.REACT_APP_TAO) return true;

    const domainRegexs = [
        /.*\.manabi-test\.l-gate\.net/,
        /.*\.manabi\.l-gate\.net/,
        /.*\.tao\.staging\.siba-service\.jp/,
        /.*\.2\.lg\.staging\.siba-service\.jp/
    ];

    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
        return domainRegexs.some(regex => apiDomainName().match(regex));
    } else {
        return domainRegexs.some(regex => hostname.match(regex));
    }
};

/**
 * API用のドメインを自身のホスト名から生成
 * @returns {string}
 */
export const generateApiDomain = (openWithBrowser) => {
    function changeApi(match, p1, p2, p3, offset, string) {
        return `${p1}-api${p3}`;
    }
    const hostname = window.location.hostname;
    const regex = /^(.*?)(-adm|-mng)(\..*?)$/;
    if (hostname === 'localhost') {
        return !openWithBrowser ? '/' : apiDomainName();
    } else {
        return `https://${hostname.replace(regex, changeApi)}`;
    }
};

/**
 * ファイル用のドメインを自身のホスト名から生成
 * @returns {string}
 */
export const generateFileDomain = () => {
    function changeApi(match, p1, p2, p3, offset, string) {
        return `${p1}-api${p3}`;
    }

    const hostname = window.location.hostname;
    const regex = /^(.*?)(-adm|-mng)(\..*?)$/;
    const domain = hostname === 'localhost' ?
        window.location.origin :
        `https://${hostname.replace(regex, changeApi)}`

    return `${domain}${checkEnvironmentForControllable() ? '/control' : ''}`;
};

/**
 * ユーザー用のwebページのURLを自身のホスト名から生成
 * @returns {string}
 */
export const generateUserDomain = () => {
    function changeApi(match, p1, p2, p3, offset, string) {
        return `${p1}${p3}`;
    }

    const hostname = window.location.hostname;
    const regex = /^(.*?)(-adm|-mng)(\..*?)$/;
    if (hostname === 'localhost') {
        return userDomainName();
    } else {
        return `https://${hostname.replace(regex, changeApi)}`;
    }
};

/**
 * tenant-management-api を自身のホスト名から生成
 * @returns {string}
 */
export const generateTenantDomain = () => {
    const replacer = (match, p1, p2) => `tenant-management-api.${p2}`;

    const hostname = window.location.hostname;
    const regex = /^(.*?-adm|-mng)\.(.*?)$/;
    if (hostname === 'localhost') {
        return userDomainName();
    } else {
        return `https://${hostname.replace(regex, replacer)}`;
    }
};

/**
 * 学校管理用のURLを自身のホスト名から生成
 * @returns {string}
 */
export const generateMngDomain = () => {
    function changeApi(match, p1, p2, p3, offset, string) {
        return `${p1}-mng${p3}`;
    }

    const hostname = window.location.hostname;
    const regex = /^(.*?)(-adm|-mng)(\..*?)$/;
    if (hostname === 'localhost') {
        return adminDomainName();
    } else {
        return `https://${hostname.replace(regex, changeApi)}`;
    }
};

export const generateLtiLaunchDomain = (isControllableUser, openWithBrowser) => {
    const domain = openWithBrowser ?
        generateApiDomain(true) :
        window.location.hostname === 'localhost' ?
            '' :
            generateApiDomain();
    const role = isControllableUser ? 'control' : 'admin';
    return `${domain}/${role}${API_LTI_LAUNCH}`;
};

/**
 * priorityStringを返す汎用関数
 * @param value
 * @returns {string}
 */
export const getPriorityString = (value) => {
    switch (value) {
        case 1:
            return '{通常|つうじょう}';
        case 2:
            return '{大切|たいせつ}';
        default:
            return '？？';
    }
};

/**
 * permissionの権限を盛っている場合 true
 * @param value
 * @returns {boolean}
 */
export const haveOrganizationPermission = (value) => {
    return value.belongs.some((belong) => belong?.role?.permission_code === 'administrator');
};

/** 今年度または無期限に所属する学校管理者かどうか  */
export const getIsActiveAdministrator = (user) => {
    return user.belongs.some((belong) => {
        const isCurrentOrInfinite = belong.school_class.term.is_current || isInfiniteTerm(belong.school_class.term);
        return (belong?.role?.permission_code === 'administrator') && isCurrentOrInfinite;
    });
};

/***
 * オブジェクトの等値比較 再帰的に内部も比較する
 * nullとundefinedは同じと扱うので注意
 * 参考: https://www.deep-rain.com/programming/javascript/755
 * @param obj1
 * @param obj2
 */
export const isEqualObject = (obj1, obj2) => {
    if (!obj1 && !obj2) { return true }
    else if (!obj1) { return false }
    else if (!obj2) { return false }

    const objectSort = (obj) => {
        const sorted = Object.entries(obj).sort();
        // valueを調べ、objectならsorted entriesに変換する
        for (let i in sorted) {
            const val = sorted[i][1];
            if (val !== null && typeof val === 'object') {
                sorted[i][1] = objectSort(val);
            }
        }
        return sorted;
    };

    return Object.is(JSON.stringify(objectSort(obj1)), JSON.stringify(objectSort(obj2)));
};

export const AppTemplateAuthenticationEnum = {
    LTI1_1: 'LTI1.1',
    LTI1_3: 'LTI1.3',
    OAuth2_0: 'OAuth2.0',
};

export const AppTemplateAuthTypeEnum = {
    LTI1_1: 1,
    LTI1_3: 2,
    OAuth2_0: 3,
};

export const AppTemplateAuthTypeList = [
    AppTemplateAuthTypeEnum.LTI1_1,
    AppTemplateAuthTypeEnum.LTI1_3,
    AppTemplateAuthTypeEnum.OAuth2_0,
];

export const convertAppTemplateAuthTypeToString = (authType) => {
    switch (authType) {
        case AppTemplateAuthTypeEnum.LTI1_1: return 'LTI1.1';
        case AppTemplateAuthTypeEnum.LTI1_3: return 'LTI1.3';
        case AppTemplateAuthTypeEnum.OAuth2_0: return 'OAuth2.0';
        default: return '不明';
    }
};

// AppTemplateの変更を適用させるかどうかのフラグ TODO: アプリテンプレートの変更を適用したくない場合はfalse
export const isApplyAppTemplate = true;

/***
 * Referer を付与してページ遷移する
 */
export const openWindowWithReferer = (url, target) => {
    const id = randomstr(20);
    const form = document.createElement('form');
    form.id = id;
    form.action = url;
    if (target !== undefined) form.target = target;

    const indexQM = url.indexOf('?');
    if (indexQM >= 0) {
        // クエリストリングで送られるはずだった内容をinputとしてformに追加
        const params = url.substring(indexQM + 1).split('&');
        params.forEach(param => {
            const input = document.createElement('input');
            const keyValuePair = param.split('=');
            input.type = 'hidden';
            input.name = keyValuePair[0];
            input.value = keyValuePair[1];
            form.appendChild(input);
        });
    }

    document.body.appendChild(form);
    form.submit();
    document.getElementById(id).remove();
};

/**
 * 適当にランダムな文字を生成する
 */
const randomstr = length => {
    return [...Array(length || 32)].map(i => {
        const random = Math.random() * 16 | 0;
        return (i === 12 ? 4 : (i === 16 ? ((random & 3) | 8) : random)).toString(16);
    }).join('');
};

/**
 * 通常の User に表示用の名前 display_name を付与する
 * @template T
 * @param {T}  user
 * @returns {AdditionDisplayName<T>}
 */
export const additionDisplayName = (user) => {
    if (!user) return { display_name: '-' };

    const userName = user.first_name !== '' || user.last_name !== '' ?
        `${user.last_name} ${user.first_name}` :
        user.login_id.split('@')[0];

    // 本当は displayName としたいが, 他のプロパティが スネークケースなのでそれに合わせている
    return { ...user, display_name: userName };
};

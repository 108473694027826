import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

export type SaveExamState = {
    isLoading: boolean
    status: typeof Actions.statusEnum[keyof typeof Actions.statusEnum] | 'DEFAULT'
};

export const useSaveExam = (
    // ここでは引数は使わないが、他でここの実装を真似する際のためにそれっぽくしておく
    successCallback?: (meta: unknown, payload: unknown, isControl: boolean) => void,
    failureCallback?: (meta: unknown, payload: unknown, isControl: boolean) => void,
) => {
    const [state, setState] = useState<SaveExamState>({
        isLoading: false,
        status: 'DEFAULT',
    });

    const taoExamControlSaveConnection = useAppSelector(state => state.taoExamControlSaveConnection);
    const taoExamSaveConnection = useAppSelector(state => state.taoExamSaveConnection);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta, payload } = taoExamControlSaveConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback && successCallback(meta, payload, isControllableUser);
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback && failureCallback(meta, payload, isControllableUser);
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            default: {
                setState(state => ({
                    ...state,
                    status: meta.status,
                }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamControlSaveConnection]);

    useEffect(() => {
        const { meta, payload } = taoExamSaveConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback && successCallback(meta, payload, isControllableUser);
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback && failureCallback(meta, payload, isControllableUser);
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            default: {
                setState(state => ({
                    ...state,
                    status: meta.status,
                }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamSaveConnection]);

    const requestData = useCallback(() => {
        setState({
            isLoading: true,
            status: Actions.statusEnum.REQUEST,
        });

        const saveAction = isControllableUser ?
            Actions.http.connection.tao.exam.controlSave :
            Actions.http.connection.tao.exam.save;

        dispatch(saveAction(''));
    }, [dispatch, isControllableUser]);

    const resetData = useCallback(() => {
        setState({
            isLoading: false,
            status: 'DEFAULT',
        });
    }, []);

    return [state, requestData, resetData] as const;
};

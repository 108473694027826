import { combineActions, handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


// admin 系

export const taoExamCategoryChangeOrderConnection = handleAction(
    Actions.http.connection.tao.examCategory.changeOrder,
    defaultReducer,
    defaultState
);

export const taoExamCategoryDeleteConnection = handleAction(
    Actions.http.connection.tao.examCategory.delete,
    defaultReducer,
    defaultState
);

export const taoExamCategoryDownloadCsvConnection = handleAction(
    Actions.http.connection.tao.examCategory.downloadCsv,
    defaultReducer,
    defaultState
);

export const taoExamCategoryEditConnection = handleAction(
    combineActions(
        Actions.http.connection.tao.examCategory.create,
        Actions.http.connection.tao.examCategory.update,
    ),
    defaultReducer,
    defaultState
);

export const taoExamCategoryResultConnection = handleAction(
    Actions.http.connection.tao.examCategory.result,
    defaultReducer,
    defaultState
);

export const taoExamCategorySearchConnection = handleAction(
    Actions.http.connection.tao.examCategory.search,
    defaultReducer,
    defaultState
);

export const taoExamCategorySearchWithResultConnection = handleAction(
    Actions.http.connection.tao.examCategory.searchWithResult,
    defaultReducer,
    defaultState
);

export const taoExamCategoryGraphConnection = handleAction(
    Actions.http.connection.tao.examCategory.graph,
    defaultReducer,
    defaultState
);

export const taoExamCategorySubjectListConnection = handleAction(
    Actions.http.connection.tao.examCategory.subjectList,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlSubjectListConnection = handleAction(
    Actions.http.connection.tao.examCategory.controlSubjectList,
    defaultReducer,
    defaultState
);

export const taoExamCategoryUserStatisticsConnection = handleAction(
    Actions.http.connection.tao.examCategory.userStatistics,
    defaultReducer,
    defaultState
);

export const taoExamCategoryViewConnection = handleAction(
    Actions.http.connection.tao.examCategory.view,
    defaultReducer,
    defaultState
);

export const taoExamCategorySaveReportConnection = handleAction(
    Actions.http.connection.tao.examCategory.saveReport,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlTargetOrganizationConnection = handleAction(
    Actions.http.connection.tao.examCategory.targetOrganization,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlTargetSchoolClassConnection = handleAction(
    Actions.http.connection.tao.examCategory.controlTargetSchoolClass,
    defaultReducer,
    defaultState
);


// control 系

export const taoExamCategoryControlDownloadCsvConnection = handleAction(
    Actions.http.connection.tao.examCategory.controlDownloadCsv,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlSearchWithResultConnection = handleAction(
    Actions.http.connection.tao.examCategory.controlSearchWithResult,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlGraphConnection = handleAction(
    Actions.http.connection.tao.examCategory.controlGraph,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlSaveReportConnection = handleAction(
    Actions.http.connection.tao.examCategory.controlSaveReport,
    defaultReducer,
    defaultState
);
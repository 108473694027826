import type {
    AppTemplate,
    ChoiceLti11Form,
    ChoiceLti13Form,
    FixedLti11Form,
    FixedLti13Form,
    FormValues,
    Lti11Form,
    Lti13Form,
} from './type';


type AppTemplate11 = Extract<AppTemplate, { type: 1 }>;
type AppTemplate13 = Extract<AppTemplate, { type: 2 }>;

// TODO: 返り値の型を付けるために Http.Connection.Request.Parameter に型を定義する
export const createSubmitValue = (shouldCreate: boolean, appTemplate: AppTemplate, values: FormValues) => {
    switch (appTemplate.type) {
        case 1:
            return createSubmitValueFromLti11(shouldCreate, appTemplate as AppTemplate11, values as Lti11Form);
        case 2:
            return createSubmitValueFromLti13(shouldCreate, appTemplate as AppTemplate13, values as Lti13Form);
        default:
            break;
    }
};

const createSubmitValueFromLti11 = (shouldCreate: boolean, appTemplate: AppTemplate11, values: Lti11Form) => {
    const {
        baseTemplateUuid,
        name,
        is_active,
        is_target_all,
        target_organization,
    } = values;

    return {
        ...(shouldCreate ? { baseTemplateUuid } : {}),
        is_active,
        is_target_all,
        name,
        params: createSubmitParamFromLti11(shouldCreate, appTemplate, values),
        target_organization: target_organization.map(v => v.uuid),
        url: createSubmitUrlFromLti11(shouldCreate, appTemplate, values),
    };
};

const createSubmitUrlFromLti11 = (shouldCreate: boolean, appTemplate: AppTemplate11, values: Lti11Form) => {
    const uuidKeyName = shouldCreate ?
        'manage_application_url_uuid' :
        'uuid';

    if (appTemplate.url.is_choices) {
        const formValues = values as ChoiceLti11Form;

        const {
            ltiUrlUuid,
            ltiUrlCandidateUuid,
        } = formValues;

        return {
            default_url_uuid: ltiUrlCandidateUuid,
            [uuidKeyName]: ltiUrlUuid,
        };

    } else {
        const formValues = values as FixedLti11Form;

        const {
            ltiUrlKey: key,
            ltiUrlSecret: secret,
            ltiUrlUrl: url,
        } = formValues;

        return {
            // 空文字入れないと失敗する
            default_url_uuid: '',
            key,
            secret,
            url,
            [uuidKeyName]: appTemplate.url.uuid,
        };
    }
};

const createSubmitParamFromLti11 = (shouldCreate: boolean, appTemplate: AppTemplate11, values: Lti11Form) => {
    const uuidKeyName = shouldCreate ?
        'manage_application_param_uuid' :
        'uuid';

    return appTemplate.params.map(param => {
        if (param.is_choices) {
            const { params } = values as ChoiceLti11Form;
            const v = params.find(v => v.ltiParamUuid === param.uuid);

            return {
                [uuidKeyName]: v?.ltiParamUuid ?? '',
                default_url_uuid: v?.ltiParamCandidateUuid ?? '',
            };
        } else {
            const { params } = values as FixedLti11Form;
            const v = params.find(v => v.ltiParamUuid === param.uuid);

            return {
                [uuidKeyName]: v?.ltiParamUuid,
                value: v?.ltiParamValue,
            };
        }
    });
};

const createSubmitValueFromLti13 = (shouldCreate: boolean, appTemplate: AppTemplate13, values: Lti13Form) => {
    const uuidKeyName = shouldCreate ?
        'manage_application_url_uuid' :
        'uuid';

    if (appTemplate.url.is_choices) {
        const formValues = values as ChoiceLti13Form;
        const {
            baseTemplateUuid,
            params: _params,
            ltiUrlUuid,
            ltiUrlCandidateUuid,
            target_organization,
            ...submitValues
        } = formValues;

        const paramJson = appTemplate.url.candidates.find(v => v.uuid === ltiUrlCandidateUuid)?.param_json;

        return {
            ...submitValues,
            ...(shouldCreate ? { baseTemplateUuid } : {}),
            target_organization: target_organization.map(v => v.uuid),
            url: {
                default_url_uuid: ltiUrlCandidateUuid,
                param_json: paramJson,
                [uuidKeyName]: ltiUrlUuid,
            },
        };
    } else {
        const formValues = values as FixedLti13Form;
        const {
            baseTemplateUuid,
            params: _params,
            param_json: {
                use_organization_code,
                ...paramJson
            },
            target_organization,
            ...submitValues
        } = formValues;

        return {
            ...submitValues,
            ...(shouldCreate ? { baseTemplateUuid } : {}),
            target_organization: target_organization.map(v => v.uuid),
            url: {
                // 空文字入れないと失敗する
                default_url_uuid: '',
                param_json: paramJson,
                [uuidKeyName]: appTemplate.url.uuid,
            },
            use_organization_code,
        };
    }
};
import { createSelector } from 'reselect';
import {selectors} from '../../../Selectors';

export const questionnaireTabSelectors = {
    loading: createSelector(
        [
            selectors.questionnaireSearchConnection,
            selectors.questionnaireEditConnection,
            selectors.questionnaireDeleteConnection,
            selectors.questionnaireCategorySearchConnection,
        ],
        (
            questionnaireSearchConnection,
            questionnaireEditConnection,
            questionnaireDeleteConnection,
            questionnaireCategorySearchConnection,
        ) => {
            const searchFetch = questionnaireSearchConnection.meta.fetch;
            const editFetch = questionnaireEditConnection.meta.fetch;
            const deleteFetch = questionnaireDeleteConnection.meta.fetch;
            const categorySearchFetch = questionnaireCategorySearchConnection.meta.fetch;

            return {
                tableLoading: searchFetch || editFetch || deleteFetch,
                buttonLoading: searchFetch || editFetch || deleteFetch,
                categorySelectLoading: categorySearchFetch,
            };
        }
    ),
};

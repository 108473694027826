import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useUrlParameters, QUERY_STRING_PARAMS } from 'constants/CustomHooks/useUrlParameters';

/**
 * タブ関連を管理するための Custom Hook.
 *
 * 初回 render 時に, URL Parameters からタブ Key を取得する.
 * URL Parameters が間違っている場合は defaultKey とする.
 *
 * また, タブ Key を設定する際に, URL も変更する.
 *
 * @param keys タブ Key を value に持つ object
 * @param defaultKey タブ Key が指定されていないときに表示するタブ Key
 * @param callback タブ Key を設定するときに一緒に実行する callback 関数
 * @returns タブ Key とその setter のペア
 */
export const useTabManagement = <T extends string>(
    keys: Record<string, T>,
    defaultKey: T,
    callback?: (tabKay: T) => void,
) => {
    const history = useHistory();
    const urlParameters = useUrlParameters();

    const currentTab = getCurrentTabKey(urlParameters, keys);
    const [tabActiveKey, setTabActiveKey] = useState(currentTab ?? defaultKey);

    useEffect(() => {
        const currentTab = getCurrentTabKey(urlParameters, keys);

        if (currentTab === undefined) {
            const urlParams = Object.entries(urlParameters)
                .filter(([key]) => key !== QUERY_STRING_PARAMS.currentTab)
                .map(([key, val]) => {
                    return `${key}=${val}`;
                }).join('&');
            history.push(`${history.location.pathname}?${urlParams}`);
        } else {
            const { [QUERY_STRING_PARAMS.currentTab]: _, ...params } = urlParameters;
            const urlParams = [
                [QUERY_STRING_PARAMS.currentTab, currentTab],
                ...Object.entries(params),
            ].map(([key, val]) => {
                return `${key}=${val}`;
            }).join('&');

            history.push(`?${urlParams}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeTab = (activeKey: string) => {
        const isValidTabKey = Object.values<string>(keys).includes(activeKey);
        const tabKey = (isValidTabKey ? activeKey : defaultKey) as T;

        if(tabActiveKey === tabKey) return;

        setTabActiveKey(tabKey);
        callback?.(tabKey);
        history.push(`?${QUERY_STRING_PARAMS.currentTab}=${tabKey}`);
    };

    return [tabActiveKey, onChangeTab] as const;
};

const getCurrentTabKey = <T extends string>(urlParameters: Record<string, string>, keys: Record<string, T>) => {
    const currentTab = urlParameters[QUERY_STRING_PARAMS.currentTab];
    const isValid = Object.values<string>(keys).includes(currentTab);
    // isValidTabKey が true ならば currentTab は T 型となる
    // 本来は型ガードを使って as を使わないようにする
    return (isValid ? currentTab as T : undefined);
};
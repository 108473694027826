import * as React from 'react';

import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

import TermEffectComponent from '../UsersManagement/Term/TermEffectCompornent';
import AdminContents from './contents/Admin';
import ControlContents from './contents/Control';

import 'styles/pages/assessment.scss';

export const Assessment: React.VFC = () => {
    const isControllableUser = useAppSelector(selectors.isControllableUser);

    return (
        <>
            <TermEffectComponent />

            <div className='assessment-list'>
                {isControllableUser ?
                    <ControlContents /> :
                    <AdminContents />
                }
            </div>
        </>
    );
};

import { createAction } from 'redux-actions';
import {
    API_TAO_EXAM_CATEGORY,
    API_TAO_CONTROL_EXAM_CATEGORY,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/TAO/EXAM_CONTROL_CATEGORY';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

type DefaultPayload = { method: HttpMethods, api: string };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

type Payload<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultPayload
        : Expand<DefaultPayload & EXTRA>;
type Meta<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultMeta
        : Expand<DefaultMeta & EXTRA>;

type DownloadCsvParameter = Http.Connection.Request.Parameter.ExamCategory.DownloadCsv;
export const downloadCsv = createAction<
    Payload,
    Meta,
    string,
    string,
    DownloadCsvParameter
>(
    createActionType('DOWNLOAD_CSV' as const),
    (uuid, encoding, data) => {
        return ({
            data,
            method: 'get',
            api: `${API_TAO_EXAM_CATEGORY}/download-csv/${uuid}?encoding=${encoding}`
        });
    },
    (uuid, encoding) => ({ fetch: true, status: StatusEnum.REQUEST, uuid })
);

type ControlDownloadCsvParameter = Http.Connection.Request.Parameter.ExamCategory.ControlDownloadCsv;
export const controlDownloadCsv = createAction<
    Payload,
    Meta,
    string,
    string,
    ControlDownloadCsvParameter
>(
    createActionType('CONTROL_DOWNLOAD_CSV' as const),
    (uuid, encoding, data) => {
        return ({
            data,
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/download-csv/${uuid}?encoding=${encoding}`
        });
    },
    (uuid, encoding) => ({ fetch: true, status: StatusEnum.REQUEST, uuid })
);


type SearchParameter = Http.Connection.Request.Parameter.ExamCategory.Search;
export const controlSearch = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.ExamCategory.ControlSearch,
    Meta<{ viewName: string }>,
    SearchParameter,
    number | undefined,
    string
>(
    createActionType('CONTROL_SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? 1 : page;
        return ({ method: 'get', data, api: `${API_TAO_CONTROL_EXAM_CATEGORY}/${pageNum}` })
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const view = createAction<
    Payload | Http.Connection.Response.ExamCategory.View,
    Meta,
    string,
    string
>(
    createActionType('VIEW' as const),
    (uuid, viewName) => ({
        method: 'get',
        api: `${API_TAO_EXAM_CATEGORY}/view/${uuid}`,
    }),
    (uuid, viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName,
    })
);

export const controlView = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlView,
    Meta,
    string,
    string
>(
    createActionType('CONTROL_VIEW' as const),
    (uuid, viewName) => ({
        method: 'get',
        api: API_TAO_CONTROL_EXAM_CATEGORY + '/view/' + uuid,
    }),
    (uuid, viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName,
    })
);

type ControlCreateParameter = Http.Connection.Request.Parameter.ExamCategory.ControlCreate;
export const controlCreate = createAction<
    Payload<{ data: ControlCreateParameter }> | Http.Connection.Response.ExamCategory.ControlCreate,
    Meta,
    string,
    ControlCreateParameter,
    string
>(
    createActionType('CONTROL_CREATE' as const),
    (uuid, data, viewName) => {
        const api = !!uuid ?
            API_TAO_CONTROL_EXAM_CATEGORY + '/create/' + uuid :
            API_TAO_CONTROL_EXAM_CATEGORY + '/create';
        return({ method: 'put', api: api, data })
    },
    (uuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

type ControlUpdateParameter = Http.Connection.Request.Parameter.ExamCategory.ControlUpdate;
export const controlUpdate = createAction<
    Payload<{ data: ControlUpdateParameter }> | Http.Connection.Response.ExamCategory.ControlUpdate,
    Meta,
    string,
    ControlUpdateParameter,
    string
>(
    createActionType('CONTROL_UPDATE' as const),
    (uuid, data, viewName) => ({
        method: 'post',
        api: API_TAO_CONTROL_EXAM_CATEGORY + '/update/' + uuid,
        data
    }),
    (uuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlDelete = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlDelete,
    Meta<{ viewName: string }>,
    string,
    string
>(
    createActionType('CONTROL_DELETE' as const),
    (uuid, _viewName) => ({ method: 'delete', api: API_TAO_CONTROL_EXAM_CATEGORY + '/delete/' + uuid }),
    (_uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName }),
);

type ResultParameter = Http.Connection.Request.Parameter.ExamCategory.Result;
export const controlResult = createAction<
    Payload<{ data: ResultParameter }> | Http.Connection.Response.ExamCategory.ControlResult,
    Meta<{ viewName: string }>,
    string,
    ResultParameter,
    number,
    string
>(
    createActionType('CONTROL_RESULT' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '1' : page;
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/result/${uuid}/${pageNum}`,
            data
        };
    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlChangeOrder = createAction<
    Payload,
    Meta,
    string,
    {uuids: string[]}
>(
    createActionType('CONTROL_CHANGE_ORDER' as const),
    (uuid, data) => ({
        method: 'post',
        api: `${API_TAO_CONTROL_EXAM_CATEGORY}/change-order/${uuid}`,
        data,
    }),
    (uuid, data) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const userStatistics = createAction<
    Payload | Http.Connection.Response.ExamCategory.UserStatistics,
    Meta<{ viewName: string }>,
    string,
    string,
    { latest_result_only?: '0' | '1' },
    string
>(
    createActionType('USER_STATISTICS' as const),
    (examCategoryUuid, userUuid, data, viewName) => {
        return {
            method: 'get',
            api: API_TAO_EXAM_CATEGORY + `/user-statistics/${examCategoryUuid}/${userUuid}`,
            data,
        };
    },
    (examCategoryUuid, userUuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlUserStatistics = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlUserStatistics,
    Meta<{ viewName: string }>,
    string,
    string,
    { latest_result_only?: '0' | '1' },
    string
>(
    createActionType('CONTROL_USER_STATISTICS' as const),
    (examCategoryUuid, userUuid, data, viewName) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/user-statistics/${examCategoryUuid}/${userUuid}`,
            data,
        };
    },
    (examCategoryUuid, userUuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlChangeCategoryOrder = createAction<
    Payload,
    Meta,
    { uuids: string[] }
>(
    createActionType('CONTROL_CHANGE_CATEGORY_ORDER' as const),
    (data,) => ({
        method: 'post',
        api: `${API_TAO_CONTROL_EXAM_CATEGORY}/change-category-order/`,
        data,
    }),
    (data,) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
)
import LoginForm from "./LoginForm";
import {Card, Divider} from "antd";
import {
    checkEnvironmentForControllable,
    checkEnvironmentIsTao,
} from 'constants/GlobalConfig';
import Title from 'components/modules/Title';
import Announcement from '../../../modules/Announcement';

const Login = props => {
    return (
        <div className={"app"}>
            <Title text='サインイン' />
            <div className={"app-wrapper container"} id="login">
                <div id="login-body">
                    <div className="login-form">
                        <Card className={"card-content"}>
                            <div className={checkEnvironmentIsTao() ? 'logo-m' : 'logo'} />
                            {checkEnvironmentForControllable() ?
                                <Divider>全体管理者サインイン</Divider>
                                :
                                <Divider>学校管理者サインイン</Divider>
                            }
                            <Announcement />
                            <LoginForm/>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {};

export default Login;

import { createAction } from 'redux-actions';
import {
    API_ACTION_LOG,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/USAGE';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const total = createAction(
    createActionType('TOTAL' as const),
    (data) => ({
        method: 'get',
        api: `${API_ACTION_LOG}/total`,
        timeout: 300000,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const detail = createAction(
    createActionType('DETAIL' as const),
    (data) => ({
        method: 'get',
        api: `${API_ACTION_LOG}/detail`,
        timeout: 300000,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

module exportModule {
    export const total = createAction(
        createActionType('EXPORT/TOTAL' as const),
        (data) => {
            return ({ method: 'get', data, api: `${API_ACTION_LOG}/download-csv-total` })
        },
        () => ({ fetch: true, status: StatusEnum.REQUEST })
    );
}
export { exportModule as export };
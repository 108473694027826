import { useCallback, useEffect, useRef, useState } from 'react';

import { PlusCircleFilled, SortAscendingOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import EditOrderModal from 'components/modules/EditOrderModal';
import { QUERY_STRING_PARAMS, useUrlParameters } from 'constants/CustomHooks/useUrlParameters';
import { generateLtiLaunchDomain } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import { useChangeExamCategoryOrder } from '../../hooks/useChangeExamCategoryOrder';
import { useChangeExamOrder } from '../../hooks/useChangeExamOrder';
import { useDeleteExam } from '../../hooks/useDeleteExam';
import { useDeleteExamCategory } from '../../hooks/useDeleteExamCategory';
import { useEditExamCategory } from '../../hooks/useEditExamCategory';
import { usePagination } from '../../hooks/usePagination';
import { useResultExamCategory } from '../../hooks/useResultExamCategory';
import { useSaveExam } from '../../hooks/useSaveExam';
import { SearchConditions, useSearchExamCategory } from '../../hooks/useSearchExamCategory';
import {
    useSearchExamCategoryWithOrderChange,
} from '../../hooks/useSearchExamCategoryWithOrderChange';
import { useViewExamCategory } from '../../hooks/useViewExamCategory';
import { ExamCategoryModal } from '../../modals/ExamCategoryModal';
import { ExamCategoryStatusModal } from '../../modals/ExamCategoryStatusModal';
import { ExamCategorySearch } from './ExamCategorySearch';
import { ExamCategoryTable } from './ExamCategoryTable';

type FormValues = Http.Connection.Request.Parameter.ExamCategory.Search;


type NestTab = ['delivery' | 'result', 'board' | 'school' | ''];

/**
 * 全体管理者, つまりタブ内に含まれていない場合の Props
 */
type NoTabProps<T extends NestTab> = {
    currentTabKeys: T
    isControllableUser: true
    tabName?: ''
    onOpenTabCondition: (currentTabKeys: T) => boolean
}

/**
 * 学校管理者, つまりタブ内のコンテンツで共通の Props
 */
type BasePropsInTab<T extends NestTab> = {
    currentTabKeys: T
    enableJumpToResult?: boolean
    isControllableUser?: false
    isSchoolContents: boolean
    onOpenTabCondition?: (currentTabKeys: T) => boolean
}

/**
 * 学校管理者で学校配信タブの場合の Props
 */
type SchoolProps<T extends NestTab> = {
    isSchoolContents: true
    tabName: 'school'
} & BasePropsInTab<T>

/**
 * 学校管理者で一斉配信タブの場合の Props
 */
type BoardOfEducationProps<T extends NestTab> = {
    isSchoolContents: false
    tabName: 'board'
} & BasePropsInTab<T>

type Props<T extends NestTab> = NoTabProps<T> | SchoolProps<T> | BoardOfEducationProps<T>;

const ExamDeliveryTemplate = <T extends NestTab>(props: Props<T>) => {
    const {
        currentTabKeys,
        tabName = '',
        isControllableUser = false,
        onOpenTabCondition = (currentTabKeys: T) => currentTabKeys[1] === tabName,
    } = props;
    const requestType = tabName === '' ?
        'control' :
        tabName === TAB_NAMES.SCHOOL ?
            'default' :
            'control';
    const enableJumpToResult = isNoTabProps(props) ? false : (props.enableJumpToResult ?? false);
    const operable = isNoTabProps(props) ? true : props.isSchoolContents;

    const [isOpenCategoryOrderModal, setIsOpenCategoryOrderModal] = useState(false);
    const [dataSource, fetchData] = useSearchExamCategory();
    const [editState, requestEdit] = useEditExamCategory();
    const [deleteState, requestDelete, resetDelete] = useDeleteExamCategory();
    const [viewData, fetchViewData, resetViewData] = useViewExamCategory(
        (_meta, _payload, state) => {
            setTargetExamCategory(state.target);
        },
    );
    const [deleteExamState, requestDeleteExam, resetDeleteExam] = useDeleteExam();
    const [resultState, requestResult, resetResultState] = useResultExamCategory();
    const [orderChangeExamCategory, requestOrderChangeExamCategory, resetOrderChangeExamCategory] = useSearchExamCategoryWithOrderChange();
    const [orderChange, requestOrderChange, resetOrderChange] = useChangeExamCategoryOrder(
        () => {
            fetchData({ ...search, page: 1 }, requestType);
            resetOrderChangeExamCategory();
            setIsOpenCategoryOrderModal(false);
        },
        () => { resetOrderChangeExamCategory(); }
    );
    const [examOrderChange, requestExamOrderChange, resetExamOrderChange] = useChangeExamOrder(
        () => {
            setIsEditOrderLoading(false);
            setIsEditOrderOpen(false);
            setOrderChangeExamCategoryUuid('');
            setOrderChangeExams([]);
            resetExamOrderChange();
            fetchData(search, requestType);
        },
        () => {
            setIsEditOrderLoading(false);
            resetExamOrderChange();
        }
    );
    const [, requestSaveExam, resetSaveExam] = useSaveExam(
        () => {
            fetchData(search, requestType);
            localStorage.removeItem('deeplinkingresponse');
        },
        () => {
            resetSaveExam();
            localStorage.removeItem('deeplinkingresponse');
        },
    );

    const history = useHistory();
    const urlParameters = useUrlParameters();
    const testGroupUuid = urlParameters[QUERY_STRING_PARAMS.testGroup];
    const searchTitle = urlParameters[QUERY_STRING_PARAMS.title];
    const searchStatus = urlParameters[QUERY_STRING_PARAMS.status];
    const searchIsRequired = urlParameters[QUERY_STRING_PARAMS.isRequired];
    const searchResultExists = urlParameters[QUERY_STRING_PARAMS.resultExists];

    const [search, setSearch] = useState<SearchConditions>({ mode: 'simple', page_size: 10 });
    const [isOpenEditExamCategory, setIsOpenEditExamCategory] = useState(false);
    const [targetExamCategory, setTargetExamCategory] = useState<ExamCategory>();
    const [isOpenStatus, setIsOpenStatus] = useState(false);
    const [shouldCopy, setShouldCopy] = useState(false);
    const [orderChangeExams, setOrderChangeExams] = useState<Exam[]>([]);
    const [isEditOrderLading, setIsEditOrderLoading] = useState(false);
    const [isEditOrderOpen, setIsEditOrderOpen] = useState(false);
    const [orderChangeExamCategoryUuid, setOrderChangeExamCategoryUuid] = useState('');
    const [pagination, setPagination] = usePagination({});

    const onChangePagination = useCallback((page: number, pageSize: number) => {
        const newSearch = { ...search, page, page_size: pageSize };
        setSearch(newSearch);
        setPagination(pagination => ({ ...pagination, current: page, pageSize }));
        fetchData(newSearch, requestType);
    }, [search, requestType]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * URL の param を元に実施状況確認モーダルを表示したかを確認するための Ref
     *
     * 一度開いたらもう開かないようにする.
     */
    const isOpenStatusModalFromUrlParams = useRef(false);

    const subWindowRef = useRef<Window | null>(null);

    useEffect(() => {
        // localStorage にデータが残っていると正常に動作しないことがあるので、
        // ページ遷移時に localStorage のデータを削除しておく
        localStorage.removeItem('deeplinkingresponse');
    }, []);

    const changeLocalStorage = useCallback((event) => {
        if (event.storageArea.getItem('deeplinkingresponse') !== 'true') return;
        if (!onOpenTabCondition(currentTabKeys)) return;

        requestSaveExam();
    }, [currentTabKeys, onOpenTabCondition, requestSaveExam]);

    useEffect(() => {
        window.addEventListener('storage', changeLocalStorage);
        return () => {
            window.removeEventListener('storage', changeLocalStorage);
        };
    }, [changeLocalStorage]);

    useEffect(() => {
        setPagination((pagination) => ({ ...pagination, onChange: onChangePagination }));
        // onChangePaginationが再作成されたらpaginationを更新
    }, [onChangePagination]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!enableJumpToResult) return;
        if (isOpenStatusModalFromUrlParams.current) return;
        if (!testGroupUuid) return;

        fetchViewData(testGroupUuid, requestType);
        resetResultState();
        setIsOpenStatus(true);

        isOpenStatusModalFromUrlParams.current = true;
    }, [fetchViewData, requestType, testGroupUuid, resetResultState, enableJumpToResult]);

    useEffect(() => {
        // タブの切替時に一覧を更新する
        if (!onOpenTabCondition(currentTabKeys) || dataSource.isLoading) return;

        const status = (
            ['before', 'ongoing', 'finished'].includes(searchStatus) ? searchStatus : undefined
        ) as FormValues['status'];
        const isRequired = searchIsRequired === '1' ? 1 : searchIsRequired === '0' ? 0 : undefined;
        const resultExists = searchResultExists === '1' ? 1 : searchResultExists === '0' ? 0 : undefined;
        const isFullSearch = [status, isRequired, resultExists].some(v => v !== undefined);

        const newSearch = {
            ...search,
            is_required: isRequired ?? search.is_required,
            mode: isFullSearch ? 'full' as const : 'simple' as const,
            result_exists: resultExists ?? search.result_exists,
            status: status ?? search.status,
            title: searchTitle ?? search.title,
        };
        setSearch(newSearch);
        fetchData(newSearch, requestType);

    // currentTabKeys は配列なので、[currentTabKeys] とすると常に変更があったことになる
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, currentTabKeys);

    useEffect(() => {
        // 通信(onSearch)の完了時に実行する
        if (dataSource.isLoading) return;

        setPagination(pagination => ({
            ...pagination,
            total: dataSource.itemCount,
        }));
    }, [dataSource.isLoading]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (editState.status !== Actions.statusEnum.SUCCESS) return;

        resetViewData();
        setShouldCopy(false);
        setIsOpenEditExamCategory(false);

        fetchData(search, requestType);
    }, [editState.status, requestType, fetchData, resetViewData, search]);

    useEffect(() => {
        if (deleteState.status !== Actions.statusEnum.SUCCESS) return;

        resetDelete();
        fetchData(search, requestType);
    }, [requestType, deleteState.status, fetchData, resetDelete, search]);

    useEffect(() => {
        if (deleteExamState.status !== Actions.statusEnum.SUCCESS) return;

        resetDeleteExam();

        fetchData(search, requestType);
    }, [requestType, deleteExamState.status, fetchData, resetDeleteExam, search]);

    const handleSearch = (values: FormValues, isFullSearch: boolean) => {
        const newSearch = {
            ...search,
            ...values,
            mode: isFullSearch ? 'full' as const : 'simple' as const,
            page: 1,
        };
        setSearch(newSearch);
        setPagination(pagination => ({
            ...pagination,
            current: 1,
        }));
        fetchData(newSearch, requestType);

        const urlParams = Object.entries(
            Object.assign(
                {},
                urlParameters,
                {
                    [QUERY_STRING_PARAMS.title]: values.title ?? '',
                    [QUERY_STRING_PARAMS.status]: values.status ?? '',
                    [QUERY_STRING_PARAMS.isRequired]: values.is_required ?? '',
                    [QUERY_STRING_PARAMS.resultExists]: values.result_exists ?? '',
                }
            )
        )
            .filter(([, val]) => val !== '')
            .map(([key, val]) => {
                return `${key}=${val}`;
            }).join('&');

        history.push(`?${urlParams}`);
    };

    const handleUpdateSearch = (values: FormValues, isFullSearch: boolean) => {
        setSearch(search => ({
            ...search,
            ...values,
            mode: isFullSearch ? 'full' as const : 'simple' as const,
            page: 1,
        }));
    };

    const handleCreateClick = () => {
        resetViewData();
        setShouldCopy(false);
        setIsOpenEditExamCategory(true);
    };

    const handleEditClick = (record: ExamCategory) => {
        fetchViewData(record.uuid, requestType);
        setShouldCopy(false);
        setIsOpenEditExamCategory(true);
    };

    const handleCopyClick = (record: ExamCategory) => {
        fetchViewData(record.uuid, requestType);
        setShouldCopy(true);
        setIsOpenEditExamCategory(true);
    };

    const handleEditSubmitted = (values: ExamCategory.EditInput, shouldCreate: boolean, isCopy: boolean) => {
        requestEdit(values, shouldCreate, isCopy);
    };

    const handleEditCancel = () => {
        resetViewData();
        setShouldCopy(false);
        setIsOpenEditExamCategory(false);
    };

    const handleDelete = (uuid: string) => {
        resetDelete();
        requestDelete(uuid);
    };

    const handleDeleteExam = (uuid: string) => {
        resetDeleteExam();
        requestDeleteExam(uuid);
    };

    const handleStatusOpen = (record: ExamCategory) => {
        resetResultState();
        setIsOpenStatus(true);
        setTargetExamCategory(record);
    };

    const handleStatusSubmit = () => setIsOpenStatus(false);
    const handleStatusCancel = () => setIsOpenStatus(false);

    const handleEditCategoryOrder = () => {
        setIsOpenCategoryOrderModal(true);
        requestOrderChangeExamCategory(requestType);
    };

    const handleCloseCategoryOrder = () => {
        resetOrderChange();
        resetOrderChangeExamCategory();
        setIsOpenCategoryOrderModal(false);
    };

    const handleOkEditCategoryOrder = (data: { uuids: string[] }) => {
        requestOrderChange(data.uuids);
    };

    const handleClickEditAppOrder = (examCategory: ExamCategory) => {
        setIsEditOrderOpen(true);
        setOrderChangeExams(examCategory.exams);
        setOrderChangeExamCategoryUuid(examCategory.uuid);
    };

    const handleOkEditOrder = (data: { uuids: string[] }) => {
        requestExamOrderChange(orderChangeExamCategoryUuid, data);
        setIsEditOrderLoading(true);
    };
    const handleCancelEditOrder = () => setIsEditOrderOpen(false);

    const handleOpenNewTab = (category: ExamCategory) => {
        subWindowRef.current = window.open(
            `${generateLtiLaunchDomain(isControllableUser, true)}/init-deep-linking/${category.uuid}`,
            '_blank',
        );
        setTargetExamCategory(category);
    };


    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onClickCopy = handleCopyClick ?? (() => { });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onClickCreate = handleCreateClick ?? (() => { });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onClickEdit = handleEditClick ?? (() => { });

    return (
        <div className='container'>
            <ExamCategorySearch
                loading={dataSource.isLoading}
                onSearch={handleSearch}
                onUpdateSearch={handleUpdateSearch}
                tabName={tabName === '' ? undefined : tabName}
            />

            <Typography.Text className='text' type='secondary'>
                本システムで「テスト」と表記してある場合、一定の質問対解答の形式を通じて児童生徒に出題するものを意味しています。
            </Typography.Text>

            <div className='flex-right-container gutter-bottom mt-4 mb-4'>
                {isControllableUser &&
                    <Button
                        className='mb-4'
                        icon={<SortAscendingOutlined />}
                        onClick={handleEditCategoryOrder}
                    >
                        並び替え
                    </Button>
                }

                <Button
                    disabled={!operable}
                    icon={<PlusCircleFilled />}
                    onClick={onClickCreate}
                    type='primary'
                >
                    新規作成
                </Button>
            </div>

            <ExamCategoryTable
                dataSource={dataSource.items}
                isLoading={dataSource.isLoading || deleteState.isLoading || deleteExamState.isLoading}
                onClickAddTest={handleOpenNewTab}
                onClickCopy={onClickCopy}
                onClickDelete={handleDelete}
                onClickDeleteTest={handleDeleteExam}
                onClickEdit={onClickEdit}
                onClickEditAppOrder={handleClickEditAppOrder}
                onClickStatus={handleStatusOpen}
                operable={operable}
                pagination={pagination}
                tabName={tabName === '' ? undefined : tabName}
            />

            <ExamCategoryModal
                isCopy={shouldCopy}
                islLoading={viewData.isLoading || editState.isLoading}
                onCancel={handleEditCancel}
                onOk={handleEditSubmitted}
                record={viewData.target}
                visible={isOpenEditExamCategory}
            />

            <ExamCategoryStatusModal
                examCategoryStatus={resultState}
                onCancel={handleStatusCancel}
                onOk={handleStatusSubmit}
                record={targetExamCategory}
                requestResult={requestResult}
                tabName={tabName === '' ? undefined : tabName}
                visible={isOpenStatus}
            />

            <EditOrderModal
                columns={[
                    {
                        className: 'drag-visible',
                        dataIndex: 'title',
                        key: 'title',
                        title: 'テストグループ名',
                        width: 350,
                    },
                ]}
                dataSource={orderChangeExamCategory.exams}
                loading={orderChangeExamCategory.isLoading || orderChange.isLoading}
                onCancel={handleCloseCategoryOrder}
                onOk={handleOkEditCategoryOrder}
                title='テストグループの並び替え'
                visible={isOpenCategoryOrderModal}
            />

            <EditOrderModal
                columns={[
                    {
                        className: 'drag-visible',
                        dataIndex: 'title',
                        key: 'title',
                        title: 'テスト名',
                        width: 350,
                    },
                ]}
                dataSource={orderChangeExams}
                loading={isEditOrderLading || examOrderChange.isLoading}
                onCancel={handleCancelEditOrder}
                onOk={handleOkEditOrder}
                title='テストの並び替え'
                visible={isEditOrderOpen}
            />
        </div>
    );
};

export default ExamDeliveryTemplate;


const isNoTabProps = <T extends NestTab>(props: Props<T>): props is NoTabProps<T> => !('isSchoolContents' in props);

import * as Actions from '../../../Actions';
import { generateFileDomain, getInfiniteSpan } from 'constants/GlobalConfig';


export const editQuestionnaireViewLogic = (store, next, action) => {

    const modalState = store.getState()['questionnaireTab']['modal'];
    const viewState = store.getState()['editQuestionnaire'];
    const editableItems = viewState['editableItems'];
    const isControllableUser = store.getState().isControllableUser;

    switch (action.type) {
        case Actions.view.viewLogic.editQuestionnaire.submitEdit.toString(): {
            const { target, ...values } = action.payload.values;
            const submitValue = {
                ...values,
                ...convertDurationToSubmittable(values),
                ...convertTargetToSubmittable(target),
                items: convertQuestionsToSubmittable(editableItems),
                target_permissions: values?.target_permissions,
            };
            delete submitValue.durationPublish;
            delete submitValue.durationPerform;
            delete submitValue.durationResult;

            if (modalState.shouldCreate) {
                const createAction = isControllableUser.payload ?
                    Actions.http.connection.questionnaires.controlCreate :
                    Actions.http.connection.questionnaires.create;

                next(createAction(submitValue));
            } else {
                const updateAction = isControllableUser.payload ?
                    Actions.http.connection.questionnaires.controlUpdate :
                    Actions.http.connection.questionnaires.update;

                next(updateAction(modalState.uuid, submitValue));
            }
            next(action);
            break;
        }
        default:
            next(action);
            break;
    }
}

export const convertTargetToSubmittable = (currentTarget) => {
    if (currentTarget.target_organizations?.length > 0) {
        return {
            target_organizations: currentTarget.target_organizations.map((v) => v.uuid),
        };
    }

    if (
        currentTarget.target_users?.length > 0 ||
        currentTarget.target_school_classes?.length > 0 ||
        currentTarget.target_roles?.length > 0
    ) {
        return {
            target_users: currentTarget.target_users.map((v) => v.uuid),
            target_school_classes: currentTarget.target_school_classes.map((value) => value.uuid),
            target_roles: currentTarget.target_roles.map((value) => value.uuid),
        };
    }

    return {};
}

/***
 *  リクエスト可能な形式に、期間を変換する。もし指定がない場合は、startは1970-01-01に、endは2037-12-31に置き換えてpostする
 * @param values
 * @return {{
 *      perform_start_at: (number|moment.Moment),
 *      publish_end_at: (number|moment.Moment),
 *      publish_start_at: (number|moment.Moment),
 *      publish_result_start_at: (number|moment.Moment),
 *      perform_end_at: (number|moment.Moment),
 *      publish_result_end_at: (number|moment.Moment)
 * }}
 */
export const convertDurationToSubmittable = (values) => {
    const { durationPublish, durationPerform, durationResult } = values;
    return {
        publish_start_at: durationPublish ? durationPublish[0].unix() : getInfiniteSpan()[0].unix(),
        publish_end_at: durationPublish ? durationPublish[1].unix() : getInfiniteSpan()[1].unix(),
        perform_start_at: durationPerform ? durationPerform[0].unix() : getInfiniteSpan()[0].unix(),
        perform_end_at: durationPerform ? durationPerform[1].unix() : getInfiniteSpan()[1].unix(),
        publish_result_start_at: durationResult ? durationResult[0].unix() : getInfiniteSpan()[0].unix(),
        publish_result_end_at: durationResult ? durationResult[1].unix() : getInfiniteSpan()[1].unix(),
    };
}

/***
 *  レスポンスから取得したアンケートの設問リストを編集可能なリストに変換する
 *  question_item_branch_choicesによる親質問の管理から、tmpItemId tmpCandidateIdでの管理に変更する
 * @param items
 * @param isCopy
 * @return {*}
 */
export const convertQuestionsToEditable = (items, isCopy) => {

    if (!items || items.length === 0) return [];

    const mapItemUuidToTmpId = {};
    const mapCandidateUuidToTmpId = {};
    let lastTmpItemId = 1;

    // 対応表の作成と、TmpIdを振り直す
    const newItems = items.map((item) => {
        const newItem = JSON.parse(JSON.stringify(item));
        // tempItemIdを振り直す
        newItem.uuid && (mapItemUuidToTmpId[newItem.uuid] = lastTmpItemId);
        newItem['tmpItemId'] = lastTmpItemId;
        lastTmpItemId++;

        // tmpCandidateIdを振り直す
        let lastTmpCandidateId = 1;
        newItem.answer_candidates && (newItem.answer_candidates = newItem.answer_candidates.map((candidate) => {
            if (candidate.uuid) {
                mapCandidateUuidToTmpId[candidate.uuid] = lastTmpCandidateId;
                candidate['tmpCandidateId'] = lastTmpCandidateId;
                lastTmpCandidateId++;
            }
            return candidate;
        }));
        return newItem;
    });

    // 対応表を考慮して、親Idを振り直す
    newItems.forEach((item) => {
        if (item.question_item_branch_choices.length !== 0) {
            const itemUuid = item.question_item_branch_choices[0].parent_question_item_uuid;
            const candidateUuid = item.question_item_branch_choices[0].question_answer_candidate_uuid;
            item['parentTmpItemId'] = mapItemUuidToTmpId[itemUuid];
            item['parentTmpCandidateId'] = mapCandidateUuidToTmpId[candidateUuid];
        }
        delete item.question_item_branch_choices;
    });

    // fileをantd Uploadで変更可能な状態にする
    newItems.forEach((item) => {
        if (item.file) {
            const newFile = Object.assign({}, item.file);
            if (newFile.hasOwnProperty('mime_type') && newFile.mime_type.match('image')) {
                newFile.thumbUrl = `${generateFileDomain()}/file/view/${newFile.uuid}`;
            } else {
                newFile.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
            }
            item.file = {
                ...newFile,
                uid: Math.floor(Math.random() * 10000),
                name: newFile.file_name,
                status: 'done',
            }

        }
    });

    if (isCopy) {
        newItems.forEach((item) => {
            delete item.uuid;
            delete item.created_at;
            delete item.updated_at;
            delete item.answer_count;
            delete item.answers;

            item.answer_candidates && item.answer_candidates.forEach((candidate) => {
                delete candidate.uuid;
                delete candidate.created_at;
                delete candidate.updated_at;
                delete candidate.answer_count;
            });
        });
    }

    return newItems;
}

/***
 *  レスポンスから取得したアンケートの設問リストを編集可能なリストから、リクエスト可能な設問リストに変換する
 *  question_item_branch_choicesによる親質問の管理から、tmpItemId tmpCandidateIdでの管理に変更する
 * @param items
 * @return {*}
 */
export const convertQuestionsToSubmittable = (items) => {

    if (!items || items.length === 0) return [];

    const mapItemTmpIdToOrder = {};
    const mapCandidateTmpIdToOrder = {};
    let lastItemOrderId = 1;

    // 対応表の作成と、orderを振り直す
    const newItems = items.map((item) => {
        const newItem = Object.assign({}, item);
        // tempItemIdを振り直す
        mapItemTmpIdToOrder[newItem.tmpItemId] = lastItemOrderId;
        newItem['order'] = lastItemOrderId++;
        delete newItem.tmpItemId;
        // tmpCandidateIdを振り直す
        let lastCandidateOrder = 1;
        newItem.answer_candidates && (newItem.answer_candidates = newItem.answer_candidates.map((candidate) => {
            mapCandidateTmpIdToOrder[candidate.tmpCandidateId] = lastCandidateOrder;
            candidate['order'] = lastCandidateOrder++;
            delete candidate.tmpCandidateId;
            return candidate;
        }));
        return newItem;
    });

    // 対応表を考慮して、親のorderを振り直す
    newItems.forEach((item) => {
        if (item.parentTmpItemId && item.parentTmpItemId !== 0) {
            item['parent_order'] = mapItemTmpIdToOrder[item.parentTmpItemId];
            item['parent_choice'] = mapCandidateTmpIdToOrder[item.parentTmpCandidateId];
            delete item.parentTmpItemId;
            delete item.parentTmpCandidateId;
        }
    });

    // fileをリクエスト可能な状態にする
    newItems.forEach((item) => {
        if (item.file) {
            item.file = item.file.uuid;
        }
    });

    // 回答選択肢を削除する
    newItems.forEach((item) => {
        if ((item.type !== 1) && (item.type !== 2)) {
            delete item.answer_candidates;
        }
    });

    return newItems;
}

import { createContext, useContext, useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import * as Actions from "../../../../flex/Actions";

JobListEffectComponent.defaultProps = {
    defaultSearchRule: {title: ""}
};

export const ListContext = createContext([{
    jobList: [],
    jobListItemCount: 0,
    jobDetail: {},
    loading: false
}, () => {
}]);
export const SearchContext = createContext([{title: ""}, () => {
}]);

export default function JobListEffectComponent(props) {
    const [list, setList] = useState({
        jobList: [],
        categoryList: [],
        jobListItemCount: 0,
        jobListPageTotal: 0,
        categoryListItemCount: 0,
        loading: false,
        unread_count: 0
    });
    const [searchRule, setSearchRule] = useState(props.defaultSearchRule);
    const jobSearchConnection = useSelector(state => state.jobSearchConnection);

    useEffect(() => {
        if (jobSearchConnection.meta.status === Actions.statusEnum.SUCCESS) {
            setList(previewList => ({
                ...previewList,
                jobList: jobSearchConnection.payload.result.items,
                jobListItemCount: jobSearchConnection.payload.result.item_count,
                loading: jobSearchConnection.meta.fetch
            }))
        }
        setList(previewList => ({
            ...previewList,
            loading: jobSearchConnection.meta.fetch
        }));
    }, [jobSearchConnection]);


    return (
        <ListContext.Provider value={[list, setList]}>
            <SearchContext.Provider value={[searchRule, setSearchRule]}>
                {props.children}
            </SearchContext.Provider>
        </ListContext.Provider>
    );
};
import { useContext, useEffect } from 'react';
import { Input, Button, Select, Row, Col, Tag, FormInstance } from 'antd';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import {
    addAllTermAndSort,
    getCurrentTerm,
    getDefaultLayout,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { userManagementTabFlex } from 'flex/view/UsersManagement/UserManagementTab'
import { ListContext } from 'components/presentational/pages/UsersManagement';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;
const { Option } = Select;

const STATUS_KEYS: { [key: string]: User.Status } = Object.freeze({
    active: 'active',
    deactive: 'deactive',
    plannedToDelete: 'planned_to_delete',
});

const layout = getDefaultLayout();

type Props = {
    form: FormInstance<User.Search>
};

const UsersSearch: React.VFC<Props> = ({ form }) => {
    const [list,] = useContext(ListContext);
    const dispatch = useDispatch();
    const termListSelector = useAppSelector(state => state.globalState.termList)
    const isControllableUser = useAppSelector(state => state.isControllableUser);
    const { optionalLoading, organizationSelectLoading, termSelectLoading } = useAppSelector(userManagementTabFlex.selectors.loading)
    const { classSelectDisabled, roleSelectDisabled, statusSelectDisabled, organizationSelectDisabled, termSelectDisabled } = useAppSelector(userManagementTabFlex.selectors.disabled)
    const usersSearchConnection = useAppSelector(state => state.usersSearchConnection);
    const tabSelector = useAppSelector(state => state.userManagementTab);

    useEffect(() => {
        const currentTermUuid = getCurrentTerm(termListSelector).uuid
        form.setFieldsValue({
            term_uuid: currentTermUuid,
            role_uuid: undefined,
            school_class_uuid: undefined
        });
        // termListSelector のみ監視したいため
    }, [termListSelector])// eslint-disable-line react-hooks/exhaustive-deps


    function onFinish(values: User.SearchInput) {
        const params: User.SearchInput & { is_active: 0 | 1 | undefined, is_planned_to_delete: 0 | 1 | undefined } = {
            ...values,
            is_active: undefined,
            is_planned_to_delete: undefined,
        };

        switch (params.status) {
            case STATUS_KEYS.active:
                params.is_active = 1;
                params.is_planned_to_delete = 0;
                break;
            case STATUS_KEYS.deactive:
                params.is_active = 0;
                params.is_planned_to_delete = 0;
                break;
            case STATUS_KEYS.plannedToDelete:
                params.is_active = undefined;
                params.is_planned_to_delete = 1;
                break;
            default:
                break;
        }
        // delete status
        const { status, ...data } = params;

        dispatch(Actions.view.viewLogic.userManagementTab.search(data))
    }

    function onReset() {
        form.resetFields();
        form.setFieldsValue({ term_uuid: getCurrentTerm(termListSelector).uuid })
        dispatch(Actions.view.viewLogic.userManagementTab.reset())
    }

    function handleSelectTerm(term_uuid: string) {
        form.resetFields(['school_class_uuid', 'role_uuid']);
        dispatch(Actions.view.viewLogic.userManagementTab.selectTerm(term_uuid))
    }

    function handleSelectOrganization(uuid?: string) {
        form.resetFields(['school_class_uuid', 'role_uuid']);
        dispatch(Actions.view.viewLogic.userManagementTab.selectOrganization(uuid))
    }

    return (
        <div className='card general-search notification-search-form-wrapper'>
            <Form<User.Search> {...layout} form={form} name='control-hooks-users-search' onFinish={onFinish}>
                {isControllableUser.payload ?
                    <Form.Item name='organization_uuid' label='学校'>
                        <Select<string>
                            placeholder="学校を選択してください"
                            onChange={handleSelectOrganization}
                            onClear={handleSelectOrganization}
                            disabled={organizationSelectDisabled}
                            loading={organizationSelectLoading}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {list.organizationList.map((value, index) => {
                                return <Option key={index} value={value.uuid}>{value.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    :
                    null
                }
                <Form.Item name='school_class_uuid' label='クラス'>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='term_uuid' style={{ marginBottom: '0px' }}>
                                <Select<string>
                                    placeholder='年度を選択してください'
                                    value={tabSelector.selectTerm}
                                    onChange={handleSelectTerm}
                                    disabled={termSelectDisabled}
                                    loading={termSelectLoading}
                                >
                                    {addAllTermAndSort(termListSelector).map((value, index) => {
                                        return <Option key={index} value={value.uuid}>{value.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='school_class_uuid' style={{ marginBottom: '0px' }}>
                                <Select
                                    disabled={classSelectDisabled}
                                    placeholder='クラスを選択してください'
                                    loading={optionalLoading}
                                    allowClear
                                >
                                    {(list.classListMap[tabSelector.selectTerm ?? ''] ?? []).map((value, index) => {
                                        return (
                                            <Option key={index} value={value.uuid}>
                                                <Tag color='#AAAAAA' style={{ width: '90px', textAlign: 'center', borderRadius: '5px' }}>
                                                    {value.term.name}
                                                </Tag>
                                                {value.name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item name='role_uuid' label='役割'>
                    <Select
                        disabled={roleSelectDisabled}
                        placeholder='役割を選択してください'
                        loading={optionalLoading}
                        allowClear
                    >
                        {list.roleList.map((value, index) => {
                            return <Option key={index} value={value.uuid}>{value.name}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name='status' label='状態'>
                    <Select
                        disabled={statusSelectDisabled}
                        placeholder='状態を選択してください'
                        loading={optionalLoading}
                        allowClear
                    >
                        <Option value={STATUS_KEYS.active}>有効</Option>
                        <Option value={STATUS_KEYS.deactive}>無効</Option>
                        <Option value={STATUS_KEYS.plannedToDelete}>削除予定</Option>
                    </Select>
                </Form.Item>

                <Form.Item name='login_id' label='ユーザーID'>
                    <Input placeholder='ユーザーIDを入力してください' />
                </Form.Item>

                <Form.Item label='名前'>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='last_name'>
                                <Input placeholder='姓を入力してください' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='last_name_kana'>
                                <Input placeholder='姓（ふりがな）を入力してください' />
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='first_name' >
                                <Input placeholder='名を入力してください' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='first_name_kana'>
                                <Input placeholder='名（ふりがな）を入力してください' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>


                <div className='submit-container'>
                    <Button htmlType='button'
                        onClick={onReset}
                    >
                        リセット
                    </Button>
                    <Button type='primary' htmlType='submit'
                        loading={usersSearchConnection.meta.fetch}
                        icon={<SearchOutlined />}
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default UsersSearch;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select } from 'antd';
import * as Actions from 'flex/Actions';
import { statusEnum } from 'flex/Actions';
import { getDefaultLayout } from 'constants/GlobalConfig';
import BaseModal from 'components/modules/BaseModal';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;

const roleList = [
    { code: 'administrator', name: '学校管理者権限' },
    { code: 'student', name: '児童生徒権限' },
    { code: 'teacher', name: '教員権限' }
];

const EditRole = ({
    onCancel,
    onOk,
    record,
    shouldCreate,
    visible = false,
}) => {
    const [loading, setLoading] = useState(false);
    const roleEditConnection = useSelector(state => state.roleEditConnection);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const handleSubmit = () => {
        form.submit();
    };
    const { Option } = Select;
    const layout = getDefaultLayout()

    const isFirstRender = useRef(false)
    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (record === null) {
            form.resetFields();
        } else {
            const reuseValue = {
                name: record.name,
                permission_code: record.permission_code,
            };
            form.setFieldsValue(reuseValue)
        }
        // record,visibleのみ監視したいため
    }, [record, visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(roleEditConnection.meta.fetch)
        if (!isFirstRender.current) {
            if (roleEditConnection.meta.status === statusEnum.SUCCESS) {
                onOk();
            }
        } else {
            isFirstRender.current = false;
        }
    }, [roleEditConnection])// eslint-disable-line react-hooks/exhaustive-deps

    const onFinish = (values) => {
        if (shouldCreate) {
            dispatch(Actions.http.connection.role.create(values));
        } else {
            dispatch(Actions.http.connection.role.update(record.uuid, values));
        }
    }

    return (
        <BaseModal
            className='common-modal'
            forceRender
            onCancel={onCancel}
            title={shouldCreate ? '新規作成' : '編集'}
            visible={visible}
            footer={[
                <Button key='back'
                    loading={loading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button key='create'
                    type='primary'
                    size='large'
                    loading={loading}
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
        >
            <Form {...layout} form={form} name='control-hooks-edit-roles' onFinish={onFinish}>
                <Form.Item name='name'
                    label='役割名'
                    rules={[
                        { required: true, message: '必須項目です' },
                        { message: '役割名は64文字以内で入力してください', max: 64 }
                    ]}
                >
                    <Input placeholder='役割名を入力してください' />
                </Form.Item>
                <Form.Item name='permission_code'
                    label='権限'
                    rules={[{ required: true, message: '必須項目です' }]}
                >
                    <Select
                        allowClear
                        placeholder='権限を選択してください'
                        onChange={() => { }}
                    >
                        {roleList.map((value) => (
                            <Option key={value.code} value={value.code}>{value.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </BaseModal>
    );
};

export default EditRole;
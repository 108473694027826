import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Input,
    Radio,
    Select,
    Spin,
    Tooltip,
} from 'antd';
import { LoadingOutlined, QuestionCircleOutlined, TeamOutlined } from '@ant-design/icons';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import * as Actions from 'flex/Actions';
import {
    applicationFormRules,
    DatePickerRangesIncludeInfinite,
    defaultGradePreset,
    formRules,
    getDefaultLayout,
    getDisabledDate,
} from 'constants/GlobalConfig';
import { texts } from 'constants/texts';
import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import { useSource } from '../SourceProvider';
import { applicationUtils } from '../ApplicationUtils';
import { FormCard } from '../../../AppTemplate/Forms/FormCard';
import Targets from '../../../Targets';

const Form = BaseForm.ModalForm;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const layout = getDefaultLayout(true);
const formItemLayoutWithoutLabel = {
    wrapperCol: {
        xl: { span: 20, offset: 8 },
    },
};

const AppGroupModal = props => {
    const { initialValue, isCreate, onCancel, onOk, order, loading, visible } = props;
    const [form] = Form.useForm();
    const [source,] = useSource();
    const [isOpenDeliveryTargetModal, setIsOpenDeliveryTargetModal] = useState(false);
    const [isPublishToAllOrganizations, setIsPublishToAllOrganizations] = useState(true);
    const [selectedChoiceTemplate, setSelectedChoiceTemplate] = useState();

    const dispatch = useDispatch();
    const [currentTarget, setCurrentTarget] = useState({
        target_organizations: []
    });
    const useDeepLinking = Form.useWatch('useDeepLinking', form);

    const presetFieldsValue = () => {
        form.setFieldsValue(
            applicationUtils.initialValueOfApplicationCategory
        );

        setCurrentTarget({
            target_organizations: []
        });
        setIsPublishToAllOrganizations(true);
        setSelectedChoiceTemplate(undefined);
    };

    useEffect(() => {
        if (!visible) return;
        if (isCreate) {
            form.resetFields();
            presetFieldsValue();
        }
    }, [dispatch, visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 新規作成時の初期化
        form.resetFields();

        if (initialValue === undefined) {
            presetFieldsValue();
        }
    }, [initialValue]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 編集時の初期化
        // source.appTemplatesForAdmin が読み込まれてから初期化する
        if (initialValue === undefined || loading) return;

        form.setFieldsValue({
            ...initialValue,
            applicationTemplateUuid: initialValue.ltiTemplate?.template?.uuid,
            applicationUrlValueCandidateUuid: initialValue.ltiTemplate?.candidate?.uuid,
            targetPermissions: initialValue.targetPermissions.map(v => v.code),
        });

        const selectedChoiceTemplate = findVer13ChangeableChoiceTemplateByUuid(
            source.appTemplatesForAdmin.data.items,
            initialValue.ltiTemplate?.template?.uuid
        );

        setCurrentTarget({
            target_organizations: initialValue.targetOrganizations
        });
        setIsPublishToAllOrganizations(initialValue.isPublishToAllOrganizations);
        setSelectedChoiceTemplate(selectedChoiceTemplate);

        // loadingのみ監視
    }, [loading]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (visible && !source.appCategory.loading && source.appCategory.status === Actions.statusEnum.SUCCESS) {
            onOk();
        }
        //loadingのみ監視
    }, [source.appCategory.loading]);// eslint-disable-line react-hooks/exhaustive-deps

    const onFinish = (values) => {
        const postBody = applicationUtils.convertApplicationCategoryToPostBody(
            values,
            initialValue,
            order,
            currentTarget
        );

        if (isCreate) {
            dispatch(Actions.http.connection.apps.category.controlCreate(postBody));
        } else {
            dispatch(Actions.http.connection.apps.category.controlUpdate(initialValue.uuid, postBody));
        }
    };

    const handleSelectTargetSubmit = (values) => {
        setIsOpenDeliveryTargetModal(false);
        setCurrentTarget(values);
        form.setFieldsValue({ target: { target_organizations: values.target_organizations } });
    };
    const handleSelectTargetCancel = () => setIsOpenDeliveryTargetModal(false);

    const handleSubmit = () => {
        form.submit();
    };

    const handleSelectUseDeepLinking = (event) => {
        if (event.target.value) return;

        form.setFieldsValue({
            applicationTemplateUuid: undefined,
            applicationUrlValueCandidateUuid: undefined,
        });
        setSelectedChoiceTemplate(undefined);
    };

    const handleApplicationTemplateUuid = (uuid) => {
        const selectedChoiceTemplate = source.appTemplatesForAdmin.data.items
            .filter(temp => temp.type === 2) // LTI 1.3
            .filter(temp => temp.url.is_choices) // 選択式
            .find(temp => temp.uuid === uuid);

        setSelectedChoiceTemplate(selectedChoiceTemplate);

        const defaultCandidateUuid = selectedChoiceTemplate ?
            selectedChoiceTemplate.url.candidates.find(candidate => candidate.is_default).uuid :
            undefined;

        form.setFieldsValue({ applicationUrlValueCandidateUuid: defaultCandidateUuid });
    };

    return (
        <BaseModal
            className='common-modal'
            title={isCreate ? <span>教材・アプリグループの新規作成</span> : <span>教材・アプリグループの編集</span>}
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button
                    key='back'
                    size='large'
                    loading={source.appCategory.loading || loading}
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    loading={source.appCategory.loading || loading}
                    onClick={handleSubmit}
                >
                    保存
                </Button>
            ]}
        >
            <Spin indicator={antIcon} spinning={loading}>
                <Form {...layout} form={form} name='control-hooks-edit-app-groups' onFinish={onFinish}>
                    <Form.Item
                        name='title'
                        label='教材・アプリグループ名称（小学校3年生以上）'
                        rules={[
                            {
                                required: true,
                                message: '教材・アプリグループ名称（小学校3年生以上）を入力してください'
                            },
                            applicationFormRules.applicationCategoryTitleRange(),
                        ]}
                    >
                        <Input
                            placeholder='教材・アプリグループ名称を入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        name='titleForLower'
                        label='教材・アプリグループ名称（小学校1・2年生）'
                        rules={[
                            {
                                required: true,
                                message: '教材・アプリグループ名称（小学校1・2年生）を入力してください'
                            },
                            applicationFormRules.applicationCategoryTitleRange(),
                        ]}
                    >
                        <Input
                            placeholder='教材・アプリグループ名称を入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        name='description'
                        label='概要'
                        rules={[
                            applicationFormRules.applicationCategoryDescriptionRange(),
                        ]}
                    >
                        <Input
                            placeholder='概要を入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        label='配信対象'
                        name='isPublishToAllOrganizations'
                        rules={[{ required: true, message: '配信対象を選択してください' }]}
                    >
                        <Radio.Group onChange={(e) => { setIsPublishToAllOrganizations(e.target.value); }}>
                            <Radio value={true}>すべて</Radio>
                            <Radio value={false}>個別指定</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {!isPublishToAllOrganizations && (
                        <Form.Item label='' name='deliveryTargets'{...formItemLayoutWithoutLabel}>
                            <>
                                <Button type='primary' icon={<TeamOutlined />} onClick={() => setIsOpenDeliveryTargetModal(true)}>
                                    配信対象を選択
                                </Button>
                                <Targets.Detail currentTarget={currentTarget} />
                            </>
                        </Form.Item>
                    )}

                    <Form.Item
                        label='公開期間'
                        name='duration'
                        rules={[formRules.requiredDuration({ label: '公開期間' })]}
                    >
                        <CustomRangePicker
                            form={form}
                            name='duration'
                            locale={jaJP}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY/MM/DD HH:mm'
                            disabledDate={getDisabledDate}
                            ranges={DatePickerRangesIncludeInfinite()}
                        />
                    </Form.Item>

                    <Form.Item
                        label={texts.targetPermissionsTitle}
                        name='targetPermissions'
                        value={0}
                        rules={[{ required: false }]}
                    >
                        <Checkbox.Group style={{ width: '100%' }} onChange={() => { }}>
                            <Checkbox value='administrator'>{texts.targetPermissionsCheckbox.administrator}</Checkbox>
                            <Checkbox value='teacher'>{texts.targetPermissionsCheckbox.teacher}</Checkbox>
                            <Checkbox value='student'>{texts.targetPermissionsCheckbox.student}</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        label='公開対象学年コード'
                        name='targetGrades'
                        rules={[
                            { required: true, message: '公開対象学年コードを選択してください' },
                            formRules.targetGradesValidator,
                        ]}
                    >
                        <Select
                            placeholder='学年コードを選択してください'
                            allowClear
                            mode='multiple'
                            onChange={() => form.validateFields(['targetGrades'])}
                            showSearch={false}
                        >
                            {defaultGradePreset.map(value => (
                                <Option
                                    key={value.code}
                                    value={value.code}
                                >
                                    {value.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                学校管理画面での公開期間変更
                                <Tooltip title='アプリグループの配信先の学校で、学校管理者が公開期間を任意に変更できるかどうかを設定できます'>
                                    <QuestionCircleOutlined style={{ marginLeft: '0.5rem' }} />
                                </Tooltip>
                            </span>
                        }
                        name='canAdminSetPublic'
                        rules={[{ required: true, message: '学校管理者での公開を選択してください' }]}
                    >
                        <Radio.Group>
                            <Radio value={false}>許可しない</Radio>
                            <Radio value={true}>許可する</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <FormCard
                        ghost
                        title={
                            <div>
                                高度な接続
                                <Tooltip title='LTI Deep Linking を用いた教材・アプリの登録をするときに設定します。'>
                                    <QuestionCircleOutlined style={{ marginLeft: '0.5rem' }} />
                                </Tooltip>
                            </div>
                        }
                        noHeaderStyle
                        isView={visible}
                        isOpen={useDeepLinking}
                    >
                        <Form.Item
                            label='LTI Deep Linking でアプリを追加'
                            name='useDeepLinking'
                            rules={[{ required: true }]}
                        >
                            <Radio.Group
                                disabled={!isCreate}
                                onChange={handleSelectUseDeepLinking}
                            >
                                <Radio value={false}>しない</Radio>
                                <Radio value={true}>する</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {useDeepLinking && (
                            <Form.Item
                                label='LTI1.3アプリ選択'
                                name='applicationTemplateUuid'
                                rules={[
                                    { required: true, message: 'LTI1.3アプリを選択してください' },
                                ]}
                            >
                                <Select
                                    placeholder='LTI1.3アプリを選択してください'
                                    allowClear
                                    disabled={!isCreate}
                                    onSelect={handleApplicationTemplateUuid}
                                >
                                    {source.appTemplatesForAdmin.data.items.map(template => (
                                        <Option key={template.uuid} value={template.uuid}>
                                            {template.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        {selectedChoiceTemplate && (
                            <Form.Item
                                // リスト選択不可の場合でも application_url_value_candidate_uuid を送る必要があるので hidden とする
                                hidden={!selectedChoiceTemplate?.url?.changeable_param_admin}
                                label='接続パラメータ'
                                name='applicationUrlValueCandidateUuid'
                                rules={[
                                    { required: true, message: '接続パラメータを選択してください' },
                                ]}
                            >
                                <Select
                                    placeholder='接続パラメータを選択してください'
                                    allowClear
                                    disabled={!isCreate}
                                >
                                    {selectedChoiceTemplate?.url?.candidates?.map(candidate => (
                                        <Option key={candidate.uuid} value={candidate.uuid}>
                                            {candidate.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </FormCard>
                </Form>
            </Spin>

            <Targets
                showClass={false}
                showUser={false}
                showOrganization={true}
                visible={isOpenDeliveryTargetModal}
                currentTarget={currentTarget}
                onCancel={handleSelectTargetCancel}
                onSubmit={handleSelectTargetSubmit}
            />
        </BaseModal>
    );
};

export default AppGroupModal;

/**
 * templates の中から、
 * LTI 1.3 で選択式のもののうち、 uuid が一致するものを見つける
 */
const findVer13ChangeableChoiceTemplateByUuid = (templates, uuid) => {
    return templates
        .filter(temp => temp.type === 2) // LTI 1.3
        .filter(temp => temp.url.is_choices) // 選択式
        .find(temp => temp.uuid === uuid);
};
import { FormProvider, useForm } from 'react-hook-form';

import { Button, buttonColorEnum } from 'components/modules/Student/Button';
import FiveLevelSelection from 'components/modules/Student/QuestionItem/FiveLevelSelection';
import FreeDescription from 'components/modules/Student/QuestionItem/FreeDescription';
import MultipleSelection from 'components/modules/Student/QuestionItem/MultipleSelection';
import { PullDown } from 'components/modules/Student/QuestionItem/PullDown';
import QuestionItemCard from 'components/modules/Student/QuestionItem/QuestionItemCard';
import SingleSelection from 'components/modules/Student/QuestionItem/SingleSelection';
import { StarMarkSelection } from 'components/modules/Student/QuestionItem/StarMarkSelection';
import Ruby from 'components/modules/Student/Ruby';
import { StudentModal, ModalHeaderTemplate } from 'components/modules/Student/StudentModal';


/* eslint-disable sort-keys */
const QUESTION_TYPE = {
    SINGLE: 1,
    MULTIPLE: 2,
    FREE_DESCRIPTION: 3,
    FIVE_LEVEL: 4,
    STAR_MARK: 6,
    PULLDOWN: 7,
};
/* eslint-enable sort-keys */

type Props = {
    isOpen: boolean
    note: Partial<EverydayNote.FormValue>
    onClose: () => void
};

// 以下は、learning-gateway-frontend の src\components\presentational\modules\EverydayNote\EverydayNoteModal.js 参照
const QuestionnairePreviewModal = (props: Props) => {
    const {
        isOpen,
        note,
        onClose,
    } = props;

    const methods = useForm();
    const { handleSubmit } = methods;

    const onSubmit = onClose;

    const onCancel = onClose;

    const headerElement = note &&
        <ModalHeaderTemplate>
            {note?.name}
        </ModalHeaderTemplate>;

    const footerElement = (
        <>
            <Button
                className='sm:ml-3 w-full sm:w-auto shadow-sm text-lg font-medium'
                title='{回答|かいとう}する'
            />
            <Button
                className='mt-3 sm:mt-0 w-full sm:w-auto shadow-sm text-lg font-medium'
                color={buttonColorEnum.WHITE}
                onClick={onCancel}
                title='とじる'
            />
        </>
    );

    const publishFrom = note?.publishFrom ?
        note?.publishFrom :
        <span className='skeleton-box h-4 w-32 inline-block mr-2' />;

    const itemsCount = note.items?.length ?? 0;
    const displayedQuestions = note.items
        ?.sort((a, b) => (a.order ?? itemsCount) - (b.order ?? itemsCount)) ?? [];

    if (!isOpen) return null;

    return (
        <StudentModal isOpen={isOpen} closeModal={onCancel} headerElement={headerElement} footerElement={footerElement}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='bg-beaver-100 p-4 sm:p-5'>
                        <div className='flex items-center'>
                            <div className='inline-block w-24'>
                                <Ruby>{'{発信元|はっしんもと}'}</Ruby>
                            </div>
                            <span> : </span>
                            <div className='break-all pl-1 w-full'>
                                {publishFrom}
                            </div>
                        </div>
                    </div>

                    <div className='bg-white p-6 px-4 sm:p-5' />

                    <div className='bg-white p-4 sm:p-5 grid grid-cols-1 gap-4'>
                        {isOpen &&
                            displayedQuestions.map((item, i) => {
                                return (
                                    <DisplayedQuestion
                                        item={item}
                                        key={item.uuid ?? `${i}`}
                                        name={item.uuid ?? `${i}`}
                                    />
                                );
                            })
                        }
                    </div>
                </form>
            </FormProvider>
        </StudentModal>
    );
};

export default QuestionnairePreviewModal;


const DisplayedQuestion = ({
    item,
    name,
}: {
    item: EverydayNote.Form.Question
    name: string
}
) => {
    let Component = null;

    switch (item.type) {
        case QUESTION_TYPE.SINGLE:
            Component = SingleSelection;
            break;
        case QUESTION_TYPE.MULTIPLE:
            Component = MultipleSelection;
            break;
        case QUESTION_TYPE.FREE_DESCRIPTION:
            Component = FreeDescription;
            break;
        case QUESTION_TYPE.FIVE_LEVEL:
            Component = FiveLevelSelection;
            break;
        case QUESTION_TYPE.STAR_MARK:
            Component = StarMarkSelection;
            break;
        case QUESTION_TYPE.PULLDOWN:
            Component = PullDown;
            break;
        default:
            return (
                <QuestionItemCard title='エラー'>
                    <Ruby>{'{予期|よき}しないデータを{取得|しゅとく}しました.'}</Ruby>
                </QuestionItemCard>
            );
    }

    return <Component name={name} {...item} />;
};

import * as React from 'react';

import { InfoCircleOutlined, LoadingOutlined, SelectOutlined } from '@ant-design/icons';
import {
    Breadcrumb,
    Col,
    Divider,
    Row,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { SideHeadTable } from 'components/modules/SideHeadTable';
import { getMoment } from 'constants/GlobalConfig';

import { ScoringStatusTag } from './ScoringStatusTag';

import type { BreadCrumbType } from '.';
import type { StatisticsExamCategoryState } from '../../hooks/useStatisticsExamCategory';
import type { UserExamState } from '../../hooks/useUserExam';


const moment = getMoment();

// 最低限, 集計結果を表示させるために必要な情報を含む型を作成
type StatisticsData = Partial<ExamCategory.UserStatistics>;

// antd の Tree data の表示を使うための型
type DataSource = NonNullable<UserExamState['result']>['exams'][number];

type Props = {
    handleClickBreadCrumb: (type: BreadCrumbType, name?: string, uuid?: string) => void
    onClickTestInfo: (test: DataSource) => void
    onReloadResult: (studentUuid: string, latest_result_only: boolean) => void
    record: ExamCategory | undefined
    statisticsExamCategory: StatisticsExamCategoryState
    userExam: UserExamState
}

const StatisticsColumnType = (latestResultOnly: boolean): ColumnsType<StatisticsData> => ([
    {
        dataIndex: 'learningProgress',
        render: (_, record) => {
            const { answeredExamCount, examCount } = record;
            return `${answeredExamCount ?? '-'} / ${examCount ?? '-'} テスト終了`;
        },
        title: '学習の進捗',
    },
    {
        dataIndex: 'RateOfCorrectAnswers',
        render: (_, record) => {
            const { totalMaxScore, totalScore } = record;

            if (totalScore === undefined || totalMaxScore === undefined || totalMaxScore === 0) {
                return `${totalScore ?? '-'} / ${totalMaxScore ?? '-'} ( - %)`;
            }

            return `${totalScore} / ${totalMaxScore} (${Math.round(totalScore / totalMaxScore * 100)}%)`;
        },
        title: (
            <>
                <span style={{ marginRight: '0.5em' }}>
                    合計の得点
                </span>
                <Tooltip
                    title={
                        '解答したテストの合計の得点です。' +
                        '解き終わっていないテストの点数は分子にも分母にも含まれません。' +
                        'また、１つのテストを複数回受検している場合、' + (
                            latestResultOnly ?
                                '最新の得点のみが反映されます。' :
                                'それらすべての得点が反映されます。'
                        )
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
    },
]);

export const StudentView: React.VFC<Props> = ({
    handleClickBreadCrumb,
    onClickTestInfo,
    onReloadResult,
    record: categoryRecord,
    statisticsExamCategory,
    userExam,
}) => {
    const [latestResultOnly, setLatestResultOnly] = React.useState(false);

    const onClickAllBreadCrumb = () => {
        handleClickBreadCrumb('List');
    };

    const handleClickTestInfo = (test: DataSource) => {
        handleClickBreadCrumb('Test', test.title, test.uuid);

        onClickTestInfo(test);
    };

    const handleReloadResult = (latestResultOnly: boolean) => {
        onReloadResult(userExam.result?.student?.uuid ?? '', latestResultOnly);
        setLatestResultOnly(latestResultOnly);
    };

    const columnsTest: ColumnsType<DataSource> = [
        {
            render: (_, record) => {
                return (
                    <button
                        className='text-button'
                        onClick={() => { handleClickTestInfo(record); }}
                    >
                        {record.title}
                    </button>
                );
            },
            title: 'テスト名',
            width: 250,
        },
        {
            render: (_, record) => {
                return (
                    <>
                        <span style={{ marginRight: '1rem' }}>
                            {`${record.score ?? '-'} / ${record.maxScore ?? '-'}`}
                        </span>
                        {
                            isDisplayDetailResultLink(record) && (
                                <a href={record.resultUrl} rel='noreferrer' target='_blank'>
                                    <Tooltip title='複数回このテストに解答した場合、最後の解答内容が表示されます'>
                                        <SelectOutlined />
                                    </Tooltip>
                                </a>
                            )
                        }
                    </>
                );
            },
            title: (
                <>
                    <span style={{ marginRight: '0.5em' }}>
                        得点/満点
                    </span>
                    <Tooltip title='複数回解答した場合、最新の得点・満点が表示されます'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </>
            ),
            width: 70,
        },
        {
            key: 'gradingProgress',
            render: (_, record) => (
                <ScoringStatusTag status={record.gradingProgress} />
            ),
            title: '',
            width: 30,
        },
        {
            dataIndex: 'createdAt',
            render: (createdAt) => {
                if (createdAt) {
                    return (<span>{moment.unix(createdAt).format('YYYY/MM/DD HH:mm')}</span>);
                } else {
                    return '-';
                }
            },
            title: '完了時刻',
            width: 100,
        },
    ];

    const examCategoryStatistics = statisticsExamCategory.result[categoryRecord?.uuid ?? ''];
    const studentStatistics = examCategoryStatistics ?
        examCategoryStatistics[userExam.result?.student?.uuid ?? ''] :
        undefined;
    const sideHeadTableData = studentStatistics ? [studentStatistics] : [{}];

    return (
        <div>
            <Breadcrumb separator='>' style={{ marginBottom: '1rem' }}>
                <Breadcrumb.Item>
                    <button
                        className='text-button'
                        onClick={onClickAllBreadCrumb}
                    >
                        全員・すべてのテスト
                    </button>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {userExam.result?.student?.name}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ flexGrow: 1 }}>
                <Typography.Title level={3}>
                    {`${userExam.result?.student?.name} さんのテスト結果`}
                </Typography.Title>
            </div>

            <Divider style={{ marginBottom: '2rem', marginTop: '0.5rem' }} />

            <Row gutter={16}>
                <Col
                    lg={24}
                    md={24}
                    sm={24}
                    xl={16}
                    xs={24}
                >
                    <Table<DataSource>
                        columns={columnsTest}
                        dataSource={userExam.result?.exams}
                        key='resourceTable'
                        loading={{
                            indicator: <LoadingOutlined style={{ fontSize: '35px' }} />,
                            spinning: userExam.isLoading,
                        }}
                        pagination={false}
                        rowKey='uuid'
                    />
                </Col>
                <Col
                    lg={24}
                    md={24}
                    sm={24}
                    xl={8}
                    xs={24}
                >
                    <SideHeadTable
                        isLoading={statisticsExamCategory.isLoading}
                        onReloadResult={handleReloadResult}
                        tableContent={
                            columnsTypeToSideHeadTableContents<StatisticsData>(
                                StatisticsColumnType(latestResultOnly),
                                sideHeadTableData,
                            )
                        }
                    />
                </Col>
            </Row>
        </div>
    );
};

// ColumnsType<RecordType> を SideHeadTable.Contents に変換する処理
// src/components/presentational/pages/Assessment/ExamCategoryStatus/TestView.tsx での処理をコピペ
function columnsTypeToSideHeadTableContents<
    T extends Record<string, unknown>
>(
    columnsType: ColumnsType<T>,
    records: T[] | undefined[]
) {
    return records.map(record => {
        return columnsType.map((col, i) => {
            if (col.render === undefined) { return undefined; }
            if (record === undefined) {
                return {
                    data: '',
                    header: col.title,
                };
            }

            let value: unknown = undefined;
            if ('dataIndex' in col) {
                const dataIndex = col.dataIndex;

                if (dataIndex === undefined) {
                    value = undefined;
                } else {
                    if (typeof dataIndex === 'string' || typeof dataIndex === 'number') {
                        value = record[dataIndex];
                    } else {
                        value = dataIndex.map(idx => record[idx]);
                    }
                }
            } else {
                value = undefined;
            }

            return {
                data: col.render(value, record, i),
                header: col.title,
            };
        }).filter((item): item is {
            header: string;
            data: string; // 本当はもっと複雑な型だが, SideHeadTable に合わせている
        } => typeof item !== 'undefined');
    })[0];
}

/**
 * 詳細な結果を開くリンクを表示するか
 */
// 一度でもテストを受けていてなおかつ result_url がある場合に表示
function isDisplayDetailResultLink(record: DataSource) {
    return !!record.createdAt && !!record.resultUrl;
}

import { Fragment } from 'react';

import { Input, Select } from 'antd';
import PropTypes from 'prop-types';

import BaseForm from 'components/modules/BaseForm';
import { formRules } from 'constants/GlobalConfig';

import { formItemLayout, formItemLayoutWithoutLabel } from '../EditAppTemplate';
import {
    PredictionParamsPopover,
    PredictedCandidates,
} from './PredictionParams/PredictionParamsPopover';
const Form = BaseForm;
const { Option } = Select;

export const EditParams = ({
    disabled,
    form,
    layout: propsLayout,
    layoutWithoutLabel: propsLayoutWithoutLabel,
    params,
}) => {
    const layout = propsLayout ?? formItemLayout;
    const layoutWithoutLabel = propsLayoutWithoutLabel ?? formItemLayoutWithoutLabel;

    const paramLabels = params.map(v => v.name);
    const required = [formRules.required({ label: undefined })];
    const paramRule = [formRules.required({ label: undefined }), formRules.range({ max: 255 })];

    return (
        <Form.List name='params' >
            {(fields) => (
                fields.map((field, index) => (
                    <Fragment key={field.name}>
                        {(!params[index]?.isHidden && params[index]?.is_choices) && (
                            <Form.Item
                                label={params[index]?.name}
                                name={[field.name, 'ltiParamCandidateUuid']}
                                rules={required}
                                {...(params[index]?.name ? layout : layoutWithoutLabel)}
                            >
                                <Select disabled={disabled} placeholder={`${params[index]?.name}を選択してください`}>
                                    {params[index].candidates.map(variable => (
                                        <Option key={variable.uuid} value={variable.uuid}>
                                            {variable.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        {(!params[index]?.isHidden && !params[index]?.is_choices) && (
                            <Form.Item
                                label={paramLabels[index]}
                                name={[field.name, 'ltiParamValue']}
                                rules={paramRule}
                                {...(paramLabels[index] ? layout : layoutWithoutLabel)}
                            >
                                <Input
                                    disabled={disabled}
                                    placeholder='値を入力してください'
                                    suffix={
                                        <PredictionParamsPopover
                                            fieldName={['params', field.name, 'ltiParamValue']}
                                            form={form}
                                            options={PredictedCandidates.Default}
                                        />
                                    }
                                />
                            </Form.Item>
                        )}
                        {(params[index]?.isHidden) && (
                            <Form.Item hidden name={[field.name, 'ltiParamUuid']}>
                                <Input disabled={disabled} />
                            </Form.Item>
                        )}
                    </Fragment>
                ))
            )}
        </Form.List>
    );
};

EditParams.propTypes = {
    editableParams: PropTypes.array,
    form: PropTypes.object,
    layout: PropTypes.object,
    name: PropTypes.string,
};

import { RightOutlined } from '@ant-design/icons';

import { getMoment } from 'constants/GlobalConfig';

const moment = getMoment();

/***
 * 実施機関や公開期間などのアンケートについての情報を表示する
 */
const QuestionnaireMetaInfo = ({ questionnaire }: { questionnaire: Questionnaire }) => {
    const performDateRange = questionnaire.isPublic ?
        formatDateRange(questionnaire.performStartAt, questionnaire.performEndAt) :
        'なし';
    const publishDateRange = questionnaire.isPublic ?
        formatDateRange(questionnaire.publishStartAt, questionnaire.publishEndAt) :
        'なし';
    const publishResultDateRange = questionnaire.isPublicResult ?
        formatDateRange(questionnaire.publishResultStartAt, questionnaire.publishResultEndAt) :
        'なし';

    const categoryName = questionnaire.category?.name ?? 'なし';

    return (
        <div className='meta'>
            <MetaInfo className='date' label='実施期間' value={performDateRange} />
            <MetaInfo className='date' label='公開期間' value={publishDateRange} />
            <MetaInfo className='date' label='結果公開期間' value={publishResultDateRange} />
            <MetaInfo className='category' label='発信元' value={questionnaire.publishFrom} />
            <MetaInfo className='category' label='カテゴリ' value={categoryName} />
        </div>
    );
};

export default QuestionnaireMetaInfo;


const formatDateRange = (start: number | undefined, end: number | undefined) => {
    if (!start || !end) return 'なし';

    const startDate = moment.unix(start).format('YYYY.MM.DD HH:mm');
    const endDate = moment.unix(end).format('YYYY.MM.DD HH:mm');
    return <>{startDate} <RightOutlined /> {endDate}</>;
};

const MetaInfo = ({
    className,
    label,
    value,
}: {
    className: string;
    label: string;
    value: string | JSX.Element;
}) => {
    return (
        <div className={className}>
            <div className='label'>{label}</div>
            <div className='separator'> : </div>
            {value}
        </div>
    );
};
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Radio, Tooltip, Alert, Checkbox } from 'antd';
import { QuestionCircleOutlined, FileSyncOutlined } from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { getDefaultLayout, querySerialize } from 'constants/GlobalConfig';
import BaseModal from 'components/modules/BaseModal';
import { allowedExtensions, FileUploader } from './FileUploader';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;


const layout = getDefaultLayout(true);

const initialValue = {
    files: [],
};

const IMPORT_TYPE_CREATE_OR_UPDATE = 'create';
const IMPORT_TYPE_DELETE = 'delete';

const CheckBoxOption = [
    { label: 'クラス挿入モード', value: 1 },
];

type Props = {
    isOrganizationType?: boolean
    isUserType?: boolean
    onCancel: () => void
    onOk: () => void
    visible: boolean
};

const UploadCsvModal: React.VFC<Props> = ({
    isOrganizationType = false,
    isUserType = true,
    onCancel = () => console.error('propsの設定'),
    onOk = () => console.error('propsの設定必須'),
    visible = true,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const usersImportConnection = useAppSelector(state => state.usersImportConnection);
    const organizationImportConnection = useAppSelector(state => state.organizationImportConnection);
    const usersDeleteFromCsvConnection = useAppSelector(state => state.usersDeleteFromCsvConnection);
    const classImportConnection = useAppSelector(state => state.classImportConnection);
    const tenant = useAppSelector(state => state.tenant);
    const importType = Form.useWatch('importType', form);
    const dispatch = useDispatch();
    const isFirstRender = useRef(false);

    const typeString = isOrganizationType ? '学校' : isUserType ? 'ユーザー' : 'クラス';

    useEffect(() => {
        isFirstRender.current = true;
        form.resetFields();
        form.setFieldsValue(initialValue);
        // 初回のみ実行
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isFirstRender.current) {
            form.resetFields();
            form.setFieldsValue(initialValue);
        }
        // visbleのみ監視
    }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(usersImportConnection.meta.fetch || organizationImportConnection.meta.fetch);
        if (!isFirstRender.current && visible) {
            if (isOrganizationType) {
                if (organizationImportConnection.meta.status === Actions.statusEnum.SUCCESS) {
                    onOk();
                }
            } else {
                if (usersImportConnection.meta.status === Actions.statusEnum.SUCCESS) {
                    onOk();
                }
            }
        }
        // storeの動きのみ監視
    }, [usersImportConnection, organizationImportConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(usersDeleteFromCsvConnection.meta.fetch);
        if (!isFirstRender.current && visible) {
            if (usersDeleteFromCsvConnection.meta.status === Actions.statusEnum.SUCCESS) {
                onOk();
            }
        }
        // storeの動きのみ監視
    }, [usersDeleteFromCsvConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(classImportConnection.meta.fetch);
        if (!isFirstRender.current && visible) {
            if (classImportConnection.meta.status === Actions.statusEnum.SUCCESS) {
                onOk();
            }
        } else {
            isFirstRender.current = false;
        }
        // storeの動きのみ監視
    }, [classImportConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    const onFinish = (values: Record<string, any>) => {
        const file = values.files[0];
        const fileReader = new FileReader();
        const importType = values.importType;
        const query = querySerialize({
            encoding: values.encoding,
            insert_belong_mode: values.insertBelongMode,
        });

        fileReader.onload = (event) => {

            if (isOrganizationType) {
                dispatch(Actions.http.connection.organization.import(
                    query, file.type, event?.target?.result
                ));
                return;
            }

            if (isUserType) {
                if (importType === IMPORT_TYPE_CREATE_OR_UPDATE) {
                    dispatch(Actions.http.connection.users.import(
                        query, file.type, event?.target?.result
                    ));
                } else if (importType === IMPORT_TYPE_DELETE) {
                    dispatch(Actions.http.connection.users.deleteFromCsv(
                        query, file.type, event?.target?.result
                    ));
                }
            } else {
                dispatch(Actions.http.connection.classes.import(
                    query, file.type, event?.target?.result
                ));
            }
        };
        fileReader.readAsArrayBuffer(file);
    }

    const submit = () => {
        form.submit();
    };

    return (
        <BaseModal
            className='common-modal'
            title={<span><FileSyncOutlined />{typeString}ＣＳＶのインポート</span>}
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button
                    key='back'
                    loading={loading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    loading={loading}
                    onClick={submit}
                    size='large'
                    type='primary'
                >
                    インポートを実行
                </Button>,
            ]}
        >
            <p>
                {typeString}情報をCSVファイルで追加・更新します。<br />
                処理件数により時間がかかることがあります。
            </p>
            <Alert
                message={<DefaultAlertMessage typeString={typeString} />}
                type='warning'
                style={{ marginBottom: '1rem' }}
                showIcon
            />
            <p>
                数字のゼロやハイフンなどの記号の欠落が無いことを確認してください。
            </p>

            {!isOrganizationType && tenant.payload?.type === '3' &&
                <Alert
                    message={<OrganizationAlertMessage />}
                    type='warning'
                    showIcon
                />
            }

            <Form {...layout} form={form} onFinish={onFinish} style={{ margin: '30px' }}>
                {!isOrganizationType && isUserType && (
                    <Form.Item
                        label={
                            <span>
                                インポートタイプ
                                <Tooltip title='ファイルのインポートタイプを選択'>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </span>
                        }
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Item
                                name='importType'
                                rules={[{ required: true, message: 'インポートタイプを選択してください' }]}
                                noStyle
                            >
                                <Radio.Group
                                    {...layout}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        verticalAlign: 'top',
                                        marginRight: '1rem',
                                    }}
                                >
                                    <Radio
                                        value={IMPORT_TYPE_CREATE_OR_UPDATE}
                                        style={{ height: '30px' }}
                                    >
                                        作成/編集
                                    </Radio>
                                    <Radio
                                        value={IMPORT_TYPE_DELETE}
                                        style={{ height: '30px' }}
                                    >
                                        削除
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name='insertBelongMode'
                                noStyle
                                hidden={importType !== IMPORT_TYPE_CREATE_OR_UPDATE}
                            >
                                <Checkbox.Group
                                    options={CheckBoxOption}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        verticalAlign: 'top',
                                        height: '30px',
                                    }}
                                    onChange={() => { }}
                                />
                            </Form.Item>
                        </div>
                    </Form.Item>
                )}


                <Form.Item
                    name='encoding'
                    rules={[{ required: true, message: 'エンコード種別を選択してください' }]}
                    label={
                        <span>
                            エンコード設定
                            <Tooltip title='ファイルのエンコード種別を選択'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    <Radio.Group {...layout} >
                        <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value='sjis'>
                            Shift-JIS 形式
                        </Radio>
                        <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value='utf8'>
                            UTF-8 形式
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <p>
                    CSVファイルの文字エンコードを指定してください。
                    Excelで表示・編集した場合は「Shift-JIS形式」を選択してください。<br />
                    Shift-JIS形式でダウンロードした場合は、
                    UTF-8形式で表現される一部の文字がうまく表示されないことがあります。
                </p>

                <Form.Item
                    name='files'
                    label={`${typeString}情報を含むCSVファイル`}
                    rules={[{ required: true, message: 'ファイルを添付してください' }]}
                >
                    <FileUploader
                        allowedExtension={allowedExtensions.csv}
                        useRawData={true}
                    />
                </Form.Item>
            </Form>

        </BaseModal>
    );
};

export default UploadCsvModal;


const DefaultAlertMessage: React.VFC<{ typeString: string }> = ({ typeString }) => {
    return (
        <span>
            すでに存在する{typeString}ID行は更新され、存在しない{typeString}IDは新規作成されます。<br />
            CSVファイルの１行目は無視されます。<br />
            進捗は画面左下よりご確認いただけます。<br />
            同一ブラウザであれば、ブラウザを閉じても前回の結果を確認することが可能です。<br />
        </span>
    );
};

const OrganizationAlertMessage: React.VFC = () => {
    return (
        <div>
            ユーザー・クラスの同期は時間がかかります。（参考: 1,000件で20～30分）<br />
            通信エラーによる同期の失敗を避けるため、
            一度に同期（登録・更新・削除）する件数を以下までにすることを推奨します。<br />
            ・ 一度に同期するユーザー数: 1,000件以下<br />
            ・ 一度に同期するクラス数: 50件以下<br />
            通信エラーが原因で同期に失敗した場合は、再度同期を実行ください。
        </div>
    );
};
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Progress } from 'antd';
import PropTypes from 'prop-types';
import * as Actions from 'flex/Actions';
import BaseModal from 'components/modules/BaseModal';


SyncPasswordModal.propTypes = {
    visible: PropTypes.bool,
    selectedRows: PropTypes.arrayOf(PropTypes.object),
    passwordResetValue: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};


function SyncPasswordModal(props) {
    const dispatch = useDispatch();
    const [endArray, setEndArray] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentValue, setCurrentValue] = useState(null);

    const usersSyncPasswordConnection = useSelector(state => state.usersSyncPasswordConnection);
    const isFirstRender = useRef(false);

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (props.visible) {
                setCurrentValue(props.selectedRows[currentIndex]);
                handleResetPassword(props.selectedRows[currentIndex].uuid, props.passwordResetValue);
            } else {
                //Parameterリセット
                setEndArray([]);
                setCurrentIndex(0);
                setCurrentValue(null);
            }
        }
    }, [props.visible])// eslint-disable-line

    useEffect(() => {
        if (!isFirstRender.current) {
            if (!usersSyncPasswordConnection.meta.fetch && usersSyncPasswordConnection.meta.status === Actions.statusEnum.SUCCESS) {
                if (currentIndex + 1 === props.selectedRows.length) {
                    message.success('パスワード情報を更新しました');
                    props.onOk();
                } else {
                    setEndArray((prevEndArray) => prevEndArray.concat([currentValue]));
                    setCurrentIndex((prevState) => {
                        setCurrentValue(props.selectedRows[currentIndex + 1]);
                        handleResetPassword(props.selectedRows[currentIndex + 1].uuid, props.passwordResetValue);
                        return prevState + 1;
                    });
                }
            } else if (!usersSyncPasswordConnection.meta.fetch && (usersSyncPasswordConnection.meta.status === Actions.statusEnum.FAILURE || usersSyncPasswordConnection.meta.status === Actions.statusEnum.ERROR)) {
                props.onCancel();
                message.error(
                    `${props.selectedRows.length}件中、${currentIndex + 1}件目でエラーが発生したため、処理を中断します。`
                );
            }
        }
    }, [usersSyncPasswordConnection])// eslint-disable-line

    useEffect(() => {
        if (!isFirstRender.current) {

        } else {
            isFirstRender.current = false;
        }
    }, [dispatch]);


    function handleResetPassword(uuid, value) {
        dispatch(Actions.http.connection.users.syncPassword(uuid, value));
    };

    return (
        <BaseModal
            centered
            title={<span>パスワードリセット実行中</span>}
            visible={props.visible}
            closable={false}
            footer={null}
            maskClosable={false}>
            <div className='job-progress-modal'>
                <p>パスワードリセットを実行しています</p>

                <div>
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={Math.floor(endArray.length / props.selectedRows.length * 100)}
                        status="active"
                    />
                    <p>{props.selectedRows.length}件中 {endArray.length}件</p>
                </div>
            </div>
        </BaseModal>
    );
};

export default SyncPasswordModal;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { convertAdminQuestionnaire, convertQuestionnaire } from '../convertQuestionnaire';
import { useAppSelector } from 'flex/utils';

type Result = {
    isLoading: boolean,
    item: EverydayNote.Questionnaire | undefined,
}

const INIT_STATE: Result = {
    isLoading: false,
    item: undefined,
};

export const useViewQuestionnaire = () => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const adminEverydayNoteControlViewConnection = useAppSelector(state => state.adminEverydayNoteControlViewConnection);
    const adminEverydayNoteViewConnection = useAppSelector(state => state.adminEverydayNoteViewConnection);
    const controlEverydayNoteViewConnection = useAppSelector(state => state.controlEverydayNoteViewConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback((questionnaire: EverydayNote.Questionnaire, tabName?: 'board' | 'school') => {

        const viewAction = isControllableUser ?
            Actions.http.connection.everydayNote.control.view :
            tabName === 'board' ?
                Actions.http.connection.everydayNote.admin.controlView :
                Actions.http.connection.everydayNote.admin.view;

        dispatch(viewAction(questionnaire.uuid));
        setResult(state => ({ ...state, isLoading: true }));
    }, [dispatch, isControllableUser]);

    type Connections = EverydayNoteViewConnection | EverydayNoteControlViewConnection | AdminEverydayNoteViewConnection;
    const connectionEffect = <T extends Connections>(
        connection: T,
        converter: (result: T['payload']['result']) => EverydayNote.Questionnaire,
    ) => () => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;

                setResult({
                    isLoading: false,
                    item: converter(payloadResult),
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(adminEverydayNoteControlViewConnection, convertQuestionnaire),
        [adminEverydayNoteControlViewConnection]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(adminEverydayNoteViewConnection, convertAdminQuestionnaire),
        [adminEverydayNoteViewConnection]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(controlEverydayNoteViewConnection, convertQuestionnaire),
        [controlEverydayNoteViewConnection]
    );

    return [result, request, reset] as const;
};

import { createAction } from 'redux-actions';

import {
    API_QUESTIONNAIRES,
    API_QUESTIONNAIRES_ANSWER,
    API_CONTROL_QUESTIONNAIRES,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

import type { Meta, Payload } from '../utils';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/QUESTIONNAIRES';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
};


export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return ({ method: 'get', data, api: `${API_QUESTIONNAIRES}/${pageNum}` });
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_QUESTIONNAIRES + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_QUESTIONNAIRES + '/update/' + uuid,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const view = createAction(
    createActionType('VIEW' as const),
    (uuid, viewName) => ({ method: 'get', api: API_QUESTIONNAIRES + '/view/' + uuid }),
    (uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_QUESTIONNAIRES + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deleteAction as delete };

export const answerResult = createAction(
    createActionType('ANSWER_RESULT' as const),
    (uuid, viewName) => ({ method: 'get', api: API_QUESTIONNAIRES + '/answer-result/' + uuid }),
    (uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const textResult = createAction(
    createActionType('TEXT_RESULT' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return { method: 'get', data, api: API_QUESTIONNAIRES_ANSWER + `/text-result/${uuid}/${pageNum}` };
    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const fileResult = createAction(
    createActionType('FILE_RESULT' as const),
    (uuid, data, page, viewName) => {
        const pageNum: string = page === undefined ? '' : page;
        return { method: 'get', data, api: API_QUESTIONNAIRES_ANSWER + `/file-result/${uuid}/${pageNum}` };

    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const target = createAction(
    createActionType('TARGET' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '1' : page;
        return ({
            method: 'get',
            api: `${API_QUESTIONNAIRES}/target-users/${uuid}/${pageNum}`,
            data,
        });
    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, uuid, viewName })
);

export const userResultCsv = createAction(
    createActionType('USER_RESULT_CSV' as const),
    (uuid, data) => {
        return ({ method: 'get', api: `${API_QUESTIONNAIRES_ANSWER}/user-result-csv/${uuid}`, data });
    },
    (uuid) => ({ fetch: true, status: StatusEnum.REQUEST, uuid })
);

export const downloadCsv = createAction(
    createActionType('DOWNLOAD_CSV' as const),
    (uuid, data) => {
        return ({ method: 'get', api: `${API_QUESTIONNAIRES}/download-csv/${uuid}`, data });
    },
    (uuid) => ({ fetch: true, status: StatusEnum.REQUEST, uuid })
);

export const answerResultByItem = createAction<
    unknown,
    unknown,
    string,
    string
>(
    createActionType('ANSWER_RESULT_BY_ITEM' as const),
    (questionnaireUuid, questionUuid) => {
        return ({
            api: `${API_QUESTIONNAIRES}/answer-result-by-item/${questionnaireUuid}/${questionUuid}`,
            method: 'get',
        });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

////////////////////////////////
// 全体管理者
////////////////////////////////

export const controlSearch = createAction(
    createActionType('CONTROL_SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return ({
            method: 'get',
            data,
            api: `${API_CONTROL_QUESTIONNAIRES}/${pageNum}`,
        });
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlCreate = createAction(
    createActionType('CONTROL_CREATE' as const),
    (data) => ({
        method: 'put',
        api: `${API_CONTROL_QUESTIONNAIRES}/create`,
        timeout: 300000,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlUpdate = createAction(
    createActionType('CONTROL_UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: `${API_CONTROL_QUESTIONNAIRES}/update/${uuid}`,
        timeout: 300000,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlView = createAction(
    createActionType('CONTROL_VIEW' as const),
    (uuid, viewName) => ({
        method: 'get',
        api: `${API_CONTROL_QUESTIONNAIRES}/view/${uuid}`,
    }),
    (uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

const controlDeleteAction = createAction(
    createActionType('CONTROL_DELETE' as const),
    (uuid) => ({
        method: 'delete',
        api: `${API_CONTROL_QUESTIONNAIRES}/delete/${uuid}`,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { controlDeleteAction as controlDelete };

export const controlAnswerResult = createAction(
    createActionType('CONTROL_ANSWER_RESULT' as const),
    (uuid, viewName) => ({
        method: 'get',
        api: `${API_CONTROL_QUESTIONNAIRES}/answer-result/${uuid}`,
    }),
    (uuid, viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        uuid,
        viewName,
    })
);

export const controlTarget = createAction(
    createActionType('CONTROL_TARGET' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '1' : page;
        return ({
            method: 'get',
            api: `${API_CONTROL_QUESTIONNAIRES}/target-users/${uuid}/${pageNum}`,
            data,
        });
    },
    (uuid, data, page, viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName,
        uuid,
    })
);

export const controlDownloadCsv = createAction(
    createActionType('CONTROL_DOWNLOAD_CSV' as const),
    (uuid, data) => {
        return ({
            method: 'get',
            api: `${API_CONTROL_QUESTIONNAIRES}/download-csv/${uuid}`,
            data,
        });
    },
    (uuid) => ({ fetch: true, status: StatusEnum.REQUEST, uuid })
);

export const controlTextResult = createAction(
    createActionType('CONTROL_TEXT_RESULT' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return {
            method: 'get',
            data,
            api: `${API_QUESTIONNAIRES_ANSWER}/text-result/${uuid}/${pageNum}`,
        };
    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlFileResult = createAction(
    createActionType('CONTROL_FILE_RESULT' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return {
            method: 'get',
            data,
            api: `${API_QUESTIONNAIRES_ANSWER}/file-result/${uuid}/${pageNum}`,
        };

    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlUserResultCsv = createAction(
    createActionType('CONTROL_USER_RESULT_CSV' as const),
    (uuid, data) => {
        return {
            method: 'get',
            data,
            api: `${API_QUESTIONNAIRES_ANSWER}/user-result-csv/${uuid}`,
        };
    },
    (uuid) => ({ fetch: true, status: StatusEnum.REQUEST, uuid })
);

export const controlAnswerResultByItem = createAction<
    unknown,
    unknown,
    string,
    string
>(
    createActionType('CONTROL_ANSWER_RESULT_BY_ITEM' as const),
    (questionnaireUuid, questionUuid) => {
        return ({
            api: `${API_CONTROL_QUESTIONNAIRES}/answer-result-by-item/${questionnaireUuid}/${questionUuid}`,
            method: 'get',
        });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlTargetOrganization = createAction<
    Payload | Http.Connection.Response.Questionnaire.Control.ControlTargetOrganization,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_ORGANIZATION' as const),
    (questionnaireUuid) => {
        return {
            api: `${API_CONTROL_QUESTIONNAIRES}/target-organization/${questionnaireUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const controlTargetSchoolClass = createAction<
    Payload | Http.Connection.Response.Questionnaire.Control.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_SCHOOL_CLASS' as const),
    (questionnaireUuid) => {
        return {
            api: `${API_CONTROL_QUESTIONNAIRES}/target-school-class/${questionnaireUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);


export const targetSchoolClass = createAction<
    Payload | Http.Connection.Response.Questionnaire.Control.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('TARGET_SCHOOL_CLASS' as const),
    (questionnaireUuid) => {
        return {
            api: `${API_QUESTIONNAIRES}/target-school-classes/${questionnaireUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

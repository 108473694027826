import { useEffect, useState } from 'react';
import * as React from 'react';

import { statusEnum } from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import Template from '../Template';
import ConfirmAnswerResultModal from '../Template/Modals/ConfirmAnswerResultModal';
import QuestionnaireModal from '../Template/Modals/QuestionnaireModal';
import { convertAdminFormValue, convertControlFormValue } from '../utils/convertFormValue';
import { genConvertRequestableSearchCondition } from '../utils/convertRequestableSearchCondition';
import { useEditQuestionnaire } from '../utils/CustomHooks/useEditQuestionnaire';
import { useSearchQuestionnaire } from '../utils/CustomHooks/useSearchQuestionnaire';
import { useViewQuestionnaire } from '../utils/CustomHooks/useViewQuestionnaire';
import { convertSubmittableValue } from './utils/convertSubmittableValue';

const INIT_SEARCH_CONDITION = {
    name: '',
    page: 1,
    pageSize: 50,
};

type Props = {
    activeTab: 'school' | 'board';
}

const BoardOfEducationTabContents: React.VFC<Props> = ({
    activeTab,
}) => {
    const [
        searchQuestionnaire,
        fetchSearchQuestionnaire,
    ] = useSearchQuestionnaire(activeTab);
    const [
        viewQuestionnaire,
        fetchViewQuestionnaire,
        resetViewQuestionnaire,
    ] = useViewQuestionnaire();
    const [
        editQuestionnaire,
        ,
        updateQuestionnaire,
        resetEditQuestionnaire,
    ] = useEditQuestionnaire();
    const [searchCondition, setSearchCondition] = useState<EverydayNote.SearchConditions>(INIT_SEARCH_CONDITION);
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const [visibleResultModal, setVisibleResultModal] = useState(false);
    const [questionnaire, setQuestionnaire] = useState<EverydayNote.ControlQuestionnaire>();
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const user = useAppSelector(state => state.user);
    const organization = (user.payload.organization);

    const handleAnswerResultClick = (questionnaire: EverydayNote.ControlQuestionnaire) => {
        setVisibleResultModal(true);
        setQuestionnaire(questionnaire);
    };

    const handleAnswerResultClose = () => {
        setVisibleResultModal(false);
    };

    const handleChangeSearchMode = () => {
        setSearchCondition(condition => {
            const { name, page, pageSize } = condition;
            return {
                name,
                page,
                pageSize,
            };
        });
    };

    const handleEditCancel = () => {
        setVisibleEditModal(false);
        resetViewQuestionnaire();
    };

    const handleOk = (values: EverydayNote.FormValue) => {
        if ('canAdminSetPublic' in values) return;
        if (!('targetOrganizations' in values)) return;

        const submitValue = convertSubmittableValue(values, false);

        if ('uuid' in submitValue && submitValue.uuid !== undefined) {
            updateQuestionnaire(submitValue, 'board');
        }
    };

    const handleSearch = (condition?: Partial<EverydayNote.SearchConditions>) => {
        const newCondition = {
            ...searchCondition,
            ...condition,
        };

        setSearchCondition(newCondition);
        fetchSearchQuestionnaire(newCondition, 'board');
    };

    const handleUpdateClick = (questionnaire: EverydayNote.Questionnaire) => {
        return fetchViewQuestionnaire(questionnaire, 'board');
    };

    useEffect(() => {
        if(activeTab !== 'board') return;

        handleSearch(INIT_SEARCH_CONDITION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        if (!viewQuestionnaire.isLoading) return;

        setVisibleEditModal(true);
    }, [viewQuestionnaire.isLoading]);

    useEffect(() => {
        const result = editQuestionnaire.result;

        if (result?.status !== statusEnum.SUCCESS) return;

        if (result?.isSuccessful) {
            setVisibleEditModal(false);
            resetViewQuestionnaire();
            resetEditQuestionnaire();
            handleSearch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editQuestionnaire]);

    const isModalLoading = viewQuestionnaire.isLoading || editQuestionnaire.isLoading;

    // ここでは convertAdminFormValue しか使われないが、型推論のために色々書いている
    const modalFormValue = React.useMemo(() => {
        if (viewQuestionnaire.isLoading) return undefined;

        return viewQuestionnaire.item && 'canAdminSetPublic' in viewQuestionnaire.item ?
            convertControlFormValue(viewQuestionnaire.item, false) :
            convertAdminFormValue(viewQuestionnaire.item, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewQuestionnaire.isLoading, visibleEditModal]);

    const convertRequestableSearchCondition = genConvertRequestableSearchCondition(isControllableUser);

    return (
        <>
            <Template<EverydayNote.ControlQuestionnaire>
                currentPage={searchCondition.page}
                currentTab='board'
                // 一旦、型を適切に表現するのが難しいので型アサーションで対応
                dataSource={searchQuestionnaire.items as EverydayNote.ControlQuestionnaire[]}
                initialPagination={{
                    current: INIT_SEARCH_CONDITION.page,
                    pageSize: INIT_SEARCH_CONDITION.pageSize,
                }}
                isControllableUser={isControllableUser}
                loading={searchQuestionnaire.isLoading}
                onAnswerResultClick={handleAnswerResultClick}
                onChangeSearchMode={handleChangeSearchMode}
                onSearch={handleSearch}
                onUpdateClick={handleUpdateClick}
                totalDataCount={searchQuestionnaire.itemCount}
            />

            <QuestionnaireModal
                isEditAdmin={true}
                loading={isModalLoading}
                onCancel={handleEditCancel}
                onOk={handleOk}
                questionnaire={modalFormValue}
                tabName='board'
                visible={visibleEditModal}
            />

            <ConfirmAnswerResultModal
                convertRequestableSearchCondition={convertRequestableSearchCondition}
                loading={false}
                onClose={handleAnswerResultClose}
                organization={organization}
                questionnaire={questionnaire}
                tabName='board'
                visible={visibleResultModal}
            />
        </>
    );
};

export default BoardOfEducationTabContents;

import * as Actions from 'flex/Actions'
import { createAddReducer } from 'flex/utils';
import { DEFAULT_STATE } from './index';

const addReducer = createAddReducer<UserManagementTabState>()

export const reducer = {
    'userManagementTab': (state: any, action: any) => ({ ...action }),
    // TODO: 可能であれば, addReducer のような関数を用いて, 自動的に型を付けられるようにする
    [Actions.view.viewLogic.userManagementTab.downloadCsv.toString()]: (state: any, action: { encoding: any }) => ({ ...action }),
    [Actions.view.viewLogic.userManagementTab.editSubmit.toString()]: (state: any, action: {}) => ({ ...action }),
    [Actions.view.viewLogic.userManagementTab.reset.toString()]: (state: any, action: {}) => ({ ...action }),

    ...addReducer(Actions.view.viewLogic.userManagementTab.closeView, (state, action) => ({
        ...state,
        ...DEFAULT_STATE,
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.editCancel, (state, action) => ({
        ...state,
        editUser: {
            loading: true,
            userRecord: null,
            isEditOpen: false,
            shouldCreate: false,
        },
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.openCreate, (state, action) => ({
        ...state,
        editUser: {
            loading: true,
            userRecord: null,
            isEditOpen: true,
            shouldCreate: true,
        },
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.openEdit, (state, action) => ({
        ...state,
        editUser: {
            loading: true,
            userRecord: null,
            isEditOpen: true,
            shouldCreate: false,
        },
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.resetControllableUser, (state, action) => ({
        ...state,
        selectTerm: action.payload.term_uuid,
        classListMap: {},
        classListMapItemCount: {},
        classListMapListCount: 0,
        roleList: [],
        roleListItemCount: 0,
        selectOrganization: undefined
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.resetUncontrollableUser, (state, action) => ({
        ...state,
        selectTerm: action.payload.term_uuid
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.savePagination, (state, action) => ({
        ...state,
        pagination: {
            ...state.pagination,
            pageSize: action.payload.page_size,
            current: action.payload.page
        },
        type: 'userManagementTab'
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.search, (state, action) => ({
        ...state,
        search: {
            ...state.search,
            ...action.payload.values,
        }
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.selectOrganization, (state, action) => ({
        ...state,
        // 年度や、役割は初期化する
        classListMap: {},
        classListMapItemCount: {},
        classListMapListCount: 0,
        roleList: [],
        roleListItemCount: 0,
        selectOrganization: action.payload.organization_uuid,
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.selectRows, (state, action) => ({
        ...state,
        currentSelectedRows: action.payload.selectedRows
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.selectTerm, (state, action) => ({
        ...state,
        selectTerm: action.payload.term_uuid
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.submitMultipleReset, (state, action) => ({
        ...state,
        multipleReset: {
            isResetClass: action.payload.data.is_forced_change_class,
            isResetName: action.payload.data.is_forced_change_name,
        },
    })),
    ...addReducer(Actions.view.viewLogic.userManagementTab.pullSelectedRows, (state, action) => {
        const selectedRows = state.selectedRows ?? [];

        const currentSelectedRows = selectedRows.filter(row => {
            return action.payload.displayedRowUuids.includes(row.uuid);
        });
        const newSelectedRows = selectedRows.filter(row => {
            return !action.payload.displayedRowUuids.includes(row.uuid);
        });

        return {
            ...state,
            selectedRows: newSelectedRows,
            currentSelectedRows: currentSelectedRows,
        };
    }),
    ...addReducer(Actions.view.viewLogic.userManagementTab.pushSelectedRows, (state, action) => {
        const selectedRows = state.selectedRows ?? [];
        const currentSelectedRows = state.currentSelectedRows ?? [];
        const newSelectedRows = Array.from(
            new Set(selectedRows.concat(currentSelectedRows))
        );

        return {
            ...state,
            selectedRows: newSelectedRows,
            currentSelectedRows: [],
        };
    }),
}
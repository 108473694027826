import React from 'react';

type Props = {
    percent: number
}

export const Rate: React.VFC<Props> = ({
    percent,
}) => {
    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            {/* 枠 */}
            <div
                style={{
                    border: '1px solid lightgray',
                    borderRadius: '1rem',
                    height: 'calc(1rem + 2px)',
                    position: 'relative',
                    top: 0,
                    width: '100%',
                    zIndex: 3,
                }}
            >
            </div>
            {/* ゲージ */}
            <div style={{
                backgroundColor: '#64B84A',
                borderRadius: '1rem',
                height: '1rem',
                position: 'absolute',
                top: '1px',
                width: '100% ',
                zIndex: 1,
            }}
            />
            {/* ゲージを削るパーツ */}
            <div style={{
                backgroundColor: '#fff',
                height: '1rem',
                left: `calc((100% - 2px) * ${percent} / 100 + 1px)`,
                position: 'absolute',
                top: '1px',
                width: `calc((100% - 2px) * ${100 - percent} / 100)`,
                zIndex: 2,
            }}
            />
        </div>
    );
};
import { combineActions, handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        // たぶんいらないが念の為残しておく
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


// カテゴリ
export const notificationCategorySearchConnection = handleAction(
    Actions.http.connection.notification.category.search,
    defaultReducer,
    defaultState
);

export const notificationCategoryEditConnection = handleAction(
    combineActions(
        Actions.http.connection.notification.category.delete,
        Actions.http.connection.notification.category.update,
        Actions.http.connection.notification.category.create
    ),
    defaultReducer,
    defaultState
);

// 本体
export const notificationSearchConnection = handleAction(
    combineActions(
        Actions.http.connection.notification.search,
        Actions.http.connection.notification.controlSearch
    ),
    defaultReducer,
    defaultState
);

export const notificationEditConnection = handleAction(
    combineActions(
        Actions.http.connection.notification.delete,
        Actions.http.connection.notification.controlDelete,
        Actions.http.connection.notification.update,
        Actions.http.connection.notification.controlUpdate,
        Actions.http.connection.notification.create,
        Actions.http.connection.notification.controlCreate
    ),
    defaultReducer,
    defaultState
);

export const notificationTargetConnection = handleAction(
    combineActions(
        Actions.http.connection.notification.target,
        Actions.http.connection.notification.controlTarget
    ),
    defaultReducer,
    defaultState
);

export const notificationViewConnection = handleAction(
    combineActions(
        Actions.http.connection.notification.view,
        Actions.http.connection.notification.controlView
    ),
    defaultReducer,
    defaultState
);

export const notificationControlTargetOrganizationConnection = handleAction(
    Actions.http.connection.notification.controlTargetOrganization,
    defaultReducer,
    defaultState
);

export const notificationControlTargetSchoolClassConnection = handleAction(
    Actions.http.connection.notification.controlTargetSchoolClass,
    defaultReducer,
    defaultState
);

export const notificationTargetSchoolClassConnection = handleAction(
    Actions.http.connection.notification.targetSchoolClass,
    defaultReducer,
    defaultState
);
import { useContext } from 'react';
import {
    Col,
    Divider,
    Row,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import {
    InfoCircleOutlined,
    LoadingOutlined,
    SelectOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';
import { getMoment } from 'constants/GlobalConfig';
import { ScoringStatusTag } from './ScoringStatusTag';
import { useStudentViewData } from '../utils/useStudentViewData';
import { BreadCrumbContext } from './index';

const moment = getMoment();

// antd の Tree data の表示を使うための型
type DataSource = AppStatusModal.StudentView['applications'][number];

export const StudentView = () => {
    const { breadcrumb, setBreadcrumb } = useContext(BreadCrumbContext);
    const [studentView] = useStudentViewData({ name: breadcrumb.name ?? '', uuid: breadcrumb.uuid });

    const handleClickTestInfo = (name: string, uuid: string) => {
        setBreadcrumb({ name: name, uuid: uuid, type: 'Application' });
    };

    const columnsTest: ColumnsType<DataSource> = [
        {
            title: '教材名',
            width: 250,
            render: (_, record) => {
                return (
                    <button
                        className='text-button'
                        onClick={() => { handleClickTestInfo(record.title, record.uuid) }}
                    >
                        {record.title}
                    </button>
                );
            }
        },
        {
            title: (
                <>
                    <span style={{ marginRight: '0.5em' }}>
                        得点/満点
                    </span>
                    <Tooltip title='複数回解答した場合、最新の得点・満点が表示されます'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </>
            ),
            width: 70,
            render: (_, record) => {
                return <>
                    <span style={{ marginRight: '1rem' }}>
                        {`${record.score ?? '-'} / ${record.maxScore ?? '-'}`}
                    </span>
                    {isDisplayDetailResultLink(record) &&
                        <a href={record.resultUrl} target='_blank' rel='noreferrer'>
                            <Tooltip title='複数回この教材に解答した場合、最後の解答内容が表示されます'>
                                <SelectOutlined />
                            </Tooltip>
                        </a>
                    }
                </>;
            }
        },
        {
            title: '',
            width: 30,
            key: 'gradingProgress',
            render: (_, record) => (
                <ScoringStatusTag status={record.gradingProgress} />
            ),
        },
        {
            title: '完了時刻',
            width: 100,
            dataIndex: 'createdAt',
            render: (_, record) => {
                if (record.createdAt && !!record?.activityProgress) {
                    return (<span>{moment.unix(record.createdAt).format('YYYY/MM/DD HH:mm')}</span>);
                } else {
                    return '-';
                }
            }
        }
    ];

    return (
        <div>
            <Typography.Title level={3}>
                {`${studentView.student.name} さんの学習結果`}
            </Typography.Title>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '2rem' }} />

            <Row gutter={16}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={16}
                >
                    <Table<DataSource>
                        rowKey='uuid'
                        key='resourceTable'
                        columns={columnsTest}
                        dataSource={studentView.applications}
                        pagination={false}
                        loading={{
                            spinning: studentView.loading,
                            indicator: <LoadingOutlined style={{ fontSize: '35px' }} />,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

/**
 * 詳細な結果を開くリンクを表示するか
 */
// 一度でもテストを受けていてなおかつ result_url がある場合に表示
const isDisplayDetailResultLink = (record: DataSource) => {
    return !!record.createdAt && !!record.resultUrl;
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { TERM_TYPE, TermTypes } from '../SearchForm';

const moment = getMoment();

type Response = Http.Connection.Response.Exam.Graph['result']['items'][number];

type ExamWithInfo = {
    exam: Response['exam_item']
    info: Expand<Omit<Response, 'exam_item'>>
    graph: {
        total: number
        value: number
    }
}

export type ExamWithInfoState = {
    isAllClass: boolean
    result: ExamWithInfo[]
    isLoading: boolean
};

type Connection = TaoExamGraphConnection | TaoExamControlGraphConnection;

export const useGraphExam = () => {
    const [state, setState] = useState<ExamWithInfoState>({
        isAllClass: false,
        isLoading: false,
        result: [],
    });
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamGraphConnection = useAppSelector(state => state.taoExamGraphConnection);
    const taoExamControlGraphConnection = useAppSelector(state => state.taoExamControlGraphConnection);

    const dispatch = useDispatch();


    const effect = useCallback((connection: Connection, state: ExamWithInfoState) => {
        const { meta, payload: { result } } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: result.items.map(item => {
                        const { exam_item, ...info } = item;
                        const target = isControllableUser ? exam_item : info;

                        return {
                            exam: exam_item,
                            graph: {
                                total: info.total_average_per ?? 0,
                                value: state.isAllClass ?
                                    target.total_average_per ?? 0 :
                                    target.unit_average_per ?? 0,
                            },
                            info,
                        };
                    }),
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
    }, [isControllableUser]);

    useEffect(
        () => effect(taoExamGraphConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamGraphConnection]
    );
    useEffect(
        () => effect(taoExamControlGraphConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlGraphConnection]
    );


    const fetchData = useCallback((
        uuid: string,
        classUuid: string,
        tab: TabKeys | '',
        organizationUuid?: string
    ) => {
        setState({
            isAllClass: tab === '' ? false : !classUuid,
            isLoading: true,
            result: [],
        });

        const graphAction = isControllableUser ?
            Actions.http.connection.tao.exam.controlGraph(uuid, { organization_uuid: organizationUuid, school_class_uuid: classUuid }) :
            tab === 'board' ?
                Actions.http.connection.tao.exam.controlGraph(uuid, { school_class_uuid: classUuid }) :
                Actions.http.connection.tao.exam.graph(uuid, { school_class_uuid: classUuid });

        dispatch(graphAction);

    }, [dispatch, isControllableUser]);

    return [state, fetchData] as const;
};

// alias: graph という命名が微妙なので、無難な名前に変更
export const useExamWithInfo = useGraphExam;


const TIME_KEY_FORMAT_TYPE_DAY = 'MM/DD/(ddd)';
const BACKEND_TIME_KEY_FORMAT_TYPE_DAY = 'YYYY-MM-DD';

const TIME_KEY_FORMAT_TYPE_TIME = 'H時';
const BACKEND_TIME_KEY_FORMAT_TYPE_TIME = 'YYYY-MM-DD HH:mm:ss';


// src\components\presentational\pages\Usage\Common\utils.js
export const createTimeKeys = (term: moment.Moment, termType: TermTypes) => {
    const start = term.startOf(termType);

    switch (termType) {
        case TERM_TYPE.MONTH: {
            const s = moment(term).startOf('month').date();
            const e = moment(term).endOf('month').date();

            const diff = e - s + 1;
            return [...Array(diff)].map((_, i) => [
                moment(start).add(i, 'days').format(BACKEND_TIME_KEY_FORMAT_TYPE_DAY),
                moment(start).add(i, 'days').format(TIME_KEY_FORMAT_TYPE_DAY),
            ]);
        }
        case TERM_TYPE.WEEK:
            return [...Array(7)].map((_, i) => [
                moment(start).add(i, 'days').format(BACKEND_TIME_KEY_FORMAT_TYPE_DAY),
                moment(start).add(i, 'days').format(TIME_KEY_FORMAT_TYPE_DAY),
            ]);
        case TERM_TYPE.DATE:
            return [...Array(24)].map((_, i) =>
                [
                    moment(start).add(i, 'hours').format(BACKEND_TIME_KEY_FORMAT_TYPE_TIME),
                    moment(start).add(i, 'hours').format(TIME_KEY_FORMAT_TYPE_TIME),
                ]
            );
        default:
            return [];
    }
};
import {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlusCircleFilled, SyncOutlined, ImportOutlined } from '@ant-design/icons';
import {
    Button,
    message,
    Select,
    Tooltip,
} from 'antd';

import { BaseTable } from 'components/modules/BaseTable';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import { useJobReloadEffect } from 'components/modules/Job/useJobReloadEffect';
import UploadCsvModal from 'components/modules/UploadCSVModal';
import { useUrlParameters } from 'constants/CustomHooks/useUrlParameters';
import {
    addAllTermAndSort,
    getCurrentTerm,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { classesManagementTabSelectors } from 'flex/view/UsersManagement/ClassesManagementTab/selectors';

import { ListContext, TabKeyEnum } from '../../index';
import AnnualUpdateModal from '../Common/AnnualUpdateModal';
import EditClassModal from '../Common/EditClassModal';
import { createColumns } from '../Common/createColumns';


const { statusEnum } = Actions;
const { Option } = Select;
const SHOULD_RELOAD_BACKGROUND_JOBS_IF_SUCCESS = [BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT];
const defaultSearchRule = {
    page_size: 50,
    term_uuid: '',
};

const AdminContents = ({
    tabActiveKey,
    hasTouchScreen,
}) => {
    const dispatch = useDispatch();
    const [list] = useContext(ListContext);
    const urlParameters = useUrlParameters();
    const [search, setSearch] = useState(defaultSearchRule);
    const [isDeleteStarted, setIsDeleteStarted] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isCsvUploadOpen, setIsCsvUploadOpen] = useState(false);
    const [isAnnualUpdateOpen, setIsAnnualUpdateOpen] = useState(false);
    const [shouldCreate, setShouldCreate] = useState(true);
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const csvExportDisabledMsg = useSelector(classesManagementTabSelectors.csvExportDisabledMsg);
    const csvImportDisabledMsg = useSelector(classesManagementTabSelectors.csvImportDisabledMsg);
    const classDeleteConnection = useSelector(state => state.classDeleteConnection);
    const classSearchConnection = useSelector(state => state.classSearchConnection);
    const classExportConnection = useSelector(state => state.classExportConnection);
    const classImportConnection = useSelector(state => state.classImportConnection);
    const classViewConnection = useSelector(state => state.classViewConnection);
    const jobInfoMap = useSelector(state => state.jobInfoMap);
    const [pagination, setPagination] = useState({
        onChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size,
            }));
            setSearch(search => ({
                ...search,
                page_size: page_size,
            }));
            dispatch(Actions.http.connection.classes.search({ page_size, term_uuid: search.term_uuid }, page));
        },
        pageSize: defaultSearchRule.page_size,
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: list.classListItemCount,
    });

    const isFirstRender = useRef(false);

    useEffect(() => {
        isFirstRender.current = true;
        dispatch(Actions.http.connection.grade.search());
    }, [dispatch]);

    useEffect(() => {
        const uuid = urlParameters['uuid'];
        if (!uuid) return;

        const { meta: { status } } = classViewConnection;

        if (status === '' || status === Actions.statusEnum.LOADING) {
            dispatch(Actions.http.connection.classes.view(uuid));
        }
    }, [urlParameters]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const { meta, payload } = classViewConnection;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                handleEditClick(payload.result);
                break;
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
            default:
                break;
        }
    }, [classViewConnection]);

    // バックグラウンドジョブ終了後に別の画面に遷移し, 再度クラス管理に戻ってくると,
    // 他の effect の後にこの effect が走り, 表示されるデータの件数がおかしくなる.
    // 場当たり的ではあるが, 一旦, この effect をコード上部に配置し対応する.
    // 関連 issue: https://github.com/sibaservice/learning-gateway-frontend-admin/issues/1837
    useJobReloadEffect(
        SHOULD_RELOAD_BACKGROUND_JOBS_IF_SUCCESS,
        () => {
            if (isFirstRender.current || tabActiveKey !== TabKeyEnum.CLASSES) return;
            fetchClassList();
        },
    );

    useEffect(() => {
        setPagination({
            ...pagination,
            onChange: (page, page_size) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize: page_size,
                }));
                setSearch(search => ({
                    ...search,
                    page_size: page_size,
                }));
                dispatch(Actions.http.connection.classes.search({ page_size, term_uuid: search.term_uuid }, page));
            },
        });
        // searchのみ監視したいため
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 初期値のセット、初期検索
        if (list.termListItemCount === 0 || tabActiveKey !== TabKeyEnum.CLASSES) return;

        const currentTermUuid = getCurrentTerm(list.termList).uuid;

        setPagination(pagination => ({ ...pagination, current: 1, page_size: defaultSearchRule.page_size }));
        setSearch({ page_size: defaultSearchRule.page_size, term_uuid: currentTermUuid });

        dispatch(Actions.http.connection.classes.search({ page_size: defaultSearchRule.page_size, term_uuid: currentTermUuid }));
    }, [list.termList, list.termListItemCount, dispatch, tabActiveKey]);

    useEffect(() => {// 削除処理
        if (!isDeleteStarted) return;

        if (classDeleteConnection.meta.status === statusEnum.SUCCESS) {
            setIsDeleteStarted(false);
            setPagination(pagination => ({
                ...pagination,
                current: 1,
            }));
            dispatch(Actions.http.connection.classes.search(search, 1));
        }
    }, [classDeleteConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            total: list.classListItemCount,
        }));
    }, [list.classList, list.classListItemCount]);

    useEffect(() => {
        switch (classSearchConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING:
                setLoading(true);
                break;
            case Actions.statusEnum.SUCCESS:
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
                setLoading(false);
                break;
            default:
                break;
        }
    }, [classSearchConnection]);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    const loadingCsvExport = useMemo(() => classExportConnection.meta.fetch, [classExportConnection]);
    const disabledCsvExport = useMemo(() =>
        list.classListItemCount === 0 ||
        classExportConnection.meta.fetch ||
        Object.values(jobInfoMap).filter(jobInfo => jobInfo?.job?.type === BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_EXPORT).length > 0,
        [classExportConnection, jobInfoMap, list]);
    const disabledCsvImport = useMemo(() =>
        classImportConnection.meta.fetch ||
        Object.values(jobInfoMap).filter(jobInfo => jobInfo?.job?.type === BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT).length > 0,
        [classImportConnection, jobInfoMap]);

    const fetchClassList = () => {
        if (shouldCreate) {
            setPagination(pagination => ({
                ...pagination,
                current: 1,
            }));
            dispatch(Actions.http.connection.classes.search(search));
        } else {
            dispatch(Actions.http.connection.classes.search(search, pagination.current));
        }
    };

    // 検索結果をCSVでダウンロードさせる
    const handleCsvDownload = (encoding) => {
        const fieldsValue = {
            encoding,
            ...search,
        };
        delete fieldsValue.page;

        dispatch(Actions.http.connection.classes.export(fieldsValue));
    };

    const handleCreateClick = () => {
        setRecord(null);
        setShouldCreate(true);
        setIsEditOpen(true);
    };

    const handleEditClick = (record) => {
        setRecord(record);
        setShouldCreate(false);
        setIsEditOpen(true);
    };

    const handleDeleteClick = (id) => {
        dispatch(Actions.http.connection.classes.delete(id));
        setIsDeleteStarted(true);
    };

    const handleEditCancel = () => setIsEditOpen(false);

    const handleEditSubmitted = () => {
        setIsEditOpen(false);
        fetchClassList();
    };

    const handleCsvUploadSubmit = () => {
        setIsCsvUploadOpen(false);
        fetchClassList();
    };

    const handleCsvUploadCancel = () => setIsCsvUploadOpen(false);

    const handleAnnualUpdateSubmit = () => {
        setIsAnnualUpdateOpen(false);
        message.success('クラス変更フラグの更新に成功しました。');
    };

    const handleAnnualUpdateCancel = () => setIsAnnualUpdateOpen(false);

    /***
     * 年度プルダウンを選択するごとに、クラスに対して再検索がかかる
     * @param term_uuid
     */
    const handleSelectTerm = (termUuid) => {
        setPagination(pagination => ({ ...pagination, current: 1 }));
        const newSearch = { ...search, term_uuid: termUuid };
        setSearch(newSearch);
        dispatch(Actions.http.connection.classes.search(newSearch));
    };

    // クラスリストのcolumns作成
    const columns = createColumns(hasTouchScreen, handleEditClick, handleDeleteClick);

    return (
        <div className='classs-list container'>
            <div className='flex-right-container gutter-bottom'>
                <Button
                    icon={<SyncOutlined />}
                    onClick={() => setIsAnnualUpdateOpen(true)}
                    type='primary'
                >
                    年次更新
                </Button>
                <Tooltip title={csvImportDisabledMsg}>
                    <Button
                        disabled={disabledCsvImport}
                        icon={<ImportOutlined />}
                        loading={loadingCsvExport}
                        onClick={() => setIsCsvUploadOpen(true)}
                        type='primary'
                    >
                        CSVインポート
                    </Button>
                </Tooltip>

                <DownloadCSVConfirm
                    disabled={disabledCsvExport}
                    disabledMessage={csvExportDisabledMsg}
                    handleOk={(encoding) => handleCsvDownload(encoding)}
                    loading={loadingCsvExport}
                />
                <Button
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                    type='primary'
                >
                    新規作成
                </Button>
            </div>

            <div style={{ position: 'relative' }}>
                <BaseTable
                    columns={columns} // 該当年度のクラスリストが無い場合レイアウトが崩れる対策
                    dataSource={list.classList}
                    emptyDescription='クラスがありません'
                    loading={loading}
                    pagination={pagination}
                    style={!list.classListItemCount ? { paddingTop: '55px' } : {}}
                />
                <Select
                    onChange={(value) => { handleSelectTerm(value); }}
                    placeholder='年度を選択してください'
                    style={{ left: '0px', minWidth: '130px', position: 'absolute', top: '8px' }}
                    value={search.term_uuid}
                >
                    {addAllTermAndSort(list.termList).map((value, index) => {
                        return <Option key={index} value={value.uuid}>{value.name}</Option>;
                    })}
                </Select>
            </div>

            <EditClassModal
                isCreate={shouldCreate}
                onCancel={handleEditCancel}
                onOk={handleEditSubmitted}
                record={record}
                visible={isEditOpen}
            />
            <UploadCsvModal
                isUserType={false}
                onCancel={handleCsvUploadCancel}
                onOk={handleCsvUploadSubmit}
                visible={isCsvUploadOpen}
            />
            <AnnualUpdateModal
                isUserType={false}
                onCancel={handleAnnualUpdateCancel}
                onOk={handleAnnualUpdateSubmit}
                visible={isAnnualUpdateOpen}
            />
        </div>
    );
};

export default AdminContents;

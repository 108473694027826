import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Checkbox,
    Col,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Tag,
    Tooltip,
} from 'antd';
import {
    MinusCircleOutlined,
    PlusOutlined,
    TeamOutlined,
    UserOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { statusEnum } from 'flex/Actions';
import { editUserSelectors } from 'flex/view/UsersManagement/EditUser/selectors';
import { selectors } from 'flex/Selectors'
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';
import { useFeatures } from 'constants/CustomHooks/useFeatures';
import { formRules } from 'constants/GlobalConfig';
import BaseForm from 'components/modules/BaseForm';
import { ListContext } from '../index';
const Form = BaseForm.ModalForm;


const { confirm } = Modal;
const initFieldValue = {
    last_name: '',
    last_name_kana: '',
    first_name: '',
    first_name_kana: '',
    login_id: '',
    password: '',
    email: '',
    is_local: false,
    is_active: true,
    organization_uuid: null,
    belongs: [],
};

const EditUser = props => {
    const [userBelongs, setUserBelongs] = useState([]);
    const dispatch = useDispatch();

    const { hideUserName } = useFeatures();
    const [{ organizationList },] = useContext(ListContext);
    const [form] = Form.useForm();
    const loading = useSelector(editUserSelectors.loading);
    const tenant = useSelector(state => state.tenant);
    const {
        loginIdInputDisabled,
        termSelectDisabled,
        classSelectDisabled,
        numberInputDisabled,
        roleSelectDisabled,
        addBelongsButtonDisabled,
    } = useSelector(editUserSelectors.disabled);
    const selectTermsSelector = useSelector(selectors.editUserSelectTerms);
    const { passwordInputVisible, organizationSelectVisible } = useSelector(editUserSelectors.visible);
    const { passwordInputPlaceholder } = useSelector(editUserSelectors.message);
    const globalSelector = useSelector(state => state.globalState);
    const roleListSelector = useSelector(editUserSelectors.roleList);
    const classListMapSelector = useSelector(editUserSelectors.classListMap);
    const selectOrganizationSelector = useSelector(selectors.editUserSelectOrganization);
    const usersEditConnection = useSelector(state => state.usersEditConnection);
    const usersAccountUnlockConnection = useSelector(state => state.usersAccountUnlockConnection);
    const usersViewConnection = useSelector(state => state.usersViewConnection);
    const isControllableUser = useSelector(state => state.isControllableUser);
    const isFirstRender = useRef(false);

    const { Option } = Select;
    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 24,
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };

    useEffect(() => {
        //refを使ってfirstRenderを制御
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!props.visible) return;

        form.resetFields();
        dispatch(Actions.view.viewLogic.editUser.openView());

        //渡されたrecordにより、イニシャライズ処理
        const record = props.record;
        if (record === null) {
            setUserBelongs([]);
            form.setFieldsValue(initFieldValue);
        } else {
            let belongs = [];
            let organization_uuid = '';
            belongs = record.belongs.length !== 0 ?
                record.belongs.map((value) => {
                    organization_uuid = value.school_class?.organization?.uuid;
                    return {
                        is_primary: value.is_primary,
                        role_uuid: value.role.uuid,
                        school_class_number: value.school_class.number,
                        school_class_uuid: value.school_class.uuid,
                        term_uuid: value.school_class.term.uuid,
                    };
                }) :
                //belongsがもしもnullのときは代理の空フィールドを格納しておかないとbelongsフィールドが生成されない対応
                initFieldValue.belongs;

            const generateFieldValue = {
                login_id: splitDomain(record.login_id),
                first_name: record.first_name,
                first_name_kana: record.first_name_kana,
                is_active: record.is_active,
                is_local: record.is_local,
                last_name: record.last_name,
                last_name_kana: record.last_name_kana,
                belongs: belongs,
                organization_uuid: organization_uuid,
            };

            form.setFieldsValue(generateFieldValue);
        }
        // dispatch, form は不変なため
    }, [props.record, props.visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //userの更新通信処理の処理
        if (!isFirstRender.current) {
            if (usersEditConnection.meta.status === statusEnum.SUCCESS) {
                props.onOk();
            }
        } else {
            isFirstRender.current = false;
        }
    }, [usersEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;

        if (usersViewConnection.meta.status === statusEnum.SUCCESS) {
            setUserBelongs(usersViewConnection.payload.result.belongs);
        }
    }, [usersViewConnection]);

    useEffect(() => {
        if (usersEditConnection.meta.fetch) return;
        if (usersEditConnection.meta.status !== Actions.statusEnum.SUCCESS) return;

        dispatch(Actions.view.viewLogic.editUser.resetCache());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersEditConnection]);

    useEffect(() => {
        //userの更新通信処理の処理
        if (!isFirstRender.current) {
            if (usersAccountUnlockConnection.meta.status === statusEnum.SUCCESS) {
                props.onOk();
            }
        } else {
            isFirstRender.current = false;
        }
    }, [usersAccountUnlockConnection]);// eslint-disable-line

    useEffect(() => {
        if (usersAccountUnlockConnection.meta.fetch) return;
        if (usersAccountUnlockConnection.meta.status !== Actions.statusEnum.SUCCESS) return;

        dispatch(Actions.view.viewLogic.editUser.resetCache());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersAccountUnlockConnection]);

    /**
     * login_idからドメイン情報を除去
     * @returns {string}
     */
    const splitDomain = (value) => {
        const regex = /@.*?$/;
        return value.replace(regex, '');
    };

    const handleSubmit = () => {
        form.submit();
    };

    /**
     * submit時のform処理部（フォームから取得したapiに投げる際に不要なデータを除去
     * @param values
     */
    const onFinish = (values) => {
        dispatch(Actions.view.viewLogic.editUser.submit(values));
    };

    /***
     * 所属内部の年度プルダウンが切り替えられたときに利用
     *  - 年度プルダウンのstateの更新
     *  - クラスプルダウンの初期化
     * @param index
     * @param newTerm
     */
    const handleOnChangedSelectTerm = (index, newTerm) => {
        dispatch(Actions.view.viewLogic.editUser.selectTerms('REPLACE', index, newTerm));
        form.resetFields([['belongs', index, 'school_class_uuid']]);
    };

    const handleAddBelong = (add) => {
        dispatch(Actions.view.viewLogic.editUser.selectTerms('ADD'));
        add({ term_uuid: globalSelector.currentTerm });
    };

    const handleRemoveBelong = (remove, index) => {
        dispatch(Actions.view.viewLogic.editUser.selectTerms('REMOVE', index));
        remove(index);
    };

    const handleCancel = () => {
        props.onCancel();
        resetClassListMapAndTerm();
    };

    /***
     * stateやlistContextに保持されているクラスと年度を初期化する
     */
    const resetClassListMapAndTerm = () => {
        dispatch(Actions.view.viewLogic.editUser.resetCache());
    };

    /***
     * 所属情報で使う、年度と役割を取得するリクエストを送る
     * (クラスは、年度選択プルダウンが選択されるごとに取得する)
     * @param organization_uuid
     */
    const getBelongsList = (organization_uuid) => {
        dispatch(Actions.view.viewLogic.editUser.searchRole(organization_uuid));
    };

    /**
     * belong がデフォルト, つまり年度が今年度で, 他が何も入力されていない状態か確認する
     */
    const isDefaultBelong = (belong) => {
        if (!belong) return false;

        return belong.school_class_uuid === undefined &&
            belong.role_uuid === undefined &&
            belong.term_uuid === globalSelector.currentTerm;
    };

    const onChangeOrganization = (uuid) => {
        const belongs = form.getFieldValue('belongs');

        if (belongs.length !== 0 && (belongs.length !== 1 || !isDefaultBelong(belongs[0]))) {
            confirm({
                title: '所属学校の変更',
                icon: <ExclamationCircleOutlined />,
                content: '既に設定されている所属がありますが、学校を変更するとフォームからクリアされます。よろしいですか？',
                onOk() {
                    // 前回の学校の情報を削除し、初期化
                    resetClassListMapAndTerm();
                    dispatch(Actions.view.viewLogic.editUser.selectOrganization(uuid));
                    getBelongsList(uuid);

                    form.setFieldsValue({
                        organization_uuid: uuid,
                        belongs: [{ term_uuid: globalSelector.currentTerm }]
                    });
                    dispatch(Actions.view.viewLogic.editUser.selectTerms('ADD'));
                },
                onCancel() {
                    form.setFieldsValue({
                        organization_uuid: selectOrganizationSelector
                    });
                },
            });
        } else {
            dispatch(Actions.view.viewLogic.editUser.selectOrganization(uuid));
            getBelongsList(uuid);

            form.setFieldsValue({
                organization_uuid: uuid,
                belongs: [{ term_uuid: globalSelector.currentTerm }],
            });
            dispatch(Actions.view.viewLogic.editUser.selectTerms('ADD'));

        }
    };

    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={
                <span>
                    <TeamOutlined /> {props.shouldCreate ? 'ユーザー情報の作成' : 'ユーザー情報の編集'}
                </span>
            }
            visible={props.visible}
            onCancel={handleCancel}
            footer={[
                <Button
                    key='back'
                    size='large'
                    loading={loading}
                    onClick={handleCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    loading={loading}
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
            forceRender
        >
            <Spin spinning={!props.shouldCreate && props.loading}>
                <Form {...formItemLayout} form={form} name='control-hooks-edit-user' onFinish={onFinish}>
                    <Form.Item
                        name='login_id'
                        label='ユーザーID'
                        rules={[
                            { required: true, message: '必須項目です' },
                            { message: 'ユーザーIDは2文字以上64文字以下で入力してください', max: 64, min: 2 },
                            { pattern: /^[a-z0-9_][a-z0-9_.-]*[a-z0-9_]$/, message: 'ユーザーIDは半角英数字(小文字)で入力してください' },
                        ]}
                    >
                        <Input placeholder='ユーザーIDを入力してください'
                            disabled={loginIdInputDisabled}
                            suffix={`@${tenant.payload.domain}`}
                        />
                    </Form.Item>


                    <Form.Item label='名前'>
                        <Row gutter={[8, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    name='last_name'
                                    rules={[{ message: '姓は50文字以下で入力してください', max: 50 }]}
                                >
                                    <Input
                                        autocomplete='off'
                                        disabled={hideUserName}
                                        placeholder='姓を入力してください'
                                        prefix={<span style={{ color: '#BBBBBB', paddingRight: '10px' }}>姓</span>}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='last_name_kana'
                                    rules={[{ message: '姓（ふりがな）は50文字以下で入力してください', max: 50 }]}
                                >
                                    <Input
                                        autocomplete='off'
                                        disabled={hideUserName}
                                        placeholder='姓（ふりがな）を入力してください'
                                        prefix={<span style={{ color: '#BBBBBB', paddingRight: '10px' }}>ふりがな</span>}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={[8, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    name='first_name'
                                    rules={[{ message: '名は50文字以下で入力してください', max: 50 }]}
                                >
                                    <Input
                                        autocomplete='off'
                                        disabled={hideUserName}
                                        placeholder='名を入力してください'
                                        prefix={<span style={{ color: '#BBBBBB', paddingRight: '10px' }}>名</span>}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='first_name_kana'
                                    disabled={hideUserName}
                                    rules={[{ message: '名（ふりがな）は50文字以下で入力してください', max: 50 }]}
                                >
                                    <Input
                                        autocomplete='off'
                                        disabled={hideUserName}
                                        placeholder='名（ふりがな）を入力してください'
                                        prefix={<span style={{ color: '#BBBBBB', paddingRight: '10px' }}>ふりがな</span>}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    {passwordInputVisible ?
                        <Form.Item name='password' label='パスワード'
                            rules={[
                                { required: props.shouldCreate, message: '必須項目です' },
                                formRules.passwordLengthValidator(),
                                formRules.passwordCharsetValidator(),
                            ]}
                        >
                            <Input.Password autocomplete='new-password' placeholder={passwordInputPlaceholder} />
                        </Form.Item>
                        :
                        null
                    }

                    <Form.Item
                        name='is_active'
                        label={
                            <span>
                                アカウントの状態&nbsp;&nbsp;
                                <Tooltip title='「無効」を選択すると、このアカウントでサインインできなくなります。このアカウントが主担任に設定されている場合は、設定が解除されます。'>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </span>
                        }
                    >
                        <Radio.Group>
                            <Radio value={true}>有効</Radio>
                            <Radio value={false}>無効</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='is_local'
                        label='認証先'
                    >
                        <Radio.Group onChange={(e) => { dispatch(Actions.view.viewLogic.editUser.setIsLocal(e.target.value)) }}>
                            <Radio value={false}>SSO(シングルサインオン)ユーザー</Radio>
                            <Radio value={true}>ローカルユーザー</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {organizationSelectVisible ?
                        <Form.Item
                            name='organization_uuid'
                            label='学校'
                            rules={[{ required: true, message: '必須項目です' }]}
                        >
                            <Select
                                placeholder='学校を選択してください'
                                onChange={onChangeOrganization}
                                value={selectOrganizationSelector}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {organizationList.map((value, index) => {
                                    return <Option key={index} value={value.uuid}>{value.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        :
                        null
                    }

                    <Form.Item
                        name='belongs'
                        rules={
                            isControllableUser.payload || props.record ?
                                [{ required: true, type: 'array', message: '必須項目です' }] :
                                []
                        }
                        label={
                            form.getFieldValue('belongs')?.length >= 0 ?
                                <Tooltip title='クラスの所属情報を削除すると、そのクラスの主担任、在籍クラスの情報もリセットされます。'>
                                    所属情報
                                </Tooltip> :
                                ''
                        }
                    >
                        <Form.List name='belongs'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <UserBelongRow
                                            {...field}
                                            belongs={userBelongs}
                                            classNumberProps={{
                                                disabled: numberInputDisabled,
                                            }}
                                            classProps={{
                                                disabled: classSelectDisabled,
                                                list: classListMapSelector[selectTermsSelector[index]],
                                            }}
                                            key={index}
                                            loading={loading}
                                            onRemove={() => handleRemoveBelong(remove, index)}
                                            roleProps={{
                                                disabled: roleSelectDisabled,
                                                list: roleListSelector,
                                            }}
                                            termProps={{
                                                disabled: termSelectDisabled,
                                                list: globalSelector.termList,
                                                onChange: (value) => handleOnChangedSelectTerm(index, value),
                                            }}
                                        />
                                    ))}

                                    <Form.Item
                                        {
                                        ...(form.getFieldValue('belongs')?.length >= 0 ?
                                            {} :
                                            formItemLayoutWithOutLabel)
                                        }
                                    >
                                        <Button
                                            loading={loading}
                                            disabled={addBelongsButtonDisabled}
                                            type='dashed'
                                            onClick={() => handleAddBelong(add)}
                                            block
                                        >
                                            <PlusOutlined />
                                            所属を追加
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Spin>
        </BaseModal>
    );
};

export default EditUser;


const UserBelongRow = ({
    belongs,
    classNumberProps: {
        disabled: isDisabledClassNumber,
    },
    classProps: {
        disabled: isDisabledClass,
        list: classList = [],
    },
    name,
    loading,
    onRemove,
    roleProps: {
        disabled: isDisabledRole,
        list: roleList,
    },
    termProps: {
        disabled: isDisabledTerm,
        list: termList,
        onChange: onChangeTerm,
    },
}) => {
    const form = Form.useFormInstance();
    const belongsWithForm = Form.useWatch('belongs', form) ?? [];
    const schoolClassUuid = belongsWithForm[name]?.school_class_uuid ?? '';
    const isMainTeacher = belongs.find(belong => belong.school_class.uuid === schoolClassUuid)?.is_main_teacher;

    const handleChangeClass = (value) => {
        const targetBelong = belongs.find(e => e.school_class.uuid === value);
        const newBelongs = belongsWithForm.slice(0);
        newBelongs[name].is_primary = targetBelong?.is_primary ?? false;
        newBelongs[name].school_class_uuid = value;

        const isMainTeacher = targetBelong?.is_main_teacher ?? false;

        if (isMainTeacher) {
            newBelongs[name].role_uuid = targetBelong.role.uuid;
        }

        form.setFieldsValue({ belongs: newBelongs });
    };

    const handleChangeTerm = (value) => {
        onChangeTerm(value);

        const newBelongs = belongsWithForm.slice(0);
        newBelongs[name].is_primary = false;
        newBelongs[name].school_class_uuid = undefined;
        newBelongs[name].term_uuid = value;

        form.setFieldsValue({ belongs: newBelongs });
    };

    return (
        <Row gutter={[16, 0]}>
            <Col span={isMainTeacher ? 2 : 3}>
                <Form.Item
                    name={[name, 'is_primary']}
                    valuePropName='checked'
                >
                    <Checkbox
                        loading={loading}
                        style={{ whiteSpace: 'nowrap' }}
                        onChange={(event) => {
                            form.setFieldsValue({
                                belongs: belongsWithForm.map((belong, i) => {
                                    return {
                                        ...belong,
                                        is_primary: i === name ? event.target.checked : false,
                                    };
                                })
                            })
                        }}
                    >
                        在籍
                    </Checkbox >
                </Form.Item>
            </Col>

            {isMainTeacher && (
                <Col span={1}>
                    <Tooltip title='このクラスの主担任として割り当てられています。'>
                        <UserOutlined
                            style={{
                                fontSize: 18,
                                position: 'relative',
                                top: 4,
                            }}
                        />
                    </Tooltip>
                </Col>
            )}

            <Col span={4}>
                <Form.Item
                    name={[name, 'term_uuid']}
                    rules={[{ required: true, message: '必須項目です' }]}
                >
                    <Select
                        loading={loading}
                        disabled={isDisabledTerm}
                        placeholder='年度を選択してください'
                        onChange={handleChangeTerm}
                    >
                        {termList.map((value) => (
                            <Option key={value.uuid} value={value.uuid}>
                                {value.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

            <Col span={6}>
                <Form.Item
                    name={[name, 'school_class_uuid']}
                    rules={[{ required: true, message: '必須項目です' }]}
                >
                    <Select
                        allowClear
                        disabled={isDisabledClass}
                        loading={loading}
                        onChange={handleChangeClass}
                        placeholder='クラスを選択してください'
                    >
                        {classList.map((value) => (
                            <Option key={value.uuid} value={value.uuid}>
                                <Tag
                                    color='#AAAAAA'
                                    style={{
                                        width: '75px',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        marginRight: '0.5rem'
                                    }}
                                >
                                    {value.term.name}
                                </Tag>
                                {value.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item
                    name={[name, 'school_class_number']}
                    rules={[{ message: '出席番号は24文字以内で入力してください', max: 24 }]}
                >
                    <Input placeholder='出席番号を入力してください'
                        disabled={isDisabledClassNumber}
                    />
                </Form.Item>
            </Col>

            <Col span={4}>
                <Form.Item
                    name={[name, 'role_uuid']}
                    rules={[{ required: true, message: '必須項目です' }]}
                >
                    <Select
                        allowClear
                        disabled={isDisabledRole || isMainTeacher}
                        loading={loading}
                        placeholder='役割を選択してください'
                    >
                        {roleList.map((value) => (
                            <Option key={value.uuid} value={value.uuid}>
                                {value.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

            <Col span={1}>
                <MinusCircleOutlined
                    className='dynamic-delete-button'
                    style={{
                        cursor: 'pointer',
                        fontSize: 24,
                        margin: '0 8px',
                        position: 'relative',
                        top: 4,
                        transition: 'all 0.3s',
                    }}
                    onClick={onRemove}
                />
            </Col>
        </Row>
    );
};
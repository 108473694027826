import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DeleteOutlined,
    EditOutlined,
    ImportOutlined,
    InfoCircleOutlined,
    LoginOutlined,
    PlusCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Row, Tooltip, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import UploadCsvModal from 'components/modules/UploadCSVModal';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import {
    generateMngDomain,
    getMoment,
    getNameFromGradeCode,
    openWindowWithReferer,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { organizationManagementTabSelectors } from 'flex/view/Organization/organizationManagmetnTabSelectors';

import EditOrganization from './EditOrganization';
import OrganizationSearch from './OrganizationSearch';

import { ListContext } from './index';
import { useJobReloadEffect } from 'components/modules/Job/useJobReloadEffect';

const moment = getMoment();

//** この画面をリロードする必要のあるジョブタイプのリスト */
const RELOAD_BACKGROUND_JOB_TYPES = [BACKGROUND_JOB_TYPE.ORGANIZATION_CSV_IMPORT];

/** テーブルに表示する件数のデフォルト値 */
const defaultPageSize = 50;

export const SearchContext = createContext<[
    Http.Connection.Request.Parameter.Organization.Search,
    (params: Http.Connection.Request.Parameter.Organization.Search) => void
    // eslint-disable-next-line @typescript-eslint/no-empty-function
]>([{ page_size: defaultPageSize }, () => { }]);

const OrganizationList = () => {
    const [list] = useContext(ListContext);

    const [search, setSearch] = useState<Http.Connection.Request.Parameter.Organization.Search>({ page_size: defaultPageSize });
    const [record, setRecord] = useState<Organization | null>(null);
    const [loading, setLoading] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isCsvUploadOpen, setIsCsvUploadOpen] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        onChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size ?? defaultPageSize,
            }));
            onSearch({
                ...search,
                page,
                page_size: page_size ?? defaultPageSize,
            });
        },
        pageSize: defaultPageSize,
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: list.organizationListItemCount,
    });

    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();
    const dispatch = useDispatch();

    const organizationImportConnection = useAppSelector(state => state.organizationImportConnection);
    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);
    const organizationAgencyConnection = useAppSelector(state => state.organizationAgencyConnection);
    const csvImportDisabledMsg = useSelector(organizationManagementTabSelectors.csvImportDisabledMsg);
    const jobInfoMap = useAppSelector(state => state.jobInfoMap);

    const disabledCsvImport = useMemo(() => {
        return organizationImportConnection.meta.fetch ||
            Object.values(jobInfoMap).filter(jobInfo => jobInfo?.job?.type === BACKGROUND_JOB_TYPE.ORGANIZATION_CSV_IMPORT).length > 0;
    }, [organizationImportConnection, jobInfoMap]);

    const loadingCsvImport = useMemo(() => {
        return organizationImportConnection.meta.fetch;
    }, [organizationImportConnection]);

    const isFirstRender = useRef(false);

    const onSearch = (prams?: Http.Connection.Request.Parameter.Organization.Search) => {
        const newSearch = prams ?? search;

        setSearch(newSearch);

        const { page, ...rest } = newSearch;

        setPagination(pagination => ({
            ...pagination,
            current: page,
            onChange: (page, page_size) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize: page_size ?? defaultPageSize,
                }));
                onSearch({
                    ...newSearch,
                    page,
                    page_size: page_size ?? defaultPageSize,
                });
            },
        }));

        dispatch(Actions.http.connection.organization.search(rest, page, ''));
    };

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        onSearch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            total: list.organizationListItemCount,
        }));
    }, [dispatch, list.organizationListItemCount]);

    useEffect(() => {
        setLoading(organizationSearchConnection.meta.fetch);
    }, [organizationSearchConnection.meta.fetch]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!!isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, [organizationAgencyConnection]);

    useJobReloadEffect(
        RELOAD_BACKGROUND_JOB_TYPES,
        () => {
            if (isFirstRender.current) return;
            onSearch();
        },
    );

    const columns: ColumnsType<Required<Organization>> = [
        {
            dataIndex: 'name',
            ellipsis: true,
            key: 'name',
            title: '学校名',
            width: 40,
        },
        {
            dataIndex: 'school_code',
            key: 'school_code',
            title: '文部科学省 学校コード',
            width: 20,
        },
        {
            dataIndex: 'code',
            key: 'code',
            render: (v: string) => (
                getNameFromGradeCode(v)
            ),
            title: '校種コード',
            width: 10,
        },
        {
            dataIndex: 'type_text',
            key: 'type_text',
            title: '権限',
            width: 10,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: 15,
        },
        {
            align: 'center',
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (_, record) => (
                <Row align='middle' justify='space-around'>
                    <Col>
                        <Tooltip title={hasTouchScreen ? undefined : <span>{record.name}へサインイン</span>}>
                            <Button
                                className='mr-2'
                                icon={<LoginOutlined />}
                                onClick={() => {
                                    handleAgencyLoginClick(record.uuid);
                                }}
                                shape='circle'
                                size='small'
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title={hasTouchScreen ? undefined : '編集'}>
                            <Button
                                className='mr-2'
                                icon={<EditOutlined />}
                                onClick={() => handleEditClick(record)}
                                shape='circle'
                                size='small'
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <CircleButton
                            className='mr-2'
                            danger
                            icon={<DeleteOutlined />}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                okText: '削除する',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>{record.name}を削除しますか？</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>
                                            削除すると{record.name}の全てのサービスが利用できなくなります。
                                        </span>
                                    </>
                                ),
                            }}
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '削除' }}
                        />
                    </Col>
                </Row>
            ),
            title: (
                <Tooltip title='同じブラウザから複数の学校にサインインすることはできません'>
                    <InfoCircleOutlined />
                </Tooltip>
            ),
            width: 10,
        },
    ];

    function handleAgencyLoginClick(id: string) {
        dispatch(Actions.http.connection.organization.agencyLogin(id));
        openWindowWithReferer(generateMngDomain(), '_blank');
    }

    function handleCreateClick() {
        //新規作成
        setRecord(null);
        setIsEditOpen(true);
    }

    function handleEditClick(record: Organization) {
        //編集
        setRecord(record);
        setIsEditOpen(true);
    }

    function handleDeleteClick(id: string) {
        dispatch(Actions.http.connection.organization.delete(id));
    }


    function handleEditSubmit() {
        //完了したので再読込
        setIsEditOpen(false);
        onSearch({
            ...search,
            page: 1,
        });
    }

    function handleEditCancel() {
        setIsEditOpen(false);
    }

    function handleCsvUploadSubmit() {
        setIsCsvUploadOpen(false);
    }
    function handleCsvUploadCancel() {
        setIsCsvUploadOpen(false);
    }


    return (
        <div className='organizations-list container'>

            <SearchContext.Provider value={[search, onSearch]}>
                <OrganizationSearch />
            </SearchContext.Provider>

            <div className='flex-right-container gutter-bottom'>
                <Tooltip title={csvImportDisabledMsg}>
                    <Button
                        disabled={disabledCsvImport}
                        icon={<ImportOutlined />}
                        loading={loadingCsvImport}
                        onClick={() => setIsCsvUploadOpen(true)}
                        type='primary'
                    >
                        CSVインポート
                    </Button>
                </Tooltip>
                <Button
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                    type='primary'
                >
                    新規作成
                </Button>
            </div>
            <BaseTable
                columns={columns}
                dataSource={list.organizationList}
                emptyDescription='学校がありません'
                loading={loading}
                pagination={pagination}
            />
            <UploadCsvModal
                isOrganizationType={true}
                isUserType={false}
                onCancel={handleCsvUploadCancel}
                onOk={handleCsvUploadSubmit}
                visible={isCsvUploadOpen}
            />
            <EditOrganization
                onCancel={handleEditCancel}
                onOk={handleEditSubmit}
                record={record}
                visible={isEditOpen}
            />
        </div>
    );
};

export default OrganizationList;

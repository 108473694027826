import { useContext, useEffect } from 'react';
import {
    Button,
    // Col,
    // Row,
    Select
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import { useAppSelector } from 'flex/utils';
// import { selectors } from 'flex/Selectors';
import * as Actions from 'flex/Actions';
import {
    ALL_TERM_UUID,
    // addAllTermAndSort,
    getDefaultLayout
} from 'constants/GlobalConfig';
import BaseForm from 'components/modules/BaseForm';
// import useOrganization from '../hooks/useOrganization';
// import useSchoolClass from '../hooks/useSchoolClass';
import { STATUS_KEIES, SearchContext } from '.';
import { useDispatch } from 'react-redux';


const Form = BaseForm.ModalForm;
const { Option } = Select;
const layout = getDefaultLayout();


export const SearchArea = ({
    isControl = false,
    notificationUuid = '',
    targetUsersLoading = false,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const organizationUuid = Form.useWatch('organizationUuid', form);
    // const [organization, searchOrganization] = useOrganization();
    // const [schoolClass, searchSchoolClass] = useSchoolClass();
    // const { termList } = useAppSelector(selectors.globalState);
    const [search, setSearch] = useContext(SearchContext);

    useEffect(() => {// 初回のデータ取得と初期値のセット
        // if (isControl) {
        //     searchOrganization(notificationUuid);
        // } else {
        //     searchSchoolClass({ page_size: 500 });
        // }

        form.setFieldsValue({
            classTermUuid: isControl ? undefined : ALL_TERM_UUID,
            isRead: STATUS_KEIES.all,
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const searchNotificationTarget = isControl
        ? Actions.http.connection.notification.controlTarget
        : Actions.http.connection.notification.target;

    // function handleChangeOrganization(value) {
    //     searchSchoolClass({
    //         organization_uuid: value,
    //         page_size: 500,
    //     });
    //     form.setFieldsValue({
    //         classTermUuid: ALL_TERM_UUID,
    //         schoolClassUuid: undefined,
    //     });
    // };

    // function handleChangeTerm(value) {
    //     searchSchoolClass({
    //         organization_uuid: organizationUuid,
    //         page_size: 500,
    //         term_uuid: value,
    //     });
    // };

    function onSearch(values) {
        const newSearchRule = {
            ...search,
            is_read: values.isRead === STATUS_KEIES.alreadyRead
                ? 1
                : values.isRead === STATUS_KEIES.notYetRead ? 0 : undefined,
            organization_uuid: (isControl && values.organizationUuid) ? values.organizationUuid : undefined,
            page: 1,
            school_class_uuid: values?.schoolClassUuid,
            term_uuid: values?.termUuid,
        };
        setSearch(newSearchRule);
        const { page, ...restSearchRule } = newSearchRule;
        dispatch(searchNotificationTarget(notificationUuid, restSearchRule, page));
    };

    return (
        <div className='card general-search'>
            <Form {...layout} form={form} onFinish={onSearch}>
                <Form.Item label='開封状態' name='isRead'>
                    <Select allowClear
                        loading={targetUsersLoading}
                        placeholder='開封状態を選択してください'
                    >
                        <Option value={STATUS_KEIES.all}>すべて</Option>
                        <Option value={STATUS_KEIES.notYetRead}>未開封</Option>
                        <Option value={STATUS_KEIES.alreadyRead}>開封済</Option>
                    </Select>
                </Form.Item>

                {/* {isControl ? (
                    <Form.Item label='学校' name='organizationUuid'>
                        <Select allowClear
                            loading={organization.isLoading}
                            onChange={handleChangeOrganization}
                            placeholder='学校名を入力してください'
                        >
                            {organization.list.map((org) => (
                                <Option key={org.uuid} value={org.uuid}>{org.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : undefined} */}

                {/* <Form.Item label='クラス'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='classTermUuid'>
                                <Select allowClear
                                    disabled={isControl && !organizationUuid}
                                    onChange={handleChangeTerm}
                                    placeholder='期間を選択してください'
                                >
                                    {addAllTermAndSort(termList).map((term) => (
                                        <Option key={term.uuid} value={term.uuid}>
                                            {term.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='schoolClassUuid'>
                                <Select allowClear
                                    disabled={(isControl && !organizationUuid) || schoolClass.isLoading}
                                    loading={schoolClass.isLoading}
                                    placeholder='クラスを選択してください'
                                >
                                    {schoolClass.list.map((schoolClass) => (
                                        <Option key={schoolClass.uuid} value={schoolClass.uuid}>
                                            {schoolClass.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item> */}

                {/* <Form.Item label='年度' name='termUuid'>
                    <Select allowClear
                        placeholder='年度を選択してください'
                    >
                        {termList.map((org) => (
                            <Option key={org.uuid} value={org.uuid}>{org.name}</Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <div className='submit-container'>
                    <Button htmlType='submit' icon={<SearchOutlined />} loading={targetUsersLoading} type='primary'>
                        絞り込む
                    </Button>
                </div>
            </Form>
        </div>
    );
};
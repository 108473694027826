import { getMoment } from 'constants/GlobalConfig';

import { Source, TARGET, TERM_TYPE } from './UsageDataProvider';
const moment = getMoment();

// private とするため `_` をつけている
export class ActionLogTotalQueryBuilder {
    edumall: {
        text: string;
        value: string;
    } | undefined;
    hierarchy: Source['hierarchy'] | undefined;
    isControllableUser: boolean | undefined;
    organizationAppLists: Source['organizationAppLists'] | undefined;
    responseAll: number | undefined;
    target: string | undefined;
    targetDetails: { app: string, group: string }[] | undefined;
    term: moment.Moment | undefined;
    termType: typeof TERM_TYPE[keyof typeof TERM_TYPE] | undefined;

    private _contentUuids: string | undefined;
    private _endAt: number | undefined;
    private _responseAll: number | undefined;
    private _scope: string | undefined;
    private _scopeUuid: string | undefined;
    private _startAt: number | undefined;
    private _termUnit: string | undefined;

    constructor() {
        // source を作る上での材料となるもの
        this.edumall = undefined;
        this.hierarchy = undefined;
        this.isControllableUser = undefined;
        this.organizationAppLists = undefined;
        this.responseAll = undefined;
        this.target = undefined;
        this.targetDetails = undefined;
        this.term = undefined;
        this.termType = undefined;

        // query を作る上でのパーツとなるもの
        this._contentUuids = undefined;
        this._endAt = undefined;
        this._responseAll = undefined;
        this._scopeUuid = undefined;
        this._startAt = undefined;
        this._termUnit = undefined;
    }

    _isValid = () => {
        if (this.term === undefined) return false;
        if (this.termType === undefined) return false;
        if (this.hierarchy === undefined) return false;
        if (this.target === undefined) return false;
        // targetDetails はなくても良い
        // 本来は target によって必要不必要が変わるので, 必要であれば実装する

        return true;
    }

    _createStartAt = () => {
        if (this.termType === undefined) return;

        switch (this.termType) {
            case TERM_TYPE.YEAR:
                this._startAt = moment(this.term).startOf(this.termType).add(3, 'months').unix();
                break;
            case TERM_TYPE.WEEK:
            case TERM_TYPE.DATE:
            case TERM_TYPE.MONTH:
                this._startAt = moment(this.term).startOf(this.termType).unix();
                break;
            default:
                throw new Error(`予期されない期間の種類 ${this.termType} が渡されました.`);
        }
    }

    _createEndAt = () => {
        if (this.termType === undefined) return;

        switch (this.termType) {
            case TERM_TYPE.YEAR:
                this._endAt = moment(this.term).endOf(this.termType).add(3, 'months').unix();
                break;
            case TERM_TYPE.MONTH:
            case TERM_TYPE.WEEK:
            case TERM_TYPE.DATE:
                this._endAt = moment(this.term).endOf(this.termType).unix();
                break;
            default:
                throw new Error(`予期されない期間の種類 ${this.termType} が渡されました.`);
        }
    }

    _createTimeUnit = () => {
        if (this.termType === undefined) return;

        const termTypeToTimeUnit = {
            [TERM_TYPE.YEAR]: 'month',
            [TERM_TYPE.MONTH]: 'day',
            [TERM_TYPE.WEEK]: 'day',
            [TERM_TYPE.DATE]: 'hour',
        };

        const termType = termTypeToTimeUnit[this.termType];

        if (termType === undefined) throw new Error(`予期されない期間の種類が ${termType} が渡されました.`);

        this._termUnit = termType;
    }

    _createScope = () => {
        const correction = this.isControllableUser ? 0 : 1;
        if (this.hierarchy === undefined) return;

        switch (this.hierarchy.length + correction) {
            case 1: // 学校別
                this._scope = 'tenant';
                break;
            case 2: // クラス別
                this._scope = 'organization';
                break;
            case 3: // ユーザー別
                this._scope = 'school-class';
                break;
            case 4: // 個別ユーザー
                this._scope = 'user';
                break;
            default:
                break;
        }
    }

    _createScopeUuid = () => {
        if (this.hierarchy === undefined) return;

        this._scopeUuid = this.hierarchy.slice(-1)[0]?.uuid;
    }

    _createContentUuids = () => {

        const uuids = [];
        switch (this.target) {
            case TARGET.USE_APP:
                if (this.targetDetails === undefined) return;

                uuids.push(...this.targetDetails.map(e => {
                    if (this.organizationAppLists === undefined) return undefined;

                    if (e.app === 'all') {
                        if (this.isControllableUser) {
                            return this.organizationAppLists[e.group].list.map(e => e.value);
                        } else {
                            // admin であれば自身のアプリにしかアクセスできないはずなので, 1つ取ってくれば良い
                            return Object.values(this.organizationAppLists)[0].list.map(e => e.value);
                        }
                    } else {
                        return e.app;
                    }
                }));
                break;
            case TARGET.EDUMALL:
                if (this.targetDetails === undefined) return;
                uuids.push(...this.targetDetails.map(e => e.app));
                break;
            default:
                break;
        }

        this._contentUuids = Array.from(new Set(uuids.flat())).join(',');
    }

    _createResponseAll = () => {
        this._responseAll = this.responseAll !== undefined ? this.responseAll : 1;
    }

    getResult = () => {
        if (!this._isValid) throw new Error();

        this._createStartAt();
        this._createEndAt();
        this._createTimeUnit();
        this._createScope();
        this._createScopeUuid();
        this._createContentUuids();
        this._createResponseAll();

        const parameter: Parameter = {
            action: this.target,
            end_at: this._endAt,
            response_all: this._responseAll,
            scope: this._scope,
            scope_uuid: this._scopeUuid,
            start_at: this._startAt,
            time_unit: this._termUnit,
        };

        if (this._contentUuids) parameter.content_uuids = this._contentUuids;

        return parameter;
    }
}

type Parameter = {
    action: string | undefined
    content_uuids?: string | undefined
    end_at: number | undefined
    response_all: number | undefined
    scope_uuid: string | undefined
    scope: string | undefined
    start_at: number | undefined
    time_unit: string | undefined
}

import { useEffect } from 'react';
import * as React from 'react';
import {
    Button,
    Col,
    Input,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
} from 'antd';
import {
    MinusCircleOutlined,
    NotificationOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { formRules, getDefaultLayout } from 'constants/GlobalConfig';
import BaseModal from 'components/modules/BaseModal';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;
const layout = getDefaultLayout();
const { Option } = Select;

const INIT_FORM_VALUE: EverydayNote.Form.Question = {
    candidates: [
        { content: '', order: 0 },
        { content: '', order: 1 },
    ],
    content: '',
    detail: '',
    isRequired: true,
    type: 1,
};

type Props = {
    isCopy: boolean
    loading?: boolean
    onCancel: () => void
    onOk: (question: EverydayNote.Form.Question) => void
    question?: EverydayNote.Form.Question
    questionsCount: number
    visible: boolean
};


const QuestionModal: React.VFC<Props> = ({
    isCopy,
    loading = false,
    onCancel,
    onOk,
    question,
    questionsCount,
    visible,
}) => {
    const [form] = Form.useForm<EverydayNote.Form.Question>();

    const isCreate = !question;

    const type = Form.useWatch('type', form) ?? INIT_FORM_VALUE.type;

    useEffect(() => {
        if (!visible) return;

        form.resetFields();

        if (isCreate) {
            form.setFieldsValue({
                ...INIT_FORM_VALUE,
                content: `${questionsCount + 1}. `,
            });
        } else {
            form.setFieldsValue({
                ...question!,
            });
        }
    }, [form, isCopy, isCreate, question, questionsCount, visible]);

    const handleChangeType = (e: RadioChangeEvent) => {
        const type = e.target.value as EverydayNote.Form.Question['type'];

        switch (type) {
            case 1:
            case 2:
            case 7:
                form.setFieldsValue({
                    candidates: [
                        { content: '', order: 0 },
                        { content: '', order: 1 },
                    ],
                });
                break;
            case 3:
            case 4:
                form.setFieldsValue({
                    candidates: undefined,
                });
                break
            case 6:
                form.setFieldsValue({
                    evaluationScaleMax: undefined,
                });
                break;
            case 5:
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = type;
                break;
        }
    };


    const onFinish = (values: EverydayNote.Form.Question) => {
        const { candidates = [], ...resetVales } = removeExtraFields(values);
        const candidatesWithOrder = candidates.map((candidate, i) => ({
            ...candidate,
            order: i,
        }));

        onOk({
            ...question,
            ...resetVales,
            candidates: candidatesWithOrder,
            order: question?.order ?? questionsCount,
        });
    };

    return (
        <BaseModal
            className='common-modal everyday-note-modal'
            title={<span><NotificationOutlined className='icon-r' /> {isCreate ? ' 設問の新規作成' : '設問の編集'}</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button
                    key='cancel'
                    size='large'
                    loading={loading}
                    disabled={loading}
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    size='large'
                    loading={loading}
                    disabled={loading}
                    onClick={form.submit}
                >
                    保存
                </Button>,
            ]}
        >
            <Form {...layout} form={form} onFinish={onFinish}>
                <Form.Item name='uuid' hidden><input /></Form.Item>

                <Form.Item
                    name='content'
                    label='設問'
                    rules={[
                        formRules.required({ label: '設問' }),
                        formRules.range({ max: 128 }),
                    ]}
                >
                    <Input placeholder='設問を入力してください' />
                </Form.Item>

                <Form.Item
                    name='detail'
                    label='設問詳細'
                    rules={[
                        formRules.required({ label: '設問詳細' }),
                        formRules.range({ max: 10000 }),
                    ]}
                >
                    <Input placeholder='設問詳細を入力してください' />
                </Form.Item>

                <Form.Item name='isRequired' label='必須回答'>
                    <Radio.Group>
                        <Radio value={true}>必須</Radio>
                        <Radio value={false}>任意</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name='type' label='回答形式'>
                    <Radio.Group onChange={handleChangeType}>
                        <Radio value={1}>単一選択式</Radio>
                        <Radio value={2}>複数選択式</Radio>
                        <Radio value={3}>自由記述式</Radio>
                        <Radio value={4}>顔マーク5段階</Radio>
                        <Radio value={6}>星マーク</Radio>
                        <Radio value={7}>プルダウン</Radio>
                    </Radio.Group>
                </Form.Item>

                {(type === 1 || type === 2 || type === 7) && (
                    <Form.List name='candidates'>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={`選択肢 ${index + 1}`}
                                        required
                                        style={{ marginBottom: 0 }}
                                        key={field.key}
                                    >
                                        <Form.Item name={[field.name, 'uuid']} hidden><input /></Form.Item>

                                        <Row gutter={[16, 12]} >
                                            <Col span={22}>
                                                <Form.Item
                                                    name={[field.name, 'content']}
                                                    rules={[
                                                        formRules.required({ label: `選択肢${index + 1}の名称` }),
                                                        formRules.range({ max: 255 }),
                                                    ]}
                                                >
                                                    <Input placeholder={`選択肢${index + 1}の名称を入力してください`} />
                                                </Form.Item>
                                            </Col>
                                            {(index > 1) && (
                                                <Col span={2}>
                                                    <Button
                                                        type='text'
                                                        onClick={() => remove(index)}
                                                    >
                                                        <MinusCircleOutlined style={{ fontSize: '24px' }} />
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form.Item>
                                ))}

                                <Form.Item wrapperCol={{ lg: { span: 24, offset: 0 }, xl: { span: 20, offset: 4 } }}>
                                    <Button
                                        type='dashed'
                                        style={{ width: '100%' }}
                                        // add をそのまま渡すと, 以下のエラーが発生する.
                                        // This synthetic event is reused for performance reasons.
                                        onClick={() => add()}
                                    >
                                        <PlusOutlined />
                                        選択肢の追加
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                )}

                {type === 6 && (
                    <Form.Item
                        name='evaluationScaleMax'
                        label='段階数'
                        rules={[formRules.required({ label: '段階数', isSelect: true }),]}
                    >
                        <Select>
                            {[...Array(8)].map((_, i) => (
                                <Option key={i + 3} value={i + 3}>{i + 3}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Form>
        </BaseModal>
    );
};

export default QuestionModal;


const removeExtraFields = (values: EverydayNote.Form.Question) => {
    switch (values.type) {
        case 1:
        case 2:
        case 7:
            return {
                ...values,
                evaluationScaleMax: undefined,
            };
        case 3:
        case 4:
            return {
                ...values,
                candidate: undefined,
                evaluationScaleMax: undefined,
            };
        case 6:
            return {
                ...values,
                candidate: undefined,
            };
        case 5:
            return values;
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = values.type;
            return values;
    }
};
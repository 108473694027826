import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/RESULT_QUESTIONNAIRE';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * モーダルを開いた際に呼び出す。
 * 回答結果を取得するリクエストを飛ばす。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    () => ({}),
    () => ({})
);

/**
 * 質問の回答結果の詳細を開く。
 * */
export const openDetail = createAction(
    createActionType('OPEN_DETAIL' as const),
    (item) => ({item}),
    () => ({})
);

/**
 * 質問の回答結果の詳細を閉じる。
 * */
export const closeDetail = createAction(
    createActionType('CLOSE_DETAIL' as const),
    () => ({}),
    () => ({})
);

/**
 *アンケートの回答結果のCSVダウンロードをする 。
 * */
export const downloadCsv = createAction(
    createActionType('DOWNLOAD_CSV' as const),
    (uuid, encoding) => ({uuid, encoding}),
    () => ({})
);

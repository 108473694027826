import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/EDIT_QUESTIONNAIRE';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * 画面を開いた際に呼び出す。
 * アンケートの取得や、アンケートカテゴリの取得などの画面に必要な要素を取得する。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    () => ({}),
    () => ({})
);

/**
 * 選択中のカテゴリをStoreに保存する。
 * */
export const selectCategory = createAction(
    createActionType('SELECT_CATEGORY' as const),
    (uuid) => ({uuid}),
    () => ({})
);

/**
 * 画面が開いたあとに、アンケートが取得されたら呼び出す。
 * */
export const prepareViewAfter = createAction(
    createActionType('PREPARE_VIEW_AFTER' as const),
    () => ({}),
    () => ({})
);

/**
 * 新規作成or編集問わずに、編集モーダルの内容でリクエストする。
 * */
export const submitEdit = createAction(
    createActionType('SUBMIT_EDIT' as const),
    (values) => ({values}),
    () => ({})
);

/**
 * すべてのモーダルを閉じる。
 * */
export const cancelEdit = createAction(
    createActionType('CANCEL_EDIT' as const),
    () => ({}),
    () => ({})
);

/**
 *  対象者選択画面を開く。
 * */
export const openTarget = createAction(
    createActionType('OPEN_TARGET' as const),
    () => ({}),
    () => ({})
);

/**
 * 対象者選択モーダルの内容をStoreに保存する。対象者選択画面を閉じる。
 * */
export const submitTarget = createAction(
    createActionType('SUBMIT_TARGET' as const),
    (values) => ({values}),
    () => ({})
);

/**
 * 対象者選択画面を閉じる。
 * */
export const cancelTarget = createAction(
    createActionType('CANCEL_TARGET' as const),
    () => ({}),
    () => ({})
);

/**
 * アンケート編集モーダル内の、指定した質問を開く。
 * */
export const openQuestion = createAction(
    createActionType('OPEN_QUESTION' as const),
    (parentTmpItemId, parentTmpCandidateId, question) => ({parentTmpItemId, parentTmpCandidateId, question}),
    () => ({})
);

/**
 * アンケート編集モーダルの内の、質問編集モーダルの内容をStoreに保存する。
 * */
export const submitQuestion = createAction(
    createActionType('SUBMIT_QUESTION' as const),
    (values) => ({values}),
    () => ({})
);

/**
 * アンケート編集モーダル内の質問編集モーダルを閉じる。
 * */
export const cancelQuestion = createAction(
    createActionType('CANCEL_QUESTION' as const),
    () => ({}),
    () => ({})
);

/**
 * アンケート編集モーダル内の、指定した質問の並び順を上か下に変更する。
 * */
export const moveQuestion = createAction(
    createActionType('MOVE_QUESTION' as const),
    (upOrDown, item) => ({upOrDown, item}),
    () => ({})
);

/**
 * アンケート編集モーダル内の、質問をコピーしてモーダルを開く。
 * */
export const copyQuestion = createAction(
    createActionType('COPY_QUESTION' as const),
    (item) => ({item}),
    () => ({})
);

/**
 * アンケート編集モーダル内の、指定した質問を削除する。
 * */
export const deleteQuestion = createAction(
    createActionType('DELETE_QUESTION' as const),
    (tmpItemId) => ({tmpItemId}),
    () => ({})
);

/**
 * アンケート編集モーダル内の、指定した質問のファイルを削除する。
 * */
export const deleteFile = createAction(
    createActionType('DELETE_FILE' as const),
    (tmpItemId) => ({tmpItemId}),
    () => ({})
);

/**
 * アンケート編集モーダル内の、質問一覧にある質問を展開する。
 * */
export const toggleExpandQuestion = createAction(
    createActionType('TOGGLE_EXPAND_QUESTION' as const),
    (tmpItemId) => ({tmpItemId}),
    () => ({})
);

/**
 * アンケート編集モーダル内で、児童生徒に見えるデザインでアンケートをプレビューする。
 * */
export const toggleIsOpenedPreview = createAction(
    createActionType('TOGGLE_IS_OPENED_PREVIEW' as const),
    () => ({}),
    () => ({})
);

import { createSelector } from 'reselect';
import {BACKGROUND_JOB_TYPE} from '../../../../components/modules/Job/Background/BackgroundJobProvider';

const  questionnaireEditConnectionSelector = state => state.questionnaireEditConnection;
const  questionnaireDeleteConnectionSelector = state => state.questionnaireDeleteConnection;
const  questionnaireAnswerResultConnectionSelector = state => state.questionnaireAnswerResultConnection;
const  questionnaireCategorySearchSelector = state => state.questionnaireCategorySearchConnection;
const  resultQuestionnaireStateSelector = state => state.resultQuestionnaire;
const  questionnaireExportConnectionSelector = state => state.questionnaireExportConnection;
const  questionnaireTargetConnectionSelector = state => state.questionnaireTargetConnection;
const  questionnaireViewConnectionSelector = state => state.questionnaireViewConnection;
const  jobInfoMapSelector = state => state.jobInfoMap;

export const resultQuestionnaireSelector = {

    loading: createSelector(
        [
            questionnaireAnswerResultConnectionSelector,
            questionnaireEditConnectionSelector,
            questionnaireDeleteConnectionSelector,
            questionnaireCategorySearchSelector,
            questionnaireTargetConnectionSelector,
            questionnaireViewConnectionSelector
        ],
        (
            questionnaireAnswerResultConnection,
            questionnaireEditConnection,
            questionnaireDeleteConnection,
            questionnaireCategorySearchConnection,
            questionnaireTargetConnection,
            questionnaireViewConnection
        ) =>
            (
                questionnaireAnswerResultConnection.meta.fetch ||
                questionnaireEditConnection.meta.fetch ||
                questionnaireDeleteConnection.meta.fetch ||
                questionnaireCategorySearchConnection.meta.fetch ||
                questionnaireTargetConnection.meta.fetch ||
                questionnaireViewConnection.meta.fetch
            )
    ),

    /** バックグラウンドジョブで、そのアンケートの回答結果がダウンロード済み or ダウンロード中のときは、新しくダウンロードできない  */
    disabledCsvExport: createSelector(
        [
            resultQuestionnaireStateSelector,
            questionnaireExportConnectionSelector,
            questionnaireAnswerResultConnectionSelector,
            jobInfoMapSelector
        ],
        (resultQuestionnaire, questionnaireExportConnection, questionnaireAnswerResultConnection, jobInfoMap) =>
        {
            return !resultQuestionnaire.questionnaire
                || questionnaireExportConnection.meta.fetch
                || questionnaireAnswerResultConnection.meta.fetch
                || (Object.values(jobInfoMap)
                    .filter(jobInfo => (
                        jobInfo?.job?.type === BACKGROUND_JOB_TYPE.ANSWER_USER_RESULT_CSV_EXPORT ||
                        jobInfo?.job?.type === BACKGROUND_JOB_TYPE.CONTROL_USER_RESULT_CSV_EXPORT ||
                        jobInfo?.job?.type === BACKGROUND_JOB_TYPE.CONTROL_QUESTIONNAIRE_CSV_EXPORT
                    ))
                    .filter(jobInfo => jobInfo?.someUuid === resultQuestionnaire?.questionnaire?.uuid).length > 0)
        }
    ),

    /** バックグラウンドジョブで、そのアンケートの回答結果がダウンロード済み or ダウンロード中のときは、新しくダウンロードできない  */
    loadingButtons: createSelector(
        [questionnaireExportConnectionSelector, questionnaireAnswerResultConnectionSelector],
        (questionnaireExportConnection, questionnaireAnswerResultConnection) =>
        {
            return questionnaireExportConnection.meta.fetch || questionnaireAnswerResultConnection.meta.fetch
        }
    )
}

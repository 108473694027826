import { createAction } from 'redux-actions';
import {
    API_ORGANIZATION,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/ORGANIZATION';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

type DefaultPayload = { method: HttpMethods, api: string };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

type Payload<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultPayload
        : Expand<DefaultPayload & EXTRA>;
type Meta<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultMeta
        : Expand<DefaultMeta & EXTRA>;


type SearchParameter = Http.Connection.Request.Parameter.Organization.Search;
export const search = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.Organization.Search,
    Meta<{ viewName: string }>,
    SearchParameter,
    number | string | undefined,
    string
>(
    createActionType('SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '1' : page;
        return ({ method: 'get', data, api: `${API_ORGANIZATION}/${pageNum}` })
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

type CreateParameter = Http.Connection.Request.Parameter.Organization.Edit;
export const create = createAction<
    Payload<{ data: CreateParameter }> | Http.Connection.Response.Organization.Create,
    Meta,
    CreateParameter
>(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_ORGANIZATION + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

type UpdateParameter = Http.Connection.Request.Parameter.Organization.Edit;
export const update = createAction<
    Payload<{ data: UpdateParameter }> | Http.Connection.Response.Organization.Update,
    Meta,
    string,
    UpdateParameter
>(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_ORGANIZATION + '/update/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const deleteAction = createAction<
    Payload | Http.Connection.Response.Organization.Delete,
    Meta,
    string
>(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_ORGANIZATION + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deleteAction as delete };

const importAction = createAction(
    createActionType('IMPORT' as const),
    (query, contentType, data) => ({
        method: 'import',
        api: `${API_ORGANIZATION}/import-csv?${query}`,
        data,
        contentType: contentType
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { importAction as import };

export const agencyLogin = createAction<
    Payload | Http.Connection.Response.Organization.AgencyLogin,
    Meta,
    string
>(
    createActionType('AGENCY_LOGIN' as const),
    (uuid) => ({ method: 'get', api: API_ORGANIZATION + '/agency-login/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

import React from 'react';

import { ColumnsType } from 'antd/lib/table';

import { BaseTable } from 'components/modules/BaseTable';

import { UserItemResultState } from '../../hooks/useUserItemResult';


type ExamItem = NonNullable<UserItemResultState['result']>['items'][0]['examItem'];

type Props = {
    userResultItemState: UserItemResultState
}

export const UserDetailTable: React.VFC<Props> = ({
    userResultItemState: { result, isLoading },
}) => {
    const dataSource = (result?.items ?? []).map(item => item.examItem);
    return (
        <div className='table' style={{ width: '100%' }}>
            <BaseTable<ExamItem>
                columns={columns}
                dataSource={dataSource}
                emptyDescription='データがありません'
                loading={isLoading}
                pagination={false}
            />
        </div>
    );
};


const columns: ColumnsType<ExamItem> = [
    {
        align: 'center',
        key: 'pageNumbers',
        render: (_, __, index: number) => {
            return index + 1;
        },
        title: 'ページ',
        width: 50,
    },
    {
        align: 'left',
        key: 'contentTitle',
        render: (_, examItem) => {
            return examItem.contentTitle;
        },
        title: '設問タイトル',
        width: 100,
    },
    {
        align: 'center',
        key: 'scoreSymbol',
        render: (_, examItem) => {
            return 'result' in examItem ?
                examItem.result.scoreSymbol ?? '' :
                '';
        },
        title: '正誤',
        width: 100,
    },
    {
        align: 'center',
        key: 'scorePer',
        render: (_, examItem) => {
            return 'result' in examItem ?
                `${examItem.result.scorePer}%` :
                '';
        },
        title: '正答率',
        width: 100,
    },
    {
        align: 'center',
        key: 'unitAveragePer',
        render: (_, examItem) => {
            return examItem.unitAveragePer ?
                `${examItem.unitAveragePer}%` :
                '';
        },
        title: '平均正答率(クラス)',
        width: 100,
    },
    {
        align: 'center',
        key: 'averagePer',
        render: (_, examItem) => {
            return examItem.averagePer ?
                `${examItem.averagePer}%` :
                '';
        },
        title: '平均正答率(全体)',
        width: 100,
    },
    {
        align: 'center',
        key: 'duration',
        render: (_, examItem) => {
            return 'result' in examItem ?
                examItem.result.totalDuration :
                '';
        },
        title: '解答時間',
        width: 100,
    },
    {
        align: 'center',
        dataIndex: 'totalDurationAverage',
        key: 'totalDurationAverage',
        render: (_, examItem) => {
            return examItem.totalDurationAverage ?? '';
        },
        title: '平均解答時間(クラス)',
        width: 100,
    },
    {
        align: 'left',
        dataIndex: 'subjectChildName',
        key: 'subjectChildName',
        render: (_, examItem) => {
            return examItem.subjectChildName;
        },
        title: '科目名',
        width: 100,
    },
];
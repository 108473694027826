import { VFC } from 'react';
import { Tag } from 'antd';

type Props = {
    /** 対象選択で使われるcurrentTarget */
    currentTarget: {
        target_school_classes?: SchoolClass[]
        target_users?: User[]
        target_roles?: Role[]
        target_organizations?: Organization[]
        target_main_teacher?: string,
    }
    /** 全体管理者からの配信されたアイテムかどうか */
    isPublishedItem?: boolean
}

/**
 * 現在選択中の対象の詳細の表示
 */
export const TargetDetails: VFC<Props> = ({
    currentTarget,
    isPublishedItem,
}) => {
    const {
        target_school_classes = [],
        target_users = [],
        target_roles = [],
        target_organizations = [],
        target_main_teacher = undefined,
    } = currentTarget;

    return (
        <div>
            {target_school_classes.length > 0 ?
                <div>対象クラス: {target_school_classes.length}クラス</div> : null
            }

            {target_users.length > 0 ?
                <div>対象ユーザー: {target_users.length}人</div> : null
            }

            {target_roles.length > 0 ?
                (isPublishedItem ?
                    <div>
                        <div>対象役割: {target_roles.length}個</div>
                        {target_roles.map(
                            role => (
                                <span className='mr-2' key={role.uuid}>
                                    <Tag color='processing' >{role.name}</Tag>
                                </span>
                            )
                        )}
                    </div> :
                    <div>対象役割: {target_roles.length}個</div>
                ) : null
            }

            {target_organizations.length > 0 ?
                <div>対象学校: {target_organizations.length}校</div> : null
            }

            {target_main_teacher ?
                <div>主担任: 1名</div> : null
            }
        </div>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';


export type SubjectListState = {
    isLoading: boolean
    items: { id: string, name: string }[]
};

type Connection = TaoExamCategorySubjectListConnection | TaoExamCategoryControlSubjectListConnection;

export const useSubjectList = () => {
    const [state, setState] = useState<SubjectListState>({
        isLoading: false,
        items: [],
    });
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamCategorySubjectListConnection = useAppSelector(state => state.taoExamCategorySubjectListConnection);
    const taoExamCategoryControlSubjectListConnection = useAppSelector(state => state.taoExamCategoryControlSubjectListConnection);
    const dispatch = useDispatch();

    const effect = useCallback((connection: Connection, state: SubjectListState) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setState(state => ({
                    ...state,
                    isLoading: meta.fetch,
                    items: Object.entries(payload.result.items).map(([key, val]) => ({
                        id: key,
                        name: val,
                    })),
                }));
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    items: [],
                }));
                break;
            }
            default:
                setState(state => ({ ...state, status: meta.status }));
                break;
        }
    }, []);

    useEffect(() => {
        effect(taoExamCategorySubjectListConnection, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, taoExamCategorySubjectListConnection]);

    useEffect(() => {
        effect(taoExamCategoryControlSubjectListConnection, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, taoExamCategoryControlSubjectListConnection]);

    const request = useCallback((
    ) => {
        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const action = isControllableUser ?
            Actions.http.connection.tao.examCategory.controlSubjectList :
            Actions.http.connection.tao.examCategory.subjectList;

        dispatch(action());
    }, [dispatch, isControllableUser]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
            items: [],
        });
    }, []);

    return [state, request, resetState] as const;
};
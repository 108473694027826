import {
    forwardRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Select.css';
import Ruby from './Ruby';

export const Select = forwardRef((props, ref) => {
    const {
        index,
        children = null,
        isForcedAttachRuby = false,
        errorMsg = '',
        loading = false,
        disable = false,
        value,
        ...restProps
    } = props;

    const commonClassName = classNames(
        'Select',// antdとtailwindが衝突しているからかうまくCSSが当たらないのでCSSを自作して適用
        'focus:outline-none focus:shadow-outline',
        `${!!errorMsg ? 'bg-red-300 bg-opacity-25' : null}`,
        `${(loading || disable) ? 'cursor-not-allowed opacity-50 bg-gray-400 bg-opacity-25' : ''}`
    );

    const ErrorMessageSpan = () => {
        return (
            <span className='text-xs absolute bottom-12 right-3 text-red-400'>
                {errorMsg && <Ruby isForcedAttach={isForcedAttachRuby}>{errorMsg}</Ruby>}
            </span>
        );
    };

    const IconSwitchArea = () => {
        return (
            // antdとtailwindが衝突しているからかうまくCSSが当たらないのでCSSを自作して適用
            <span className='Select-Icon-Switch-Area'>
                {loading
                    ? <span className='mr-2'>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </span>
                    : <svg className='fill-current h-4 w-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                        <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                    </svg>
                }
            </span>
        );
    };

    return (
        <div className='relative'>
            <select
                ref={ref}
                {...restProps}
                className={commonClassName}
                disabled={(loading || disable)}
                value={value}
                type='text'
            >
                {children}
            </select>
            <IconSwitchArea />
            <ErrorMessageSpan />
        </div>
    );
});

Select.propTypes = {
    children: PropTypes.node, //オプションが入る
    errorMsg: PropTypes.string,
    loading: PropTypes.bool,
    isForcedAttachRuby: PropTypes.bool,
};

Select.displayName = 'Select';

import { useAppSelector } from 'flex/utils';
import * as React from 'react';
import ControlContents from './ControlContents';
import AdminContents from './AdminContents';
import 'styles/pages/everyday-note.scss';

const EverydayNote: React.VFC = () => {
    return (
        <div className='page-everyday-note'>
            <MainContents />
        </div>
    );
};

export default EverydayNote;

const MainContents: React.VFC = () => {
    const isControllableUse = useAppSelector(state => state.isControllableUser.payload);

    return isControllableUse ?
        <ControlContents /> :
        <AdminContents />;
};

import { useEffect } from 'react';
import {useFormContext} from 'react-hook-form';
import Ruby from 'components/modules/Student/Ruby';
import QuestionItemCard from './QuestionItemCard';
import FiveLevelCandidate from './FiveLevelCandidate';

const FiveLevelSelection = props => {
    const {
        content,
        detail,
        file,
        isRequired,
        name,
    } = props;
    const {errors, register, unregister, watch} = useFormContext();

    useEffect(() => {
        const validate = {};
        if(isRequired) {
            validate.required = true;
        }
        register(name, validate);
        return () => unregister(name);
    }, [register, unregister, name, isRequired]);

    let errorMessage = '';
    if(errors[name]?.message !== undefined) {
        switch (errors[name].type) {
            case 'required':
                errorMessage = <div><Ruby>{'{回答|かいとう}が{必要|ひつよう}です。'}</Ruby></div>;
                break;
            default:
                errorMessage = <div>{errors[name].message}</div>;
                break;
        }
    }

    return (
        <QuestionItemCard
            title={content}
            detail={detail}
            error={errorMessage}
            file={file}
            // 顔マーク5段階だけど, ユーザーからは 1 つ選べるという意味で単一選択と変わらないので
            // 単一回答としてると思われる.
            type={<Ruby>{'{単一回答|たんいつかいとう}'}</Ruby>}
            isRequired={isRequired}
            watch={watch}
        >
            <div className='grid grid-cols-2 gap-4'>
                <FiveLevelCandidate
                    ref={register}
                    watch={watch}
                    name={name}
                />
            </div>

        </QuestionItemCard>
    );
};

export default FiveLevelSelection;
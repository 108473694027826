import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { BaseTable } from 'components/modules/BaseTable';
import { usePagination } from 'components/presentational/pages/Assessment/hooks/usePagination';

import { ResultItemState } from '../../hooks/useResultItem';


type User = NonNullable<ResultItemState['result']>['users'][number];
type ExamItemWith = User['resultHistories'][number]['items'][number];
type ExamItemWithResult = Extract<ExamItemWith, { result: unknown }>

const byExamItemUuid = (examItemUuid: string) => (item: ExamItemWith): item is ExamItemWithResult => {
    return ('result' in item) && item.result?.examItemUuid === examItemUuid;
};

type Props = {
    onClickUserName: (user: User, historyUuid: string) => void
    resultItemState: ResultItemState
    pagination: ReturnType<typeof usePagination>[0]
}

export const AnswerStatusTable: React.VFC<Props> = ({
    onClickUserName,
    resultItemState: { result, isLoading },
    pagination,
}) => {
    const dataSource = result?.users ?? [];

    const columns: ColumnsType<User> = [
        {
            align: 'left',
            dataIndex: 'displayName',
            key: 'displayName',
            render: (_: unknown, user: User) => {

                return user.resultHistories[0] === undefined ?
                    (
                        <div className='ant-btn-like'>
                            {user.displayName}
                        </div>
                    ) :
                    (
                        <Button
                            className='link'
                            onClick={() => onClickUserName(
                                user,
                                user.resultHistories[0]?.uuid ?? ''
                            )}
                            type='link'
                        >
                            {user.displayName}
                        </Button>
                    );
            },
            title: 'ユーザー名',
            width: 300,
        },
        {
            align: 'center',
            key: 'correctRate',
            render: (_: unknown, user: User) => {
                const rate = user.resultHistories.at(0)?.correctRate;

                if (rate === undefined) return null;
                return rate;
            },
            title: (
                <div>
                    正答率
                    <Tooltip
                        className='icon-l'
                        title='各ユーザーが最後にテストを受検した時の正答率です。'
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            ),
            width: 100,
        },
        {
            align: 'center',
            children: correctionsOnEachPage(result?.examItems),
            dataIndex: 'author',
            title: (
                <div>
                    各ページの正誤
                    <Tooltip
                        className='icon-l'
                        title='テストの１ページごとの正誤情報です。１ページの中のすべての問題に正解していた場合は○と表示されます。手動採点や自己採点の問題などでは、正誤の情報が表示されない場合があります。'
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            ),
            width: 500,
        },
    ];


    const expandsColumns: ColumnsType<NonNullable<ResultItemState['result']>['users'][0]['resultHistories'][0]> = [
        {
            align: 'left',
            key: 'empty',
            render: (_: unknown) => <div style={{ maxWidth: '16px', minWidth: '16px', width: '16px' }} />,
            width: 49,
        },
        {
            align: 'left',
            dataIndex: 'displayName',
            key: 'displayName',
            render: (_: unknown) => null,
            title: 'ユーザー名',
            width: 300,
        },
        {
            align: 'center',
            key: 'correctRate',
            render: (_: unknown, resultHistory) => {
                const rate = resultHistory.correctRate;

                if (rate === undefined) return null;
                return rate;
            },
            title: '正答率',
            width: 100,
        },
        {
            align: 'center',
            children: correctionsOnEachPage2(result),
            dataIndex: 'author',
            title: '各ページの正誤',
            width: 500,
        },
    ];

    return (
        <div className='table' style={{ width: '100%' }}>
            <BaseTable<User>
                columns={columns}
                dataSource={dataSource}
                emptyDescription='データがありません'
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <BaseTable<NonNullable<ResultItemState['result']>['users'][0]['resultHistories'][0]>
                                columns={expandsColumns}
                                dataSource={record.resultHistories.slice(1)}
                                emptyDescription='データがありません'
                                pagination={false}
                            />
                        );
                    },
                    rowExpandable: (record) => record.resultHistories.length > 1,
                }}
                loading={isLoading}
                pagination={pagination}
            />
        </div>
    );
};


const correctionsOnEachPage = (examItems: NonNullable<ResultItemState['result']>['examItems'] | undefined) => examItems?.map((examItem, i) => {
    return {
        align: 'center' as const,
        className: examItem.isHighlight ?
            'highlight' :
            '',
        key: examItem.uuid,
        render: (user: User) => {
            // 型がうまく補完されないので、無理やりやっている
            const exam = (user.resultHistories[0]?.items as ExamItemWith[])?.find(byExamItemUuid(examItem.uuid));
            if (!exam) return null;

            return exam.result?.scoreSymbol;
        },
        title: i + 1,
        width: 100,
    };
}) ?? [];

const correctionsOnEachPage2 = (
    result: NonNullable<ResultItemState['result']> | undefined
) => (
    result
        ?.users
        .reduce((a, b) => a.resultHistories.length > b.resultHistories.length ? a : b)
        .resultHistories
        .at(0)
        ?.items
        .map((examItem, i) => {
            return {
                align: 'center' as const,
                className: examItem.isHighlight ?
                    'highlight' :
                    '',
                key: examItem.uuid,
                render: (resultHistory: NonNullable<ResultItemState['result']>['users'][0]['resultHistories'][0]) => {
                    // 型がうまく補完されないので、無理やりやっている
                    const exam = (resultHistory?.items as ExamItemWith[])?.find(byExamItemUuid(examItem.uuid));
                    if (!exam) return null;

                    return exam.result?.scoreSymbol;
                },
                title: i + 1,
                width: 100,
            };
        }) ?? []
);
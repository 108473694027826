import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

const CandidateOption = forwardRef(({ type, text, value, name, watch }, ref) => {
    let checked = false;
    switch (type) {
        case 'checkbox':
            checked = watch(`${name}`)?.includes(value);
            break;
        case 'radio':
            const formValue = watch(`${name}`);
            checked = formValue !== undefined && formValue === value;
            break;
        default:
            break;
    }

    return (
        <div className={classNames(
            'inline-flex items-center rounded-md',
            !checked && 'border border-gray-300 bg-white text-black',
            checked && 'border border-lg-green bg-lg-green text-white',
        )}>
            <label className='p-2 w-full break-words cursor-pointer'>
                <input
                    type={type}
                    className='hidden'
                    name={name}
                    value={value}
                    ref={ref}
                />
                <span className='ml-2'>{text}</span>
            </label>

            {checked && <div className='p-2'>
                <FontAwesomeIcon
                    color='#fff'
                    icon={faCheckCircle}
                    size='1x'
                />
            </div>}
        </div>
    )
});

export default CandidateOption;

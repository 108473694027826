import { useMemo } from 'react';

import BaseForm from 'components/modules/BaseForm';

import { EditParams } from './EditParams';
import { EditUrl } from './EditUrl';
import { FormCard } from './FormCard';

const Form = BaseForm;

export const EditUrlAndParam = ({
    appTemplate,
    context,
    disabled,
    isControllableUser,
    isCreate,
    layout,
    layoutWithoutLabel,
}) => {
    const form = Form.useFormInstance();

    const expandedParams = useMemo(() => {
        return getExpandedParams(appTemplate, isControllableUser);
    }, [appTemplate, isControllableUser]);

    if (!appTemplate) return <></>;

    const useOrganizationCode = getUseOrganizationCode(
        context,
        isControllableUser,
        isCreate,
        appTemplate,
    );

    const isEdit = isEditable(appTemplate.url, isControllableUser);

    const isDisabled = disabled || !isEdit;

    // 全体管理者の場合、
    //     アプリ配信管理：不許可でも表示する必要有
    //     教材・アプリ：不許可の場合表示しない
    // 学校管理者の場合、
    //    編集出来ない場合は表示しない
    const isHidden = isControllableUser ?
        context === 'App' && !appTemplate?.url?.changeable_param_control :
        !isEdit;

    return (
        <>
            <FormCard
                noStyle={isCreate || isHidden}
                title={appTemplate.url?.name}
            >
                <EditUrl
                    appTemplate={appTemplate}
                    context={context}
                    disabled={isDisabled}
                    form={form}
                    isControllableUser={isControllableUser}
                    isHidden={isHidden}
                    layout={layout}
                    layoutWithoutLabel={layoutWithoutLabel}
                    useOrganizationCode={useOrganizationCode}
                />
            </FormCard>

            <Form.Item noStyle>
                <EditParams
                    disabled={disabled}
                    form={form}
                    layout={layout}
                    layoutWithoutLabel={layoutWithoutLabel}
                    params={expandedParams}
                />
            </Form.Item>
        </>
    );
};

const getExpandedParams = (appTemplateOrRecord, isControllableUser) => {
    if (!Array.isArray(appTemplateOrRecord?.params)) return [];

    return appTemplateOrRecord.params
        .filter(param => isEditable(param, isControllableUser))
        .map(param => ({
            ...param,
            isHidden: !isPermittedToEdit(param, isControllableUser),
        }));
};

const isEditable = (urlOrParam, isControllableUser) => {
    if (!urlOrParam) return false;

    if (urlOrParam.is_choices) {
        // リストから選択する場合、全体管理者は選択肢の中から初期値を選択する必要があるので、
        // 選択肢が存在するときは編集可能とする
        return isControllableUser ?
            urlOrParam.candidates.length > 0 :
            urlOrParam.changeable_param_admin;
    } else {
        return isPermittedToEdit(urlOrParam, isControllableUser);
    }
};

const isPermittedToEdit = (urlOrParam, isControllableUser) => {
    if (!urlOrParam) return false;

    return isControllableUser ?
        urlOrParam.changeable_param_control :
        urlOrParam.is_choices ?
            urlOrParam.changeable_param_admin :
            // 学校管理者の場合、固定/変数値は編集不可
            // 古いデータは固定/変数値でも学校管理者に編集権限を渡してしまっているものがあるため強制的に false
            false;
};

/**
 * use_organization_code を取得するための関数
 *
 * 状況によって appTemplate の形が異なるため少し複雑になっている
 */
const getUseOrganizationCode = (context, isControllableUser, isCreate, appTemplate) => {
    switch (context) {
        case 'App':
            if (isControllableUser) {
                return appTemplate.lti_app_template.use_organization_code;
            } else {
                // use_organization_code が 存在しない
            }
            break;
        case 'AppTemplate':
            if (isControllableUser) {
                return appTemplate.use_organization_code;
            } else {
                // 学校管理者は アプリ配信管理はない
            }
            break;
        default:
            // 上記以外の context はない
            break;
    }
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Layout,
    Menu,
    PageHeader,
    Avatar,
    Dropdown,
    Space,
    Modal,
} from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    MenuOutlined,
    LogoutOutlined,
    BookOutlined,
    QuestionOutlined
} from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import {
    checkEnvironmentIsTao,
    generateTitle,
    getDefaultFeatures,
    generateUserDomain,
} from 'constants/GlobalConfig';
import Title from 'components/modules/Title';
import { BackgroundJobMenu } from '../../modules/Job/Background/BackgroundJobMenu';
import { BackgroundJobProvider } from '../../modules/Job/Background/BackgroundJobProvider';
import LeftSideMenu from 'components/modules/LeftSideMenu';
import { LicenseModal } from 'components/modules/LicenseModal';

const { Content, Footer, Sider, } = Layout;

const BaseLayout = props => {
    const [, setFeatures] = useState(getDefaultFeatures());
    const dispatch = useDispatch();
    const isControllableUser = useSelector(state => state.isControllableUser);
    const manualGetConnection = useSelector(state => state.manualGetConnection);
    const location = useLocation();
    const [titleObject, setTitleObject] = useState(generateTitle(location.pathname));
    const user = useSelector(state => state.user);
    const tenant = useSelector(state => state.tenant);
    const [collapsed, setCollapsed] = useState(false)
    const [visible, setVisible] = useState(false);
    const session = useAppSelector(state => state.session);

    useEffect(() => {
        setTitleObject(generateTitle(location.pathname));
        document.querySelector('html').scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (tenant.payload?.features) {
            setFeatures(tenant.payload.features);
        }
    }, [tenant]);

    useEffect(() => {
        if (manualGetConnection.payload?.result !== undefined) return;

        dispatch(Actions.http.connection.manual.get());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (session.payload.is_timeout) {
            Modal.error({
                title: 'セッションはタイムアウトしました',
                content: '再度ログインしてください。',
                onOk: () => { window.location.replace('./') },
            });
        }
    }, [session]);

    const logout = () => {
        dispatch(Actions.http.connection.authentication.logout());
    };

    const goPortal = () => {
        window.location = generateUserDomain();
    };

    const manualInfo = {
        isActive: manualGetConnection.payload.result?.is_active,
        label: manualGetConnection.payload.result?.label,
        url: manualGetConnection.payload.result?.url,
    };

    const menu = (
        isControllableUser.payload ?
            <Menu>
                {manualInfo.isActive &&
                    <Menu.Item
                        icon={<BookOutlined />}
                        onClick={() => { window.open(manualInfo.url, '_blank'); }}
                        style={{ padding: '16px' }}
                    >
                        <span>{manualInfo.label}</span>
                    </Menu.Item>
                }
                <Menu.Item
                    icon={<QuestionOutlined />}
                    onClick={() => { window.open(checkEnvironmentIsTao() ? 'https://support2.mexcbt.mext.go.jp/contact/' : 'https://www.info.l-gate.net/inquiry_mng/', '_blank'); }}
                    style={{ padding: '16px' }}
                >
                    <span>ヘルプデスク</span>
                </Menu.Item>
                <Menu.Item
                    key='header_menu_1'
                    icon={<LogoutOutlined />}
                    danger
                    style={{ padding: '16px' }}
                    onClick={logout}
                >
                    サインアウト
                </Menu.Item>
            </Menu>
            :
            <Menu>
                <Menu.Item
                    key='header_menu_1'
                    icon={<HomeOutlined />}
                    onClick={goPortal}
                    style={{ padding: '16px' }}
                    disabled={isControllableUser.payload}
                >
                    ユーザーホーム画面
                </Menu.Item>
                {manualInfo.isActive &&
                    <Menu.Item
                        icon={<BookOutlined />}
                        onClick={() => { window.open(manualInfo.url, '_blank'); }}
                        style={{ padding: '16px' }}
                    >
                        <span>{manualInfo.label}</span>
                    </Menu.Item>
                }
                <Menu.Item
                    icon={<QuestionOutlined />}
                    onClick={() => { window.open(checkEnvironmentIsTao() ? 'https://support2.mexcbt.mext.go.jp/contact/' : 'https://www.info.l-gate.net/inquiry_mng/', '_blank'); }}
                    style={{ padding: '16px' }}
                >
                    <span>ヘルプデスク</span>
                </Menu.Item>
                <Menu.Item key='headeR_menu_2'
                    icon={<LogoutOutlined />}
                    danger
                    onClick={logout}
                    style={{ padding: '16px' }}
                >
                    <span>サインアウト</span>
                </Menu.Item>
            </Menu>
    );

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Title text={titleObject.title} />
            <Sider
                style={{
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                breakpoint='lg'
                collapsedWidth='0'
                onBreakpoint={() => { }}
                onCollapse={(collapsed, _) => {
                    setCollapsed(collapsed);
                }}
                className='global-nav'
                width={250}
            >
                <div className={checkEnvironmentIsTao() ? 'logo-m' : 'logo'} />

                <LeftSideMenu selectedKey={location.pathname} />

                {/*バックグラウンドジョブ*/}
                <BackgroundJobProvider>
                    <BackgroundJobMenu collapsed={collapsed} />
                </BackgroundJobProvider>

            </Sider>
            <Layout className='container-outer'>
                <PageHeader
                    style={{
                        padding: '0 24',
                        background: 'white',
                        border: '1px solid rgb(235, 237, 240)',
                    }}
                    title={titleObject.title}
                    subTitle={titleObject.subTitle}
                    extra={[
                        <Dropdown key='dropdownExtra' overlay={menu} placement='topRight' arrow trigger={['click']}>
                            <Space size={15} className='header_prof'>
                                <Avatar key='avator'
                                    style={isControllableUser.payload ? { backgroundColor: '#f50' } : { backgroundColor: '#87d068' }}
                                    size={44} icon={<UserOutlined />}
                                />
                                <div>
                                    <span key='tenant'
                                        className='header_label_tenant'
                                    >
                                        {tenant ? tenant.payload?.name : ''}
                                    </span>
                                    <span key='organization'
                                        className='header_label_organization'
                                    >
                                        {isControllableUser.payload ? '全体管理者' : `${user.payload?.organization?.name}`}
                                    </span><br />
                                    <span key='display_name'
                                        className='header_label_name'
                                    >
                                        {user ? `${user.payload?.user_name} さん` : ''}
                                    </span><br />
                                </div>
                                <MenuOutlined className='header_prof_icon' />
                            </Space>
                        </Dropdown>,
                    ]}
                />
                <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                    {props.children}
                </Content>
                <Footer style={{ textAlign: 'left' }}>
                    {checkEnvironmentIsTao() ? '' : 'L-Gate / '}WebAdmin 2021.9.20<br />
                    COPYRIGHT(C) UCHIDA YOKO CO., LTD. ALL RIGHTS RESERVED. | <Button type='link' onClick={() => setVisible(true)}>ライセンス情報</Button>
                </Footer>
            </Layout>

            <LicenseModal
                visible={visible}
                onClose={() => setVisible(false)}
            />
        </Layout>
    );
};

export default BaseLayout;

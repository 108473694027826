import { ReactChild, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Empty,
    FormInstance,
    Row,
    Select,
} from 'antd';

import BaseForm from 'components/modules/BaseForm';
import { BaseSpin } from 'components/modules/Spin';
import * as Actions from 'flex/Actions';

import { Source } from '../../Common/UsageDataProvider';
import { createAppSelectOptions } from '../../Common/utils';

const Form = BaseForm;
const { Option } = Select;

const CenterSpin = () => (
    <div style={{ textAlign: 'center' }}>
        <BaseSpin size='small' />
    </div>
);

type Props = {
    name: string;
    form: FormInstance;
    organizationList: Source['organizationList'];
    organizationAppLists: Source['organizationAppLists'];
    onRemove: () => void;
};

const AppSelectWithGroup: React.VFC<Props> = ({
    name,
    form,
    organizationList,
    organizationAppLists,
    onRemove,
}) => {
    const organizationUuid = form.getFieldsValue().targetDetails[name].group;
    const organizationAppInfo = organizationAppLists[organizationUuid] || { list: [], loading: false };

    const dispatch = useDispatch();

    useEffect(() => {
        if (organizationUuid === undefined || organizationAppInfo.list.length > 0) return;
        dispatch(Actions.http.connection.apps.search({ organization_uuid: organizationUuid, page_size: 50 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationUuid]);

    useEffect(() => {
        if (organizationAppInfo.list.length === 0) return;
        const targetDetails = form.getFieldsValue().targetDetails;
        targetDetails[name] = { app: 'all', group: organizationUuid };
        form.setFieldsValue({ targetDetails });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationAppInfo.list.length]);

    const onChangeAppGroup = (value: unknown) => {
        console.log(111111, value);
        const fields = form.getFieldsValue();
        const { targetDetails } = fields;
        Object.assign(targetDetails[name], { group: value });
        form.setFieldsValue({ targetDetails });
        form.resetFields([['targetDetails', name, 'app']]);
    };

    const onChangeApp = (value: unknown) => {
        const fields = form.getFieldsValue();
        const { targetDetails } = fields;
        Object.assign(targetDetails[name], { app: value });
        form.setFieldsValue({ targetDetails });
    };

    return (
        <div className='search-target-detail'>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        name={[name, 'group']}
                    >
                        <Select
                            allowClear
                            filterOption={(input, option) =>{
                                // 型がおかしいので、それらしい型に変換している
                                const children = option?.children as unknown as ReactChild;

                                if (typeof children === 'string') {
                                    return children.toLowerCase().includes(input.toLowerCase());
                                } else {
                                    // ここには来ないはず
                                    return false;
                                }
                            }}
                            notFoundContent={
                                organizationList.length === 0 ?
                                    <CenterSpin /> :
                                    undefined
                            }
                            onChange={onChangeAppGroup}
                            placeholder='対象を選択してください'
                            showSearch
                        >
                            {organizationList.map(org => {
                                return <Option key={org.value} value={org.value}>{org.text}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[name, 'app']}
                    >
                        <Select
                            notFoundContent={
                                organizationAppInfo.loading ?
                                    <CenterSpin /> :
                                    organizationAppInfo.list.length === 0 ?
                                        <Empty description='アプリがありません' image={Empty.PRESENTED_IMAGE_DEFAULT} /> :
                                        undefined
                            }
                            onChange={onChangeApp}
                            placeholder='対象を選択してください'
                        >
                            {createAppSelectOptions(organizationAppInfo.list)}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Button
                className='mr-2'
                danger
                icon={<DeleteOutlined />}
                onClick={onRemove}
                shape='circle'
                size='small'
            />
        </div>
    );
};

export default AppSelectWithGroup;

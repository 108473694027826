import { useState } from 'react';

import { Button, Spin } from 'antd';

import RichTextView from 'components/modules/RichTextView';

import useAnswerResultByItem from '../../hooks/useAnswerResultByItem';
import QuestionnaireMetaInfo from './QuestionnaireMetaInfo';
import { QuestionResult } from './QuestionResults';


const SingleView = ({
    onClickAnswerDetail,
    questionnaire,
}: {
    onClickAnswerDetail: (item: Question) => void;
    questionnaire: Questionnaire;
}) => {
    const [index, setIndex] = useState(0);
    const [answerResult, fetchAnswerResult] = useAnswerResultByItem();

    const handleClickPrev = () => {
        const newIndex = index - 1;
        if (newIndex < 0) return;
        setIndex(newIndex);

        if (1 <= newIndex) {
            fetchAnswerResult(questionnaire.uuid, questionnaire.items[newIndex - 1].uuid);
        }
    };

    const handleClickNext = () => {
        const newIndex = index + 1;
        if (questionnaire.items.length + 1 < newIndex) return;
        setIndex(newIndex);

        if (1 <= newIndex) {
            fetchAnswerResult(questionnaire.uuid, questionnaire.items[newIndex - 1].uuid);
        }
    };

    return (
        <div className='single-view'>
            <div className='contents'>
                {index === 0 ?
                    <FirstPage questionnaire={questionnaire} /> :
                    <Spin
                        spinning={answerResult.isLoading}
                        tip='しばらくお待ち下さい...'
                    >
                        {answerResult.question ?
                            <div style={{ height: '100%' }}>
                                <QuestionResult
                                    item={answerResult.question}
                                    onClickAnswerDetail={onClickAnswerDetail}
                                />
                            </div> :
                            <div style={{ minHeight: '300px', width: '100%' }} />
                        }
                    </Spin>
                }
            </div>
            <div className='operator'>
                <div className='index'>
                    ( {index + 1}/{questionnaire.items.length + 1} )
                </div>
                <Button
                    disabled={answerResult.isLoading || index === 0}
                    loading={answerResult.isLoading}
                    onClick={handleClickPrev}
                    size='large'
                >
                    前へ
                </Button>
                <Button
                    disabled={answerResult.isLoading || index === questionnaire.items.length}
                    loading={answerResult.isLoading}
                    onClick={handleClickNext}
                    size='large'
                >
                    次へ
                </Button>
            </div>
        </div>
    );
};

export default SingleView;


const FirstPage = ({
    questionnaire,
}: {
    questionnaire: Questionnaire;
}) => {
    return (
        <>
            <QuestionnaireMetaInfo questionnaire={questionnaire} />

            <div className='module-questionnaire-title'>
                <h2 className='title'>{questionnaire.name}</h2>
                <RichTextView model={questionnaire.content} />
            </div>
        </>
    );
};

import { useEffect } from 'react';

import { statusEnum } from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
const STATUS_SUCCESS = statusEnum.SUCCESS;

/**
 *
 * バックグラウンドジョブの完了後リロードするためのカスタムフック
 *
 * @param jobTypes リロード対象のジョブタイプ
 * @param reload リロードするための関数
 */
export const useJobReloadEffect = (
    jobTypes: string[],
    reload: () => void
) => {
    const jobViewConnection = useAppSelector(state => state.jobViewConnection);

    useEffect(() => {
        const {
            payload: { result },
            meta: { status },
        } = jobViewConnection;

        if (result === undefined) return;

        const jobType = result.type;
        const isRequestSuccess = status === STATUS_SUCCESS && !!result.is_success && result.done_at !== null;

        if (isRequestSuccess && jobTypes.includes(jobType)) {
            reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobViewConnection]);
};
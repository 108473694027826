import { useEffect } from 'react';
import AppGroupsList from './AppGroupsList';
import {SourceProvider} from './SourceProvider';
import 'styles/pages/apps.scss';

const ControlAppContents = props => {
    useEffect(() => {
        localStorage.removeItem('controlAppDeepLinking');
    }, []);

    return (
        <SourceProvider>
            <AppGroupsList/>
        </SourceProvider>
    )
}

export default ControlAppContents;

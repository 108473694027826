import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { convertTableSource } from '../ResultAnswerUtils';
import { convertAdminQuestionnaire, convertQuestionnaire } from '../convertQuestionnaire';
import { getMoment } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';
const moment = getMoment();

type Result = {
    isLoading: boolean,
    questionnaire: EverydayNote.Questionnaire | undefined
    tableSource: EverydayNote.SubTotalTableRecord[]
    numberOfAllItems: number
}

type TermInfo = {
    /** 基準となる日付 */
    base: number
    /** 期間の種類 */
    type: EverydayNote.ResultTotal.TermTypes
}

const INIT_STATE: Result = {
    isLoading: false,
    questionnaire: undefined,
    tableSource: [],
    numberOfAllItems: 0,
};

export const useResultSubTotalQuestionnaire = () => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });
    const [term, setTerm] = useState<TermInfo>({
        base: moment().unix(),
        type: 'week',

    });
    const [question, setQuestion] = useState<EverydayNote.Question>();

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const controlEverydayNoteResultSubTotalConnection = useAppSelector(state => state.controlEverydayNoteResultSubTotalConnection);
    const adminEverydayNoteControlResultSubTotalConnection = useAppSelector(state => state.adminEverydayNoteControlResultSubTotalConnection);
    const adminEverydayNoteResultSubTotalConnection = useAppSelector(state => state.adminEverydayNoteResultSubTotalConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback(
        (
            questionnaireUuid: string,
            question: EverydayNote.Question,
            data: Http.Connection.Request.Parameter.EverydayNote.ResultSubTotal,
            termType: EverydayNote.ResultTotal.TermTypes,
            page: number,
            tabName?: 'board' | 'school'
        ) => {
            const resultSubTotalAction = isControllableUser ?
                Actions.http.connection.everydayNote.control.resultSubTotal :
                tabName === 'board' ?
                    Actions.http.connection.everydayNote.admin.controlResultSubTotal :
                    Actions.http.connection.everydayNote.admin.resultSubTotal;

            dispatch(resultSubTotalAction(questionnaireUuid, question.uuid, data, page));
            setResult(state => ({ ...state, isLoading: true }));
            setTerm({
                base: data.start_at,
                type: termType,
            });
            setQuestion(question);
        },
        [dispatch]// eslint-disable-line
    );

    type Connections = EverydayNoteResultSubTotalConnection | EverydayNoteControlResultSubTotalConnection | AdminEverydayNoteResultSubTotalConnection;
    const connectionEffect = <T extends Connections>(connection: T, converter: (result: T['payload']['result']['note']) => EverydayNote.Questionnaire) => () => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;
                if (question === undefined || term === undefined) break;

                setResult({
                    isLoading: false,
                    questionnaire: converter(payloadResult.note),
                    tableSource: convertTableSource(payloadResult.items, question, term),
                    numberOfAllItems: payloadResult.item_count
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
    };

    useEffect(
        connectionEffect(controlEverydayNoteResultSubTotalConnection, convertQuestionnaire),
        [controlEverydayNoteResultSubTotalConnection]
    );
    useEffect(
        connectionEffect(adminEverydayNoteControlResultSubTotalConnection, convertQuestionnaire),
        [adminEverydayNoteControlResultSubTotalConnection]
    );
    useEffect(
        connectionEffect(adminEverydayNoteResultSubTotalConnection, convertAdminQuestionnaire),
        [adminEverydayNoteResultSubTotalConnection]
    );

    return [result, request, reset] as const;
};

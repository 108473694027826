import { Tag, Tooltip } from 'antd';

export const TYPES_QUESTIONNAIRE_ANSWERED = 'QUESTIONNAIRE/ANSWERED';
export const TYPES_QUESTIONNAIRE_REQUIRED = 'QUESTIONNAIRE/REQUIRED';
export const TYPES_QUESTIONNAIRE_ANONYMOUS = 'QUESTIONNAIRE/ANONYMOUS';
export const TYPES_QUESTIONNAIRE_PUBLIC = 'QUESTIONNAIRE/PUBLIC';
export const TYPES_QUESTIONNAIRE_PUBLIC_RESULT = 'QUESTIONNAIRE/PUBLIC/RESULT';

export const TYPES_NOTIFICATION_PRIORITY = 'NOTIFICATION/PRIORITY';

export const generate = (type, data) => {
    switch (type) {
        //記名・無記名 分別tag
        case TYPES_QUESTIONNAIRE_ANONYMOUS:
            switch (data) {
                case true:
                    return (
                        <Tooltip
                            title={'アンケートは無記名で送信されます'}
                            placement='bottom'
                        >
                            <Tag color={'gray'} className='tag' >
                                無記名式
                            </Tag>
                        </Tooltip>
                    );
                case false:
                    return (
                        <Tooltip
                            title={'アンケートは記名で送信されます'}
                            placement='bottom'
                        >
                            <Tag color={'green'} className='tag' >
                                記名式
                            </Tag>
                        </Tooltip>
                    );
                default:
                    return (
                        <span />
                    );
            }

        //公開・非公開
        case TYPES_QUESTIONNAIRE_PUBLIC:
            switch (data) {
                case true:
                    return (
                        <Tooltip
                            title={'アンケートは公開されています'}
                            placement='bottom'
                        >
                            <Tag color={'blue'} className='tag' >
                                公開
                            </Tag>
                        </Tooltip>
                    );
                case false:
                    return (
                        <Tooltip
                            title={'アンケートは非公開です'}
                            placement='bottom'
                        >
                            <Tag color={'red'} className='tag'>
                                非公開
                            </Tag>
                        </Tooltip>
                    );
                default:
                    return (
                        <span />
                    );
            }

        //回答の公開・非公開
        case TYPES_QUESTIONNAIRE_PUBLIC_RESULT:
            switch (data) {
                case true:
                    return (
                        <Tooltip
                            title={'アンケートの回答結果は他の回答者に公開されます'}
                            placement='bottom'
                        >
                            <Tag color={'green'} className='tag'>
                                回答公開
                            </Tag>
                        </Tooltip>
                    );
                case false:
                    return (
                        <Tooltip
                            title={'アンケートの回答結果は他の回答者に公開されません'}
                            placement='bottom'
                        >
                            <Tag color={'gray'} className='tag'>
                                回答非公開
                            </Tag>
                        </Tooltip>
                    );
                default:
                    return (
                        <span />
                    );
            }

        //回答済・未回答分別tag
        case TYPES_QUESTIONNAIRE_ANSWERED:
            switch (data) {
                case true:
                    return (
                        <Tag className='tag gray'>回答済</Tag>
                    );
                case false:
                    return (
                        <Tag className='tag emergency'>未回答</Tag>
                    );
                default:
                    return (
                        <span />
                    );
            }

        //オプション 必須・任意
        case TYPES_QUESTIONNAIRE_REQUIRED:
            switch (data) {
                case 0:
                    return (
                        <Tag className='tag emergency'>必須</Tag>
                    );
                case 1:
                    return (
                        <Tag color='cyan'>任意</Tag>
                    );
                default:
                    return (
                        <span />
                    );
            }

        case TYPES_NOTIFICATION_PRIORITY:
            switch (data) {
                case 1:
                    return (
                        <span />
                    );
                case 2:
                    return (
                        <Tag className='tag important'>重要</Tag>
                    );
                case 3:
                    return (
                        <Tag className='tag emergency'>緊急</Tag>
                    );
                default:
                    return (
                        <span />
                    );
            }

        default:
            return (
                <span />
            );
    }
};

import * as React from 'react';

import { faSchool, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from 'antd';

import { TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import { ExamResultTemplate } from '../../templates/ExamResultTemplate';

const { TabPane } = Tabs;

export const TABS = {
    result_board: 'rb' as const,
    result_school: 'rs' as const,
};

type Props = {
    activeKey: string
    onChangTab: (tab: string) => void
};

const ExamResultContents: React.VFC<Props> = ({
    activeKey,
    onChangTab,
}) => {
    const localTab = activeKey === TABS.result_school ?
        TAB_NAMES.SCHOOL :
        TAB_NAMES.BOARD_OF_EDUCATION;

    const onChange = (activeKey: string) => {
        const tab = activeKey === TAB_NAMES.SCHOOL ?
            TABS.result_school :
            TABS.result_board;
        onChangTab(tab);
    };

    return (
        <Tabs
            activeKey={localTab}
            onChange={onChange}
        >
            <TabPane
                key={TAB_NAMES.SCHOOL}
                tab={
                    <span>
                        <FontAwesomeIcon icon={faSchool} style={{ height: 14, marginRight: 10, width: 14 }} />
                        学校配信
                    </span>
                }
            >
                <ExamResultTemplate
                    currentTabKey={localTab}
                    tabKey={TAB_NAMES.SCHOOL}
                />
            </TabPane>
            <TabPane
                key={TAB_NAMES.BOARD_OF_EDUCATION}
                tab={
                    <span>
                        <FontAwesomeIcon icon={faSitemap} style={{ height: 14, marginRight: 10, width: 14 }} />
                        一斉配信
                    </span>
                }
            >
                <ExamResultTemplate
                    currentTabKey={localTab}
                    tabKey={TAB_NAMES.BOARD_OF_EDUCATION}
                />
            </TabPane>
        </Tabs>
    );
};

export default ExamResultContents;

import { combineActions, handleAction } from 'redux-actions';
import * as Actions from 'flex/Actions';

const defaultState = {
    payload: {},
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
};

const defaultReducer = {
    next: (state: any, action: any) => {
        return { ...action };
    },
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const appsTemplateSearchConnection = handleAction(
    Actions.http.connection.apps.template.search,
    defaultReducer,
    defaultState
);

export const appsTemplateSearchForAdminConnection = handleAction(
    Actions.http.connection.apps.template.searchForAdmin,
    defaultReducer,
    defaultState
);

export const appsTemplateForAdminEditConnection = handleAction(
    combineActions(
        Actions.http.connection.apps.template.deleteForAdmin,
        Actions.http.connection.apps.template.updateForAdmin,
        Actions.http.connection.apps.template.createForAdmin,
    ),
    defaultReducer,
    defaultState
);

export const appsTemplateViewForAdminConnection = handleAction(
    Actions.http.connection.apps.template.viewForAdmin,
    defaultReducer,
    defaultState
);

import { createAction } from 'redux-actions';
import {
    API_SYNC,
} from 'constants/endpoints';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/SYNC';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const dryRunGroup = createAction(
    createActionType('DRY_RUN_GROUP' as const),
    (deleteJobId) => ({ method: 'sync', api: `${API_SYNC}/dryrun-group?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);

export const executeGroup = createAction(
    createActionType('EXECUTE_GROUP' as const),
    (deleteJobId) => ({ method: 'sync', api: `${API_SYNC}/execute-group?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);

export const dryRunUser = createAction(
    createActionType('DRY_RUN_USER' as const),
    (deleteJobId) => ({ method: 'sync', api: `${API_SYNC}/dryrun-user?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);

export const executeUser = createAction(
    createActionType('EXECUTE_USER' as const),
    (data, deleteJobId) => ({ method: 'sync', data, api: `${API_SYNC}/execute-user?encoding=sjis` }),
    (data, deleteJobId) => ({ fetch: true, deleteJobId })
);

export const dryRunGroupMember = createAction(
    createActionType('DRY_RUN_GROUP_MEMBER' as const),
    (deleteJobId) => ({ method: 'sync', api: `${API_SYNC}/dryrun-member?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);

export const executeGroupMember = createAction(
    createActionType('EXECUTE_GROUP_MEMBER' as const),
    (deleteJobId) => ({ method: 'sync', api: `${API_SYNC}/execute-member?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);

export const dryRunDeleteUser = createAction(
    createActionType('DRY_RUN_DELETE_USER' as const),
    (deleteJobId) => ({ method: 'sync', api: `${API_SYNC}/dryrun-deleted-user?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);

export const executeDeleteUser = createAction(
    createActionType('EXECUTE_DELETE_USER' as const),
    (data, deleteJobId) => ({ method: 'sync', data, api: `${API_SYNC}/execute-deleted-user?encoding=sjis` }),
    (deleteJobId) => ({ fetch: true, deleteJobId })
);
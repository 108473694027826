import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Space, Button, Divider, Popover } from 'antd';

import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';
import { generateFileDomain } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import useAnswerResultByItem from '../hooks/useAnswerResultByItem';
import useViewQuestion from '../hooks/useViewQuestion';
import * as TagElementGenerator from '../TagElementGenerator';
import { AnswerResultModal } from './AnswerResultModal';
import { AnswerGraph, AnswerDetailButton } from './ResultQuestionnaireModal/QuestionResults';

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    uuid: string | undefined
};

const FullScreenResultQuestionnaireModal: React.VFC<Props> = ({
    isOpen: isOpenFullScreenResult,
    onCancel,
    uuid,
}) => {
    const {
        modal: { isOpen, item },
    } = useAppSelector(state => state.resultQuestionnaire);
    const [index, setIndex] = useState(0);
    const [answerResult, fetchAnswerResult] = useAnswerResultByItem();
    const [{ questionnaire, isLoading: isLoadingInView }, fetchViewQuestionnaire] = useViewQuestion(
        (meta, payload) => uuid && fetchAnswerResult(uuid, payload.result.items[0].uuid)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isOpenFullScreenResult || !uuid) return;

        setIndex(0);
        fetchViewQuestionnaire(uuid, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenFullScreenResult]);

    const handleClickPrev = () => {
        if (!questionnaire || !uuid) return;
        const newIndex = index - 1;
        if (newIndex < 0) return;

        setIndex(newIndex);
        fetchAnswerResult(uuid, questionnaire.items[newIndex].uuid ?? '');
    };

    const handleClickNext = () => {
        if (!questionnaire || !uuid) return;

        const newIndex = index + 1;
        if (questionnaire.items.length - 1 < newIndex) return;

        setIndex(newIndex);
        fetchAnswerResult(uuid, questionnaire.items[newIndex].uuid);
    };

    const handleResultCancel = () => {
        dispatch(Actions.view.viewLogic.resultQuestionnaire.closeDetail());
    };

    const handleClickAnswerDetail = (item: Question) => {
        dispatch(Actions.view.viewLogic.resultQuestionnaire.openDetail(item));
    };

    return (
        <BaseModal
            className='full-screen-modal questionnaire-fullscreen-result'
            footer={[
                <h5 key='pagination' style={{ display: 'inline', width: '300px' }}>
                    {questionnaire ?
                        ` ( ${index + 1}/${questionnaire.items.length} ) ` :
                        ''
                    }
                </h5>,
                <Button
                    disabled={answerResult.isLoading || !questionnaire  || index === 0}
                    key='back'
                    loading={answerResult.isLoading}
                    onClick={handleClickPrev}
                    size='large'
                    type='primary'
                >
                    前へ
                </Button>,
                <Button
                    disabled={answerResult.isLoading || !questionnaire || index === questionnaire.items.length - 1}
                    key='next'
                    loading={answerResult.isLoading}
                    onClick={handleClickNext}
                    size='large'
                    type='primary'
                >
                    次へ
                </Button>,
            ]}
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={questionnaire ? <ModalTitle questionnaire={questionnaire} /> : ''}
            visible={isOpenFullScreenResult}
        >
            <Spin spinning={isLoadingInView || answerResult.isLoading} style={{ minHeight: '94vh' }} tip='しばらくお待ち下さい...'>
                {answerResult.question ?
                    <ShowResult
                        item={answerResult.question}
                        onClickAnswerDetail={handleClickAnswerDetail}
                    /> :
                    <div style={{ minHeight: '94vh' }}></div>
                }
            </Spin>

            {/* AnswerResultModal は BaseModal 内にないと後ろに隠れてしまう*/}
            <AnswerResultModal
                isFullScreen={true}
                isOpen={isOpen}
                item={item}
                onCancel={handleResultCancel}
                questionnaire={questionnaire}
            />
        </BaseModal>
    );
};


/**
 * アンケートのモーダルにつけるたいとるについて変更する
 */
const ModalTitle = ({ questionnaire }: { questionnaire: Questionnaire }) => {
    const tagNode = TagElementGenerator.generate(
        TagElementGenerator.TYPES_QUESTIONNAIRE_PUBLIC,
        questionnaire.isPublic,
    );
    const tagNode2 = TagElementGenerator.generate(
        TagElementGenerator.TYPES_QUESTIONNAIRE_ANONYMOUS,
        questionnaire.isAnonymous,
    );
    const tagNode3 = TagElementGenerator.generate(
        TagElementGenerator.TYPES_QUESTIONNAIRE_PUBLIC_RESULT,
        questionnaire.isPublicResult,
    );

    return (
        <Space size={8}>
            {tagNode}{tagNode2}{tagNode3}<div className='questionnaire-title'>{questionnaire.name}</div>
        </Space>
    );
};


const ShowResult = ({
    item,
    onClickAnswerDetail,
}: {
    item: Question;
    onClickAnswerDetail: (item: Question) => void;
}) => {
    if (!item) {
        return <></>;
    }

    const handleDownloadClick = (uuid: string) => {
        const urlString = `${generateFileDomain()}/file/view/${uuid}`;
        window.open(urlString);
    };

    return (
        <div key={item.uuid}>
            {/*タイトル*/}
            <Popover
                content={<h2>{item.content}</h2>}
            >
                <h1 className='slide-title'>{item.content}</h1>
            </Popover>

            <Divider />

            {/*設問の内容*/}
            <div className='answer-explain'>
                <Popover content={<h2>{item.detail}</h2>}>
                    <h4>{item.detail}</h4>
                </Popover>
            </div>

            <div className='detail-buttons'>
                {/* 添付ファイルのダウンロード */}
                {item.file && (
                    <Button
                        onClick={() => item.file && handleDownloadClick(item.file.uuid)}
                        size='large'
                        type='primary'
                    >
                        {item.file.fileName} をダウンロードする
                    </Button>
                )}
                {/*自由記述を見る or ファイルでの回答見るボタン*/}
                <AnswerDetailButton
                    item={item}
                    onClickAnswerDetail={onClickAnswerDetail}
                />
            </div>

            <div className='answer-results-container'>
                <AnswerCount item={item} />
                <AnswerGraph
                    isResponsive={true}
                    item={item}
                    legendProps={{
                        iconSize: 30,
                        lineHeight: 52,
                    }}
                />
            </div>
        </div>
    );
};

const AnswerCount = ({ item }: { item: Question & { tmpItemId?: string } }) => {
    let allAnswersCount = 0;
    if (item.type === 1 || item.type === 2) {
        item.answerCandidates.forEach(value => {
            allAnswersCount = allAnswersCount + (value.answerCount ?? 0);
        });
    } else {
        allAnswersCount = item.answerCount ?? 0;
    }

    switch (item.type) {
        case 1:
        case 2:
            return (
                <div className='answer-result-detail'>
                    <div className='answer-total-count'>
                        <h5>全回答数: {allAnswersCount}件</h5>
                    </div>

                    {item.answerCandidates.map((candidate, index) => (
                        <div className='answer-result-choice' key={candidate.uuid}>
                            <Popover
                                content={<h2>{candidate.content}</h2>}
                            >
                                <h2 key={candidate.uuid}>{`■選択肢${index + 1}:${candidate.content}`}</h2>
                            </Popover>
                            <h3>回答数: {candidate.answerCount ?? 0}件</h3>
                        </div>
                    ))}
                </div>
            );
        case 3:
            return (
                <div className='answer-result-detail'>
                    <h5>全回答数: {allAnswersCount}件</h5><br />
                </div>
            );
        case 4:
            return (
                <div className='answer-result-detail'>
                    <h5>全回答数: {allAnswersCount}件</h5><br />
                </div>
            );
        default:
            return <Fragment key={item.tmpItemId} ></Fragment>;
    }
};

export default FullScreenResultQuestionnaireModal;

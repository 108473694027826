import { useEffect } from 'react';
import QuestionItemCard from './QuestionItemCard';
import {useFormContext} from 'react-hook-form';
import Ruby from 'components/modules/Student/Ruby';


const FreeDescription = props => {
    const {
        content,
        detail,
        file,
        isRequired,
        name,
    } = props;
    const {errors, register, unregister, watch} = useFormContext();

    useEffect(() => {
        const validate = {};
        if(isRequired) {
            validate.required = true;
        }
        register(name, validate);
        return () => unregister(name);
    }, [register, unregister, name, isRequired]);

    let errorMessage = '';
    if(errors[name]?.message !== undefined) {
        switch (errors[name].type) {
            case 'required':
                errorMessage = <div><Ruby>{`${content} を{入力|にゅうりょく}してください。`}</Ruby></div>
                break;

            default:
                errorMessage = <div>{errors[name].message}</div>
                break;
        }
    }

    return (
        <QuestionItemCard
            title={content}
            detail={detail}
            error={errorMessage}
            file={file}
            type={<Ruby>{'{自由記述|じゆうきじゅつ}'}</Ruby>}
            isRequired={isRequired}
            watch={watch}
        >
            <textarea
                name={name}
                rows='3'
                className='w-full px-4 py-2'
                placeholder='自由に入力してください'
                ref={register}
            />
        </QuestionItemCard>
    );
}

export default FreeDescription;

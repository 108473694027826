import { Tabs } from 'antd';
import 'styles/pages/usage.scss';
import UserContents from './UserContents';
import { UsageDataProvider } from './Common/UsageDataProvider';

const Usage = () => {
    const userTabKey = 'User';

    return (
        <UsageDataProvider>
            <Tabs defaultActiveKey={userTabKey}>
                <Tabs.TabPane tab={<span>学校・クラス・利用者別</span>} key={userTabKey}>
                    <UserContents />
                </Tabs.TabPane>
            </Tabs>
        </UsageDataProvider>
    );
}

export default Usage;

import { convertQuestionnaireTabSearchToSubmittable } from './utils';
import * as Actions from 'flex/Actions';

/***
 * action.typeに VIEW_LOGIC/USER_MANAGEMENT_TAB を含むactionをSavingData middlewareで処理する際に呼び出す
 * @param store
 * @param next
 * @param action
 */
export const questionnaireTabSavingData = (store, next, action) => {

    const tabState = store.getState()['questionnaireTab'];
    const pagination = tabState['pagination'];

    switch (action.type) {
        case Actions.view.viewLogic.questionnaireTab.submitSearch.toString():
            next({
                ...tabState,
                search: convertQuestionnaireTabSearchToSubmittable(action.payload.values),
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.savePage.toString():
            next({
                ...tabState,
                pagination: {
                    ...pagination,
                    pageSize: action.payload.page_size,
                    current: action.payload.page,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.openEdit.toString():
            next({
                ...tabState,
                modal: {
                    ...tabState.modal,
                    uuid: action.payload.uuid,
                    shouldCreate: false,
                    isAnonymous: undefined,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.openCreate.toString():
            next({
                ...tabState,
                modal: {
                    ...tabState.modal,
                    uuid: null,
                    shouldCreate: true,
                    isAnonymous: undefined,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.openCopy.toString():
            next({
                ...tabState,
                modal: {
                    uuid: action.payload.uuid,
                    shouldCreate: true,
                    isTargetOpen: false,
                    isResultOpen: false,
                    isAnonymous: undefined,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.openResult.toString():
            next({
                ...tabState,
                modal: {
                    uuid: action.payload.uuid,
                    shouldCreate: false,
                    isTargetOpen: false,
                    isResultOpen: true,
                    isFullScreenResultOpen: false,
                    isAnonymous: undefined,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.openTarget.toString():
            next({
                ...tabState,
                modal: {
                    uuid: action.payload.uuid,
                    shouldCreate: false,
                    isTargetOpen: true,
                    isResultOpen: false,
                    isFullScreenResultOpen: false,
                    isAnonymous: action.payload.isAnonymous,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.cancelModal.toString():
            next({
                ...tabState,
                modal: {
                    ...tabState.modal,
                    // isEditOpen: false,
                    isTargetOpen: false,
                    isResultOpen: false,
                    isFullScreenResultOpen: false,
                },
                type: 'questionnaireTab'
            })
            break
        case Actions.view.viewLogic.questionnaireTab.submitEdit.toString():
            next({
                ...tabState,
                modal: {
                    uuid: null,
                    shouldCreate: false,
                    isTargetOpen: false,
                    isResultOpen: false,
                    isFullScreenResultOpen: false,
                    isAnonymous: undefined,
                },
                type: 'questionnaireTab'
            })
            break
        default:
            break;
    }
}

import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/EXAM_CATEGORY_TAB';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const cancelModal = createAction(
    createActionType('CANCEL_MODAL' as const),
    () => ({}),
    () => ({})
);

export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    (tabName) => ({tabName}),
    () => ({})
);

export const savePageStatusList = createAction(
    createActionType('SAVE_PAGE_STATUS_LIST' as const),
    (page, page_size) => ({page, page_size}),
    () => ({})
);

export const submittedSave = createAction(
    createActionType('SUBMITTED_SAVE' as const),
    () => ({}),
    () => ({})
);

export const submitEdit = createAction<
    {values: ExamCategory.EditInput, shouldCreate: boolean, isCopy:boolean, tabName: string},
    {},
    ExamCategory.EditInput,
    boolean,
    boolean,
    string
>(
    createActionType('SUBMIT_EDIT' as const),
    (values, shouldCreate, isCopy, tabName) => ({values, shouldCreate, isCopy, tabName}),
    () => ({})
);

export const submitPageStatusList = createAction(
    createActionType('SUBMIT_PAGE_STATUS_LIST' as const),
    (page, page_size, tabName) => ({page, page_size, tabName}),
    () => ({})
);

import {useSelector} from 'react-redux';


/***
 * viewLogicの処理に型をつけて、実行するクラス
 */
export class ViewLogicExecutor<StateType> {
    private viewLogics: {[key:string]:(store: StoreAccessor, next:(arg:ActionType<unknown,unknown>|Partial<StateType>)=>void, action: any)=>void}  = {}

    /**
     * 処理するべきViewLogicを追加する。この内部で定義すると、型が補完される
     * @param actionCreator Actions.から始まるAction作成関数
     * @param viewLogic store, next, actionを引数にとるViewLogic関数
     */
    addViewLogic<TActionCreator extends (...value: any[])=>ActionType<unknown,unknown>, TActionType = ReturnType<TActionCreator>>(
        actionCreator:TActionCreator,
        viewLogic: (store: StoreAccessor, next:(arg:ActionType<unknown,unknown>|Partial<StateType>)=>void, action:TActionType)=>void
    ){
        this.viewLogics[actionCreator().type] = viewLogic
    }

    /***
     * 実行する
     * @param store
     * @param next
     * @param action
     */
    execute(store: StoreAccessor, next:StoreNext<StateType>, action: ActionType<unknown,unknown>){
        const viewLogic = this.viewLogics[action.type]
        if(viewLogic){
            viewLogic(store, next, action);
        }else{
            next(action)
        }
    }
}

/***
 * SavingDataの処理に型をつける
 */
export class SavingDataExecutor<StateType> {
    private savingDatum: {[key:string]:(store: StoreAccessor, next:(arg:ActionType<unknown,unknown>|Partial<StateType>)=>void, action: any)=>void}  = {}

    /**
     * 処理するべきViewLogicを追加する。この内部で定義すると、型が補完される
     * @param actionCreator Actions.から始まるAction作成関数
     * @param savingData store, next, actionを引数にとるViewLogic関数
     */
    addSavingData<TActionCreator extends (...value: any[])=>ActionType<unknown,unknown>, TActionType = ReturnType<TActionCreator>>(
        actionCreator:TActionCreator,
        savingData: (store: StoreAccessor, next:(arg:ActionType<unknown,unknown>|Partial<StateType>)=>void, action:TActionType)=>void
    ){
        this.savingDatum[actionCreator().type] = savingData
    }

    /***
     * 実行する
     * @param store
     * @param next
     * @param action
     */
    execute(store: StoreAccessor, next:StoreNext<StateType>, action: ActionType<unknown,unknown>){
        const viewLogic = this.savingDatum[action.type]
        if(viewLogic){
            viewLogic(store, next, action);
        }else{
            next(action)
        }
    }
}


/***
 * actionとstateに型の補完を追加できるaddReducerを作成する
 */
export const createAddReducer = <StateType>() =>
    <TActionCreator extends (...value: any[])=>ActionType<unknown,unknown>>(
        actionCreator:TActionCreator,
        reducer: (state: StateType, action: ReturnType<typeof actionCreator>)=>StateType
    ) => (
        {[actionCreator.toString()]: reducer}
    )


/***
 *  selectorに型をつける
 */
type TypedUseSelectorHook<TState> = {
    <TSelected>(
        selector: (state: TState) => TSelected,
        equalityFn?: (left: TSelected, right: TSelected) => boolean
    ): TSelected;
}
export const useAppSelector: TypedUseSelectorHook<Store> = useSelector

import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalProps } from 'antd';

type Props =  ModalProps & {
    /** モーダル下部のフッターの左下に表示するメッセージ */
    extraMessage?: React.ReactNode | {
        leftStyle?: React.CSSProperties,
        rightStyle?: React.CSSProperties,
        message: React.ReactNode,
    }
}

const BaseModal: React.FC<Props> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { visible, children, ...restProps } = props;
    const [tempHeight, setTempHeight] = useState(0);
    const [currentHeight, setCurrentHeight] = useState(0);
    const [windowDimensions, setWindowDimensions] = useState({ height: 0, width: 0 });
    const [scrollY, setScrollY] = useState(0);
    const [isChangeWindowHeight, setIsChangeWindowHeight] = useState(false);
    // 自動スクロールと判断されないスクロール幅
    const ScrollFlexibilityNum = 200;

    const newFooter = props.extraMessage ? (
        <div style={{ display: 'flex' }}>
            <div style={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
            }}>
                {props.extraMessage}
            </div>
            <div style={{marginLeft: '1rem'}}>
                {props.footer}
            </div>
        </div >
    ) :
        props.footer;

    const modalProps = {...restProps, footer: newFooter, visible};

    useEffect(() => {// open時の処理（初期値取得など
        if (!visible) return;

        setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
        setTempHeight(window.innerHeight ?? 0);
        setCurrentHeight(window.innerHeight ?? 0);
        setScrollY(0);
        // スクロール位置をトップにするための処理
        setTimeout(() => {
            ref.current?.parentElement?.scrollTo(0, 0)
        }, 200);
    }, [visible]);

    useEffect(() => { // モーダルの高さを調整すべきか判断するところ
        if (!visible) return;

        if (windowDimensions.height < windowDimensions.width) {// 横向きのとき高さ調整を行う可能性がある
            if ((tempHeight * 3 / 4) > currentHeight) {
                setIsChangeWindowHeight(true);
            } else if (tempHeight === currentHeight) {
                setIsChangeWindowHeight(false);
            } else {
                setIsChangeWindowHeight(false);
            }
        } else {
            setIsChangeWindowHeight(false);
        }
    }, [currentHeight, tempHeight, visible]);// eslint-disable-line

    useEffect(() => {// バーチャルkeyboard表示時のresizeイベントを追加
        const onResize = () => {
            if (visible) { // スマホ表示時のアドレスバーのresizeイベントに影響するため、モーダルが開いているときだけ動作させる
                setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
                setCurrentHeight(visualViewport?.height ?? 0);
            }
        };
        visualViewport?.addEventListener('resize', onResize);
        return () => visualViewport?.removeEventListener('resize', onResize);
    }, [visible]);

    useEffect(() => {// スクロール処理
        const onScroll = (e: any) => {
            if (tempHeight === currentHeight) {
                setScrollY(e.target.scrollTop)
            }
            // スクリーンキーボードが表示された状態で自動スクロールされた場合、スクロール前の位置に戻す
            if ((isChangeWindowHeight) && ((e.target.scrollTop + ScrollFlexibilityNum) < scrollY)) {
                ref.current?.parentElement?.scrollTo(0, scrollY)
            }
        }
        if (ref && ref.current) {
            ref.current?.parentElement?.addEventListener('scroll', onScroll)
            return () => ref.current?.parentElement?.removeEventListener('scroll', onScroll);
        }
    }, [visible, isChangeWindowHeight, scrollY])// eslint-disable-line

    return (
        <Modal {...modalProps}>
            <div className='ant-modal-body-content' ref={ref}>
                {children}
            </div>
        </Modal>
    );
}

export default BaseModal;

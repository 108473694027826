import * as React from 'react';
import {
    Button,
    Tooltip,
    Popconfirm,
    PopconfirmProps,
} from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';

type Props = {
    popconfirmProps?: PopconfirmProps
    tooltipProps?: TooltipPropsWithTitle
} & ButtonProps

const CircleButton: React.VFC<Props> = props => {
    const {
        className,
        disabled,
        icon,
        popconfirmProps,
        size,
        tooltipProps,
        ...restButtonProps
    } = props;

    const title = popconfirmProps?.title ? (
        <div style={{ maxWidth: '80vw' }}>{popconfirmProps.title}</div>
    ) :
        undefined;

    return (
        <CustomTooltip
            title={''} // tooltipProps が undefined のときの型エラー回避用
            {...tooltipProps}
            isDisplay={!!tooltipProps}
        >
            {!popconfirmProps ? (
                <Button
                    {...restButtonProps}
                    className={className}
                    disabled={disabled}
                    icon={icon}
                    shape='circle'
                    size={size}
                />) : (
                <Popconfirm
                    {...popconfirmProps}
                    title={title}
                    disabled={disabled}
                >
                    <Button
                        {...restButtonProps}
                        className={className}
                        disabled={disabled}
                        icon={icon}
                        shape='circle'
                        size={size}
                    />
                </Popconfirm>
            )}
        </CustomTooltip>
    );
}

const CustomTooltip: React.FC<TooltipPropsWithTitle & {isDisplay: boolean}> = ({children, isDisplay, ...props}) => {
    return isDisplay ? <Tooltip {...props}>{children}</Tooltip> : <>{children}</>;
}

export default CircleButton;

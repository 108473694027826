import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SelectOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { BaseTable } from 'components/modules/BaseTable';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import { generateLtiLaunchDomain, getCurrentTerm } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import { examCategoryTabFlex, TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import { usePagination } from '../../../hooks/usePagination';
import { ExamCategoryStatusSearch } from '../ExamCategoryStatusSearch';
import ExamStatisticsPopover from '../ExamStatisticsPopover';
import { ScoringStatusTag } from '../ScoringStatusTag';

import type {
    ResultExamCategoryState,
    RequestResultExamCategory,
} from '../../../hooks/useResultExamCategory';

import 'styles/modules/test_status_modal.scss';
import { createColumns } from './createColumns';

type Exams = NonNullable<ResultExamCategoryState['result']>['exams'];
export type ExamWithHistory = Extract<Exams[number], { resultHistory: unknown }>;
export type DataSource = Extract<NonNullable<ResultExamCategoryState['result']>, { usersList: unknown }>['usersList'][number];
type DownloadCsvParameter = Http.Connection.Request.Parameter.ExamCategory.DownloadCsv;

type SearchValues = Expand<
    DownloadCsvParameter & {
        latest_result_only: boolean
    }
>

type Props = {
    breadCrumb: ExamCategoryTabState.BreadCrumb
    examCategoryStatus: ResultExamCategoryState
    isStatusOpen: boolean
    onClickUserName: (examUuid: string, studentUuid: string, tabName?: '' | 'board' | 'school' | undefined) => void
    onClickTestName: (exam: ExamWithHistory) => void
    record: ExamCategory | undefined
    requestResult: RequestResultExamCategory
    tabName: 'board' | 'school' | undefined
}

export const StudentList: React.VFC<Props> = ({
    breadCrumb,
    examCategoryStatus: { isLoading, result },
    isStatusOpen,
    onClickUserName,
    onClickTestName,
    record,
    requestResult,
    tabName,
}) => {
    const dispatch = useDispatch();
    const disabledCsvExport = useAppSelector(examCategoryTabFlex.selectors.disabledCsvExport);
    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const { termList } = useAppSelector(selectors.globalState);
    const defaultSearchRule = {
        latest_result_only: false,
        term_uuid: getCurrentTerm(termList).uuid,
    };
    const defaultPageSize = 50;
    const requestType = tabName === TAB_NAMES.SCHOOL ? 'default' : 'control';
    const dataSource = (result && 'usersList' in result) ? result.usersList : [];
    const isShowList = breadCrumb.type === 'List';
    const [search, setSearch] = useState<SearchValues>(defaultSearchRule);
    const [latestResultOnly, setlatestResultOnly] = useState(false);
    const [pagination, setPagination, resetPagination] = usePagination({
        current: 1,
        onChange: (page, pageSize) => {
            setPagination(pagination => ({ ...pagination, current: page, pageSize }));
            requestResult(
                record?.uuid ?? '',
                {
                    ...search,
                    page,
                    page_size: pageSize,
                },
                requestType,
            );
        },
        pageSize: defaultPageSize,
    });

    useEffect(() => {// termList取得時の初期値セット
        setSearch(search => ({
            ...search,
            term_uuid: getCurrentTerm(termList).uuid,
        }));
    }, [termList]);

    useEffect(() => {// 表示時初回検索
        if (!isStatusOpen) {// 閉じた時は検索条件を初期値に戻す
            setSearch(defaultSearchRule);
            return;
        };
        if (!record?.uuid) return;

        resetPagination();
        requestResult(
            record?.uuid,
            {
                ...defaultSearchRule,
                page_size: defaultPageSize,
            },
            requestType,
        );
    }, [isStatusOpen, record?.uuid]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 検索処理後のページネーションの更新
        if (isLoading) return;

        setPagination(pagination => ({
            ...pagination,
            onChange: (page, pageSize) => {
                setPagination(pagination => ({ ...pagination, current: page, pageSize }));
                requestResult(
                    record?.uuid ?? '',
                    {
                        ...search,
                        page,
                        page_size: pageSize,
                    },
                    requestType,
                );
            },
            total: result && 'usersListItemCount' in result ?
                result.usersListItemCount :
                0,
        }));
    }, [setPagination, requestResult, isLoading, record?.uuid, result, search, requestType]);

    const handleClickUserName = (uuid: string) => {
        onClickUserName(
            record?.uuid ?? '',
            uuid,
            tabName,
        );
    };

    const columns: ColumnsType<DataSource> = createColumns(
        handleClickUserName,
        result,
        generateChildren(
            isControllableUser,
            latestResultOnly,
            tabName,
            result,
            onClickTestName
        )
    );

    const calculateScrollWidth = () => {
        return 550 + (100 * (result ? result.exams.length : 0));
    };


    // 検索結果をCSVでダウンロードさせる
    const handleCsvDownload = (encoding: string) => {
        if (!record?.uuid) return;

        if (tabName === TAB_NAMES.BOARD_OF_EDUCATION) {
            dispatch(Actions.http.connection.tao.examCategory.controlDownloadCsv(
                record.uuid,
                encoding,
                search
            ));
        } else {
            dispatch(Actions.http.connection.tao.examCategory.downloadCsv(
                record.uuid,
                encoding,
                search
            ));
        }
    };

    const onFinish = (values: SearchValues) => {
        if (!record?.uuid) return;

        const { latest_result_only, ...restSearch } = values;
        requestResult(
            record?.uuid,
            {
                ...restSearch,
                latest_result_only,
                page_size: pagination.pageSize,
            },
            requestType,
        );
        setPagination(pagination => ({ ...pagination, current: 1 }));
        setlatestResultOnly(latest_result_only);
        setSearch((search) => ({ ...search, ...values }));
    };

    if (!isStatusOpen) return null; // モーダルが閉じている時はレンダリングしない

    return (
        <div>
            <ExamCategoryStatusSearch
                onFinish={onFinish}
                showList={isShowList}
                tabName={tabName}
            />

            {isShowList && (
                <>
                    {!isControllableUser && (
                        <div className='flex-right-container gutter-bottom my-4'>
                            <DownloadCSVConfirm
                                disabled={isLoading || disabledCsvExport}
                                disabledMessage={<p>CSVエクスポートはすでに実行済みです。<br />CSVのダウンロード状況については、画面左下からご確認ください</p>}
                                handleOk={(encoding) => handleCsvDownload(encoding)}
                                loading={isLoading}
                            />
                        </div>
                    )}

                    <BaseTable
                        className='all-white-table'
                        columns={columns}
                        dataSource={dataSource}
                        emptyDescription='対象のテスト結果が存在しません'
                        loading={isLoading}
                        pagination={pagination}
                        scroll={{ x: calculateScrollWidth() }}
                        size='middle'
                    />
                </>
            )}
        </div>
    );
};

const createTextOfLatestExamResult = (exam: DataSource['exams'][number]) => {
    // result_historyの0が最新なのでそれを表示
    const result_history = exam.resultHistory[0];
    return result_history ? `${result_history.score} / ${result_history.maxScore}` : '―';
};

/**
 * exams が学校管理者用かを確認する
 */
const isControlResultExam = (isControllableUser: boolean, exams: Exams): exams is Exam.ControlResult[] => {
    return isControllableUser;
};

export const generateChildren = (
    isControllableUser: boolean,
    latestResultOnly: boolean,
    tabName: 'board' | 'school' | undefined,
    result: ResultExamCategoryState['result'],
    handleClickTestName: (exams: ExamWithHistory) => void,
): ColumnsType<DataSource> => {
    if (result === undefined) return [];

    // このコンポーネントは学校管理者用であり、学校管理者であれば必ず usersList が含まれる
    if (!('usersList' in result)) return [];

    if (result.usersList.length === 0) return [];
    const exams = result.exams;
    if (isControlResultExam(isControllableUser, exams)) return [];

    return exams.map((value, index) => ({
        className: 'test-result-column',
        key: index,
        render: (_, rowRecord) => {
            const resultType = isControllableUser ?
                'exam-result' :
                tabName === 'school' ?
                    'exam-result' :
                    'control-exam-result';
            const href = `${generateLtiLaunchDomain(isControllableUser, true)}/${resultType}/${rowRecord.exams[index].uuid}/${rowRecord.uuid}`;

            return (
                <div>
                    <span style={{ marginRight: '1rem' }}>
                        {createTextOfLatestExamResult(rowRecord.exams[index])}
                    </span>

                    {rowRecord.exams[index]?.resultUrl && rowRecord.exams[index]?.resultHistory?.length > 0 && (
                        <a
                            href={href}
                            rel='noreferrer'
                            style={{ marginRight: '1rem' }}
                            target='_blank'
                        >
                            <SelectOutlined />
                        </a>
                    )}

                    <ScoringStatusTag
                        status={rowRecord.exams[index]?.resultHistory ?
                            rowRecord.exams[index].resultHistory[0]?.gradingProgress :
                            undefined
                        }
                    />
                </div>
            );
        },
        title: (
            <>
                <Tooltip title={value.title} >
                    <button
                        className='test-result-button text-button'
                        onClick={() => handleClickTestName(value)}
                        style={{ marginRight: '0.5rem' }}
                    >
                        {value.title}
                    </button>
                </Tooltip>

                <ExamStatisticsPopover
                    latestResultOnly={latestResultOnly}
                    tabName={tabName}
                    title={value.title}
                    uuid={value.uuid}
                />
            </>
        ),
        width: 250,
    }));
};
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance, Popover, Table, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import * as React from 'react';
import { items as Default} from './PredictedCandidates/Default.json';
import { items as LoginHint} from './PredictedCandidates/LoginHint.json';

export const PredictedCandidates = {
    Default,
    LoginHint,
}

type Props = {
    form: FormInstance
    fieldName: string[]
    options: TableRow[]
}

type TableRow = {
    value: string
    content: string
}

/***
 * アプリテンプレートのパラメータの予測候補の入力をおこなう。
 */
export const PredictionParamsPopover: React.VFC<Props> = (props) => {
    const { form, fieldName, options } = props;
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLSpanElement>(null);

    const handleClickRow = (record: TableRow) => {
        const currentValue = form.getFieldValue(fieldName) || '';
        form.setFields([{
            name: fieldName,
            value: `${currentValue}{{${record.value}}}`,
        }]);
        setVisible(false);
    };

    return (
        <Popover
            trigger='click'
            getPopupContainer={() => ref.current as HTMLElement}
            onVisibleChange={setVisible}
            visible={visible}
            content={
                <Table<TableRow>
                    dataSource={options}
                    columns={[
                        { title: '値', dataIndex: 'value', key: 'value' },
                        { title: '内容', dataIndex: 'content', key: 'content' }
                    ]}
                    scroll={{ y: 300 }}
                    size='small'
                    rowKey='value'
                    onRow={(record) => ({ onClick: () => handleClickRow(record) })}
                    pagination={false}
                    className='prediction-params-table'
                />
            }
        >
            <Tooltip title='こちらから予測候補を入力できます'>
                <QuestionCircleOutlined ref={ref} />
            </Tooltip>
        </Popover>
    );
};
import { combineActions, handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = ({
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
});

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (_: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (_: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const usersSearchConnection = handleAction(
    Actions.http.connection.users.search,
    defaultReducer,
    defaultState
);

export const usersEditConnection = handleAction(
    combineActions(
        Actions.http.connection.users.create,
        Actions.http.connection.users.delete,
        Actions.http.connection.users.unsetPlannedToDelete,
        Actions.http.connection.users.update,
    ),
    defaultReducer,
    defaultState
);

export const usersImportConnection = handleAction(
    Actions.http.connection.users.import,
    defaultReducer,
    defaultState
);

export const usersExportConnection = handleAction(
    Actions.http.connection.users.export,
    defaultReducer,
    defaultState
);

export const usersDeleteFromCsvConnection = handleAction(
    Actions.http.connection.users.deleteFromCsv,
    defaultReducer,
    defaultState
);

export const usersSyncPasswordConnection = handleAction(
    Actions.http.connection.users.syncPassword,
    defaultReducer,
    defaultState
);

export const usersUpdateRenewClassConnection = handleAction(
    Actions.http.connection.users.updateRenewClass,
    defaultReducer,
    defaultState
);

export const usersMultipleUpdateFlagConnection = handleAction(
    Actions.http.connection.users.multipleUpdateFlag,
    defaultReducer,
    defaultState
);

export const usersAccountUnlockConnection = handleAction(
    Actions.http.connection.users.accountUnlock,
    defaultReducer,
    defaultState
);

export const usersViewConnection = handleAction(
    Actions.http.connection.users.view,
    defaultReducer,
    defaultState
);

export const usersDownloadSimpleCsvConnection = handleAction(
    Actions.http.connection.users.downloadSimpleCsv,
    defaultReducer,
    defaultState
);

export const unsetPlannedToDeleteConnection = handleAction(
    Actions.http.connection.users.unsetPlannedToDelete,
    defaultReducer,
    defaultState
);
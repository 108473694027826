import { createContext, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { message } from 'antd';

import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import { useJobReloadEffect } from 'components/modules/Job/useJobReloadEffect';
import * as Actions from 'flex/Actions';

import AnnualUpdateModal from '../Common/AnnualUpdateModal';
import AnnualUpdateCard from './AnnualUpdateCard';
import AnnualUpdateCsvOperators from './AnnualUpdateCsvOperators';
import ClassesListTable from './ClassesListTable';
import Search from './Search';


const INITIAL_SEARCH_RULE = {
    current_page: 1,
    organization_uuid: '',
    page_size: 50,
    term_uuid: '',
};
const RELOAD_BACKGROUND_JOB_TYPES = [BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT];

export const SearchRuleContext = createContext([INITIAL_SEARCH_RULE], () => { });

const ControlContents = () => {
    const [isAnnualUpdateOpen, setIsAnnualUpdateOpen] = useState(false);
    const [search, setSearch] = useState(INITIAL_SEARCH_RULE);
    const dispatch = useDispatch();

    useJobReloadEffect(
        RELOAD_BACKGROUND_JOB_TYPES,
        () => {
            handleSearch({ current_page: 1 });
        },
    );

    const handleAnnualUpdateCancel = () => setIsAnnualUpdateOpen(false);
    const handleAnnualUpdateOpen = () => setIsAnnualUpdateOpen(true);
    const handleAnnualUpdateSubmit = () => {
        setIsAnnualUpdateOpen(false);
        message.success('クラス変更フラグの更新に成功しました。');
    };

    const handleSearch = useCallback((newSearchRule = INITIAL_SEARCH_RULE) => {
        const { current_page: currentPage, ...rest } = newSearchRule;
        const { current_page: preCurrentPage, ...preRest } = search;
        setSearch(search => ({ ...search, ...newSearchRule }));
        dispatch(Actions.http.connection.classes.search({ ...preRest, ...rest }, currentPage ? currentPage : 1));
    }, [dispatch, search]);

    return (
        <div className='classs-list container'>
            <SearchRuleContext.Provider value={[search, setSearch]}>
                <AnnualUpdateCard />
                <Search onSearch={handleSearch} />
                <AnnualUpdateCsvOperators
                    onClickAnnualUpdate={handleAnnualUpdateOpen}
                    onSearch={handleSearch}
                />
                <ClassesListTable onSearch={handleSearch} />
                <AnnualUpdateModal
                    isUserType={false}
                    onCancel={handleAnnualUpdateCancel}
                    onOk={handleAnnualUpdateSubmit}
                    visible={isAnnualUpdateOpen}
                />
            </SearchRuleContext.Provider>
        </div>
    );
};

export default ControlContents;

import { useEffect, useState } from 'react';
import {
    Badge,
    Col,
    Dropdown,
    Menu,
    message,
    Row,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { BaseTable, BaseTableProps } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import {
    getMoment,
    PageSizeOptions,
    generateUserDomain,
} from 'constants/GlobalConfig';
import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    FileAddOutlined,
    LineChartOutlined,
} from '@ant-design/icons';

const moment = getMoment();

type Props<T extends EverydayNote.Questionnaire> = Omit<
    BaseTableProps<T>,
    'emptyDescription' | 'pagination'
> & {
    currentPage: number
    currentTab?: 'school' | 'board'
    onAnswerResultClick: (questionnaire: T) => void
    onCopyClick?: (questionnaire: T) => void
    onDeleteClick?: (uuid: string) => void
    onEditClick?: (questionnaire: T) => void
    onPageChange: (page: number, pageSize: number) => void
    total: number
    initialPagination: Partial<TablePaginationConfig>
    isControllableUser: boolean
};

export const QuestionnaireTable = <T extends EverydayNote.Questionnaire>(props: Props<T>) => {
    const {
        currentPage,
        currentTab,
        onAnswerResultClick,
        onCopyClick,
        onDeleteClick,
        onEditClick,
        onPageChange,
        total,
        initialPagination,
        isControllableUser,
    } = props;
    const [hasTouchScreen, setHasTouchScreen] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: pageSize,
            }));
            onPageChange(page, pageSize);
        },
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return `全${total}件中 ${range[0]}-${range[1]} 件`;
        },
        total,
        ...initialPagination,
    });

    useEffect(() => {
        if ('ontouchstart' in window && navigator.maxTouchPoints > 0) {
            setHasTouchScreen(true);
        } else {
            setHasTouchScreen(false);
        }
    }, []);

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            current: currentPage,
            total,
            onChange: (page, pageSize) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize: pageSize,
                }));
                onPageChange(page, pageSize);
            },
        }));
    }, [currentPage, total, onPageChange]);

    const columns: ColumnsType<T> = [
        {
            title: 'タイトル',
            width: 15,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '発信元',
            width: 15,
            dataIndex: 'publishFrom',
            key: 'publishFrom',
        },
        {
            title: 'ホーム画面への表示',
            width: 15,
            dataIndex: 'isViewedHome',
            key: 'isViewedHome',
            render: (isViewedHome: T['isViewedHome']) => {
                return isViewedHome ?
                    <Badge status='success' text='表示' /> :
                    <Badge status='warning' text='非表示' /> ;
            },
        },
        {
            title: '日毎の複数回答',
            width: 12,
            dataIndex: 'isOnceADay',
            key: 'isOnceADay',
            render: (isOnceADay: T['isOnceADay']) => {
                return isOnceADay ?
                    <Badge status='warning' text='許容しない' /> :
                    <Badge status='success' text='許容する' />;
            },
        },
        {
            title: '状態',
            width: 10,
            dataIndex: 'statusPeriod',
            key: 'statusPeriod',
            render: (statusPeriod: T['statusPeriod']) => {
                switch (statusPeriod) {
                    case 'disable':
                    case 'public_after':
                    case 'public_before': {
                        return <Badge status='default' text='記録停止中' />;
                    }
                    case 'public': {
                        return <Badge status='success' text='記録中' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            },
        },
        {
            title: '実施期間',
            width: 15,
            dataIndex: 'durationPublish',
            key: 'durationPublish',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.publishStartAt).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(record.publishEndAt).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
        },
        {
            title: '回答状態',
            width: 10,
            dataIndex: 'isLocked',
            key: 'isLocked',
            render: (isLocked: EverydayNote.ControlQuestionnaire['isLocked']) => {
                return isLocked ?
                    <Badge status='processing' text='回答あり' /> :
                    <Badge status='default' text='回答なし' />;
            },
        },
        {
            title: '作成者/更新者',
            width: 15,
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => (
                <>
                    <span>{record.createdBy.displayName}</span>
                    <br />
                    <span>{record.updatedBy.displayName}</span>
                </>
            ),
        },
        {
            title: '作成時間/更新時間',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 15,
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.createdAt).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updatedAt).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 12,
            fixed: 'right',
            render: (_, record) => (
                <Row
                    align='middle'
                    justify='space-around'
                    gutter={10}
                >
                    <Col span={4}>
                        <CircleButton
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '編集' }}
                            icon={<EditOutlined />}
                            disabled={onEditClick === undefined || (!isControllableUser && ('canAdminSetPublic' in record && !record.canAdminSetPublic))}
                            onClick={() => onEditClick && onEditClick(record)}
                        />
                    </Col>
                    <Col span={4}>
                        <CircleButton
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : 'コピーして作成' }}
                            icon={<FileAddOutlined />}
                            disabled={onCopyClick === undefined}
                            onClick={() => onCopyClick && onCopyClick(record)}
                        />
                    </Col>
                    <Col span={4}>
                        <CircleButton
                            size='small'
                            icon={
                                <Dropdown
                                    arrow
                                    overlay={(
                                        <Menu>
                                            <Menu.Item onClick={ genHandleClickNewQuestionnaire(record.uuid, isControllableUser, currentTab) }>
                                                新しい記録 URL のコピー
                                            </Menu.Item>
                                            <Menu.Item onClick={ genHandleClickEveryDayQuestionnaire(record.uuid, isControllableUser, currentTab)}>
                                                毎日の記録 URL のコピー
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                    placement="top"
                                    trigger={['click']}
                                >
                                    <CopyOutlined />
                                </Dropdown>
                            }
                        />
                    </Col>
                    <Col span={4}>
                        <CircleButton
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '回答結果の確認' }}
                            icon={<LineChartOutlined />}
                            onClick={() => onAnswerResultClick(record)}
                        />
                    </Col>
                    <Col span={4}>
                        <CircleButton
                            tooltipProps={{ title: hasTouchScreen ? undefined : '削除' }}
                            popconfirmProps={{
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onConfirm: () => onDeleteClick && onDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>毎日の記録の回答結果も全て削除されます。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>
                                            削除すると復元することはできません。削除してもよろしいですか？
                                        </span>
                                    </>
                                ),
                            }}
                            disabled={onDeleteClick === undefined}
                            size='small'
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <BaseTable<T>
            {...props}
            emptyDescription='毎日の記録はありません'
            columns={columns}
            scroll={{ x: 1500 }}
            rowKey='uuid'
            pagination={pagination}
        />
    );
};

const genHandleClickNewQuestionnaire = (uuid: string, isControllableUser: boolean, currentTab?: 'board' | 'school') => () => {
    const divisionCode = isControllableUser ?
        'c' :
        currentTab === 'board' ? 'c' :
            'a';

    navigator.clipboard
        .writeText(`${generateUserDomain()}/everyday-note/direct-answer/${divisionCode}/${uuid}`)
        .then(
            () => { message.success('URL をコピーしました。'); },
            () => { message.error('URL のコピーに失敗しました。'); }
        );
};

const genHandleClickEveryDayQuestionnaire = (uuid: string, isControllableUser: boolean, currentTab?: 'board' | 'school') => () => {
    const divisionCode = isControllableUser ?
        'c' :
        currentTab === 'board' ? 'c' :
            'a';

    navigator.clipboard
        .writeText(`${generateUserDomain()}/everyday-note/${divisionCode}/${uuid}`)
        .then(
            () => { message.success('URL をコピーしました。'); },
            () => { message.error('URL のコピーに失敗しました。'); }
        );
};

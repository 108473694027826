import { FieldTimeOutlined, TeamOutlined } from '@ant-design/icons';
import { Avatar, Badge, Card, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { getMoment } from 'constants/GlobalConfig';

import { BackgroundJobStatuses } from '../BackgroundJobUtils';
import { BackgroundJobCardBody } from './BackgroundJobCardBody';

const moment = getMoment();


const statusElement = (status) => {
    switch (status) {
        case BackgroundJobStatuses.done: return (<Badge status='success' text='完了' />);
        case BackgroundJobStatuses.sync: return (<Badge status='warning' text='進行中' />);
        case BackgroundJobStatuses.wait: return (<Badge status='default' text='待機中' />);
        case BackgroundJobStatuses.error: return (<Badge status='error' text='エラー' />);
        default: return (<Badge status='error' text='エラー' />);
    }
};

/** BackgroundJobの情報を示すカード  */
export const BackgroundJobCard = ({
    buttons,
    created_at,
    done_at,
    icon,
    message,
    status,
    title,
    updated_at,
}) => {
    const startAtString = created_at ? moment.unix(created_at).format('YYYY/M/D HH:mm') : '';
    const finishAtString = done_at ? moment.unix(done_at).format('YYYY/M/D HH:mm') : '';

    return (
        <Card
            bordered={true}
            className='background_task_card'
            title={(
                <Space size={15}>
                    <Avatar icon={icon ? icon : <TeamOutlined />} size={44} style={{ backgroundColor: '#004C97' }} />
                    <div>
                        <h4><b>{title}</b></h4>
                        <p className='job-date'>日時: {startAtString} ~ {finishAtString}</p>
                        <p>
                            状態: {statusElement(status)}
                            <Tooltip title={<span>最終確認時刻:<br /> {moment.unix(updated_at).format('YYYY/MM/DD HH:mm:ss')}</span>}>
                                <FieldTimeOutlined style={{ margin: '6px 0 0 4px' }} />
                            </Tooltip>
                        </p>
                    </div>
                </Space>
            )}
        >
            <BackgroundJobCardBody buttons={buttons} message={message} />
        </Card>
    );
};

BackgroundJobCard.protoType = {
    buttons: PropTypes.arrayOf(PropTypes.object),
    created_at: PropTypes.number.isRequired,
    done_at: PropTypes.number,
    icon: PropTypes.object,
    message: PropTypes.object,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updated_at: PropTypes.number.isRequired,
};

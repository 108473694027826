import { forwardRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export const StarMarkCandidate = forwardRef(({ name, watch, evaluationScaleMax, disabled }, ref) => {
    const checkedIconValue = watch(name);

    const iconClassNames = (checkNum, checkedIconValue) => {
        //FontAwesomeによって無理やりwidthが指定されてしまうためfontsizeでサイズを変えている
        const fillIconClass = 'text-star-sm h-10 lg:text-star-md lg:h-12 text-lg-yellow';
        const wireIconClass = 'text-star-sm h-10 lg:text-star-md lg:h-12 text-gray-300';
        const baseClass = 'first:fill-current hover:cursor-pointer';

        return (
            classNames(
                `${checkedIconValue >= checkNum
                    ? fillIconClass
                    : wireIconClass
                }`,
                baseClass
            )
        );
    };

    return (
        <div className='flex h-16 items-center'>
            {[...Array(evaluationScaleMax)].map((_, index) => {
                const scaleNum = index + 1;
                return (
                    <label key={`name-${scaleNum}`} className='mx-auto'>
                        <input name={name} className='hidden' type='radio' value={scaleNum} ref={ref} disabled={disabled} />
                        <div className='pr-2 flex flex-col'>
                            <div className='mx-auto font-bold text'>{scaleNum}</div>
                            <FontAwesomeIcon icon={faStar} className={iconClassNames(scaleNum, checkedIconValue)} />
                        </div>
                    </label>
                );
            })
            }
        </div>
    );
});

StarMarkCandidate.displayName = 'StarMarkCandidate';

import { createContext, useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { useJobEffect } from 'constants/CustomHooks/useJobEffect';
import { BackgroundJobStatuses, dispatchNextSyncAction, getJobStatus } from './BackgroundJobUtils';
const moment = getMoment();

// learning-gateway-backend の
// src\models\infrastructures\JobState.php を参照.
export const BACKGROUND_JOB_TYPE = {
    USER_CSV_IMPORT: '1',
    USER_CSV_EXPORT: '2',
    SYNC_GROUP: '3',
    SYNC_USER: '4',
    SYNC_MEMBER: '5',
    SYNC_GROUP_DRYRUN: '6',
    SYNC_USER_DRYRUN: '7',
    SYNC_MEMBER_DRYRUN: '8',
    LOG_TOTAL_CSV_EXPORT: '9',
    LOG_DETAIL_CSV_EXPORT: '10', // 使われていない
    QUESTIONNAIRE_LIST_CSV_EXPORT: '11', // 使われていない
    QUESTIONNAIRE_CSV_EXPORT: '12',
    SCHOOL_CLASS_CSV_IMPORT: '13',
    SCHOOL_CLASS_CSV_EXPORT: '14',
    SYNC_DELETED_USER: '15',
    SYNC_DELETED_USER_DRYRUN: '16',
    DELETE_USER_FROM_CSV_IMPORT: '17',
    ANSWER_TEXT_RESULT_CSV_EXPORT: '18', //使われていない
    ANSWER_FILE_RESULT_CSV_EXPORT: '19', //使われていない
    ANSWER_USER_RESULT_CSV_EXPORT: '20',
    STUDENT_ANSWER_TEXT_RESULT_CSV_EXPORT: '21', // 使われていない
    STUDENT_ANSWER_FILE_RESULT_CSV_EXPORT: '22',
    STUDENT_ANSWER_USER_RESULT_CSV_EXPORT: '23',
    ORGANIZATION_CSV_IMPORT: '24',
    EXAM_RESULT_CSV_EXPORT: '25',
    CONTROL_EXAM_RESULT_CSV_EXPORT: '26',
    CONTROL_QUESTIONNAIRE_LIST_CSV_EXPORT: '27',
    CONTROL_QUESTIONNAIRE_CSV_EXPORT: '28',
    CONTROL_USER_RESULT_CSV_EXPORT: '29',
    CONTROL_ANSWER_FILE_RESULT_CSV_EXPORT: '30',
    CONTROL_ANSWER_TEXT_RESULT_CSV_EXPORT: '31',
    CONTROL_EVERYDAY_NOTE_RESULT_CSV_EXPORT: '32',
    EVERYDAY_NOTE_RESULT_CSV_EXPORT: '33',
    SAVE_EXAM_REPORT: '34',
};

/**
 * バックグランドで処理する必要のない Job のタイプ
*/
const NOT_REQUIRE_PROCESSING_TYPES = [
    BACKGROUND_JOB_TYPE.LOG_DETAIL_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.QUESTIONNAIRE_LIST_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.ANSWER_TEXT_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.ANSWER_FILE_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.STUDENT_ANSWER_TEXT_RESULT_CSV_EXPORT,
];

const CSV_IMPORT_TYPES = [
    BACKGROUND_JOB_TYPE.USER_CSV_IMPORT,
    BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT,
    BACKGROUND_JOB_TYPE.ORGANIZATION_CSV_IMPORT,
    BACKGROUND_JOB_TYPE.DELETE_USER_FROM_CSV_IMPORT,
];

const CSV_EXPORT_TYPES = [
    BACKGROUND_JOB_TYPE.USER_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.LOG_TOTAL_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.LOG_DETAIL_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.QUESTIONNAIRE_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.QUESTIONNAIRE_LIST_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.ANSWER_TEXT_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.ANSWER_FILE_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.ANSWER_USER_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.STUDENT_ANSWER_TEXT_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.STUDENT_ANSWER_FILE_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.STUDENT_ANSWER_USER_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.EXAM_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_EXAM_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_QUESTIONNAIRE_LIST_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_QUESTIONNAIRE_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_USER_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_ANSWER_FILE_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_ANSWER_TEXT_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.CONTROL_EVERYDAY_NOTE_RESULT_CSV_EXPORT,
    BACKGROUND_JOB_TYPE.EVERYDAY_NOTE_RESULT_CSV_EXPORT,
];

const SAVE_TYPES = [
    BACKGROUND_JOB_TYPE.SAVE_EXAM_REPORT,
];

const SYNC_TYPES = [
    BACKGROUND_JOB_TYPE.SYNC_GROUP,
    BACKGROUND_JOB_TYPE.SYNC_USER,
    BACKGROUND_JOB_TYPE.SYNC_MEMBER,
    BACKGROUND_JOB_TYPE.SYNC_DELETED_USER,
];

const SYNC_DRY_RUN_TYPES = [
    BACKGROUND_JOB_TYPE.SYNC_GROUP_DRYRUN,
    BACKGROUND_JOB_TYPE.SYNC_USER_DRYRUN,
    BACKGROUND_JOB_TYPE.SYNC_MEMBER_DRYRUN,
    BACKGROUND_JOB_TYPE.SYNC_DELETED_USER_DRYRUN,
];

export const BACKGROUND_JOB_TYPE_GROUPS = {
    CSV_IMPORT_TYPES,
    CSV_EXPORT_TYPES,
    SAVE_TYPES,
    SYNC_TYPES,
    SYNC_DRY_RUN_TYPES,
};

// バックグラウンドで処理すべき Type の job の配列
const BACKGROUND_JOB_TYPE_ARR = Object.values(BACKGROUND_JOB_TYPE)
    .filter(type => !NOT_REQUIRE_PROCESSING_TYPES.includes(type));

/** バックグラウンドで処理すべきTypeのjobかどうか  */
const isBackgroundJobType = (type) => {
    return BACKGROUND_JOB_TYPE_ARR.includes(type);
};

export const BACKGROUND_JOB_FETCH_INTERVAL_TIME = 15000; // [ms]
export const BACKGROUND_JOB_RE_FETCH_IN_NO_RESPONSE_INTERVAL_TIME = 60000; // [ms]
export const BACKGROUND_JOB_CANCELABLE_TIME = 60 * 60 * 1000; // [ms]

/** JobInfoMapを提供する
 *  jobInfoMap: {jobUuid: {job:jobResult, isError:boolean,  gmt:number}
 *  removeJobInfo: (jobUuid) => {}
 *  setLoadingJobInfo: (jobUuid) => {} // ジョブメニュー上で、次のジョブに移動する前に、二回クリックされないように対策
 * */
const BackgroundJobInfoContext = createContext([
    /**** @type {{string: JobInfo}} */ {},
    (jobUuid) => { }, // removeJobInfo
    (jobUuid) => { }  // setLoadingJobInfo
]);

export const useBackgroundJobInfoSource = () => useContext(BackgroundJobInfoContext);

/**
 * バックグラウンドで処理すべきTypeのjobを提供・処理をする
 *
 * ブラウザに保存して読み出しもする
 * */

export const BackgroundJobProvider = ({ children }) => {

    /** shouldFetchJobUuidsにジョブuuidが追加されると、そのジョブのリクエストを行う。setTimeoutで、未来で変更される  */
    const [shouldFetchJobUuids, setShouldFetchJobUuids] = useState([]);
    /** fetchReservationJobUuidsにジョブuuidがついかされていると、そのuuidのジョブは 新たにshouldFetchJobUuidsをしない */
    const [fetchReservationJobUuids, setFetchReservationJobUuids] = useState([]);
    /** リクエスト要求が失敗して、レスポンスがないjobInfoのために定期的に再取得確認を行うためのカウント  */
    const [shouldIntervalReFetchCount, setShouldIntervalReFetchCount] = useState(0);
    const dispatch = useDispatch();
    const { payload: { features } } = useAppSelector(state => state.tenant);
    const { payload: user } = useAppSelector(state => state.user);
    /*** @type {Map<string, JobInfo>}} */
    const jobInfoMap = useSelector(state => state.jobInfoMap);

    const isFirstRender = useRef(true);
    const intervalReFetch = useRef(null);

    const localStorageKey = `${user.belongs[0].school_class.organization.uuid}-${user.uuid}`;

    const defaultSaveCondision = useCallback((connection) => (
        !isFirstRender.current && !connection.meta.fetch && !!connection.payload.result
    ), []);

    useJobEffect(
        state => state.jobViewConnection,
        (connection) => (
            // バックグラウンドで処理すべきジョブのみ、保存する
            defaultSaveCondision(connection) && isBackgroundJobType(connection.payload?.result?.type)
        ),
        (jobResult, connection) => {
            if (jobResult.type !== BACKGROUND_JOB_TYPE.SAVE_EXAM_REPORT) return jobResult;

            const jobInfo = jobInfoMap[jobResult.uuid];

            if (jobInfo === undefined) return jobResult;

            const originMessage = jobInfo.job.message;
            const { is_success, message, result } = connection.payload.result;

            const connectionMessage = message ?? '';
            const newMessage = originMessage ?
                originMessage.includes('\n\n') ?
                    `${originMessage.split('\n\n')[0]}\n\n${connectionMessage}` :
                    `${originMessage}\n\n${connectionMessage}` :
                connectionMessage;

            const hasError = Object.values(result ?? {}).flatMap(e => e).length > 0;
            const cause = !is_success && hasError ?
                'MEXCBTのスタディログが見つかりません。' +
                'テストを受検した当日中にレポートを生成した場合は、翌日以降に再度お試しください。' +
                'また、テストによりスタディログを生成しないように設定されている場合もあります。' :
                '';

            return {
                ...jobResult,
                message: `${newMessage}${cause === '' ? '' : '\n'}${cause}`,
            };
        }
    );
    useJobEffect(state => state.dryRunGroupConnection, defaultSaveCondision);
    useJobEffect(state => state.executeGroupConnection, defaultSaveCondision);
    useJobEffect(state => state.dryRunUserConnection, defaultSaveCondision);
    useJobEffect(state => state.executeUserConnection, defaultSaveCondision);
    useJobEffect(state => state.dryRunGroupMemberConnection, defaultSaveCondision);
    useJobEffect(state => state.executeGroupMemberConnection, defaultSaveCondision);
    useJobEffect(state => state.dryRunDeleteUserConnection, defaultSaveCondision);
    useJobEffect(state => state.executeDeleteUserConnection, defaultSaveCondision);
    useJobEffect(state => state.usersImportConnection, defaultSaveCondision);
    useJobEffect(state => state.usersDeleteFromCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.classImportConnection, defaultSaveCondision);
    useJobEffect(state => state.usersExportConnection, defaultSaveCondision);
    useJobEffect(state => state.classExportConnection, defaultSaveCondision);
    useJobEffect(state => state.organizationImportConnection, defaultSaveCondision);
    useJobEffect(state => state.questionnaireExportConnection, defaultSaveCondision);
    useJobEffect(state => state.usageExportTotalConnection, defaultSaveCondision);
    useJobEffect(state => state.taoExamCategoryDownloadCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.taoExamCategoryControlDownloadCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.everydayNoteResultCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.controlEverydayNoteResultCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.adminEverydayNoteControlResultCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.adminEverydayNoteResultCsvConnection, defaultSaveCondision);
    useJobEffect(state => state.usersDownloadSimpleCsvConnection, defaultSaveCondision);
    useJobEffect(
        state => state.taoExamSaveReportConnection,
        defaultSaveCondision,
        taoExamSaveReportMessageConverter
    );
    useJobEffect(
        state => state.taoExamControlSaveReportConnection,
        defaultSaveCondision,
        taoExamSaveReportMessageConverter
    );
    useJobEffect(
        state => state.taoExamCategorySaveReportConnection,
        defaultSaveCondision,
        taoExamCategorySaveReportMessageConverter
    );
    useJobEffect(
        state => state.taoExamCategoryControlSaveReportConnection,
        defaultSaveCondision,
        taoExamCategorySaveReportMessageConverter
    );

    useEffect(() => {// jobのリクエスト予約
        if (!isFirstRender.current) {
            /** ブラウザに保存しておく  */
            localStorage.setItem(localStorageKey, JSON.stringify(jobInfoMap));

            /** リクエスト予約する  */
            Object.keys(jobInfoMap).map(uuid => {// forEachで良いのでは？
                if (!shouldFetchJobUuids.includes(uuid) && !fetchReservationJobUuids.includes(uuid) && (getJobStatus(jobInfoMap[uuid].job) === BackgroundJobStatuses.sync)) {
                    setFetchReservationJobUuids(prev => prev.concat([uuid]));
                    setTimeout(() => setShouldFetchJobUuids(prev => prev.concat([uuid])), BACKGROUND_JOB_FETCH_INTERVAL_TIME);
                }
            });
        }
    }, [jobInfoMap])// eslint-disable-line

    useEffect(() => {
        intervalReFetch.current = setInterval(() => setShouldIntervalReFetchCount(prev => prev + 1), BACKGROUND_JOB_RE_FETCH_IN_NO_RESPONSE_INTERVAL_TIME);
        return () => {
            intervalReFetch.current && clearInterval(intervalReFetch.current);
        }
    }, []);

    /** 同期が完了していないジョブで、指定した時間、更新がない場合は、再フェッチする  */
    useEffect(() => {
        Object.keys(jobInfoMap).forEach(uuid => {
            /*** @type JobInfo */
            const jobInfo = jobInfoMap[uuid];
            const jobStatus = getJobStatus(jobInfo.job);
            // エラー時は何もしない
            if ((jobStatus === BackgroundJobStatuses.error)) return;
            // 60秒以上更新が無い
            if ((moment.unix(jobInfo.gmt).isSameOrBefore(moment().subtract(BACKGROUND_JOB_RE_FETCH_IN_NO_RESPONSE_INTERVAL_TIME, 'millisecond')))) {
                if (jobStatus === BackgroundJobStatuses.sync) { // 同期中の状態 (Actions.http.connection.job.viewの失敗)
                    dispatch(Actions.http.connection.job.view(uuid));
                }
                if (jobStatus === BackgroundJobStatuses.done) { // 次のjobに進まない同期jobのリトライ(Actions.http.connection.syncの失敗) or 同期jobの自動進行
                    dispatchNextSyncAction(jobInfo.job, dispatch, setLoadingJobInfo, features);
                }
            }
            // 1時間以上完了しない バックエンドでジョブが死んでしまっている場合
            if (!jobInfo.job.done_at && (moment.unix(jobInfo.job.created_at).isSameOrBefore(moment().subtract(BACKGROUND_JOB_CANCELABLE_TIME, 'millisecond')))) {
                setShowCloseJobInfo(jobInfo.job.uuid);
            }
        });
    }, [shouldIntervalReFetchCount])// eslint-disable-line

    useEffect(() => {
        isFirstRender.current = false;

        const jobInfoMapInBrowserString = localStorage.getItem(localStorageKey);
        const jobInfoMapInBrowser = JSON.parse(jobInfoMapInBrowserString ? jobInfoMapInBrowserString : '{}');
        if (Object.keys(jobInfoMapInBrowser).length > 0) {
            dispatch(Actions.data.jobInfoMap.setMap(jobInfoMapInBrowser));
        }
    }, [localStorageKey]);// eslint-disable-line

    useEffect(() => {
        if (shouldFetchJobUuids.length > 0) {
            shouldFetchJobUuids.forEach(uuid => {
                // 存在しないuuidはリクエストしない。死んでしまったjobを閉じるとshouldFetchJobUuidsに残る可能性があるため、ここで対策
                if (Object.keys(jobInfoMap).includes(uuid)) {
                    dispatch(Actions.http.connection.job.view(uuid));
                }
                setShouldFetchJobUuids(prev => prev.filter(v => v !== uuid));
                setFetchReservationJobUuids(prev => prev.filter(v => v !== uuid));
            })
        }
    }, [shouldFetchJobUuids])// eslint-disable-line

    /***
     * JobInfoをstoreから削除する
     * @param {Job.uuid} jobUuid
     */
    function removeJob(jobUuid) {
        dispatch(Actions.data.jobInfoMap.remove(jobUuid));
    };

    /***
     * Storeに登録済みのJobInfoにloadingをセットする
     * @param {Job.uuid} jobUuid
     */
    function setLoadingJobInfo(jobUuid) {
        dispatch(Actions.data.jobInfoMap.setStatus(jobUuid, { loading: true }));
    };

    /***
     * Storeに登録済みのJobInfoに、閉じるボタンを表示するフラグをセットする
     * @param {Job.uuid} jobUuid
     */
    function setShowCloseJobInfo(jobUuid) {
        dispatch(Actions.data.jobInfoMap.setStatus(jobUuid, { showClose: true }));
    };

    return (
        <BackgroundJobInfoContext.Provider value={[jobInfoMap, removeJob, setLoadingJobInfo]}>
            {children}
        </BackgroundJobInfoContext.Provider>
    );
};


const taoExamSaveReportMessageConverter = (result, connection) => {
    const message = result.done_at ?
        `${result.message}\n結果は「レポートを見る」から確認できます。` :
        `グループ名: ${connection.meta.groupName}\nテスト名: ${connection.meta.examName}`;

    return {
        ...result,
        message,
    };
};

const taoExamCategorySaveReportMessageConverter = (result, connection) => {
    const message = result.done_at ?
        `${result.message}\n結果は「レポートを見る」から確認できます。` :
        `グループ名: ${connection.meta.groupName}`;

    return {
        ...result,
        message,
    };
};
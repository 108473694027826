import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Modal, Progress } from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import * as Actions from 'flex/Actions';
import {
    checkEnvironmentForControllable,
    generateFileDomain,
} from 'constants/GlobalConfig';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';
import {
    BACKGROUND_JOB_TYPE,
    BACKGROUND_JOB_TYPE_GROUPS as JOB_TYPE_GROUPS,
} from './Background/BackgroundJobProvider';
import JobEffectComponent from './JobEffectComponent';

// learning-gateway-backend の src\models\infrastructures\JobState.php を参照.
export const JOB_TYPE = BACKGROUND_JOB_TYPE;

const JobModal = () => {
    const jobData = useSelector(state => state.jobData);
    const jobCallback = useSelector(state => state.jobCallback);
    const [loading, setLoading] = useState(jobData.meta.jobWaiting);
    const [loadingContent, setLoadingContent] = useState(jobData.payload);
    const dispatch = useDispatch();

    useEffect(() => {
        const isNotWaitingForQueueToRun = jobData.payload.is_success !== null;

        if (isNotWaitingForQueueToRun) {
            setLoading(false);
            if (jobData.payload.is_success) {
                if (JOB_TYPE_GROUPS.CSV_IMPORT_TYPES.includes(jobData.payload.type)) {
                    message.success(jobData.payload.message);
                } else if (JOB_TYPE_GROUPS.CSV_EXPORT_TYPES.includes(jobData.payload.type)) {
                    Modal.confirm({
                        title: '準備完了',
                        icon: <ExclamationCircleOutlined />,
                        content: 'エクスポートされたデータを保存します',
                        okText: '保存',
                        cancelText: 'キャンセル',
                        onOk: () => {
                            let urlString = checkEnvironmentForControllable() ? `${generateFileDomain()}/file/view/${jobData.payload.result.file_uuid}` : `${generateFileDomain()}/admin/user/view-file/${jobData.payload.result.file_uuid}`;
                            window.open(urlString);
                        }
                    });
                }
                jobCallback.payload.success();
            } else {
                if (!JOB_TYPE_GROUPS.SYNC_TYPES.includes(jobData.payload.type)) {
                    const payload = Object.assign({}, jobData.payload);
                    payload.result = {
                        errors: Object.assign({}, payload.result),
                        message: payload.message
                    };
                    dispatch(Actions.data.failure(payload, true))
                }
                jobCallback.payload.failure();
            }
        } else {
            if (jobData.payload.hasOwnProperty('type')) {
                if (
                    ![
                        ...JOB_TYPE_GROUPS.SYNC_TYPES,
                        ...JOB_TYPE_GROUPS.SYNC_DRY_RUN_TYPES
                    ].includes(jobData.payload.type)
                ) {
                    setLoading(true);
                    setLoadingContent((content) => ({
                        ...content,
                        message: jobData.payload.message,
                        result: jobData.payload.result
                    }))
                }
            } else {
                //完全なエラー jobデータに存在するはずのパラメータがないのでエラー
                setLoading(false);
                const payload = {};
                payload.result = {
                    errors: jobData.payload.errors,
                    message: jobData.payload.message
                };
                dispatch(Actions.data.failure(payload, true))
            }
        }
        // jobDataのみ監視
    }, [jobData]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <JobEffectComponent />
            <BaseModal
                centered
                title={<span>{jobData.payload.type_name}</span>}
                visible={loading}
                closable={false}
                footer={null}
                maskClosable={false}>
                <div className='job-progress-modal'>
                    <p>{loadingContent.message}</p>
                    {loadingContent.result ?
                        <div>
                            <Progress
                                strokeColor={{
                                    from: '#108ee9',
                                    to: '#87d068',
                                }}
                                percent={Math.floor(loadingContent.result.processed_count / loadingContent.result.total_count * 100)}
                                status='active'
                            />
                            <p>{loadingContent.result.total_count}件中 {loadingContent.result.processed_count}件</p>
                        </div>
                        :
                        <Spin />
                    }
                </div>
            </BaseModal>
        </>
    );
}

export default JobModal;

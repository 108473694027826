import { createAction } from 'redux-actions';
import {
    API_TAO_EXAM,
    API_TAO_CONTROL_EXAM,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/TAO/EXAM';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

type DefaultPayload = { method: HttpMethods, api: string };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

type Payload<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
    ? DefaultPayload
    : Expand<DefaultPayload & EXTRA>;
type Meta<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
    ? DefaultMeta
    : Expand<DefaultMeta & EXTRA>;


export const confirm = createAction<
    Payload | Http.Connection.Response.Exam.Confirm,
    Meta
>(
    createActionType('CONFIRM' as const),
    () => ({ method: 'get', api: API_TAO_EXAM + '/confirm' }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const save = createAction<
    Payload | Http.Connection.Response.Exam.Save,
    Meta,
    string
>(
    createActionType('SAVE' as const),
    (viewName) => ({
        method: 'post',
        api: API_TAO_EXAM + '/save', data: {}
    }),
    (viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName
    }),
);

export const controlSave = createAction<
    Payload | Http.Connection.Response.Exam.ControlSave,
    Meta,
    string
>(
    createActionType('CONTROL_SAVE' as const),
    (viewName) => ({
        method: 'post',
        api: `${API_TAO_CONTROL_EXAM}/save`, data: {}
    }),
    (viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName,
    })
);

export const saveReport = createAction<
    Payload | Http.Connection.Response.Exam.SaveReport,
    Meta,
    string,
    string,
    string
>(
    createActionType('SAVE_REPORT' as const),
    (uuid, groupName, examName) => ({
        method: 'get',
        api: `${API_TAO_EXAM}/save-report/${uuid}`,
    }),
    (uuid, groupName, examName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        uuid,
        groupName,
        examName,
    })
);

export const controlSaveReport = createAction<
    Payload | Http.Connection.Response.Exam.SaveReport,
    Meta,
    string,
    string,
    string
>(
    createActionType('CONTROL_SAVE_REPORT' as const),
    (uuid, groupName, examName) => ({
        method: 'get',
        api: `${API_TAO_CONTROL_EXAM}/save-report/${uuid}`,
    }),

    (uuid, groupName, examName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        uuid,
        groupName,
        examName,
    })
);

const deleteAction = createAction<
    Payload | Http.Connection.Response.Exam.Delete,
    Meta<{ viewName: string }>,
    string,
    string
>(
    createActionType('DELETE' as const),
    (uuid, _viewName) => ({ method: 'delete', api: API_TAO_EXAM + '/delete/' + uuid }),
    (_uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);
export { deleteAction as delete };

export const controlDelete = createAction<
    Payload | Http.Connection.Response.Exam.Delete,
    Meta<{ viewName: string }>,
    string,
    string
>(
    createActionType('CONTROL_DELETE' as const),
    (uuid, _viewName) => ({
        method: 'delete',
        api: `${API_TAO_CONTROL_EXAM}/delete/${uuid}`,
    }),
    (_uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const result = createAction<
    Payload | Http.Connection.Response.Exam.Result,
    Meta<{ viewName: string }>,
    string,
    { page?: number, pageSize?: number },
    string
>(
    createActionType('RESULT' as const),
    (uuid, data, _viewName) => {
        const { page, ...rest } = data;
        return {
            api: API_TAO_EXAM + `/result/${uuid}/${page ?? 1}`,
            data: {
                page_size: rest.pageSize ?? 10,
            },
            method: 'get',
        }
    },
    (_uuid, _data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlResult = createAction<
    Payload | Http.Connection.Response.Exam.ControlResult,
    Meta<{ viewName: string }>,
    string,
    { page?: number, pageSize?: number },
    string
>(
    createActionType('CONTROL_RESULT' as const),

    (uuid, data, _viewName) => {
        const { page, ...rest } = data;
        return {
            api: `${API_TAO_CONTROL_EXAM}/result/${uuid}/${page ?? 1}`,
            data: {
                page_size: rest.pageSize ?? 10,
            },
            method: 'get',
        }
    },
    (_uuid, _data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const user = createAction<
    Payload | Http.Connection.Response.Exam.User,
    Meta<{ viewName: string }>,
    string,
    string,
    string
>(
    createActionType('USER' as const),
    (examCategoryUuid, userUuid, _viewName) => {
        return { method: 'get', api: API_TAO_EXAM + `/user/${examCategoryUuid}/${userUuid}` }
    },
    (_uuid, _uuid2, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlUser = createAction<
    Payload | Http.Connection.Response.Exam.ControlUser,
    Meta<{ viewName: string }>,
    string,
    string,
    string
>(
    createActionType('CONTROL_USER' as const),
    (examCategoryUuid, userUuid, _viewName) => {
        return { method: 'get', api: `${API_TAO_CONTROL_EXAM}/user/${examCategoryUuid}/${userUuid}` }
    },
    (_uuid, _uuid2, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const statistics = createAction<
    Payload | Http.Connection.Response.Exam.Statistics,
    Meta<{ uuid: string }>,
    string,
    Http.Connection.Request.Parameter.Exam.Statistics,
    string
>(
    createActionType('STATISTICS' as const),
    (uuid, data, _viewName) => {
        return {
            method: 'get',
            api: API_TAO_EXAM + `/statistics/${uuid}`,
            data,
        }
    },
    (uuid, _data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, uuid, viewName })
);

export const controlStatistics = createAction<
    Payload | Http.Connection.Response.Exam.ControlStatistics,
    Meta<{ uuid: string }>,
    string,
    Http.Connection.Request.Parameter.Exam.ControlStatistics,
    string
>(
    createActionType('CONTROL_STATISTICS' as const),
    (uuid, data, _viewName) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/statistics/${uuid}`,
            data,
        }
    },
    (uuid, _data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, uuid, viewName })
);

export const distributionGraph = createAction<
    Payload,
    Meta,
    string,
    { school_class_uuid?: string }
>(
    createActionType('DISTRIBUTION_GRAPH' as const),
    (uuid, data) => {
        return {
            method: 'get',
            api: `${API_TAO_EXAM}/distribution-graph/${uuid}`,
            data,
        }
    },
    (_uuid, _data) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlDistributionGraph = createAction<
    Payload,
    Meta,
    string,
    { organization_uuid?: string, school_class_uuid?: string }
>(
    createActionType('CONTROL_DISTRIBUTION_GRAPH' as const),
    (uuid, data) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/distribution-graph/${uuid}`,
            data,
        }
    },
    (_uuid, _data) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const graph = createAction<
    Payload,
    Meta,
    string,
    { school_class_uuid?: string }
>(
    createActionType('GRAPH' as const),
    (uuid, data) => {
        return {
            method: 'get',
            api: `${API_TAO_EXAM}/graph/${uuid}`,
            data,
        }
    },
    (_uuid, _data) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlGraph = createAction<
    Payload,
    Meta,
    string,
    { school_class_uuid?: string, organization_uuid?: string }
>(
    createActionType('CONTROL_GRAPH' as const),
    (uuid, data) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/graph/${uuid}`,
            data,
        }
    },
    (_uuid, _data) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const resultItem = createAction<
    Payload | Http.Connection.Response.Exam.ResultItem,
    Meta,
    string,
    Record<string, unknown>,
    number
>(
    createActionType('RESULT_ITEM' as const),
    (uuid, data, page) => {
        return {
            method: 'get',
            api: `${API_TAO_EXAM}/result-item/${uuid}/${page}`,
            data,
        }
    },
    (_uuid, _data, _page) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlResultItem = createAction<
    Payload | Http.Connection.Response.Exam.ControlResultItem,
    Meta,
    string,
    Record<string, unknown>,
    number
>(
    createActionType('CONTROL_RESULT_ITEM' as const),
    (uuid, data, page) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/result-item/${uuid}/${page}`,
            data,
        }
    },
    (_uuid, _data, _page) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const reportDetail = createAction<
    Payload | Http.Connection.Response.Exam.ReportDetail,
    Meta,
    string,
    { school_class_uuid?: string }
>(
    createActionType('REPORT_DETAIL' as const),
    (uuid, data) => {
        return {
            method: 'get',
            api: `${API_TAO_EXAM}/report-detail/${uuid}`,
            data,
        }
    },
    (_uuid) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlReportDetail = createAction<
    Payload | Http.Connection.Response.Exam.ReportDetail,
    Meta,
    string,
    { organization_uuid?: string, school_class_uuid?: string }
>(
    createActionType('CONTROL_REPORT_DETAIL' as const),
    (uuid, data) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/report-detail/${uuid}`,
            data,
        }
    },
    (_uuid) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const userItemResult = createAction<
    Payload | Http.Connection.Response.Exam.ReportDetail,
    Meta,
    string,
    string,
    string,
    string | undefined
>(
    createActionType('USER_ITEM_RESULT' as const),
    (examUuid, userUuid, historyUuid, schoolClassUuid) => {
        return {
            method: 'get',
            api: `${API_TAO_EXAM}/user-item-result/${examUuid}/${userUuid}/${historyUuid}`,
            data: { school_class_uuid: schoolClassUuid },
        }
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlUserItemResult = createAction<
    Payload | Http.Connection.Response.Exam.ReportDetail,
    Meta,
    string,
    string,
    string,
    string | undefined
>(
    createActionType('CONTROL_USER_ITEM_RESULT' as const),
    (examUuid, userUuid, historyUuid, schoolClassUuid) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/user-item-result/${examUuid}/${userUuid}/${historyUuid}`,
            data: { school_class_uuid: schoolClassUuid },
        }
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const saveComment = createAction<
    Payload | Http.Connection.Response.Exam.ReportDetail,
    Meta,
    string,
    string,
    string,
    { comment: string | undefined }
>(
    createActionType('SAVE_COMMENT' as const),
    (examUuid, userUuid, historyUuid, data) => {
        return {
            method: 'post',
            api: `${API_TAO_EXAM}/save-comment/${examUuid}/${userUuid}/${historyUuid}`,
            data,
        }
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlSaveComment = createAction<
    Payload | Http.Connection.Response.Exam.ReportDetail,
    Meta,
    string,
    string,
    string,
    { comment: string | undefined }
>(
    createActionType('SAVE_COMMENT' as const),
    (examUuid, userUuid, historyUuid, data) => {
        return {
            method: 'post',
            api: `${API_TAO_CONTROL_EXAM}/save-comment/${examUuid}/${userUuid}/${historyUuid}`,
            data,
        }
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const targetSchoolClass = createAction<
    Payload | Http.Connection.Response.Exam.TargetSchoolClass,
    Meta,
    string
>(
    createActionType('TARGET_SCHOOL_CLASS' as const),
    (uuid) => {
        return {
            api: `${API_TAO_EXAM}/target-school-class/${uuid}`,
            method: 'get',
        }
    },
    (_uuid) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlTargetSchoolClass = createAction<
    Payload | Http.Connection.Response.Exam.TargetSchoolClass,
    Meta,
    string,
    string
>(
    createActionType('CONTROL_TARGET_SCHOOL_CLASS' as const),
    (examUuid, organizationUuid) => {
        //FIXME 暫定対応としてorganizationUuidがないケースで末尾に / つかないようにする
        if(organizationUuid){
            return {
                method: 'get',
                api: `${API_TAO_CONTROL_EXAM}/target-school-class/${examUuid}/${organizationUuid}`,
            }
        }else{
            return {
                method: 'get',
                api: `${API_TAO_CONTROL_EXAM}/target-school-class/${examUuid}`,
            }
        }
    },
    (_uuid, data) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

export const controlTargetOrganization = createAction<
    Payload | Http.Connection.Response.Exam.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_ORGANIZATION' as const),
    (uuid) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM}/target-organization/${uuid}`,
        }
    },
    (_uuid) => ({
        fetch: true,
        status: StatusEnum.REQUEST
    })
);

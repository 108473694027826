import { useEffect, useState } from 'react';
import * as React from 'react';

import { NotificationOutlined } from '@ant-design/icons';
import { Button, TablePaginationConfig, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import BaseModal from 'components/modules/BaseModal';
import { BaseTable } from 'components/modules/BaseTable';
import { getMoment, PageSizeOptions } from 'constants/GlobalConfig';

import {
    ResultSubItemsResult,
    useResultSubItems,
} from '../../../utils/CustomHooks/useResultSubItems';
const moment = getMoment();

const INIT_PAGINATION = {
    current: 1,
    pageSize: 50,
    pageSizeOptions: PageSizeOptions,
    position: ['topRight', 'bottomRight'] as TablePaginationConfig['position'],
    showSizeChanger: true,
    showTotal: (total: number, range: [number, number]) => {
        return `全${total}件中 ${range[0]}-${range[1]} 件`;
    },
};

type Props = {
    loading: boolean
    onClose: () => void
    visible: boolean
    questionnaireUuid: string | undefined
    question: EverydayNote.Question | undefined
    data: Http.Connection.Request.Parameter.EverydayNote.ResultSubTotal | undefined,
    classUuid: string
    userUuid: string | undefined
    tabName?: 'board' | 'school'
};

const DetailAnswerModal: React.VFC<Props> = ({
    loading,
    onClose,
    visible,
    questionnaireUuid,
    userUuid,
    question,
    data,
    classUuid,
    tabName = 'board',
}) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[]>([]);
    const [
        resultSubTotal,
        dispatchResultSubTotal,
        resetResultSubTotal,
    ] = useResultSubItems();
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        ...INIT_PAGINATION,
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: pageSize,
            }));
            dispatchData(page, pageSize, tabName);
        },
    });

    const dispatchData = (page: number, pageSize: number, tabName: 'board' | 'school') => {
        if (question === undefined || data === undefined) return;

        dispatchResultSubTotal(
            questionnaireUuid ?? '',
            question,
            {
                ...data,
                page_size: pageSize,
                scope: 'school-class',
                scope_uuid: classUuid ?? '',
            },
            userUuid,
            page,
            tabName,
        );
    };

    useEffect(() => {
        if (!visible) return;
        dispatchData(1, INIT_PAGINATION.pageSize, tabName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (visible) return;

        resetResultSubTotal();
        setExpandedRowKeys([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        setExpandedRowKeys(resultSubTotal.tableSource.map(({ uuid }) => uuid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSubTotal]);

    return (
        <BaseModal
            className='common-modal everyday-note-modal detail-answer-modal'
            footer={[
                <Button
                    disabled={loading}
                    key='close'
                    loading={loading}
                    onClick={onClose}
                    size='large'
                >
                    閉じる
                </Button>,
            ]}
            onCancel={onClose}
            title={<span><NotificationOutlined className='icon-r' />回答詳細</span>}
            visible={visible}
        >
            <div className='abstract'>
                <Typography.Title level={5}>
                    対象: {resultSubTotal.name}
                </Typography.Title>
                <Typography.Title level={5}>
                    期間: {moment.unix(data?.start_at ?? 0).format('YYYY年MM月DD日')}
                </Typography.Title>
                <Typography.Title level={5}>
                    設問: {question?.content}
                </Typography.Title>
            </div>

            <BaseTable
                columns={columns}
                dataSource={resultSubTotal.tableSource}
                emptyDescription='データはありません'
                expandable={{
                    // なぜか defaultExpandedRowKeys だと動かない
                    expandedRowKeys,
                    onExpandedRowsChange: setExpandedRowKeys,
                }}
                loading={resultSubTotal.isLoading}
                pagination={pagination}
                rowKey='uuid'
                scroll={{ x: 1000 }}
            />
        </BaseModal>
    );
};

export default DetailAnswerModal;


const columns: ColumnsType<ResultSubItemsResult['tableSource'][number]> = [
    {
        dataIndex: 'name',
        key: 'name',
        title: '名前',
        width: 200,
    },
    {
        dataIndex: 'answer',
        key: 'answer',
        title: '回答',
        width: 200,
    },
    {
        dataIndex: 'answeredAt',
        key: 'answeredAt',
        title: '回答日時',
        width: 100,
    },
];
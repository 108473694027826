import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { getDefaultLayout } from 'constants/GlobalConfig';
import BaseModal from 'components/modules/BaseModal';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;

const layout = getDefaultLayout()
const initFieldValue = {
    name: '',
    description: '',
};

function EditOrganization({
    record,
    onCancel,
    onOk,
    shouldCreate = false,
    visible = false,
}) {
    const [isConfirming,] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (record === null) {
            form.resetFields();
            form.setFieldsValue(initFieldValue)
        } else {
            const reuseValue = {
                name: record.name,
                description: record.description
            };
            form.setFieldsValue(reuseValue)
        }
    }, [record]);// eslint-disable-line

    function handleSubmit() {
        form.submit();
    };

    function onFinish(values) {
        onOk(values);
    };

    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined /> {shouldCreate ? '新規作成' : '編集'}</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key='back' size='large' onClick={onCancel}>
                    キャンセル
                </Button>,
                <Button key='create' type='primary' size='large'
                    loading={isConfirming}
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
            forceRender
        >
            <Form {...layout} form={form} name='control-hooks-edit-notification-category' onFinish={onFinish}>
                <Form.Item name='name'
                    label='カテゴリ名'
                    rules={[{ required: true, message: 'カテゴリ名を入力してください' }]}
                >
                    <Input placeholder='カテゴリ名を入力してください' />
                </Form.Item>

                <Form.Item name='description'
                    label='カテゴリ概要'
                    rules={[{ required: true, message: 'カテゴリ概要を入力してください' }]}
                >
                    <Input placeholder="カテゴリ概要を入力してください" />
                </Form.Item>
            </Form>
        </BaseModal>
    );
};

export default EditOrganization;
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Empty, Select } from 'antd';

import * as Actions from 'flex/Actions';

const { Option } = Select;

const EduMallSelect = ({
    edumallAppLists,
    form,
    name,
    onRemove,
}) => {
    const dispatch = useDispatch();
    const edumallSearchConnection = useSelector(state => state.edumallSearchConnection);
    const [input, setInput] = useState();
    const latestSelectInput = useRef(undefined);
    const searchReservation = useRef(undefined);

    const handleSearch = value => {
        // 入力内容が削除された場合は、内容をリセット及び検索をキャンセル
        if(value === '') {
            handleSelect(undefined, {});
            clearTimeout(searchReservation.current);
            return;
        }

        // 入力中(新たな入力が500ms以内の場合)は検索しないように直前の検索予約をキャンセル
        clearTimeout(searchReservation.current);

        latestSelectInput.current = value;

        searchReservation.current = setTimeout((ref) => {
            const v = ref.current;
            ref.current = undefined;

            if (v === undefined) return;

            setInput(v);
            dispatch(Actions.http.connection.edumall.search({
                contents_name: v,
                page_size: 50,
            }));
        }, 500, latestSelectInput);
    };

    const handleSelect = (value, option) => {
        const fields = form.getFieldsValue();
        const { targetDetails } = fields;
        Object.assign(targetDetails[name], { app: value });
        form.setFieldsValue({ targetDetails });
        setInput(option.children);
    };

    const handleClear = () => {
        handleSelect(undefined, {});
    };

    const edumallAppList = edumallAppLists[input] || {};
    return (
        <div className='search-target-detail'>
            <Select
                allowClear
                filterOption={false}
                name={name}
                notFoundContent={
                    edumallSearchConnection.meta.fetch ?
                        <span><LoadingOutlined  spin />検索中</span> :
                        <Empty description='アプリがありません' image={Empty.PRESENTED_IMAGE_DEFAULT}/>
                }
                onClear={handleClear}
                onSearch={handleSearch}
                onSelect={handleSelect}
                placeholder='対象を入力してください（例: 「教科書」「算数」など）'
                showArrow={false}
                showSearch
                value={input}
            >
                {edumallAppList.list?.map(term => {
                    return <Option key={term.value} value={term.value}>{term.text}</Option>;
                })}
            </Select>
            <Button
                className='mr-2'
                danger
                icon={<DeleteOutlined/>}
                onClick={onRemove}
                shape='circle'
                size='small'
            />
        </div>
    );
};

export default EduMallSelect;

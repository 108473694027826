import PropTypes from 'prop-types';


const Ruby = ({ children, className }) => {
    // 基本的にプレビューに使う都合上, 常にルビをつける
    const generatedChildren = generateRuby(children, true);
    const result = flatMap(generatedChildren.split(':!:'), function (part) {
        return [part];
    });
    return (
        <span>
            {result.map((value, index) => {
                const rubyPattern = /\{(.*?)\|(.*?)\}/;
                const rubyArray = value.match(rubyPattern);
                if (rubyArray) {
                    return (
                        <ruby key={index}><span>{rubyArray[1]}</span>
                            <rt><span className={className}>{rubyArray[2]}</span></rt>
                        </ruby>
                    );
                } else {
                    return <span className={className} key={index}>{value}</span>;
                }
            })}
        </span>
    );
};

function flatMap(array, fn) {
    let result = [];
    for (let i = 0; i < array.length; i++) {
        let mapping = fn(array[i]);
        result = result.concat(mapping);
    }
    return result;
}

const rubyPattern = /\{(.*?)\|(.*?)\}/g;
const generateRuby = (string, isLower) => {
    let result = '';
    if (isLower) {
        result = string?.replace(rubyPattern, `:!:$&:!:`);
    } else {
        result = string?.replace(rubyPattern, `$1`);
    }
    return result;
};

Ruby.propTypes = {
    className: PropTypes.string,
    isForcedAttach: PropTypes.bool
};
Ruby.defaultProps = {
    className: '',
    isForcedAttach: false,
};

export default Ruby;

import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Typography } from 'antd'
import CloudUploadOutlined from '@ant-design/icons/lib/icons/CloudUploadOutlined'
import { useDispatch, useSelector } from 'react-redux'
import CautionIllust from 'assets/illust/illust-caution.svg';
import * as Actions from '../../../../../../flex/Actions'
import { useAppSelector } from 'flex/utils';
import BaseModal from 'components/modules/BaseModal';

export const BackgroundSyncModal = ({
    isSyncOpen,
    isSyncDeleteUserOpen,
    onCancel,
    onOk,
}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const tenant = useAppSelector(state => state.tenant);
    const dryRunGroupConnection = useSelector(state => state.dryRunGroupConnection);
    const dryRunDeleteUserConnection = useSelector(state => state.dryRunDeleteUserConnection);
    const dryRunUserConnection = useSelector(state => state.dryRunUserConnection);
    const isFirstRender = useRef(true)

    useEffect(() => {
        if (!isFirstRender.current) {
            if (dryRunGroupConnection.meta.fetch || dryRunDeleteUserConnection.meta.fetch || dryRunUserConnection.meta.fetch) {
                setLoading(true)
            } else {
                setLoading(false)
                onOk()
            }
        }
        isFirstRender.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dryRunGroupConnection, dryRunDeleteUserConnection, dryRunUserConnection])

    function handleClickSync() {
        if (tenant.payload.features['sync-group']) {
            isSyncOpen && dispatch(Actions.http.connection.sync.dryRunGroup());
        } else {
            isSyncOpen && dispatch(Actions.http.connection.sync.dryRunUser());
        }
        isSyncDeleteUserOpen && dispatch(Actions.http.connection.sync.dryRunDeleteUser());
    }

    return (
        <BaseModal
            centered
            className='full-modal'
            title={<span><CloudUploadOutlined /> 外部アカウント管理サービスへの連携・同期</span>}
            visible={isSyncOpen || isSyncDeleteUserOpen}
            onCancel={onCancel}
            footer={[
                <Button key='cancel' size='large' onClick={onCancel} loading={loading} disabled={loading}>キャンセル</Button>,
                <Button key='start' size='large' onClick={handleClickSync} loading={loading} disabled={loading}>同期する</Button>
            ]}
        >
            {isSyncOpen && <SyncUserGuide tenant={tenant} />}
            {isSyncDeleteUserOpen && <SyncDeleteUserGuide tenant={tenant} />}
        </BaseModal>
    );
}

const BackgroundJobMessage = (props) => {
    const step = props.step;
    return (
        <Typography.Title level={5} className={'text'}>
            同期はバックグラウンドで行われ、同期中は他の作業を行うことができます。<br />
            {`同期は全${step}ステップの同期があり、ステップごとの同期の開始は手動で進める必要があります。`}<br />
            同期開始後は、画面左下より同期を進めてください。<br />
            同一ブラウザであれば、ブラウザを閉じても前回の同期から再開することが可能です。<br />
        </Typography.Title>
    );
}

const SyncConfirmMessage = (props) => {
    const targetInfo = props.isUserOnly ?
        'ユーザー情報' :
        'クラス情報およびユーザー情報';
    return (
        <Typography.Title level={5} className={'text'}>
            現在L-Gateに登録されている{targetInfo}をサーバに同期します。<br />
            サーバの情報は現在L-Gateに登録されている情報で全て上書きされます。<br />
            同期してよろしいですか？<br />
        </Typography.Title>
    );
}

const GoogleWorkspaceMessage = () => {
    return (
        <Typography.Text mark>
            <span>
                Google Workspaceに一度作成・同期されたアカウントは、<br />
                L-Gateの同期機能を使って、Google Workspaceでの役割（教員、児童生徒）を変更することはできません。<br />
                （L-Gate側の役割は変更されます。）
            </span>
        </Typography.Text>
    );
}

const ConnectionErrorMessage = (props) => {
    const target = props.isUserOnly ?
        'ユーザー' :
        'クラス・ユーザー';
    const message = (
        <span>
            通信エラーが原因で同期に失敗した場合は、再度同期を実行ください。（同期済みの{target}はスキップされるため、処理時間は前回より短くなります。）<br />
            通信エラーが発生した場合に表示されるメッセージの例:<br />
            ・ The service is currently unavailable.<br />
            ・ internal error encountered<br />
            ※ これらはGooogle WorkspaceからL-Gateへ返されているエラーメッセージです。
        </span>
    );
    return <Alert message={message} type='warning' showIcon />;
}

const SyncUserGuide = (props) => {
    const { tenant } = props;
    const isSyncGroup = !!tenant.payload?.features?.['sync-group'];

    return (
        <div className={'sync-flex center'}>
            <img alt='Caution Illust' src={CautionIllust} />
            <SyncConfirmMessage isUserOnly={!isSyncGroup} />
            <BackgroundJobMessage step={isSyncGroup ? 12 : 4} />

            {tenant.payload?.type === '3' && (
                <>
                    <GoogleWorkspaceMessage />
                    <ConnectionErrorMessage isUserOnly={!isSyncGroup} />
                </>
            )}
        </div>
    );
}

const SyncDeleteUserGuide = (props) => {
    const { tenant } = props;
    const isSyncGroup = !!tenant.payload?.features?.['sync-group'];

    return (
        <div className={'sync-flex center'}>
            <img alt='Caution Illust' src={CautionIllust} />
            <Typography.Title level={5} className={'text'}>
                ユーザ情報を同期し、サーバのユーザ情報を完全に削除します。<br />
                同期してよろしいですか？<br />
            </Typography.Title>
            <BackgroundJobMessage step={4} />

            {tenant.payload?.type === '3' && (
                <>
                    <GoogleWorkspaceMessage />
                    <ConnectionErrorMessage isUserOnly={!isSyncGroup} />
                </>
            )}
        </div>
    );
}

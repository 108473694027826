import {createSelector} from 'reselect'

const appsTemplateSearchForAdminConnectionSelector = state => state.appsTemplateSearchForAdminConnection
const appsTemplateSearchConnectionSelector = state => state.appsTemplateSearchConnection
const appsTemplateForAdminEditConnectionSelector =state => state.appsTemplateForAdminEditConnection

export const appTemplateTabSelectors = {
    loading: createSelector([appsTemplateSearchForAdminConnectionSelector, appsTemplateSearchConnectionSelector, appsTemplateForAdminEditConnectionSelector],
        (appsTemplateSearchForAdminConnection, appsTemplateSearchConnection, appsTemplateForAdminEditConnection)=>{
            return appsTemplateSearchForAdminConnection.meta.fetch || appsTemplateSearchConnection.meta.fetch || appsTemplateForAdminEditConnection.meta.fetch
        })
}

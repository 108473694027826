import React, { useEffect, useState } from 'react';

import { NotificationOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import BaseModal from 'components/modules/BaseModal';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import { TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import { useResultExam } from '../../hooks/useResultExam';
import { RequestStatisticsExam, useStatisticsExam } from '../../hooks/useStatisticsExam';
import { useStatisticsExamCategory } from '../../hooks/useStatisticsExamCategory';
import { UserExamState, useUserExam } from '../../hooks/useUserExam';
import { OrganizationList } from './OrganizationList';
import { StudentList } from './StudentList';
import { StudentView } from './StudentView';
import { Summary } from './Summary';
import { TestView } from './TestView';

import type {
    ResultExamCategoryState,
    RequestResultExamCategory,
} from '../../hooks/useResultExamCategory';

import 'styles/modules/test_status_modal.scss';

/**
 * パンくずリストの種類
 *
 * * Student: 生徒の詳細
 * * Test: テストの詳細
 * * List: 学校一覧 または 生徒一覧
 */
export type BreadCrumbType = 'Student' | 'Test' | 'List';

type Props = {
    examCategoryStatus: ResultExamCategoryState
    onCancel: () => void
    onOk: () => void
    record?: ExamCategory | undefined
    requestResult: RequestResultExamCategory
    tabName: 'board' | 'school' | undefined
    visible: boolean
}

export const ExamCategoryStatusModal: React.VFC<Props> = ({
    examCategoryStatus,
    onCancel,
    requestResult,
    record,
    tabName,
    visible,
}) => {
    const requestType = tabName === TAB_NAMES.SCHOOL ? 'default' : 'control';

    const [breadCrumb, setBreadCrumb] = useState<ExamCategoryTabState.BreadCrumb>({ type: 'List' });
    const [summaryInfo, setSummaryInfo] = useState<{ count: number, targetUsersCount: number }>();

    const [userExam, requestUserExam] = useUserExam();

    const [resultExam, requestResultExam] = useResultExam();
    const [statisticsExam, requestStatisticsExam] = useStatisticsExam();
    const [statisticsExamCategory, requestStatisticsExamCategory] = useStatisticsExamCategory();

    const { isLoading, result } = examCategoryStatus;

    const isControllableUser = useAppSelector(selectors.isControllableUser);

    const organizationsListItemCount = result && 'organizationsListItemCount' in result ?
        result.organizationsListItemCount :
        0;

    // モーダルを開いた直後(summaryInfo === undefined のとき)のみローディング中の表示を行う
    const isLoadingSummary = isLoading && summaryInfo === undefined;

    const targetString = isControllableUser ? '配信対象校' : '配信対象者';

    const targetCount = isControllableUser ?
        organizationsListItemCount :
        result?.targetUsersCount ?? 0;

    useEffect(() => {
        if (!visible) return;

        setBreadCrumb({ type: 'List' });
        setSummaryInfo(undefined);
    }, [visible]);

    useEffect(() => {
        if (!result?.targetUsersCount) return;
        if (summaryInfo && (summaryInfo.count !== 0 || summaryInfo.targetUsersCount !== 0)) return;

        setSummaryInfo({
            count: targetCount,
            targetUsersCount: result.targetUsersCount,
        });
    }, [result, summaryInfo, targetCount]);

    const handleClickBreadCrumb = (type: BreadCrumbType, name?: string, uuid?: string) => {
        setBreadCrumb({ name, type, uuid });
    };

    const handleClickTestInfo = (test: NonNullable<UserExamState['result']>['exams'][number]) => {
        requestResultExam(test.uuid, '', { page: 1, pageSize: 10 }, tabName);
        requestStatisticsExam(test, { latest_result_only: false }, requestType);
    };

    const handleShowStudentView = (name: string, uuid: string,) => {
        handleClickBreadCrumb('Student', name, uuid);
        requestUserExam(
            record?.uuid ?? '',
            uuid,
            requestType,
        );
        requestStatisticsExamCategory(
            record?.uuid ?? '',
            uuid,
            false,
            requestType,
        );
    };

    const handleReloadResultInStudent = (uuid: string, latestResultOnly?: boolean) => {
        requestStatisticsExamCategory(
            record?.uuid ?? '',
            uuid,
            latestResultOnly ?? true,
            requestType,
        );
    };

    const handleClickTestName = (exam: Parameters<RequestStatisticsExam>[0]) => {
        // requestStatisticsExam のパラメーターで横着をしたつけ
        if (typeof exam === 'string') return;

        handleClickBreadCrumb('Test', exam.title, exam.uuid);
        requestResultExam(
            exam.uuid,
            exam.title,
            {
                page: 1,
                pageSize: 10,
            },
            tabName
        );
        requestStatisticsExam(
            exam,
            { latest_result_only: false },
            requestType,
        );
    };

    const handleReloadResultInTest = (exam: Parameters<RequestStatisticsExam>[0], latestResultOnly?: boolean) => {
        requestStatisticsExam(
            exam,
            { latest_result_only: latestResultOnly ?? true },
            requestType,
        );
    };

    return (
        <BaseModal
            className='common-modal test-status-modal'
            footer={[<Button key='back' onClick={onCancel} size='large'>キャンセル</Button>]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined />{`テスト${targetString}と実施状況の確認`}</span>}
            visible={visible}
        >
            <Summary
                category={record}
                count={summaryInfo?.count}
                isControllableUser={isControllableUser}
                loading={isLoadingSummary}
                targetUsersCount={summaryInfo?.targetUsersCount}
            />

            <div className='wrapper'>
                {isControllableUser ?
                    <OrganizationList
                        breadCrumb={breadCrumb}
                        examCategoryStatus={examCategoryStatus}
                        isStatusOpen={visible}
                        record={record}
                        requestResult={requestResult}
                        tabName={tabName}
                    /> :
                    <StudentList
                        breadCrumb={breadCrumb}
                        examCategoryStatus={examCategoryStatus}
                        isStatusOpen={visible}
                        onClickTestName={handleClickTestName}
                        onClickUserName={handleShowStudentView}
                        record={record}
                        requestResult={requestResult}
                        tabName={tabName}
                    />
                }

                {breadCrumb.type === 'Student' && (
                    <StudentView
                        handleClickBreadCrumb={handleClickBreadCrumb}
                        onClickTestInfo={handleClickTestInfo}
                        onReloadResult={handleReloadResultInStudent}
                        record={record}
                        statisticsExamCategory={statisticsExamCategory}
                        userExam={userExam}
                    />
                )}

                {breadCrumb.type === 'Test' && (
                    <TestView
                        handleClickBreadCrumb={handleClickBreadCrumb}
                        handleShowStudentView={handleShowStudentView}
                        onReloadResult={handleReloadResultInTest}
                        record={resultExam}
                        requestResultExam={requestResultExam}
                        statisticsExam={statisticsExam}
                        tabName={tabName}
                    />
                )}
            </div>
        </BaseModal>
    );
};

import * as Actions from '../../../Actions'
import { createAddReducer } from '../../../utils';
import { defaultState, TAB_NAMES } from './index';
const addReducer = createAddReducer<ExamCategoryTabState>();

export const reducer = {
    // TODO: 適切な型に変更する
    'examCategoryTab': (state: any, action: any) => ({ ...action }),

    ...addReducer(Actions.view.viewLogic.examCategoryTab.cancelModal, (state, action) => ({
        ...state,
        modal: defaultState.modal,
    })),

    ...addReducer(Actions.view.viewLogic.examCategoryTab.savePageStatusList, (state, action) => ({
        ...state,
        modal: {
            ...state.modal,
            examCategoryStatus: {
                ...state.modal.examCategoryStatus,
                pagination: {
                    ...state.modal.examCategoryStatus.pagination,
                    current: action.payload.page,
                    pageSize: action.payload.page_size,
                },
            },
        },
    })),

    ...addReducer(Actions.view.viewLogic.examCategoryTab.submitEdit, (state, action) => {
        if(action.payload.tabName === TAB_NAMES.SCHOOL){
            return({
                ...state,
                modal: {
                    // タイトルを編集モードのままにするために
                    // この時点ではレコードも削除せず, まだ開いた状態を維持する
                    // submittedSave したときに諸々初期化する
                    ...state.modal,
                    isEditCategoryOpen: true,
                    isLoading: true,
                },
            })
        }else{
            return({
                ...state
            })
        }
    }),
};
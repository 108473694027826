import { createAction } from 'redux-actions';
import {
    API_AUTH_ADMIN,
    API_CHECK_LOGGED_IN,
    API_LOGOUT,
    API_TENANT,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/AUTHENTICATION';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const login = createAction(
    createActionType('LOGIN' as const),
    (data) => ({
        method: 'post',
        api: API_AUTH_ADMIN,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const logout = createAction(
    createActionType('LOGOUT' as const),
    () => ({
        method: 'get',
        api: API_LOGOUT
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const check = createAction(
    createActionType('CHECK' as const),
    () => ({
        method: 'get',
        api: API_CHECK_LOGGED_IN
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const tenant = createAction(
    createActionType('TENANT' as const),
    () => ({
        method: 'get',
        api: API_TENANT,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import * as Actions from 'flex/Actions';
import BaseModal from 'components/modules/BaseModal';

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const EditOrderApps = (props) => {
    const {
        applications = [],
        onOk,
        onCancel,
        uuid = '',
        visible = false,
    } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([])

    const appsOrderEditConnection = useSelector(state => state.appsOrderEditConnection);
    const isFirstRender = useRef(false)

    useEffect(() => {
        isFirstRender.current = true;
    }, [])

    useEffect(() => {//表示時、非表示時の初期化
        if (visible) {
            applications && setDataSource(applications.map((value, index) => ({
                ...value,
                index,
            })));
        } else {
            setDataSource([]);
        }
    }, [visible])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isFirstRender.current) {
            setLoading(appsOrderEditConnection.meta.fetch);
            if (appsOrderEditConnection.meta.status === Actions.statusEnum.SUCCESS) {
                onOk()
            }
        } else {
            isFirstRender.current = false;
        }
    }, [appsOrderEditConnection])// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        const data = {
            uuids: dataSource.map((value) => {
                return value.uuid
            })
        }
        dispatch(Actions.http.connection.apps.changeOrder(uuid, data, uuid));
    }

    const DragHandle = sortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            setDataSource(newData)
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const data = dataSource
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            helperClass='row-dragging'
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const columns = [
        {
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        }, {
            title: 'アプリ名',
            dataIndex: 'title',
            width: 350,
            key: 'title',
            className: 'drag-visible',
        }
    ];

    return (
        <BaseModal
            className='common-modal'
            title={<span>アプリ並び順の編集</span>}
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button key='back_order'
                    loading={loading}
                    size='large'
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button key='create_order'
                    loading={loading}
                    type='primary'
                    size='large'
                    onClick={handleSubmit}
                >
                    保存
                </Button>
            ]}>
            <div>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey='index'
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            </div>
        </BaseModal>
    );
}

export default EditOrderApps;

/* eslint-disable no-constant-condition */

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/ja.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/themes/gray.min.css';

import 'styles/pages/print_questionnaire_result.scss';
import ListView from './ListView';
import SingleView from './SingleView';

const ModalContents = ({
    onClickAnswerDetail,
    questionnaire,
    printTargetRef,
}: {
    onClickAnswerDetail: (item: Question) => void;
    questionnaire: Store['resultQuestionnaire']['questionnaire'];
    printTargetRef: React.RefObject<HTMLDivElement>;
}) => {
    return (
        <div ref={printTargetRef}>
            { questionnaire.isListView ?
                <ListView
                    onClickAnswerDetail={onClickAnswerDetail}
                    questionnaire={questionnaire}
                /> :
                <SingleView
                    onClickAnswerDetail={onClickAnswerDetail}
                    questionnaire={questionnaire}
                />
            }
        </div>
    );
};

export default ModalContents;


type ExamItemWithResult = Http.Connection.Response.Exam.ResultItem['result']['items'][0]['result_histories'][0]['items'][0];

export const convertExamItem = (
    item: ExamItem |
        ExamItemWithResult |
        Omit<ExamItem, 'duration_average'> |
        Omit<ExamItem, 'duration_average' | 'correct_rate'>
) => {
    const unitAveragePer = formatPercent(item.unit_average_per);
    const base = {
        averagePer: formatPercent(item.average_per),
        averageScore: item.average_score,
        contentTitle: item.content_title,
        createdAt: item.created_at,
        difficulty: item.difficulty,
        examUuid: item.exam_uuid,
        goalScore: item.goal_score,
        isHighlight: !isNaN(parseFloat(unitAveragePer)) &&
            parseFloat(unitAveragePer) < 50,
        itemId: item.item_id,
        itemListNumber: item.item_list_number,
        perfectScore: item.perfect_score,
        subjectChildName: item.subject_child_name,
        testTimeMin: item.test_time_min,
        totalDurationAverage: formatSecond(item.total_duration_average),
        unitAveragePer,
        updatedAt: item.updated_at,
        uuid: item.uuid,
    };

    return 'correct_rate' in item ?
        {
            ...base,
            correctRate: item.correct_rate,
        } :
        base;
};

export const convertExamItemWithResult = (item: ExamItemWithResult) => {
    return {
        ...convertExamItem(item),
        result: convertExamResult(item.result),
    };
};


const convertExamResult = (result: ExamResult) => {
    const isViewScoreSymbol = result.verb === 'answered' &&
        (result.scoring_type === 'auto' || result.scoring_type === 'manual');

    return {
        createdAt: result.created_at,
        duration: formatSecond(result.duration),
        examItemUuid: result.exam_item_uuid,
        isSuccess: result.is_success,
        response: result.response,
        scorePer: formatPercent(result.score_scaled === null ? result.score_scaled : result.score_scaled * 100),
        scoreRaw: result.score_raw,
        scoreScaled: result.score_scaled,
        scoreSymbol: isViewScoreSymbol && !isNaN(parseFloat(`${result.score_scaled}`)) ?
            result.score_scaled === 1 ?
                '○' :
                result.score_scaled === 0 ?
                    '×' :
                    '△' :
            '-',
        scoringType: result.scoring_type,
        studyLogId: result.studylog_id,
        totalDuration: formatSecond(result.total_duration),
        updatedAt: result.updated_at,
        userUuid: result.user_uuid,
        uuid: result.uuid,
        verb: result.verb,
        viewCount: result.view_count,
        viewRecentness: result.view_recentness,
    };
};


const formatPercent = (percent: number | undefined | null) => {
    if (percent === undefined || percent === null) return '-' as const;
    return percent.toFixed(1) as `${number}`;
};

const formatSecond = (second: string | number | undefined | null) => {
    if (second === undefined || second === null) return undefined;
    const s = Math.round(Number(second));
    const zps = `0${s % 60}`.slice(-2);
    return `${Math.floor(s / 60)}:${zps}` as `${number}:${number}`;
};
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Badge, Tooltip, Tag, Row, Col, message } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
    UnlockOutlined,
    ImportOutlined,
    IssuesCloseOutlined,
    FlagOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { userManagementTabFlex } from 'flex/view/UsersManagement/UserManagementTab';
import {
    getIndefiniteTerm,
    getIsActiveAdministrator,
    getMoment,
    checkEnvironmentIsTao,
} from 'constants/GlobalConfig';
import UsersSearch from './UsersSearch';
import EditUser from './EditUser';
import { MultipleResetFormValue, MultipleResetModal } from './Modals/MultipleResetModal';
import { PasswordModal } from './Modals/PasswordModal';
import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import SyncServerConfirm from 'components/modules/SyncServerConfirm';
import UploadCsvModal from 'components/modules/UploadCSVModal';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import SyncPasswordModal from './Sync/SyncPasswordModal';
import { BackgroundSyncModal } from './Sync/BackgroundSyncModal';
import { TabKeyEnum } from '../index';
import { Form } from 'antd';
import { useJobReloadEffect } from 'components/modules/Job/useJobReloadEffect';

const moment = getMoment();
const SHOULD_RELOAD_BACKGROUND_JOBS_IF_SUCCESS = [
    BACKGROUND_JOB_TYPE.SYNC_DELETED_USER, // ユーザ削除同期
    BACKGROUND_JOB_TYPE.USER_CSV_IMPORT, // ユーザCSVインポート
    BACKGROUND_JOB_TYPE.DELETE_USER_FROM_CSV_IMPORT, //ユーザ削除CSVインポート
];
const PULLDOWN_LENGTH = 1000;

interface Props {
    tabActiveKey: (typeof TabKeyEnum)[keyof typeof TabKeyEnum]
    hasTouchScreen: boolean
}

/***
 * ユーザーリスト一覧用component
 */
const UsersList: React.VFC<Props> = props => {
    const [searchForm] = Form.useForm<User.Search>();
    const [isSyncOpen, setIsSyncOpen] = useState(false);
    const [isSyncDeleteUserOpen, setIsSyncDeleteUserOpen] = useState(false);
    const [isCsvUploadOpen, setIsCsvUploadOpen] = useState(false);
    // クラス/名前リセット確認画面
    const [isOpenMultipleResetModal, setIsOpenMultipleResetModal] = useState(false);
    //パスワードリセット確認画面
    const [passwordResetConfirm, setPasswordResetConfirm] = useState(false);
    //パスワードリセットダイアログ用flag
    const [passwordResetOpen, setPasswordResetOpen] = useState(false);
    //パスワードリセットダイアログ用flag
    const [passwordResetValue, setPasswordResetValue] = useState({ is_forced_change_password: false, password: '' });
    //アカウントロック解除対象のユーザ名
    const [selectRowsUserName, setSelectRowsUserName] = useState<string | null>(null);
    // セレクタ
    const { usersLoading, csvExportLoading, csvImportLoading } = useAppSelector(userManagementTabFlex.selectors.loading);
    const { visibleResetPasswordButton, visibleSyncUserButton } = useAppSelector(userManagementTabFlex.selectors.visible);
    const { csvExportDisabled, csvImportDisabled } = useAppSelector(userManagementTabFlex.selectors.disabled);
    const csvExportDisabledMsg = useAppSelector(userManagementTabFlex.selectors.csvExportDisabledMsg);
    const csvImportDisabledMsg = useAppSelector(userManagementTabFlex.selectors.csvImportDisabledMsg);
    const tabSelector = useAppSelector(state => state.userManagementTab);
    const globalSelector = useAppSelector(state => state.globalState);
    const isControllableUser = useAppSelector(state => state.isControllableUser);
    const user = useAppSelector(state => state.user.payload);
    const dispatch = useDispatch();
    const usersAccountUnlockConnection = useAppSelector(state => state.usersAccountUnlockConnection);
    const { payload: tenant } = useAppSelector(state => state.tenant);
    const multipleUpdateFlagSelector = useAppSelector(userManagementTabFlex.selectors.multipleUpdateFlag);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (globalSelector.currentTerm) {
            dispatch(Actions.view.viewLogic.userManagementTab.openView());
        }
    }, [globalSelector.currentTerm, dispatch]);

    useEffect(() => {
        if (props.tabActiveKey !== 'users') return;

        // 余計な通信が発生しないように初回表示時はスキップ
        if (isFirstRender.current) return;

        const search = tabSelector.search;
        const current = tabSelector.pagination.current;
        dispatch(Actions.view.viewLogic.userManagementTab.search(search, current));

        const organizationUuid = searchForm.getFieldValue('organization_uuid');

        const roleSearchBody = organizationUuid ?
            { page_size: PULLDOWN_LENGTH, organization_uuid: organizationUuid } :
            { page_size: PULLDOWN_LENGTH };

        dispatch(Actions.http.connection.role.search(roleSearchBody));


        const classesSearchBody = organizationUuid ?
            { page_size: PULLDOWN_LENGTH, organization_uuid: organizationUuid, term_uuid: globalSelector.currentTerm } :
            { page_size: PULLDOWN_LENGTH, term_uuid: globalSelector.currentTerm };

        dispatch(Actions.http.connection.classes.searchForListMap(
            classesSearchBody,
            undefined,
            globalSelector.currentTerm,
        ));
        // tabActiveKeyのみ監視したいため
    }, [props.tabActiveKey]);// eslint-disable-line react-hooks/exhaustive-deps

    useJobReloadEffect(
        SHOULD_RELOAD_BACKGROUND_JOBS_IF_SUCCESS,
        () => {
            if (isFirstRender.current || props.tabActiveKey !== TabKeyEnum.USERS) return;
            dispatch(Actions.view.viewLogic.userManagementTab.search());
        },
    );

    useEffect(() => {
        if (multipleUpdateFlagSelector.meta.fetch) return;
        if (multipleUpdateFlagSelector.meta.status !== Actions.statusEnum.SUCCESS) return;

        setIsOpenMultipleResetModal(false);
        const target = [
            tabSelector.multipleReset.isResetClass !== undefined ? 'クラス' : undefined,
            tabSelector.multipleReset.isResetName !== undefined ? '名前' : undefined,
        ].filter(e => e).join('/');

        if (target === '') return;

        message.success(`${target}の次回ログイン時設定を更新しました`);

        !!rowSelection.onChange && rowSelection.onChange([], []);

    }, [multipleUpdateFlagSelector]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersAccountUnlockConnection.meta.fetch && usersAccountUnlockConnection.meta.status === Actions.statusEnum.SUCCESS) {
            if (selectRowsUserName !== null) {
                message.success(selectRowsUserName ? `${selectRowsUserName}のアカウントロックの解除に成功しました` : 'アカウントロックの解除に成功しました');
            }
        } else {
            return;
        }
    }, [usersAccountUnlockConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            dispatch(Actions.view.viewLogic.userManagementTab.closeView());
        }
    }, [dispatch]);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    const handleCreateClick = () => {
        dispatch(Actions.view.viewLogic.userManagementTab.openCreate());
    };

    // 検索結果をCSVでダウンロードさせる
    const handleCsvDownload = (encoding: Encoding, options?: Record<string, unknown>) => {
        dispatch(Actions.view.viewLogic.userManagementTab.downloadCsv(encoding, options?.isSimpleExport));
    };

    const handleCsvImport = () => {
        setIsCsvUploadOpen(true);
    };

    const handleEditClick = (record: User) => {
        dispatch(Actions.view.viewLogic.userManagementTab.openEdit(record));
    };

    const handleDeleteClick = (id: string) => {
        dispatch(Actions.http.connection.users.delete(id));
    };

    const handleCancelDeleteClick = (id: string) => {
        dispatch(Actions.http.connection.users.unsetPlannedToDelete(id));
    }

    const handleEditSubmitted = () => {
        dispatch(Actions.view.viewLogic.userManagementTab.editSubmit());
    };

    const handleEditCancel = () => {
        dispatch(Actions.view.viewLogic.userManagementTab.editCancel());
    };

    const handleCsvUploadSubmit = () => {
        setIsCsvUploadOpen(false);
        dispatch(Actions.view.viewLogic.userManagementTab.search());
    };

    const handleCsvUploadCancel = () => {
        setIsCsvUploadOpen(false);
    };

    const handleSyncServer = (importTYpe: User.ImportType) => {
        switch (importTYpe) {
            case 'create':
                setIsSyncOpen(true);
                break;

            case 'delete':
                setIsSyncDeleteUserOpen(true);
                break;
            default:
                break;
        }
    };

    const handleSubmitMultipleReset = (value: MultipleResetFormValue) => {
        dispatch(Actions.view.viewLogic.userManagementTab.submitMultipleReset({
            is_forced_change_class: value.isResetClass,
            is_forced_change_name: value.isResetName,
            user_uuids: (tabSelector.selectedRows ?? []).concat(tabSelector.currentSelectedRows).map(e => e.uuid),
        }));
    };

    const handleCancelMultipleReset = () => {
        setIsOpenMultipleResetModal(false);
    };

    const handleAccountUnlock = (record: User) => {
        const userName = [
            record.last_name ? record.last_name : undefined,
            record.first_name ? record.first_name : undefined,
        ].filter(e => e).join(' ');
        setSelectRowsUserName(userName);

        dispatch(Actions.http.connection.users.accountUnlock({ user_uuid: record.uuid }));
    };

    /***
     * 検索対象の年度もしくは、無期限の年度のみ rowを返す
     * @param row  フィルター前
     * @return row フィルター済み
     */
    const filterByTerm = (row: User['belongs']) => {
        const term = tabSelector.search.term_uuid;
        if (!term) { return row; }
        const infiniteTerm = getIndefiniteTerm(globalSelector.termList).uuid;
        return row.filter(val => val.school_class.term.uuid === term || val.school_class.term.uuid === infiniteTerm);
    };

    /***
     * 全体管理者と学校管理者は、学校管理者、教員、生徒のリセットが可能
     * 教師は生徒だけのロールを持つユーザのみリセットが可能
     ***/
    const canResetPassword = (userRecord: User) => {
        if (userRecord.is_planned_to_delete) return false;
        if (isControllableUser.payload) return true;
        if (getIsActiveAdministrator(user)) return true;
        // 管理者でない(=教員)なら、生徒しか操作できない
        for (const belong of userRecord.belongs) {
            if (belong.role.permission_code !== 'student') {
                return false;
            }
        }
        return true;
    };

    /***
     * 削除権限を持つユーザであるかを判断
     ***/
    const isDeletePermissionUser = (userRecord: User) => {
        // 管理者であるか
        if (isControllableUser.payload) return true;
        if (getIsActiveAdministrator(user)) return true;
        // 教員または生徒であるか
        for (const belong of userRecord.belongs) {
            if ((belong.role.permission_code === 'student') || (belong.role.permission_code === 'teacher')) {
                return false;
            }
        }
        return true;
    };

    const columns: ColumnsType<User> = [
        {
            title: '認証先',
            dataIndex: 'is_local',
            key: 'is_local',
            width: 70,
            render: (is_local) => {
                switch (is_local) {
                    case true: {
                        const tag =
                            <Tag
                                className='tag blue-gray'
                                style={{ width: '70px', textAlign: 'center', borderRadius: '5px' }}
                            >
                                ローカル
                            </Tag>
                        return checkEnvironmentIsTao()
                            ? tag
                            : <Tooltip mouseEnterDelay={0.1} title='このアカウントはL-Gateのサインイン画面からのみサインインすることができます'>
                                {tag}
                            </Tooltip>;
                    }
                    case false: {
                        return (
                            <Tooltip
                                mouseEnterDelay={0.1}
                                title='このアカウントは連携先IdP(AzureADまたはGSuite）からサインインすることができます'
                            >
                                <Tag
                                    className='tag blue-gray'
                                    style={{ width: '70px', textAlign: 'center', borderRadius: '5px' }}
                                >
                                    SSO
                                </Tag>
                            </Tooltip>
                        );
                    }
                    default: {
                        return <Tag className='tag blue-gray'>不明</Tag>;
                    }
                }
            }
        },
        {
            title: 'ユーザーID',
            dataIndex: 'login_id',
            key: 'login_id',
            width: 150,
            render: (login_id, record) => {
                return (
                    <Tooltip mouseEnterDelay={1} title={'UUID:' + record.uuid}>
                        {login_id}
                    </Tooltip>
                );
            }
        },
        {
            title: '状態',
            width: 100,
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.is_planned_to_delete) {
                    return <Badge color='orange' text='削除予定' />;
                }

                switch (record.is_active) {
                    case true: {
                        return <Badge color='green' text='有効' />;
                    }
                    case false: {
                        return <Badge color='volcano' text='無効' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            }
        },
        {
            title: '学校',
            width: 150,
            dataIndex: 'belongs',
            key: 'organization',
            render: (row) => {
                return filterByTerm(row).map((val, index) => {
                    return (
                        <div key={`${val.school_class.organization.name}-${index}`}>
                            {val.school_class.organization.name}
                        </div>
                    );
                });
            }
        },
        {
            title: 'クラス',
            children: [
                {
                    title: 'クラス名',
                    width: 150,
                    dataIndex: 'belongs',
                    key: 'class',
                    render: (row) => {
                        return filterByTerm(row).map((val, index) => {
                            return (
                                <div key={`${val.school_class.name}-${index}`}>
                                    <Tag color='#AAAAAA' style={{ width: '75px', textAlign: 'center', borderRadius: '5px' }}>
                                        {val.school_class.term.name}
                                    </Tag>
                                    {val.school_class.name}
                                    {val.is_primary && <FlagOutlined className='ml-2' />}
                                </div>
                            );
                        });
                    }
                },
                {
                    title: '役割',
                    width: 80,
                    dataIndex: 'belongs',
                    key: 'roles',
                    render: (row) => {
                        return filterByTerm(row).map((val, index) => {
                            return <div key={`roles-${index}`}>{val.role.name}</div>;
                        });
                    }

                },
                {
                    title: '出席番号',
                    width: 75,
                    dataIndex: 'belongs',
                    key: 'num',
                    render: (row) => {
                        return filterByTerm(row).map((val, index) => {
                            return <div key={`school_class_num-${index}`}>{val.school_class.number}</div>;
                        });
                    }
                },

            ]
        },
        {
            title: '名前',
            children: [
                {
                    title: '姓',
                    width: 100,
                    dataIndex: 'last_name',
                    key: 'last_name'
                },
                {
                    title: '姓（ふりがな）',
                    width: 100,
                    dataIndex: 'last_name_kana',
                    key: 'last_name_kana'
                },
                {
                    title: '名',
                    width: 100,
                    dataIndex: 'first_name',
                    key: 'first_name'
                },
                {
                    title: '名（ふりがな）',
                    width: 100,
                    dataIndex: 'first_name_kana',
                    key: 'first_name_kana'
                },
            ]
        },
        {
            title: '作成時間/更新時間',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 150,
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            fixed: 'right',
            render: (_, record) => (
                <Row justify='space-around' align='middle'>
                    {record.is_local
                        ? <Col>
                            <Tooltip title={props.hasTouchScreen ? undefined : 'アカウントロック解除'}>
                                <Button
                                    disabled={!record.is_account_locked}
                                    className='mr-2'
                                    shape='circle'
                                    size='small'
                                    icon={<UnlockOutlined />}
                                    onClick={() => handleAccountUnlock(record)}
                                />
                            </Tooltip>
                        </Col>
                        : <Col>
                            <div className='invisible mr-2 ant-btn ant-btn-circle ant-btn-sm ant-btn-icon-only'>
                                <UnlockOutlined />
                            </div>
                        </Col>
                    }

                    <Col>
                        <CircleButton
                            tooltipProps={{
                                title: props.hasTouchScreen ? undefined : 'ユーザー情報の編集',
                            }}
                            className='mr-2'
                            disabled={record.is_planned_to_delete}
                            size='small'
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(record)}
                        />
                    </Col>
                    <Col>
                        {record.is_planned_to_delete ?
                            <CircleButton
                                className='mr-2'
                                danger
                                disabled={!isDeletePermissionUser(user)}
                                icon={<UndoOutlined />}
                                popconfirmProps={{
                                    cancelText: 'キャンセル',
                                    okText: '取り消す',
                                    onConfirm: () => handleCancelDeleteClick(record.uuid),
                                    placement: 'topRight',
                                    title: (
                                        <span>
                                            削除予定を取り消します。
                                        </span>
                                    ),
                                }}
                                size='small'
                                tooltipProps={{
                                    title: props.hasTouchScreen ? undefined : '削除予定の取り消し',
                                }}
                            /> :
                            <CircleButton
                                tooltipProps={{
                                    title: props.hasTouchScreen ? undefined : '削除',
                                }}
                                popconfirmProps={{
                                    okText: '削除',
                                    cancelText: 'キャンセル',
                                    onConfirm: () => handleDeleteClick(record.uuid),
                                    placement: 'topRight',
                                    title: (
                                        <>
                                            <span>
                                                ユーザを削除します。
                                                {tenant.features.sync && !record.is_local ?
                                                    '削除の実行はユーザ同期後行われます。' : ''
                                                }
                                            </span>
                                            <br />
                                            <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                        </>
                                    ),
                                }}
                                className='mr-2'
                                disabled={record.is_planned_to_delete || !isDeletePermissionUser(user)}
                                size='small'
                                danger
                                icon={<DeleteOutlined />}
                            />
                        }
                    </Col>
                </Row>
            )
        },
    ];

    const rowSelection: TableRowSelection<User> = {
        selectedRowKeys: tabSelector.currentSelectedRows.map(row => row.uuid),
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch(Actions.view.viewLogic.userManagementTab.selectRows(selectedRows));
        },
        getCheckboxProps: record => {
            return {
                disabled: !canResetPassword(record),
                name: record.login_id,
            };
        },
    };

    return (
        <div className='users-list container'>
            <UsersSearch form={searchForm} />
            <div className='flex-right-container gutter-bottom'>

                {visibleResetPasswordButton
                    ? <Button
                        type='primary'
                        onClick={() => {
                            setIsOpenMultipleResetModal(true)
                        }}
                        icon={<IssuesCloseOutlined />}
                    >
                        クラス/名前の再入力
                    </Button>
                    : null
                }

                {(tenant.type === '1' || tenant.features.sync) && visibleResetPasswordButton
                    ? <Button
                        type='primary'
                        onClick={() => {
                            setPasswordResetConfirm(true)
                        }}
                        icon={<IssuesCloseOutlined />}
                    >
                        パスワードリセット
                    </Button>
                    : null
                }

                {tenant.features.sync && visibleSyncUserButton
                    ? <SyncServerConfirm handleOk={handleSyncServer} />
                    : undefined
                }
                <Tooltip title={csvImportDisabledMsg}>
                    <Button
                        type='primary'
                        loading={csvImportLoading}
                        disabled={csvImportDisabled}
                        icon={<ImportOutlined />}
                        onClick={handleCsvImport}
                    >
                        CSVインポート
                    </Button>
                </Tooltip>

                <DownloadCSVConfirm
                    loading={csvExportLoading}
                    disabled={csvExportDisabled}
                    disabledMessage={csvExportDisabledMsg}
                    handleOk={handleCsvDownload}
                    isUserManagement
                />
                <Button
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                rowSelection={rowSelection}
                emptyDescription={
                    <>
                        条件に一致する利用者はいませんでした。 <br />
                        検索条件を変更して再度検索をしてください。
                    </>
                }
                loading={usersLoading}
                columns={columns}
                dataSource={tabSelector.usersList || []}
                scroll={{ x: 1950 }}
                pagination={{
                    ...tabSelector.pagination,
                    showTotal: (total, range) => { return (`全${total}件中 ${range[0]}-${range[1]} 件`) },
                    onChange: (page, page_size) => {
                        dispatch(Actions.view.viewLogic.userManagementTab.page(page, page_size ?? 50));
                    },
                    onShowSizeChange: () => { },
                }}
            />
            <EditUser
                shouldCreate={tabSelector.editUser.shouldCreate}
                record={tabSelector.editUser.userRecord}
                loading={tabSelector.editUser.loading}
                visible={tabSelector.editUser.isEditOpen}
                onOk={handleEditSubmitted}
                onCancel={handleEditCancel}
            />
            <BackgroundSyncModal
                isSyncOpen={isSyncOpen}
                isSyncDeleteUserOpen={isSyncDeleteUserOpen}
                onOk={() => {
                    setIsSyncOpen(false);
                    setIsSyncDeleteUserOpen(false);
                }}
                onCancel={() => {
                    setIsSyncOpen(false);
                    setIsSyncDeleteUserOpen(false);
                }}
            />
            <UploadCsvModal
                visible={isCsvUploadOpen}
                onOk={handleCsvUploadSubmit}
                onCancel={handleCsvUploadCancel}
            />
            <SyncPasswordModal
                visible={passwordResetOpen}
                selectedRows={(tabSelector.selectedRows ?? []).concat(tabSelector.currentSelectedRows)}
                passwordResetValue={passwordResetValue}
                onOk={() => {
                    !!rowSelection.onChange && rowSelection.onChange([], []);
                    setPasswordResetOpen(false);
                    setPasswordResetConfirm(false);
                }}
                onCancel={() => {
                    setPasswordResetOpen(false);
                }}
            />
            <PasswordModal
                visible={passwordResetConfirm}
                onOk={(value) => {
                    setPasswordResetValue(value);
                    setPasswordResetOpen(true);
                }}
                onCancel={() => {
                    setPasswordResetConfirm(false);
                }}
            />

            <MultipleResetModal
                loading={multipleUpdateFlagSelector.meta.fetch}
                visible={isOpenMultipleResetModal}
                onOk={handleSubmitMultipleReset}
                onCancel={handleCancelMultipleReset}
            />
        </div>
    );
};

export default UsersList;

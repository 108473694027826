import { createContext, useState } from 'react';

import { faSchool } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from 'antd';

import OrganizationEffectComponent from './OrganizationEffectComponent';
import OrganizationList from './OrganizationList';

const { TabPane } = Tabs;

export const ListContext = createContext<[Organization.List, React.Dispatch<React.SetStateAction<Organization.List>>]>([
    { organizationList: [], organizationListItemCount: 0 },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ()=>{},
]);

const Organization = () => {
    const [list, setList] = useState<Organization.List>({
        organizationList: [],
        organizationListItemCount: 0,
    });

    return (
        <div className='page-questionnaire card-container'>
            <Tabs defaultActiveKey='/notifications'>
                <TabPane key='/notifications' tab={<span><FontAwesomeIcon icon={faSchool} style={{ paddingRight:'3px' }}/> 学校一覧</span>}>
                    <ListContext.Provider value={[list, setList]}>
                        <OrganizationList/>
                        <OrganizationEffectComponent />
                    </ListContext.Provider>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Organization;

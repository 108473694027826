import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Badge, Space } from 'antd';

import { BackgroundJobStatuses, getJobStatus } from './BackgroundJobUtils';


/** 画面の横幅が広い時のアイコンと状態の表示  */
export const BackgroundJobStatus = ({ jobInfoList, collapsed }) => {
    const doneJobCount = jobInfoList.filter(jobInfo => getJobStatus(jobInfo.job) === BackgroundJobStatuses.done).length;
    const syncJobCount = jobInfoList.filter(jobInfo => getJobStatus(jobInfo.job) === BackgroundJobStatuses.sync).length;
    const errorJobCount = jobInfoList.filter(jobInfo => getJobStatus(jobInfo.job) === BackgroundJobStatuses.error).length;

    const jobCount = jobInfoList.length;
    const statusStyle = errorJobCount > 0 ?
        {} :
        doneJobCount > 0 ?
            { backgroundColor: '#52c41a' } :
            { backgroundColor: '#faad13' };

    return (
        <Space className={collapsed ? 'background_task_icon_only' : 'background_task_full_status'} size={15} >
            <Badge count={jobCount} style={statusStyle}>
                <Avatar icon={<FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>} size={44} style={{ backgroundColor: '#004C97' }} />
            </Badge>
            {!collapsed && (
                <div className='description'>
                    <span className='label'>タスク </span>
                    {syncJobCount > 0 ? <span className='label' key='sync'>進行中:{syncJobCount} </span> : null}
                    {doneJobCount > 0 ? <span className='label' key='done'>完了:{doneJobCount} </span> : null}
                    {errorJobCount > 0 ? <span className='label' key='error'>エラー:{errorJobCount} </span> : null}
                </div>
            )}
        </Space>
    );
};

import { Card, Col, Progress, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


export const TargetUsersInfo = ({
    loading = false,
    targetUsersList,
}) => {
    const getReadPercent = () => {
        return (targetUsersList.readCount === 0 ? 0 : Math.round((targetUsersList.readCount / targetUsersList.totalCount) * 100))
    }

    return (
        <div className='notification-status-modal-card-wrapper'>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false} className='notification-status-modal-card' title='公開対象者'>
                        {loading ?
                            <Row
                                align='middle'
                                justify='space-around'
                                style={{ textAlign: 'center' }}
                                type='flex'
                            >
                                <Col span={24}>
                                    <span><LoadingOutlined /> 読み込み中...</span>
                                </Col>
                            </Row> :
                            <Row align='middle' type='flex'>
                                <Col span={24}>
                                    <div className='notification-status-modal-title'>合計公開者数</div>
                                    <div className='notification-status-modal-value'>{targetUsersList.totalCount}名</div>
                                </Col>
                            </Row>
                        }
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} className='notification-status-modal-card' title='開封状況'>
                        {loading ?
                            <Row
                                align='middle'
                                justify='space-around'
                                style={{ textAlign: 'center' }}
                                type='flex'
                            >
                                <Col span={24}>
                                    <span><LoadingOutlined /> 読み込み中...</span>
                                </Col>
                            </Row> :
                            <Row align='middle' type='flex'>
                                <Col span={12}>
                                    <Progress
                                        percent={getReadPercent()}
                                        strokeWidth={8}
                                        type='circle'
                                        width={90}
                                    />
                                </Col>
                                <Col span={12}>
                                    <div className='notification-status-modal-title'>開封者数</div>
                                    <div className='notification-status-modal-value'>
                                        {targetUsersList.readCount}名
                                    </div>
                                    <div className='notification-status-modal-title'>未開封者数</div>
                                    <div className='notification-status-modal-value'>
                                        {targetUsersList.unreadCount}名
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
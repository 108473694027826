import { createAction } from 'redux-actions';
import {
    API_OGP,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/OGP';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
};

export const viewData = createAction(
    createActionType('VIEW_DATA' as const),
    (url) => {
        const data = { url };
        return ({ method: 'post', data, api: `${API_OGP}` });
    },
    (url) => ({ fetch: true, status: StatusEnum.REQUEST }),
);
import {
    Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AppSelectWithGroup from './AppSelectWithGroup';
import AppSelectWithoutGroup from './AppSelectWithoutGroup';
import EduMallSelect from './EduMallSelect';
import { TARGET } from '../../Common/UsageDataProvider';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;


const DetailTargetSelect = ({form, source, user, target}) => {
    const nonTitleInputFormItemLayout = {
        wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
        md: {
            span: 24,
            offset: 0,
        },
        xl: {
            span: 20,
            offset: 4,
        },
        },
    };

    switch (target) {
        case TARGET.USE_APP: {
            return (
                <Form.List
                    className='search-non_title_input'
                    name='targetDetails'
                >
                    {(fields, {add, remove})=>
                        <>
                            {fields.map(field => (
                                <Form.Item
                                    key={field.key}
                                    name='app'
                                    {...nonTitleInputFormItemLayout}
                                >
                                    <AppSelect
                                        name={field.name}
                                        isControllableUser={source.isControllableUser}
                                        form={form}
                                        source={source}
                                        user={user}
                                        onRemove={()=>remove(field.name)}
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item
                                name='addTarget'
                                className='search-add_target_button'
                                {...nonTitleInputFormItemLayout}
                            >
                                <Button
                                    type='dashed'
                                    icon={<PlusOutlined />}
                                    loading={Object.values(source.organizationAppLists).map(e=>e.loading).some(e=>e)}
                                    onClick={()=>add({
                                        group: undefined,
                                        app: undefined,
                                    })}
                                    block
                                />
                            </Form.Item>
                        </>
                    }
                </Form.List>
            );
        }
        case TARGET.EDUMALL: {
            return (
                <Form.List
                    name='targetDetails'
                    className='search-non_title_input'
                    {...nonTitleInputFormItemLayout}
                >
                    {(fields, {add, remove})=>
                        <>
                            {fields.map(field => (
                                <Form.Item
                                    key={field.key}
                                    name='edumall'
                                    {...nonTitleInputFormItemLayout}
                                >
                                    <EduMallSelect
                                        name={field.name}
                                        form={form}
                                        edumallAppLists={source.edumallAppLists}
                                        onRemove={()=>remove(field.name)}
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item
                                name='addTarget'
                                className='search-add_target_button'
                                {...nonTitleInputFormItemLayout}
                            >
                                <Button
                                    type='dashed'
                                    icon={<PlusOutlined />}
                                    loading={Object.values(source.edumallAppLists).map(e=>e.loading).some(e=>e)}
                                    onClick={()=>add({
                                        group: undefined,
                                        app: undefined,
                                    })}
                                    block
                                />
                            </Form.Item>
                        </>
                    }
                </Form.List>
            );
        }
        default:
            return <></>
    }
}

export default DetailTargetSelect;

const AppSelect = ({isControllableUser, name, form, onRemove, source, user}) =>
    isControllableUser
        ?
            <AppSelectWithGroup
                name={name}
                form={form}
                organizationList={source.organizationList}
                organizationAppLists={source.organizationAppLists}
                onRemove={onRemove}
            />
        :
            <AppSelectWithoutGroup
                name={name}
                form={form}
                organizationUuid={user.payload.organization.uuid}
                organizationAppLists={source.organizationAppLists}
                onRemove={onRemove}
            />
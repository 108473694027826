import * as React from 'react';
import { Menu, MenuProps, Skeleton } from 'antd';

type Props = {
    loading: boolean
} & MenuProps;

/**
 * antd の Menu にローディング機能をつけたコンポーネント
 */
const CustomeMenu: React.FC<Props> = props => {
    const { children, loading, ...menuProps } = props;

    const contents = loading ? MenuSkeltons : children;

    return (
        <div className='custom-menu'>
            <Menu {...menuProps}>
                {contents}
            </Menu>
        </div>
    );
}

export default CustomeMenu;

const avatarIcon = (
    <Skeleton.Avatar
        active
        size='small'
        shape='circle'
        style={{ alignSelf: 'center', verticalAlign: 'middle' }}
    />
);

const MenuSkeltons = [...Array(8)].map((_, index) => (
    <Menu.Item
        key={`${index}`}
        icon={avatarIcon}
        style={{ height: '100%' }}
    >
        <div style={{ height: '54px', alignItems: 'center', display: 'flex' }}>
            <Skeleton paragraph={{ rows: 1, width: 100 }} title={false} active />
        </div>
    </Menu.Item>
));

import React, { useEffect, VFC } from 'react';

import { Select } from 'antd';

import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { useTargetSchoolClass } from '../../hooks/useTargetSchoolClass';
import { ALL_CLASS_VALUE } from './SearchForm';

type Props = {
    currentTabKey: TabKeys | ''
    onChange: (uuid: string) => void
    uuid: string
    value?: string
};

export const ClassFilter: VFC<Props> = ({
    currentTabKey,
    onChange,
    uuid,
    value,
}) => {
    const [targetSchoolClass, fetchTargetSchoolClass] = useTargetSchoolClass();

    useEffect(() => {
        fetchTargetSchoolClass(uuid, currentTabKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTargetSchoolClass, uuid]);

    const handleChange = (uuid: string) => {
        onChange(uuid  === ALL_CLASS_VALUE ? '' : uuid);
    };

    const list = [{ name: '全クラス', uuid: ALL_CLASS_VALUE }].concat(targetSchoolClass.result.items);

    return (
        <div>
            <span style={{ marginRight: '0.5rem' }}>比較するクラス</span>
            <Select
                defaultValue={value ?? ALL_CLASS_VALUE}
                disabled={targetSchoolClass.isLoading}
                loading={targetSchoolClass.isLoading}
                onChange={handleChange}
                style={{ minWidth: '200px' }}
            >
                {list.map((item) => (
                    <Select.Option key={item.uuid} value={item.uuid}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

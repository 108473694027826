import * as React from 'react';

import { InfoCircleOutlined, SelectOutlined } from '@ant-design/icons';
import {
    Breadcrumb,
    Col,
    Divider,
    Row,
    Tooltip,
    Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { BaseTable } from 'components/modules/BaseTable';
import { SideHeadTable } from 'components/modules/SideHeadTable';
import { getMoment } from 'constants/GlobalConfig';

import { usePagination } from '../../hooks/usePagination';
import { ScoringStatusTag } from './ScoringStatusTag';
import { TestInformationTable } from './TestInformationTable';

import type { BreadCrumbType } from '.';
import type { RequestResultExam, ResultExamState } from '../../hooks/useResultExam';
import type { RequestStatisticsExam, StatisticsExamState } from '../../hooks/useStatisticsExam';


const moment = getMoment();

// antd の Tree data の表示を使うための型
type DataSource = ExamCategoryTabState['modal']['testView']['users'][number];

type Props = {
    handleClickBreadCrumb: (type: BreadCrumbType, name?: string, uuid?: string) => void
    handleShowStudentView: (name: string, uuid: string) => void
    onReloadResult: (exam: Parameters<RequestStatisticsExam>[0], latest_result_only?: boolean) => void
    record: ResultExamState
    requestResultExam: RequestResultExam
    statisticsExam: StatisticsExamState
    tabName: 'board' | 'school' | undefined
}

const overViewLabels = (latestResultOnly: boolean): ColumnsType<Exam.Statistics> => ([
    {
        render: (_, record) => {
            return `${record.resultCount ?? ''}名 / ${record.targetUserCount}名`;
        },
        title: (
            <>
                <span style={{ marginRight: '0.5em' }}>
                    解答者数
                </span>
                <Tooltip
                    title={
                        'このテストの解答が完了したユーザー数です。' +
                        'このテストを複数回受検したユーザーがいる場合でも、1名と数えます。' +
                        '右側の数字はこのテストグループの配信対象ユーザーの人数です。'
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
    },
    {
        dataIndex: 'resultHistoryCount',
        render: (resultHistoryCount) => {
            return `${resultHistoryCount ?? 0}回`;
        },
        title: (
            <>
                <span style={{ marginRight: '0.5em' }}>
                    解答回数
                </span>
                <Tooltip
                    title={
                        'このテストグループ内のこのテストに解答があった回数です。' +
                        'このテストに複数回解答したユーザーがいる場合、それらすべての解答がカウントされます。'
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
    },
    {
        dataIndex: 'maxScore',
        render: (maxScore) => `${maxScore ?? ''}`,
        title: '満点',
    },
    {
        dataIndex: 'averageScore',
        render: (averageScore) => `${averageScore ?? ''}`,
        title: (
            <>
                <span style={{ marginRight: '0.5em' }}>
                    平均点
                </span>
                <Tooltip
                    title={
                        'このテストグループ内のこのテストの平均点です。' + (
                            latestResultOnly ?
                                'このテストに複数回解答したユーザーがいる場合、最新の得点のみが反映されます。' :
                                'このテストに複数回解答したユーザーがいる場合、それらすべての得点が反映されます。'
                        )
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
    },
    {
        dataIndex: 'highestScore',
        render: (highestScore) => `${highestScore ?? ''}`,
        title: (
            <>
                <span style={{ marginRight: '0.5em' }}>
                    最高点
                </span>
                <Tooltip
                    title={
                        'このテストグループ内のこのテストへの解答のうち、最も高い得点です。' + (
                            latestResultOnly ?
                                'このテストに複数回解答したユーザーがいる場合、最新の得点のみが反映されます。' :
                                ''
                        )
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
    },
    {
        dataIndex: 'lowestScore',
        render: (lowestScore) => `${lowestScore ?? ''}`,
        title: (
            <>
                <span style={{ marginRight: '0.5em' }}>
                    最低点
                </span>
                <Tooltip
                    title={
                        'このテストグループ内のこのテストへの解答のうち、最も低い得点です。' + (
                            latestResultOnly ?
                                'このテストに複数回解答したユーザーがいる場合、最新の得点のみが反映されます。' :
                                ''
                        )
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
    },
]);

export const TestView: React.VFC<Props> = ({
    handleClickBreadCrumb,
    handleShowStudentView,
    onReloadResult,
    record,
    requestResultExam,
    statisticsExam,
    tabName,
}) => {
    const [latestResultOnly, setLatestResultOnly] = React.useState(false);

    const statistics = statisticsExam.result[record.exam?.uuid ?? ''];

    const [pagination, setPagination] = usePagination({
        onChange: (page, pageSize) => {
            setPagination(pagination => ({ ...pagination, current: page, pageSize }));

            requestResultExam(
                statistics?.exam?.uuid ?? '', // 通常空になることはない
                statistics?.exam?.title ?? '',
                { page, pageSize },
                tabName
            );
        },
        pageSize: 10,
    });

    React.useEffect(() => {
        setPagination(pagination => ({ ...pagination, total: record.result?.total ?? 0 }));
    }, [record.result?.total, setPagination]);

    const onClickAllBreadCrumb = () => {
        handleClickBreadCrumb('List');
    };

    const handleReloadResult = (latestResultOnly: boolean) => {
        onReloadResult(statistics?.exam ?? '', latestResultOnly);
        setLatestResultOnly(latestResultOnly);
    };

    const columnsTest: ColumnsType<DataSource> = [
        {
            key: 'name',
            render: (_, record) => {
                const existExamResult = record.score != null; // null undefined以外はtrue
                return (
                    <Tooltip
                        mouseEnterDelay={1}
                        title={existExamResult ? '生徒ごとのビューを開く' : 'テスト結果が存在しません'}
                    >
                        <button
                            className={existExamResult ? 'text-button' : 'text-button disabled'}
                            disabled={!existExamResult}
                            onClick={() => {
                                handleShowStudentView(
                                    record.name,
                                    record.uuid
                                );
                            }}
                        >
                            {record.name}
                        </button>
                    </Tooltip>
                );
            },
            title: '氏名',
        },
        {
            key: 'score',
            render: (_, record) => {
                return (
                    <>
                        <span style={{ marginRight: '1rem' }}>
                            {`${record.score ?? '-'} / ${record.maxScore ?? '-'}`}
                        </span>
                        {
                            isDisplayDetailResultLink(record) && (
                                <a href={record.resultUrl} rel='noreferrer' target='_blank'>
                                    <Tooltip title='複数回このテストに解答した場合、最後の解答内容が表示されます'>
                                        <SelectOutlined />
                                    </Tooltip>
                                </a>
                            )
                        }
                    </>
                );
            },
            title: (
                <>
                    <span style={{ marginRight: '0.5em' }}>
                        得点/満点
                    </span>
                    <Tooltip
                        title='複数回解答した場合、最新の得点・満点が表示されます'
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </>
            ),
        },
        {
            key: 'gradingProgress',
            render: (_, record) => (
                <ScoringStatusTag status={record.gradingProgress} />
            ),
            title: '採点状況',
        },
        {
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => {
                if (createdAt) {
                    return (<span>{moment.unix(createdAt).format('YYYY/MM/DD HH:mm')}</span>);
                } else {
                    return '-';
                }
            },
            title: '完了時刻',
        },
    ];

    return (
        <div>
            <Breadcrumb separator='>' style={{ marginBottom: '1rem' }}>
                <Breadcrumb.Item>
                    <button
                        className='text-button'
                        onClick={onClickAllBreadCrumb}
                    >
                        全員・すべてのテスト
                    </button>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {statistics?.exam?.title}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ flexGrow: 1 }}>
                <Typography.Title level={3}>
                    {statistics?.exam?.title}
                </Typography.Title>
            </div>

            <Divider style={{ marginBottom: '2rem', marginTop: '0.5rem' }} />

            <Row gutter={16}>
                <Col
                    lg={24}
                    md={24}
                    sm={24}
                    xl={16}
                    xs={24}
                >
                    <Typography.Text style={{ margin: '0.5rem' }}> 解答状況一覧 </Typography.Text>

                    <BaseTable
                        columns={columnsTest}
                        dataSource={record.result?.users ?? []}
                        emptyDescription=''
                        loading={record.isLoading} // 通常空になることはない
                        pagination={pagination}
                        rowKey={exam => exam.uuid}
                    />
                </Col>
                <Col
                    lg={24}
                    md={24}
                    sm={24}
                    xl={8}
                    xs={24}
                >
                    <SideHeadTable
                        isLoading={statisticsExam.isLoading}
                        onReloadResult={handleReloadResult}
                        style={{ marginBottom: '1rem' }}
                        tableContent={
                            columnsTypeToSideHeadTableContents<Exam.Statistics>(
                                overViewLabels(latestResultOnly),
                                // TODO: めんどうなので assign で対応
                                [statistics?.statistics] as [Exam.Statistics] | [undefined],
                            )
                        }
                    />
                    <TestInformationTable
                        fullScore={statistics?.statistics?.maxScore ?? 0}
                        isLoading={statisticsExam.isLoading}
                        isUsable={!!statistics?.exam?.resultUrl}
                        maxAttempts={statistics?.exam?.maxAttempts ?? 0}
                        publishEnd={statistics?.exam?.endAt ?? 0}
                        publishStart={statistics?.exam?.startAt ?? 0}
                        title={statistics?.exam?.title ?? ''}
                    />
                </Col>
            </Row>
        </div>
    );
};

// ColumnsType<RecordType> を SideHeadTable.Contents に変換する処理
// 一旦, 最低限動く形で実装
function columnsTypeToSideHeadTableContents<
    T extends Record<string, unknown>
>(
    columnsType: ColumnsType<T>,
    records: T[] | undefined[]
) {
    return records.map(record => {
        return columnsType.map((col, i) => {
            if (col.render === undefined) { return undefined; }
            if (record === undefined) {
                return {
                    data: '',
                    header: col.title,
                };
            }

            let value: unknown = undefined;
            if ('dataIndex' in col) {
                const dataIndex = col.dataIndex;

                if (dataIndex === undefined) {
                    value = undefined;
                } else {
                    if (typeof dataIndex === 'string' || typeof dataIndex === 'number') {
                        value = record[dataIndex];
                    } else {
                        value = dataIndex.map(idx => record[idx]);
                    }
                }
            } else {
                value = undefined;
            }

            return {
                data: col.render(value, record, i),
                header: col.title,
            };
        }).filter((item): item is {
            header: string;
            data: string; // 本当はもっと複雑な型だが, SideHeadTable に合わせている
        } => typeof item !== 'undefined');
    })[0];
}

/**
 * 詳細な結果を開くリンクを表示するか
 */
// 一度でもテストを受けていてなおかつ result_url がある場合に表示
function isDisplayDetailResultLink(record: DataSource) {
    return !!record.createdAt && !!record.resultUrl;
}

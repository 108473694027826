import { useEffect, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import * as Actions from 'flex/Actions';

const FOREGROUND_JOB_TYPE = {};
const FOREGROUND_JOB_TYPE_ARR = Object.values(FOREGROUND_JOB_TYPE)

/** フォアグラウンドで処理すべきTypeのjobかどうか  */
const isForegroundJobType = (type) => {
    return FOREGROUND_JOB_TYPE_ARR.includes(type);
}

export default function JobEffectComponent() {
    const dispatch = useDispatch();
    const jobData = useSelector(state => state.jobData);
    const jobViewConnection = useSelector(state => state.jobViewConnection);

    const isFirstRender = useRef(false);

    function fetchJobStatus(uuid) {
        if (uuid) {
            dispatch(Actions.http.connection.job.view(uuid));
        }
    };

    let timeout = null;

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    //jobData更新時の処理
    useEffect(() => {
        if (!isFirstRender.current) {
            if (jobData.payload.uuid) {
                if (jobData.meta.jobWaiting) {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(() => fetchJobStatus(jobData.payload.uuid), 3000);
                }
            }
        }
    }, [jobData, dispatch])

    useEffect(() => {
        if (!isFirstRender.current) {
            if(!jobViewConnection.meta.fetch){
                if(jobViewConnection.payload?.result && isForegroundJobType(jobViewConnection.payload.result.type)){
                    const payload = Object.assign(jobViewConnection.payload.result, {details: jobViewConnection.payload.details});
                    dispatch(Actions.data.jobData(payload, jobViewConnection.payload.result.is_success === null, jobViewConnection.payload.gmt));
                }else if(!jobViewConnection.payload?.result){
                    const payload = {details: jobViewConnection.payload.details};
                    dispatch(Actions.data.jobData(payload, false ,jobViewConnection.payload.gmt));
                }
            }
        }
    }, [jobViewConnection, dispatch]);

    useEffect(() => {
        isFirstRender.current = false;
    }, [dispatch]);

    return null;
};

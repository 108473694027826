import React, { useEffect, useRef, VFC } from 'react';

import { BarChartOutlined } from '@ant-design/icons';

import { JOB_TYPE } from 'components/modules/Job/JobModal';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys, TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import { usePagination } from '../../hooks/usePagination';
import { ExamCategoryTable } from './ExamCategoryTable';
import { Graph } from './Graph';
import { useDistributionGraph } from './hooks/useDistributionGraph';
import { useGraphExam } from './hooks/useGraphExam';
import { useGraphExamCategory } from './hooks/useGraphExamCategory';
import { useReportDetail } from './hooks/useReportDetail';
import { useResultItem } from './hooks/useResultItem';
import { useSaveAllReport } from './hooks/useSaveAllReport';
import { useSaveComment } from './hooks/useSaveComment';
import { useSaveReport } from './hooks/useSaveReport';
import { SearchExamCategoryWithResultState } from './hooks/useSearchWithResultExamCategory';
import { useSearchWithResultExamCategory } from './hooks/useSearchWithResultExamCategory';
import { useSubjectList } from './hooks/useSubjectList';
import { useUserItemResult } from './hooks/useUserItemResult';
import { ViewAllTestResultModal } from './modals/ViewAllTestResultModal';
import { ALL_CLASS_VALUE, ALL_SCHOOL_VALUE } from './modals/ViewAllTestResultModal/SearchForm';
import { FormValues, SearchForm, INIT_FORM_VALUES } from './SearchForm';

type ExamWithResult = SearchExamCategoryWithResultState['items'][number]['exams'][number];


type Props = {
    currentTabKey: TabKeys | ''
    tabKey?: TabKeys
}

export const ExamResultTemplate: VFC<Props> = ({
    currentTabKey,
    tabKey = '' as const,
}) => {
    const GRAPH_PAGE_SIZE_NUM = 10000;
    const [searchCondition, setSearchCondition] = React.useState<FormValues>(INIT_FORM_VALUES);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [exam, setExam] = React.useState<ExamWithResult>();
    const [class2Uuid, setClass2Uuid] = React.useState<string>();
    const [organization2Uuid, setOrganization2Uuid] = React.useState<string>();
    const [pagination, setPagination] = usePagination({
        onChange: (page: number, pageSize: number) => {
            setPagination(pagination => ({ ...pagination, current: page, pageSize }));
            searchWithResult(searchCondition, page, pageSize);
        },
    });

    const [stateWithResult, searchWithResult] = useSearchWithResultExamCategory(
        tabKey,
        (meta, payload) => {
            setPagination(pagination => ({
                ...pagination,
                total: payload?.item_count ?? 0,
            }));
        }
    );
    const [graphState, fetchGraphData] = useGraphExamCategory(tabKey);
    const [subjectList, fetchSubjectList] = useSubjectList();
    const [, saveReport] = useSaveReport(tabKey);
    const [, saveAllReport] = useSaveAllReport(tabKey);
    const [resultItem, fetchResultItem] = useResultItem();
    const [distributeGraph, fetchDistributeGraph] = useDistributionGraph();
    const [distributeGraph2, fetchDistributeGraph2] = useDistributionGraph();
    const [graphExam, fetchGraphExam] = useGraphExam();
    const [graphExam2, fetchGraph2Exam] = useGraphExam();
    const [reportDetail, fetchReportDetail] = useReportDetail();
    const [reportDetail2, fetchReportDetail2, resetReportDetail2] = useReportDetail();

    const [userResultItemState, fetchUserResultItemState] = useUserItemResult();
    const [saveComment, fetchSaveComment, resetSaveComment] = useSaveComment();

    const jobViewConnection = useAppSelector(state => state.jobViewConnection);

    // 動作の確認ができないので、実績のある実装と同じようにしておく
    // src\components\presentational\pages\Organization\OrganizationList.tsx
    const isFirstRender = useRef(true);

    useEffect(() => {
        searchWithResult(INIT_FORM_VALUES);
        fetchGraphData(INIT_FORM_VALUES, 1, GRAPH_PAGE_SIZE_NUM);
        fetchSubjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // バックグラウンドジョブの完了後リロード
        if (isFirstRender.current) return;
        if (jobViewConnection.meta.status !== Actions.statusEnum.SUCCESS) return;
        if (!jobViewConnection.payload?.result?.is_success) return;
        if (JOB_TYPE.SAVE_EXAM_REPORT !== jobViewConnection.payload?.result?.type) return;

        const endpointPrefix = tabKey === TAB_NAMES.SCHOOL ?
            'admin' :
            tabKey === TAB_NAMES.BOARD_OF_EDUCATION ?
                'control' :
                '';
        const url = jobViewConnection.payload?.details?.url as string | undefined;
        if (!url || !url.includes(endpointPrefix)) return;

        setPagination(pagination => ({ ...pagination, current: 1 }));
        searchWithResult(searchCondition, 1, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobViewConnection]);

    useEffect(() => {
        if (currentTabKey !== tabKey) return;
        if (distributeGraph.isLoading) return;
        if (class2Uuid === undefined) return;
        if (exam === undefined) return;

        fetchDistributeGraph2(exam.uuid, class2Uuid, tabKey, organization2Uuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distributeGraph.isLoading]);

    useEffect(() => {
        if (currentTabKey !== tabKey) return;
        if (graphExam.isLoading) return;
        if (class2Uuid === undefined) return;
        if (exam === undefined) return;

        fetchGraph2Exam(exam.uuid, class2Uuid, tabKey, organization2Uuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphExam.isLoading]);

    useEffect(() => {
        if (currentTabKey !== tabKey) return;
        if (reportDetail.isLoading) return;
        if (class2Uuid === undefined) return;
        if (exam === undefined) return;

        fetchReportDetail2(exam.uuid ?? '', class2Uuid, tabKey, organization2Uuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportDetail.isLoading]);

    useEffect(() => {
        if (distributeGraph2.isLoading || graphExam2.isLoading) return;
        setClass2Uuid(undefined);
    }, [distributeGraph2.isLoading, graphExam2.isLoading]);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    const handleSearch = (value: FormValues) => {
        setSearchCondition(value);
        setPagination(pagination => ({
            ...pagination,
            current: 1,
            onChange: (page: number, pageSize: number) => {
                setPagination(pagination => ({ ...pagination, current: page, pageSize }));
                searchWithResult(value, page, pageSize);
            },
        }));
        searchWithResult(value, 1, pagination.pageSize);
        fetchGraphData(value, 1, GRAPH_PAGE_SIZE_NUM);
    };

    const handleClickViewAllTestResult = (exam: ExamWithResult) => {
        fetchReportDetail(exam.uuid, '', tabKey);
        fetchDistributeGraph(exam.uuid, '', tabKey);
        fetchGraphExam(exam.uuid, '', tabKey);

        setExam(exam);
        setIsModalOpen(true);
    };

    const handleShowGraph = (
        type: 'question' | 'user',
        uuid: string,
        targetParam: {organizationId: string, classId: string} | {classId: string},
        page: number,
        pageSize?: number
    ) => {
        const classUuid = targetParam.classId === ALL_CLASS_VALUE ? '' : targetParam.classId;
        const organizationId = 'organizationId' in targetParam ?
            targetParam.organizationId === ALL_SCHOOL_VALUE ?
                '' :
                targetParam.organizationId :
            '';

        switch (type) {
            case 'user':
                fetchResultItem(uuid, classUuid, page, pageSize ?? 10, tabKey);
                break;
            case 'question':
                fetchGraphExam(uuid, classUuid, tabKey, organizationId);
                break;

            default:
                break;
        }
    };

    type ModalSearchParam = {
        type: 'question' | 'user' | '',
        uuid: string,
        target1Param: {organizationId: string, classId: string} | {classId: string},
        target2Param?: {organizationId: string, classId: string} | {classId: string},
        page?: number,
        pageSize?: number
    };
    const handleModalSearch = ({
        type,
        uuid,
        target1Param,
        target2Param,
        page = 1,
        pageSize,
    }: ModalSearchParam) => {
        const classUuid = target1Param.classId === ALL_CLASS_VALUE ? '' : target1Param.classId;
        const organizationUuid = 'organizationId' in target1Param ?
            target1Param.organizationId === ALL_SCHOOL_VALUE ?
                '' :
                target1Param.organizationId :
            '';

        const classUuid2 = target2Param?.classId === ALL_CLASS_VALUE ? '' : target2Param?.classId;
        const organizationUuid2 = target2Param && 'organizationId' in target2Param ?
            target2Param.organizationId === ALL_SCHOOL_VALUE ?
                '' :
                target2Param.organizationId :
            '';

        resetReportDetail2();

        setClass2Uuid(classUuid2);
        setOrganization2Uuid(organizationUuid2);
        fetchReportDetail(uuid ?? '', classUuid, tabKey, organizationUuid);
        fetchDistributeGraph(uuid, classUuid, tabKey, organizationUuid);

        if (type !== '') {
            handleShowGraph(type, uuid, target1Param, page, pageSize);
        }
    };

    const handleCloseModal = () => {
        resetReportDetail2();
        resetSaveComment();
        setIsModalOpen(false);
    };

    return (
        <div
            className='tab-contents answer-results'
            style={{
                padding: '32px',
            }}
        >
            <div style={{ marginBottom: '1rem' }}>
                <SearchForm
                    currentTabKey={tabKey}
                    isLoading={subjectList.isLoading}
                    onSearch={handleSearch}
                    subjectList={subjectList.items}
                />
            </div>

            <div style={{ marginBottom: '1rem' }}>
                <div className='subtitle'>
                    <BarChartOutlined /> 受検回数
                </div>
                <Graph
                    dataSources={graphState.result}
                    isLoading={graphState.isLoading}
                />
            </div>

            <div style={{ marginBottom: '1rem' }}>
                一覧には、すでに回答があるMEXCBTテストのみが表示されます。
            </div>

            <div>
                <ExamCategoryTable
                    dataSource={stateWithResult}
                    onClickSaveAllReport={saveAllReport}
                    onClickSaveReport={saveReport}
                    onClickViewAllTestResult={handleClickViewAllTestResult}
                    pagination={pagination}
                />
            </div>

            <ViewAllTestResultModal
                commentResponse={saveComment}
                currentTabKey={tabKey}
                distributeGraph={distributeGraph}
                distributeGraph2={distributeGraph2}
                exam={exam}
                graph={graphExam}
                graph2={graphExam2}
                isLoadingComment={saveComment.isLoading}
                isOpen={isModalOpen}
                onChangeGraphType={handleShowGraph}
                onClickSaveComment={fetchSaveComment}
                onClickUserName={fetchUserResultItemState}
                onClose={handleCloseModal}
                onSearch={handleModalSearch}
                reportDetail={reportDetail}
                reportDetail2={reportDetail2}
                resultItem={resultItem}
                userResultItemState={userResultItemState}
            />
        </div>
    );
};
import { forwardRef, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Row,
    Typography,
} from 'antd';
import { useUsageData } from '../Common/UsageDataProvider';
import Graph from './Graph';
import SummaryCard from './SummaryCard';
import TotalTable from './TotalTable';
import DetailHistory from './DetailHistory';
import BaseModal from 'components/modules/BaseModal';
const { Text } = Typography;

interface Props {
    appName: string,
    current: string,
    data: unknown,
    mean: number,
    term: string,
    visible: boolean,
}

const PdfViewModal = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { appName, current, data, mean, term, visible } = props;
    const [source,] = useUsageData();
    const [isView, setIsView] = useState(true);

    useEffect(() => {
        if (visible) {
            // モーダル表示直後にグラフを表示すると,
            // レイアウトが崩れるので,
            // モーダルが完全に表示された後にグラフが表示されるように調整
            setTimeout(() => setIsView(false), 500);
        } else {
            setIsView(true);
        }
    }, [visible]);

    return (
        <BaseModal
            className='full-view-modal'
            closable={false}
            footer={null}
            forceRender
            title='印刷プレビュー'
            visible={visible}
        >
            <div
                className='pdf-view'
                ref={ref}
            >
                <Row>
                    <Col span={24} className='summary-contents'>
                        <SummaryCard
                            style={{ marginBottom: '1rem' }}
                            appName={appName}
                            current={current}
                            mean={mean}
                            term={term}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className='summary-contents'>
                        <Row className='summary-title'>
                            <Col span={2} className='summary-title-operator-left'>
                                <Button htmlType='button' disabled={true}>
                                    {'<'}
                                </Button>
                            </Col>
                            <Col span={20} className='summary-title-text'>
                                <Text>{term} 利用履歴</Text>
                            </Col>
                            <Col span={2} className='summary-title-operator-right'>
                                <Button htmlType='button' disabled={true}>
                                    {'>'}
                                </Button>
                            </Col>
                        </Row>
                        <Row className='summary-graph'>
                            <Graph
                                data={data}
                                mean={mean}
                                loading={isView}
                            />
                        </Row>
                    </Col>
                </Row>

                <TotalTable
                    isAllLinkDisabled={true}
                />

                {
                    (source.isControllableUser && source.hierarchy?.length === 4) ||
                        (!source.isControllableUser && source.hierarchy?.length === 3) ?
                        <DetailHistory /> :
                        <></>
                }
            </div>
        </BaseModal>
    )
});

export default PdfViewModal;

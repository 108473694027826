import * as React from 'react';
import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

type Props = {
    className?: string;
    message: string;
    onClick: () => void;
    showIcon: boolean;
    style?: React.CSSProperties;
    type: 'success' | 'info' | 'warning' | 'error';
};

const ClickableAlert = (props: Props) => {
    const {
        className,
        message,
        onClick,
        showIcon,
        style,
        type,
    } = props;

    return (
        <button
            className={className}
            style={{
                padding: 0,
                textAlign: 'left',
                width: '100%',
                ...style,
            }}
            onClick={onClick}
        >
            <Alert
                message={message}
                type={type}
                showIcon={showIcon}
                icon={<InfoCircleFilled  style={{color:'#ff4d4f'}}/>}
            />
        </button>
    );
}

export default ClickableAlert;
import { useState, useEffect } from 'react';
import { Radio, Input, Button, Select, DatePicker } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'flex/Actions';
import {
    questionnaireTabSelectors,
} from 'flex/view/Questionnaire/QuestionnaireTab/selectors';
import isTouchDevice from 'is-touch-device';
import { ControlledIcon } from 'components/modules/PublishedItems.tsx';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;
const { Option } = Select;

const layout = {
    labelCol: {
        sm: { span: 7 },
        xl: { span: 5 },
        xxl: { span: 4 },
    },
    wrapperCol: {
        span: 24,
    },
};

const QuestionnaireSearch = () => {
    const [form] = Form.useForm();
    const { buttonLoading, categorySelectLoading } = useSelector(questionnaireTabSelectors.loading);
    const questionnaireCategoryList = useSelector(state => state.questionnaireTab.questionnaireCategoryList);
    const [isFullSearch, setIsFullSearch] = useState(false);
    const [durationPublishStart, setPublishDurationStart] = useState(undefined);
    const [durationPublishEnd, setPublishDurationEnd] = useState(undefined);
    const [durationPerformStart, setPerformDurationStart] = useState(undefined);
    const [durationPerformEnd, setPerformDurationEnd] = useState(undefined);
    const [durationResultStart, setDurationResultStart] = useState(undefined);
    const [durationResultEnd, setDurationResultEnd] = useState(undefined);
    const [selectIsPublic, setSelectIsPublic] = useState(undefined);
    const [selectIsPublicResult, setSelectIsPublicResult] = useState(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isFullSearch) {
            form.setFieldsValue({
                'is_public': selectIsPublic,
                'is_public_result': selectIsPublicResult,
            });
        }
    }, [isFullSearch])// eslint-disable-line

    function onCategoryChange(value) {
        form.setFieldsValue({ category: value });
    }

    function onFinish(values) {
        dispatch(Actions.view.viewLogic.questionnaireTab.submitSearch(values));
    }

    function onReset() {
        form.resetFields();
        setPublishDurationStart(undefined);
        setPublishDurationEnd(undefined);
        setPerformDurationStart(undefined);
        setPerformDurationEnd(undefined);
        setDurationResultStart(undefined);
        setDurationResultEnd(undefined);
        setSelectIsPublic(undefined);
        setSelectIsPublicResult(undefined);
    }

    function onModeChange(e) {
        setIsFullSearch(e.target.value === 'full');
    }

    function disabledStartDatePublish(currentDate) {
        if (!durationPublishEnd) return false;
        return currentDate.valueOf() >= durationPublishEnd.valueOf();
    }

    function disabledEndDatePublish(currentDate) {
        if (!durationPublishStart) return false;
        return currentDate.valueOf() <= durationPublishStart.valueOf();
    }

    function disabledStartDatePerform(currentDate) {
        if (!durationPerformEnd) return false;
        return currentDate.valueOf() >= durationPerformEnd.valueOf();
    }

    function disabledEndDatePerform(currentDate) {
        if (!durationPerformStart) return false;
        return currentDate.valueOf() <= durationPerformStart.valueOf();
    }

    function disabledStartDateResult(currentDate) {
        if (!durationResultEnd) return false;
        return currentDate.valueOf() > durationResultEnd.valueOf();
    }

    function disabledEndDateResult(currentDate) {
        if (!durationResultStart) return false;
        return currentDate.valueOf() <= durationResultStart.valueOf();
    }

    function handleIsPublicChange(e) {
        const value = e.target.value;
        setSelectIsPublic(value);
        form.setFieldsValue({ is_public: value });
    }
    function handleIsPublicResultChange(e) {
        const value = e.target.value;
        setSelectIsPublicResult(value);
        form.setFieldsValue({ is_public_result: value });
    }

    return (
        <div className='card general-search questionnaire-search-form-wrapper'>
            <div className='mb-8'>
                <Radio.Group onChange={onModeChange} defaultValue='simple' buttonStyle='solid'>
                    <Radio.Button value='simple'>シンプルな検索</Radio.Button>
                    <Radio.Button value='full'>詳細検索</Radio.Button>
                </Radio.Group>
            </div>

            <Form {...layout} form={form} name='control-hooks-questionnaire-search' onFinish={onFinish}>
                <Form.Item name='category_uuid' label='カテゴリ'>
                    <Select
                        placeholder='カテゴリを選択してください'
                        loading={categorySelectLoading}
                        disabled={categorySelectLoading}
                        onChange={onCategoryChange}
                        allowClear
                    >
                        {questionnaireCategoryList && questionnaireCategoryList.map((v) =>
                            (<Option key={v.uuid} value={v.uuid}>{v.name} <ControlledIcon hidden={!v.is_published_item} /></Option>)
                        )}
                    </Select>
                </Form.Item>

                <Form.Item name='name' label='タイトル'>
                    <Input placeholder='タイトルを入力してください' />
                </Form.Item>

                {isFullSearch && (<>
                    <Form.Item name='publish_from' label='発信元'>
                        <Input placeholder='発信元を入力してください' />
                    </Form.Item>

                    <Form.Item name='is_anonymous' label='アンケート種別'>
                        <Radio.Group>
                            <Radio value={0}>記名式</Radio>
                            <Radio value={1}>無記名式</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name='can_be_answered_only_once' label='回答の再編集'>
                        <Radio.Group>
                            <Radio value={1}>不可</Radio>
                            <Radio value={0}>許可</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name='is_public' label='公開設定'>
                        <Radio.Group
                            value={selectIsPublic}
                            onChange={handleIsPublicChange}
                        >
                            <Radio value={1}>公開</Radio>
                            <Radio value={0}>非公開</Radio>
                            <Radio value={undefined}>指定なし</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {!!selectIsPublic && (<>
                        <Form.Item label='公開期間' extra='指定した期間に公開中となっているアンケートを対象とします。'>
                            <Input.Group compact>
                                <Form.Item noStyle name='publish_start_at'>
                                    <DatePicker
                                        locale={jaJP}
                                        showTime={{ format: 'HH:mm' }}
                                        format='YYYY/MM/DD HH:mm'
                                        disabledDate={disabledStartDatePublish}
                                        value={durationPublishStart}
                                        placeholder='開始日時'
                                        onChange={setPublishDurationStart}
                                        inputReadOnly={isTouchDevice()}
                                    />
                                </Form.Item>
                                <Form.Item noStyle name='publish_end_at'>
                                    <DatePicker
                                        locale={jaJP}
                                        showTime={{ format: 'HH:mm' }}
                                        format='YYYY/MM/DD HH:mm'
                                        disabledDate={disabledEndDatePublish}
                                        value={durationPublishEnd}
                                        placeholder='終了日時'
                                        onChange={setPublishDurationEnd}
                                        inputReadOnly={isTouchDevice()}
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item label='実施期間' extra='指定した期間に実施中となっているアンケートを対象とします。'>
                            <Input.Group compact>
                                <Form.Item noStyle name='perform_start_at'>
                                    <DatePicker
                                        locale={jaJP}
                                        showTime={{ format: 'HH:mm' }}
                                        format='YYYY/MM/DD HH:mm'
                                        disabledDate={disabledStartDatePerform}
                                        value={durationPerformStart}
                                        placeholder='開始日時'
                                        onChange={setPerformDurationStart}
                                        inputReadOnly={isTouchDevice()}
                                    />
                                </Form.Item>
                                <Form.Item noStyle name='perform_end_at'>
                                    <DatePicker
                                        locale={jaJP}
                                        showTime={{ format: 'HH:mm' }}
                                        format='YYYY/MM/DD HH:mm'
                                        disabledDate={disabledEndDatePerform}
                                        value={durationPerformEnd}
                                        placeholder='終了日時'
                                        onChange={setPerformDurationEnd}
                                        inputReadOnly={isTouchDevice()}
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </>)}

                    <Form.Item name='is_public_result' label='アンケート結果公開設定'>
                        <Radio.Group
                            value={selectIsPublicResult}
                            onChange={handleIsPublicResultChange}
                        >
                            <Radio value={1}>公開</Radio>
                            <Radio value={0}>非公開</Radio>
                            <Radio value={undefined}>指定なし</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {!!selectIsPublicResult && (<>
                        <Form.Item label='集計結果公開期間' extra='指定した期間に集計結果が公開中となっているアンケートを対象とします。'>
                            <Input.Group compact>
                                <Form.Item noStyle name={'publish_result_start_at'}>
                                    <DatePicker
                                        locale={jaJP}
                                        showTime={{ format: 'HH:mm' }}
                                        format='YYYY/MM/DD HH:mm'
                                        disabledDate={disabledStartDateResult}
                                        value={durationResultStart}
                                        placeholder='開始日時'
                                        onChange={setDurationResultStart}
                                        inputReadOnly={isTouchDevice()}
                                    />
                                </Form.Item>
                                <Form.Item noStyle name={'publish_result_end_at'}>
                                    <DatePicker
                                        locale={jaJP}
                                        showTime={{ format: 'HH:mm' }}
                                        format='YYYY/MM/DD HH:mm'
                                        disabledDate={disabledEndDateResult}
                                        value={durationResultEnd}
                                        placeholder='終了日時'
                                        onChange={setDurationResultEnd}
                                        inputReadOnly={isTouchDevice()}
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </>)}
                </>)}

                <div className='submit-container'>
                    <Form.Item >
                        <Button htmlType='button'
                            onClick={onReset}
                            loading={buttonLoading}
                        >
                            リセット
                        </Button>
                        <Button type='primary' htmlType='submit'
                            loading={buttonLoading}
                        >
                            検索
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default QuestionnaireSearch;

import { createAction } from 'redux-actions';
import {
    API_QUESTIONNAIRE_CATEGORY,
    API_CONTROL_QUESTIONNAIRE_CATEGORY,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/QUESTIONNAIRE_CATEGORY';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return ({ method: 'get', data, api: `${API_QUESTIONNAIRE_CATEGORY}/${pageNum}` })
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_QUESTIONNAIRE_CATEGORY + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_QUESTIONNAIRE_CATEGORY + '/update/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_QUESTIONNAIRE_CATEGORY + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deleteAction as delete };


////////////////////////////////
// 全体管理者
////////////////////////////////

export const controlSearch = createAction(
    createActionType('CONTROL_SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return ({
            method: 'get',
            api: `${API_CONTROL_QUESTIONNAIRE_CATEGORY}/${pageNum}`,
            data,
        });
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlCreate = createAction(
    createActionType('CONTROL_CREATE' as const),
    (data) => ({
        method: 'put',
        api: `${API_CONTROL_QUESTIONNAIRE_CATEGORY}/create`,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlUpdate = createAction(
    createActionType('CONTROL_UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: `${API_CONTROL_QUESTIONNAIRE_CATEGORY}/update/${uuid}`,
        data,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const controlDeleteAction = createAction(
    createActionType('CONTROL_DELETE' as const),
    (uuid) => ({
        method: 'delete',
        api: `${API_CONTROL_QUESTIONNAIRE_CATEGORY}/delete/${uuid}`,
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { controlDeleteAction as controlDelete };
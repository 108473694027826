import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { getMoment } from 'constants/GlobalConfig';

import { Source, TERM_TYPE } from './UsageDataProvider';
import { createTimeKeys } from './utils';
const moment = getMoment();

const NOT_FOUND_NAME = '姓名なし';


export class SourceBuilder {
    isControllableUser: boolean;
    pathname: string | undefined;
    result: UsageTotalConnection['payload']['result'] | undefined;
    source: Source | undefined;
    startAt: string | undefined;
    tenantName: string | undefined;

    private _hierarchy: Source['hierarchy'] | undefined;
    private _rankThresholds: number[] | undefined;
    private _summaryDataItems:  {
        count: number;
        name: string;
    }[] | undefined;
    private _summaryDataMean: number | undefined;
    private _totalData: Source['total']['data'] | undefined;

    constructor() {
        // source を作る上での材料となるもの
        this.isControllableUser = false;
        this.pathname = undefined;
        this.result = undefined;
        this.source = undefined;
        this.startAt = undefined;
        this.tenantName = undefined;

        // source を作る上でのパーツとなるもの
        this._hierarchy = undefined;
        this._rankThresholds = undefined;
        this._summaryDataItems = undefined;
        this._summaryDataMean = undefined;
        this._totalData = undefined;
    }

    private _isValid = () => {
        if (this.source === undefined) return false;
        if (this.isControllableUser && this.tenantName === undefined) return false;
        if (this.pathname === undefined) return false;
        if (this.result === undefined) return false;

        return true;
    }

    private _createHierarchy = () => {
        if (!this._isValidUrl(this.pathname) || this.source === undefined) {
            this._hierarchy = [];
            return;
        }

        const hierarchy = this._getHierarchyCorrespondingToUrl();
        // 型の推論のため
        if (hierarchy === undefined) return;

        if (this.source.isControllableUser) {
            hierarchy.unshift({
                name: this.tenantName,
                url: undefined,
                uuid: undefined,
            });
        }

        this._hierarchy = hierarchy
            .reduce((acc, elm, idx) => {
                const upper = acc.slice(-1)[0]?.url || '/usage';
                const directory = idx === 0 ? '' : `/${elm.uuid}`;
                const url = idx === hierarchy.length - 1 ? undefined : `${upper}${directory}`;
                return acc.concat({ ...elm, url });
            }, [] as NonNullable<Source['hierarchy']>);
    }

    private _createTotalData = () => {
        if (this.result === undefined || this.pathname === undefined || this.source === undefined) return;
        if (this._hierarchy === undefined) this._createHierarchy();
        // 型の推論のため
        if (this._hierarchy === undefined) return;

        let totalData: Source['total']['data'] = [];
        if (this._isScopeStudentUrl(this.pathname)) {
            const studentUuid = this.pathname.split('/').slice(-1)[0];
            const isStudent = (e: { uuid: string }) => e.uuid === studentUuid;
            totalData = this.result.sub_totals.filter(isStudent).map(e => ({
                ...e,
                isLink: false,
            }));
        } else {
            totalData = this.result.sub_totals.map(e => {
                const isDeleteUser = e.login_id?.match(/@.+?_.+$/);
                return {
                    isLink: !isDeleteUser,
                    login_id: isDeleteUser ? '' : e.login_id,
                    name: isDeleteUser ? '削除済みユーザー' : e.name,
                    term_name: e.term_name,
                    totals: e.totals,
                    uuid: e.uuid,
                };
            });

            let summaryRowName = <></>;

            if (this.source.isControllableUser) {
                switch (this._hierarchy.length) {
                    case 1: // 学校別
                        summaryRowName = (
                            <div>
                                <div>すべての学校</div>
                            </div>
                        );
                        break;
                    case 2: // クラス別
                        summaryRowName = (
                            <div style={{ alignItems: 'center', display: 'inline-flex' }}>
                                <div style={{ marginRight: '0.25rem' }}>すべてのクラス</div>
                                <Tooltip
                                    title='「すべてのクラス」に表示されている総数は、各クラスの利用回数の合計に一致しないことがあります。（複数のクラスに所属しているユーザーがいる場合）'
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        );
                        break;
                    case 3: // ユーザー別
                        summaryRowName = (
                            <div style={{ alignItems: 'center', display: 'inline-flex' }}>
                                <div style={{ marginRight: '0.25rem' }}>すべてのユーザー</div>
                            </div>
                        );
                        break;
                    default:
                        break;
                }
            } else {
                switch (this._hierarchy.length) {
                    case 1: // クラス別
                        summaryRowName = (
                            <div style={{ alignItems: 'center', display: 'inline-flex' }}>
                                <div style={{ marginRight: '0.25rem' }}>すべてのクラス</div>
                                <Tooltip
                                    title='「すべてのクラス」に表示されている総数は、各クラスの利用回数の合計に一致しないことがあります。（複数のクラスに所属しているユーザーがいる場合）'
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        );
                        break;
                    case 2: // ユーザー別
                        summaryRowName = (
                            <div style={{ alignItems: 'center', display: 'inline-flex' }}>
                                <div style={{ marginRight: '0.25rem' }}>すべてのユーザー</div>
                            </div>
                        );
                        break;
                    default:
                        break;
                }
            }

            totalData.unshift({
                isLink: false,
                name: summaryRowName,
                totals: this.result.all || {},
                uuid: '',
            });
        }

        if (this._isScopeSchoolClassUrl(this.pathname) || this._isScopeStudentUrl(this.pathname)) {
            totalData = totalData.map(student => {
                return {
                    ...student,
                    name: student.name || NOT_FOUND_NAME,
                };
            });
        }

        // データが無かったときのための処理
        if (totalData.length === 0) {
            totalData.push({});
        }
        this._totalData = totalData;
    }

    private _createSummaryDataItems = () => {
        if (this.source === undefined || this.result === undefined || this._totalData === undefined) return false;

        if (this._totalData === undefined) this._createTotalData();

        let summarySource: Record<string, number> | [] | undefined = this.result.all;

        if (this._isScopeStudentUrl(this.pathname)) {
            summarySource = this._totalData[0].totals;
        }

        summarySource = summarySource === undefined || Array.isArray(summarySource) ?
            {} as Record<string, number> :
            summarySource;

        const summaryDataItems = Object.fromEntries(
            createTimeKeys(this.source.term, this.source.termType).map(([backendKey, frontKey]) => {
                const val = summarySource === undefined || Array.isArray(summarySource) || summarySource[backendKey] === undefined ?
                    0 :
                    summarySource[backendKey];
                return [frontKey, val];
            })
        );

        const restrictionSummaryDataItems = Object.entries(summaryDataItems).map(([key, val]) => (
            { count: val, name: key }
        ));

        this._summaryDataItems = restrictionSummaryDataItems;
    }

    private _createSummaryDataMean = () => {
        if (this.source === undefined || this._summaryDataItems === undefined) return;

        if (this._summaryDataItems === undefined) this._createSummaryDataItems();

        const termTypeToDataCount = {
            [TERM_TYPE.YEAR]: 12,
            [TERM_TYPE.MONTH]: moment(this.startAt).daysInMonth(),
            [TERM_TYPE.WEEK]: 7,
            [TERM_TYPE.DATE]: 12,
        };
        const dataCount = termTypeToDataCount[this.source.termType];

        let summaryDataMean = 0;
        if (Object.values(this._summaryDataItems).length > 0) {
            summaryDataMean = this._summaryDataItems.map(e => e.count).reduce((e, a) => a + (e || 0)) / dataCount;
        }

        this._summaryDataMean = summaryDataMean;
    }

    private _createRankThresholds = () => {
        if (this.result === undefined) return;

        const thresholdCount = 3;
        const maxCandidates = Object.values(this.result.all);
        const max = Math.max(...maxCandidates);

        if (max <= 0) {
            this._rankThresholds = [0];
            return;
        }

        this._rankThresholds = [...Array(thresholdCount + 1)].map((_, i) => max * i / thresholdCount);
    }

    private _isScopeSchoolClassUrl = (pathname: string) => {
        if (this.source === undefined) return false;
        const uuids = pathname.split('/').slice(2);
        if (this.source.isControllableUser) {
            return uuids.length === 2;
        } else {
            return uuids.length === 1;
        }
    }

    private _isScopeStudentUrl = (pathname: string | undefined) => {
        if (this.source === undefined || pathname === undefined) return false;
        const uuids = pathname.split('/').slice(2);
        if (this.source.isControllableUser) {
            return uuids.length === 3;
        } else {
            return uuids.length === 2;
        }
    }

    private _isValidUrl = (pathname: string | undefined): pathname is string => {
        if (this.source === undefined || pathname === undefined) return false;

        const defaultLength = 2;
        const validHierarchy = this.source.isControllableUser ? 3 : 2;
        const pathLength = pathname.split('/').length;
        return defaultLength <= pathLength && pathLength <= defaultLength + validHierarchy;
    }

    private _getHierarchyCorrespondingToUrl = (): Source['hierarchy'] => {
        if (this.result?.hierarchy === undefined || this.result.hierarchy.length === 0) return [];
        if (!this._isValidUrl(this.pathname)) return [];
        if (this.source?.isControllableUser === undefined || this.source?.hierarchy === undefined) return [];

        const isFirstAccess = this.source
            .hierarchy
            .slice(1)
            .map(e => e.name)
            .filter(e => e)
            .length ===
            0;

        if (isFirstAccess) {
            return this.source.hierarchy;
        }

        const pathString = this.pathname.split('/').slice(2).join('');
        const offset = this.source.isControllableUser ? 0 : 1;
        const matchHierarch = this.result.hierarchy.filter(hierarchy => {
            return hierarchy.slice(offset).map(e => e.uuid).join('') === pathString;
        })[0];

        return matchHierarch ? matchHierarch : [];
    }

    getResult = (): Source => {
        if (!this._isValid()) throw new Error();
        // (this._isValid で検証済み)型の推論のため
        if (this.source === undefined) throw new Error();

        this._createHierarchy();
        this._createTotalData();
        this._createSummaryDataItems();
        this._createSummaryDataMean();
        this._createRankThresholds();

        return {
            ...this.source,
            hierarchy: this._hierarchy,
            loading: false,
            rankThresholds: this._rankThresholds,
            summary: {
                data: {
                    items: this._summaryDataItems ?? [],
                    mean: this._summaryDataMean,
                },
            },
            total: {
                data: this._totalData,
                loading: false,
            },
        };
    }
}
import { findCandidate } from '../Forms/findCandidate';

import type { ViewTemplate, Lti11Form, Lti13Form } from './type';

export const createInitFormValues = (record: ViewTemplate | undefined, isControllableUser: boolean) => {
    if (record) {
        return createInitFormValueFromRecord(record, isControllableUser);
    } else {
        return createInitFormValue();
    }
};

const createInitFormValue = (): Lti11Form => {
    // 本当はきちんと選択と固定で分けたほうがいい
    /* eslint-disable sort-keys */
    return {
        baseTemplateUuid: undefined,
        is_active: true,
        is_target_all: true,
        name: '',
        params: [],
        target_organization: [],

        // 選択の場合
        ltiUrlCandidateUuid: '',
        ltiUrlUuid: '',

        // 固定の場合
        ltiUrlKey: '',
        ltiUrlSecret: '',
        ltiUrlUrl: '',
    };
    /* eslint-disable sort-keys */
};

const createInitFormValueFromRecord = (template: ViewTemplate, isControllableUser: boolean): Lti11Form | Lti13Form => {
    switch (template.type) {
        case 1: {
            const {
                is_active,
                is_target_all,
                lti_app_template,
                name,
                params,
            } = template;

            const candidate = findCandidate(template);

            const param = params.filter(param => {
                return isControllableUser ?
                    param.changeable_param_control :
                    param.changeable_param_admin;
            });

            return {
                baseTemplateUuid: lti_app_template.uuid,
                is_active,
                is_target_all,
                ltiUrlCandidateUuid: candidate?.uuid ?? '',
                ltiUrlKey: template.url.key ?? '',
                ltiUrlSecret: template.url.secret ?? '',
                ltiUrlUrl: template.url.url ?? '',
                ltiUrlUuid: template.url.uuid,
                name,
                params: param.map(param => ({
                    ltiParamCandidateUuid: param.candidates.find(
                        c => param.value ? c.value === param.value : c.is_default
                    )?.uuid ?? '',
                    ltiParamUuid: param.uuid,
                    ltiParamValue: param.value,
                })),
                target_organization: template.target_organization,
            };
        }
        case 2: {
            const {
                is_active,
                is_target_all,
                lti_app_template,
                name,
                target_organization,
                url,
                use_organization_code,
            } = template;

            const candidate = findCandidate(template);

            const paramJson = url.is_choices ?
                candidate?.param_json :
                url.param_json;

            if(!paramJson) {
                // paramJson が null | undefined となるのは、
                // candidate?.param_json が null | undefined になるときだけだが、
                // URL が選択式の場合は candidate を含め、paramJson が null | undefined になることはない。
                return createInitFormValue();
            }

            const { redirect_uris, deployment_id, ...restParamJson } = paramJson;

            return {
                baseTemplateUuid: lti_app_template.uuid,
                is_active,
                is_target_all,
                ltiUrlCandidateUuid: candidate?.uuid ?? '',
                ltiUrlUuid: url.uuid,
                name,
                param_json: {
                    ...restParamJson,
                    deployment_id: deployment_id ?? '',
                    redirect_uris: Array.isArray(redirect_uris) ? redirect_uris : [redirect_uris],
                    use_organization_code: use_organization_code,
                },
                params: [],
                target_organization: target_organization,
            };
        }
        default:
            return createInitFormValue();
    }
};

import { combineActions, handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const appsCategorySearchConnection = handleAction(
    combineActions(
        Actions.http.connection.apps.category.search,
        Actions.http.connection.apps.category.controlSearch,
    ),
    defaultReducer,
    defaultState
);

export const appsCategoryEditConnection = handleAction(
    combineActions(
        Actions.http.connection.apps.category.delete,
        Actions.http.connection.apps.category.controlDelete,
        Actions.http.connection.apps.category.update,
        Actions.http.connection.apps.category.controlUpdate,
        Actions.http.connection.apps.category.create,
        Actions.http.connection.apps.category.controlCreate,
    ),
    defaultReducer,
    defaultState
);

export const appsCategoryOrderEditConnection = handleAction(
    combineActions(
        Actions.http.connection.apps.category.changeOrder,
        Actions.http.connection.apps.category.controlChangeOrder
    ),
    defaultReducer,
    defaultState
);

export const appsCategoryViewConnection =  handleAction(
    combineActions(
        Actions.http.connection.apps.category.view,
        Actions.http.connection.apps.category.controlView
    ),
    defaultReducer,
    defaultState
);

export const appsCategoryResultConnection = handleAction(
    Actions.http.connection.apps.category.result,
    defaultReducer,
    defaultState
);

export const appsCategoryControlResultConnection = handleAction(
    Actions.http.connection.apps.category.controlResult,
    defaultReducer,
    defaultState
);

export const appsCategoryTargetSchoolClassConnection = handleAction(
    Actions.http.connection.apps.category.targetSchoolClass,
    defaultReducer,
    defaultState
);
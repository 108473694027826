import { useRef, RefObject, VFC } from 'react';

import FroalaEditor, { MyComponentProps as DefaultFroalaProps } from 'react-froala-wysiwyg';

import { FroalaEditorLicenseKey } from 'constants/GlobalConfig';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/ja.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/themes/gray.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

const defaultConfig = {
    attribution: false,
    charCounterMax: -1,
    codeBeautifierOptions: {
        indent_char: ' ',
        indent_size: 4,
        wrap_line_length: 0,
    },
    colorsBackground: [
        '#dc0000',
        '#004da0',
        '#f8b500',
        '#009d62',
        '#ec6c00',
        '#ffffff',
        '#314b57',
        'REMOVE',
    ],
    colorsHEXInput: false,
    colorsText: [
        '#dc0000',
        '#004da0',
        '#f8b500',
        '#009d62',
        '#ec6c00',
        '#ffffff',
        '#314b57',
        'REMOVE',
    ],
    events: {
        // コンポーネント中で上書きされるのでここには書かない。
        // ここに書く場合、コンポーネント内の処理をよしなに修正すること。
    },
    heightMin: 200,
    htmlAllowedTags: [
        'a',
        'br',
        'code',
        'em',
        'hr',
        'p',
        'strong',
        'u',
    ],
    key: FroalaEditorLicenseKey,
    language: 'ja',
    linkAlwaysBlank: true,
    linkEditButtons: [
        'linkOpen',
        'linkEdit',
        'linkRemove',
    ],
    linkInsertButtons: ['linkBack'],
    placeholderText: '内容を入力してください',
    pluginsEnabled: [
        'align',
        'charCounter',
        'colors',
        'fullscreen',
        'link',
        'lists',
        'print',
        'url',
    ],
    toolbarButtons: [
        'fullscreen',
        'bold',
        'italic',
        'underline',
        '|',
        'color',
        '|',
        'insertHR',
        'insertLink',
        'clearFormatting',
        '|',
        'help',
        '|',
        'undo',
        'redo',
        '|',
        'print',
    ],
    toolbarSticky: false,
};

type FroalaProps = {
    // object 型だと使い勝手が悪いので any とする
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any;
} & DefaultFroalaProps;

type Props = {
    disabled: boolean
    /** antd 用 */
    // eslint-disable-next-line @typescript-eslint/ban-types
    value?: string | object
    /** antd 用 */
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange?: (value: string | object) => void
    /**
     * antd 用
     *
     * scrollToFirstError は id を基にしてスクロールするため、
     * 指定の id を設定する必要がある
     */
    id?: string
} & FroalaProps

/**
 * リッチテキストエディタ
 *
 * 文字数制限を指定する場合には、 Antd の Form.Item ないの rules で指定せず、
 * このコンポーネントの config で指定すること。
 *
 * 参考: #3792
 */
const RichTextEditor: VFC<Props> = ({
    config: _config = {},
    disabled,
    id = '',
    model: _model,
    onChange,
    onModelChange: _onModelChange,
    value,
    ...restProps
}) => {
    // https://github.com/froala/react-froala-wysiwyg/issues/184#issuecomment-680194877
    const ref = useRef<FroalaEditor>(null);
    const model = _model ?? value;
    const onModelChange = _onModelChange ?? onChange;
    const { events, ...restConfig } = _config;
    const HandleCharCounterUpdater = () => charCounterUpdater(ref, disabled);
    const config = {
        ...defaultConfig,
        events: {
            'charCounter.update': HandleCharCounterUpdater,
            ...events,
        },
        ...restConfig,
    };

    return (
        <div id={id}>
            <FroalaEditor
                {...restProps}
                config={config}
                model={model}
                onModelChange={onModelChange}
                ref={ref}
            />
        </div>
    );
};

export default RichTextEditor;


/**
 * 文字数カウントが更新されたときの処理
 *
 * エディタが disabled の場合、文字数のカウントがされないため、
 * カウントが完了してから disabled にする。
 *
 * 参考: https://github.com/froala/wysiwyg-editor/issues/3501
 */
const charCounterUpdater = (ref: RefObject<FroalaEditor>, disabled: boolean) => () => {
    const editor = ref.current?.getEditor();
    // この Editor を使う属性は必須なので、文字数が 0 になることはない。
    if (!editor || !editor.charCounter || editor.charCounter.count() <= 0) return;
    disabled ?
        editor.edit.off() :
        editor.edit.on();
};
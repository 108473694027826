import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TablePaginationConfig } from 'antd';

type Result = {
    isLoading: boolean,
    pagination: TablePaginationConfig,
    usersList: App.AppStatus.UsersListItem[],
    targetUsers: number,
}

const INIT_STATE: Result = {
    isLoading: false,
    pagination: {
        total: 0,
        pageSize: 20,
        current: 1,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: false,
    },
    usersList: [],
    targetUsers: 0,
};

export const useAppCategoryResult = () => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });
    const appsCategoryResult = useAppSelector(state => state.appsCategoryResultConnection);
    const dispatch = useDispatch();
    const resultAction = Actions.http.connection.apps.category.result;

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback(
        (
            appCategoryUuid: string,
            data: Http.Connection.Request.Parameter.App.GroupStatusSearch,
            pageNum: number,
        ) => {
            dispatch(resultAction(appCategoryUuid, data, pageNum));
            setResult((result) => ({
                ...result,
                isLoading: true,
            }));
        },
        [dispatch, resultAction]
    );

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = appsCategoryResult;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                setResult((result) => ({
                    ...result,
                    isLoading: true,
                }));
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                const total = payloadResult?.item_count ? payloadResult?.item_count : 0;
                const targetUsers = payloadResult?.target_users ? payloadResult?.target_users : 0;
                const usersList = payloadResult
                    ? payloadResult.items.map(item => {
                        const applications = (item.applications ?? []).map(application => {
                            return {
                                ...application,
                                result_histories: application.result_histories.map(history => ({
                                    ...(history!),
                                    score: history!.score ?? 0,
                                })),
                            }
                        }).sort((a,b) => {
                            return a.order < b.order ? -1 : 1;
                        });
                        const userName = item.first_name !== '' || item.last_name !== '' ?
                            `${item.last_name} ${item.first_name}` :
                            item.login_id;

                        return {
                            belongs: item.belongs,
                            name: userName,
                            uuid: item.uuid,
                            applications,
                        };
                    })
                    : [];
                setResult({
                    pagination: {
                        ...result.pagination,
                        total: total
                    },
                    isLoading: false,
                    usersList: usersList,
                    targetUsers: targetUsers,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsCategoryResult]);

    return [result, request, reset] as const;
};

import { convertQuestionsToEditable } from './viewLogic';
import * as Actions from 'flex/Actions';

/***
 * @param store
 * @param next
 * @param action
 */
export const editQuestionnaireSavingData = (store, next, action) => {
    const viewState = store.getState()['editQuestionnaire'];
    const modalState = store.getState()['questionnaireTab']['modal'];
    const questionnaire = viewState['questionnaire'];
    const editableItems = viewState['editableItems'];
    const editQuestion = viewState['editQuestion'];

    switch (action.type) {
        case Actions.view.viewLogic.editQuestionnaire.prepareView.toString():
            next({
                ...viewState,
                questionnaire: null,
                isExpandQuestionMap: {},
                editableItems: [],
                isTargetOpen: false,
                editQuestion: {
                    isEditOpen: false,
                    shouldCreate: false,
                    question: null,
                    parentTmpItemId: null,
                    parentTmpCandidateId: null,
                    questionsCount: 0,
                },
                type: 'editQuestionnaire',
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.selectCategory.toString():
            next({
                ...viewState,
                selectCategory: action.payload.uuid,
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.openTarget.toString():
            next({
                ...viewState,
                isTargetOpen: true,
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.submitTarget.toString():
            next({
                ...viewState,
                isTargetOpen: false,
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.cancelTarget.toString():
            next({
                ...viewState,
                isTargetOpen: false,
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.openQuestion.toString():
            next({
                ...viewState,
                editQuestion: {
                    isEditOpen: true,
                    shouldCreate: !!!action.payload.question,
                    question: action.payload.question,
                    parentTmpItemId: action.payload.parentTmpItemId,
                    parentTmpCandidateId: action.payload.parentTmpCandidateId,
                    questionsCount: editableItems.length,
                },
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.submitQuestion.toString():
            const item = action.payload.values
            // tmpItemIdの有無で、編集か作成か見分ける
            const newItems = editQuestion.shouldCreate ? addQuestionWithTmpId(editableItems, item) : mergeQuestionsWithTmpId(editableItems, item)

            next({
                ...viewState,
                editableItems: newItems,
                editQuestion: {
                    isEditOpen: false,
                    shouldCreate: false,
                    question: null,
                    parentTmpItemId: null,
                    parentTmpCandidateId: null,
                },
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.cancelQuestion.toString():
            next({
                ...viewState,
                editQuestion: {
                    isEditOpen: false,
                    shouldCreate: false,
                    question: null,
                    parentTmpItemId: null,
                    parentTmpCandidateId: null,
                },
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.moveQuestion.toString():
            {
                const { tmpItemId, parentTmpItemId } = action.payload.item
                const upOrDown = action.payload.upOrDown
                next({
                    ...viewState,
                    editableItems: moveUpOrDownQuestion(editableItems, tmpItemId, parentTmpItemId, upOrDown),
                    type: 'editQuestionnaire'
                });
            }
            break;
        case Actions.view.viewLogic.editQuestionnaire.copyQuestion.toString():
            next({
                ...viewState,
                editableItems: copyQuestionRecursive(editableItems, action.payload.item),
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.deleteQuestion.toString():
            next({
                ...viewState,
                editableItems: deleteQuestionRecursive(editableItems, action.payload.tmpItemId),
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.deleteFile.toString():
            {
                const newEditableItems = editableItems.slice();
                const indexHasTargetFile = newEditableItems.findIndex(({ tmpItemId }) => tmpItemId === action.payload.tmpItemId);
                newEditableItems[indexHasTargetFile].file = undefined;

                next({
                    ...viewState,
                    editableItems: newEditableItems,
                    type: 'editQuestionnaire'
                });
            }
            break;
        case Actions.view.viewLogic.editQuestionnaire.toggleExpandQuestion.toString():
            next({
                ...viewState,
                isExpandQuestionMap: {
                    ...viewState.isExpandQuestionMap,
                    [action.payload.tmpItemId]: !(!!viewState.isExpandQuestionMap[action.payload.tmpItemId]),
                },
                type: 'editQuestionnaire'
            });
            break;
        case Actions.view.viewLogic.editQuestionnaire.prepareViewAfter.toString():
            const { uuid, shouldCreate } = modalState;
            const isCopy = uuid && shouldCreate;
            next({
                ...viewState,
                selectCategory: questionnaire.category.uuid,
                editableItems: convertQuestionsToEditable(questionnaire.items, isCopy),
                type: 'editQuestionnaire'
            });
            break;
        default:
            return;
    }
}

/***
 *  子もろとも再帰的に質問を削除して、削除したものを返す
 * @param items
 * @param {number} tmpItemId
 * @return {*}
 */
const deleteQuestionRecursive = (items, tmpItemId) => {
    const newItems = deleteOneQuestion(items, tmpItemId);
    const children = items.filter(v => v.parentTmpItemId === tmpItemId);
    if (children && children.length > 0) {
        return children.reduce((items, childItem) => { return deleteQuestionRecursive(items, childItem.tmpItemId) }, newItems);
    } else {
        return newItems;
    }
}

/***
 *  itemsを複製し、指定したtmpItemIdの要素を削除し、items返す
 * @param items
 * @param {number} tmpItemId
 * @return {*}
 */
const deleteOneQuestion = (items, tmpItemId) => {
    const index = items.findIndex(v => tmpItemId === v.tmpItemId);
    const newItems = items.slice();
    newItems.splice(index, 1);
    return newItems;
}

/***
 * 子もろとも再帰的に質問を複製し、itemsの末尾に追加して返す。newParentTmpItemIdは、コピー対象に新しく親のIDを割り当てるときに利用
 * @param items
 * @param item
 * @param {number | undefined} newParentTmpItemId
 * @return {*}
 */
const copyQuestionRecursive = (items, item, newParentTmpItemId) => {
    const newItems = copyOneQuestion(items, item);
    if (newParentTmpItemId) {
        newItems[newItems.length - 1].parentTmpItemId = newParentTmpItemId;
    }
    const children = items.filter(v => v.parentTmpItemId === item.tmpItemId);
    if (children && children.length > 0) {
        const parentTmpItemId = newItems[newItems.length - 1].tmpItemId;
        return children.reduce((items, childItem) => { return copyQuestionRecursive(items, childItem, parentTmpItemId) }, newItems);
    } else {
        return newItems;
    }
}

/***
 * itemを複製し、必要最低限を初期化して、新しいitemsの末尾に追加し、返す
 * @param items
 * @param item
 * @return {*}
 */
const copyOneQuestion = (items, item) => {
    const copiedItem = JSON.parse(JSON.stringify(item));
    delete copiedItem.uuid;
    delete copiedItem.order;
    delete copiedItem.created_at;
    delete copiedItem.updated_at;
    delete copiedItem.answer_count;
    delete copiedItem.answers;
    copiedItem.answer_candidates && copiedItem.answer_candidates.forEach(candidate => {
        delete candidate.uuid;
        delete candidate.order;
        delete candidate.created_at;
        delete candidate.updated_at;
        delete candidate.answer_count;
    });
    copiedItem.tmpItemId = getMaxTmpItemId(items) + 1;
    const newItem = changeContentId(copiedItem, items.length + 1);

    const newItems = items.slice();
    newItems.push(newItem);
    return newItems;
}


/***
 * 同じ親を持つアイテムの並びにおいて、上か下かに一つ移動させる
 * @param items
 * @param {number} tmpItemId
 * @param {number|undefined} parentTmpItemId
 * @param {'UP' | 'DOWN'} upOrDown
 * @return {*} items
 */
const moveUpOrDownQuestion = (items, tmpItemId, parentTmpItemId, upOrDown) => {

    // 利用する関するの定義
    /***
     * 同じ親を持つ子供のアイテムにおいての、順番を取得する
     * @return {number} index
     */
    const getIndexInChildren = (items, tmpItemId, parentTmpItemId) => {
        return items.filter((v) => v.parentTmpItemId === parentTmpItemId).findIndex((v) => v.tmpItemId === tmpItemId);
    }
    /**
     * 同じ親を持つ子供のアイテムにおいてのindexにより、itemを取得する (undefined を返す = 同じ親で次のアイテムなし)
     * @return {{}|undefined} item
     */
    const getItemByChildrenIndex = (items, indexInChildren, parentTmpItemId) => {
        return items.filter((v) => v.parentTmpItemId === parentTmpItemId)[indexInChildren];
    }
    /***
     * 同じparentTmpItemIdを持つ子のアイテム内で、tmpItemIdの位置を一つ上げる
     * @return {{}[]} items
     */
    const sortUpItemInChildren = (items, tmpItemId, parentTmpItemId) => {
        const startIndexInChildren = getIndexInChildren(newItems, tmpItemId, parentTmpItemId);
        if (startIndexInChildren !== 0) {
            // 前後を入れ替えて、おなじ親を持つitemの中で、対象の順番が、一つ前になるまで繰り替えす
            for (let count = 0; count < items.length; count++) {
                const targetIndex = newItems.findIndex(v => v.tmpItemId === tmpItemId);
                const [newItem1, newItem2] = exchangeContentId(newItems[targetIndex], newItems[targetIndex - 1]);
                newItems.splice(targetIndex - 1, 2, newItem1, newItem2);
                if (getIndexInChildren(newItems, tmpItemId, parentTmpItemId) < startIndexInChildren) {
                    return newItems;
                }
            }
        } else {
            return newItems;
        }
    }

    // 並び替え処理の実行
    const newItems = JSON.parse(JSON.stringify(items));
    switch (upOrDown) {
        case 'UP': // 同じ親を持つアイテムのなかで、一つ順番をあげる
            return sortUpItemInChildren(newItems, tmpItemId, parentTmpItemId);
        case 'DOWN': // 同じ親を持つアイテムのなかで、次のitemを一つ順番を上げることで、相対的に対象のitemを下げる
            const targetIndexInChildren = getIndexInChildren(newItems, tmpItemId, parentTmpItemId);
            const nextItemInChildren = getItemByChildrenIndex(newItems, targetIndexInChildren + 1, parentTmpItemId);
            if (nextItemInChildren) {
                return sortUpItemInChildren(newItems, nextItemInChildren.tmpItemId, parentTmpItemId);
            }
            break
        default:
            break;
    }
    return items;
}

/***
 * itemsに質問の追加を行い、返す
 * @param items
 * @param newItem
 * @return {*}
 */
export const addQuestionWithTmpId = (items, newItem) => {

    // tmpItemIdをわりふる
    let lastTmpItemId = getMaxTmpItemId(items);
    const newItemWithTmpId = Object.assign({ tmpItemId: ++lastTmpItemId }, newItem);

    // tmpCandidateIdをわりふる
    if (newItem.answer_candidates && newItem.answer_candidates.length !== 0) {
        newItemWithTmpId.answer_candidates = newItem.answer_candidates.map((answer, index) => {
            return Object.assign({ tmpCandidateId: index + 1 }, answer);
        });
    }

    const newItems = items.slice();
    newItems.push(newItemWithTmpId);
    return newItems;
}

export const mergeQuestionsWithTmpId = (items, newItem) => {

    const existedItem = Object.assign({}, items.find(({ tmpItemId }) => tmpItemId === newItem.tmpItemId));
    const existedItemIndex = items.findIndex(({ tmpItemId }) => tmpItemId === newItem.tmpItemId);
    const newItemWithTmpId = Object.assign(existedItem, { ...newItem });

    // tmpCandidateIdをわりふる
    if (newItem.answer_candidates && newItem.answer_candidates.length !== 0) {
        newItemWithTmpId.answer_candidates = newItem.answer_candidates.map((answer, index) => {
            return Object.assign({ tmpCandidateId: index + 1 }, answer);
        })
    }

    const newItems = items.slice();
    newItems.splice(existedItemIndex, 1, newItemWithTmpId);
    return newItems;
}


/***
 * itemsのなかで、最大のtmpItemIdを取得する
 * @param items
 * @return {number}
 */
const getMaxTmpItemId = (items) => {
    let lastTmpItemId = 1;
    items && items.forEach((item) => {
        if (item.tmpItemId > lastTmpItemId) {
            lastTmpItemId = item.tmpItemId;
        }
    })
    return lastTmpItemId;
}

/**
 * 設問番号を表す正規表現
 */
// 数値以外も許容する場合は \d を . とする
const serialNumberRegExp = new RegExp('^(\\d+?)\\. ');

/**
 * item の設問に記述されている設問番号を newId に変更する
 * @param {*} item item
 * @param {string | number | undefined} newId 新しい設問番号
 * @returns 設問番号を変更した item
 */
const changeContentId = (item, newId) => {
    const pureContent = item.content.replace(serialNumberRegExp, '');
    const id = newId ? `${newId}. ` : '';
    return {
        ...item,
        content: `${id}${pureContent}`,
    };
}
/**
 * item1 と item2 の設問番号を入れ替える
 * @param {*} item1 item
 * @param {*} item2 item
 * @returns [item, item]
 */
const exchangeContentId = (item1, item2) => {
    const itemMatch1 = item1.content.match(serialNumberRegExp);
    const itemMatch2 = item2.content.match(serialNumberRegExp);

    const id1 = itemMatch1 ? itemMatch1[1] : undefined;
    const id2 = itemMatch2 ? itemMatch2[1] : undefined;

    return [
        changeContentId(item1, id2),
        changeContentId(item2, id1),
    ];
}

import { useContext, useEffect, createContext, useState, useCallback } from 'react';
import { Button } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as GlobalConfig from 'constants/GlobalConfig';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import * as Actions from 'flex/Actions';

import NotificationsSearch from './NotificationsSearch';
import EditNotification from '../EditNotification';
import { ListContext } from '../index';
import NotificationStatusModal from '../NotificationStatusModal';
import { BaseTable } from '../../../../modules/BaseTable';
import { createColumns } from './createColumns';

export const INITIAL_SEARCH_RULE = {
    title: undefined,
    page: 1,
    page_size: 50,
};

export const SearchContext = createContext([INITIAL_SEARCH_RULE, () => { }]);

const NotificationsList = () => {
    const dispatch = useDispatch();
    const { hasTouchScreen } = useHasTouchScreen();
    const [list,] = useContext(ListContext);
    const [search, setSearch] = useState(INITIAL_SEARCH_RULE);
    const [uuid, setUuid] = useState(null);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isStatusShowOpen, setIsStatusShowOpen] = useState(false);
    const [shouldCreate, setShouldCreate] = useState(true);
    const [record, setRecord] = useState(null);
    const isControllableUser = useSelector(state => state.isControllableUser.payload);

    const notificationSearch = useCallback((data, page) => {
        const search = isControllableUser
            ? Actions.http.connection.notification.controlSearch
            : Actions.http.connection.notification.search;
        dispatch(search(data, page));
    }, [dispatch, isControllableUser]);

    const onChangePagination = useCallback((page, page_size) => {
        setPagination(pagination => ({
            ...pagination,
            current: page,
            pageSize: page_size,
        }));
        const { page: _, ...restSearchRule } = search;
        const newSearchRule = {
            ...restSearchRule,
            page_size: page_size,
        };
        setSearch({
            ...newSearchRule,
            page: page,
        });
        notificationSearch(newSearchRule, page);
    }, [search, notificationSearch]);// eslint-disable-line react-hooks/exhaustive-deps

    const [pagination, setPagination] = useState({
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`)
        },
        pageSize: INITIAL_SEARCH_RULE.page_size,
        total: list.notificationListItemCount,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        pageSizeOptions: isControllableUser ? ['50', '100'] : GlobalConfig.PageSizeOptions,
        onChange: onChangePagination,
    });

    useEffect(() => {//初回検索
        notificationSearch(INITIAL_SEARCH_RULE, 1);
    }, []);// eslint-disable-line

    useEffect(() => {// totalの更新でPaginationを更新
        setPagination(pagination => ({
            ...pagination,
            total: list.notificationListItemCount
        }));
    }, [list.notificationListItemCount]);

    useEffect(() => {// onChangePaginationが再作成されたらpaginationを更新
        setPagination((pagination) => ({ ...pagination, onChange: onChangePagination }))
    }, [onChangePagination]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleCreateClick = () => {
        setRecord(null);
        setShouldCreate(true);
        setIsEditOpen(true);
    };

    const handleEditClick = (record) => {
        setRecord(record);
        setShouldCreate(false);
        setIsEditOpen(true);
    };

    const handleCopyClick = (record) => {
        setRecord(record);
        setShouldCreate(true);
        setIsEditOpen(true);
    };

    const handleShowStatusClick = (record) => {
        setUuid(record);
        setIsStatusShowOpen(true);
    };

    const handleDeleteClick = (id) => {
        if (isControllableUser) {
            dispatch(Actions.http.connection.notification.controlDelete(id));
        } else {
            dispatch(Actions.http.connection.notification.delete(id));
        }
    };

    const handleEditSubmitted = () => {
        setIsEditOpen(false);
        const { page, ...restSearch } = search;

        setPagination(pagination => ({
            ...pagination,
            current: page,
        }));
        notificationSearch(restSearch, page);
    };

    const handleEditCancel = () => {
        setRecord(null);
        setIsEditOpen(false);
    };

    const handleShowStatusSubmit = () => setIsStatusShowOpen(false);
    const handleShowStatusCancel = () => setIsStatusShowOpen(false);

    const columns = createColumns(
        hasTouchScreen,
        handleEditClick,
        handleCopyClick,
        handleShowStatusClick,
        handleDeleteClick,
    );

    return (
        <div className='notifications-list container'>
            <SearchContext.Provider value={[search, setSearch]}>
                <NotificationsSearch isControllableUser={isControllableUser} />
            </SearchContext.Provider>

            <div className='flex-right-container gutter-bottom'>
                <Button
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                emptyDescription='配信されているお知らせはありません'
                columns={columns}
                dataSource={list.notificationList}
                scroll={{ x: 1430 }}
                pagination={pagination}
                loading={list.loading}
            />
            <EditNotification
                shouldCreate={shouldCreate}
                record={record}
                visible={isEditOpen}
                onOk={handleEditSubmitted}
                onCancel={handleEditCancel}
            />
            <NotificationStatusModal
                uuid={uuid}
                visible={isStatusShowOpen}
                onOk={handleShowStatusSubmit}
                onCancel={handleShowStatusCancel}
            />
        </div>
    );
};

export default NotificationsList;

import { Form, FormProps } from 'antd';

// export のタイミングで型をつけるので、ここでは any とする。
const BaseForm: any = Form;

/**
 * Modal 用の Form コンポーネント。
 *
 * scrollToFirstError がデフォルトで有効となっている。
 */
const ModalForm = <T extends any>(props: FormProps<T>) => {
    const { children, ...restProps } = props;
    return (
        <Form
            {...restProps}
            scrollToFirstError={{ behavior: 'smooth' }}
        >
            {children}
        </Form>
    );
};

ModalForm.ErrorList = Form.ErrorList;
ModalForm.Item = Form.Item;
ModalForm.List = Form.List;
ModalForm.Provider = Form.Provider;
ModalForm.useForm = Form.useForm;
ModalForm.useFormInstance = Form.useFormInstance;
ModalForm.useWatch = Form.useWatch;

BaseForm.ModalForm = ModalForm;

/**
 * カスタマイズされた Form コンポーネント。
 */
export default BaseForm as typeof Form & {ModalForm: typeof Form};

import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';

type Props = {
    category: App.AppsCategory | undefined
    count: number
    isControllableUser: boolean
    loading: boolean
    targetUsersCount: number
}

export const Summary: React.VFC<Props> = (props) => {
    const { category, count, isControllableUser, loading, targetUsersCount } = props;
    const title = category?.title ?? '';
    const targetString = isControllableUser ? '配信対象校' : '配信対象者';
    const targetCount = isControllableUser ? count : targetUsersCount;
    const measureWord = isControllableUser ? '校' : '名';

    return (
        <div className='wrapper'>
            <div className='test-status-modal-card-wrapper'>
                <Row gutter={16}>
                    <Col span={24}>
                        <Card bordered={false} className='test-status-modal-card'>
                            {loading ?
                                <Row justify='space-around' align='middle'
                                    style={{ textAlign: 'center' }}>
                                    <Col span={24}>
                                        <span><LoadingOutlined /> 読み込み中...</span>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col span={12}>
                                        <Row align='middle'>
                                            <Col span={24}>
                                                <div className='test-status-modal-title'>アプリグループ名</div>
                                                <div className='test-status-modal-value'>{title}</div>
                                            </Col>
                                        </Row>
                                        <Row align='middle'>
                                            <Col span={6}>
                                                <div className='test-status-modal-title'>{targetString}</div>
                                                <div className='test-status-modal-value'>{`${targetCount ?? '-'} ${measureWord}`}</div>
                                            </Col>

                                            {isControllableUser && (
                                                <Col span={6}>
                                                    <div className='test-status-modal-title'>配信対象者</div>
                                                    {/* 管理者権限で開いたときの配信対象校と挙動を同じにするために, undefined のときの表示を 0 としている */}
                                                    <div className='test-status-modal-value'>{`${targetUsersCount ?? 0} 名`}</div>
                                                </Col>
                                            )}
                                        </Row>

                                    </Col>
                                </Row>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

import axios from 'axios';
import {generateApiDomain} from 'constants/GlobalConfig';


const apiAccess = axios.create({
});
apiAccess.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.timeout = config.timeout === 0 ? 120000 : config.timeout;
    config.responseType = 'json';
    config.baseURL = generateApiDomain();
    config.withCredentials = true;
    return config;
}, (error) => {
    return Promise.reject(error);
});

const apiLongTimeoutAccess = axios.create({
});
apiLongTimeoutAccess.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.responseType = 'json';
    config.timeout= 0;
    config.baseURL = generateApiDomain();
    config.withCredentials = true;
    return config;
}, (error) => {
    return Promise.reject(error);
});

const apiExternalServiceAccess = axios.create({
});
apiExternalServiceAccess.interceptors.request.use((config) => {
    config.timeout = 3000;
    config.baseURL ='';
    return config;
}, (error) => {
    return Promise.reject(error);
});


export { apiAccess, apiLongTimeoutAccess, apiExternalServiceAccess };

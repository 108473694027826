import { useState } from 'react';
import {
    Button,
    DatePicker,
    Input,
    Radio,
    RadioChangeEvent,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import isTouchDevice from 'is-touch-device';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;

const layout = {
    labelCol: {
        sm: { span: 6 },
        xl: { span: 5 },
        xxl: { span: 4 },
    },
    wrapperCol: {
        span: 24,
    },
};


type Props = {
    loading: boolean
    onChangeSearchMode: () => void
    onSearch: (condition: Partial<EverydayNote.SearchConditions>) => void
}

const QuestionnaireSearch: React.VFC<Props> = ({
    loading,
    onChangeSearchMode,
    onSearch,
}) => {
    const [isDetailMode, setIsDetailMode] = useState(false);

    const onModeChange = (e: RadioChangeEvent) => {
        setIsDetailMode(e.target.value);
        onChangeSearchMode();
    };

    return (
        <div className='card general-search'>
            <div className='gutter-bottom'>
                <Radio.Group onChange={onModeChange} value={isDetailMode} buttonStyle='solid'>
                    <Radio.Button value={false}>シンプルな検索</Radio.Button>
                    <Radio.Button value={true}>詳細検索</Radio.Button>
                </Radio.Group>
            </div>

            <SearchForm
                isDetailMode={isDetailMode}
                loading={loading}
                onSearch={onSearch}
            />
        </div>
    );
};

export default QuestionnaireSearch;


type FormValue = Omit<EverydayNote.SearchCondition.Detail, 'page' | 'pageSize'> |
    Omit<EverydayNote.SearchCondition.Simple, 'page' | 'pageSize'>;

type FormProps = {
    isDetailMode: boolean
} & Omit<Props, 'onChangeSearchMode'>;

const SearchForm: React.VFC<FormProps> = ({
    isDetailMode,
    loading,
    onSearch,
}) => {
    const [form] = Form.useForm<FormValue>();

    const handleReset = () => form.resetFields();

    const handleFinish = (value: FormValue) => {
        const newCondition = isDetailMode ? value : { name: value.name };
        onSearch({ ...newCondition, page: 1 });
    };

    return (
        <Form {...layout} form={form} onFinish={handleFinish}>
            <Form.Item name='name' label='タイトル'>
                <Input placeholder='タイトルを入力してください' />
            </Form.Item>

            {isDetailMode && (
                <DetailSearchFormItems />
            )}

            <div className='search-container'>
                <Form.Item >
                    <Button htmlType='button'
                        onClick={handleReset}
                        loading={loading}
                    >
                        リセット
                    </Button>
                    <Button type='primary' htmlType='submit'
                        loading={loading}
                    >
                        検索
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};

const DetailSearchFormItems: React.VFC = () => {
    return (
        <>
            <Form.Item name='publishFrom' label='発信元'>
                <Input placeholder='発信元を入力してください' />
            </Form.Item>

            <Form.Item name='isViewedHome' label='ホーム画面に表示する'>
                <Radio.Group>
                    <Radio value={true}>表示</Radio>
                    <Radio value={false}>非表示</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item name='isOnceADay' label='日毎の複数回答'>
                <Radio.Group>
                    <Radio value={false}>許容する</Radio>
                    <Radio value={true}>許容しない</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label='実施期間' extra='指定した期間に実施中となっている毎日の記録を対象とします。'>
                <Input.Group compact>
                    <Form.Item noStyle name='publishStartAt'>
                        <DatePicker
                            locale={jaJP}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY/MM/DD HH:mm'
                            placeholder='開始日時'
                            inputReadOnly={isTouchDevice()}
                        />
                    </Form.Item>
                    <Form.Item noStyle name='publishEndAt'>
                        <DatePicker
                            locale={jaJP}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY/MM/DD HH:mm'
                            placeholder='終了日時'
                            inputReadOnly={isTouchDevice()}
                        />
                    </Form.Item>
                </Input.Group>
            </Form.Item>

            <Form.Item name='isLocked' label='回答状態'>
                <Radio.Group>
                    <Radio value={true}>回答あり</Radio>
                    <Radio value={false}>回答なし</Radio>
                </Radio.Group>
            </Form.Item>

        </>
    );
};
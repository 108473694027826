import { useState, createContext, useContext } from "react";
import {Tabs} from "antd";
import JobListEffectComponent from "./JobListEffectComponent";
import JobList from "./JobList";

const {TabPane} = Tabs;


function Job() {
    return (
        <JobListEffectComponent defaultSearchrule={{page_size: 20}}>
            <div className="page-notifications card-container">
                <JobList/>
            </div>
        </JobListEffectComponent>
    )
}

export default Job;

import * as React from 'react';

import { useAppSelector } from 'flex/utils';

import AdminContents from './AdminContents';
import ControlContents from './ControlContents';

type Props = {
    tabActiveKey: string
    hasTouchScreen: boolean
};

const Classes: React.VFC<Props> = ({
    tabActiveKey,
    hasTouchScreen,
}) => {
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    return isControllableUser ?
        <ControlContents /> :
        <AdminContents hasTouchScreen={hasTouchScreen} tabActiveKey={tabActiveKey} />;
};

export default Classes;

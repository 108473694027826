import { additionDisplayName } from 'constants/GlobalConfig';
import { defaultState } from '.';
import * as Actions from '../../../Actions';
import { SavingDataExecutor } from '../../../utils';
const { statusEnum } = Actions;

const addControlSavingData = (
    baseStore: StoreAccessor,
    baseNext: StoreNext<ExamControlCategoryTabState>,
    baseAction: ActionType<unknown, unknown>,
    executor: SavingDataExecutor<ExamControlCategoryTabState>
) => {
    const examControlCategoryTab = baseStore.getState().examControlCategoryTab;

    executor.addSavingData(Actions.http.connection.tao.examControlCategory.controlDelete, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;

        const pageCount = examControlCategoryTab.examCategoryList.length > 1 ?
        examControlCategoryTab.pagination.current :
        examControlCategoryTab.pagination.current === 1 ?
                1 :
                examControlCategoryTab.pagination.current - 1

        next({
            ...examControlCategoryTab,
            pagination: {
                ...examControlCategoryTab.pagination,
                current: pageCount,
            },
        });

        store.dispatch(Actions.http.connection.tao.examControlCategory.controlSearch(
            {
                ...examControlCategoryTab.search,
                page_size: examControlCategoryTab.pagination.pageSize
            },
            pageCount,
            'examControlCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.examControlCategory.controlCreate, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        next({
            ...examControlCategoryTab,
            modal: {
                ...defaultState.modal
            },
            pagination: {
                ...examControlCategoryTab.pagination,
                current: 1,
            },
        });

        store.dispatch(Actions.http.connection.tao.examControlCategory.controlSearch(
            {
                ...examControlCategoryTab.search,
                page_size: examControlCategoryTab.pagination.pageSize
            },
            1,
            'examControlCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.examControlCategory.controlUpdate, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        next({
            ...examControlCategoryTab,
            modal: {
                ...defaultState.modal
            },
            pagination: {
                ...examControlCategoryTab.pagination,
                current: 1,
            },
        });

        store.dispatch(Actions.http.connection.tao.examControlCategory.controlSearch(
            {
                ...examControlCategoryTab.search,
                page_size: examControlCategoryTab.pagination.pageSize,
            },
            1,
            'examControlCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.examControlCategory.controlSearch, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        const examCategoryList = action.payload.result.items.map(examCategory => {
            const exams = examCategory.exams.map(convertToUsefulExamFormat);

            const created_by = additionDisplayName(examCategory.created_by);
            const updated_by = examCategory.updated_by ?
                additionDisplayName(examCategory.updated_by) :
                created_by;

            return {
                ...examCategory,
                created_by,
                updated_by,
                exams,
                target_term: examCategory.target_term?.uuid ?? '',
                target_organizations: examCategory.target_organizations ?? [],
            };
        });

        next({
            ...examControlCategoryTab,
            pagination: {
                ...examControlCategoryTab.pagination,
                total: action.payload.result.item_count,
            },
            examCategoryList,
            examCategoryListItemCount: action.payload.result.item_count,
        });
    });

    executor.addSavingData(Actions.http.connection.tao.examControlCategory.controlUserStatistics, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        const result = action.payload.result;
        const newExamCategoryTab = Object.assign({}, examControlCategoryTab);
        const examCategoryUuid = newExamCategoryTab.modal.categoryRecord!.uuid;
        const studentUuid = newExamCategoryTab.modal.studentView.student.uuid;
        newExamCategoryTab.modal.studentsStatistics[examCategoryUuid] = {
            ...(newExamCategoryTab.modal.studentsStatistics[examCategoryUuid] ?? {}),
            [studentUuid]: {
                examCount: result.exam_count,
                answeredExamCount: result.answered_exam_count,
                totalMaxScore: result.total_max_score ?? undefined,
                totalScore: result.total_score ?? undefined,
            },
        };
        next(newExamCategoryTab);
    });

    executor.addSavingData(Actions.http.connection.tao.exam.controlDelete, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        store.dispatch(Actions.http.connection.tao.examControlCategory.controlSearch(
            {
                ...examControlCategoryTab.search,
                page_size: examControlCategoryTab.pagination.pageSize
            },
            examControlCategoryTab.pagination.current,
            'examControlCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.exam.controlStatistics, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        const result = action.payload.result;
        const newExamCategoryTab = Object.assign({}, examControlCategoryTab);
        newExamCategoryTab.modal.statistics[action.meta.uuid] = {
            averageScore: result.average_score ?? undefined,
            highestScore: result.highest_score ?? undefined,
            lowestScore: result.lowest_score ?? undefined,
            maxScore: result.max_score,
            resultCount: result.result_count,
            resultHistoryCount: result.result_history_count ?? undefined,
            targetUserCount: result.target_user_count,
        }
        next(newExamCategoryTab);
    });
}

export const controlSavingDataForConnection = (
    baseStore: StoreAccessor,
    baseNext: StoreNext<ExamControlCategoryTabState>,
    baseAction: ActionType<unknown, unknown>
) => {
    const examControlCategoryTab = baseStore.getState().examControlCategoryTab;
    const executor = new SavingDataExecutor<ExamControlCategoryTabState>();

    addControlSavingData(baseStore, baseNext, baseAction, executor);

    executor.addSavingData(Actions.http.connection.tao.exam.delete, (store, next, action) => {
        if (action.meta.status === statusEnum.SUCCESS) {
            store.dispatch(Actions.http.connection.tao.examCategory.search(
                {
                    ...examControlCategoryTab.search,
                    page_size: examControlCategoryTab.pagination.pageSize
                },
                examControlCategoryTab.pagination.current,
                'examControlCategoryTab'
            ));
        }
    });

    executor.addSavingData(Actions.http.connection.tao.exam.save, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;

        store.dispatch(Actions.view.viewLogic.examCategoryTab.submittedSave());
    });

    executor.addSavingData(Actions.http.connection.tao.exam.statistics, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        const result = action.payload.result;
        const newExamCategoryTab = Object.assign({}, examControlCategoryTab);
        newExamCategoryTab.modal.statistics[action.meta.uuid] = {
            averageScore: result.average_score ?? undefined,
            highestScore: result.highest_score ?? undefined,
            lowestScore: result.lowest_score ?? undefined,
            maxScore: result.max_score,
            resultCount: result.result_count,
            resultHistoryCount: result.result_history_count ?? undefined,
            targetUserCount: result.target_user_count,
        }
        next(newExamCategoryTab);
    });

    executor.addSavingData(Actions.http.connection.tao.examCategory.userStatistics, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        const result = action.payload.result;
        const newExamCategoryTab = Object.assign({}, examControlCategoryTab);
        const examCategoryUuid = newExamCategoryTab.modal.categoryRecord!.uuid;
        const studentUuid = newExamCategoryTab.modal.studentView.student.uuid;
        newExamCategoryTab.modal.studentsStatistics[examCategoryUuid] = {
            ...(newExamCategoryTab.modal.studentsStatistics[examCategoryUuid] ?? {}),
            [studentUuid]: {
                examCount: result.exam_count,
                answeredExamCount: result.answered_exam_count,
                totalMaxScore: result.total_max_score ?? undefined,
                totalScore: result.total_score ?? undefined,
            },
        };
        next(newExamCategoryTab);
    });

    executor.addSavingData(Actions.http.connection.tao.examControlCategory.view, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;
        if(!examControlCategoryTab.modal.categoryRecord) return;

        const {target_users, target_school_classes, target_roles} = action.payload.result;

        next({
            ...examControlCategoryTab,
            modal: {
                ...examControlCategoryTab.modal,
                categoryRecord: {
                    ...examControlCategoryTab.modal.categoryRecord as Admin.ExamCategory,
                    target_users,
                    target_school_classes,
                    target_roles,
                },
                isLoading: false,
            },
        });
    });

    executor.execute(baseStore, baseNext, baseAction);
};

type ResponseExam = Http.Connection.Response.ExamCategory.Search['result']['items'][number]['exams'][number];
/**
 * レスポンスに含まれる Exam を
 * プログラムで使いやすいデータ形式に変換する
 */
const convertToUsefulExamFormat = (exam: ResponseExam): Exam => ({
    ...exam,
    created_by: additionDisplayName(exam.created_by),
    max_attempts: exam.max_attempts ?? 0,
});

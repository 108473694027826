import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Table } from 'antd';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import arrayMove from 'array-move';
import {
    SortableContainer as SortableContainerHoc,
    SortableElement as SortableElementHoc,
    SortableHandle,
} from 'react-sortable-hoc';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';

// 少し適当なので、必要に応じて適切な型に修正
type TableContentsBodyWrapperProps = {
    className: string,
};

// 少し適当なので、必要に応じて適切な型に修正
type TableContentsBodyRowProps = {
    className: string,
    'data-row-key': number,
    onClick: () => void,
    style: any,
};

const SortableElement = SortableElementHoc(
    (props: TableContentsBodyRowProps) => <tr {...props} />
);

const SortableContainer = SortableContainerHoc(
    (props: any) => <tbody {...props} />
);

const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

// 一旦、最低限必要なもののみ
// 必要に応じて Generics などを使って適切な方に修正する
type BaseDataSource = { uuid: string };

// 一旦、最低限必要なもののみ
// 必要に応じて Generics などを使って適切な方に修正する
type DataSource = { uuid: string, index: number };

type Props = {
    columns: any[]
    dataSource: BaseDataSource[]
    loading: boolean
    onCancel: () => void
    onOk: (data: { uuids: string[] }) => void
    title: string
    visible: boolean
};

const EditOrderModal: React.VFC<Props> = ({
    columns: receiveColumns,
    dataSource: data,
    loading,
    onCancel,
    onOk,
    title,
    visible,
}) => {
    const [dataSource, setDataSource] = useState<DataSource[]>([]);

    useEffect(() => {
        if (!visible) return;

        setDataSource(data.map((d, i) => ({ index: i, ...d })));
    }, [data, visible]);

    const handleSubmit = () => {
        onOk({ uuids: dataSource.map(v => v.uuid) });
    };

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        if (oldIndex === newIndex) return;
        setDataSource(dataSource => (
            arrayMove(dataSource, oldIndex, newIndex).filter(e => !!e)
        ));
    };

    const DraggableBodyRow: React.FC<TableContentsBodyRowProps> = props => {
        const index = dataSource.findIndex(x => x.index === props['data-row-key']);
        return <SortableElement index={index} {...props} />;
    };

    const DraggableContainer: React.FC<TableContentsBodyWrapperProps> = props => {
        return (
            <SortableContainer
                useDragHandle
                helperClass='row-dragging'
                onSortEnd={onSortEnd}
                {...props}
            />
        );
    };

    const columns = [
        {
            dataIndex: 'sort',
            width: 0,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
    ].concat(receiveColumns);

    return (
        <BaseModal
            className='common-modal'
            title={<span>{title}</span>}
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button
                    key='cancel'
                    loading={loading}
                    size='large'
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='save'
                    loading={loading}
                    type='primary'
                    size='large'
                    onClick={handleSubmit}
                >
                    保存
                </Button>
            ]}
        >
            <Spin spinning={loading}>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey='index'
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            </Spin>
        </BaseModal>
    );
};

export default EditOrderModal;

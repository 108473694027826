import {
    apiAccess,
    apiExternalServiceAccess,
    apiLongTimeoutAccess,
} from 'components/modules/AxiosWrapper';
import * as endpoints from 'constants/endpoints';
import { checkEnvironmentForControllable, checkEnvironmentIsTao } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

export const connection = store => next => action => {
    next(action);
    if (!action.type.match('^CONNECTION')) return;
    if (action.payload.method === null || !action.meta.fetch) return;

    const endpointPrefix = createEndpointPrefix(action);

    // POSTリクエスト
    if (action.payload.method === 'post') {
        apiAccess.post(`${endpointPrefix}${action.payload.api}`, action.payload.data, { timeout: action.payload.timeout })
            .then((response) => {
                console.log(response);
                if (response.data.is_successful) {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.SUCCESS,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                } else {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromResponse(response), true)
                    );
                }

            })
            .catch((error) => {
                console.error('Error', error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 412) {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                    } else if (error.response.status === 403) {
                        store.dispatch(Actions.data.session(true, true));
                    } else {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.FAILURE,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(error.response), true)
                        );
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    checkNetworkErrAndDispatch(store, next, action, error);

                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.ERROR,
                        },
                        payload: {
                            ...error.message,
                        },
                    }));
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromError(error), true)
                    );
                }
            });

    }
    // GETリクエスト
    if (action.payload.method === 'get') {
        apiAccess.get(`${endpointPrefix}${action.payload.api}`, { params: action.payload.data, timeout: action.payload.timeout })
            .then((response) => {
                if (response.data.is_successful) {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.SUCCESS,
                        },
                        payload: generatePayloadFromResponse(response), // JobModalで使うためにdetailを付与
                    })
                    );
                } else {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                    if (action.type !== Actions.http.connection.authentication.check().type) {
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(response), true)
                        );
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 412) {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                    } else if (error.response.status === 403) {
                        store.dispatch(Actions.data.session(
                            true,
                            true,
                            !!action.payload.api && action.payload.api.includes(endpoints.API_CHECK_LOGGED_IN)
                        ));
                    } else {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.FAILURE,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(error.response), true)
                        );
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    checkNetworkErrAndDispatch(store, next, action, error);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.ERROR,
                        },
                        payload: {
                            ...error.message,
                        },
                    }));
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromError(error), true)
                    );
                }
            });

    }
    // PUTリクエスト
    if (action.payload.method === 'put') {
        apiAccess.put(`${endpointPrefix}${action.payload.api}`, action.payload.data, { timeout: action.payload.timeout })
            .then((response) => {
                if (response.data.is_successful) {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.SUCCESS,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                } else {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromResponse(response), true)
                    );
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 412) {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                    } else if (error.response.status === 403) {
                        store.dispatch(Actions.data.session(true, true));
                    } else {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.FAILURE,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(error.response), true)
                        );
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    checkNetworkErrAndDispatch(store, next, action, error);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.ERROR,
                        },
                        payload: {
                            ...error.message,
                        },
                    }));
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromError(error), true)
                    );
                }
            });
    }
    // DELETEリクエスト
    if (action.payload.method === 'delete') {
        apiAccess.delete(`${endpointPrefix}${action.payload.api}`, action.payload.data)
            .then((response) => {

                if (response.data.is_successful) {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.SUCCESS,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                } else {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromResponse(response), true)
                    );
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 412) {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                    } else if (error.response.status === 403) {
                        store.dispatch(Actions.data.session(true, true));
                    } else {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.FAILURE,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(error.response), true)
                        );
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    checkNetworkErrAndDispatch(store, next, action, error);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.ERROR,
                        },
                        payload: {
                            ...error.message,
                        },
                    }));
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromError(error), true)
                    );
                }
            });
    }
    // Syncリクエスト
    if (action.payload.method === 'sync') {
        console.log(action.payload.data);
        apiLongTimeoutAccess.post(`${endpointPrefix}${action.payload.api}`, action.payload.data)
            .then((response) => {
                if (response.data.is_successful) {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.SUCCESS,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                } else {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                    if (action.type.match('DRY_RUN')) {
                        //ドライラン時の `is_successfule=false` は汎用エラー対象外とする
                    } else {
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(response), true)
                        );
                    }

                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...error.response.data,
                        },
                    })
                    );
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromResponse(error.response), true)
                    );


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    checkNetworkErrAndDispatch(store, next, action, error);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.ERROR,
                        },
                        payload: {
                            ...error.message,
                        },
                    }));
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromError(error), true)
                    );
                }
            });

    }

    if (action.payload.method === 'import') {
        apiLongTimeoutAccess.post(`${endpointPrefix}${action.payload.api}`, action.payload.data)
            .then((response) => {
                console.log(response);
                if (response.data.is_successful) {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.SUCCESS,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                } else {
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.FAILURE,
                        },
                        payload: {
                            ...response.data,
                        },
                    })
                    );
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromResponse(response), true)
                    );
                }

            })
            .catch((error) => {
                console.error('Error', error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 412) {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                    } else {
                        next(Object.assign(action, {
                            meta: {
                                ...action.meta,
                                fetch: false,
                                status: Actions.statusEnum.FAILURE,
                            },
                            payload: {
                                ...error.response.data,
                            },
                        })
                        );
                        store.dispatch(
                            Actions.data.failure(generatePayloadFromResponse(error.response), true)
                        );
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    checkNetworkErrAndDispatch(store, next, action, error);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    next(Object.assign(action, {
                        meta: {
                            ...action.meta,
                            fetch: false,
                            status: Actions.statusEnum.ERROR,
                        },
                        payload: {
                            ...error.message,
                        },
                    }));
                    store.dispatch(
                        Actions.data.failure(generatePayloadFromError(error), true)
                    );
                }
            });

    }
};

const createEndpointPrefix = (action) => {
    if (action.type.match('/FILE/')) {
        return checkEnvironmentForControllable() ? '/control' : '';
    } else {
        if (action.type.match('/AUTHENTICATION/')) {
            return checkEnvironmentForControllable() ? '/control' : '';
        } else {
            return checkEnvironmentForControllable() ? '/control' : '/admin';
        }
    }
};

/***
 * ネットワークの状況をチェックして、それに応じてエラーを処理する
 *  1. CORSチェックをして、CORSなら通常のエラーをdispatchして終了。CORSでないなら、'タイムアウトが発生しました'メッセージとローディングを表示
 *  2. window.navigatorを用いてonline調査を行う --> ネットないなら「インターネット接続がありません」
 *  3. 弊社sakuraサーバへの通信チェック用通信を行う --> sakura駄目なら「インターネット接続が不安定です」
 *  4. sakuraサーバへは接続ができた場合「L-Gateサーバからの応答がありません」
 * @param store
 * @param next
 * @param action
 * @param error
 */
const checkNetworkErrAndDispatch = (store, next, action, error) => {
    const newAction = createNewAction(store, action);
    if (newAction) {
        next(newAction);
        return;
    }

    next(Object.assign(action, {
        meta: {
            ...action.meta,
            fetch: false,
            status: Actions.statusEnum.ERROR,
        },
        payload: {
            ...error.request,
        },
    }));

    // すでにfailureがローディング中であれば、リクエスト中であるため、failureを上書きして変更しない
    if (store.getState().failure.meta.loading) return;

    const errorJson = error.toJSON();
    if (isCorsByErrorJson(errorJson)) {
        store.dispatch(
            Actions.data.failure(generatePayloadFromError(error), true)
        );
        return;
    } else {
        store.dispatch(
            Actions.data.failure(
                generatePayloadFromResponse(
                    { ...errorJson, data: { result: { errors: ['タイムアウトが発生しました'] } } })
                , true, true)
        );
    }

    if (window.navigator.onLine === false) {
        store.dispatch(
            Actions.data.failure(generatePayloadFromResponse(
                { ...errorJson, data: { result: { errors: ['タイムアウトが発生しました', 'インターネットに接続されていません。', 'Wi-Fiの電波状況や、インターネットを正常に利用できているか確認ください。'] } } }
            ), true, false)
        );
        return;
    }

    apiExternalServiceAccess.head('https://l-gate-status.info/timeout-check/')
        .then(() => {
            store.dispatch(
                Actions.data.failure(generatePayloadFromResponse(
                    {
                        ...errorJson,
                        data: {
                            result: {
                                errors: checkEnvironmentIsTao() ?
                                    ['タイムアウトが発生しました', '接続できませんでした。', '時間をおいて再度アクセスしてください。'] :
                                    ['タイムアウトが発生しました', 'L-Gateに接続できませんでした。', '時間をおいて再度アクセスしてください。稼働状況は&lt;こちら&gt;から確認できます。'],
                            },
                        },
                    }
                ), true, false)
            );
        }).catch(() => {
            store.dispatch(
                Actions.data.failure(generatePayloadFromResponse(
                    { ...errorJson, data: { result: { errors: ['タイムアウトが発生しました', 'インターネットが不安定なようです。', 'Wi-Fiの電波状況や、インターネットを正常に利用できているか確認ください。'] } } }
                ), true, false)
            );
        });
};



/***
 * レスポンスを受け取れた場合のペイロードを作成
 * 通常のペイロードの内容に加えて、以下を含むdetails情報を付加する
 * 発生日時、エラーコード、リクエストメソッド、リクエストURL、追跡情報、ユーザ情報エージェント
 * @param response
 * @return {result:{}, details:{}}
 */
export const generatePayloadFromResponse = (response) => {
    const details = {};
    if (response.config) {
        details.method = response.config.method.toUpperCase();
        details.url = response.config.url;
        details.baseURL = response.config.baseURL;
    }
    if (response.headers) {
        details.trackingInfo = response.headers['x-azure-ref'];
    }
    details.date = response.headers ? response.headers.date : new Date();

    details.code = response.status;
    details.userAgent = navigator.userAgent;
    details.frontAppVersion = '1.0.0';

    if (response.data) {
        return Object.assign(response.data, { details: details });
    } else {
        return { details: details };
    }
};

/***
 * レスポンスを受け取れなかった場合のエラーペイロードを作成
 * 通常のペイロードの内容に加えて、以下を含むdetails情報を付加する
 * 発生日時、エラーコード、リクエストメソッド、リクエストURL、追跡情報、ユーザ情報エージェント
 * @param error
 * @return {result: {}, details: {}}
 */
export const generatePayloadFromError = (error) => {
    console.log(error);
    const errorJson = error.toJSON();

    const isCors = isCorsByErrorJson(errorJson);

    const errorPayload = isCors ?
        { result: { message: 'テナントが見つかりません。URLが正しく入力されているか確認してください。' } } :
        error.request ? { errorRequest: error.request } : { error: error };
    isCors && console.log('isCors');

    const details = {};
    if (errorJson.config) {
        details.method = errorJson.config.method.toUpperCase();
        details.url = errorJson.config.url;
        details.baseURL = errorJson.config.baseURL;
    }
    details.date = new Date();
    details.trackingInfo = '';

    details.code = errorJson.code;
    details.userAgent = navigator.userAgent;
    details.frontAppVersion = '1.0.0';

    return Object.assign({ ...errorPayload }, { details: details });
};



/***
 * テナントURL間違いによるCORS時のエラー表示
 * @param {{config: {url: string}, message:string, code:string}} errorJson
 * @return {boolean}
 */
const isCorsByErrorJson = (errorJson) => {
    const endpointPrefix = checkEnvironmentForControllable() ? '/control' : '';
    const targetUrls = [endpointPrefix + endpoints.API_CHECK_LOGGED_IN, endpointPrefix + endpoints.API_AUTH_ADMIN];
    const isCors = errorJson.code === undefined && errorJson.message === 'Network Error' &&
        targetUrls.includes(errorJson.config.url);

    return isCors;
};

/**
 * タイムアウトなどのエラーが発生した際に, エラーを表示せず, 通常表示するための新しい Action を生成する.
 *
 * 新しい Action を生成する必要がない場合は, undefined を返す.
 */
const createNewAction = (store, action) => {
    switch (action.type) {
        case Actions.http.connection.questionnaires.controlAnswerResult.toString(): {
            // controlAnswerResult は内容によってサーバー側の処理が長くなるので,
            // タイムアウトした際にエラーとせずに, 別の手段での確認を促す.
            if (!action.meta.fetch || action.meta.status !== Actions.statusEnum.REQUEST) return;

            const questionnaireTab = store.getState()['questionnaireTab'];
            const questionnaire = questionnaireTab.questionnaireList.find(item => item.uuid === action.meta.uuid);

            return {
                ...action,
                meta: {
                    ...action.meta,
                    fetch: false,
                    // savingData で処理を実行するために status を SUCCESS にする必要がある.
                    status: Actions.statusEnum.SUCCESS,
                },
                payload: {
                    result: {
                        ...questionnaire,
                        isShow: false,
                    },
                },
            };
        }
        default: {
            return undefined;
        }
    }
};
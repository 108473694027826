import * as React from 'react';

import { FormInstance } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import { generateTenantDomain } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';
import './ConnectionInfoTable.scss';

const Form = BaseForm;

// 本来は、きちんと Key などをキャメルケースに変換したものを使いたいが、
// 実装時にはそういった変換などの処理がないので、
// 仕方なくレスポンスをそのまま使う。
type AppTemplate = Http.Connection.Response.AppTemplate.Search['result']['items'][number];

type Props = {
    appTemplate?: AppTemplate | null
    form: FormInstance
    isCreate: boolean
};

const AUTH = 'lti/app-oidc/auth';
const TOKEN = 'lti/app-oauth/token';
const JWKS = 'lti/app-oidc/jwks';

const ConnectionInfoTable: React.VFC<Props> = ({
    appTemplate,
    form,
    isCreate,
}) => {

    const tenantId = useAppSelector(state => state.tenant.payload.uuid);
    const endpoint = generateTenantDomain();
    const ltiUrlCandidateUuid = Form.useWatch('ltiUrlCandidateUuid', form);

    if (!appTemplate || appTemplate.type === 1) return <></>;

    if (isCreate) {
        return (
            <div className='connection-info-table'>
                <div className='title'>
                    L-Gate の接続情報
                </div>
                <div className='contents'>
                    アプリ配信の登録時に生成します。登録後に再度ご確認ください。
                </div>
            </div>
        );
    }

    const clientId = getClientId(appTemplate, ltiUrlCandidateUuid);

    return (
        <div className='connection-info-table'>
            <div className='title'>
                L-Gate の接続情報
            </div>
            <div className='contents'>

                <div className='row'>
                    <div className='name'>
                        Issuer
                    </div>
                    <div className='content'>
                        {endpoint}
                    </div>
                </div>

                <div className='row'>
                    <div className='name'>
                        クライアント ID
                    </div>
                    <div className='content'>
                        {clientId}
                    </div>
                </div>

                <div className='row'>
                    <div className='name'>
                        認証リクエスト URL
                    </div>
                    <div className='content'>
                        {`${endpoint}/${AUTH}`}
                    </div>
                </div>

                <div className='row'>
                    <div className='name'>
                        アクセストークン URL
                    </div>
                    <div className='content'>
                        {`${endpoint}/${TOKEN}/${tenantId}/${clientId}`}
                    </div>
                </div>

                <div className='row'>
                    <div className='name'>
                        公開鍵 URL
                    </div>
                    <div className='content'>
                        {`${endpoint}/${JWKS}/`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectionInfoTable;


const getClientId = (appTemplate: AppTemplate, ltiUrlCandidateUuid?: string) => {
    if (appTemplate.type === 1) return '';

    const url = appTemplate.url;

    return url.is_choices ?
        url.candidates.find(candidate => candidate.uuid === ltiUrlCandidateUuid)?.param_json.client_id :
        url.param_json.client_id;
};
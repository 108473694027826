import { createAction } from 'redux-actions';
import {
    API_MANUAL,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/MANUAL';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const get = createAction(
    createActionType('GET' as const),
    () => ({ method: 'get', api: `${API_MANUAL}/get` }),
    () => ({ fetch: true, status: StatusEnum.REQUEST }),
);
import { createAction } from 'redux-actions';

import { API_TAO_EXAM_CATEGORY, API_TAO_CONTROL_EXAM_CATEGORY } from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

import type { Meta, Payload } from '../../utils';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/TAO/EXAM_CATEGORY';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
};


type DownloadCsvParameter = Http.Connection.Request.Parameter.ExamCategory.DownloadCsv;
export const downloadCsv = createAction<
    Payload,
    Meta,
    string,
    string,
    DownloadCsvParameter
>(
    createActionType('DOWNLOAD_CSV' as const),
    (uuid, encoding, data) => {
        return ({
            data,
            method: 'get',
            api: `${API_TAO_EXAM_CATEGORY}/download-csv/${uuid}?encoding=${encoding}`,
        });
    },
    (uuid, encoding) => ({ fetch: true, someUuid: uuid, status: StatusEnum.REQUEST })
);

type ControlDownloadCsvParameter = Http.Connection.Request.Parameter.ExamCategory.ControlDownloadCsv;
export const controlDownloadCsv = createAction<
    Payload,
    Meta,
    string,
    string,
    ControlDownloadCsvParameter
>(
    createActionType('CONTROL_DOWNLOAD_CSV' as const),
    (uuid, encoding, data) => {
        return ({
            data,
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/download-csv/${uuid}?encoding=${encoding}`,
        });
    },
    (uuid, encoding) => ({ fetch: true, someUuid: uuid, status: StatusEnum.REQUEST })
);

export const graph = createAction<
    Payload | Http.Connection.Response.ExamCategory.Graph,
    Meta,
    Partial<{
        average_per_end: number
        average_per_start: number
        end_at: number
        is_mine: 0 | 1
        page_size: number
        start_at: number
        time_unit: 'day' | 'week' | 'month' // 必須
        title: string
    }>,
    number
>(
    createActionType('GRAPH' as const),
    (data, page) => {
        const pageNum = page === undefined ? 1 : page;

        return {
            method: 'get',
            api: `${API_TAO_EXAM_CATEGORY}/graph/${pageNum}`,
            data,
        };
    },
    (data, page) => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlGraph = createAction<
    Payload | Http.Connection.Response.ExamCategory.Graph,
    Meta,
    Partial<{
        average_per_end: number
        average_per_start: number
        end_at: number
        is_mine: 0 | 1
        page_size: number
        start_at: number
        time_unit: 'day' | 'week' | 'month' // 必須
        title: string
    }>,
    number
>(
    createActionType('CONTROL_GRAPH' as const),
    (data, page) => {
        const pageNum = page === undefined ? 1 : page;

        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/graph/${pageNum}`,
            data,
        };
    },
    (data, page) => ({ fetch: true, status: StatusEnum.REQUEST })
);

type SearchParameter = Http.Connection.Request.Parameter.ExamCategory.Search;
export const search = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.ExamCategory.Search,
    Meta<{ viewName: string }>,
    SearchParameter,
    number | undefined,
    string
>(
    createActionType('SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? 1 : page;
        return ({ method: 'get', data, api: `${API_TAO_EXAM_CATEGORY}/${pageNum}` });
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

// バックエンドは with-result のみだが、わかりにくいので search を追加している
export const searchWithResult = createAction<
    Payload | Http.Connection.Response.ExamCategory.SearchWitchResult,
    Meta,
    Partial<{
        average_per_end: number
        average_per_start: number
        end_at: number
        is_mine: 0 | 1
        page_size: number
        start_at: number
        time_unit: 'day' | 'week' | 'month' // 必須
        title: string
    }>,
    number
>(
    createActionType('SEARCH_WITH_RESULT' as const),
    (data, page) => {
        const pageNum = page === undefined ? 1 : page;

        return {
            method: 'get',
            api: `${API_TAO_EXAM_CATEGORY}/with-result/${pageNum}`,
            data,
        };
    },
    (data, page) => ({ fetch: true, status: StatusEnum.REQUEST })
);


// バックエンドは with-result のみだが、わかりにくいので search を追加している
export const controlSearchWithResult = createAction<
    Payload | Http.Connection.Response.ExamCategory.SearchWitchResult,
    Meta,
    Partial<{
        average_per_end: number
        average_per_start: number
        end_at: number
        is_mine: 0 | 1
        page_size: number
        start_at: number
        time_unit: 'day' | 'week' | 'month' // 必須
        title: string
    }>,
    number
>(
    createActionType('CONTROL_SEARCH_WITH_RESULT' as const),
    (data, page) => {
        const pageNum = page === undefined ? 1 : page;

        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/with-result/${pageNum}`,
            data,
        };
    },
    (data, page) => ({ fetch: true, status: StatusEnum.REQUEST })
);


export const subjectList = createAction(
    createActionType('SUBJECT_LIST' as const),
    () => {
        return ({
            method: 'get',
            api: `${API_TAO_EXAM_CATEGORY}/subject-list`,
        });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

// admin では使わないが、 control で使う
export const controlSubjectList = createAction(
    createActionType('CONTROL_SUBJECT_LIST' as const),
    () => {
        return ({
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/subject-list`,
        });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const view = createAction<
    Payload | Http.Connection.Response.ExamCategory.View,
    Meta,
    string,
    string
>(
    createActionType('VIEW' as const),
    (uuid, viewName) => ({
        method: 'get',
        api: `${API_TAO_EXAM_CATEGORY}/view/${uuid}`,
    }),
    (uuid, viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName,
    })
);

export const controlView = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlView,
    Meta,
    string,
    string
>(
    createActionType('CONTROL_VIEW' as const),
    (uuid, viewName) => ({
        method: 'get',
        api: API_TAO_CONTROL_EXAM_CATEGORY + '/view/' + uuid,
    }),
    (uuid, viewName) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        viewName,
    })
);

type CreateParameter = Http.Connection.Request.Parameter.ExamCategory.Create;
export const create = createAction<
    Payload<{ data: CreateParameter }> | Http.Connection.Response.ExamCategory.Create,
    Meta,
    string,
    CreateParameter,
    string
>(
    createActionType('CREATE' as const),
    (uuid, data, viewName) => {
        const api = uuid ?
            API_TAO_EXAM_CATEGORY + '/create/' + uuid :
            API_TAO_EXAM_CATEGORY + '/create';
        return({ method: 'put', api: api, data });
    },
    (uuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

type UpdateParameter = Http.Connection.Request.Parameter.ExamCategory.Update;
export const update = createAction<
    Payload<{ data: UpdateParameter }> | Http.Connection.Response.ExamCategory.Update,
    Meta,
    string,
    UpdateParameter,
    string
>(
    createActionType('UPDATE' as const),
    (uuid, data, viewName) => ({
        method: 'post',
        api: API_TAO_EXAM_CATEGORY + '/update/' + uuid,
        data,
    }),
    (uuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

const deleteAction = createAction<
    Payload | Http.Connection.Response.ExamCategory.Delete,
    Meta<{ viewName: string }>,
    string,
    string
>(
    createActionType('DELETE' as const),
    (uuid, _viewName) => ({ method: 'delete', api: API_TAO_EXAM_CATEGORY + '/delete/' + uuid }),
    (_uuid, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName }),
);
export { deleteAction as delete };

type ResultParameter = Http.Connection.Request.Parameter.ExamCategory.Result;
export const result = createAction<
    Payload<{ data: ResultParameter }> | Http.Connection.Response.ExamCategory.Result,
    Meta<{ viewName: string }>,
    string,
    ResultParameter,
    number,
    string
>(
    createActionType('RESULT' as const),
    (uuid, data, page, viewName) => {
        const pageNum = page === undefined ? '1' : page;
        return {
            method: 'get',
            api: API_TAO_EXAM_CATEGORY + '/result/' + uuid + '/' + pageNum,
            data,
        };
    },
    (uuid, data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const changeOrder = createAction<
    Payload,
    Meta,
    string,
    {uuids: string[]}
>(
    createActionType('CHANGE_ORDER' as const),
    (uuid, data) => ({
        method: 'post',
        api: `${API_TAO_EXAM_CATEGORY}/change-order/${uuid}`,
        data,
    }),
    (uuid, data) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const userStatistics = createAction<
    Payload | Http.Connection.Response.ExamCategory.UserStatistics,
    Meta<{ viewName: string }>,
    string,
    string,
    { latest_result_only?: '0' | '1' },
    string
>(
    createActionType('USER_STATISTICS' as const),
    (examCategoryUuid, userUuid, data, viewName) => {
        return {
            method: 'get',
            api: API_TAO_EXAM_CATEGORY + `/user-statistics/${examCategoryUuid}/${userUuid}`,
            data,
        };
    },
    (examCategoryUuid, userUuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const controlUserStatistics = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlUserStatistics,
    Meta<{ viewName: string }>,
    string,
    string,
    { latest_result_only?: '0' | '1' },
    string
>(
    createActionType('CONTROL_USER_STATISTICS' as const),
    (examCategoryUuid, userUuid, data, viewName) => {
        return {
            method: 'get',
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/user-statistics/${examCategoryUuid}/${userUuid}`,
        };
    },
    (examCategoryUuid, userUuid, data, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);


export const saveReport = createAction<
    Payload | Http.Connection.Response.ExamCategory.SaveReport,
    Meta,
    string,
    { exam_uuids: string[] },
    string
>(
    createActionType('SAVE_REPORT' as const),
    (examCategoryUuid, data, _) => {
        return {
            api: `${API_TAO_EXAM_CATEGORY}/save-report/${examCategoryUuid}`,
            data,
            method: 'get',
        };
    },
    (examCategoryUuid, _, groupName) => ({
        fetch: true,
        groupName,
        someUuid: examCategoryUuid,
        status: StatusEnum.REQUEST,
    })
);

export const controlSaveReport = createAction<
    Payload | Http.Connection.Response.ExamCategory.SaveReport,
    Meta,
    string,
    { exam_uuids: string[] },
    string
>(
    createActionType('CONTROL_SAVE_REPORT' as const),
    (examCategoryUuid, data, _) => {
        return {
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/save-report/${examCategoryUuid}`,
            data,
            method: 'get',
        };
    },
    (examCategoryUuid, _, groupName) => ({
        fetch: true,
        groupName,
        someUuid: examCategoryUuid,
        status: StatusEnum.REQUEST,
    })
);

export const targetOrganization = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlTargetOrganization,
    Meta,
    string
>(
    createActionType('TARGET_ORGANIZATION' as const),
    (examCategoryUuid) => {
        return {
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/target-organization/${examCategoryUuid}`,
            method: 'get',
        };
    },
    (examCategoryUuid) => ({
        fetch: true,
        someUuid: examCategoryUuid,
        status: StatusEnum.REQUEST,
    })
);

export const controlTargetSchoolClass = createAction<
    Payload | Http.Connection.Response.ExamCategory.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_SCHOOL_CLASS' as const),
    (examCategoryUuid) => {
        return {
            api: `${API_TAO_CONTROL_EXAM_CATEGORY}/target-school-class/${examCategoryUuid}`,
            method: 'get',
        };
    },
    (examCategoryUuid) => ({
        fetch: true,
        someUuid: examCategoryUuid,
        status: StatusEnum.REQUEST,
    })
);


export const targetSchoolClass = createAction<
    // 一旦、control と合わせる
    // 使用時に適切な型に変更する
    Payload | Http.Connection.Response.ExamCategory.ControlTargetSchoolClass,
    Meta,
    string,
    boolean
>(
    createActionType('TARGET_SCHOOL_CLASS' as const),
    (examCategoryUuid, isControllableUser) => {
        return {
            api: isControllableUser ?
                `${API_TAO_EXAM_CATEGORY}/target-school-class/${examCategoryUuid}` :
                `${API_TAO_EXAM_CATEGORY}/target-school-classes/${examCategoryUuid}`,
            method: 'get',
        };
    },
    (examCategoryUuid) => ({
        fetch: true,
        someUuid: examCategoryUuid,
        status: StatusEnum.REQUEST,
    })
);
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Actions from 'flex/Actions';
import { ListContext } from '../index';


function TermEffectComponent(props) {
    const [list, setList] = useContext(props.context ? props.context : ListContext);

    const termSearchConnection = useSelector(state => state.termSearchConnection);

    useEffect(() => {
        if (termSearchConnection.meta.status === Actions.statusEnum.SUCCESS) {
            console.log(termSearchConnection);
            setList({
                ...list,
                termList: termSearchConnection.payload.result.items,
                termListItemCount: termSearchConnection.payload.result.item_count
            });
        }
        if (termSearchConnection.meta.status === Actions.statusEnum.FAILURE || termSearchConnection.meta.status === Actions.statusEnum.ERROR) {
            //OPTIMIZE 基本的には汎用エラーコンポーネントがエラーをスポイルしてここでは何も起こらないのが正なので、処理しない
        }
    }, [termSearchConnection]);// eslint-disable-line

    return null;
};

export default TermEffectComponent;

import React from 'react';
import { Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getMoment } from 'constants/GlobalConfig';

const moment = getMoment();

type Props = {
    title: string,
    publishStart: number,
    publishEnd: number,
    fullScore: number,
    isUsable: boolean,
    maxAttempts: number,
};

/***
 * テーブルのヘッダが横に表示されるテーブル
 * @param props
 * @constructor
 */
export const ApplicationInfomationTable: React.VFC<Props> = (props) => {
    const isIndefinitePeriod = props.publishEnd >= 2147483647;
    const publishStart = moment.unix(props.publishStart).format('YYYY/MM/DD HH:mm');
    const publishEnd = isIndefinitePeriod
        ? ''
        : moment.unix(props.publishEnd).format('YYYY/MM/DD HH:mm');

    return (
        <div>
            <div>
                <Typography.Text style={{ margin: '0.5rem' }}>
                    教材・アプリの情報
                </Typography.Text>
                <Tooltip title='この教材・アプリの情報です。'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <table className='test-info-table'>
                <tbody>
                    <tr>
                        <th colSpan={2}>概要</th>
                    </tr>
                    <tr>
                        <th>タイトル</th>
                        <td>{props.title}</td>
                    </tr>
                    <tr>
                        <th>
                            <span style={{ marginRight: '0.5em' }}>
                                公開期間
                            </span>
                            <Tooltip title='この教材・アプリが公開されている期間です。'>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </th>
                        <td>{`${publishStart} ~ ${publishEnd}`}</td>
                    </tr>
                    <tr>
                        <th>
                            <span style={{ marginRight: '0.5em' }}>
                                受検可能回数
                            </span>
                            <Tooltip title='1人のユーザーがこの教材・アプリを受検できる回数です。'>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </th>
                        <td>{props.maxAttempts ? `${props.maxAttempts} 回` : '制限回数なし'}</td>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <th colSpan={2}>採点情報</th>
                    </tr>
                    <tr>
                        <th>満点</th>
                        <td>{`${props.fullScore} 点`}</td>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <th colSpan={2}>解答結果の確認ページ</th>
                    </tr>
                    <tr>
                        <th>
                            <span style={{ marginRight: '0.5em' }}>
                                利用可否
                            </span>
                            <Tooltip title='「利用可能」となっている場合、解答終了後にL-Gateから答案確認ページを起動できます。'>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </th>
                        <td>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div
                                    style={{
                                        height: '0px',
                                        width: '0px',
                                        border: `${props.isUsable ? 'green' : 'red'} solid 5px`,
                                        borderRadius: '50%',
                                        margin: '0.5rem'
                                    }}
                                />
                                <span>{props.isUsable ? '利用可能' : '利用不可'}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

import { useContext, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from 'flex/Actions';
import { checkEnvironmentForControllable, generateApiDomain } from 'constants/GlobalConfig';
import { LocationContext } from 'App';

export default function AuthEffectComponent() {
    const [previewLocation] = useContext(LocationContext);
    const checkLoginConnection = useSelector(state => state.checkLoginConnection);
    const loginConnection = useSelector(state => state.loginConnection);
    const logoutConnection = useSelector(state => state.logoutConnection);
    const tenantConnection = useSelector(state => state.tenantConnection);
    const dispatch = useDispatch();

    const isFirstCheckRender = useRef(false)

    useEffect(() => {
        isFirstCheckRender.current = true;
    }, []);

    useEffect(() => {
        if (isFirstCheckRender.current) {
            if (checkLoginConnection.meta.status === Actions.statusEnum.REQUEST) {
                dispatch(Actions.data.session(false));
            }
        }
        if (checkLoginConnection.meta.status === Actions.statusEnum.SUCCESS) {
            dispatch(Actions.data.session(checkLoginConnection.payload.is_successful));
            dispatch(Actions.data.user(checkLoginConnection.payload.is_successful ? checkLoginConnection.payload.user : null));
            dispatch(Actions.data.tenant(checkLoginConnection.payload.result.tenant));
            // どのページでも利用され、どのユーザでも、頻繁に変更されるものではないため、ここで取得しておく
            dispatch(Actions.http.connection.term.search({ page_size: '50' }, undefined, 'globalState'));
            isFirstCheckRender.current = false;
        }
        if (checkLoginConnection.meta.status === Actions.statusEnum.FAILURE) {
            if (previewLocation !== "/login") {
                //loginページからではないので、該当画面に遷移させる
                if (!checkEnvironmentForControllable()) {
                    if (checkLoginConnection.payload.result?.login_url) {
                        window.location.replace(`${generateApiDomain(true)}${checkLoginConnection.payload.result.login_url}`)
                    } else {
                        //local接続時はlogin_urlが帰ってこないため、サインインページに遷移
                        window.location.replace(`/login`);
                    }
                } else {
                    window.location.replace(`/login`);
                }
            } else {
                //何もしない
            }
            dispatch(Actions.data.session(false));
            isFirstCheckRender.current = false;
        }
        if (checkLoginConnection.meta.status === Actions.statusEnum.ERROR) {
            //想定しないエラー
            isFirstCheckRender.current = false;
        }
    }, [checkLoginConnection, dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loginConnection.meta.status === Actions.statusEnum.SUCCESS && loginConnection.payload.is_successful === true) {
            //サインイン完了時
            dispatch(Actions.data.session(true));
            dispatch(Actions.data.user(loginConnection.payload.user));
            //バックグラウンドでテナントに関する情報を取得
            dispatch(Actions.http.connection.authentication.tenant());
            // どのページでも利用され、どのユーザでも、頻繁に変更されるものではないため、ここで取得しておく
            dispatch(Actions.http.connection.term.search({ page_size: '50' }, undefined, 'globalState'));
        }
        if (loginConnection.meta.status === Actions.statusEnum.FAILURE || loginConnection.meta.status === Actions.statusEnum.ERROR) {
            dispatch(Actions.data.session(false));
        }
    }, [loginConnection, dispatch]);


    useEffect(() => {
        if (logoutConnection.meta.status === Actions.statusEnum.SUCCESS) {
            dispatch(Actions.data.session(false));
            dispatch(Actions.data.user(null));
        }
        if (logoutConnection.meta.status === Actions.statusEnum.FAILURE || logoutConnection.meta.status === Actions.statusEnum.ERROR) {
            //OPTIMIZE ここ、怪しいな･･････
            dispatch(Actions.data.session(false));
            dispatch(Actions.data.user(null));
        }
    }, [logoutConnection, dispatch]);


    useEffect(() => {
        if (tenantConnection.meta.status === Actions.statusEnum.SUCCESS) {
            dispatch(Actions.data.tenant(
                tenantConnection.payload.result
            ))
        }
    }, [tenantConnection, dispatch]);


    return null;
}

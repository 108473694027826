import * as React from 'react';
import { Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faSitemap } from '@fortawesome/free-solid-svg-icons';
import {
    useTabManagement,
} from 'constants/CustomHooks/useTabManagement';
import BoardOfEducationTabContents from './BoardOfEducationTabContents';
import SchoolTabContents from './SchoolTabContents';
const { TabPane } = Tabs;

export const TAB_KEYS = {
    BOARD_OF_EDUCATION: 'board' as const,
    SCHOOL: 'school' as const,
}

const AdminContents: React.VFC = () => {
    const [tabActiveKey, setTabActiveKey] = useTabManagement(TAB_KEYS, TAB_KEYS.SCHOOL);

    return (
        <Tabs
            activeKey={tabActiveKey}
            onChange={setTabActiveKey}
        >
            <TabPane
                tab={<SchoolTabTitle />}
                key={TAB_KEYS.SCHOOL}
            >
                <SchoolTabContents
                    activeTab={tabActiveKey}
                />
            </TabPane>
            <TabPane
                tab={<BoardOfEducationTabTitle />}
                key={TAB_KEYS.BOARD_OF_EDUCATION}
            >
                <BoardOfEducationTabContents
                    activeTab={tabActiveKey}
                />
            </TabPane>
        </Tabs>
    );
};

export default AdminContents;

const SchoolTabTitle: React.VFC = () => (
    <span>
        <FontAwesomeIcon style={{ marginRight: 10, height: 14, width: 14 }} icon={faSchool} />
        学校配信
    </span>
);

const BoardOfEducationTabTitle: React.VFC = () => (
    <span>
        <FontAwesomeIcon style={{ marginRight: 10, height: 14, width: 14 }} icon={faSitemap} />
        一斉配信
    </span>
);
import * as Actions from 'flex/Actions';

export const answerResultSavingData = (store, next, action) => {
    const viewState = store.getState()['answerResultModal'];
    const pagination = viewState['pagination'];

    switch (action.type){
        case Actions.view.viewLogic.answerResultModal.savePage.toString():
            next({
                ...viewState,
                pagination: {
                    ...pagination,
                    pageSize: action.payload.page_size,
                    current: action.payload.page,
                },
                type: 'answerResultModal'
            })
            break;
        default:
            break;
    }
}

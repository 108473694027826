import {
    useContext,
    useState,
    useEffect,
    createContext,
    Dispatch,
    SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';

import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import { Result, Typography } from 'antd';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation,
    useParams,
    RouteProps,
} from 'react-router-dom';

import IpRestrictions from 'components/modules/IpRestrictions';
import StyleSwitcher, { StyleNames } from 'components/modules/StyleSwitcher';
import { checkEnvironmentForControllable, checkEnvironmentIsTao } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';

import AuthEffectComponent from './components/modules/Auth/AuthEffectComponent';
import GateProgress from './components/modules/GateProgress';
import JobModal from './components/modules/Job/JobModal';
import Message from './components/modules/Message/Message';
import Progress from './components/modules/Progress';
import Apps from './components/presentational/pages/Apps';
import AppTemplate from './components/presentational/pages/AppTemplate';
import { Assessment } from './components/presentational/pages/Assessment';
import {
    AppDeepLinkingResponse,
} from './components/presentational/pages/DeepLinkings/AppDeepLinkingResponse';
import {
    DeepLinkingResponse,
} from './components/presentational/pages/DeepLinkings/DeepLinkingResponse';
import EverydayNote from './components/presentational/pages/EverydayNote';
import Top from './components/presentational/pages/Home';
import Job from './components/presentational/pages/Job';
import Login from './components/presentational/pages/Login/Login';
import Notifications from './components/presentational/pages/Notifications';
import Organization from './components/presentational/pages/Organization';
import Questionnaire from './components/presentational/pages/Questionnaire';
import Security from './components/presentational/pages/Security';
import Usage from './components/presentational/pages/Usage';
import UsersManagement from './components/presentational/pages/UsersManagement';
import BaseLayout from './components/presentational/templates/BaseLayout';
import * as Actions from './flex/Actions';

const { Paragraph, Text } = Typography;

export const LocationContext = createContext<[
    Location['pathname'],
    Dispatch<SetStateAction<Location['pathname']>>
]>(['', () => undefined]);


const App = () => {
    const [previewLocation, setPreviewLocation] = useState<Location['pathname']>('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (checkEnvironmentForControllable()) {
            dispatch(Actions.data.isControllableUser(true));
        } else {
            dispatch(Actions.data.isControllableUser(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!checkEnvironmentIsTao()) return;

        const fav = document.getElementById('fav');
        fav?.setAttribute('href', 'favicon-m.ico');

        const icon = document.getElementById('apple-touch-icon');
        icon?.setAttribute('href', 'logo192-m.png');

        const manifest = document.getElementById('manifest');
        manifest?.setAttribute('href', 'mexcbt-manifest.json');

        const description = document.querySelector('meta[name="description"]');
        description?.setAttribute('content', '実証用学習eポータル');

        const title = document.querySelector('title');
        title?.setAttribute('innerText', '実証用学習eポータル');
    }, []);

    return (
        <Router>
            <LocationContext.Provider value={[previewLocation, setPreviewLocation]}>
                <StyleSwitcher
                    styleName={
                        checkEnvironmentIsTao() ? StyleNames.Mexcbt : StyleNames.Default
                    }
                >
                    <Switch>
                        <Route exact path='/login'>
                            <PublicPage />
                        </Route>
                        <Route path='/error/:tag'>
                            <Error />
                        </Route>
                        <PrivateRoute>
                            <ProtectedPage />
                        </PrivateRoute>
                    </Switch>
                    <AuthEffectComponent />
                    <Message />
                </StyleSwitcher>
            </LocationContext.Provider>
        </Router>
    );
};

export default App;


const PrivateRoute: React.FC<RouteProps<string>> = ({ children, ...rest }) => {
    const [, setPreviewLocation] = useContext(LocationContext);
    const session = useAppSelector(state => state.session);
    const checkLoginConnection = useAppSelector(state => state.checkLoginConnection);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        setPreviewLocation(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        //Sessionページが必要かどうかをここで判断してよしなに処理
        if (process.env.REACT_APP_HAVE_SESSION === 'true' && !session.payload.is_authenticated) {
            dispatch(Actions.http.connection.authentication.check());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (session.payload.is_ip_restricted) {
        return <IpRestrictions />;
    }

    if (checkLoginConnection.meta.fetch || (!session.payload.is_checked && !session.payload.is_ip_restricted)) {
        return <Progress />;
    }

    return (
        <Route
            {...rest}
            render={() =>
                session.payload.is_required_session ?
                    session.payload.is_authenticated ?
                        (
                            children
                        ) :
                        (
                            <GateProgress />
                        ) :
                    (children)
            }
        />
    );
};


/** 未認証時のページ */
const PublicPage = ({ ...rest }) => {
    const [previewLocation, setPreviewLocation] = useContext(LocationContext);
    const session = useAppSelector(state => state.session);
    const checkLoginConnection = useAppSelector(state => state.checkLoginConnection);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        setPreviewLocation(location.pathname);
        if (process.env.REACT_APP_HAVE_SESSION === 'true' && !session.payload.is_authenticated) {
            dispatch(Actions.http.connection.authentication.check());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (session.payload.is_ip_restricted) {
        return <IpRestrictions />;
    }

    return (
        checkLoginConnection.meta.fetch || !session.payload.is_checked ?
            <Progress /> :
            <Route
                {...rest}
                render={({ location }) =>
                    session.payload.is_authenticated ?
                        (
                            <Redirect
                                to={{
                                    pathname: previewLocation && previewLocation !== '/login' ? previewLocation : '/',
                                    state: { from: location },
                                }}
                            />
                        ) :
                        (
                            <Login />
                        )
                }
            />
    );
};

/** 認証時のページ */
const ProtectedPage = () => {
    return (
        <Router>
            <JobModal />
            <Switch>
                {/** BaseLayout を使わなずに表示するページは, */}
                {/** BaseLayOutPage より上に書かないと Not Found が表示されてしまう */}
                <Route component={AppDeepLinkingResponse} path='/app-deeplinkingresponse/confirm/' />
                <Route component={DeepLinkingResponse} path='/deeplinkingresponse/confirm/' />
                <Route component={BaseLayoutPage} />
            </Switch>
        </Router>
    );
};

/** BaseLayout で表示するページ */
const BaseLayoutPage = () => {
    return (
        <Router>
            <BaseLayout>
                <Switch>
                    <Route component={Top} exact path='/' />
                    <Route component={AppTemplate} path='/app-templates' />
                    <Route component={Apps} path='/apps' />
                    <Route component={Assessment} path='/assessment' />
                    <Route component={EverydayNote} path='/everyday-note' />
                    <Route component={Notifications} path='/notifications' />
                    <Route component={Organization} path='/organization' />
                    <Route component={Questionnaire} path='/questionnaire/result/:uuid' />
                    <Route component={Questionnaire} path='/questionnaires' />
                    <Route component={Usage} path='/usage' />
                    <Route component={UsersManagement} path='/users' />
                    <Route component={Security} path='/security' />
                    <Route component={Job} path='/worker' />
                    <Route render={() => <h2>Not Found</h2>} />
                </Switch>
            </BaseLayout>
        </Router>
    );
};

const Error = () => {
    const { tag } = useParams<{ tag: string }>();
    let messageTitle = 'エラー';
    const messageSubTitle = '';
    let messageComponent = <span></span>;
    switch (tag) {
        case 'invalid_tenant':
            messageTitle = 'テナントエラー';
            break;
        case 'invalid_user':
            messageTitle = 'ユーザーエラー';
            break;
        case 'resource_not_found':
            messageTitle = '該当のリソースが存在しません';
            break;
        case 'result_not_found':
            messageTitle = 'テストの結果が存在しません';
            break;
        case 'db_error':
            messageTitle = 'データベースエラー';
            break;
        case 'error':
        default:
            messageTitle = 'その他のエラー';
            break;
    }

    switch (tag) {
        case 'invalid_tenant':
        case 'invalid_user':
            messageComponent = (
                <>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> テナント情報が誤っている可能性があります
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> サインインするユーザ情報があやまっている可能性があります
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> その他、idpに関連するエラー
                    </Paragraph>
                </>
            );
            break;
        case 'resource_not_found':
            messageComponent = (
                <>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> テストのURLが誤っている可能性があります
                    </Paragraph>
                </>
            );
            break;
        case 'result_not_found':
            messageComponent = (
                <>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> テスト結果のURLが誤っている可能性があります
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> テストを受験していない可能性があります
                    </Paragraph>
                </>
            );
            break;
        case 'db_error':
            messageComponent = (
                <>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> データベース異常です 管理者にお問い合わせください
                    </Paragraph>
                </>
            );
            break;
        case 'error':
        default:
            messageComponent = (
                <>
                    <Paragraph>
                        <CloseCircleOutlined className='site-result-demo-error-icon' /> 不明なエラーです。管理者にお問い合わせください
                    </Paragraph>
                </>
            );
            break;
    }

    /*
    リソース（TAOの場合はテスト）が存在しない： resource_not_found
    結果が存在しない： result_not_found
    DBエラー： db_error
     */

    return (
        <Result
            extra={[]}
            status='error'
            subTitle={messageSubTitle}
            title={messageTitle}
        >
            <div className='desc'>
                <Paragraph>
                    <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        エラーの発生理由は以下の通りです:
                    </Text>
                </Paragraph>
                {messageComponent}
            </div>
        </Result>
    );
};

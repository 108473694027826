import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    NotificationOutlined,
    LoadingOutlined,
    SearchOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    Button,
    Tooltip,
    Row,
    Col,
    Card,
    Progress,
    Empty,
    Select,
} from 'antd';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import { BaseTable } from 'components/modules/BaseTable';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import {
    // addAllTermAndSort,
    ALL_TERM_UUID,
    getDefaultLayout,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
// import { selectors } from 'flex/Selectors';
// import { useAppSelector } from 'flex/utils';

// import useOrganization from '../hooks/useOrganization';
// import useSchoolClass from '../hooks/useSchoolClass';

import 'styles/modules/notification_status_modal.scss';
import { createColumns } from './createColumns';

const Form = BaseForm.ModalForm;
const { Option } = Select;

const layout = getDefaultLayout();

const QuestionnaireStatusModal = ({
    isAnonymous,
    onCancel,
    uuid,
    visible,
}) => {
    const [form] = Form.useForm();
    // const [organization, searchOrganization] = useOrganization();
    // const [schoolClass, searchSchoolClass] = useSchoolClass();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({});
    const [data, setData] = useState(false);
    const [list, setList] = useState([]);
    const questionnaireTargetConnection = useSelector(state => state.questionnaireTargetConnection);
    const questionnaireExportConnection = useSelector(state => state.questionnaireExportConnection);
    // const { termList } = useAppSelector(selectors.globalState);
    const isControllableUser = useSelector(state => state.isControllableUser.payload);
    const jobInfoMap = useSelector(state => state.jobInfoMap);
    const [pagination, setPagination] = useState({
        current: 1,
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize,
            }));
            onSearch(search, pageSize, page);
        },
        onShowSizeChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize,
            }));
            onSearch(search, pageSize, page);
        },
        pageSize: 50,
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: list.usersListItemCount,
    });
    const dispatch = useDispatch();
    const disabledCsvExport = questionnaireExportConnection.meta.fetch ||
        list.length === 0 ||
        (Object.values(jobInfoMap)
            .filter(jobInfo => (
                jobInfo?.job?.type === BACKGROUND_JOB_TYPE.QUESTIONNAIRE_CSV_EXPORT ||
                jobInfo?.job?.type === BACKGROUND_JOB_TYPE.CONTROL_QUESTIONNAIRE_CSV_EXPORT
            ))
            .filter(jobInfo => jobInfo?.someUuid === uuid).length > 0);

    // const organizationUuid = Form.useWatch('organizationUuid', form);

    useEffect(() => {// 各種 state の初期化,初回検索
        if (!visible) return;

        setLoading(false);
        setSearch({});
        setData(false);
        setList([]);
        setPagination(pagination => ({
            ...pagination,
            current: 1,
            pageSize: 50,
            total: 0,
        }));
        onSearch({}, 50, 1);// 初期値で検索
        // if (isControllableUser) {
        //     searchOrganization();
        // } else {
        //     searchSchoolClass({
        //         page_size: 500,
        //     });
        // }
        form.setFieldsValue({
            // classTermUuid: isControllableUser ? undefined : ALL_TERM_UUID,
            is_answered: null,
            // organization_uuid: undefined,
            // schoolClass_uuid: undefined,
        });
    }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 検索後のページネーション更新（検索条件の反映）
        if (!visible) return;

        setPagination(pagination => ({
            ...pagination,
            onChange: (page, pageSize) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize,
                }));
                onSearch(search, pageSize, page);
            },
            onShowSizeChange: (page, pageSize) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize,
                }));
                onSearch(search, pageSize, page);
            },
        }));
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 検索結果のセット
        if (!visible) return;

        const { meta, payload: { result } } = questionnaireTargetConnection;
        setLoading(meta.fetch);

        if (meta.status === Actions.statusEnum.SUCCESS) {
            setData(result);
            setList(result.items);
            setPagination((previewPagination) => ({
                ...previewPagination,
                total: result.item_count,
            }));
        }
    }, [questionnaireTargetConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    function onSearch(values, pageSize, page = 1) {
        const obj = {
            is_answered: values.is_answered,
            organization_uuid: values.organization_uuid,
            page_size: pageSize,
            school_class_uuid: values.school_class_uuid,
            term_uuid: values.term_uuid,
        };

        if (isControllableUser && values.organizationUuid) {
            obj.organization_uuid = values.organizationUuid;
        }

        if (values.schoolClassUuid) {
            obj.school_class_uuid = values.schoolClassUuid;
        }

        const data = Object.entries(obj)// 検索条件
            .reduce((acc, [k, v]) => v === undefined ? acc : { ...acc, [k]: v }, {});

        setSearch(data);
        searchQuestionnaireTarget(uuid, data, page);

        setPagination(pagination => ({
            ...pagination,
            current: page,
        }));
    }

    function searchQuestionnaireTarget(uuid, data, page) {
        const targetAction = isControllableUser ?
            Actions.http.connection.questionnaires.controlTarget :
            Actions.http.connection.questionnaires.target;

        dispatch(targetAction(uuid, data, page));
    }

    function handleCsvDownload(encoding) {
        const downloadCsvAction = isControllableUser ?
            Actions.http.connection.questionnaires.controlDownloadCsv :
            Actions.http.connection.questionnaires.downloadCsv;

        dispatch(downloadCsvAction(uuid, { encoding }));
    }

    const columns = createColumns();

    function getReadPercent() {
        if (data.answered_count === 0) {
            return 0;
        } else {
            return Math.round((data.answered_count / data.total_count) * 100);
        }
    }

    // function handleChangeOrganization(value) {
    //     searchSchoolClass({
    //         organization_uuid: value,
    //         page_size: 500,
    //     });
    //     form.setFieldsValue({
    //         classTermUuid: ALL_TERM_UUID,
    //         schoolClassUuid: undefined,
    //     });
    // }

    // function handleChangeTerm(value) {
    //     searchSchoolClass({
    //         organization_uuid: organizationUuid,
    //         page_size: 500,
    //         term_uuid: value,
    //     });
    // }

    return (
        <BaseModal
            className='common-modal notification-status-modal'
            footer={[<Button key='back' onClick={onCancel} size='large'>キャンセル</Button>]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined /> 公開対象者と回答状況の確認</span>}
            visible={visible}
        >
            <div className='wrapper'>
                <div className='notification-status-modal-card-wrapper'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card bordered={false} className='notification-status-modal-card' title='公開対象者'>
                                {loading ?
                                    <Row
                                        align='middle'
                                        justify='space-around'
                                        style={{ textAlign: 'center' }}
                                        type='flex'
                                    >
                                        <Col span={24}>
                                            <span><LoadingOutlined /> 読み込み中...</span>
                                        </Col>
                                    </Row> :
                                    <Row align='middle' type='flex'>
                                        <Col span={24}>
                                            <div className='notification-status-modal-title'>合計公開者数</div>
                                            <div className='notification-status-modal-value'>
                                                {data.total_count}名
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card bordered={false} className='notification-status-modal-card' title='回答状況'>
                                {loading ?
                                    <Row
                                        align='middle'
                                        justify='space-around'
                                        style={{ textAlign: 'center' }}
                                        type='flex'
                                    >
                                        <Col span={24}>
                                            <span><LoadingOutlined /> 読み込み中...</span>
                                        </Col>
                                    </Row> :
                                    <Row align='middle' type='flex'>
                                        <Col span={12}><Progress
                                            percent={getReadPercent()}
                                            strokeWidth={8}
                                            type='circle'
                                            width={90}
                                        />
                                        </Col>
                                        <Col span={12}>
                                            <div className='notification-status-modal-title'>
                                                回答者数
                                                <Tooltip title='現在配信対象となっているユーザーの回答者数です。回答後に配信対象外となったユーザーはカウントされません。'>
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <div className='notification-status-modal-value'>
                                                {data.answered_count}名
                                            </div>
                                            <div className='notification-status-modal-title'>未回答者数</div>
                                            <div className='notification-status-modal-value'>
                                                {data.unanswered_count}名
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Card>
                        </Col>
                    </Row>
                </div>

                {!isAnonymous ? (
                    <>
                        <div className='card general-search'>
                            <Form {...layout} form={form} onFinish={onSearch}>

                                <Form.Item label='回答状態' name='is_answered'>
                                    <Select allowClear
                                        loading={loading}
                                        placeholder='回答状態を選択してください'
                                    >
                                        <Option value={null}>すべて</Option>
                                        <Option value={0}>未回答</Option>
                                        <Option value={1}>回答済</Option>
                                    </Select>
                                </Form.Item>

                                {/* {isControllableUser && (
                                    <Form.Item label='学校' name='organization_uuid'>
                                        <Select
                                            allowClear
                                            loading={organization.isLoading}
                                            onChange={handleChangeOrganization}
                                            placeholder='学校名を入力してください'
                                        >
                                            {organization.list.map((org) => (
                                                <Option key={org.uuid} value={org.uuid}>{org.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )} */}

                                {/* <Form.Item label='クラス'>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item name='classTermUuid'>
                                                <Select
                                                    allowClear
                                                    disabled={isControllableUser && !organizationUuid}
                                                    onChange={handleChangeTerm}
                                                    placeholder='期間を選択してください'
                                                >
                                                    {addAllTermAndSort(termList).map((term) => (
                                                        <Option key={term.uuid} value={term.uuid}>
                                                            {term.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name='school_class_uuid'>
                                                <Select
                                                    allowClear
                                                    disabled={(isControllableUser && !organizationUuid) || schoolClass.isLoading}
                                                    loading={schoolClass.isLoading}
                                                    placeholder='クラスを選択してください'
                                                >
                                                    {schoolClass.list.map((schoolClass) => (
                                                        <Option key={schoolClass.uuid} value={schoolClass.uuid}>
                                                            {schoolClass.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item> */}

                                {/* <Form.Item label='年度' name='term_uuid'>
                                    <Select
                                        allowClear
                                        placeholder='年度を選択してください'
                                    >
                                        {termList.map((org) => (
                                            <Option key={org.uuid} value={org.uuid}>{org.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item> */}

                                <div className='submit-container'>
                                    <Button htmlType='submit' icon={<SearchOutlined />} loading={loading} type='primary'>
                                        絞り込む
                                    </Button>
                                </div>
                            </Form>
                        </div>

                        <div className='flex-right-container gutter-bottom'>
                            <DownloadCSVConfirm
                                disabled={disabledCsvExport}
                                disabledMessage={
                                    <p>
                                        CSVエクスポートはすでに実行済みです。<br />
                                        CSVのダウンロード状況については、画面左下からご確認ください
                                    </p>
                                }
                                handleOk={(encoding) => handleCsvDownload(encoding)}
                                loading={questionnaireExportConnection.meta.fetch}
                            />
                        </div>

                        <BaseTable
                            bordered
                            className='general-table'
                            columns={columns}
                            dataSource={list}
                            loading={loading}
                            locale={{
                                emptyText: <Empty description='対象のユーザーはいません' image={Empty.PRESENTED_IMAGE_DEFAULT} />,
                                filterConfirm: '確定',
                                filterReset: 'リセット',
                            }}
                            pagination={pagination}
                            size='middle'
                        />
                    </>
                ) : undefined}
            </div>
        </BaseModal>
    );
};

export default QuestionnaireStatusModal;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';


export type TargetOrganization = {
    result: {
        // 他との利便性のために items Key を経由するようにしている
        items: {
            uuid: string
            name: string
        }[]
    }
    isLoading: boolean
};

export const useTargetOrganization = () => {
    const [state, setState] = useState<TargetOrganization>({
        isLoading: false,
        result: { items: [] },
    });

    const taoExamControlTargetOrganizationConnection = useAppSelector(state => state.taoExamControlTargetOrganizationConnection);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta, payload } = taoExamControlTargetOrganizationConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState({
                    isLoading: meta.fetch,
                    result: {
                        items: payload.result.items.map(item => ({
                            name: item.name,
                            uuid: item.uuid,
                        })),
                    },
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamControlTargetOrganizationConnection]);

    const fetchData = useCallback((
        examUuid: string,
    ) => {
        setState(state => ({
            ...state,
            isLoading: true,
        }));

        dispatch(Actions.http.connection.tao.exam.controlTargetOrganization(examUuid));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return [state, fetchData] as const;
};

import { Dispatch, SetStateAction, useEffect, useMemo, VFC } from 'react';
import { Tag, Input, Button, Select, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
    addAllTermAndSort,
    ALL_TERM_UUID,
    getCurrentTerm,
} from 'constants/GlobalConfig';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;

const { Option } = Select;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 24,
    },
};

export type UserSearch = {
    first_name?: string
    first_name_kana?: string
    last_name?: string
    last_name_kana?: string
    login_id?: string,
    page?: number
    page_size: number
    role_uuid?: string,
    school_class_uuid?: string,
    term_uuid?: string,
}

type SearchForm = Required<Omit<UserSearch, 'page' | 'page_size'>>

type Props = {
    classList: SchoolClass[]
    isLoadingClass: boolean
    isLoading: boolean
    onSearch: Dispatch<SetStateAction<UserSearch>>
    onSelectTerm: (termUuid: string, pageSize: number, page?: number) => void
    roleList: Role[]
    search: UserSearch
    termList: Term[]
}

const AddSearch: VFC<Props> = ({
    classList,
    isLoadingClass,
    isLoading,
    onSearch,
    onSelectTerm,
    roleList,
    search,
    termList,
}) => {
    const [form] = Form.useForm<SearchForm>();
    const selectTerm = Form.useWatch('term_uuid', form);
    const currentTermUuid = useMemo(() => getCurrentTerm(termList).uuid, [termList]);

    useEffect(() => {
        if (currentTermUuid === undefined) return;

        form.resetFields();
        form.setFieldsValue({ term_uuid: currentTermUuid });
        onSearch(search => ({
            ...search,
            term_uuid: currentTermUuid,
        }));
        handleSelectTerm(currentTermUuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTermUuid]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function onFinish(values: SearchForm) {
        onSearch(search => ({ ...search, page: 1, ...values }));
    }

    function onReset() {
        form.resetFields();
        form.setFieldsValue({ term_uuid: currentTermUuid });
    }

    function handleSelectTerm(term_uuid: string) {
        form.resetFields(['school_class_uuid']);
        onSelectTerm(term_uuid, 500);
    }

    return (
        <div className='card general-search notification-search-form-wrapper'>

            <Form {...layout} form={form} name='control-hooks-users-add-search' onFinish={onFinish}>

                <Form.Item label='クラス'>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='term_uuid' style={{ 'marginBottom': '0px' }}>
                                <Select
                                    placeholder='年度を選択してください'
                                    onChange={handleSelectTerm}
                                    disabled={isLoadingClass || isLoading}
                                    loading={isLoadingClass || isLoading}
                                >
                                    {addAllTermAndSort(termList).map((value, index) => {
                                        return <Option key={index} value={value.uuid}>{value.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='school_class_uuid' style={{ 'marginBottom': '0px' }}>
                                <Select
                                    placeholder='クラスを選択してください'
                                    allowClear
                                    disabled={isLoadingClass || isLoading || selectTerm === ALL_TERM_UUID}
                                    loading={isLoadingClass || isLoading}
                                >
                                    {classList.map((value, index) => {
                                        return (
                                            <Option key={index} value={value.uuid}>
                                                <Tag color='#AAAAAA' style={{ width: '90px', textAlign: 'center', borderRadius: '5px', marginRight: '0.5rem' }}>
                                                    {value.term.name}
                                                </Tag>
                                                {value.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item name='role_uuid' label='役割'>
                    <Select
                        placeholder='役割を選択してください'
                        allowClear
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        {roleList.map((value, index) => {
                            return <Option key={index} value={value.uuid}>{value.name}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name='login_id' label='ユーザーID'>
                    <Input
                        disabled={isLoading}
                        placeholder='ユーザーIDを入力してください'
                    />
                </Form.Item>

                <Form.Item label='名前'>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='last_name'>
                                <Input
                                    disabled={isLoading}
                                    placeholder='姓を入力してください'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='last_name_kana'>
                                <Input
                                    disabled={isLoading}
                                    placeholder='姓（ふりがな）を入力してください'
                                />
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='first_name' >
                                <Input
                                    disabled={isLoading}
                                    placeholder='名を入力してください'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='first_name_kana'>
                                <Input
                                    disabled={isLoading}
                                    placeholder='名（ふりがな）を入力してください'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <div className='submit-container'>
                    <Form.Item>
                        <Button
                            className='mr-4'
                            disabled={isLoading}
                            htmlType='button'
                            loading={isLoading}
                            onClick={onReset}
                        >
                            リセット
                        </Button>
                        <Button
                            disabled={isLoading}
                            htmlType='submit'
                            icon={<SearchOutlined />}
                            loading={isLoading}
                            type='primary'
                        >
                            検索
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default AddSearch;

import * as React from 'react';
import {
    Bar,
    BarChart,
    YAxis,
} from 'recharts';

const Colors = [
    '#005AFF',
    '#FF4B00',
    '#FFF100',
    '#990099',
    '#4DC4FF',
    '#03AF7A',
    '#F6AA00',
    '#003699',
    '#992D00',
    '#999100',
    '#330033',
    '#2E7699',
    '#014A34',
    '#8F6300',
    '#669CFF',
    '#FF9366',
    '#FFF766',
    '#993D99',
    '#B2E6FF',
    '#4AAF90',
    '#F6C862',
    '#0048CC',
    '#CC3C00',
    '#CCC100',
    '#660066',
    '#3E9DCC',
    '#027D57',
    '#C28600',
    '#99BDFF',
    '#FFB799',
    '#FFF999',
    '#995C99',
    '#E5F7FF',
    '#6DAF9B',
    '#F6D894',
];

const FiveLevelColors = [
    '#ED2100',
    '#FFB800',
    '#FAFF00',
    '#63D20A',
    '#006E18',
];

type Props = {
    data: [Record<string, { count: number, name: string }>]
    loading: boolean
    max: number
    questionType: EverydayNote.QuestionTypes | undefined
    style?: React.CSSProperties
}

const SimpleGraph: React.VFC<Props> = ({
    data,
    loading,
    max,
    questionType,
    style = {}
}) => {
    if(loading) return null;

    const keys = Object.keys(data[0]);

    const dataKeys = questionType === 4 ? keys.reverse() : keys;

    const fill = questionType === 4 ? FiveLevelColors : Colors;

    const colorsNum = fill.length;

    const newData = data.map(d => Object.fromEntries(dataKeys.map(key => [key, d[key].count])));

    return (
        <BarChart data={newData} width={80} height={50} style={style}>
            <YAxis domain={[0, max]} hide/>
            {dataKeys.map((dataKey, i) => (
                <Bar key={dataKey} dataKey={dataKey} fill={fill[i % colorsNum]} />
            ))}
        </BarChart>
    );
}

export default SimpleGraph;

import { useState } from 'react';
import { Button, DatePicker, Input } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import isTouchDevice from 'is-touch-device';
import { getDefaultLayout } from 'constants/GlobalConfig'
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;

const layout = getDefaultLayout()

/** 全体管理者と学校管理者の両方で利用されるアプリグループ検索  */

const AppsSearch = ({
    setSearch, 
    loading = false,
}) => {
    const [form] = Form.useForm();
    const [durationPublishStart, setPublishDurationStart] = useState(null)
    const [durationPublishEnd, setPublishDurationEnd] = useState(null)

    function onFinish(values) {
        setSearch(values)
    }

    function disabledStartDatePublish(currentDate) {
        if (!durationPublishEnd) return false;
        return currentDate.valueOf() >= durationPublishEnd.valueOf();
    }

    function disabledEndDatePublish(currentDate) {
        if (!durationPublishStart) return false;
        return currentDate.valueOf() <= durationPublishStart.valueOf();
    }

    function onReset() {
        form.resetFields();
        setPublishDurationStart(null);
        setPublishDurationEnd(null);
    }

    return (
        <div className='card general-search apps-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-apps-search' onFinish={onFinish}>
                <Form.Item name='title' label='アプリグループ名'>
                    <Input placeholder='アプリグループ名を入力してください' />
                </Form.Item>
                <Form.Item label='公開期間' extra='指定した期間に公開中となっているアプリグループを対象とします。'>
                    <Input.Group compact>
                        <Form.Item noStyle name='publish_start_at'>
                            <DatePicker
                                locale={jaJP}
                                showTime={{ format: 'HH:mm' }}
                                format='YYYY/MM/DD HH:mm'
                                disabledDate={disabledStartDatePublish}
                                value={durationPublishStart}
                                placeholder='開始日時'
                                onChange={setPublishDurationStart}
                                inputReadOnly={isTouchDevice()}
                            />
                        </Form.Item>
                        <Form.Item noStyle name='publish_end_at'>
                            <DatePicker
                                locale={jaJP}
                                showTime={{ format: 'HH:mm' }}
                                format='YYYY/MM/DD HH:mm'
                                disabledDate={disabledEndDatePublish}
                                value={durationPublishEnd}
                                placeholder='終了日時'
                                onChange={setPublishDurationEnd}
                                inputReadOnly={isTouchDevice()}
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <div className='submit-container'>
                    <Button htmlType='button'
                        onClick={onReset}
                        loading={loading}
                        disabled={loading}
                    >
                        リセット
                    </Button>
                    <Button type='primary' htmlType='submit'
                        loading={loading}
                        disabled={loading}
                        icon={<SearchOutlined />}
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
}

AppsSearch.propTypes = {
    loading: PropTypes.bool.isRequired,
    /** 検索時 onFinishで呼び出される関数  */
    setSearch: PropTypes.func.isRequired
}

export default AppsSearch;

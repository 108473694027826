import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Payload = QuestionnaireControlAnswerResultByItemConnection['payload'] | QuestionnaireAnswerResultByItemConnection['payload']
type Meta = QuestionnaireControlAnswerResultByItemConnection['meta'] | QuestionnaireAnswerResultByItemConnection['meta']

type CallbackFn = (meta: Meta, payload: Payload, isControl: boolean) => void

type AnswerResultByItemState = {
    isLoading: boolean
    question: Question | undefined
    questionnaire: Questionnaire | undefined
}

/** キャッシュ機能付き */
const useAnswerResultByItem = (
    successCallback: CallbackFn = () => undefined,
    failureCallback: CallbackFn = () => undefined,
) => {
    const dispatch = useDispatch();
    const [state, setState] = useState<AnswerResultByItemState>({
        isLoading: false,
        question: undefined,
        questionnaire: undefined,
    });
    // 雑にキャッシュ(本来はHTTPレベルのキャッシュを使うべき)
    const [cache, setCache] = useState<{ [key: string]: AnswerResultByItemState & { expiry: number } }>({});

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const questionnaireAnswerResultByItemConnection = useAppSelector(state => state.questionnaireAnswerResultByItemConnection);
    const questionnaireControlAnswerResultByItemConnection = useAppSelector(state => state.questionnaireControlAnswerResultByItemConnection);

    const effect = useCallback((connection: QuestionnaireAnswerResultByItemConnection | QuestionnaireControlAnswerResultByItemConnection) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback(meta, payload, true);
                const question = questionConvert(payload.result.item);
                const questionnaire = questionnaireConvert(payload.result.questionnaire, isControllableUser);
                setState(state => ({
                    ...state,
                    isLoading: false,
                    question,
                    questionnaire,
                }));
                setCache(cache => ({
                    ...cache,
                    [payload.result.item.uuid]: {
                        // Date.now() との比較のために秒をミリ秒に変換
                        expiry: (payload.gmt + 5 * 60) * 1000,
                        isLoading: false,
                        question,
                        questionnaire,
                    },
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback(meta, payload, false);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
        }
    }, [failureCallback, isControllableUser, state.isLoading, successCallback]);

    useEffect(
        () => effect(questionnaireAnswerResultByItemConnection),
        [effect, questionnaireAnswerResultByItemConnection],
    );
    useEffect(
        () => effect(questionnaireControlAnswerResultByItemConnection),
        [effect, questionnaireControlAnswerResultByItemConnection],
    );

    const request = useCallback((
        questionnaireUuid: string,
        questionUuid: string,
    ) => {
        const cacheData = cache[questionUuid];
        if (cacheData && cacheData.expiry > Date.now()) {
            setState(cacheData);
            return;
        }

        const action = isControllableUser ?
            Actions.http.connection.questionnaires.controlAnswerResultByItem :
            Actions.http.connection.questionnaires.answerResultByItem;

        dispatch(action(questionnaireUuid, questionUuid));
        setState(state => ({
            ...state,
            isLoading: true,
        }));

    }, [cache, dispatch, isControllableUser]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
            question: undefined,
            questionnaire: undefined,
        });
        setCache({});
    }, []);

    return [state, request, reset] as const;
};

export default useAnswerResultByItem;

type ResponseResult = Http.Connection.Response.Questionnaire.Control.AnswerResultByItem['result'] |
    Http.Connection.Response.Questionnaire.Admin.AnswerResultByItem['result']

const questionConvert = (question: ResponseResult['item']): Question => {
    return {
        answerCandidates: question.answer_candidates.map(c => ({
            answerCount: c.answer_count ?? undefined,
            content: c.content,
            createdAt: c.created_at,
            order: c.order,
            updatedAt: c.updated_at,
            uuid: c.uuid,
        })),
        answerCount: question.answer_count ?? undefined,
        answers: question.answers,
        content: question.content,
        createdAt: question.created_at,
        detail: question.detail,
        file: question.file ?
            {
                fileName: question.file.file_name,
                uuid: question.file.uuid,
            } :
            undefined,
        isRequired: question.is_required,
        order: question.order,
        questionItemBranchChoices: question.question_item_branch_choices,
        type: question.type,
        updatedAt: question.updated_at,
        uuid: question.uuid,
    };
};

const questionnaireConvert = (questionnaire: ResponseResult['questionnaire'], isControllableUser: boolean): Questionnaire => {
    const baseCategory = {
        canSetForced: questionnaire.category.can_set_forced,
        createdAt: questionnaire.category.created_at,
        createdBy: questionnaire.category.created_by,
        description: questionnaire.category.description,
        name: questionnaire.category.name,
        order: questionnaire.category.order ?? undefined,
        updatedAt: questionnaire.category.updated_at ?? undefined,
        updatedBy: questionnaire.category.updated_by,
        uuid: questionnaire.category.uuid,
    };

    const base = {
        afterMessage: '', //ダミーの値
        canBeAnsweredOnlyOnce: questionnaire.can_be_answered_only_once,
        content: '', //ダミーの値
        createdAt: questionnaire.created_at,
        createdBy: questionnaire.created_by,
        isAnonymous: questionnaire.is_anonymous,
        isEditable: questionnaire.is_editable,
        isForced: false, //ダミーの値
        isLocked: questionnaire.is_locked,
        isPublic: questionnaire.is_public,
        isPublicResult: questionnaire.is_public_result,
        items: [], //ダミーの値
        memo: '', //ダミーの値
        name: questionnaire.name,
        performEndAt: questionnaire.perform_end_at,
        performStartAt: questionnaire.perform_start_at,
        publishEndAt: questionnaire.publish_end_at,
        publishFrom: questionnaire.publish_from,
        publishResultEndAt: questionnaire.publish_result_end_at,
        publishResultStartAt: questionnaire.publish_result_start_at,
        publishStartAt: questionnaire.publish_start_at,
        statusPeriod: questionnaire.status_period,
        updatedAt: questionnaire.updated_at,
        updatedBy: questionnaire.updated_by,
        uuid: questionnaire.uuid,
    };

    if(isControllableUser) {
        const q = questionnaire as Http.Connection.Response.Questionnaire.Control.AnswerResultByItem['result']['questionnaire'];
        return {
            ...base,
            canAdminSetPublic: q.can_admin_set_public ?? false,
            category: {
                ...baseCategory,
                kind: 'control',
            },
            kind: 'control',
            publishToAllOrganizations: q.publish_to_all_organizations,
            targetOrganizations: [], //ダミーの値
            targetPermissions: [], //ダミーの値
        };
    } else {
        const q = questionnaire as Http.Connection.Response.Questionnaire.Admin.AnswerResultByItem['result']['questionnaire'];
        return {
            ...base,
            canAdminSetPublic: q.can_admin_set_public ?? undefined,
            category: {
                ...baseCategory,
                isPublishedItem: undefined, //ダミーの値
                kind: 'admin',
            },
            isPublishedItem: undefined, //ダミーの値
            kind: 'admin',
            targetRoles: [], //ダミーの値
            targetSchoolClasses: [], //ダミーの値
            targetUsers: [], //ダミーの値
        };
    }
};
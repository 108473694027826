import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Result = {
    isLoading: boolean,
    // Create や Update では返り値を使わない
    result: {
        isSuccessful: boolean,
        status: typeof Actions.statusEnum[keyof typeof Actions.statusEnum],
    } | undefined
}

const INIT_STATE: Result = {
    isLoading: false,
    result: undefined,
};

type AdminCreateValue = Http.Connection.Request.Parameter.EverydayNote.Admin.Create
type AdminUpdateValue = Http.Connection.Request.Parameter.EverydayNote.Admin.Update;
type AdminControlUpdateValue = Http.Connection.Request.Parameter.EverydayNote.Admin.ControlUpdate;
type ControlCreateValue = Http.Connection.Request.Parameter.EverydayNote.Create
type ControlUpdateValue = Http.Connection.Request.Parameter.EverydayNote.Update;

// 学校管理者は全体管理者の作成した毎日の記録を削除できないので、考慮しないで良い
export const useEditQuestionnaire = () => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const controlEverydayNoteCreateConnection = useAppSelector(state => state.controlEverydayNoteCreateConnection);
    const adminEverydayNoteCreateConnection = useAppSelector(state => state.adminEverydayNoteCreateConnection);

    const controlEverydayNoteUpdateConnection = useAppSelector(state => state.controlEverydayNoteUpdateConnection);
    const adminEverydayNoteUpdateConnection = useAppSelector(state => state.adminEverydayNoteUpdateConnection);
    const adminEverydayNoteControlUpdateConnection = useAppSelector(state => state.adminEverydayNoteControlUpdateConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const create = useCallback((value: AdminCreateValue | ControlCreateValue) => {

        const createAction = isControllableUser ?
        Actions.http.connection.everydayNote.control.create :
            Actions.http.connection.everydayNote.admin.create;

        dispatch(createAction(value));
        setResult(state => ({
            ...state,
            isLoading: true,
            result: {
                isSuccessful: false,
                status: Actions.statusEnum.LOADING,
            },
        }));
    }, [dispatch, isControllableUser]);

    const update = useCallback((value: AdminUpdateValue | AdminControlUpdateValue | ControlUpdateValue, tabName?: 'board' | 'school') => {

        const createAction = isControllableUser ?
        Actions.http.connection.everydayNote.control.update :
            tabName === 'board' ?
            Actions.http.connection.everydayNote.admin.controlUpdate :
            Actions.http.connection.everydayNote.admin.update;

        dispatch(createAction(value));
        setResult(state => ({
            ...state,
            isLoading: true,
            result: {
                isSuccessful: false,
                status: Actions.statusEnum.LOADING,
            },
        }));
    }, [dispatch, isControllableUser]);

    type Connection = EverydayNoteCreateConnection |
        EverydayNoteUpdateConnection |
        EverydayNoteControlUpdateConnection;
    const connectionEffect = (connection: Connection) => () => {
        const {
            meta: { status },
            payload,
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;
                setResult({
                    isLoading: false,
                    result: {
                        isSuccessful: payload.is_successful,
                        status,
                    },
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({
                    isLoading: false,
                    result: {
                        isSuccessful: payload.is_successful,
                        status,
                    },
                });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(controlEverydayNoteCreateConnection),
        [controlEverydayNoteCreateConnection]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(adminEverydayNoteCreateConnection),
        [adminEverydayNoteCreateConnection]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(controlEverydayNoteUpdateConnection),
        [controlEverydayNoteUpdateConnection]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(adminEverydayNoteUpdateConnection),
        [adminEverydayNoteUpdateConnection]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        connectionEffect(adminEverydayNoteControlUpdateConnection),
        [adminEverydayNoteControlUpdateConnection]
    );

    return [result, create, update, reset] as const;
};
import { Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';
import type { ResultExamCategoryState, } from '../../../hooks/useResultExamCategory';
import type { DataSource } from '.';


export function createColumns(
    onClickUserName: (uuid: string) => void,
    result: ResultExamCategoryState['result'],
    examResultChildren: ColumnsType<DataSource>
): ColumnsType<DataSource> {
    const isEmptyExamResultChildren = examResultChildren.length === 0;
    return [
        {
            fixed: 'left',
            key: 'name',
            render: (_, record) => {
                const existExamResult = result &&
                    result.exams.length !== 0 &&
                    record.exams.filter(exam => exam.resultHistory.length !== 0).length !== 0;
                return (
                    <Tooltip
                        mouseEnterDelay={1}
                        title={existExamResult ? '生徒ごとのビューを開く' : 'テスト結果が存在しません'}
                    >
                        <button
                            className={existExamResult ? 'text-button' : 'text-button disabled'}
                            disabled={!existExamResult}
                            onClick={() => onClickUserName(record.uuid)}
                        >
                            {record.name}
                        </button>
                    </Tooltip>
                );
            },
            title: '氏名',
            width: 200,
        },
        {
            children: [
                {
                    dataIndex: 'belongs',
                    key: 'class',
                    render: (belongs: DataSource['belongs']) => (
                        belongs.map((val, index) => (
                            <div key={`${val.school_class.name}-${index}`}>
                                <Tag
                                    color='#AAAAAA'
                                    style={{
                                        borderRadius: '5px',
                                        marginRight: '0.5rem',
                                        textAlign: 'center',
                                        width: '75px',
                                    }}
                                >
                                    {val.school_class.term.name}
                                </Tag>
                                {val.school_class.name}
                            </div>
                        ))
                    ),
                    title: 'クラス名',
                    width: 200,
                },
                {
                    dataIndex: 'belongs',
                    key: 'roles',
                    render: (belongs: DataSource['belongs']) => (
                        belongs.map((val, index) => (
                            <div key={index}>{val.number}</div>
                        ))
                    ),
                    title: '出席番号',
                    width: 150,
                },
                {
                    dataIndex: 'exams',
                    key: 'progress',
                    render: (exams: DataSource['exams']) => {
                        const totalExams = exams.length;
                        const finishedExams = exams.reduce((prev, exam) => {
                            const progress = exam.resultHistory[0]?.activityProgress;
                            return progress === 'Submitted' || progress === 'Completed' ? ++prev : prev;
                        }, 0) || 0;
                        return <div>{totalExams ? `${finishedExams} / ${totalExams} テスト完了` : '- / -'}</div>;
                    },
                    title: '学習の進捗',
                    width: 200,
                },
            ],
            title: '配信対象者',
        },
        ...(isEmptyExamResultChildren)
            ? []
            : [{
                children: examResultChildren,
                title: <div style={{ left: '200px', maxWidth: '500px', position: 'sticky' }}>テスト結果</div>,
                width: 250 * (result ? result.exams.length : 0),
            }]
        ,
    ];
};


import * as Actions from '../../../Actions'
import {appTemplateTabDefaultState} from './defaultState'

export const appTemplateTabInViewLogic = (store, next, action) => {

    const tabState = store.getState()['appTemplateTab']
    const pagination = tabState["pagination"]
    const search = tabState["search"]

    switch (true) {
        case /PREPARE_VIEW/.test(action.type):
            next(Actions.http.connection.apps.template.searchForAdmin({page_size:50}, 1, 'appTemplateTab'))
            next(Actions.http.connection.apps.template.search({page_size:500}, 1,'appTemplateTab'))
            next(Actions.view.viewLogic.appTemplateTab.savePage(1, 50))
            break;
        case /SUBMIT_SEARCH/.test(action.type):

            const submitValues = Object.assign({}, action.payload.values)
            submitValues.page_size = action.payload.page_size || pagination.pageSize
            const page = action.payload.page || 1
            next(Actions.http.connection.apps.template.searchForAdmin(submitValues, page, 'appTemplateTab'))
            next(Actions.view.viewLogic.appTemplateTab.saveSearch(action.payload.values))
            next(Actions.view.viewLogic.appTemplateTab.savePage(page, submitValues.page_size))

            break;
        case /SUBMIT_PAGE/.test(action.type):
        {
            const {page, page_size} = action.payload
            store.dispatch(Actions.view.viewLogic.appTemplateTab.submitSearch(search, page, page_size))
            break
        }

        case /SUBMIT_EDIT/.test(action.type):
        {
            const page = pagination.current
            const page_size = pagination.pageSize
            store.dispatch(Actions.view.viewLogic.appTemplateTab.submitSearch(search, page, page_size))
            break;
        }
        default:
            next(action)
            break;
    }
}

import * as React from 'react';
import 'styles/theme/mexcbt.scss';

type StyleNames = {
    Mexcbt: 'mexcbt',
    Default: 'default',
}

// type と variable の被りは問題ないはずなので, disable
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StyleNames: StyleNames = {
    Mexcbt: 'mexcbt',
    Default: 'default',
}

interface Props {
    styleName: StyleNames[keyof StyleNames] | undefined;
}

/**
 * 使い方
 *
 * 1. styles/theme フォルダにテーマを作成(e.g. theme.scss)
 *    わかりやすさのために一番トップのクラス名ファイル名と合わせておく
 * 2. このコンポーネントでインポートする
 * 3. StyleNames 型に key をファイル名のキャメルケースとして, value をファイル名として追加する
 * 4. 変数にも追加する
 * 5. コンポーネント内の switch 文に追加する
 *    その際に返す div DOM の className をファイル名とする
 *    (e.g. <div className='theme'>{children}</div>)
 */
/**
 * style を動的に切り替えるためのコンポーネント
 */
const StyleSwitcher: React.FC<Props> = props => {
    const { styleName = StyleNames.Default, children } = props;

    switch (styleName) {
        case StyleNames.Mexcbt:
            return <div className='mexcbt'>{children}</div>;

        case StyleNames.Default:
            return <div>{children}</div>;

        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _never: never = styleName;
            return null;
    }
}

export default StyleSwitcher;
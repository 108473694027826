import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationOutlined, TeamOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Select, Tooltip } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import { defaultGradePreset, getDefaultLayout } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import Targets from '../../../Targets';
import { ListContext } from '../../index';

const Form = BaseForm.ModalForm;
const { Option } = Select;
const { statusEnum } = Actions;
const layout = getDefaultLayout();

const ClassTypes = {
    homeroom: 1,
    scheduled: 2,
};

const initialValue = {
    class_type: ClassTypes.homeroom,
    grade_code: null,
    name: '',
    target: {
        target_organizations: [],
        target_roles: [],
        target_school_classes: [],
        target_users: [],
    },
    term_uuid: null,
};

const EditClassModal = ({
    onCancel,
    onOk,
    record,
    isCreate = false,
    visible = false,
}) => {
    const [list] = useContext(ListContext);
    const [loading, setLoading] = useState(false);
    const [isOpenTarget, setIsOpenTarget] = useState(false);
    const [form] = Form.useForm();
    const target = Form.useWatch('target', form);
    const dispatch = useDispatch();

    const usersViewConnection = useAppSelector(state => state.usersViewConnection);
    const classEditConnection = useSelector(state => state.classEditConnection);
    const handleSubmit = form.submit;

    const isFirstRender = useRef(false);

    const gradeList = list.gradeListItemCount === 0 ?
        defaultGradePreset :
        list.gradeList;

    // Targets が各 key がないとtypescript化したときエラーになるので, つけておく
    const currentTarget = {
        target_organizations: [],
        target_roles: [],
        target_school_classes: [],
        target_users: [],
        ...target,
    };

    useEffect(() => {
        form.setFieldsValue(initialValue);
        isFirstRender.current = true;
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (usersViewConnection.meta.status === statusEnum.SUCCESS) {
            form.setFieldsValue({
                target: {
                    target_users: [usersViewConnection.payload.result],
                },
            });
        }
    }, [usersViewConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (record) {
            form.resetFields();
            form.setFieldsValue({
                class_type: record.class_type,
                grade_code: record.grade.code,
                main_teacher_uuid: record.main_teacher?.uuid,
                name: record.name,
                term_uuid: record.term.uuid,
            });
            if (record.main_teacher?.uuid) {
                dispatch(Actions.http.connection.users.view(record.main_teacher.uuid));
            }
        } else {
            form.resetFields();
            form.setFieldsValue(initialValue);
        }
    }, [record, visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(classEditConnection.meta.fetch);
        if (isFirstRender.current) return;

        if (classEditConnection.meta.status === statusEnum.SUCCESS) {
            onOk();
        }
    }, [classEditConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, []);

    const onFinish = (values) => handleEditSubmit(values);

    const handleEditSubmit = (postBody) => {
        if (isCreate) {
            dispatch(Actions.http.connection.classes.create(postBody));
        } else {
            dispatch(Actions.http.connection.classes.update(record.uuid, postBody));
        }
    }

    const handleTargetOpen = () => setIsOpenTarget(true);
    const handleTargetCancel = () => setIsOpenTarget(false);

    return (
        <BaseModal
            className='common-modal'
            footer={[
                <Button
                    key='back'
                    loading={loading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    loading={loading}
                    onClick={handleSubmit}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined /> {isCreate ? '新規作成' : '編集'}</span>}
            visible={visible}
        >
            <Form {...layout} form={form} name='control-hooks-edit-class' onFinish={onFinish}>
                <Form.Item
                    label='クラス名'
                    name='name'
                    rules={[
                        { message: '必須項目です', required: true },
                        { max: 64, message: 'クラス名は64文字以内で入力してください' },
                    ]}
                >
                    <Input placeholder='クラス名を入力してください' />
                </Form.Item>

                <Form.Item
                    label='年度'
                    name='term_uuid'
                    rules={[{ message: '必須項目です', required: true }]}
                >
                    <Select
                        allowClear
                        placeholder='年度を選択してください'
                    >
                        {list.termList.map((value) => {
                            return <Option key={value.uuid} value={value.uuid}>{value.name}</Option>;
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label='学年コード'
                    name='grade_code'
                    rules={[{ message: '必須項目です', required: true }]}
                >
                    <Select
                        allowClear
                        placeholder='学年コードを選択してください'
                    >
                        {gradeList.map((value) => {
                            return <Option key={value.code} value={value.code}>{value.name}</Option>;
                        })}
                    </Select>
                </Form.Item>

                <Form.Item hidden name='target' value={currentTarget}><Input /></Form.Item>

                <Form.Item
                    label={(
                        <span>
                            主担任
                            <Tooltip title='主担任を選びなおすと、既存の主担任設定が上書きされます。'>
                                <QuestionCircleOutlined style={{ marginLeft: '0.25rem' }} />
                            </Tooltip>
                        </span>)}
                    name='main_teacher_uuid'
                >
                    <TargetFormItem onClick={handleTargetOpen} />
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            クラスの種類
                            <Tooltip title='「学籍クラス」は学級活動を行うクラス(例：1年1組)、「その他のクラス」は習熟度別等のクラス、クラブや部活、委員会などで選択します。'>
                                <QuestionCircleOutlined style={{ marginLeft: '0.25rem' }} />
                            </Tooltip>
                        </span>
                    }
                    name='class_type'
                    rules={[{ message: '必須項目です', required: true }]}
                >
                    <Radio.Group>
                        <Radio value={ClassTypes.homeroom}>
                            学籍クラス
                        </Radio>
                        <Radio value={ClassTypes.scheduled}>
                            その他
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>

            <Targets.MainTeacher
                currentTarget={currentTarget.target_users}
                onCancel={handleTargetCancel}
                onSubmit={(users) => {
                    setIsOpenTarget(false);
                    form.setFieldsValue(
                        {
                            main_teacher_uuid: users[0]?.uuid,
                            target: {
                                target_users: users[0] ? [users[0]] : [],
                            },
                        }
                    );
                }}
                organizationUuid={record?.organization?.uuid}
                visible={isOpenTarget}
            />
        </BaseModal>
    );
};

export default EditClassModal;


const TargetFormItem = ({
    disabled,
    onClick,
    value,
}) => {
    return (
        <>
            <Button
                disabled={disabled}
                icon={<TeamOutlined />}
                onClick={onClick}
                type='primary'
            >
                主担任を選択
            </Button>

            <Targets.Detail
                currentTarget={{
                    target_main_teacher: value,
                }}
            />
        </>
    );
};

import { VFC } from 'react';

import { Badge } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';

import { BaseTable } from 'components/modules/BaseTable';
import { getMoment } from 'constants/GlobalConfig';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

import { ExamTable } from './ExamTable';
import { SearchExamCategoryWithResultState } from './hooks/useSearchWithResultExamCategory';

const moment = getMoment();


type ExamCategoryWithResult = SearchExamCategoryWithResultState['items'][number];
type ExamWithResult = ExamCategoryWithResult['exams'][number];


type Props = {
    dataSource: SearchExamCategoryWithResultState
    onClickViewAllTestResult: (exam: ExamWithResult) => void
    onClickSaveAllReport: (uuid: string, examUuids: string[], groupName: string) => void
    onClickSaveReport: (uuid: string, groupName: string, examName: string) => void
    pagination: TablePaginationConfig
}

export const ExamCategoryTable: VFC<Props> = ({
    dataSource,
    onClickViewAllTestResult,
    onClickSaveAllReport,
    onClickSaveReport,
    pagination,
}) => {
    const isControllableUser = useAppSelector(selectors.isControllableUser);

    const columns: ColumnsType<ExamCategoryWithResult> = [
        {
            dataIndex: 'title',
            key: 'title',
            title: 'テストグループ名称',
            width: 150,
        },
        {
            dataIndex: 'is_required',
            key: 'is_required',
            render: (isRequired) => {
                if (isRequired) {
                    return <Badge status='warning' text='必修' />;
                } else {
                    return <Badge status='success' text='自習' />;
                }
            },
            title: 'テスト種別',
            width: 90,
        },
        {
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                switch (status) {
                    case ('before'): {
                        return <Badge status='processing' text='解答期間前' />;
                    }
                    case ('ongoing'): {
                        return <Badge status='success' text='解答期間中' />;
                    }
                    case ('finished'): {
                        return <Badge status='default' text='解答期間終了' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            },
            title: '状態',
            width: 140,
        },
        {
            key: 'duration',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.publish_start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(record.publish_end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
            title: '解答期間',
            width: 190,
        },
        {
            dataIndex: 'result_exists',
            key: 'result_exists',
            render: (resultExists) => {
                if (resultExists) {
                    return <Badge status='processing' text='解答あり' />;
                } else {
                    return <Badge status='default' text='解答なし' />;
                }
            },
            title: '解答状態',
            width: 140,
        },
        {
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                return (
                    <>
                        <span>{record.created_by.display_name}</span>
                        <br />
                        <span>{record.updated_by.display_name}</span>
                    </>
                );
            },
            title: '作成者/更新者',
            width: 140,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: 170,
        },
    ];

    const filteredColumns = columns.filter(column => !isControllableUser || column.key !== 'is_required');

    return (
        <BaseTable<ExamCategoryWithResult>
            columns={filteredColumns}
            dataSource={dataSource.items}
            emptyDescription={(
                <div>
                    <div>データがありません。</div>
                    <div>条件を変えて検索してください。</div>
                </div>
            )}
            expandedRowRender={
                expandedRowRender(
                    onClickSaveAllReport,
                    onClickSaveReport,
                    onClickViewAllTestResult,
                )
            }
            loading={dataSource.isLoading}
            pagination={pagination}
        />
    );
};

const expandedRowRender = (
    onClickSaveOrUpdateAllReport: Props['onClickSaveAllReport'],
    onClickSaveOrUpdateReport: Props['onClickSaveReport'],
    onClickViewAllTestResult: (exam: ExamWithResult) => void,
    // eslint-disable-next-line react/function-component-definition
) => (categoryRecord: ExamCategoryWithResult) => {
    return (
        <div>
            <ExamTable
                dataSource={categoryRecord.exams}
                examCategory={categoryRecord}
                onClickSaveOrUpdateAllReport={onClickSaveOrUpdateAllReport}
                onClickSaveOrUpdateReport={onClickSaveOrUpdateReport}
                onClickViewAllTestResult={onClickViewAllTestResult}
            />
        </div>
    );
};

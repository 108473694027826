type RequestParameter = Http.Connection.Request.Parameter.EverydayNote.Create
    | Http.Connection.Request.Parameter.EverydayNote.Update;

/**
 * フォームの値をバックエンドに送るフォーマットに変換する
 */
export const convertSubmittableValue = (
    values: EverydayNote.Form.Control.Questionnaire,
    isCopy: boolean
): RequestParameter => {
    const {
        afterMessage,
        allowPastDateAnswer,
        canAdminSetPublic,
        duration,
        isDesignatedTerm,
        isOnceADay,
        isOverwritePublish,
        isRequired,
        isSetRecordableTime,
        isViewedHome,
        items,
        publishFrom,
        publishToAll,
        recordableDayOfWeeks,
        recordableTimeEnd,
        recordableTimeStart,
        targetGrades,
        targetOrganizations,
        targetPermissions,
        targetTerm,
        uuid,
        name,
        // 以下、不要
        // 現状使用しない
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        description: _description,
        // 現状選択しないので固定値
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        type: _type,
    } = values;

    const base = {
        after_message: afterMessage,
        allow_past_date_answer: allowPastDateAnswer,
        can_admin_set_public: canAdminSetPublic,
        day_of_weeks: isSetRecordableTime ?
            recordableDayOfWeeks :
            [0, 1, 2, 3, 4, 5, 6],
        description: '',
        end_time: isSetRecordableTime ?
            recordableTimeEnd?.format('HH:mm') ?? '23:59' :
            '23:59',
        is_designated_term: isDesignatedTerm,
        is_once_a_day: isOnceADay,
        is_required: isRequired,
        is_viewed_home: isViewedHome,
        items: items.map(convertItem(isCopy)),
        name,
        // 保存時には必ず 公開終了時刻 が設定されるため、undefined とはならないが、
        // 適切な型付けが大変なので、一旦強制的に対応する
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        publish_end_at: duration[1]!.unix(),
        publish_from: publishFrom,
        publish_start_at: duration[0].unix(),
        publish_to_all: publishToAll,
        start_time: isSetRecordableTime ?
            recordableTimeStart?.format('HH:mm') ?? '00:00' :
            '00:00',
        target_grades: targetGrades,
        target_organizations: targetOrganizations.map(e => e.uuid),
        target_permissions: targetPermissions,
        target_term: targetTerm ?? null,
        type: 1 as EverydayNote.QuestionnaireTypes,
        // true のときのみ送る
        ...(isOverwritePublish ? { is_overwrite_publish: isOverwritePublish[0] } : {}),
    };

    return isCopy || uuid === undefined ?
        base :
        { ...base, uuid };
};

const convertItem = (isCopy: boolean) => (
    item: Parameters<typeof convertSubmittableValue>[0]['items'][number],
    index: number,
): RequestParameter['items'][number] => {
    const {
        candidates,
        evaluationScaleMax,
        isRequired,
        uuid,
        order,
        ...restItem
    } = item;

    return isCopy || uuid === undefined ?
        {
            candidates: candidates.map(candidate => ({
                content: candidate.content,
                order: candidate.order,
                uuid: isCopy ? undefined : candidate.uuid,
            })),
            is_required: isRequired,
            order: order ?? index,
            ...(restItem.type === 6 ? { evaluation_scale_max: evaluationScaleMax } : {}),
            ...restItem,
        } :
        {
            candidates: candidates.map(candidate => ({
                content: candidate.content,
                order: candidate.order,
                uuid: isCopy ? undefined : candidate.uuid,
            })),
            is_required: isRequired,
            order: order ?? index,
            uuid,
            ...(restItem.type === 6 ? { evaluation_scale_max: evaluationScaleMax } : {}),
            ...restItem,
        };
};
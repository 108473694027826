import {Alert} from 'antd'

type Props = {
    disabledForPublishedItem: boolean;
    editableForCanAdminSetPublic: boolean;
};

/**
 * 全体管理者から配信されたアプリグループorお知らせでのメッセージ
 */
export const PublishedItemAlert = (props: Props) => {
    const {disabledForPublishedItem, editableForCanAdminSetPublic} = props
    if(!disabledForPublishedItem) return null

    return (<div className='mb-8'>
        <Alert type="warning" showIcon message={
            <span>
                全体管理者で管理されています。{editableForCanAdminSetPublic ? '公開期間のみ編集可能です。': '閲覧のみ可能です。'}
            </span>} />
    </div>)
}

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { additionDisplayName } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

export type ViewExamCategoryState = {
    target: ExamCategory | undefined
    isLoading: boolean
};

type Meta = TaoExamCategoryViewConnection['meta'] | TaoExamCategoryControlViewConnection['meta'];
type payload = TaoExamCategoryViewConnection['payload'] | TaoExamCategoryControlViewConnection['payload'];

export const useViewExamCategory = (
    // ここでは引数は使わないが、他でここの実装を真似する際のためにそれっぽくしておく
    successCallback?: (meta: Meta, payload: payload, state: ViewExamCategoryState, isControl: boolean) => void,
    failureCallback?: (meta: Meta, payload: payload, isControl: boolean) => void,
) => {
    const [state, setState] = useState<ViewExamCategoryState>({
        isLoading: false,
        target: undefined,
    });

    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const taoExamCategoryViewConnection = useAppSelector(state => state.taoExamCategoryViewConnection);
    const taoExamCategoryControlViewConnection = useAppSelector(state => state.taoExamCategoryControlViewConnection);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta, payload } = taoExamCategoryViewConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {

                const { created_by, exams, updated_by, ...rest } = payload.result;

                const createdBy = additionDisplayName(created_by);
                const updatedBy = updated_by ?
                    additionDisplayName(updated_by) :
                    created_by;

                const newExams = exams.map(convertToExamFormat);

                const newState = {
                    isLoading: false,
                    target: {
                        ...rest,
                        created_by: createdBy,
                        exams: newExams,
                        updated_by: updatedBy,
                    } as Admin.ExamCategory,
                };

                successCallback && successCallback(meta, payload, newState, true);
                setState(newState);
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback && failureCallback(meta, payload, true);
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryViewConnection]);

    useEffect(() => {
        const { meta, payload } = taoExamCategoryControlViewConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const { exams, target_organizations, created_by, target_term, ...rest } = payload.result;

                const createdBy = additionDisplayName(created_by);
                const newExams = exams.map(convertToExamFormat);

                // /admin/control-exam-category/view/<uuid>のレスポンスには
                // 更新していようといまいと updated_by が存在しない
                const newState = {
                    isLoading: false,
                    target: {
                        ...rest,
                        created_by: createdBy,
                        exams: newExams,
                        target_organizations: target_organizations ?? [],
                        target_term: target_term?.uuid ?? undefined,
                        updated_by: { display_name: '', first_name: '', last_name: '', login_id: '' },
                    } as Control.ExamCategory,
                };

                successCallback && successCallback(meta, payload, newState, true);
                setState(newState);
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback && failureCallback(meta, payload, true);
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlViewConnection]);


    const fetchData = useCallback((
        uuid: string,
        requestType?: 'control' | 'default'
    ) => {
        setState({
            isLoading: true,
            target: undefined,
        });

        const searchAction = isControllableUser || requestType === 'control' ?
            Actions.http.connection.tao.examControlCategory.controlView :
            Actions.http.connection.tao.examCategory.view;

        dispatch(searchAction(uuid, ''));

    }, [dispatch, isControllableUser]);


    const resetData = useCallback(() => {
        setState({
            isLoading: false,
            target: undefined,
        });
    }, []);

    return [state, fetchData, resetData] as const;
};


type ResponseExamCategory = Http.Connection.Response.ExamCategory.View['result']
type ResponseExam = ResponseExamCategory['exams'][number];

/**
 * レスポンスに含まれる Exam を
 * プログラムで使いやすい形式に変換する
 */
const convertToExamFormat = (exam: ResponseExam): Exam => ({
    ...exam,
    created_by: additionDisplayName(exam.created_by),
    max_attempts: exam.max_attempts ?? 0,
});

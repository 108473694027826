import { checkEnvironmentIsTao } from 'constants/GlobalConfig';
import * as React from 'react';
import Helmet from 'react-helmet';

type Props = {
    text: string
}

const Title: React.VFC<Props> = (props) => {
    const defaultTitle = checkEnvironmentIsTao() ?
        '実証用学習eポータル' :
        'L-Gate';

    return (
        <Helmet
            defaultTitle={defaultTitle}
            titleTemplate={`%s - ${defaultTitle}`}
            title={props.text}
        />
    );
};

export default Title;

import { reducer } from './reducer';
import { reducerForControl } from './reducerForControl';
import { savingDataForConnection} from './savingDataForConnection';
import { controlSavingDataForConnection } from './controlSavingDataForConnection';
import * as selectors from './selectors';
import { viewLogic } from './viewLogic';

export type TabKeys = typeof TAB_NAMES[keyof typeof TAB_NAMES];

export const TAB_NAMES = {
    BOARD_OF_EDUCATION:'board' as const,
    SCHOOL:'school' as const,
}

export const defaultState = {
    classListMap: {},
    classListMapItemCount: {},
    classListMapListCount: 0,
    examCategoryList: [],
    examCategoryListItemCount: 0,
    roleList: [],
    roleListItemCount: 0,
    modal: {
        isEditCategoryOpen: false,
        isEditExamOpen: false,
        isStatusOpen: false,
        isCopy: false,
        isLoading: false,
        categoryRecord: undefined,
        examCategoryStatus: {
            breadCrumb: { type: 'List' },
            pagination: {
                total: 0,
                position: ["topRight", "bottomRight"],
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100'],
                pageSize: 50,
                current: 1,
            },
            exams: [],
            search: {},
            organizationsList: [],
            organizationsListItemCount: 0,
            targetUsersCount: undefined,
            usersList: [],
            usersListItemCount: 0,
        },
        testView: {
            exam: undefined,
            loading: true,
            pagination: {
                total: 0,
                position: ['topRight', 'bottomRight'],
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100'],
                pageSize: 10,
                current: 1,
            },
            users: [],
        },
        studentView: {
            student: {
                uuid: '',
                name: '',
            },
            loading: true,
            tests: [],
        },
        statistics: {},
        studentsStatistics: {},
    },
    search: {
        title: undefined,
        is_required: undefined,
    },
    tabName: TAB_NAMES.SCHOOL,
    type: 'examCategoryTab',
    pagination: {
        total: 0,
        position: ["topRight", "bottomRight"],
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '100'],
        pageSize: 10,
        current: 1,
    },
} as ExamCategoryTabState;

export const defaultStateForControl = {
    classListMap: {},
    classListMapItemCount: {},
    classListMapListCount: 0,
    examCategoryList: [],
    examCategoryListItemCount: 0,
    roleList: [],
    roleListItemCount: 0,
    modal: {
        isEditCategoryOpen: false,
        isEditExamOpen: false,
        isStatusOpen: false,
        isCopy: false,
        isLoading: false,
        categoryRecord: undefined,
        examCategoryStatus: {
            breadCrumb: { type: 'List' },
            pagination: {
                total: 0,
                position: ["topRight", "bottomRight"],
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100'],
                pageSize: 50,
                current: 1,
            },
            exams: [],
            search: {},
            organizationsList: [],
            organizationsListItemCount: 0,
            targetUsersCount: undefined,
            usersList: [],
            usersListItemCount: 0,
        },
        testView: {
            exam: undefined,
            loading: true,
            pagination: {
                total: 0,
                position: ['topRight', 'bottomRight'],
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100'],
                pageSize: 10,
                current: 1,
            },
            users: [],
        },
        studentView: {
            student: {
                uuid: '',
                name: '',
            },
            loading: true,
            tests: [],
        },
        statistics: {},
        studentsStatistics: {},
    },
    search: {
        title: undefined,
        is_required: undefined,
    },
    tabName: TAB_NAMES.BOARD_OF_EDUCATION,
    type: 'examControlCategoryTab',
    pagination: {
        total: 0,
        position: ["topRight", "bottomRight"],
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '100'],
        pageSize: 10,
        current: 1,
    },
} as ExamControlCategoryTabState;


export const examCategoryTabFlex = {
    reducer,
    savingDataForConnection,
    selectors,
    viewLogic,
    defaultState,
};

export const examControlCategoryTabFlex = {
    reducerForControl,
    controlSavingDataForConnection,
    selectors,
    viewLogic,
    defaultStateForControl,
};
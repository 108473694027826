import { Fragment, useEffect } from 'react';
import * as React from 'react';
import {
    Upload,
    Popconfirm,
    Button,
    Tooltip,
} from 'antd';
import {
    UpOutlined,
    DownOutlined,
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import EditQuestionModal from './EditQuestionModal';
import * as Actions from 'flex/Actions';
import { editQuestionnaireSelector } from 'flex/view/Questionnaire/EditQuestionnaire/selectors';

const EditRecursive = ({
    disabled,
    form,
}) => {
    const items = useSelector(state => state.editQuestionnaire.editableItems);
    const {
        isEditOpen,
        shouldCreate,
        question,
        parentTmpItemId,
        parentTmpCandidateId,
        questionsCount,
    } = useSelector(state => state.editQuestionnaire.editQuestion);
    const { disabledUpDownMap, disabledEditButtons } = useSelector(editQuestionnaireSelector.disabledItems);
    const isExpandQuestionMap = useSelector(state => state.editQuestionnaire.isExpandQuestionMap);
    const dispatch = useDispatch();
    const renderedQuestionListForRecurse = [];

    useEffect(() => {
        form.setFieldsValue({ items: items });
    }, [form, items]);

    const handleEditQuestion = (parentTmpItemId, parentTmpCandidateId, question) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.openQuestion(parentTmpItemId, parentTmpCandidateId, question));
    }

    const handleCopyQuestion = (item) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.copyQuestion(item));
    }

    const handleDeleteQuestion = (tmpItemId) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.deleteQuestion(tmpItemId));
    }

    const handleSubmitEditQuestion = (value) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.submitQuestion(value));
    }

    const handleCancelEditQuestion = () => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.cancelQuestion());
    }

    const handleUpQuestion = (item) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.moveQuestion('UP', item));
    }

    const handleDownQuestion = (item) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.moveQuestion('DOWN', item));
    }

    const getChildrenItems = (editableItem, tmpItemId) => {
        if (!tmpItemId) return [];
        return editableItem.filter(({ parentTmpItemId }) => (parentTmpItemId === tmpItemId));
    };

    const recurse = (item, index) => {
        const childrenItems = getChildrenItems(items, item.tmpItemId);
        const children = childrenItems.length > 0 && childrenItems.map(recurse);
        const isOpen = isExpandQuestionMap[item.tmpItemId] || false;
        const parentItem = items.find(({ tmpItemId }) => tmpItemId === item.parentTmpItemId);
        const parentCandidate = parentItem &&
            (parentItem.type === 1 || parentItem.type === 2) &&
            parentItem.answer_candidates.find(({ tmpCandidateId }) => tmpCandidateId === item.parentTmpCandidateId);
        const { upDisabled, downDisabled } = disabledUpDownMap[item.tmpItemId] ?
            disabledUpDownMap[item.tmpItemId] :
            { upDisabled: true, downDisabled: true };

        // 表示済みの質問は表示しない
        if (renderedQuestionListForRecurse.includes(item.tmpItemId)) {
            return <Fragment key={`invisible=${item.tmpItemId}`}></Fragment>;
        }

        renderedQuestionListForRecurse.push(item.tmpItemId);

        const handleToggleExpandClick = () => {
            dispatch(Actions.view.viewLogic.editQuestionnaire.toggleExpandQuestion(item.tmpItemId));
        };

        return (
            <div key={item.tmpItemId} className='question_form'>
                <div className='question-control-wrapper'>
                    <Tooltip title={disabledEditButtons ? '回答済みのため並び替えは行えません' : '上へ移動'}>
                        <Button
                            icon={<UpOutlined />}
                            onClick={() => handleUpQuestion(item)}
                            disabled={disabled || upDisabled}
                            style={{ border: '0px' }}
                            size='small'
                            type='primary'
                            shape='circle'
                        />
                    </Tooltip>

                    <Tooltip title={disabledEditButtons ? '回答済みのため並び替えは行えません' : '下へ移動'}>
                        <Button
                            icon={<DownOutlined />}
                            onClick={() => handleDownQuestion(item)}
                            disabled={disabled || downDisabled}
                            style={{ border: '0px' }}
                            size='small'
                            type='primary'
                            shape='circle'
                        />
                    </Tooltip>

                    <div className='question-title'>
                        {item.content}
                    </div>

                    <Tooltip title={isOpen ? '展開された設問を閉じる' : '設問を展開する'}>
                        <Button
                            onClick={handleToggleExpandClick}
                            icon={isOpen ? <UpOutlined /> : <DownOutlined />}
                        >
                            {isOpen ? '閉じる' : '開く'}
                        </Button>
                    </Tooltip>
                </div>

                {parentItem && parentCandidate && (
                    <>
                        <div className='fork-box'>
                            <div className='fork-question-header'>親設問</div>
                            <div className='fork-question-date'>
                                {parentItem.content}
                            </div>
                        </div>
                        <div className='fork-box'>
                            <div className='fork-question-header'>親選択肢</div>
                            <div className='fork-question-date'>
                                {parentCandidate.content}
                            </div>
                        </div>
                    </>
                )}

                {isOpen && (
                    <React.Fragment key={index}>
                        <div className='fork-box'>
                            <div className='fork-question-header'>設問詳細</div>
                            <div className='fork-question-date'>{item.detail}</div>
                        </div>

                        <div className='fork-box'>
                            <div className='fork-question-header'>ファイル添付</div>
                            <div className='fork-question-date'>
                                <Upload
                                    onRemove={() => {
                                        dispatch(Actions.view.viewLogic.editQuestionnaire.deleteFile(item.tmpItemId));
                                    }}
                                    className='upload-list-inline'
                                    beforeUpload={() => false}
                                    fileList={item.file ? [item.file] : []}
                                    listType='picture'
                                />
                            </div>
                        </div>

                        <div className='fork-box'>
                            <div className='fork-question-header'>必須回答</div>
                            <div className='fork-question-date'>
                                {item.is_required ? '必須回答である' : '必須回答ではない'}
                            </div>
                        </div>

                        <div className='fork-box'>
                            <div className='fork-question-header'>回答形式</div>
                            <div className='fork-question-date'>
                                {(() => {
                                    switch (item.type) {
                                        case 1:
                                            return '単一選択式';
                                        case 2:
                                            return '複数選択式';
                                        case 3:
                                            return '自由記述式';
                                        case 4:
                                        default:
                                            return 'ファイル添付';
                                    }
                                })()}
                                {item.answer_candidates?.map((answer, index) => {
                                    return (
                                        <div key={answer.tmpCandidateId} style={{ margin: 10 }}>
                                            選択肢{index + 1}&nbsp;
                                            <br />
                                            ・・・{answer.content}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {children}
                    </React.Fragment>
                )}

                <div className='question-delete-button-wrapper'>
                    <Tooltip title={disabledEditButtons ? '回答済みのため編集は行えません' : '設問を編集'} placement='bottom'>
                        <Button
                            className='question-control-button-mini'
                            icon={<EditOutlined />}
                            onClick={() => handleEditQuestion(
                                parentItem && parentItem.tmpItemId,
                                parentCandidate && parentCandidate.tmpCandidateId,
                                item
                            )}
                            disabled={disabled || disabledEditButtons}
                        >
                            編集
                        </Button>
                    </Tooltip>

                    <Popconfirm
                        title={
                            <span>
                                <strong>設問のコピー</strong><br />
                                設問をコピーします。設問に紐づく子設問も全てコピーされます。
                            </span>
                        }
                        onConfirm={() => handleCopyQuestion(item)}
                        placement='left'
                        okText='コピー'
                        cancelText='キャンセル'
                        disabled={disabled || disabledEditButtons}
                    >
                        <Tooltip
                            title={disabledEditButtons ? '回答済みのためコピーは行えません' : '設問を最下部へコピー'}
                            placement='bottom'
                        >
                            <Button
                                className='question-control-button-mini'
                                icon={<CopyOutlined />}
                                disabled={disabled || disabledEditButtons}
                            >
                                コピー
                            </Button>
                        </Tooltip>
                    </Popconfirm>

                    <Popconfirm
                        title={<span><strong>設問の削除</strong><br />設問を削除します。設問に紐づく子設問も全て削除されます。</span>}
                        onConfirm={() => handleDeleteQuestion(item.tmpItemId)}
                        placement='left'
                        okText='削除'
                        cancelText='キャンセル'
                        disabled={disabled || disabledEditButtons}
                    >
                        <Tooltip title={disabledEditButtons ? '回答済みのため削除は行えません' : '設問を削除'} placement='bottom'>
                            <Button
                                className='question-control-button-mini'
                                icon={<DeleteOutlined />}
                                disabled={disabled || disabledEditButtons}
                            >
                                削除
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </div>
            </div>
        );
    }

    return (
        <div>
            {items && items.map(recurse)}
            <EditQuestionModal
                items={items}
                shouldCreate={shouldCreate}
                question={question}
                parentTmpItemId={parentTmpItemId}
                parentTmpCandidateId={parentTmpCandidateId}
                questionsCount={questionsCount}
                visible={isEditOpen}
                onOk={handleSubmitEditQuestion}
                onCancel={handleCancelEditQuestion}
            />
        </div>
    );
};

export default EditRecursive;

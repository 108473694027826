import {BACKGROUND_JOB_TYPE} from './BackgroundJobProvider'
import * as Actions from '../../../../flex/Actions'



/**
 * job.viewの結果 jobリクエストのresultの中のresult
 * @typedef JobResult
 * @type {Object}
 * @property {string | undefined} file_uuid ダウンロードできるファイルのuuid
 * @property {boolean} is_empty ダウンロードできるファイルが空かどうか
 * @property {boolean} is_success 同期系Jobの正否 CSV処理系Jobだと存在しない
 */

/**
 * job.viewのリクエストをした際のresult. jobを扱うリクエストのレスポンスは基本これ
 * @typedef Job
 * @type {Object}
 * @property {number} created_at jobが作成された時刻
 * @property {number | null} done_at jobが完了した時刻, 未完だとnull
 * @property {boolean} is_success CSV処理系Jobの正否。同期系Jobだと true: jobの完了とエラー, false: jobの未完
 * @property {string | null} message 不明
 * @property {JobResult | null} result 同期系Jobの結果
 * @property {string } type jobの種類 BACKGROUND_JOB_TYPEに対応
 * @property {string } type_name jobの種類の名前
 * @property {number | null} updated_at jobが更新された時刻, 未更新だとnull
 * @property {string} uuid jobのuuid
 */

/**
 * JobCardに必要な情報のまとまり
 * @typedef JobInfo
 * @type {Object}
 * @property {Job} job
 * @property {boolean} isError ネットワークエラー
 * @property {number} gmt レスポンスを返したサーバの時刻
 * @property {string | undefined} someUuid  なにかのuuid (例: アンケート回答結果をダウンロードする際、同一のものはダウンロードさせたくないが、他のアンケートはダウンロードさせたいなどのユースケースにおいて、同じjob.typeでも違うものと区別するためなどに利用する)
 * @property {boolean | undefined} loading jobCardのボタンのローディング ジョブメニュー上で、次のジョブに移動する前に、二回クリックされないように対策
 * @property {boolean | undefined} showClose 閉じるボタンの表示非表示 バックエンドでjobの完了が見込めない同期中のjobに対して、ジョブメニュー上で「閉じる」ボタンを表示させる
 */

/***
 * ジョブの完了に関する状態
 * @typedef BackgroundJobStatus
 * @type {'done' | 'sync' | 'wait' | 'error' }
 */

/***
 * @type {Object}
 * @property {'wait'} wait
 * @property {'error'} error
 * @property {'done'} done
 * @property {'sync'} sync
 */
export const BackgroundJobStatuses = {
    /*** @type 'done' */ done: 'done',
    /*** @type 'sync' */ sync: 'sync',
    /*** @type 'wait' */ wait: 'wait',
    /*** @type 'error' */ error: 'error'
}

/***
 * Jobの状態を取得する
 * @param {Job} job
 * @return BackgroundJobStatus
 */
export const getJobStatus = (job) => {
    return !job.done_at? BackgroundJobStatuses.sync
        : job?.result?.is_success !== undefined
            ? job?.result?.is_success  // 同期などの成功判定
                ? BackgroundJobStatuses.done : BackgroundJobStatuses.error
            : job?.is_success // CSV エクスポートなどの成功判定
                ? BackgroundJobStatuses.done : BackgroundJobStatuses.error
}

/** 現状のjobに応じた、次の同期のタスクをdispatchする  */
export function dispatchNextSyncAction(job, dispatch, setLoadingJobInfo, features) {
    switch (job.type) {
        case BACKGROUND_JOB_TYPE.SYNC_GROUP_DRYRUN:
            dispatch(Actions.http.connection.sync.executeGroup(job.uuid));
            setLoadingJobInfo(job.uuid);
            break;

        case BACKGROUND_JOB_TYPE.SYNC_GROUP:
            dispatch(Actions.http.connection.sync.dryRunUser(job.uuid));
            setLoadingJobInfo(job.uuid);
            break;

        case BACKGROUND_JOB_TYPE.SYNC_USER_DRYRUN:
            dispatch(Actions.http.connection.sync.executeUser({update_password: 0},job.uuid))
            setLoadingJobInfo(job.uuid);
            break;

        case BACKGROUND_JOB_TYPE.SYNC_USER:
            if(!features['sync-group']) break;
            dispatch(Actions.http.connection.sync.dryRunGroupMember(job.uuid));
            setLoadingJobInfo(job.uuid);
            break;

        case BACKGROUND_JOB_TYPE.SYNC_MEMBER_DRYRUN:
            dispatch(Actions.http.connection.sync.executeGroupMember(job.uuid));
            setLoadingJobInfo(job.uuid);
            break;

        case BACKGROUND_JOB_TYPE.SYNC_DELETED_USER_DRYRUN:
            dispatch(Actions.http.connection.sync.executeDeleteUser(job.uuid));
            setLoadingJobInfo(job.uuid);
            break;
        default:
            // 上記以外は無視
            break;
    }
}


/***
 * jobInfoMapに指定したtypeのjobInfoが含まれているかどうか
 * @param jobInfoMap
 * @param type
 * @return {boolean}
 */
export const isContainsJobType = (jobInfoMap, type) => {
    return jobInfoMap ? (Object.values(jobInfoMap).filter(jobInfo => jobInfo?.job?.type === type).length > 0) : false
}

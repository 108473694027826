import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { generateFileDomain } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Config = {
    /**
     * アップロード可能な拡張子の一覧
     *
     * 設定されていない (undefined) 場合, すべての拡張子を許可する
     *
     * デフォルト: undefined
     */
    allowedExtensions: string[] | undefined,

    /**
     * ファイルを受け取ってすぐにアップデートするか
     *
     * デフォルト: true
     */
    isUploadImmediately: boolean | undefined

    /**
     * ファイルを保持できる数
     *
     * 0 の場合, 無制限
     *
     * デフォルト: 1
     */
    stockSize?: number

    /** 最大ファイルサイズ(byte) */
    fileSizeOfMax: number

    /** 最小ファイルサイズ(byte) */
    fileSizeOfMin: number
}

// RcFile, UploadFile, レスポンスの File の型3つが混在しており,
// 現状では型を付けるのが不可能なので, 仕方なく any とする
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type File = any;

/** ファイルアップロード  */
export const useFileManager = (
    {
        allowedExtensions = undefined,
        isUploadImmediately = true,
        stockSize = 1,
        fileSizeOfMax,
        fileSizeOfMin,
    }: Config,
    /**
     * アップロード成功後、Responseの形式のファイル or 生のデータを親に渡す。
     * なければ、ただのプレビューになり動作しない
     */
    onChange: (uploadedFile: File) => void,
) => {
    const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
    const [files, originSetFiles] = useState<File[]>([]);
    const [lastUploadGmt, setLastUploadGmt] = useState(0);
    const dispatch = useDispatch();
    const fileConnection = useAppSelector(state => state.fileConnection);

    const setFiles = useCallback((newFiles: Parameters<typeof originSetFiles>[0]) => {
        if (typeof newFiles === 'function') {
            onChange(newFiles(files).slice(-stockSize));
            originSetFiles(files => newFiles(files).slice(-stockSize));
        } else {
            const state = newFiles.slice(-stockSize);
            onChange(state);
            originSetFiles(state);
        }
    }, [files, onChange, stockSize]);

    useEffect(() => {
        if (fileConnection.meta.status !== Actions.statusEnum.SUCCESS) return;
        if (fileConnection.payload.gmt === lastUploadGmt) return;
        if (uploadingFiles.every(file => file.status === 'done')) return;
        const result = fileConnection.payload.result;

        setLastUploadGmt(fileConnection.payload.gmt);

        setUploadingFiles(uploadingFiles => uploadingFiles.filter((value) => {
            return value.name !== result.file_name;
        }));

        setFiles(files => files.concat([convertLGateFileToAntdFile(result, 'uploading')]));

        // リードレプリカの関係で、アップロードしたファイルが一時的に 404 になることがあるため、数回リトライする
        retryFetchFile(result).then((result) => {
            // setFiles だとうまくいかないので、originSetFiles で更新する
            originSetFiles(files => {
                const newFiles = files.filter(f => f.uuid !== result.uuid);

                return newFiles.length === files.length ?
                    files :
                    newFiles.concat([convertLGateFileToAntdFile(result, 'done')]);
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileConnection]);

    useEffect(() => {
        onChange(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- filesのみ監視したいため
    }, [files]);

    const add = useCallback((file: File) => {
        const ext = file.name.replace(/^.+\./, '').toLowerCase();
        if (!!allowedExtensions && !allowedExtensions.includes(ext)) {
            throw new Error(`拡張子が '${ext}' のファイルは添付できません`);
        }

        if (file.size <= fileSizeOfMin) {
            throw new Error(`${byte(fileSizeOfMin)}以下のファイルは添付できません`);
        }

        if (file.size >= fileSizeOfMax) {
            throw new Error(`${byte(fileSizeOfMax)}以上のファイルは添付できません`);
        }

        if (isUploadImmediately) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const uploadingFile = generateUploadingFile(file.name);

                setUploadingFiles(files => files.concat([uploadingFile]));

                dispatch(Actions.http.connection.file.upload(
                    file.name,
                    file.type,
                    event?.target?.result,
                ));
            };
            fileReader.readAsArrayBuffer(file);
        } else {
            setFiles(files => files.concat([file]));
        }
        return false;
    }, [allowedExtensions, dispatch, fileSizeOfMax, fileSizeOfMin, isUploadImmediately, setFiles]);

    const remove = useCallback((removedFile: File) => {
        setFiles(files => files.filter(f => f.uid !== removedFile.uid));
    }, [setFiles]);

    const reset = useCallback((initValue: {
        created_at: number;
        file_name: string;
        mime_type: string;
        uuid: string;
    }[] = []) => {
        const newFileList = initValue
            .filter(v => v)
            // ここで, バックエンドから返ってきた値を antd が表示できる形式に変換する.
            .map(file => convertLGateFileToAntdFile(file, 'done'));

        setFiles(newFileList);
    }, [setFiles]);

    return [add, remove, reset, files] as const;
};

/***
 * L-GateのresponseのFileのデータオブジェクトを、Antd用に変換する
 */
export const convertLGateFileToAntdFile = (uploadedFile: File, status: 'uploading' | 'done') => {
    const uuid = uploadedFile.uuid || Math.floor(Math.random() * 10000).toString();

    const thumbUrl = (uploadedFile?.mime_type.match('image')) ?
        `${generateFileDomain()}/file/view/${uuid}` :
        process.env.PUBLIC_URL + '/static/media/attach.svg';

    return {
        mime_type: uploadedFile.mime_type,
        name: uploadedFile.file_name,
        response: uploadedFile,
        status: status,
        thumbUrl: thumbUrl,
        uid: uuid,
        url: `${generateFileDomain()}/file/view/${uuid}`,
        uuid,
    };
};

const generateUploadingFile = (fileName: string) => ({
    name: fileName,
    status: 'uploading',
    uid: Math.floor(Math.random() * 10000).toString(),
});

/**
 * ファイルサイズを適切な単位に変換する
 */
const byte = (size: number, decimal = 0) => {
    const units = ['byte', 'KB', 'MB', 'GB', 'TB'];

    const { newSize, unit } = [...Array(5)]
        .map((_, i) => {
            const target = size / Math.pow(1000, i);

            return target < 1000 ?
                { newSize: target, unit: units[i] } :
                undefined;
        })
        .filter(e => e)[0] ??
        { newSize: size / Math.pow(1000, 4), unit: units[4] };

    const d = Math.pow(10, decimal);

    const floor = Math.floor(newSize * d) / d;

    return floor + unit;
};

// Promise を使った方法だと、 リトライを途中で中断する方法を実装するのが大変なので、
// 上限回数を設けて、その回数だけリトライすることとした。
const COUNT_MAX = 5;
/**
 * ファイルが取得できるか、`COUNT_MAX` 回までリトライする関数
 * @param result File を作成したときの結果
 * @param count リトライした回数
 * @returns
 */
const retryFetchFile = (result: FileConnection['payload']['result'], count = 0) => {
    return new Promise<typeof result>(resolve => {
        fetch(`${generateFileDomain()}/file/view/${result.uuid}`).then((response) => {
            if (response.status === 200 || count + 1 >= COUNT_MAX) {
                resolve(result);
                return;
            }

            setTimeout(() => {
                retryFetchFile(result, count + 1).then(resolve);
            }, 2000);
        });
    });
};

import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { TeamOutlined, UserOutlined, IdcardOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import ClassesList from './Classes';
import UsersList from './Users/UsersList';
import UserEffectComponent from './Users/UserEffectComponent';
import RoleList from './Role/RoleList';
import RoleEffectComponent from './Role/RoleEffectComponent';
import ClassEffectComponent from './Classes/ClassEffectComponent';
import AnnualUpdate from './Classes/ControlContents';
import TermEffectComponent from './Term/TermEffectCompornent';
import {
    useTabManagement,
} from 'constants/CustomHooks/useTabManagement';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

const { TabPane } = Tabs;

type ListContextType = {
    classList: SchoolClass[],
    classListItemCount: number,
    classListMap: Record<string, SchoolClass[]>,
    classListMapItemCount: Record<string, number>,
    classListMapListCount: number,
    gradeList: Grade[],
    gradeListItemCount: number,
    organizationList: Organization[],
    organizationListItemCount: number,
    roleList: Role[],
    roleListItemCount: number,
    termList: Term[],
    termListItemCount: number,
    usersList: User[],
    usersListItemCount: number,
}

type SearchContextType = {
    page_size: number,
}

export const ListContext = createContext<UseStateType<ListContextType>>([
    {
        classList: [],
        classListItemCount: 0,
        classListMap: {},
        classListMapItemCount: {},
        classListMapListCount: 0,
        gradeList: [],
        gradeListItemCount: 0,
        organizationList: [],
        organizationListItemCount: 0,
        roleList: [],
        roleListItemCount: 0,
        termList: [],
        termListItemCount: 0,
        usersList: [],
        usersListItemCount: 0,
    },
    () => { }
]);

export const SearchContext = createContext<UseStateType<SearchContextType>>([
    { page_size: 50 },
    () => { },
]);

export const TabKeyEnum = {
    USERS: 'users' as const,
    CLASSES: 'classes' as const,
    ROLES: 'roles' as const,
}

const UsersManagement: React.VFC = () => {
    const [tabActiveKey, setTabActiveKey] = useTabManagement<
        typeof TabKeyEnum[keyof typeof TabKeyEnum]
    >(
        TabKeyEnum,
        TabKeyEnum.USERS
    );
    const [list, setList] = useState<ListContextType>({
        classList: [],
        classListItemCount: 0,
        classListMap: {},
        classListMapItemCount: {},
        classListMapListCount: 0,
        gradeList: [],
        gradeListItemCount: 0,
        organizationList: [],
        organizationListItemCount: 0,
        roleList: [],
        roleListItemCount: 0,
        termList: [],
        termListItemCount: 0,
        usersList: [],
        usersListItemCount: 0,
    });
    const [search, setSearch] = useState({ page_size: 50 });
    const isControllableUser = useAppSelector(state => state.isControllableUser);
    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);
    const dispatch = useDispatch();
    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();

    useEffect(() => {
        if (!isControllableUser.payload) return;
        dispatch(Actions.http.connection.organization.search({ page_size: 5000 }, 1, ''));
    }, [dispatch, isControllableUser.payload]);

    useEffect(()=>{
        if(organizationSearchConnection.meta.status === Actions.statusEnum.SUCCESS){
            setList(list => ({
                ...list,
                organizationList: organizationSearchConnection.payload.result.items,
                organizationListItemCount: organizationSearchConnection.payload.result.item_count,
            }));
        }
    },[organizationSearchConnection]);

    return (
        <ListContext.Provider value={[list, setList]}>
            <SearchContext.Provider value={[search, setSearch]}>
                <UserEffectComponent />
                <RoleEffectComponent />
                <ClassEffectComponent />
                <TermEffectComponent context={ListContext} />
                <div className='page-UsersManagement card-container'>
                    <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey} >
                        <TabPane tab={<span><TeamOutlined />ユーザー管理</span>} key={TabKeyEnum.USERS}>
                            <UsersList tabActiveKey={tabActiveKey} hasTouchScreen={hasTouchScreen}/>
                        </TabPane>
                        <TabPane tab={<span><UserOutlined />クラス管理</span>} key={TabKeyEnum.CLASSES}>
                            {!isControllableUser.payload
                                ? <ClassesList tabActiveKey={tabActiveKey} hasTouchScreen={hasTouchScreen}/>
                                : <AnnualUpdate />
                            }
                        </TabPane>
                        {!isControllableUser.payload ?
                            <TabPane tab={<span><IdcardOutlined />役割管理</span>} key={TabKeyEnum.ROLES}>
                                <RoleList tabActiveKey={tabActiveKey} hasTouchScreen={hasTouchScreen}/>
                            </TabPane>
                            :
                            null
                        }
                    </Tabs>
                </div>
            </SearchContext.Provider>
        </ListContext.Provider>
    )
};

export default UsersManagement;

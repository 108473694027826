import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';


export type DistributionGraphState = {
    result: {
        name: string
        count: number
    }[]
    averageScore: number
    scoreMax: number
    isLoading: boolean
};

type Connection = TaoExamDistributionGraphConnection | TaoExamControlDistributionGraphConnection;


export const useDistributionGraph = () => {
    const [state, setState] = useState<DistributionGraphState>({
        averageScore: -1,
        isLoading: false,
        result: [],
        scoreMax: -1,
    });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamDistributionGraphConnection = useAppSelector(state => state.taoExamDistributionGraphConnection);
    const taoExamControlDistributionGraphConnection = useAppSelector(state => state.taoExamControlDistributionGraphConnection);

    const dispatch = useDispatch();


    const effect = useCallback((connection: Connection, state: DistributionGraphState) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState({
                    averageScore: payload.result.average_score,
                    isLoading: false,
                    result: Object.entries(payload.result.items).map(([key, val]) => {
                        return {
                            count: isNaN(Number(val)) ? 0 : Number(val),
                            name: key,
                        };
                    }),
                    scoreMax: payload.result.score_max,
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => effect(taoExamDistributionGraphConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamDistributionGraphConnection]
    );
    useEffect(
        () => effect(taoExamControlDistributionGraphConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlDistributionGraphConnection]
    );


    const fetchData = useCallback((uuid: string, classUuid: string, tab: TabKeys | '', organizationUuid?: string) => {
        setState({
            averageScore: -1,
            isLoading: true,
            result: [],
            scoreMax: -1,
        });

        const distributionGraphAction = isControllableUser ?
            Actions.http.connection.tao.exam.controlDistributionGraph(uuid, { organization_uuid: organizationUuid, school_class_uuid: classUuid }) :
            tab === 'board' ?
                Actions.http.connection.tao.exam.controlDistributionGraph(uuid, { school_class_uuid: classUuid }) :
                Actions.http.connection.tao.exam.distributionGraph(uuid, { school_class_uuid: classUuid });

        dispatch(distributionGraphAction);

    }, [dispatch, isControllableUser]);

    return [state, fetchData] as const;
};

import { combineActions, handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: unknown, action: any) => {
        return { ...action };
    },
    throw: (state: unknown, action: unknown) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


// admin 系

// examCategory と名前がかぶっているので、examControlCategory としている
export const taoExamControlCategoryUserStatisticsConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.userStatistics,
    defaultReducer,
    defaultState
);


// control 系

export const taoExamCategoryControlChangeOrderConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlChangeOrder,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlChangeCategoryOrderConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlChangeCategoryOrder,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlEditConnection = handleAction(
    combineActions(
        Actions.http.connection.tao.examControlCategory.controlCreate,
        Actions.http.connection.tao.examControlCategory.controlUpdate,
    ),
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlDeleteConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlDelete,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlResultConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlResult,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlSearchConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlSearch,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlUserStatisticsConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlUserStatistics,
    defaultReducer,
    defaultState
);

export const taoExamCategoryControlViewConnection = handleAction(
    Actions.http.connection.tao.examControlCategory.controlView,
    defaultReducer,
    defaultState
);
import { additionDisplayName } from 'constants/GlobalConfig';

const convertCandidate = (
    candidate: Http.Connection.Response.EverydayNote.Candidate
): EverydayNote.Candidate => {
    return {
        content: candidate.content,
        order: candidate.order,
        uuid: candidate.uuid,
    };
};

const convertQuestion = (
    question: Http.Connection.Response.EverydayNote.Question
): EverydayNote.Question => {
    const newCandidates = question.candidates.map(convertCandidate);

    return {
        candidates: newCandidates,
        content: question.content,
        detail: question.detail,
        evaluationScaleMax: question.evaluation_scale_max,
        isRequired: question.is_required,
        order: question.order,
        type: question.type,
        uuid: question.uuid,
    };
};

/**
 * バックエンドからのレスポンスをフロントエンドで使うフォーマットに変換する
 */
export const convertQuestionnaire = (
    questionnaire: PartOfPartial<
        Http.Connection.Response.EverydayNote.Questionnaire,
        'target_grades' | 'target_organization' | 'target_permissions' | 'target_term'
    >
): EverydayNote.ControlQuestionnaire => {
    const createdBy = {
        displayName: additionDisplayName(questionnaire.created_by).display_name,
    };
    const updatedBy = {
        displayName: additionDisplayName(questionnaire.updated_by).display_name,
    };

    const newQuestions = questionnaire.items.map(convertQuestion);

    return {
        afterMessage: questionnaire.after_message,
        allowPastDateAnswer: questionnaire.allow_past_date_answer,
        canAdminSetPublic: questionnaire.can_admin_set_public,
        createdAt: questionnaire.created_at,
        createdBy: createdBy,
        description: questionnaire.description,
        isDesignatedTerm: questionnaire.is_designated_term,
        isLocked: questionnaire.is_locked,
        isOnceADay: questionnaire.is_once_a_day,
        isRequired: questionnaire.is_required,
        isViewedHome: questionnaire.is_viewed_home,
        items: newQuestions,
        name: questionnaire.name,
        publishEndAt: questionnaire.publish_end_at,
        publishFrom: questionnaire.publish_from,
        publishStartAt: questionnaire.publish_start_at,
        publishToAll: questionnaire.publish_to_all,
        recordableDayOfWeeks: questionnaire.day_of_weeks,
        recordableTimeEnd: questionnaire.end_time,
        recordableTimeStart: questionnaire.start_time,
        statusPeriod: questionnaire.status_period,
        targetGrades: questionnaire.target_grades?.map(e => e.code) ?? [],
        targetOrganizations: questionnaire?.target_organization ?? [],
        targetPermissions: questionnaire.target_permissions?.map(e => e.code) ?? [],
        targetTerm: questionnaire.target_term?.uuid ?? undefined,
        type: questionnaire.type,
        updatedAt: questionnaire.updated_at,
        updatedBy: updatedBy,
        uuid: questionnaire.uuid,
    };
};


const convertAdminCandidate = (
    candidate: Http.Connection.Response.EverydayNote.QuestionAdmin['candidates'][number]
): EverydayNote.Candidate => {
    return {
        content: candidate.content,
        order: candidate.order,
        uuid: candidate.uuid,
    };
};

const convertAdminQuestion = (
    question: Http.Connection.Response.EverydayNote.QuestionAdmin
): EverydayNote.Question => {
    const newCandidates = question.candidates.map(convertAdminCandidate);

    return {
        candidates: newCandidates,
        content: question.content,
        detail: question.detail,
        evaluationScaleMax: question.evaluation_scale_max,
        isRequired: question.is_required,
        order: question.order,
        type: question.type,
        uuid: question.uuid,
    };
};

/**
 * バックエンドからのレスポンスをフロントエンドで使うフォーマットに変換する
 */
export const convertAdminQuestionnaire = (
    questionnaire: PartOfPartial<
        Http.Connection.Response.EverydayNote.QuestionnaireAdminAdmin,
        'target_roles' | 'target_school_classes' | 'target_users'
    >
): EverydayNote.AdminQuestionnaire => {
    const createdBy = {
        displayName: additionDisplayName(questionnaire.created_by).display_name,
    };
    const updatedBy = {
        displayName: additionDisplayName(questionnaire.updated_by).display_name,
    };

    const newQuestions = questionnaire.items.map(convertAdminQuestion);

    return {
        afterMessage: questionnaire.after_message,
        allowPastDateAnswer: questionnaire.allow_past_date_answer,
        createdAt: questionnaire.created_at,
        createdBy: createdBy,
        description: questionnaire.description,
        isLocked: questionnaire.is_locked,
        isOnceADay: questionnaire.is_once_a_day,
        isRequired: questionnaire.is_required,
        isViewedHome: questionnaire.is_viewed_home,
        items: newQuestions,
        name: questionnaire.name,
        publishEndAt: questionnaire.publish_end_at,
        publishFrom: questionnaire.publish_from,
        publishStartAt: questionnaire.publish_start_at,
        recordableDayOfWeeks: questionnaire.day_of_weeks,
        recordableTimeEnd: questionnaire.end_time,
        recordableTimeStart: questionnaire.start_time,
        statusPeriod: questionnaire.status_period,
        targetRoles: questionnaire.target_roles ?? [],
        targetSchoolClasses: questionnaire.target_school_classes ?? [],
        targetUsers: questionnaire.target_users ?? [],
        type: questionnaire.type,
        updatedAt: questionnaire.updated_at,
        updatedBy: updatedBy,
        uuid: questionnaire.uuid,
    };
};

import { additionDisplayName } from 'constants/GlobalConfig';


export const dataSourceConvert = (data) => data.map(item => {
    const createdBy = additionDisplayName(item.created_by);
    const updatedBy = item.updated_by ?
        additionDisplayName(item.updated_by) :
        createdBy;

    return {
        author: {
            create: createdBy.display_name,
            update: updatedBy.display_name,
        },
        canAdminSetPublic: item.can_admin_set_public,
        canBeAnsweredOnlyOnce: item.can_be_answered_only_once,
        category: item.category.name,
        content: item.content,
        datetime: {
            created_at: item.created_at,
            updated_at: item.updated_at,
        },
        durationPerform: {
            end_at: item.perform_end_at,
            start_at: item.perform_start_at,
        },
        durationPublish: {
            end_at: item.publish_end_at,
            start_at: item.publish_start_at,
        },
        durationResult: {
            end_at: item.publish_result_end_at,
            start_at: item.publish_result_start_at,
        },
        isAnonymous: item.is_anonymous,
        isEditable: item.is_editable,
        isLocked: item.is_locked,
        isPublic: item.is_public,
        isPublicResult: item.is_public_result,
        isPublishedItem: item.is_published_item,
        key: item.uuid,
        rewritable: !item.can_be_answered_only_once,
        sender: item.publish_from,
        status: item.status_period,
        title: item.name,
    };
});
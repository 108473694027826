import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import { getDefaultLayout } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

const Form = BaseForm.ModalForm;
const { statusEnum } = Actions;
const layout = getDefaultLayout();

const initFieldValue = {
    code: null,
    name: '',
    school_code: null,
    type: '2',
};

type Props = {
    record: Organization | null;
    visible: boolean;
    onOk: () => void;
    onCancel: () => void;
};

const EditOrganization = ({ onCancel, onOk, record, visible }: Props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const organizationEditConnection = useAppSelector(state => state.organizationEditConnection);
    const isFirstRender = useRef(false);

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (record) {
            const reuseValue = {
                code: record.code,
                name: record.name,
                school_code: record.school_code,
                type: record.type,
            };
            form.setFieldsValue(reuseValue);
        } else {
            form.resetFields();
            form.setFieldsValue(initFieldValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, visible]);

    useEffect(() => {
        if(isFirstRender.current) return;

        setLoading(organizationEditConnection.meta.fetch);
        if (organizationEditConnection.meta.status === statusEnum.SUCCESS) {
            onOk();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationEditConnection]);

    useEffect(() => {
        if(!isFirstRender.current) return;

        isFirstRender.current = false;
    }, []);


    const handleSubmit = () => {
        form.submit();
    };

    const onFinish = (values: Http.Connection.Request.Parameter.Organization.Edit) => {
        if (record) {
            dispatch(Actions.http.connection.organization.update(record.uuid, values));
        } else {
            dispatch(Actions.http.connection.organization.create(values));
        }
    };

    return (
        <BaseModal
            className='common-modal'
            footer={[
                <Button
                    key='back'
                    loading={loading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    loading={loading}
                    onClick={handleSubmit}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined /> {record ? '編集' : '新規作成'}</span>}
            visible={visible}
        >
            <Form {...layout} form={form} name='control-hooks-edit-organization' onFinish={onFinish}>
                <Form.Item
                    label='学校名'
                    name='name'
                    rules={[{ message: '学校名を入力してください', required: true }, { max: 64, message: '学校名は64文字以内で入力してください' }]}
                >
                    <Input placeholder='学校名を入力してください' />
                </Form.Item>
                <Form.Item
                    label='校種コード'
                    name='code'
                    rules={[{ message: '校種コードを入力してください', required: true }]}
                >
                    <Select
                        allowClear
                        placeholder='校種コードを選択してください'
                    >
                        <Select.Option key='P' value='P'>小学校</Select.Option>
                        <Select.Option key='J' value='J'>中学校</Select.Option>
                        <Select.Option key='PJ' value='PJ'>義務教育学校</Select.Option>
                        <Select.Option key='JH' value='JH'>中等教育学校</Select.Option>
                        <Select.Option key='H' value='H'>高等学校（学年制）</Select.Option>
                        <Select.Option key='U' value='U'>高等学校（単位制）</Select.Option>
                        <Select.Option key='S' value='S'>特別支援学校</Select.Option>
                        <Select.Option key='A' value='A'>その他</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label='権限'
                    name='type'
                    rules={[{ message: '権限を入力してください', required: true }]}
                >
                    <Select
                        allowClear
                        placeholder='権限を選択してください'
                    >
                        <Select.Option key='1' value='1'>管理組織</Select.Option>
                        <Select.Option key='2' value='2'>一般</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label='文部科学省 学校コード'
                    name='school_code'
                    rules={[
                        {
                            message: '文部科学省 学校コードは半角英数字で入力してください',
                            pattern: /^[a-zA-Z0-9]*$/,
                        },
                        {
                            max: 13,
                            message: '文部科学省 学校コードは13文字以内で入力してください',
                        },
                    ]}
                >
                    <Input placeholder='文部科学省 学校コードを入力してください' />
                </Form.Item>
            </Form>
        </BaseModal>
    );
};

export default EditOrganization;

import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table } from 'antd';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import * as Actions from 'flex/Actions';
import { useSource } from '../SourceProvider';
import BaseModal from 'components/modules/BaseModal';

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const AppsOrderModal = (props) => {
    const { categoryUuid, onOk, onCancel, visible } = props;
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([])
    const [source,] = useSource();
    const isFirstRender = useRef(true);

    const applications = source.appCategoryMap[categoryUuid]?.item?.applications;

    useEffect(() => {//表示時、非表示時の初期化
        if (visible) {
            applications && setDataSource(applications.map((value, index) => ({
                ...value,
                index,
            })));
        } else {
            setDataSource([]);
        }
    }, [visible])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFirstRender.current) return;
        if (!source.appOrderEdit.loading && source.appOrderEdit.status === Actions.statusEnum.SUCCESS) {
            onOk();
        }
        // loading だけ監視
    }, [source.appOrderEdit.loading]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isFirstRender.current = false
    }, [])

    const handleSubmit = () => {
        const data = {
            uuids: dataSource.map(value => value.uuid),
        }
        dispatch(Actions.http.connection.apps.controlChangeOrder(categoryUuid, data, categoryUuid));
    }

    const DragHandle = sortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        setDataSource(newData);
    };

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            helperClass='row-dragging'
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const columns = [
        {
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        }, {
            title: 'アプリ名',
            dataIndex: 'title',
            width: 350,
            key: 'title',
            className: 'drag-visible',
        }
    ];

    return (
        <BaseModal
            className='common-modal'
            title={<span>アプリ並び順の編集</span>}
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button
                    key='back_order'
                    loading={source.appOrderEdit.loading}
                    size='large'
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='create_order'
                    loading={source.appOrderEdit.loading}
                    type='primary'
                    size='large'
                    onClick={handleSubmit}
                >
                    保存
                </Button>
            ]}>
            <div>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey='index'
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            </div>
        </BaseModal>
    );
}

export default AppsOrderModal;

const emptyInitialValues = {
    ltiUrlCandidateUuid: undefined,
    ltiUrlUuid: undefined,
};

export const createInitialValues = (
    appTemplate,
    isControllableUser
) => {
    if (appTemplate === undefined || appTemplate === null) {
        return emptyInitialValues;
    }

    switch (appTemplate.type) {
        case 1:{
            return createLti11InitialValues(
                appTemplate,
                isControllableUser
            );
        }
        case 2:{
            return createLti13InitialValues(appTemplate);
        }
        default:
            break;
    }
};

const createLti11InitialValues = (
    appTemplate,
    isControllableUser,
) => {
    if (!appTemplate.url.is_choices) {
        const params = appTemplate.params?.filter(param => {
            return isControllableUser ?
                param?.changeable_param_control :
                param?.changeable_param_admin;
        });
        const url = appTemplate.url;
        return {
            ltiUrlCandidateUuid: url.candidates?.find(
                c => url?.url ? isSelectedUrl(c, appTemplate) : c.is_default
            )?.uuid,
            ltiUrlUuid: url?.uuid,
            ltiUrlUrl: url?.url,
            ltiUrlKey: url?.key,
            ltiUrlSecret: url?.secret,
            params: params?.map(param => ({
                ltiParamCandidateUuid: param.candidates.find(
                    c => param.value ? c.value === param.value: c.is_default
                )?.uuid,
                ltiParamUuid: param.uuid,
                ltiParamValue: param.value,
            })),
        };
    } else {
        const params = appTemplate.params;
        return {
            ltiUrlCandidateUuid: appTemplate.url?.candidates?.find(c => c.is_default)?.uuid,
            ltiUrlUuid: appTemplate.url?.uuid,
            params: params?.map(param => ({
                ltiParamCandidateUuid: param.candidates.find(c => c.is_default)?.uuid || '',
                ltiParamUuid: param.uuid,
            })),
        };
    }
};

const createLti13InitialValues = (
    appTemplate,
) => {
    if (!appTemplate.url.is_choices) {
        const url = appTemplate.url;
        return {
            ltiUrlCandidateUuid: url.candidates?.find(
                c => url?.is_choices ? c.is_default : isSelectedUrl(c, appTemplate)
            )?.uuid,
            param_json: url.param_json === null ?
                url.param_json :
                {
                    ...url.param_json,
                    redirect_uris: typeof url.param_json.redirect_uris === 'string' ?
                        [url.param_json.redirect_uris] :
                        url.param_json.redirect_uris,
                    use_organization_code: appTemplate.use_organization_code,
                },
            params: [],
        };
    } else {
        const url = appTemplate.url;
        return {
            ltiUrlCandidateUuid: url.candidates?.find(c => c.is_default)?.uuid,
            ltiUrlUuid: url.uuid,
            params: [],
        };
    }
};

const isSelectedUrl = (candidate, appTemplate) => {
    switch(appTemplate.type) {
        case 1: {
            return candidate.url === appTemplate.url.url
                && candidate.key === appTemplate.url.key
                && candidate.secret === appTemplate.url.secret;
        }
        case 2: {
            const canParamJson = candidate.param_json;
            const appTempParamJson = appTemplate.url.param_json;
            return canParamJson.tool_url === appTempParamJson.tool_url
                && canParamJson.initiate_login_url === appTempParamJson.initiate_login_url
                && canParamJson.public_keyset === appTempParamJson.public_keyset
                && canParamJson.redirect_uris === appTempParamJson.redirect_uris
                && canParamJson.login_hint === appTempParamJson.login_hint
                && canParamJson.deployment_id === appTempParamJson.deployment_id;
        }
        default: {
            return false;
        }
    }
}
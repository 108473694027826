import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Result = {
    isLoading: boolean,
    item: { message: string } | undefined
}

const INIT_STATE: Result = {
    isLoading: false,
    item: undefined,
};

// 学校管理者は全体管理者の作成した毎日の記録を削除できないので、考慮しないで良い
export const useDeleteQuestionnaire = () => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const controlEverydayNoteDeleteConnection = useAppSelector(state => state.controlEverydayNoteDeleteConnection);
    const adminEverydayNoteDeleteConnection = useAppSelector(state => state.adminEverydayNoteDeleteConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback((uuid: string) => {
        const deleteAction = isControllableUser ?
            Actions.http.connection.everydayNote.control.delete :
            Actions.http.connection.everydayNote.admin.delete;

        dispatch(deleteAction(uuid));
        setResult(state => ({ ...state, isLoading: true }));
    }, [dispatch]);// eslint-disable-line

    type Connection = EverydayNoteDeleteConnection;
    const connectionEffect = (connection: Connection) => () => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;

                setResult({
                    isLoading: false,
                    item: payloadResult,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
    };

    useEffect(
        connectionEffect(controlEverydayNoteDeleteConnection),
        [controlEverydayNoteDeleteConnection]
    );
    useEffect(
        connectionEffect(adminEverydayNoteDeleteConnection),
        [adminEverydayNoteDeleteConnection]
    );

    return [result, request, reset] as const;
};

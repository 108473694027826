import { useState } from 'react';
import * as React from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    Popconfirm,
    Radio,
    RadioChangeEvent,
    Tooltip,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import ExportOutlined from '@ant-design/icons/lib/icons/ExportOutlined';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

type Options = Partial<{
    startMonth: number
    endMonth: number
    isSimpleExport: boolean
}>;

type Props = {
    disabled: boolean
    disabledMessage: JSX.Element | string
    handleCancel?: () => void
    handleOk: (encoding: Encoding, options?: Options) => void
    loading: boolean
    size?: SizeType
    term?: string
    title?: string
    isUserManagement?: boolean
}

const DownloadCSVConfirm: React.VFC<Props> = ({
    disabled,
    disabledMessage,
    handleCancel = () => { },
    handleOk,
    loading,
    size = 'middle',
    term = '',
    title = 'CSVエクスポート',
    isUserManagement = false,
}) => {
    const isYear = term.endsWith('年度') ? true : false;

    const [encoding, setEncoding] = useState<Encoding>('sjis');
    const [startMonth, setStartMonth] = useState<number>(moment().startOf('month').unix());
    const [endMonth, setEndMonth] = useState<number>(moment().endOf('month').unix());
    const [isSimpleExport, setIsSimpleExport] = useState(false);

    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const clientRect = buttonRef.current?.getBoundingClientRect();
    const x = clientRect?.left ?? 0;
    // PopconfirmTitle の maxWidth - 100px
    // -100px に明確な理由はない
    const isEnoughSpace = x > 820;

    const onConfirm = () => {
        if (typeof handleOk === 'function') {
            if (isYear) {
                handleOk(encoding, { startMonth, endMonth });
            }

            if (isSimpleExport) {
                handleOk(encoding, { isSimpleExport: true });
            }

            if (!isYear && !isSimpleExport) {
                handleOk(encoding, { startMonth: 0, endMonth: 0 });
            }

        }
        //再度開くタイミングではRangePickerの入力値が初期化されているためStateも初期化する
        setStartMonth(moment().startOf('month').unix());
        setEndMonth(moment().endOf('month').unix());
    };

    const onCancel = handleCancel;

    const handleChangeEncodeValue = (e: RadioChangeEvent) => {
        setEncoding(e.target.value);
    };

    const handleChangeRenegePicker = (values: RangeValue<Moment>, dateStrings: [string, string]) => {
        if (!dateStrings) return;

        setStartMonth(moment(dateStrings[0]).startOf('month').unix());
        setEndMonth(moment(dateStrings[1]).endOf('month').unix());
    };

    const handleChangeSimpleMode = (e: RadioChangeEvent) => {
        setIsSimpleExport(e.target.checked);
    };

    const handleClose = (visible: boolean) => {
        if (visible) return;
        setIsSimpleExport(false);
        setEncoding('sjis');
        setStartMonth(moment().startOf('month').unix());
        setEndMonth(moment().endOf('month').unix());
    }

    if (disabled) {
        return (
            <Tooltip title={disabledMessage}>
                <Button
                    disabled={disabled}
                    icon={<ExportOutlined />}
                    loading={loading}
                    size={size}
                    type='primary'
                >
                    {title}
                </Button>
            </Tooltip>
        );
    }

    return (
        <Popconfirm
            autoAdjustOverflow
            cancelText='キャンセル'
            okText='CSVダウンロード'
            onCancel={onCancel}
            onConfirm={onConfirm}
            onVisibleChange={handleClose}
            placement={isEnoughSpace ? 'topRight' : 'top'}
            title={
                <PopconfirmTitle
                    checked={isSimpleExport}
                    encoding={encoding}
                    isUserManagement={isUserManagement}
                    isYear={isYear}
                    onChangeEncoding={handleChangeEncodeValue}
                    onChangeRange={handleChangeRenegePicker}
                    onChangeSimpleMode={handleChangeSimpleMode}
                />
            }
        >
            <Button
                icon={<ExportOutlined />}
                size={size}
                type='primary'
                ref={buttonRef}
            >
                {title}
            </Button>
        </Popconfirm>
    )
};

export default DownloadCSVConfirm;


const rangePickerRangesForMonth: { [range: string]: [moment.Moment, moment.Moment] } = {
    '今月のみ': [
        moment().startOf('month'),
        moment().endOf('month'),
    ],
    '先月のみ': [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
    ],
    '過去２ヶ月': [
        moment().subtract(2, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
    ],
};

type PopconfirmProps = {
    checked: boolean,
    encoding: Encoding,
    isUserManagement?: boolean,
    isYear: boolean,
    onChangeEncoding: (e: RadioChangeEvent) => void,
    onChangeRange: (values: RangeValue<Moment>, dateStrings: [string, string]) => void,
    onChangeSimpleMode?: (e: CheckboxChangeEvent) => void,
};

const PopconfirmTitle: React.VFC<PopconfirmProps> = ({
    checked,
    encoding,
    isUserManagement,
    isYear,
    onChangeEncoding,
    onChangeRange,
    onChangeSimpleMode,
}) => {
    return (
        <div style={{maxWidth: '920px', width: '80vw'}}>
            <p>
                CSVをファイルダウンロードします。<br />
                対象件数により、CSVのダウンロードには時間がかかることがあります。
                ダウンロードの進捗確認とダウンロードは画面左下をご利用下さい。<br />
                同一ブラウザであれば、ブラウザを閉じても前回の結果をダウンロードすることが可能です。<br />
            </p>
            <p>
                CSVファイルの文字エンコードを指定してください。Excelで表示・編集するには「Shift-JIS形式」を選択してください。<br />
                Shift-JIS形式でダウンロードした場合は、UTF-8形式で表現される一部の文字がうまく表示されないことがあります。
            </p>
            <p>
                数字のゼロやハイフンなどの記号の欠落にご注意ください。
            </p>

            <div style={{ margin: '1.5em 0px' }}>
                <RadioGroup defaultValue={encoding} onChange={onChangeEncoding}>
                    <Radio value='sjis'>ShiftJIS形式</Radio>
                    <Radio value='utf8'>UTF-8形式</Radio>
                </RadioGroup>
            </div>

            {isUserManagement && onChangeSimpleMode && (
                <Checkbox
                    defaultChecked={checked}
                    onChange={onChangeSimpleMode}
                >
                    UUID を出力する(この機能を用いて出力したファイルは、インポートにはご利用出来ません)
                </Checkbox>
            )}

            {isYear &&
                <div style={{ margin: '1.5em 0px' }}>
                    <p>
                        出力する期間を選択してください。期間を長くした場合、エクスポートされるまでに数時間かかる場合があります。
                    </p>
                    <RangePicker
                        locale={jaJP}
                        defaultValue={[moment(), moment()]}
                        defaultPickerValue={[moment().subtract(1, 'y'), moment().subtract(1, 'y')]}
                        format={'YYYY/MM'}
                        picker={'month'}
                        placeholder={['開始月', '終了月']}
                        onChange={onChangeRange}
                        ranges={rangePickerRangesForMonth}
                    />
                </div>
            }
        </div>
    );
}
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

export type StatisticsExamCategoryState = {
    isLoading: boolean
    result: {
        [examCategoryUuid: string]: {
            [studentUuid: string]: {
                answeredExamCount: number
                examCount: number
                totalMaxScore: number | undefined
                totalScore: number | undefined
            }
        }
    } | Record<string, never>
    studentUuid: string | undefined
    examCategoryUuid: string | undefined
};

export type RequestStatisticsExamCategory = ReturnType<typeof useStatisticsExamCategory>[1];

export const useStatisticsExamCategory = () => {
    const [state, setState] = useState<StatisticsExamCategoryState>({
        examCategoryUuid: undefined,
        isLoading: false,
        result: {},
        studentUuid: undefined,
    });

    const taoExamCategoryControlUserStatisticsConnection = useAppSelector(state => state.taoExamCategoryControlUserStatisticsConnection);
    const taoExamControlCategoryUserStatisticsConnection = useAppSelector(state => state.taoExamControlCategoryUserStatisticsConnection);

    const dispatch = useDispatch();

    const effect = (connection: TaoExamCategoryControlUserStatisticsConnection | TaoExamControlCategoryUserStatisticsConnection) => {
        const { meta, payload: { result } } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;
        if (meta.status !== Actions.statusEnum.SUCCESS) return;

        const examCategoryUuid = state.examCategoryUuid ?? '';
        const studentUuid = state.studentUuid ?? '';

        const results = {
            ...(state.result[examCategoryUuid] ?? {}),
            [studentUuid]: {
                answeredExamCount: result.answered_exam_count,
                examCount: result.exam_count,
                totalMaxScore: result.total_max_score ?? undefined,
                totalScore: result.total_score ?? undefined,
            },
        };

        setState(state => ({
            ...state,
            isLoading: false,
            result: {
                ...state.result,
                [examCategoryUuid]: results,
            },
        }));
    };

    useEffect(
        () => effect(taoExamCategoryControlUserStatisticsConnection),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamCategoryControlUserStatisticsConnection]
    );

    useEffect(
        () => effect(taoExamControlCategoryUserStatisticsConnection),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlCategoryUserStatisticsConnection]
    );

    const request = useCallback((
        examCategoryUuid: string,
        studentUuid: string,
        latestResultOnly: boolean,
        // あくまで学校管理者が操作する場合のみ必要なので、それ以外では使われない
        requestType?: 'control' | 'default'
    ) => {
        setState({
            examCategoryUuid,
            isLoading: true,
            result: {},
            studentUuid,
        });

        const userStatisticsAction = requestType === 'control' ?
            Actions.http.connection.tao.examControlCategory.controlUserStatistics :
            Actions.http.connection.tao.examControlCategory.userStatistics;

        const data = { latest_result_only: latestResultOnly ? '1' as const : '0' as const };
        dispatch(userStatisticsAction(examCategoryUuid, studentUuid, data, ''));
    }, [dispatch]);

    const resetState = useCallback(() => {
        setState({
            examCategoryUuid: undefined,
            isLoading: false,
            result: {},
            studentUuid: undefined,
        });
    }, []);

    return [state, request, resetState] as const;
};

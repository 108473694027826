import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { UserExamState } from './useUserExam';

type Exam = NonNullable<UserExamState['result']>['exams'][number];
type UseKeysInTestView = 'title' | 'resultUrl' | 'maxAttempts'| 'endAt' | 'startAt' | 'uuid';

type StatisticsExamStateResult = {
    statistics: Exam.Statistics | undefined
    exam: Expand<Pick<Exam, UseKeysInTestView>> | undefined
    latestResultOnly: boolean
}

export type StatisticsExamState = {
    isLoading: boolean
    result: Record<string, StatisticsExamStateResult>
};

export type RequestStatisticsExam = ReturnType<typeof useStatisticsExam>[1];

export const useStatisticsExam = () => {
    const [state, setState] = useState<StatisticsExamState>({
        isLoading: false,
        result: {},
    });

    const taoExamControlStatisticsConnection = useAppSelector(state => state.taoExamControlStatisticsConnection);
    const taoExamStatisticsConnection = useAppSelector(state => state.taoExamStatisticsConnection);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta, payload: { result } } = taoExamControlStatisticsConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const uuid = meta.uuid;
                const statistics = {
                    averageScore: result.average_score ?? undefined,
                    highestScore: result.highest_score ?? undefined,
                    lowestScore: result.lowest_score ?? undefined,
                    maxScore: result.max_score,
                    resultCount: result.result_count,
                    resultHistoryCount: result.result_history_count ?? undefined,
                    targetUserCount: result.target_user_count,
                };

                setState(state => {
                    const newState = { ...state, isLoading: false };

                    newState.result[uuid] = {
                        ...newState.result[uuid],
                        exam: newState.result[uuid]?.exam,
                        statistics,
                    };

                    return newState;
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: {},
                }));
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamControlStatisticsConnection]);

    useEffect(() => {
        const { meta, payload: { result } } = taoExamStatisticsConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const uuid = meta.uuid;
                const statistics = {
                    averageScore: result.average_score ?? undefined,
                    highestScore: result.highest_score ?? undefined,
                    lowestScore: result.lowest_score ?? undefined,
                    maxScore: result.max_score,
                    resultCount: result.result_count,
                    resultHistoryCount: result.result_history_count ?? undefined,
                    targetUserCount: result.target_user_count,
                };

                setState(state => {
                    const newState = { ...state, isLoading: false };

                    newState.result[uuid] = {
                        ...newState.result[uuid],
                        exam: newState.result[uuid]?.exam,
                        statistics,
                    };

                    return newState;
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: {},
                }));
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamStatisticsConnection]);

    // コード中では latest_result_only を boolean で使えるようにする
    type Parameter = Expand<
        Omit<Http.Connection.Request.Parameter.Exam.Statistics, 'latest_result_only'> & {
            latest_result_only?: boolean
        }
    >

    const request = useCallback((
        // exam が必要ない場合があるので、そういった場合は uuid のみで通信できるようにする。
        // 第一引数を uuid にして、別の引数で exam を任意で渡す形式のほうがいいかもしれない。
        test: NonNullable<StatisticsExamStateResult['exam']> | string,
        data: Parameter,
        // あくまで学校管理者が操作する場合のみ必要なので、それ以外では使われない
        requestType?: 'control' | 'default'
    ) => {
        const uuid = typeof test === 'string' ? test : test.uuid;
        const latestResultOnly = data.latest_result_only ?? false;

        setState(state => {
            const newState = {
                ...state,
                isLoading: true,
            };

            newState.result = {
                [uuid]: {
                    exam: typeof test === 'string' ? undefined : test,
                    latestResultOnly: latestResultOnly,
                    statistics: undefined,
                },
            };

            return newState;
        });

        const resultAction = requestType === 'control' ?
            Actions.http.connection.tao.exam.controlStatistics :
            Actions.http.connection.tao.exam.statistics;

        const resultData = {
            ...data,
            latest_result_only: latestResultOnly ? '1' as const : '0' as const,
        };

        dispatch(resultAction(uuid, resultData, ''));
    }, [dispatch]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
            result: {},
        });
    }, []);

    return [state, request, resetState] as const;
};
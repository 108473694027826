import React, { useState, useEffect, createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import BaseModal from 'components/modules/BaseModal';
import { Summary } from './Summary';
import { StudentList } from './StudentList';
import { OrganizationList } from './OrganizationList';
import { ResultView } from './ResultView';
import { useAppCategoryResult } from '../utils/useAppCategoryResult';
import { useAppCategoryControlResult } from '../utils/useAppCategoryControlResult';

type Props = {
    isOnlyAgsApplication: boolean
    onCancel: () => void
    category: App.AppsCategory
    visible: boolean
};

type GroupStatusSearch = Http.Connection.Request.Parameter.App.GroupStatusSearch;
type FormValuesKeys = keyof Required<Omit<Http.Connection.Request.Parameter.App.GroupStatusSearch, 'only_ags_application'>>;
type FormValues = Record<FormValuesKeys, string | undefined>;

export const defaultBreadCrumbStatus = {
    name: '',
    uuid: '',
    type: 'List' as const,
};

export const BreadCrumbContext = createContext({} as {
    breadcrumb: AppStatusModal.Breadcrumb,
    setBreadcrumb: React.Dispatch<React.SetStateAction<AppStatusModal.Breadcrumb>>
});

export const defaultSearchState: FormValues = {
    role_uuid: undefined,
    school_class_uuid: undefined,
    term_uuid: undefined,
};

export const SearchContext = createContext({} as {
    search: FormValues,
    setSearch: React.Dispatch<React.SetStateAction<FormValues>>
});

export const AppGroupStatus: React.VFC<Props> = ({
    isOnlyAgsApplication,
    onCancel,
    category,
    visible,
}) => {
    const dispatch = useDispatch();
    const [categoryResult, categoryResultRequest, resetCategoryResult] = useAppCategoryResult();
    const [categoryControlResult, categoryResultControlRequest,] = useAppCategoryControlResult();
    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const [breadcrumb, setBreadcrumb] = useState<AppStatusModal.Breadcrumb>(defaultBreadCrumbStatus);
    const [search, setSearch] = useState<FormValues>(defaultSearchState);
    const handleStudentListSearch = useCallback((uuid: string, values: GroupStatusSearch, page: number) => {
        const newValues = {
            only_ags_application: isOnlyAgsApplication
                ? 1 as const
                : 0 as const,
            ...values
        };

        categoryResultRequest(uuid, newValues, page);
    }, [isOnlyAgsApplication, categoryResultRequest]);
    const handleCloseModal = useCallback(() => {
        setBreadcrumb(defaultBreadCrumbStatus);
        onCancel();
    }, [onCancel]);

    const ListComponent = isControllableUser
        ? <OrganizationList />
        : <StudentList
            category={category}
            categoryResult={categoryResult}
            onSearch={handleStudentListSearch}
            resetCategoryResult={resetCategoryResult}
            visible={visible}
        />;

    useEffect(() => {
        if (!visible) return;

        if (isControllableUser) {
            categoryResultControlRequest(
                category.uuid,
                {},
            );
        }
        // admin の場合は StudentList 内で検索する

        // 表示・非表示のみをトリガーとしたいため
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, visible]);

    return (
        <BaseModal
            className='common-modal test-status-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined />{`教材・アプリ配信対象者と実施状況の確認`}</span>}
            visible={visible}
            onCancel={handleCloseModal}
            footer={[
                <Button key='back' size='large' onClick={handleCloseModal}>キャンセル</Button>
            ]}
            forceRender
        >
            <Summary
                loading={false}
                isControllableUser={isControllableUser}
                category={category}
                count={isControllableUser
                    ? categoryControlResult.organizationList.length
                    : categoryResult.targetUsers
                }
                targetUsersCount={isControllableUser
                    ? categoryControlResult.targetUsers
                    : categoryResult.targetUsers
                }
            />
            <div className='wrapper'>
                <SearchContext.Provider value={{ search, setSearch }}>
                    <BreadCrumbContext.Provider value={{ breadcrumb, setBreadcrumb }}>
                        {(breadcrumb.type === 'List' && visible) &&
                            ListComponent
                        }
                        {breadcrumb.type !== 'List' &&
                            <ResultView
                                category={category}
                                onSearch={handleStudentListSearch}
                            />
                        }
                    </BreadCrumbContext.Provider>
                </SearchContext.Provider>
            </div>
        </BaseModal>
    );
};

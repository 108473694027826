import {
    useEffect,
    useRef
} from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as Actions from 'flex/Actions';
import { BaseTable } from 'components/modules/BaseTable';
import 'styles/modules/test_status_modal.scss';
import {
    generateMngDomain,
    openWindowWithReferer,
} from 'constants/GlobalConfig';
import { useAppCategoryControlResult } from '../utils/useAppCategoryControlResult';

type DataSource = App.AppStatus.OrganizationListItem;

export const OrganizationList: React.VFC = () => {
    const [categoryControlResult, ,] = useAppCategoryControlResult();
    const dispatch = useDispatch();
    const isFirstRender = useRef(false);

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    const handleAgencyLoginClick = (organizationUuid: string, applicationCategoryUuid: string) => {
        dispatch(Actions.http.connection.organization.agencyLogin(organizationUuid));
        openWindowWithReferer(
            `${generateMngDomain()}/apps?ag=${applicationCategoryUuid}`,
            '_blank'
        );
    };

    const columns: ColumnsType<DataSource> = [
        {
            title: '配信対象校',
            key: 'target',
            children: [
                {
                    title: '学校名',
                    width: 200,
                    fixed: 'left',
                    key: 'name',
                    render: (_, record) => {
                        return (
                            <Tooltip title={<span>{record.organization.name}へサインインして結果を確認する</span>}>
                                <button
                                    className='text-button'
                                    onClick={() => {
                                        handleAgencyLoginClick(record.organization.uuid, record.applicationCategory.uuid);
                                    }}
                                >
                                    {record.organization.name}
                                </button>
                            </Tooltip>
                        );
                    },
                },
            ]
        },
        {
            title: '学習結果',
            key: 'result',
            align: 'center',
            width: 250 * categoryControlResult.categoryApplicationCount,
            children: categoryControlResult.organizationList[0]?.applications.map((application, index) => ({
                key: `${index}`,
                title: application.title,
                width: 200,
                render: () => {
                    if (index === 0) {
                        return '各学校のデータは代理ログインで確認してください';
                    } else {
                        return undefined;
                    }
                },
                onCell: () => ({ colSpan: index === 0 ? categoryControlResult.categoryApplicationCount : 0 })
            }))
        },
    ];

    const calculateScrollWidth = () => {
        return 550 + (100 * categoryControlResult.categoryApplicationCount);
    };

    const dataSource = categoryControlResult.organizationList;

    return (
        <BaseTable
            loading={categoryControlResult.isLoading}
            columns={columns}
            dataSource={dataSource}
            emptyDescription='対象の学習結果が存在しません'
            size='middle'
            rowKey={record => record.organization.name}
            pagination={{
                ...categoryControlResult.pagination,
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                onChange: (page, page_size) => {
                    dispatch(Actions.view.viewLogic.examCategoryTab.submitPageStatusList(page, page_size || 50));
                }
            }}
            scroll={{ x: calculateScrollWidth() }}
            className='all-white-table'
        />
    );
};
import { Badge, Col, Row } from 'antd';
import {
    DeleteOutlined,
    FileAddOutlined,
    SolutionOutlined,
} from '@ant-design/icons';
import { getMoment } from 'constants/GlobalConfig';
import {
    EditOrInfoIcon,
    PublishedItemTitleHint,
    createPublishedItemButtonTooltipTitle,
    createPublishedItemEditOrInfoButtonTooltipTitle,
} from '../../../../modules/PublishedItems';
import CircleButton from '../../../../modules/CircleButton';
const moment = getMoment();


export const createColumns = (
    hasTouchScreen = false,
    handleEditClick,
    handleCopyClick,
    handleShowStatusClick,
    handleDeleteClick,
) => {
    return [
        {
            title: 'タイトル',
            width: 30,
            dataIndex: 'title',
            key: 'title',
            render: (title, record) => (
                <span>{title} <PublishedItemTitleHint record={record} /></span>
            )
        },
        {
            title: '優先順位',
            width: 10,
            dataIndex: 'priority',
            key: 'priority',
            render: (priority) => {
                switch (priority) {
                    case 1: {
                        return <Badge color='green' text='通常' />;;
                    }
                    case 2: {
                        return <Badge color='volcano' text='大切なお知らせ' />;;
                    }
                    default: {
                        return <Badge status='error' text='無効' />;;
                    }
                }
            }
        },
        {
            title: '発信元',
            width: 10,
            dataIndex: 'publish_from',
            key: 'publish_from',
        },
        {
            title: '作成者/更新者',
            width: 12,
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                return (
                    <>
                        <span>{record.created_by.display_name}</span>
                        <br />
                        <span>{record.updated_by.display_name}</span>
                    </>
                )
            }
        },
        {
            title: '状態',
            width: 8,
            dataIndex: 'status_period',
            key: 'status_period',
            render: (status_period) => {
                switch (status_period) {
                    case 'disable': {
                        return <Badge status='warning' text='無効' />;
                    }
                    case 'public': {
                        return <Badge status='processing' text='公開中' />;
                    }
                    case 'public_before': {
                        return <Badge status='success' text='公開前' />;
                    }
                    case 'public_after': {
                        return <Badge status='default' text='公開終了' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            }
        },
        {
            title: '公開期間',
            width: 12,
            key: 'duration',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.publish_start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(record.publish_end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            )
        },
        {
            title: '作成時間/更新時間',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 11,
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 8,
            fixed: 'right',
            render: (_, record) => (
                <Row type='flex' justify='space-around' align='middle'>
                    <Col>
                        <CircleButton size='small'
                            className='mr-2'
                            icon={<EditOrInfoIcon record={record} shouldCheckCanAdminSetPublic />}
                            tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemEditOrInfoButtonTooltipTitle(record, true, '編集') }}
                            onClick={() => handleEditClick(record)}
                        />
                    </Col>
                    <Col>
                        <CircleButton size='small'
                            className='mr-2'
                            disabled={record.is_published_item}
                            icon={<FileAddOutlined />}
                            tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record, 'コピーして作成') }}
                            onClick={() => handleCopyClick(record)}
                        />
                    </Col>
                    <Col>
                        <CircleButton size='small'
                            className='mr-2'
                            icon={<SolutionOutlined />}
                            onClick={() => handleShowStatusClick(record.uuid)}
                            tooltipProps={{ title: hasTouchScreen ? undefined : '公開対象者を確認' }}
                        />
                    </Col>
                    <Col>
                        <CircleButton size='small' danger
                            className='mr-2'
                            disabled={record.is_published_item}
                            icon={<DeleteOutlined />}
                            tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record) }}
                            popconfirmProps={{
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>お知らせを削除すると配信は停止されます。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                ),
                            }}
                        />
                    </Col>
                </Row>
            ),
        },
    ];
};
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Ruby from 'components/modules/Student/Ruby';
import QuestionItemCard from './QuestionItemCard';
import { StarMarkCandidate } from './StarMarkCandidate';

export const StarMarkSelection = props => {
    const {
        content = '',
        detail = '',
        file = [],
        isRequired = false,
        name = '',
        disabled = false,
        evaluationScaleMax = 5,
    } = props;
    const { errors, register, unregister, watch, setValue } = useFormContext();

    useEffect(() => {
        const validate = {};
        if (isRequired) {
            validate.required = true;
        }
        register(name, validate);
        return () => unregister(name);
    }, [register, unregister, name, isRequired]);

    let errorMessage = '';
    if (errors[name]?.message !== undefined) {
        switch (errors[name].type) {
            case 'required':
                errorMessage = <Ruby>{'{回答|かいとう}が{必要|ひつよう}です。'}</Ruby>;
                break;
            default:
                errorMessage = <div>{errors[name].message}</div>;
                break;
        }
    }

    return (
        <QuestionItemCard
            title={content}
            detail={detail}
            error={errorMessage}
            file={file}
            type={<Ruby>{'{単一回答|たんいつかいとう}'}</Ruby>}
            isRequired={isRequired}
            watch={watch}
        >
            <div className='grid grid-cols-2 gap-4'>
                <StarMarkCandidate
                    ref={register}
                    watch={watch}
                    name={name}
                    disabled={disabled}
                    evaluationScaleMax={evaluationScaleMax}
                />
            </div>

        </QuestionItemCard>
    );
};

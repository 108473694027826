import { Tabs } from 'antd';
import QuestionnaireList from './QuestionnaireTab/QuestionnaireList';
import QuestionnaireCategoryList from './QuestionnaireCategoryTab/QuestionnaireCategoryList';
import {
    useTabManagement,
} from 'constants/CustomHooks/useTabManagement';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
const { TabPane } = Tabs;


export const TAB_KEYS = {
    QUESTIONNAIRE: 'questionnaires',
    CATEGORY: 'category',
};

const Questionnaire = () => {
    const [tabActiveKey, setTabActiveKey] = useTabManagement(TAB_KEYS, TAB_KEYS.QUESTIONNAIRE);
    const { hasTouchScreen } = useHasTouchScreen(); 

    return (
        <div className='page-questionnaire card-container'>
            <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey} >
                <TabPane tab='アンケート一覧' key={TAB_KEYS.QUESTIONNAIRE}>
                    <QuestionnaireList hasTouchScreen={hasTouchScreen} />
                </TabPane>
                <TabPane tab='アンケートカテゴリ一覧' key={TAB_KEYS.CATEGORY}>
                    <QuestionnaireCategoryList hasTouchScreen={hasTouchScreen} />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Questionnaire;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type ChangeExamCategoryOrderState = {
    isLoading: boolean
};

export const useChangeExamCategoryOrder = (
    // ここでは引数は使わないが、他でここの実装を真似する際のためにそれっぽくしておく
    successCallback: (meta: unknown, payload: unknown, isControl: boolean) => void,
    failureCallback: (meta: unknown, payload: unknown, isControl: boolean) => void,
) => {
    const [state, setState] = useState<ChangeExamCategoryOrderState>({
        isLoading: false,
    });

    const taoExamCategoryControlChangeCategoryOrderConnection = useAppSelector(
        state => state.taoExamCategoryControlChangeCategoryOrderConnection
    );

    const dispatch = useDispatch();


    useEffect(() => {
        const { meta, payload } = taoExamCategoryControlChangeCategoryOrderConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlChangeCategoryOrderConnection]);

    const request = useCallback((
        uuids: string[],
    ) => {
        const changeOrderAction = Actions.http.connection.tao.examControlCategory.controlChangeCategoryOrder;

        dispatch(changeOrderAction({ uuids }));
        setState(state => ({
            ...state,
            isLoading: true,
        }));

    }, [dispatch]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
        });
    }, []);

    return [state, request, reset] as const;
};

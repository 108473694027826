import * as Actions from "../../Actions";
import {getCurrentTerm} from "../../../constants/GlobalConfig";


// viewNameを持つ通信処理は、viewNameごとの領域に、保存する。
export const viewNameInSavingData = (store, next, action) =>{
	const currentState = store.getState()[action.meta.viewName]
	switch (action.type) {
		// アプリテンプレート(全体管理者の作成)
		case Actions.http.connection.apps.template.searchForAdmin().type:
			next({
				type: action.meta.viewName,
				...currentState,
				pagination: currentState["pagination"] && { // ページネーションの設定が可能であれば、ここで更新する
					...currentState["pagination"],
					total: action.payload.result.item_count
				},
				appTemplateForAdminList: action.payload.result.items,
				appTemplateForAdminListItemCount: action.payload.result.item_count
			})
			break;
		// アプリテンプレート(システム管理者の作成)
		case Actions.http.connection.apps.template.search().type:
			next({
				type: action.meta.viewName,
				...currentState,
				appTemplateList: action.payload.result.items,
				appTemplateListItemCount: action.payload.result.item_count
			})
			break;
		// 組織
		case Actions.http.connection.organization.search().type:
			next({
				type: action.meta.viewName,
				...currentState,
				organizationList: action.payload.result.items,
				organizationListItemCount: action.payload.result.item_count
			})
			break;
		// 年度
		case Actions.http.connection.term.search().type:
			next({
				type: action.meta.viewName,
				...currentState,
				termList: action.payload.result.items,
				termListItemCount: action.payload.result.item_count,
				currentTerm: getCurrentTerm(action.payload.result.items).uuid,
				search: action.meta.data,
			})
			break;
		// ユーザー
		case Actions.http.connection.users.search().type:
			const users =({
				type: action.meta.viewName,
				...currentState,
				usersList: action.payload.result.items,
				usersListItemCount: action.payload.result.item_count,
				pagination: currentState["pagination"] && { // ページネーションの設定が可能であれば、ここで更新する
					...currentState["pagination"],
					total: action.payload.result.item_count
				},
			})
			next(users)
			break;
		// クラス
		case Actions.http.connection.classes.search().type:
			next({
				type: action.meta.viewName,
				...currentState,
				classList: action.payload.result.items,
				classListItemCount: action.payload.result.item_count
			})
			break;
		// mapKey付きクラス
		case Actions.http.connection.classes.searchForListMap().type:
			const targetUuid = action.meta.data.term_uuid
			const lastClassListMap = currentState["classListMap"]
			const lastClassListMapItemCount = currentState["classListMapItemCount"]
			const newClassListMap = {...lastClassListMap, [targetUuid]: action.payload.result.items}
			const newClassListMapItemCount = {...lastClassListMapItemCount, [targetUuid]: action.payload.result.item_count};
			const newClassListMapListCount = Object.keys(newClassListMap).length;

			next({
				type: action.meta.viewName,
				...currentState,
				selectTerm: targetUuid,
				classListMap: newClassListMap,
				classListMapItemCount: newClassListMapItemCount,
				classListMapListCount: newClassListMapListCount
			})
			break;
		// ロール
		case Actions.http.connection.role.search().type:
			const newState = {
				type: action.meta.viewName,
				...currentState,
				roleList: action.payload.result.items,
				roleListItemCount: action.payload.result.item_count
			}

			next(newState)
			break;
		default:
			break;
	}
}

import { useState, useEffect } from 'react';
import {
    Button,
    Select,
    Input,
    Radio,
    Row, Col,
} from 'antd';
import {
    NotificationOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { formRules, getDefaultLayout } from 'constants/GlobalConfig';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'flex/Actions';
import { editQuestionLoadingSelector } from 'flex/view/Questionnaire/EditQuestion/selectors';
import { allowedExtensions, FileUploader } from 'components/modules/FileUploader';
import BaseModal from 'components/modules/BaseModal';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;

const { Option } = Select;


const layout = getDefaultLayout();

const initialFieldValue = {
    content: '',
    detail: '',
    is_required: true,
    type: 1,
    files: [],
    answer_candidates: [
        { content: '' }, { content: '' },
    ],
};

const EditQuestionModal = props => {
    const [form] = Form.useForm();
    const [type, setType] = useState(1);
    const {
        parentTmpItemId,
        parentTmpCandidateId,
        items,
        visible,
        question,
        questionsCount,
        shouldCreate,
        onOk,
        onCancel,
    } = props;
    const selectParentCandidateOptions = useSelector(state => state.editQuestion.selectParentCandidateOptions);
    const selectParent = useSelector(state => state.editQuestion.selectParent);
    const loading = useSelector(editQuestionLoadingSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (visible) {
            form.resetFields();
            setType(initialFieldValue.type);
            dispatch(Actions.view.viewLogic.editQuestion.prepareView());

            if (shouldCreate) {
                form.setFieldsValue({
                    ...initialFieldValue,
                    parentTmpItemId, parentTmpCandidateId,
                    content: `${questionsCount + 1}. `,
                });
            } else {
                setType(question.type);
                form.setFieldsValue({
                    ...question,
                    file: question.file ? [question.file] : [],
                    answer_candidates: question.answer_candidates &&
                        question.answer_candidates.length > 0 &&
                        question.answer_candidates.map((answer) => ({ content: answer.content })),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    function handleParentItemChange(value) {
        form.setFieldsValue({ parentTmpItemId: value });
        if (selectParent !== value) {
            form.resetFields(['parentTmpCandidateId']);
        }
        dispatch(Actions.view.viewLogic.editQuestion.selectParent(value));
    }

    function handleTypeChange(e) {
        const newType = e.target.value;
        const candidates = form.getFieldValue('answer_candidates');
        setType(newType);
        const answerCandidates = (newType === 1 || newType === 2) && candidates ?
            candidates :
            [{ content: '' }, { content: '' }];

        form.setFieldsValue({ type: newType, answer_candidates: answerCandidates });
    }

    function handleAddAnswer(add) {
        add();
    }

    function handleRemoveAnswer(remove, index) {
        remove(index);
        //setAnswers(answers.filter((_, i) => i !== index))
    }
    const handleSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        const submitValue = { ...question, ...values, file: values.file[0] };
        onOk(submitValue);
    };


    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined /> {(shouldCreate) ? ' 設問の新規作成' : '設問の編集'}</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button
                    key='back'
                    size='large'
                    loading={loading}
                    disabled={loading}
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='save'
                    type='primary'
                    size='large'
                    loading={loading}
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
        >
            <Form {...layout} form={form} name='control-hooks-edit-questions' onFinish={onFinish}>
                {parentTmpItemId > 0 && (
                    <>
                        <Form.Item
                            name='parentTmpItemId'
                            label='親設問'
                            rules={[{ required: true, message: '親設問を選択してください' }]}
                        >
                            <Select
                                placeholder='親設問を選択してください'
                                onChange={handleParentItemChange}
                                defaultValue={parentTmpItemId}
                                allowClear
                            >
                                {items.filter(({ type }) => (type === 1)).map(item => (
                                    <Option key={item.tmpItemId} value={item.tmpItemId}>{item.content}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='parentTmpCandidateId'
                            label='親選択肢'
                            rules={[{ required: true, message: '親選択肢を選択してください' }]}
                        >
                            <Select
                                placeholder='親選択肢を選択してください'
                                //onChange={handleParentCandidateChange}
                                allowClear
                            >
                                {(selectParentCandidateOptions.length !== 0) ?
                                    selectParentCandidateOptions.map(choice => (
                                        <Option key={choice.tmpCandidateId} value={choice.tmpCandidateId}>
                                            {choice.content}
                                        </Option>
                                    )) :
                                    null}
                            </Select>
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name='content'
                    label='設問'
                    rules={[
                        formRules.required({ label: '設問' }),
                        formRules.range({ max: 128 }),
                    ]}
                >
                    <Input placeholder='設問を入力してください' />
                </Form.Item>

                <Form.Item
                    name='detail'
                    label='設問詳細'
                    rules={[
                        formRules.required({ label: '設問詳細' }),
                        formRules.range({ max: 10000 }),
                    ]}
                >
                    <Input placeholder='設問詳細を入力してください' />
                </Form.Item>

                <Form.Item name='file' label='ファイル'>
                    <FileUploader
                        allowedExtension={allowedExtensions.image_movie_zip_doc}
                    />
                </Form.Item>

                <Form.Item name='is_required' label='必須回答'>
                    <Radio.Group initialValues={true}>
                        <Radio value={true}>必須</Radio>
                        <Radio value={false}>任意</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name='type' label='回答形式'>
                    <Radio.Group initialValues={1} value={type} onChange={handleTypeChange}>
                        <Radio value={1}>単一選択式</Radio>
                        <Radio value={2}>複数選択式</Radio>
                        <Radio value={3}>自由記述式</Radio>
                        <Radio value={4}>ファイル添付</Radio>
                    </Radio.Group>
                </Form.Item>

                {(type === 1 || type === 2) && (
                    <Form.List name='answer_candidates'>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        key={field.key}
                                        label={`選択肢 ${index + 1}`}
                                        required
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Row gutter={[16, 12]} >
                                            <Col span={22}>
                                                <Form.Item
                                                    key={index}
                                                    name={[field.name, 'content']}
                                                    rules={[
                                                        formRules.required({ label: `選択肢${index + 1}の名称` }),
                                                        formRules.range({ max: 255 }),
                                                    ]}
                                                >
                                                    <Input placeholder={`選択肢${index + 1}の名称を入力してください`} />
                                                </Form.Item>
                                            </Col>
                                            {(index > 1) && (
                                                <Col span={2}>
                                                    <MinusCircleOutlined
                                                        className='dynamic-delete-button'
                                                        style={{
                                                            margin: '0 8px',
                                                            cursor: 'pointer',
                                                            position: 'relative',
                                                            top: 4,
                                                            fontSize: 24,
                                                            transition: 'all 0.3s',
                                                        }}
                                                        onClick={() => { handleRemoveAnswer(remove, index); }}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </Form.Item>
                                ))}

                                <Form.Item wrapperCol={{ lg: { span: 24, offset: 0 }, xl: { span: 20, offset: 4 } }}>
                                    <Button
                                        type='dashed'
                                        style={{ width: '100%' }}
                                        onClick={() => handleAddAnswer(add)}
                                    >
                                        <PlusOutlined />
                                        選択肢の追加
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                )}
            </Form>
        </BaseModal>
    );
};

export default EditQuestionModal;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    EditOutlined,
    DeleteOutlined,
    PlusCircleFilled,
} from '@ant-design/icons';
import {
    Button,
    Badge,
    Tooltip,
    Tag,
    Row,
    Col,
} from 'antd';

import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import {
    convertAppTemplateAuthTypeToString,
    getMoment,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { statusEnum } from 'flex/Actions';
import { appTemplateTabSelectors } from 'flex/view/AppTemplate/AppTemplateTab/selectors';

import AppTemplateSearch from './AppTemplateSearch';
import EditAppTemplate from './EditAppTemplate';
import 'styles/pages/app-template.scss';

const moment = getMoment();


const AppTemplate = () => {
    const appsTemplateForAdminEditConnection = useSelector(state => state.appsTemplateForAdminEditConnection);
    const appsTemplateViewForAdminConnection = useSelector(state => state.appsTemplateViewForAdminConnection);
    const loading = useSelector(appTemplateTabSelectors.loading);
    const tabSelector = useSelector(state => state.appTemplateTab);
    const dispatch = useDispatch();
    const [editVisible, setEditVisible] = useState(false);
    const [record, setRecord] = useState(null);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const isFirstRender = useRef(true);
    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();

    useEffect(() => {
        dispatch(Actions.view.viewLogic.appTemplateTab.prepareView());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFirstRender.current) return;
        if (appsTemplateForAdminEditConnection.meta.status === statusEnum.SUCCESS) {
            dispatch(Actions.view.viewLogic.appTemplateTab.submitEdit());
        }
    }, [appsTemplateForAdminEditConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFirstRender.current) return;

        const { meta, payload } = appsTemplateViewForAdminConnection;

        setIsLoadingModal(meta.fetch);
        if (meta.status === statusEnum.SUCCESS) {
            setRecord(payload.result);
        }
    }, [appsTemplateViewForAdminConnection]);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    const handleOpenEdit = (selectedRecord) => {
        dispatch(Actions.http.connection.apps.template.viewForAdmin(selectedRecord.uuid));
        setEditVisible(true);
    };
    const handleOpenCreate = () => {
        setEditVisible(true);
    };

    const handleCloseEdit = () => {
        setRecord(null);
        setEditVisible(false);
    };

    const handleDelete = (uuid) => {
        dispatch(Actions.http.connection.apps.template.deleteForAdmin(uuid));
    };

    const columns = [
        {
            dataIndex: 'type',
            key: 'type',
            render: (type) => (
                <Tag className='tag blue-gray'>
                    {convertAppTemplateAuthTypeToString(type)}
                </Tag>
            ),
            title: '認証方式',
            width: '20%',
        },
        {
            dataIndex: 'name',
            key: 'name',
            render: (name) => name,
            title: 'アプリ名称',
            width: '40%',
        },
        {
            dataIndex: 'is_active',
            key: 'is_active',
            render: (is_active) => {
                switch (is_active) {
                    case true: {
                        return <Badge color='green' text='有効' />;
                    }
                    case false: {
                        return <Badge color='volcano' text='無効' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            },
            title: '状態',
            width: '10%',
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: '20%',
        },
        {
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Row align='middle' justify='space-around'>
                    <Col>
                        <Tooltip title={ hasTouchScreen ? undefined : 'アプリ配信の編集'}>
                            <Button
                                className='mr-2'
                                icon={<EditOutlined />}
                                onClick={() => handleOpenEdit(record)}
                                shape='circle'
                                size='small'
                            />
                        </Tooltip>
                    </Col>

                    <Col>
                        <CircleButton
                            className='mr-2'
                            danger
                            icon={<DeleteOutlined />}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                okText: '削除',
                                onConfirm: () => handleDelete(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <span>
                                        <strong>配信アプリの削除</strong><br />
                                        配信アプリを削除します。よろしいでしょうか？
                                    </span>
                                ),
                            }}
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '削除' }}
                        />
                    </Col>
                </Row>
            ),
            title: '',
            width: '10%',
        },
    ];

    return (
        <div className='page-app-template container'>
            <AppTemplateSearch />

            <div className='flex-right-container gutter-bottom'>
                <Button
                    disabled={loading}
                    icon={<PlusCircleFilled />}
                    loading={loading}
                    onClick={() => handleOpenCreate()}
                    type='primary'
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                columns={columns}
                dataSource={tabSelector.appTemplateForAdminList || []}
                emptyDescription='条件に一致するアプリ配信はありませんでした'
                loading={loading}
                pagination={{
                    ...tabSelector.pagination,
                    onChange: (page, page_size) => {
                        dispatch(Actions.view.viewLogic.appTemplateTab.submitPage(page, page_size));
                    },
                    showTotal: (total, range) => { return (`全${total}件中 ${range[0]}-${range[1]} 件`); },
                }}
            />

            <EditAppTemplate
                isLoading={isLoadingModal}
                onCancel={handleCloseEdit}
                onOk={handleCloseEdit}
                record={record}
                visible={editVisible}
            />
        </div>
    );
};

export default AppTemplate;

import {
    Breadcrumb,
    Typography,
} from 'antd';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {
    useUsageData,
} from '../Common/UsageDataProvider';
import * as Actions from '../../../../../flex/Actions';
import { useDispatch } from 'react-redux';
import { createActionLogTotalQuery } from '../Common/utils';
const { Text } = Typography;

const TotalBreadcrumb = (props) => {
    const { hierarchy, isAllLinkDisabled, style } = props;
    const [ source, setSource ]= useUsageData();
    const dispatch = useDispatch();

    const onClick = (uuid) => () => {
        const index = source.hierarchy.map(e=>e.uuid).indexOf(uuid);
        const hierarchy = source.hierarchy.slice(0, index+1);
        setSource(source => ({...source, hierarchy}));
        const queries = createActionLogTotalQuery({source, hierarchy});
        dispatch(Actions.http.connection.usage.total(queries));
    }

    const Separator = <Text className='total-breadcrumb-separator'>{'>'}</Text>;
    const breadcrumbItems = hierarchy
        .map((elm, idx) => {
            const link = isAllLinkDisabled || !elm.url
                ? <span className='dummy-text'>{elm.name}</span>
                : <Link to={elm.url} onClick={onClick(elm.uuid)}>{elm.name}</Link>
            return (
                <Breadcrumb.Item key={idx}>
                    {elm.url === undefined ? <Text>{elm.name}</Text> : link}
                </Breadcrumb.Item>
            );
        });

    return (
        <Breadcrumb className='total-breadcrumb' separator={Separator} style={style}>
            {breadcrumbItems}
        </Breadcrumb>
    );
}

TotalBreadcrumb.prototype = {
    hierarchy: PropTypes.arrayOf(PropTypes.exact({
        UUID: PropTypes.string,
        name: PropTypes.string,
    })),
}

export default TotalBreadcrumb;
import { useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
} from 'antd';

import BaseForm from 'components/modules/BaseForm';
import { QUERY_STRING_PARAMS, useUrlParameters } from 'constants/CustomHooks/useUrlParameters';
import { getDefaultLayout } from 'constants/GlobalConfig';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import { TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

const Form = BaseForm;
const layout = getDefaultLayout(false, true);
const { Option } = Select;

type FormValues = Http.Connection.Request.Parameter.ExamCategory.Search;

type Props = {
    loading: boolean
    tabName: 'board' | 'school' | undefined
    onSearch: (values: FormValues, isFullSearch: boolean) => void
    onUpdateSearch: (values: FormValues, isFullSearch: boolean) => void
}

export const ExamCategorySearch: React.VFC<Props> = ({
    loading,
    tabName: activeTabName,
    onSearch,
    onUpdateSearch,
}) => {
    const [form] = Form.useForm<FormValues>();
    const [isFullSearch, setIsFullSearch] = useState(false);
    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const urlParameters = useUrlParameters();
    const title = urlParameters[QUERY_STRING_PARAMS.title];
    const status = urlParameters[QUERY_STRING_PARAMS.status];
    const isRequired = urlParameters[QUERY_STRING_PARAMS.isRequired];
    const resultExists = urlParameters[QUERY_STRING_PARAMS.resultExists];

    useEffect(() => {
        form.resetFields();

        const isFullSearch = [status, isRequired, resultExists].some(v => v !== undefined);

        const search: FormValues = {
            is_required: isRequired === '1' ? 1 : isRequired === '0' ? 0 : undefined,
            result_exists: resultExists === '1' ? 1 : resultExists === '0' ? 0 : undefined,
            status: (['before', 'ongoing', 'finished'].includes(status) ? status : undefined) as FormValues['status'],
            title: title,
        };

        setIsFullSearch(isFullSearch);
        onUpdateSearch(search, isFullSearch);

        if (title || status || isRequired || resultExists) {
            form.setFieldsValue(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabName, form, title]);

    function onFinish(values: FormValues) {
        onSearch(values, isFullSearch);
    }

    function onReset() {
        form.resetFields();
    }

    function onModeChange(e: RadioChangeEvent) {
        setIsFullSearch(e.target.value === 'full');
    }

    return (
        <div className='card general-search'>
            <div className='mb-8'>
                <Radio.Group buttonStyle='solid' onChange={onModeChange} value={isFullSearch ? 'full' : 'simple'}>
                    <Radio.Button value='simple'>シンプルな検索</Radio.Button>
                    <Radio.Button value='full'>詳細検索</Radio.Button>
                </Radio.Group>
            </div>

            <Form {...layout} form={form} name='control-hooks-test-search' onFinish={onFinish}>
                <Form.Item label='タイトル' name='title'>
                    <Input placeholder='タイトルを入力してください' />
                </Form.Item>

                {isFullSearch && (
                    <>
                        <Row gutter={16}>
                            {(!isControllableUser && activeTabName === TAB_NAMES.SCHOOL) && (
                                <Col span={8}>
                                    <Form.Item label='テスト種別' name='is_required'>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                        >
                                            <Option value={1}>必修</Option>
                                            <Option value={0}>自習</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={8}>
                                <Form.Item label='状態' name='status'>
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                    >
                                        <Option value='before'>解答期間前</Option>
                                        <Option value='ongoing'>解答期間中</Option>
                                        <Option value='finished'>解答期間終了</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='解答状態' name='result_exists'>
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                    >
                                        <Option value={1}>解答あり</Option>
                                        <Option value={0}>解答なし</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                <div className='submit-container'>
                    <Button
                        disabled={loading}
                        htmlType='button'
                        loading={loading}
                        onClick={onReset}
                    >
                        リセット
                    </Button>
                    <Button
                        disabled={loading}
                        htmlType='submit'
                        icon={<SearchOutlined />}
                        loading={loading}
                        type='primary'
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Space, Button, Popover } from 'antd';
import { useReactToPrint } from 'react-to-print';

import BaseModal from 'components/modules/BaseModal';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import Spin from 'components/modules/Spin';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { resultQuestionnaireSelector } from 'flex/view/Questionnaire/ResultQuestionnaire/selectors';

import * as TagElementGenerator from '../../TagElementGenerator';
import { AnswerResultModal } from '../AnswerResultModal';
import ModalContents from './ModalContents';


const ResultQuestionnaireModal = ({
    onCancel,
    onClickFullScreen,
}: {
    onCancel: () => void;
    onClickFullScreen: (uuid: string) => void;
}) => {
    const { isResultOpen } = useAppSelector(state => state.questionnaireTab.modal);
    const { isOpen, item } = useAppSelector(state => state.resultQuestionnaire.modal);
    const { questionnaire } = useAppSelector(state => state.resultQuestionnaire);
    const loading = useAppSelector(resultQuestionnaireSelector.loading);
    const disabledCsvExport = useAppSelector(resultQuestionnaireSelector.disabledCsvExport);
    const loadingButtons = useAppSelector(resultQuestionnaireSelector.loadingButtons);
    const dispatch = useDispatch();
    const printTargetRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printTargetRef.current,
        documentTitle: `${questionnaire?.name}の結果`,
    });

    useEffect(() => {
        if (!isResultOpen) return;

        dispatch(Actions.view.viewLogic.resultQuestionnaire.prepareView());
    }, [dispatch, isResultOpen]);

    const handleClickFullScreen = () => {
        onClickFullScreen(questionnaire.uuid);
    };

    const handleResultCancel = () => {
        dispatch(Actions.view.viewLogic.resultQuestionnaire.closeDetail());
    };

    const handleCsvDownload = (encoding: Encoding) => {
        dispatch(Actions.view.viewLogic.resultQuestionnaire.downloadCsv(questionnaire.uuid, encoding));
    };

    const handleClickAnswerDetail = (item: Question) => {
        dispatch(Actions.view.viewLogic.resultQuestionnaire.openDetail(item));
    };

    const footer = [
        <DownloadCSVConfirm
            disabled={disabledCsvExport}
            disabledMessage={
                <p>
                    CSVエクスポートはすでに実行済みです。<br />
                    CSVのダウンロード状況については、画面左下からご確認ください
                </p>
            }
            handleOk={handleCsvDownload}
            key='download'
            loading={loadingButtons}
            size='large'
        />,
        <Popover
            content={
                '印刷設定画面が表示されましたら、' +
                '送信先を「PDFに保存」を選択して、' +
                '「保存」ボタンをクリックしてください'
            }
            key='popover'
        >
            <Button
                disabled={loadingButtons}
                loading={loadingButtons}
                onClick={handlePrint}
                size='large'
            >
                PDFエクスポート
            </Button>
        </Popover>,
        <Button
            disabled={loadingButtons}
            key='full-screen'
            loading={loadingButtons}
            onClick={handleClickFullScreen}
            size='large'
        >
            全画面表示
        </Button>,
        <Button key='back' onClick={onCancel} size='large'>閉じる</Button>,
    ];

    return (
        <BaseModal
            className='common-modal questionnaire-result questionnaire-preview'
            footer={footer}
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={questionnaire ? <ModalTitle questionnaire={questionnaire} /> : ''}
            visible={isResultOpen}
        >
            <Spin
                spinning={loading}
                style={{ minHeight: '100px' }}
                tip='しばらくお待ち下さい...'
            >
                {questionnaire ?
                    <ModalContents
                        onClickAnswerDetail={handleClickAnswerDetail}
                        printTargetRef={printTargetRef}
                        questionnaire={questionnaire}
                    /> :
                    <div style={{ minHeight: '100px' }} />
                }
            </Spin>

            {/* AnswerResultModal は BaseModal 内にないと後ろに隠れてしまう*/}
            <AnswerResultModal
                isOpen={isOpen}
                item={item}
                onCancel={handleResultCancel}
                questionnaire={questionnaire}
            />
        </BaseModal>
    );
};


/**
 * アンケートのモーダルにつけるたいとるについて変更する
 */
const ModalTitle = ({ questionnaire }: { questionnaire: Questionnaire }) => {
    const tagNode = TagElementGenerator.generate(
        TagElementGenerator.TYPES_QUESTIONNAIRE_PUBLIC,
        questionnaire.isPublic,
    );
    const tagNode2 = TagElementGenerator.generate(
        TagElementGenerator.TYPES_QUESTIONNAIRE_ANONYMOUS,
        questionnaire.isAnonymous,
    );
    const tagNode3 = TagElementGenerator.generate(
        TagElementGenerator.TYPES_QUESTIONNAIRE_PUBLIC_RESULT,
        questionnaire.isPublicResult,
    );

    return (
        <Space size={8}>
            {tagNode}{tagNode2}{tagNode3}<span>{questionnaire.name}</span>
        </Space>
    );
};

export default ResultQuestionnaireModal;

import {combineActions} from 'redux-actions'
import * as Actions from '../../../Actions'


const defaultReducer = {
    next: (state, action) => {
        return {...action}
    },
    throw: (state, action) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE
            }
        }))
    }
};

export const appTemplateTabReducer = {
    appTemplateTab: (state, action)=>({...action}),
    [Actions.view.viewLogic.appTemplateTab.saveSearch]: (state, action) => ({
        ...state,
        search: action.payload.values
    }),
    [Actions.view.viewLogic.appTemplateTab.savePage]: (state, action) => ({
        ...state,
        pagination: { ...state.pagination,
            current: action.payload.page,
            pageSize: action.payload.page_size
        }
    })
}

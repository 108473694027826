import * as React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';

import './DetailOfListItemTable.scss';
import BaseForm from 'components/modules/BaseForm';

const Form = BaseForm;

// 本来は、きちんと Key などをキャメルケースに変換したものを使いたいが、
// 実装時にはそういった変換などの処理がないので、
// 仕方なくレスポンスをそのまま使う。
type AppTemplate = Http.Connection.Response.AppTemplate.SearchForAdmin['result']['items'][number];

type Lti11ChoiceUrl<T> = Http.Connection.Response.AppTemplate.Lti11.URL.ChoiceUrl<T>;
type Lti13ChoiceUrl<T> = Http.Connection.Response.AppTemplate.Lti13.URL.ChoiceUrl<T>;
type Lti11CandidateWithDefault = Http.Connection.Response.AppTemplate.Lti11.URL.CandidateWithDefault;
type Lti13CandidateWithDefault = Http.Connection.Response.AppTemplate.Lti13.URL.CandidateWithDefault;

type Props = {
    appTemplate?: AppTemplate
    form: FormInstance
};

export const DetailOfListItemTable: React.VFC<Props> = ({
    appTemplate,
    form,
}) => {
    const ltiUrlCandidateUuid = Form.useWatch('ltiUrlCandidateUuid', form) ?? '';

    if (!appTemplate || !appTemplate.url.is_choices) return <></>;

    switch (appTemplate.type) {
        case 1: {
            return <Lti11Table ltiUrlCandidateUuid={ltiUrlCandidateUuid} url={appTemplate.url} />;
        }
        case 2: {
            return (
                <Lti13Table
                    ltiUrlCandidateUuid={ltiUrlCandidateUuid}
                    url={appTemplate.url}
                    useOrganizationCode={appTemplate.use_organization_code}
                />
            );
        }
    }
};

const Lti11Table: React.VFC<{
    url: Lti11ChoiceUrl<Lti11CandidateWithDefault>,
    ltiUrlCandidateUuid: string,
}> = ({
    url,
    ltiUrlCandidateUuid,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const candidate = url.candidates.find(e => e.uuid === ltiUrlCandidateUuid);

    if (!candidate) return <></>;

    return (
        <div className='detail-of-list-item-table'>
            <button className='title' onClick={() => setIsOpen(f => !f)} type='button'>
                <DownOutlined style={{ marginRight: '0.5rem', transform: `rotate(${isOpen ? '0' : '-90deg'})` }} />
                <span>
                    {url.name}「{candidate.name}」の詳細情報
                </span>
            </button>
            {isOpen && (
                <div className='contents'>
                    <div className='row'>
                        <div className='name'>
                            ベース URL
                        </div>
                        <div className='content'>
                            {candidate.url}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            キー
                        </div>
                        <div className='content'>
                            {candidate.key}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            シークレット
                        </div>
                        <div className='content'>
                            {candidate.secret}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const Lti13Table: React.VFC<{
    ltiUrlCandidateUuid: string,
    url: Lti13ChoiceUrl<Lti13CandidateWithDefault>,
    useOrganizationCode: boolean,
}> = ({
    ltiUrlCandidateUuid,
    url,
    useOrganizationCode,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const candidate = url.candidates.find(e => e.uuid === ltiUrlCandidateUuid);

    if (!candidate) return <></>;

    const paramJson = candidate.param_json;

    const redirectUris = Array.isArray(paramJson.redirect_uris) ?
        paramJson.redirect_uris :
        [paramJson.redirect_uris];

    return (
        <div className='detail-of-list-item-table'>
            <button className='title' onClick={() => setIsOpen(f => !f)} type='button'>
                <DownOutlined style={{ marginRight: '0.5rem', transform: `rotate(${isOpen ? '0' : '-90deg'})` }} />
                <span>
                    {url.name}「{candidate.name}」の詳細情報
                </span>
            </button>
            {isOpen && (
                <div className='contents'>
                    <div className='row'>
                        <div className='name'>
                            学習ツール URL
                        </div>
                        <div className='content'>
                            {paramJson.tool_url}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            ログイン開始 URL
                        </div>
                        <div className='content'>
                            {paramJson.initiate_login_url}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            学習ツール公開鍵 URL
                        </div>
                        <div className='content'>
                            {paramJson.public_keyset}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            リダイレクト URIs
                        </div>
                        <div className='content'>
                            {redirectUris[0]}
                        </div>
                    </div>
                    {redirectUris.slice(1).map((uri, i) => (
                        <div className='row' key={i}>
                            <div className='name' />
                            <div className='content'>
                                {uri}
                            </div>
                        </div>
                    ))}

                    <div className='row'>
                        <div className='name'>
                            ログインヒント
                        </div>
                        <div className='content'>
                            {paramJson.login_hint}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            クライアント ID
                        </div>
                        <div className='content'>
                            {paramJson.client_id}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            デプロイメント ID
                        </div>
                        <div className='content'>
                            {useOrganizationCode && paramJson.deployment_id === null ?
                                '学校コードを利用する' :
                                paramJson.deployment_id
                            }
                        </div>
                    </div>

                    <div className='row'>
                        <div className='name'>
                            ユーザー情報の連携
                        </div>
                        <div className='content'>
                            {paramJson.is_send_user_info ?
                                'ユーザー情報を送信する' :
                                'ユーザー情報を送信しない'
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
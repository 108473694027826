import * as React from 'react';
import {
    Button,
    Col,
    Row,
    Typography,
} from 'antd';
import SummaryGraph from './SummaryGraph';
import {
    canGetNextTerm,
    canGetPrevTerm,
    formatUserFriendlyFuncCreator,
} from '../../../utils/TermOperators';
const { Title } = Typography;

type Props = {
    data: EverydayNote.ResultTotal.TimeSeries
    term: moment.Moment
    termType: Exclude<TermTypes, 'year'>
    loading: boolean
    onClickPrevTerm: () => void
    onClickNextTerm: () => void
    questionType: EverydayNote.QuestionTypes | undefined
}

const Summary: React.VFC<Props> = ({
    data,
    term,
    termType,
    loading,
    onClickPrevTerm,
    onClickNextTerm,
    questionType,
}) => {
    const legendRef = React.useRef<HTMLDivElement>(null);
    const legendHeight = legendRef.current?.getBoundingClientRect().height ?? 0;

    // 本来は Legend が更新されたら再描画したいが、
    // Legend はの描画を判断するのは legendRef しかなく、
    // ref では再描画されないので、無理やり更新する。
    const [, baseUpdater] = React.useState(true);
    const updater = React.useCallback(() => baseUpdater(e => !e), []);

    return (
        <div className='summary'>
            <Row>
                <Col span={2} className='title-operator-left'>
                    <Button
                        htmlType='button'
                        onClick={onClickPrevTerm}
                        disabled={loading || !isClickablePrevButton(term, termType)}
                    >
                        {'<'}
                    </Button>
                </Col>

                <Col span={20} className='title-text'>
                    <Title level={3}>{createTermTile(term, termType)}</Title>
                </Col>

                <Col span={2} className='title-operator-right'>
                    <Button
                        htmlType='button'
                        onClick={onClickNextTerm}
                        disabled={loading || !isClickableNextButton(term, termType)}
                    >
                        {'>'}
                    </Button>
                </Col>
            </Row>

            <Row style={{height: `${300 + legendHeight}px`}}>
                <SummaryGraph
                    data={data}
                    loading={loading}
                    questionType={questionType}
                    ref={legendRef}
                    updater={updater}
                />
            </Row>
        </div>
    );
}

export default Summary;

const isClickablePrevButton = (term: moment.Moment, termType: Exclude<TermTypes, 'year'>) => {
    return canGetPrevTerm(term, termType);
};

const isClickableNextButton = (term: moment.Moment, termType: Exclude<TermTypes, 'year'>) => {
    return canGetNextTerm(term, termType);
};

const createTermTile = (term: moment.Moment, termType: Exclude<TermTypes, 'year'>) => {
    return formatUserFriendlyFuncCreator(termType)(term);
}
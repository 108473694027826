import {
    ALL_TERM_UUID,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { ViewLogicExecutor } from 'flex/utils';

const CLASS_OPTIONS_LENGTH = 1000;

export const viewLogic = (store: StoreAccessor, next: StoreNext<UserManagementTabState>, action: ActionType<unknown, unknown>) => {
    const userManagementTabState = store.getState()['userManagementTab'];
    const isControllableUser = store.getState()['isControllableUser'];

    const executor = new ViewLogicExecutor<UserManagementTabState>();

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.openEdit, (store, next, action) => {
        next(Actions.http.connection.users.view(action.payload.record.uuid, 'userManagementTab'));
        next(Actions.view.viewLogic.userManagementTab.openEdit());
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.openView, (store, next, action) => {
        const pagination = userManagementTabState['pagination'];
        const search = userManagementTabState['search'];
        const currentTerm = store.getState()['globalState']['currentTerm'];
        const searchParam = { ...search, term_uuid: currentTerm, page_size: pagination.pageSize ?? 50 };

        store.dispatch(Actions.view.viewLogic.userManagementTab.search(searchParam, pagination.current));

        if (!isControllableUser.payload) {
            next(Actions.http.connection.classes.searchForListMap(
                { page_size: CLASS_OPTIONS_LENGTH, term_uuid: currentTerm },
                undefined,
                currentTerm,
                'userManagementTab'
            ));
        }

        if (!isControllableUser.payload && userManagementTabState.roleList?.length === 0) {
            next(Actions.http.connection.role.search({ page_size: 1000 }, undefined, 'userManagementTab'));
        }
        next(Actions.view.viewLogic.userManagementTab.selectTerm(currentTerm));
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.closeView, (store, next, action) => {
        next(Actions.view.viewLogic.userManagementTab.closeView());
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.selectTerm, (store, next, action) => {
        const uuid = action.payload.term_uuid;
        next(action);
        if (uuid) {
            if (!userManagementTabState.classListMap[uuid]) {
                if (userManagementTabState.selectOrganization && isControllableUser.payload) {
                    next(Actions.http.connection.classes.searchForListMap(
                        { page_size: CLASS_OPTIONS_LENGTH, organization_uuid: userManagementTabState.selectOrganization, term_uuid: uuid },
                        undefined,
                        uuid,
                        'userManagementTab',
                    ));
                } else if (!isControllableUser.payload) {
                    next(Actions.http.connection.classes.searchForListMap(
                        { page_size: CLASS_OPTIONS_LENGTH, term_uuid: uuid },
                        undefined,
                        uuid,
                        'userManagementTab',
                    ));
                }
            }
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.selectOrganization, (store, next, action) => {
        const term_uuid = userManagementTabState['selectTerm']
        const organization_uuid = action.payload.organization_uuid
        next(action)

        if (organization_uuid && term_uuid) {
            next(Actions.http.connection.classes.searchForListMap(
                { page_size: CLASS_OPTIONS_LENGTH, organization_uuid: organization_uuid, term_uuid: term_uuid },
                undefined,
                term_uuid,
                'userManagementTab',
            ));
        }

        if (organization_uuid) {
            next(Actions.http.connection.role.search(
                { page_size: CLASS_OPTIONS_LENGTH, organization_uuid: organization_uuid },
                undefined,
                'userManagementTab',
            ));
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.reset, (store, next, action) => {
        const currentTerm = store.getState()['globalState']['currentTerm']
        const selectTerm = userManagementTabState['selectTerm']

        if (isControllableUser.payload) {
            next(Actions.view.viewLogic.userManagementTab.resetControllableUser(currentTerm))
        } else {
            next(Actions.view.viewLogic.userManagementTab.resetUncontrollableUser(currentTerm))
        }

        // 学校もリセットされるため、全体管理者だとリクエスト飛ばない
        if ((currentTerm !== selectTerm) && !isControllableUser.payload) {
            next(Actions.http.connection.classes.searchForListMap(
                { page_size: '500', term_uuid: currentTerm },
                undefined,
                currentTerm,
                'userManagementTab',
            ));
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.page, (store, next, action) => {
        const search = userManagementTabState['search']

        const searchQuery = Object.assign(search, { page_size: action.payload.page_size || 50 })

        next(Actions.view.viewLogic.userManagementTab.pushSelectedRows());
        next(Actions.http.connection.users.search(searchQuery, action.payload.page, 'userManagementTab'));
        next(Actions.view.viewLogic.userManagementTab.savePagination(action.payload.page, action.payload.page_size))
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.search, (store, next, action) => {
        next(action)
        const currentTerm = store.getState()['globalState']['currentTerm']
        const pagination = userManagementTabState['pagination']
        const search = userManagementTabState['search']
        // 入力内容
        const values = action.payload.values
        const searchQuery = {
            ...search,
            ...values,
            page_size: pagination?.pageSize || 50
        };
        // 検索時に指定されていなければ1
        const page = action.payload.page ? action.payload.page : 1

        // すべての年度でない かつ 年度がからの場合は今年度としてあつかう
        if (!searchQuery.term_uuid && searchQuery.term_uuid !== ALL_TERM_UUID) {
            searchQuery.term_uuid = currentTerm
        }

        next(Actions.http.connection.users.search(searchQuery, page, 'userManagementTab'));
        if (pagination && page !== pagination.current) {
            next(Actions.view.viewLogic.userManagementTab.savePagination(page, pagination?.pageSize || 50))
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.editSubmit, (store, next, action) => {
        next(Actions.view.viewLogic.userManagementTab.editCancel());
        const search = userManagementTabState['search']
        const current = userManagementTabState['pagination'].current
        // 新規検索しないように対応
        store.dispatch(Actions.view.viewLogic.userManagementTab.search(search, current))
    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.downloadCsv, (store, next, action) => {
        let fieldsValue = {
            encoding: action.payload.encoding,
            ...userManagementTabState.search
        };
        if (action.payload.isSimpleExport) {
            next(Actions.http.connection.users.downloadSimpleCsv(fieldsValue));
        } else {
            next(Actions.http.connection.users.export(fieldsValue));
        }

    });

    executor.addViewLogic(Actions.view.viewLogic.userManagementTab.submitMultipleReset, (store, next, action) => {
        next(Actions.view.viewLogic.userManagementTab.submitMultipleReset(action.payload.data));
        next(Actions.http.connection.users.multipleUpdateFlag(action.payload.data));
    });

    executor.execute(store, next, action);
}
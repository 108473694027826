import { useState, useEffect, VFC } from 'react';

import { PlusCircleFilled } from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import { Button, Col, Popconfirm, Row, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';

import { BaseTable } from 'components/modules/BaseTable';

import AddModal from './AddModal';

type Props = {
    currentUserList: User[],
    /**
     * チェックボックスを無効する条件
     */
    getCheckboxDisabled?: (user: User, selectTerm: string ) => boolean,
    onSave: (userList: User[]) => void,
    organizationUuid?: string,
    selectLimit?: number,
};

type Pagination = PartOfRequired<
    TablePaginationConfig,
    'pageSize' | 'current'
>;

const UsersTab: VFC<Props> = ({
    currentUserList,
    getCheckboxDisabled,
    onSave,
    organizationUuid,
    selectLimit,
}) => {
    const [selectedList, setSelectedList] = useState(currentUserList);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: pageSize ?? 50,
            }));
        },
        pageSize: 50,
        position: ['topRight', 'bottomRight'],
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: selectedList.length,
    });

    useEffect(() => {
        setSelectedList(currentUserList);
    }, [currentUserList]);

    function updateSelectList(newValue: User[]) {
        setSelectedList(newValue);
        onSave(newValue);
        setPagination(prev => {
            const current = Math.ceil(newValue.length / prev.pageSize);
            return {
                ...prev,
                current: current < prev.current && current !== 0 ? current : prev.current,
                total: newValue.length,
            };
        });
    }

    function mergeSelectList(newValue: User[]) {
        const tmp = [...newValue, ...selectedList]
            .reduce<Record<string, User>>((obj, it) => {
                obj[it.uuid] = it;
                return obj;
            }, {});
        return Object.values(tmp);
    }

    const columns: ColumnsType<User> = [
        {
            dataIndex: 'login_id',
            key: 'login_id',
            title: 'ユーザーID',
            width: 40,
        },
        {
            children: [
                {
                    dataIndex: 'belongs',
                    key: 'class',
                    render: (row: User['belongs']) => {
                        return row.map((val, index) => {
                            return <div key={`${val.school_class.name}-${index}`}>{val.school_class.name}</div>;
                        });
                    },
                    title: 'クラス名',
                    width: 40,
                },
                {
                    dataIndex: 'belongs',
                    key: 'roles',
                    render: (row: User['belongs']) => {
                        return row.map((val, index) => {
                            return <div key={`roles-${index}`}>{val.role.name}</div>;
                        });
                    },
                    title: '役割',
                    width: 20,
                },
                {
                    dataIndex: 'belongs',
                    key: 'num',
                    render: (row: User['belongs']) => {
                        return row.map((val, index) => {
                            return <div key={`school_class_num-${index}`}>{val.school_class.number}</div>;
                        });
                    },
                    title: '出席番号',
                    width: 15,
                },
            ],
            title: 'クラス',
        },
        {
            children: [
                {
                    dataIndex: 'last_name',
                    key: 'last_name',
                    title: '姓',
                    width: 30,
                },
                {
                    dataIndex: 'last_name_kana',
                    key: 'last_name_kana',
                    title: '姓（ふりがな）',
                    width: 30,
                },
                {
                    dataIndex: 'first_name',
                    key: 'first_name',
                    title: '名',
                    width: 30,
                },
                {
                    dataIndex: 'first_name_kana',
                    key: 'first_name_kana',
                    title: '名（ふりがな）',
                    width: 30,
                },
            ],
            title: '名前',
        },
        {
            dataIndex: 'action',
            ellipsis: true,
            fixed: 'right',
            key: 'action',
            render: (_, record) => (
                <Row align='middle' justify='space-around'>
                    <Col>
                        <Tooltip title='除外'>
                            <Popconfirm
                                cancelText='キャンセル'
                                okText='除外'
                                onConfirm={() => {
                                    updateSelectList(
                                        selectedList
                                            .slice()
                                            .filter(e => e.uuid !== record.uuid)
                                    );
                                }}
                                title={
                                    <>
                                        <span>対象からユーザーを除外します</span>
                                        <br />
                                    </>
                                }
                            >
                                <Button
                                    className='mr-2'
                                    danger
                                    icon={<CloseOutlined />}
                                    shape='circle'
                                    size='small'
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>
            ),
            title: '',
            width: 20,
        },
    ];

    function handleAddUser(values: User[]) {
        updateSelectList(mergeSelectList(values));
        setIsEditOpen(false);
    }

    function handleAddCancel() {
        setIsEditOpen(false);
    }

    return (
        <div className='user-list container'>
            <div className='flex-right-container gutter-bottom'>
                <Popconfirm
                    cancelText='キャンセル'
                    okText='除外'
                    onConfirm={() => updateSelectList([])}
                    title={
                        <>
                            <span>現在対象になっているユーザーを一括除外します</span>
                            <br />
                            <span style={{ fontWeight: 'bold' }}>保存しないと適用されません</span>
                        </>
                    }
                >
                    <Button
                        className='mb-4'
                        icon={<MinusCircleOutlined />}
                        type='primary'
                    >
                        全て除外
                    </Button>
                </Popconfirm>
                <Button
                    className='mb-4'
                    disabled={selectLimit !== undefined && selectedList.length >= selectLimit}
                    icon={<PlusCircleFilled />}
                    onClick={() => setIsEditOpen(true)}
                    type='primary'
                >
                    追加
                </Button>
            </div>
            <BaseTable
                columns={columns}
                dataSource={selectedList}
                emptyDescription='設定されたユーザーはまだいません'
                pagination={pagination}
                scroll={{ x: 1200 }}
            />
            <AddModal
                getCheckboxDisabled={getCheckboxDisabled}
                onCancel={handleAddCancel}
                onOk={handleAddUser}
                organizationUuid={organizationUuid}
                selectLimit={selectLimit}
                visible={isEditOpen}
            />
        </div>
    );
};

export default UsersTab;

import { createAction } from 'redux-actions';
import {
    API_UPLOAD_FILE,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/FILE';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const upload = createAction(
    createActionType('UPLOAD' as const),
    (filename, contentType, data) => ({
        method: 'put', api: `${API_UPLOAD_FILE}/${filename}`,
        data,
        contentType: contentType
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

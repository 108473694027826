import { useEffect, useState } from 'react';
import * as React from 'react';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';
import axios from 'axios';
import { Button } from 'antd';

type Props = {
    visible: boolean
    onClose: () => void
};

export const LicenseModal: React.VFC<Props> = ({
    onClose,
    visible,
}) => {
    const [license, setLicense] = useState('');
    const [error, setError] = useState<Record<'message' | 'stack', string> | Record<never, unknown>>({});
    const [contents, setContents] = useState<React.ReactNode>(null);

    // LICENSE.txt の取得
    useEffect(() => {
        if (!visible || license.length > 0) return;
        let ignore = false;

        const startFetching = async () => {
            await axios.get('/LICENSE.txt', { responseType: 'text' })
                .then(response => {
                    if (!ignore) {
                        setLicense(response.data);
                    }
                })
                .catch(error => {
                    setError(error.toJSON());
                });
        }

        startFetching();

        return () => {
            ignore = true;
        };
    }, [license.length, visible]);

    // モーダルが開いたときの処理
    useEffect(() => {
        if(!visible) return;

        // ライセンス情報のサイズが大きいためか,
        // モーダルを開く際に時間がかかってしまうため,
        // 開くタイミングと表示するタイミングをズラす
        setTimeout(() => {
            setContents(
                'message' in error ?
                    <ErrorContents {...error} /> :
                    license
            );
        }, 1000);
    }, [error, license, visible]);

    // モーダルが閉じたときの処理
    useEffect(() => {
        if(visible) return;

        setContents(null);
        setError({});
    }, [visible]);

    const footer = [
        <Button key='close' size='large' onClick={onClose}>閉じる</Button>
    ];

    // contents が空のときと空文字列(通信中)の場合, true
    const loading = contents === null || contents === ''

    return (
        <BaseModal
            className='full-modal'
            footer={footer}
            style={{ top: 10, whiteSpace: 'pre-line' }}
            title='ライセンス情報'
            onCancel={onClose}
            visible={visible}
        >
            <Spin spinning={loading}>
                {contents}
            </Spin>
        </BaseModal>
    )
};

const ErrorContents = ({ message, stack }: { message: string, stack: string }) => {
    return (
        <div>
            <h1>エラーが発生しました</h1>

            <p>{message}</p>
            <p>{stack}</p>
        </div>
    );
};
type SearchTemplate = Http.Connection.Response.AppTemplate.ControlSearch['result']['items'][number];
type ViewTemplate = Http.Connection.Response.AppTemplate.ControlView['result'];

/**
 * /view のレスポンスから、選択されたと思われる candidate を探す
 *
 * AppTemplate については、 is_default が true のものが選択された candidate となる
 */
export const findCandidate = (template: ViewTemplate | SearchTemplate) => {
    if (!('lti_app_template' in template)) return;

    const {
        lti_app_template,
        type,
        url,
    } = template;

    if (!url || !lti_app_template.url.is_choices) return;

    // 型推論の都合で type を別々にし、 record をつけている
    switch (type) {
        case 1: {
            return template.url.candidates.find(c => c.is_default);
        }
        case 2: {
            return template.url.candidates.find(c => c.is_default);
        }
        default: {
            return;
        }
    }
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BookOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Select } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';
import { convertAppTemplateAuthTypeToString, formRules } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import Targets from '../Targets';
import ConnectionInfoTable from './ConnectionInfoTable';
import { EditUrlAndParam } from './Forms/EditUrlAndParam';
import { createInitFormValues } from './utils/createInitFormValues';
import { createSubmitValue } from './utils/createSubmitValue';
import { createUpdateFormValue } from './utils/createUpdateFormValue';
import './EditAppTemplate.scss';

const Form = BaseForm.ModalForm;
const { Option } = Select;
const { statusEnum } = Actions;


// 教材アプリ管理のモーダルの基本のレイアウト
export const modalBaseLayout = {
    labelCol: {
        /* eslint-disable sort-keys */
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 5 },
        xxl: { span: 4 },
        /* eslint-enable sort-keys */
    },
    wrapperCol: {
        /* eslint-disable sort-keys */
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 19 },
        xxl: { span: 20 },
        /* eslint-enable sort-keys */
    },
};

// 教材アプリ管理のモーダルのレイアウト (ラベルなし)
export const modalBaseLayoutWithoutLabel = {
    labelCol: {
        /* eslint-disable sort-keys */
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 5 },
        xxl: { span: 4 },
        /* eslint-enable sort-keys */
    },
    wrapperCol: {
        /* eslint-disable sort-keys */
        sm: { offset: 0, span: 24 },
        md: { offset: 0, span: 24 },
        xl: { offset: 5, span: 19 },
        xxl: { offset: 4, span: 20 },
        /* eslint-enable sort-keys */
    },
};

// antd Form.Itemのレイアウト
export const formItemLayout = {
    labelCol: {
        /* eslint-disable sort-keys */
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 6 },
        xl: { span: 5 },
        xxl: { span: 4 },
        /* eslint-enable sort-keys */
    },
    wrapperCol: {
        /* eslint-disable sort-keys */
        xm: { span: 24 },
        sm: { span: 24 },
        md: { span: 18 },
        xl: { span: 19 },
        xxl: { span: 20 },
        /* eslint-enable sort-keys */
    },
};

// antd Form.Itemのレイアウト (ラベルなし)
export const formItemLayoutWithoutLabel = {
    labelCol: {
        /* eslint-disable sort-keys */
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 6 },
        xl: { span: 5 },
        xxl: { span: 4 },
        /* eslint-enable sort-keys */
    },
    wrapperCol: {
        /* eslint-disable sort-keys */
        xs: { offset: 0, span: 24 },
        sm: { offset: 0, span: 24 },
        md: { offset: 6, span: 18 },
        xl: { offset: 5, span: 19 },
        xxl: { offset: 4, span: 20 },
        /* eslint-enable sort-keys */
    },
};

const EditAppTemplate = ({
    isLoading,
    onCancel,
    onOk,
    record,
    visible,
}) => {
    const [form] = Form.useForm();

    const [isSelectTargetOpen, setIsSelectTargetOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const isFirstRender = useRef(true);

    const appTemplateList = useSelector(state => state.appTemplateTab.appTemplateList);
    const appsTemplateForAdminEditConnection = useSelector(state => state.appsTemplateForAdminEditConnection);
    const isControllableUser = useSelector(state => state.isControllableUser.payload);

    const baseTemplateUuid = Form.useWatch('baseTemplateUuid', form);
    const isTargetAll = Form.useWatch('is_target_all', form);
    const targetOrganization = Form.useWatch('target_organization', form);

    const shouldCreate = !record;
    const currentTarget = { target_organizations: targetOrganization ?? [] };
    const selectAppTemplate = useMemo(() => {
        return shouldCreate ? appTemplateList.find(v => v.uuid === baseTemplateUuid) : record;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseTemplateUuid, shouldCreate]);

    const dispatch = useDispatch();

    useEffect(() => {
        // モーダルが表示された際の初期化

        if (!visible) return;

        // 以前のモーダルの内容(エラーなどを含む)をリセット
        form.resetFields();

        form.setFieldsValue(
            createInitFormValues(
                shouldCreate ? undefined : selectAppTemplate,
                isControllableUser
            )
        );

        // 全 State の初期化
        setIsSelectTargetOpen(false);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, shouldCreate]);

    useEffect(() => {
        if (isFirstRender.current) return;

        const meta = appsTemplateForAdminEditConnection.meta;

        setLoading(meta.fetch);
        if (meta.status === statusEnum.SUCCESS) {
            onOk();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsTemplateForAdminEditConnection]);

    useEffect(() => {
        if (!isFirstRender.current) return;
        isFirstRender.current = false;
    }, []);

    function onFinish(values) {
        const submitValues = createSubmitValue(shouldCreate, selectAppTemplate, values);

        if (shouldCreate) {
            dispatch(Actions.http.connection.apps.template.createForAdmin(submitValues));
        } else {
            dispatch(Actions.http.connection.apps.template.updateForAdmin(record.uuid, submitValues));
        }
    }

    function handleCancel() {
        onCancel();
    }

    function handleChangeBaseTemplateUuid(uuid) {
        const appTemplate = appTemplateList.find(v => v.uuid === uuid);

        if (!appTemplate) return;

        form.setFieldsValue(
            createUpdateFormValue(appTemplate)
        );
    }

    function handleSubmitTarget(values) {
        setIsSelectTargetOpen(false);
        form.setFieldsValue({ target_organization: values.target_organizations });
    }

    function handleCancelTarget() {
        setIsSelectTargetOpen(false);
    }

    return (
        <BaseModal
            className='common-modal app-template-modal edit-modal'
            footer={[
                <Button
                    key='back'
                    loading={loading || isLoading}
                    onClick={handleCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    loading={loading || isLoading}
                    onClick={form.submit}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            forceRender
            onCancel={handleCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><BookOutlined /> {shouldCreate ? 'アプリ配信の新規作成' : 'アプリ配信の編集'}</span>}
            visible={visible}
        >
            <Spin spinning={loading || isLoading}>
                <Form {...formItemLayout} form={form} name='control-hooks-edit-user' onFinish={onFinish}>

                    <Form.Item
                        label='ベースアプリ選択'
                        name='baseTemplateUuid'
                        rules={[{ message: '必須項目です', required: true }]}
                    >
                        <Select
                            allowClear
                            disabled={!shouldCreate}
                            onChange={handleChangeBaseTemplateUuid}
                            placeholder='ベースアプリを選択してください'
                        >
                            {appTemplateList.map((value) => (
                                <Option key={value.uuid} value={value.uuid}> {value.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='アプリの名称'
                        name='name'
                        rules={[
                            formRules.required({ label: 'アプリの名称' }),
                            formRules.range({ label: 'アプリの名称', max: 255 }),
                        ]}
                    >
                        <Input placeholder='アプリの名称を入力してください' />
                    </Form.Item>

                    <Form.Item
                        label='配信の状態'
                        name='is_active'
                        rules={[{ message: '必須項目です', required: true }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>有効</Radio>
                            <Radio value={false}>無効</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label='配信対象'
                        name='is_target_all'
                        rules={[{ message: '必須項目です', required: true }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>すべて</Radio>
                            <Radio value={false}>個別指定</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item hidden name='target_organization'><Input /></Form.Item>

                    {!isTargetAll && (
                        <Form.Item
                            rules={[formRules.required({ isSelect: true, label: '配信対象' })]}
                            {...formItemLayoutWithoutLabel}
                        >
                            <Button icon={<TeamOutlined />} onClick={() => setIsSelectTargetOpen(true)} type='primary'>
                                配信対象を選択
                            </Button>
                            <Targets.Detail currentTarget={currentTarget} />
                        </Form.Item>
                    )}

                    <Form.Item label='連携方式'>
                        <p>{convertAppTemplateAuthTypeToString(selectAppTemplate?.type)}</p>
                    </Form.Item>

                    <Form.Item noStyle>
                        <EditUrlAndParam
                            appTemplate={selectAppTemplate}
                            context='AppTemplate'
                            isControllableUser={isControllableUser}
                            isCreate={shouldCreate}
                        />
                    </Form.Item>

                    <ConnectionInfoTable
                        appTemplate={selectAppTemplate}
                        form={form}
                        isCreate={shouldCreate}
                    />

                    <Targets
                        currentTarget={currentTarget}
                        onCancel={handleCancelTarget}
                        onSubmit={handleSubmitTarget}
                        showClass={false}
                        showOrganization={true}
                        showRole={false}
                        showUser={false}
                        visible={isSelectTargetOpen}
                    />
                </Form>
            </Spin>
        </BaseModal>
    );
};

export default EditAppTemplate;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';


export type TargetSchoolClassState = {
    result: {
        // 他との利便性のために items Key を経由するようにしている
        items: {
            name: string,
            termName: string,
            uuid: string,
        }[]
    }
    isLoading: boolean
};

type Connection = TaoExamTargetSchoolClassConnection | TaoExamControlTargetSchoolClassConnection;


export const useTargetSchoolClass = () => {
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const [state, setState] = useState<TargetSchoolClassState>({
        isLoading: false,
        result: { items: [] },
    });

    const taoExamTargetSchoolClassConnection = useAppSelector(state => state.taoExamTargetSchoolClassConnection);
    const taoExamControlTargetSchoolClassConnection = useAppSelector(state => state.taoExamControlTargetSchoolClassConnection);

    const dispatch = useDispatch();


    const effect = useCallback((connection: Connection, state: TargetSchoolClassState) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState({
                    isLoading: meta.fetch,
                    result: {
                        items: payload.result.items.map(item => ({
                            name: item.name,
                            termName: item.term.name,
                            uuid: item.uuid,
                        })),
                    },
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
    }, []);

    useEffect(
        () => effect(taoExamTargetSchoolClassConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamTargetSchoolClassConnection]
    );
    useEffect(
        () => effect(taoExamControlTargetSchoolClassConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlTargetSchoolClassConnection]
    );


    const fetchData = useCallback((
        examUuid: string,
        tab: TabKeys | '',
        organizationUuid?: string,
    ) => {
        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const action = isControllableUser ?
            Actions.http.connection.tao.exam.controlTargetSchoolClass(examUuid, organizationUuid ?? '') :
            tab === 'board' ?
                Actions.http.connection.tao.exam.controlTargetSchoolClass(examUuid, '') :
                Actions.http.connection.tao.exam.targetSchoolClass(examUuid);

        dispatch(action);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return [state, fetchData] as const;
};

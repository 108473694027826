import { createContext, useEffect, useState, useContext, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import { getMoment, getDefaultFeatures } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { SourceBuilder } from './SourceBuilder';
const moment = getMoment();

const ACTION_KEY_ALL = '';
const ACTION_KEY_SIGNIN = 'login';
const ACTION_KEY_FAIL_SIGNIN = 'fail-login';
const ACTION_KEY_SIGNOUT = 'logout';
const ACTION_KEY_VIEW_INFORMATION = 'view-information';
const ACTION_KEY_OPEN_APPLICATION = 'open-application';
const ACTION_KEY_START_TAO_LTI = 'start-exam';
const ACTION_KEY_START_EDU_MALL = 'start-edu-mall';
const ACTION_KEY_ANSWER_QUESTIONNAIRE = 'answer-questionnaire';
const ACTION_KEY_ANSWER_EVERYDAY_NOTE = 'answer-everyday-note';

const ACTION_TEXT_SIGNIN = 'サインインしました[IP_ADDRESS]';
const ACTION_TEXT_FAIL_SIGNIN = 'サインインに失敗しました';
const ACTION_TEXT_SIGNOUT = 'サインアウトしました[IP_ADDRESS]';
const ACTION_TEXT_VIEW_INFORMATION = 'おしらせ「CONTENT_NAME」を確認しました';
const ACTION_TEXT_OPEN_APPLICATION = 'アプリ「CONTENT_NAME」を利用しました';
const ACTION_TEXT_START_TAO_LTI = 'テスト「CONTENT_NAME」を受検しました';
const ACTION_TEXT_START_EDU_MALL = 'EduMall「CONTENT_NAME」を利用しました';
const ACTION_TEXT_ANSWER_QUESTIONNAIRE = 'アンケート「CONTENT_NAME」を回答しました';
const ACTION_TEXT_ANSWER_EVERYDAY_NOTE = '毎日の記録「CONTENT_NAME」を回答しました';

/* eslint-disable sort-keys */
const ACTION_KEY_TO_TEXT = {
    [ACTION_KEY_SIGNIN]: ACTION_TEXT_SIGNIN,
    [ACTION_KEY_FAIL_SIGNIN]: ACTION_TEXT_FAIL_SIGNIN,
    [ACTION_KEY_SIGNOUT]: ACTION_TEXT_SIGNOUT,
    [ACTION_KEY_VIEW_INFORMATION]: ACTION_TEXT_VIEW_INFORMATION,
    [ACTION_KEY_OPEN_APPLICATION]: ACTION_TEXT_OPEN_APPLICATION,
    [ACTION_KEY_START_TAO_LTI]: ACTION_TEXT_START_TAO_LTI,
    [ACTION_KEY_START_EDU_MALL]: ACTION_TEXT_START_EDU_MALL,
    [ACTION_KEY_ANSWER_QUESTIONNAIRE]: ACTION_TEXT_ANSWER_QUESTIONNAIRE,
    [ACTION_KEY_ANSWER_EVERYDAY_NOTE]: ACTION_TEXT_ANSWER_EVERYDAY_NOTE,
} as const;
/* eslint-enable sort-keys */

type ActionKey = keyof typeof ACTION_KEY_TO_TEXT;

/**
 * 対象となるアクションキーの一覧
 */
/* eslint-disable sort-keys */
export const TARGET = {
    ALL: ACTION_KEY_ALL,
    SIGNIN: ACTION_KEY_SIGNIN,
    FAIL_SIGNIN: ACTION_KEY_FAIL_SIGNIN,
    SIGNOUT: ACTION_KEY_SIGNOUT,
    NOTIFICATION: ACTION_KEY_VIEW_INFORMATION,
    USE_APP: ACTION_KEY_OPEN_APPLICATION,
    USE_TAO_LTI: ACTION_KEY_START_TAO_LTI,
    EDUMALL: ACTION_KEY_START_EDU_MALL,
    ANSWER_QUESTIONNAIRE: ACTION_KEY_ANSWER_QUESTIONNAIRE,
    ANSWER_EVERYDAY_NOTE: ACTION_KEY_ANSWER_EVERYDAY_NOTE,
};
/* eslint-enable sort-keys */

/**
 * TARGET を元にテキスト取得するための Object
 * 選択肢などは, tenant 情報を元に表示内容が変化するので使用禁止
 */
export const TARGET_TO_TEXT = {
    [TARGET.ALL]: 'すべて',
    [TARGET.SIGNIN]: 'L-Gate サインイン',
    [TARGET.FAIL_SIGNIN]: 'L-Gate サインインへの失敗',
    [TARGET.SIGNOUT]: 'L-Gate サインアウト',
    [TARGET.NOTIFICATION]: 'お知らせの確認',
    [TARGET.USE_APP]: '教材・アプリの利用',
    [TARGET.USE_TAO_LTI]: 'MEXCBTテストの受検',
    [TARGET.EDUMALL]: 'EduMall',
    [TARGET.ANSWER_QUESTIONNAIRE]: 'アンケートの回答',
    [TARGET.ANSWER_EVERYDAY_NOTE]: '毎日の記録の回答',
};
/* eslint-disable sort-keys */
export const TERM_TYPE = {
    YEAR: 'year',
    MONTH: 'month',
    WEEK: 'week',
    DATE: 'date',
} as const;
/* eslint-enable sort-keys */

/** 利用履歴画面において必要となる情報を提供する Context. */
// useSelector は基本的にこの中でしか使わず,
// Connection の監視(useEffectで使うこと)もこの中で行なう.
export const UsageDataProvider: React.FC = ({ children }) => {
    // 画面切り替え時に, connection 系の情報が残っているために
    // 意図しない描画が行われてしまうので, 最初の render を判定する処理を加える.
    // よりよい方法があれば変更したい.
    // 関連 Issue #1625
    const isFirstRender = useRef(true);

    const location = useLocation();
    const tenant = useAppSelector(state => state.tenant);
    const user = useAppSelector(state => state.user);
    const isControllableUser = useAppSelector(state => state.isControllableUser);
    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);
    const edumallSearchConnection = useAppSelector(state => state.edumallSearchConnection);
    const appsListConnection = useAppSelector(state => state.appsListConnection);
    const usageTotalConnection = useAppSelector(state => state.usageTotalConnection);
    const usageDetailConnection = useAppSelector(state => state.usageDetailConnection);
    const usageExportTotalConnection = useAppSelector(state => state.usageExportTotalConnection);
    const jobInfoMap = useAppSelector(state => state.jobInfoMap);
    const hierarchy: Hierarchy[] = location.pathname.split('/').slice(2).map(e => ({ name: '', uuid: e }));

    if (isControllableUser.payload) {
        hierarchy.unshift({
            name: tenant.payload.name,
            url: undefined,
            uuid: undefined,
        });
    } else {
        hierarchy.unshift({
            name: user.payload.organization.name,
            uuid: user.payload.organization.uuid,
        });
    }

    const [source, setSource] = useState<Source>({
        ...createInitState(),
        featureOptions: createOptions(tenant.payload.features),
        hierarchy: hierarchy,
        isControllableUser: isControllableUser.payload,
    });

    useEffect(() => {
        if (isFirstRender.current) return;
        switch (organizationSearchConnection.meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const organizationList = organizationSearchConnection.payload.result.items.map(e => {
                    return {
                        text: e.name,
                        value: e.uuid,
                    };
                });
                setSource({
                    ...source,
                    organizationList: organizationList,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationSearchConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        switch (edumallSearchConnection.meta.status) {
            case Actions.statusEnum.REQUEST: {
                const copySearchState = Object.assign({}, source);
                const contentsName = edumallSearchConnection.payload.data.contents_name ?? '';

                copySearchState.edumallAppLists[contentsName] = {
                    list: [],
                    loading: true,
                };
                setSource(copySearchState);
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (Object.keys(source.edumallAppLists).length === 0) return;

                const edumallAppList = edumallSearchConnection.payload.result.items.map((e) => {
                    return {
                        text: e.contents_name,
                        value: e.uuid,
                    };
                });
                const copySearchState = Object.assign({}, source);

                const dataCandidatesBeingFetched = Object.entries(copySearchState.edumallAppLists).filter(([_, val]) => {
                    return val.loading;
                })[0];
                const keyOfFetchedData = dataCandidatesBeingFetched[0];

                copySearchState.edumallAppLists[keyOfFetchedData] = {
                    list: edumallAppList,
                    loading: false,
                };

                setSource(copySearchState);
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edumallSearchConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        switch (appsListConnection.meta.status) {
            case Actions.statusEnum.REQUEST: {
                const orgUuid = appsListConnection.payload.data.organization_uuid;
                if (orgUuid === undefined) return;

                const copySearchState = Object.assign({}, source);
                copySearchState.organizationAppLists[orgUuid] = {
                    list: [],
                    loading: true,
                };
                setSource(copySearchState);
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                const orgUuid = appsListConnection.payload.result.items.length === 0 ?
                    Object.entries(source.organizationAppLists).filter(e => e[1].loading)[0][0] :
                    source.isControllableUser && 'organization' in appsListConnection.payload.result.items[0] ?
                        appsListConnection.payload.result.items[0].organization.uuid :
                        user.payload.organization.uuid;

                const organizationAppList = appsListConnection.payload.result.items.map(e => {
                    return {
                        category: e.category?.title ?? '',
                        title: e.title,
                        value: e.uuid,
                    };
                });
                const copySearchState = Object.assign({}, source);
                copySearchState.organizationAppLists[orgUuid] = {
                    list: organizationAppList,
                    loading: false,
                };
                setSource(copySearchState);
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsListConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        switch (usageTotalConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
                setSource({
                    ...source,
                    loading: usageTotalConnection.meta.fetch,
                    summary: {
                        ...source.summary,
                    },
                    total: {
                        ...source.total,
                        loading: true,
                    },
                });
                break;
            case Actions.statusEnum.SUCCESS: {
                const builder = new SourceBuilder();

                builder.isControllableUser = isControllableUser.payload;
                builder.source = source;
                builder.pathname = location.pathname;
                builder.result = usageTotalConnection.payload.result;

                if (source.isControllableUser) {
                    builder.tenantName = tenant.payload.name;
                }

                setSource(builder.getResult());
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usageTotalConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        switch (usageDetailConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
                setSource({
                    ...source,
                    detail: {
                        data: {
                            item_count: undefined,
                            items: [],
                            page_size: undefined,
                            page_total: undefined,
                        },
                        loading: true,
                    },
                });
                break;
            case Actions.statusEnum.SUCCESS: {
                const detailData = Object.assign({}, usageDetailConnection.payload.result) as DetailData;
                detailData.items = detailData.items.map((e) => ({
                    created_at: e.created_at,
                    description: createDescription(e),
                }));

                setSource({
                    ...source,
                    detail: {
                        data: detailData,
                        loading: false,
                    },
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usageDetailConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        switch (usageExportTotalConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
                setSource(prev => ({
                    ...prev,
                    disabledCsvExport: true,
                    loadingCsvExport: true,
                }));
                break;
            case Actions.statusEnum.SUCCESS:
                setSource(prev => ({
                    ...prev,
                    loadingCsvExport: false,
                    // jobInfoMapによってすぐボタンが無効化されるので、disabledCsvExportはそのまま
                }));
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                setSource(prev => ({
                    ...prev,
                    disabledCsvExport: false,
                    loadingCsvExport: false,
                }));
                break;
            default:
                break;
        }
    }, [usageExportTotalConnection]);

    useEffect(() => {
        setSource(prev => ({
            ...prev,
            disabledCsvExport: Object.values(jobInfoMap)
                .filter(jobInfo => jobInfo?.job?.type === BACKGROUND_JOB_TYPE.LOG_TOTAL_CSV_EXPORT).length > 0,
        }));
    }, [jobInfoMap]);

    useEffect(() => {
        // 関数上部にあると, ref なので即時反映されてしまい,
        // isFirstRender での条件分岐が無意味になるので, 一番最後に実行されるようにする
        isFirstRender.current = false;
    }, []);

    return (
        <UsageDataContext.Provider value={[source, setSource]}>
            {children}
        </UsageDataContext.Provider>
    );
};

export const useUsageData = () => useContext(UsageDataContext);

const createDescription = (actionLogDetailItem: DetailData['items'][number]) => {
    if (!actionLogDetailItem.action || ACTION_KEY_TO_TEXT[actionLogDetailItem.action] === undefined) {
        console.error(actionLogDetailItem);
        return '詳細の取得に失敗しました。';
    }

    const contentName = actionLogDetailItem.content_name ?
        actionLogDetailItem.content_name :
        actionLogDetailItem.description?.replace('を起動', '') || '不明なアプリ';

    return ACTION_KEY_TO_TEXT[actionLogDetailItem.action]
        .replace('IP_ADDRESS', actionLogDetailItem.remote_address ?? '')
        .replace('CONTENT_NAME', contentName);
};


export const createInitFormValues = () => ({
    edumall: undefined,
    responseAll: 1 as const,
    target: TARGET.SIGNIN,
    targetDetails: [],
    term: moment(new Date()),
    termType: TERM_TYPE.WEEK,
});

const createInitSummaryValues = () => ({
    data: {
        items: [],
        mean: undefined,
    },
});

const createInitTotalValues = () => ({
    data: [],
    loading: false,
});

const createInitDetailValues = () => ({
    data: {
        item_count: undefined,
        items: [],
        page_size: undefined,
        page_total: undefined,
    },
    loading: false,
});

export const createInitDisplayValues = () => ({
    detail: createInitDetailValues(),
    summary: createInitSummaryValues(),
    total: createInitTotalValues(),
});

const createOptions = (features:  Tenant.Features) => {
    if (features === undefined) return {};

    const options: Record<string, string> = {};

    // ここで options に追加した順番で選択肢が表示される
    // 本当は order などできちんと制御したほうがいい気がする…
    options[TARGET.ALL] = TARGET_TO_TEXT[TARGET.ALL];
    options[TARGET.SIGNIN] = TARGET_TO_TEXT[TARGET.SIGNIN];
    if (features.application) {
        options[TARGET.USE_APP] = TARGET_TO_TEXT[TARGET.USE_APP];
        options[TARGET.EDUMALL] = TARGET_TO_TEXT[TARGET.EDUMALL];
    }
    if (features.information) {
        options[TARGET.NOTIFICATION] = TARGET_TO_TEXT[TARGET.NOTIFICATION];
    }
    //MEXCBTテストの受検は常に表示
    options[TARGET.USE_TAO_LTI] = TARGET_TO_TEXT[TARGET.USE_TAO_LTI];
    options[TARGET.SIGNOUT] = TARGET_TO_TEXT[TARGET.SIGNOUT];
    if (features.questionnaire) {
        options[TARGET.ANSWER_QUESTIONNAIRE] = TARGET_TO_TEXT[TARGET.ANSWER_QUESTIONNAIRE];
    }
    if (features['everyday-note']) {
        options[TARGET.ANSWER_EVERYDAY_NOTE] = TARGET_TO_TEXT[TARGET.ANSWER_EVERYDAY_NOTE];
    }
    return options;
};

const createInitState = (): Source => ({
    ...createInitDisplayValues(),
    ...createInitFormValues(),
    disabledCsvExport: false,
    edumallAppLists: {} as {
        [key: string]: {
            list: {
                text: string;
                value: string;
            }[];
            loading: boolean;
        };
    },
    featureOptions: createOptions(getDefaultFeatures()),
    hierarchy: [
        {
            name: undefined,
            url: undefined,
            uuid: undefined,
        },
    ],
    isControllableUser: false,
    loading: false,
    loadingCsvExport: false,
    organizationAppLists: {} as {
        [key: string]: {
            list: {
                category: string;
                title: string;
                value: string;
            }[];
            loading: boolean;
        };
    },
    organizationList: [] as {
        text: string;
        value: string;
    }[],
    pagination: {
        current: 1,
        pageSize: 50,
    },
    rankThresholds: [0],
});

type Hierarchy = {
    name: string | undefined;
    uuid?: string;
    url?: string;
};

type TotalData = Partial<{
    isLink: boolean
    login_id: string | null;
    name: React.ReactNode;
    term_name: string | null;
    totals: Record<string, number> | [];
    uuid: string;
}>[];

type DetailData = {
    item_count?: number | undefined;
    items: {
        action?: ActionKey;
        content_name?: null;
        created_at: number;
        description: string;
        remote_address?: string;
    }[];
    page_size?: number | undefined;
    page_total?: number | undefined;
};

export type Source = {
    detail: {
        data: DetailData;
        loading: boolean;
    };
    disabledCsvExport: boolean;
    // フォームの初期値としてしか使われない
    edumall: undefined;
    edumallAppLists: {
        [key: string]: {
            list: {
                text: string;
                value: string;
            }[];
            loading: boolean;
        };
    };
    featureOptions: Record<string, string>;
    hierarchy: Hierarchy[] | undefined;
    isControllableUser: boolean;
    loading: boolean;
    loadingCsvExport: boolean;
    organizationAppLists: {
        [key: string]: {
            list: {
                category: string;
                title: string;
                value: string;
            }[];
            loading: boolean;
        };
    };
    organizationList: {
        text: string;
        value: string;
    }[];
    pagination: {
        current: number;
        pageSize: number;
    };
    rankThresholds: number[] | undefined;
    responseAll: 0 | 1;
    summary: {
        data: {
            items:  {
                count: number;
                name: string;
            }[];
            mean: number | undefined;
        };
    };
    target: string;
    // フォームの初期値としてしか使われない
    targetDetails: never[];
    term: moment.Moment;
    termType: typeof TERM_TYPE[keyof typeof TERM_TYPE];
    total: {
        data: TotalData | undefined;
        loading: boolean;
    };
};

const UsageDataContext = createContext([
    createInitState(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (_source: Source) => { },
] as const);
import RichTextView from 'components/modules/RichTextView';
import Ruby from 'components/modules/Ruby';
import { generateFileDomain, getPriorityString } from 'constants/GlobalConfig';
import { StudentModal, ModalHeaderTemplate } from 'components/modules/Student/StudentModal'
import { LabelImportance } from 'components/modules/Student/LabelImportance'

const NotificationPreviewModal = ({
    isOpen = false,
    data = {},
    closeModal = () => {
        console.error("need function 'closeModal'");
    },
}) => {
    const headerElement =
        <ModalHeaderTemplate
            status={
                <LabelImportance type='pills' className={`${data?.priority === 2 ? 'bg-lg-red' : ''}`}>
                    {getPriorityString(data?.priority)}
                </LabelImportance>
            }
        >
            {data?.title}
        </ModalHeaderTemplate>;

    if (!isOpen) return null;

    return (
        <StudentModal
            isOpen={isOpen}
            closeModal={closeModal}
            headerElement={headerElement}
        >
            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='text-xl py-8 leading-10 text-gray-700 break-all'>
                    <RichTextView model={data?.content ?? ''} />
                </div>
                {data?.files && data.files.length > 0
                    ?
                    <div className='w-full'>
                        <table className='w-full border-collapse border table-auto'>
                            <thead>
                                <tr className='bg-gray-100 bg-opacity-25 '>
                                    <td className='flex-1 p-2 pl-4 border'>
                                        ファイル名
                                    </td>
                                    <td className='p-2 px-4 border ' style={{ width: '130px' }}>
                                        ファイル形式
                                    </td>
                                    <td className='p-2 px-4 border'>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.files.map((value, index) => {
                                    return <tr key={index}>
                                        <td className='flex-1 p-2 pl-4 border'>{value.file_name ?? value.name}</td>
                                        <td className='p-2 px-4 border'>{value.mime_type}</td>
                                        <td className={`cursor-pointer text-lg-blue border p-2 px-4 w-48 text-center`}>
                                            <a target='_blank' rel='noopener noreferrer' href={`${generateFileDomain()}/file/view/${value.uuid}`}>
                                                ダウンロード
                                            </a>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    : null
                }
                <div className='w-full pt-5 pb-4 opacity-50'>
                    <table>
                        <tbody>
                            {data?.link ? (
                                <tr>
                                    <td><Ruby>{'{詳細|しょうさい}リンク'}</Ruby></td>
                                    <td> :</td>
                                    <td> <a href={data?.link}>{data?.link}</a></td>
                                </tr>
                            ) : (<tr></tr>)}
                            <tr>
                                <td><Ruby>{'{作成者|さくせいしゃ}'}</Ruby></td>
                                <td> :</td>
                                <td>{data?.publish_from}</td>
                            </tr>
                            <tr>
                                <td><Ruby>{'{配信期間|はいしんきかん}'}</Ruby></td>
                                <td> :</td>
                                <td><Ruby>{`${(data?.duration && data.duration[0]) ? (data?.duration[0].format('YYYY{年|ねん}M{月|がつ}D{日|にち}')) : ' '} から ${(data?.duration && data.duration[1]) ? data?.duration[1].format('YYYY{年|ねん}M{月|がつ}D{日|にち}') : ' '} まで`}</Ruby></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </StudentModal>
    );
};

export default NotificationPreviewModal;

import { useEffect } from 'react';
import QuestionItemCard from './QuestionItemCard';
import {useFormContext} from 'react-hook-form';

import Ruby from 'components/modules/Student/Ruby';
import FileInput from '../FileInput'


const ALLOWED_EXTENSIONS = [
    'jpg', 'jpeg', 'png',
    'pdf',
    'mp4',
    'zip',
    'txt',
    'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx',
];

const FileSelection = props => {
    const {
        content,
        detail,
        file,
        isRequired,
        name,
        onAccept,
        onReject,
    } = props;
    const {errors, register, setValue, unregister, watch} = useFormContext();

    useEffect(() => {
        const validate = {};
        if(isRequired) {
            validate.required = true;
        }
        register(name, validate);
        return () => unregister(name);
    }, [register, unregister, name, isRequired]);

    const fileInfo = undefined;
    useEffect(() => {
        if(fileInfo !== undefined && !fileInfo.loading) {
            setValue(name, fileInfo.uuid, { shouldValidate: true });
        }
    }, [fileInfo, name, setValue]);

    let errorMessage = '';
    if(errors[name]?.message !== undefined) {
        switch (errors[name].type) {
            case 'required':
                errorMessage = <div><Ruby>{`${content} にファイルを{選択|せんたく}してください。`}</Ruby></div>
                break;

            default:
                errorMessage = <div>{errors[name].message}</div>
                break;
        }
    }

    const onDelete = () => {}

    return (
        <QuestionItemCard
            title={content}
            detail={detail}
            error={errorMessage}
            file={file}
            type={<Ruby>{'ファイル{添付|てんぷ}'}</Ruby>}
            isRequired={isRequired}
            watch={watch}
        >
            <div>
                <FileInput
                    type='file'
                    accept={
                        ALLOWED_EXTENSIONS.map(e=>`.${e}`).join(',')
                    }
                    className='mb-4'
                    name={name}
                    maxFileSize={50 * 1000 * 1000}
                    onAccept={onAccept}
                    onReject={onReject}
                />

            </div>
        </QuestionItemCard>
    );
};

export default FileSelection;

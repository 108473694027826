import { useAppSelector } from 'flex/utils';
import * as Actions from 'flex/Actions'
import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

/**
 * ジョブに関するレスポンスを JobInfo に保存する副作用を持つカスタムフック
 */
export const useJobEffect = <TSelected extends ActionType<any, any>>(
    func: (state: Store) => TSelected,
    saveCondition: boolean | ((connection: TSelected) => boolean),
    resultFormatter?: (result: Http.Connection.BaseJobResult, connection: TSelected) => Http.Connection.BaseJobResult,
) => {
    const connection = useAppSelector(func);
    const dispatch = useDispatch();
    const formatter = resultFormatter ?? (res => res);

    /** JobInfo を Store に登録する */
    const setJobInfo = useCallback((
        jobResult: Http.Connection.BaseJobResult,
        gmt: number,
        isError: boolean = false,
        someUuid?: string,
        showClose?: boolean
    ) => {
        dispatch(Actions.data.jobInfoMap.add({
            jobResult,
            gmt,
            isError,
            someUuid,
            showClose,
        }));
    }, [dispatch]);

    /** JobInfo を Store から削除する */
    const removeJob = useCallback((jobUuid: string) => {
        dispatch(Actions.data.jobInfoMap.remove(jobUuid));
    }, [dispatch]);

    const isSave = useMemo(() => {
        return typeof saveCondition === 'boolean' ? saveCondition : saveCondition(connection);
    }, [connection, saveCondition]);

    useEffect(() => {
        if (!isSave) return;

        setJobInfo(
            formatter(connection.payload.result, connection),
            connection.payload.gmt,
            isConnectionError(connection),
            connection.meta?.uuid
        )
        connection.meta.deleteJobId && removeJob(connection.meta.deleteJobId);
    }, [connection, isSave, removeJob, setJobInfo]);// eslint-disable-line react-hooks/exhaustive-deps
}

/** ネットワークエラーかどうか判断する */
const isConnectionError = <TSelected extends ActionType<any, any>>(connection: TSelected) => (
    connection?.payload?.is_successful === false
    || connection.meta.status === Actions.statusEnum.ERROR
    || connection.meta.status === Actions.statusEnum.FAILURE
)

import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/EDIT_QUESTION';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * 質問モーダルを開いた際に呼び出す。
 * Storeの内部にデータをセットする。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    () => ({}),
    () => ({})
);

/**
 * 質問モーダル内で、親のアンケートを変更した際呼び出す。
 * Storeの内部にデータをセットする。
 * */
export const selectParent = createAction(
    createActionType('SELECT_PARENT' as const),
    (parentTmpItemId) => ({parentTmpItemId}),
    () => ({})
);

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'flex/utils';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import ClickableAlert from "./ClickableAlert";
import DetailModal from './DetailModal';

const Announcement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [announcement, setAnnouncement] = useState<{items?: Announcement[]}>({});
    const [detailValue, setDetailValue] = useState<Announcement>();
    const announcementGetConnection = useAppSelector(selectors.announcementGetConnection);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Actions.http.connection.announcement.get());
    }, [dispatch]);

    useEffect(() => {
        switch (announcementGetConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
                break;
            case Actions.statusEnum.SUCCESS:
                const result = announcementGetConnection.payload.result;
                setAnnouncement(result);
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
    }, [announcementGetConnection]);

    return (
        <>
            <div style={{ width: '100%' }}>
                {announcement.items?.map((notification, i) => (
                    <ClickableAlert
                        key={i}
                        message={notification.title}
                        onClick={() => { setDetailValue(notification); setIsOpen(true); }}
                        style={{ marginBottom: '0.75rem' }}
                        showIcon
                        type='error'
                    />
                ))}
            </div>

            <DetailModal
                isOpen={isOpen}
                detail={detailValue}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
}

export default Announcement;
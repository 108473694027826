import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/USER_MANAGEMENT_TAB';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const downloadCsv = createAction(
    createActionType('DOWNLOAD_CSV' as const),
    (encoding, isSimpleExport) => ({ encoding, isSimpleExport }),
    () => ({})
);

export const editCancel = createAction(
    createActionType('EDIT_CANCEL' as const),
    () => ({}),
    () => ({})
);

export const editSubmit = createAction(
    createActionType('EDIT_SUBMIT' as const),
    () => ({}),
    () => ({})
);

export const openCreate = createAction(
    createActionType('OPEN_CREATE' as const),
    () => ({}),
    () => ({})
);

export const openEdit = createAction(
    createActionType('OPEN_EDIT' as const),
    (record) => ({ record }),
    () => ({})
);

export const openView = createAction(
    createActionType('OPEN_VIEW' as const),
    () => ({}),
    () => ({})
);

/** 画面を閉じた・切り替えた際のアクション */
export const closeView = createAction(
    createActionType('CLOSE_VIEW' as const),
    () => ({}),
    () => ({})
);

export const page = createAction(
    createActionType('PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

export const reset = createAction(
    createActionType('RESET' as const),
    () => ({}),
    () => ({})
);

export const resetControllableUser = createAction(
    createActionType('RESET_CONTROLLABLE_USER' as const),
    (term_uuid) => ({ term_uuid }),
    () => ({})
);

export const resetUncontrollableUser = createAction(
    createActionType('RESET_UNCONTROLLABLE_USER' as const),
    (term_uuid) => ({ term_uuid }),
    () => ({})
);

export const savePagination = createAction(
    createActionType('SAVE_PAGINATION' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

export const search = createAction(
    createActionType('SEARCH' as const),
    (values, page) => ({ values, page }),
    () => ({})
);

export const selectOrganization = createAction(
    createActionType('SELECT_ORGANIZATION' as const),
    (organization_uuid) => ({ organization_uuid }),
    () => ({})
);

export const selectRows = createAction(
    createActionType('SELECT_ROWS' as const),
    (selectedRows) => ({ selectedRows }),
    () => ({})
);

export const selectTerm = createAction(
    createActionType('SELECT_TERM' as const),
    (term_uuid) => ({ term_uuid }),
    () => ({})
);

type MultipleUpdateParam = Http.Connection.Request.Parameter.User.MultipleUpdateFlag;
/**
 * クラス/名前リセットを実行する
 */
export const submitMultipleReset = createAction(
    createActionType('SUBMIT_MULTIPLE_RESET' as const),
    (data: MultipleUpdateParam) => ({ data }),
    () => ({})
);

/**
 * "選択されているすべての行" から, 今必要な行を取得する
 */
export const pullSelectedRows = createAction(
    createActionType('PULL_SELECTED_ROWS' as const),
    (displayedRowUuids: string[]) => ({ displayedRowUuids }),
    () => ({})
);

/**
 * 今選択している行を "選択されているすべての行" に反映する
 */
export const pushSelectedRows = createAction(
    createActionType('PUSH_SELECTED_ROWS' as const),
    () => ({}),
    () => ({})
);

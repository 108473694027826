import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/EDIT_USER';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

type OprnType = 'ADD'|'REMOVE'|'INITIALIZE'|'REPLACE';


/**
 * 編集モーダルを開いたときに呼び出す。
 * */
export const openView = createAction(
    createActionType('OPEN_VIEW' as const),
    () => ({}),
    () => ({})
);

/**
 * 選択した学校を保存する。
 * それにともなって、前回の学校のキャッシュを削除する。
 * */
export const selectOrganization = createAction(
    createActionType('SELECT_ORGANIZATION' as const),
    (organization_uuid) => ({organization_uuid}),
    () => ({}),
);


/**
 * 選択中の年度リストを更新する。
 * 学校の有無とキャッシュを考慮し、年度でしぼりこまれたクラスリストの取得リクエストを飛ばす。
 * */
export const selectTerms = createAction(
    createActionType('SELECT_TERMS' as const),
    /***
     * @param operationType
     * @param index 'ADD'の場合、undefined
     * @param term_uuid  'ADD'、'REMOVE' の場合、undefined
     */
    (operationType: OprnType, index: number | undefined, term_uuid: string | undefined) => ({
        operationType,
        index,
        term_uuid
    }),
    () => ({}),
);

/**
 * プルダウン用に役割を検索する。
 * */
export const searchRole = createAction(
    createActionType('SEARCH_ROLE' as const),
    (uuid) => ({uuid}),
    () => ({})
);

/**
 * 編集モーダル上にある、クラスリストや役割リストなどのキャッシュを削除する。
 * */
export const resetCache = createAction(
    createActionType('RESET_CACHE' as const),
    () => ({}),
    () => ({})
);

/**
 * 作成、編集問わず、編集モーダルの内容をリクエストを送信する。
 * */
export const submit = createAction(
    createActionType('SUBMIT' as const),
    (values) => ({values}),
    () => ({}),
);

/**
 * ローカルかどうかをStoreに保存する。
 * パスワードをリセット時にローカルの情報が’必要なためStoreに入れておく。
 * */
export const setIsLocal = createAction(
    createActionType('SET_IS_LOCAL' as const),
    (isLocal) => ({isLocal}),
    () => ({}),
);

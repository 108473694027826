import {
    DatePicker,
    FormInstance,
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { RangeValue } from 'rc-picker/lib/interface';
import isTouchDevice from 'is-touch-device';
const { RangePicker } = DatePicker;

type Props = {
    form: FormInstance<any>
} & RangePickerProps;

const CustomRangePicker = (props: Props) => {
    const {
        onChange: receivedOnChange,
        form,
        ...restProps
    } = props;

    const onChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
        receivedOnChange && receivedOnChange(dates, dateStrings);

        form.setFieldsValue({
            [dateStrings[0]]: dates && dates[0],
            [dateStrings[1]]: dates && dates[1],
        });
    }

    return (
        <RangePicker
            {...restProps}
            inputReadOnly={isTouchDevice()}
            placeholder={['開始日時', '終了日時']}
            onChange={onChange}
        />
    );
}

export default CustomRangePicker;
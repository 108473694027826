import * as React from 'react';
import {
    Card,
    Skeleton,
    Typography,
} from 'antd';
import classNames from 'classnames';
const { Text, Title } = Typography;

interface Props {
    appName: string,
    className?: string,
    current: string,
    mean: number,
    style?: React.CSSProperties,
    term: string,
}

const SummaryCard: React.VFC<Props> = props => {
    const { appName, className, current, mean, style, term } = props;

    return (
        <Card
            className={classNames(
                'summary-average',
                className
            )}
            style={style}
            title={
                term !== undefined ?
                    <div className='summary-average-title'>
                        <Text className='summary-average-title-term'>
                            {term}
                        </Text>
                        <Title level={4}  className='summary-average-title-app'>
                            {appName}
                        </Title>
                    </div> :
                    <Skeleton active title={false} paragraph={{rows:2, width:['50%', '100%']}} />
            }
        >
            <Text className='summary-average-contents-prefix'>
                平均利用回数
            </Text>
            <br />
            {
                mean !== undefined ?
                <div className='summary-average-contents'>
                    <div className='summary-average-contents-count'>
                        <Text className='summary-average-contents-count-number'>
                            {mean < 1 ? 1 : Math.round(mean)}
                        </Text>
                        <Text className='summary-average-contents-count-unit'>
                            {mean < 1 ? '回未満' : '回'}
                        </Text>
                    </div>
                    <Text className='summary-average-contents-at_moment'>
                        {current}
                    </Text>
                </div> :
                <div style={{marginTop: '24px'}}>
                    <Skeleton active title={false} paragraph={{rows:2, width:'100%'}} />
                </div>
            }
        </Card>
    )
}

export default SummaryCard;

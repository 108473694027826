import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Col,
    Popover,
    Row,
    Tag,
} from 'antd';
import {
    BarChartOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
} from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { getMoment } from 'constants/GlobalConfig';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import AppGroupModal from './Modals/AppGroupModal';
import AppGroupsOrderModal from './Modals/AppGroupsOrderModal';
import { useSource } from './SourceProvider';
import AppsList from './AppsList';
import AppsSearch from '../AppsSearch';
import { AppGroupStatus } from '../AppGroupStatus';
const moment = getMoment();

const APP_GROUP_MODAL_TYPE = {
    create: 'create',
    update: 'update',
};

const AppGroupsList = () => {
    const [source, setSource] = useSource();
    const [isOpenAppGroupModal, setIsOpenAppGroupModal] = useState(false);
    const [isOpenAppGroupsOrderModal, setIsOpenAppGroupsOrderModal] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [groupRecord, setGroupRecord] = useState(null);
    const [appGroupModalType, setAppGroupModalType] = useState(undefined);
    const [editAppGroupUuid, setEditAppGroupUuid] = useState(undefined);
    const [deepLinkingCategoryUuid, setDeepLinkingCategoryUuid] = useState();

    const isFirstRender = useRef(true);
    const dispatch = useDispatch();
    const handlePageChange = (page, pageSize) => {
        setSource({
            ...source,
            appCategories: {
                ...source.appCategories,
                data: { ...source.appCategories.data, current: page, pageSize }
            }
        })
    };
    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();

    const appsControlSaveConnection = useAppSelector(state => state.appsControlSaveConnection);

    const pagination = {
        showTotal: (total, range) => (`全${total}件中 ${range[0]}-${range[1]} 件`),
        position: ['topRight', 'bottomRight'],
        pageSize: source.appCategories.data.pageSize,
        current: source.appCategories.data.current,
        total: source.appCategories.data.itemCount,
        showSizeChanger: false, // 明示的にfalse
        onChange: handlePageChange,
    };

    const changeLocalStorage = useCallback((event) => {
        if (!deepLinkingCategoryUuid) return;

        if (event.storageArea.getItem('controlAppDeepLinking') === 'true') {
            setSource(source => ({ ...source, appCategory: { ...source.appCategory, loading: true } }));
            dispatch(Actions.http.connection.apps.controlSave());
        }
        // 初回表示
    }, [deepLinkingCategoryUuid]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('storage', changeLocalStorage);
        return () => {
            window.removeEventListener('storage', changeLocalStorage);
        };
    }, [changeLocalStorage]);

    useEffect(() => {
        if (appsControlSaveConnection.meta.status !== Actions.statusEnum.SUCCESS) return;

        if (localStorage.getItem('controlAppDeepLinking') === 'true') {
            localStorage.removeItem('controlAppDeepLinking');
            setSource(source => ({ ...source, appCategory: { ...source.appCategory, loading: false } }));
            setDeepLinkingCategoryUuid(undefined);
            dispatch(Actions.http.connection.apps.category.controlView(deepLinkingCategoryUuid, deepLinkingCategoryUuid));
        }
        // storeのみ監視したいため
    }, [appsControlSaveConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    const handleCreateClick = () => {
        setAppGroupModalType(APP_GROUP_MODAL_TYPE.create);
        setIsOpenAppGroupModal(true);
        dispatch(Actions.http.connection.apps.template.searchForAdmin({ use_deep_linking: 1, page_size: 500 }, 1));
    };

    const handleEditClick = (record) => {
        setAppGroupModalType(APP_GROUP_MODAL_TYPE.update);
        setIsOpenAppGroupModal(true);
        setEditAppGroupUuid(record.uuid);
        dispatch(Actions.http.connection.apps.template.searchForAdmin({ use_deep_linking: 1, page_size: 500 }, 1));
        dispatch(Actions.http.connection.apps.category.controlView(record.uuid, record.uuid));
    };

    const handleDeleteClick = (uuid) => {
        dispatch(Actions.http.connection.apps.category.controlDelete(uuid));
    };

    const handleStatusClick = (record) => {
        setGroupRecord(source.appCategoryMap[record.uuid] ?
            source.appCategoryMap[record.uuid].item :
            record
        );
        setIsStatusOpen(true);
    };

    const handleEditSubmitted = () => {
        setIsOpenAppGroupModal(false);
        setEditAppGroupUuid(undefined);
    };

    const handleEditCancel = () => {
        setIsOpenAppGroupModal(false);
        setEditAppGroupUuid(undefined);
    };

    const handleEditOrderSubmitted = () => setIsOpenAppGroupsOrderModal(false);
    const handleEditOrderCancel = () => setIsOpenAppGroupsOrderModal(false);


    const handleStatusCancel = () => {
        setIsStatusOpen(false);
    };

    const columns = [
        {
            title: <span>教材・アプリグループ名称<br />（小学校３年生以上）</span>,
            dataIndex: 'title',
            key: 'title',
            width: 250,
        },
        {
            title: <span>教材・アプリグループ名称<br />（小学校１・２年生）</span>,
            dataIndex: 'titleForLower',
            key: 'titleForLower',
            width: 250,
        },
        {
            title: '概要',
            dataIndex: 'description',
            key: 'description',
            width: 190,
        },
        {
            title: '対象学校',
            dataIndex: 'targetOrganizations',
            key: 'targetOrganizations',
            width: 190,
            render: (targetOrganizations) => (
                <TargetOrganizations targetOrganizations={targetOrganizations} />
            ),
        },
        {
            title: '公開対象の権限',
            dataIndex: 'targetPermissions',
            key: 'rotargetPermissionsles',
            width: 190,
            render: (_, record) => (
                record.targetPermissions?.map((role, i) => {
                    return <Tag key={i}>{role.name}</Tag>
                })
            ),
        },
        {
            title: '公開期間',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 180,
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.publishStartAt).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(record.publishEndAt).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            )
        },
        {
            title: '作成者/更新者',
            width: 150,
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                return (
                    <>
                        <span>{record.created_by.display_name}</span>
                        <br />
                        <span>{record.updated_by.display_name}</span>
                    </>
                )
            }
        },
        {
            title: '作成時間/更新時間',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 160,
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.createdAt).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updatedAt).format('YYYY/MM/DD HH:mm')}</span>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 120,
            fixed: 'right',
            render: (text, record, index) => (
                <Row type='flex' justify='space-around' align='middle'>
                    <Col>
                        <CircleButton
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(record)}
                            size='small'
                            tooltipProps={{ placement: 'topLeft', title: hasTouchScreen ? undefined : '編集' }}
                        />
                    </Col>
                    <Col>
                        <CircleButton
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '削除' }}
                            popconfirmProps={{
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>アプリグループを削除します。よろしいですか。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                ),
                            }}
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Col>
                    {record.useAgs &&
                        <Col>
                            <CircleButton
                                tooltipProps={{ title: hasTouchScreen ? undefined : '対象者と実施結果を確認' }}
                                size='small'
                                icon={<BarChartOutlined />}
                                onClick={() => handleStatusClick(record)}
                            />
                        </Col>
                    }
                </Row>
            )
        },
    ];

    const expandedRowRender = (appCategory) => {
        const categoryMapData = source.appCategoryMap[appCategory.uuid];
        const appListLoading = categoryMapData?.loading;
        const data = categoryMapData?.item ?? { applications: [] };

        return (
            <AppsList
                appCategory={data}
                loading={appListLoading}
                onClickDeepLinkingCreate={setDeepLinkingCategoryUuid}
            />
        );
    };

    const setSearch = (values) => {
        setSource({
            ...source,
            appCategories: {
                ...source.appCategories,
                data: {
                    ...source.appCategories.data,
                    search: {
                        ...values,
                        publish_start_at: values.publish_start_at ? moment(values.publish_start_at).unix() : null,
                        publish_end_at: values.publish_end_at ? moment(values.publish_end_at).unix() : null,
                    }
                },
            },
        });
    };
    const onExpand = (expanded, record) => {
        if (expanded) {
            dispatch(Actions.http.connection.apps.category.controlView(record.uuid, record.uuid));
        }
    };

    const isLoadingModal = !!source.appCategoryMap[editAppGroupUuid]?.loading || source.appTemplatesForAdmin.loading;

    return (
        <div className='page-apps container'>
            <AppsSearch loading={source.appCategories.loading} setSearch={setSearch} />
            <div className='flex-right-container gutter-bottom'>
                <Button
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                columns={columns}
                emptyDescription='配信されている教材・アプリグループはありません'
                dataSource={source.appCategories.data.items}
                expandable={{ expandedRowRender, onExpand }}
                size='small'
                loading={source.appCategories.loading || source.application.loading || source.appCategory.loading}
                pagination={pagination}
                scroll={{ x: 1430 }}
            />

            <AppGroupModal
                visible={isOpenAppGroupModal}
                isCreate={appGroupModalType === APP_GROUP_MODAL_TYPE.create}
                initialValue={source.appCategoryMap[editAppGroupUuid]?.item}
                onOk={handleEditSubmitted}
                onCancel={handleEditCancel}
                order={source.appCategories.data.itemCount + 1}
                loading={isLoadingModal}
            />

            <AppGroupsOrderModal
                onOk={handleEditOrderSubmitted}
                onCancel={handleEditOrderCancel}
                visible={isOpenAppGroupsOrderModal}
            />

            <AppGroupStatus
                category={groupRecord}
                isOnlyAgsApplication={groupRecord && groupRecord.use_deep_linking}
                visible={isStatusOpen}
                onCancel={handleStatusCancel}
            />
        </div>
    );
};


const TargetOrganizations = (props) => {
    const { targetOrganizations } = props;

    if (targetOrganizations.length <= 5) { // 5件以内の場合は通常表示
        return targetOrganizations?.map((organization, i) => {
            return i < 5 ? <span key={i} className={'target-school-name-tag'}>{organization.name}</span> : null;
        });
    } else { // 5件以上の場合は、3件を通常表示、残りをTooltip表示
        const organizations = targetOrganizations?.map((organization, i) => {
            return i < 3 ? <span key={i} className={'target-school-name-tag'}>{organization.name}</span> : null;
        });
        const organizationsElse = targetOrganizations?.map((organization, i) => {
            return i >= 3 ? <Tag key={i}>{organization.name}</Tag> : null;
        });
        organizations.push(
            <Popover
                trigger='click'
                key='else'
                content={
                    <div style={{ width: '500px' }}>{organizationsElse}</div>
                }
            >
                <Button type='link' size='small'>
                    その他(全{targetOrganizations.length - 3}学校)
                </Button>
            </Popover>
        )
        return organizations;
    }
};

export default AppGroupsList;

import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseTable } from 'components/modules/BaseTable';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import { useUrlParameters } from 'constants/CustomHooks/useUrlParameters';
import { PageSizeOptions } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import { ListContext } from '../../index';
import EditClassModal from '../Common/EditClassModal';
import { SearchRuleContext } from '.';
import { createColumns } from '../Common/createColumns';
const { statusEnum } = Actions;


const ClassesListTable = ({
    onSearch,
}) => {
    const dispatch = useDispatch();
    const [search] = useContext(SearchRuleContext);
    const [list] = useContext(ListContext);
    const urlParameters = useUrlParameters();
    const [isDeleteStarted, setIsDeleteStarted] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(false);
    const { hasTouchScreen } = useHasTouchScreen();
    const classSearchConnection = useSelector(state => state.classSearchConnection);
    const classDeleteConnection = useSelector(state => state.classDeleteConnection);
    const classViewConnection = useSelector(state => state.classViewConnection);
    const onChangePagination = useCallback((page, page_size) => {
        setPagination(pagination => ({
            ...pagination,
            current: page,
            pageSize: page_size,
        }));
        onSearch({
            ...search,
            current_page: page,
            page_size: page_size,
        });
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps
    const [pagination, setPagination] = useState({
        onChange: onChangePagination,
        pageSize: 50,
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: list.classListItemCount,
    });

    useEffect(() => {//クラスリスト取得時
        setPagination(pagination => ({
            ...pagination,
            current: search.current_page,
            total: list.classListItemCount,
        }));
    }, [list.classList, list.classListItemCount]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// onChangePaginationが再作成されたらpaginationを更新
        setPagination((pagination) => ({ ...pagination, onChange: onChangePagination }))
    }, [onChangePagination]);// eslint-disable-line react-hooks/exhaustive-deps 

    useEffect(() => {// 検索処理時のloading
        setLoading(classSearchConnection.meta.fetch);
    }, [classSearchConnection]);

    useEffect(() => {// 削除処理
        if (!isDeleteStarted) return;

        if (classDeleteConnection.meta.status === statusEnum.SUCCESS) {
            setIsDeleteStarted(false);
            onSearch({ term_uuid: search.term_uuid, current_page: 1 });
        }
    }, [classDeleteConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// uuid指定で直接開く機能？
        const uuid = urlParameters['uuid'];
        if (!uuid) return;

        const { meta: { status } } = classViewConnection;

        if (status === '' || status === Actions.statusEnum.LOADING) {
            dispatch(Actions.http.connection.classes.view(uuid));
        }
        // urlParametersのみ監視したいため
    }, [dispatch, urlParameters]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// uuidでviewを取得できた場合開く
        const { meta, payload } = classViewConnection;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                handleEditClick(payload.result);
                break;
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
            default:
                break;
        }
    }, [classViewConnection]);

    const handleEditClick = (record) => {
        setRecord(record);
        setIsEditOpen(true);
    };
    const handleEditCancel = () => setIsEditOpen(false);

    const handleEditSubmitted = () => {
        setIsEditOpen(false);
        onSearch({ term_uuid: search.term_uuid, current_page: search.current_page });
    };

    const handleDeleteClick = (id) => {
        dispatch(Actions.http.connection.classes.delete(id));
        setIsDeleteStarted(true);
    };

    // クラスリストのcolumns作成
    const columns = createColumns(hasTouchScreen, handleEditClick, handleDeleteClick, true);

    return (
        <div>
            <BaseTable
                columns={columns} // 該当年度のクラスリストが無い場合レイアウトが崩れる対策
                dataSource={list.classList}
                emptyDescription='クラスがありません'
                loading={loading}
                pagination={pagination}
                style={!list.classListItemCount ? { paddingTop: '55px' } : {}}
            />

            <EditClassModal
                onCancel={handleEditCancel}
                onOk={handleEditSubmitted}
                record={record}
                visible={isEditOpen}
            />
        </div>
    );
};

export default ClassesListTable;

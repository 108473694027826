import React from 'react'
import { Tag } from 'antd';

interface Props {
    status: App.History['grading_progress'] | undefined;
}

/***
 * 採点前 or 採点完了を きれいなタグとして表示する
 * @param props
 * @constructor
 */
export const ScoringStatusTag: React.VFC<Props> = (props) => {
    switch (props.status) {
        case 'PendingManual':
            return (
                <Tag color='#AAAAAA' style={{width: '75px', textAlign: 'center', borderRadius: '5px'}}>
                    採点待ち
                </Tag>
            );
        case 'FullyGraded':
            return (
                <Tag color='#118584' style={{width: '75px', textAlign: 'center', borderRadius: '5px'}}>
                    採点完了
                </Tag>
            );
        default:
            return null;
    }
}

import {
    ALL_TERM_UUID,
    checkEnvironmentForControllable,
    haveOrganizationPermission,
} from 'constants/GlobalConfig';
import { selectors } from 'flex/Selectors';
import { isContainsJobType } from 'components/modules/Job/Background/BackgroundJobUtils';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import { createSelector } from 'reselect';

const userManagementTabLoadingSelector = createSelector(
    [selectors.roleSearchConnection, selectors.classSearchConnection, selectors.usersSearchConnection, selectors.organizationSearchConnection,
    selectors.termSearchConnection, selectors.usersExportConnection, selectors.usersImportConnection],
    (roleSearchConnection, classSearchConnection, usersSearchConnection, organizationSearch, termSearch, usersExportConnection, usersImportConnection) => (
        {
            usersLoading: usersSearchConnection.meta.fetch || termSearch.meta.fetch,
            optionalLoading: classSearchConnection.meta.fetch || roleSearchConnection.meta.fetch || termSearch.meta.fetch,
            organizationSelectLoading: termSearch.meta.fetch || organizationSearch.meta.fetch,
            termSelectLoading: termSearch.meta.fetch,
            csvExportLoading: usersExportConnection.meta.fetch,
            csvImportLoading: usersImportConnection.meta.fetch
        }
    )
);

// ユーザ管理タブのローディング (他のselectorで利用するため外部で定義している)
export const loading = userManagementTabLoadingSelector;

export const disabled = createSelector(
    [
        selectors.isControllableUser,
        selectors.userManagementTabSelectOrganization,
        selectors.userManagementTabSelectTerm,
        userManagementTabLoadingSelector,
        selectors.userManagementTab,
        selectors.jobInfoMap,
        selectors.usersExportConnection,
        selectors.usersImportConnection,
    ],
    (
        isControllable,
        selectOrganization,
        selectTerm,
        { optionalLoading, organizationSelectLoading, termSelectLoading },
        tabSelector,
        jobInfoMap,
        usersExportConnection,
        usersImportConnection,
    ) => (
        {
            classSelectDisabled: (isControllable && !selectOrganization) || optionalLoading || (selectTerm === ALL_TERM_UUID),
            roleSelectDisabled: (isControllable && !selectOrganization) || optionalLoading,
            statusSelectDisabled: optionalLoading,
            csvExportDisabled: tabSelector.usersListItemCount === 0
                || usersExportConnection.meta.fetch
                || isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.USER_CSV_EXPORT),
            csvImportDisabled: usersImportConnection.meta.fetch
                || isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.USER_CSV_IMPORT)
                || isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.DELETE_USER_FROM_CSV_IMPORT),
            organizationSelectDisabled: organizationSelectLoading,
            termSelectDisabled: termSelectLoading,
        }
    )
);

export const visible = createSelector(
    [selectors.user, selectors.userManagementTab, selectors.tenant],
    (user, tabSelector, tenant) => {
        const selectedRows = (tabSelector.selectedRows ?? []).concat(tabSelector.currentSelectedRows);
        return (
            {
                visibleResetPasswordButton: selectedRows && selectedRows.length !== 0,
                visibleSyncUserButton: checkEnvironmentForControllable() || haveOrganizationPermission(user) ? tenant?.type !== '1' : false
            }
        )
    }
);

export const csvExportDisabledMsg = createSelector([selectors.jobInfoMap], (jobInfoMap) => (
    isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.USER_CSV_EXPORT)
        ? <p>CSVエクスポートはすでに実行済みです。<br />進捗状況については、画面左下からご確認ください</p >
        : ''
));

export const csvImportDisabledMsg = createSelector([selectors.jobInfoMap], (jobInfoMap) => (
    isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.USER_CSV_IMPORT) || isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.DELETE_USER_FROM_CSV_IMPORT)
        ? <p>CSVインポートはすでに実行済みです。<br />進捗状況については、画面左下からご確認ください</p >
        : ''
));

export const multipleUpdateFlag = createSelector(
    [selectors.usersMultipleUpdateFlagConnection],
    (usersMultipleUpdateFlagConnection) => usersMultipleUpdateFlagConnection,
);
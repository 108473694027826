import { handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const controlEverydayNoteCreateConnection = handleAction(
    Actions.http.connection.everydayNote.control.create,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteDeleteConnection = handleAction(
    Actions.http.connection.everydayNote.control.delete,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteResultCsvConnection = handleAction(
    Actions.http.connection.everydayNote.control.resultCsv,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteResultTotalConnection = handleAction(
    Actions.http.connection.everydayNote.control.resultTotal,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteResultSubItemsConnection = handleAction(
    Actions.http.connection.everydayNote.control.resultSubItems,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteResultSubTotalConnection = handleAction(
    Actions.http.connection.everydayNote.control.resultSubTotal,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteSearchConnection = handleAction(
    Actions.http.connection.everydayNote.control.search,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteUpdateConnection = handleAction(
    Actions.http.connection.everydayNote.control.update,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteViewConnection = handleAction(
    Actions.http.connection.everydayNote.control.view,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteTargetOrganizationConnection = handleAction(
    Actions.http.connection.everydayNote.control.targetOrganization,
    defaultReducer,
    defaultState
);

export const controlEverydayNoteTargetSchoolClassConnection = handleAction(
    Actions.http.connection.everydayNote.control.targetSchoolClass,
    defaultReducer,
    defaultState
);
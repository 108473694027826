import * as Actions from '../../../Actions';
import {convertQuestionnaireTabSearchToSubmittable} from './utils';
import {isEqualObject} from '../../../../constants/GlobalConfig'

export const questionnaireTabViewLogic = (store, next, action) => {
    const tabState = store.getState()['questionnaireTab'];
    const pagination = tabState['pagination'];
    const search = tabState['search'];
    const isControllableUser = store.getState()['isControllableUser'];

    switch (action.type) {
        case Actions.view.viewLogic.questionnaireTab.prepareView.toString(): {

            const searchAction = isControllableUser.payload ?
                Actions.http.connection.questionnaireCategory.controlSearch :
                Actions.http.connection.questionnaireCategory.search;

            next(searchAction(
                { page_size: 500 },
                1,
                'questionnaireTab'
            ));

            next({
                ...tabState,
                pagination: {
                    ...pagination,
                    current: 1,
                    page_size: 50,
                },
                type: 'questionnaireTab'
            });

            store.dispatch(Actions.view.viewLogic.questionnaireTab.submitSearch());
            break;
        }
        case Actions.view.viewLogic.questionnaireTab.submitSearch.toString(): {
            next(action);

            const values = convertQuestionnaireTabSearchToSubmittable(action.payload.values)
            let page = pagination.current

            // これまでと異なる検索内容、ページは1
            if(!isEqualObject(search,values)){
                page = 1
                next(Actions.view.viewLogic.questionnaireTab.savePage(1, pagination.pageSize))
            }

            const searchAction = isControllableUser.payload ?
                Actions.http.connection.questionnaires.controlSearch :
                Actions.http.connection.questionnaires.search;

            next(searchAction(
                {
                    ...values,
                    page_size: pagination.pageSize
                },
                page,
                'questionnaireTab'
            ));
            break;
        }
        case Actions.view.viewLogic.questionnaireTab.submitPage.toString(): {
            next(Actions.view.viewLogic.questionnaireTab.savePage(action.payload.page,  action.payload.page_size))

            const searchAction = isControllableUser.payload ?
                Actions.http.connection.questionnaires.controlSearch :
                Actions.http.connection.questionnaires.search;

            next(searchAction(
                {
                    ...search,
                    page_size: action.payload.page_size || 50
                },
                action.payload.page,
                'questionnaireTab'
            ));
            break;
        }
        case Actions.view.viewLogic.questionnaireTab.submitDelete.toString():
            const deleteAction = isControllableUser.payload ?
                Actions.http.connection.questionnaires.controlDelete :
                Actions.http.connection.questionnaires.delete;

            next(deleteAction(action.payload.uuid));
            break;
        case Actions.view.viewLogic.questionnaireTab.submitEdit.toString():
            next(action)
            store.dispatch(Actions.view.viewLogic.questionnaireTab.submitSearch(search))
            break
        default:
            next(action)
            break;
    }
}

import { useEffect, useState, createContext } from 'react';

import { useAppSelector } from 'flex/utils';

import AppsEffectComponent from './AppsEffectComponent';
import AppsList from './AppsList';
import ControlAppContents from './ControlAppContents';
import 'styles/pages/apps.scss';


const initSource = {
    appCategoryMap: {}, //appCategoryMapは次の内容を保持する {[uuid]:{loading:boolean, item:AppCategory}}
    application: null,
    appsList: [],
    appsListItemCount: 0,
    loading: false,
    pagination: {
        current: 1,
        pageSize: 20,
    },
    search: {
        publish_end_at: undefined,
        publish_start_at: undefined,
        title: undefined,
    },
};

const adminInitSource = {
    ...initSource,
    appsEdit: { loading: false },
    categoryEdit: { loading: false },
    categorySearch: { loading: false },
};

export const SourceContext = createContext<[typeof initSource, React.Dispatch<React.SetStateAction<typeof initSource>>]>([
    {
        ...initSource,
    },
    (() => undefined) as React.Dispatch<React.SetStateAction<typeof initSource>>,
]);

const Apps = () => {
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const [source, setSource] = useState(isControllableUser ? initSource : adminInitSource);

    useEffect(() => {
        localStorage.removeItem('appDeepLinking');
    }, []);

    return (
        <SourceContext.Provider value={[source, setSource]}>
            <div className='page-notifications card-container'>
                {isControllableUser ?
                    <ControlAppContents /> :
                    <>
                        <AppsList />
                        <AppsEffectComponent />
                    </>
                }
            </div>
        </SourceContext.Provider>
    );
};

export default Apps;

import { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ColoredDisappointed } from 'assets/icon/ic_disappointed_c.svg';
import { ReactComponent as ColoredGood } from 'assets/icon/ic_good_c.svg';
import { ReactComponent as ColoredHappy } from 'assets/icon/ic_happy_c.svg';
import { ReactComponent as ColoredNeautral } from 'assets/icon/ic_neutral_c.svg';
import { ReactComponent as ColoredSad } from 'assets/icon/ic_sad_c.svg';

const FiveLevelCandidate = forwardRef(({ name, watch }, ref) => {
    const checkedIconValue = watch(name);
    const [hoverIconValue, setHoverIconValue] = useState('0');

    const iconClassNames = ( checkNum, checkedIconValue, hoverIconValue ) => {
        const checkedIconClass = 'w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 rubberBand';
        const hoverIconClass = 'w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14';
        const normalIconClass = 'w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12';
        const baseClass = 'first:fill-current hover:cursor-pointer';

        return(
            classNames(
                `${checkedIconValue === checkNum
                    ? checkedIconClass
                    : hoverIconValue === checkNum
                        ? hoverIconClass
                        : normalIconClass
                }`,
                baseClass
            )
        );
    };

    const iconStyle = ( checkNum, checkedIconValue ) => {
        return({
            fillOpacity: !checkedIconValue
                ? 1
                : checkedIconValue !== checkNum ? 0.5 : 1
        });
    };

    return (
        <div className='grid grid-cols-5 gap-2 h-16 items-center'>
            <label className='mx-auto'>
                <input name={name} className='hidden' type='radio' value={1} ref={ref} />
                <div
                    onMouseEnter={() => setHoverIconValue('1')}
                    onMouseLeave={() => setHoverIconValue('0')}
                >
                    <ColoredDisappointed
                        style={iconStyle( '1', checkedIconValue )}
                        className={iconClassNames( '1', checkedIconValue, hoverIconValue )}
                    />
                </div>
            </label>
            <label className='mx-auto'>
                <input name={name} className='hidden' type='radio' value={2} ref={ref} />
                <div
                    onMouseEnter={() => setHoverIconValue('2')}
                    onMouseLeave={() => setHoverIconValue('0')}
                >
                    <ColoredSad
                        style={iconStyle( '2', checkedIconValue )}
                        className={iconClassNames( '2', checkedIconValue ,hoverIconValue )}
                    />
                </div>
            </label>
            <label className='mx-auto'>
                <input name={name} className='hidden' type='radio' value={3} ref={ref} />
                <div
                    onMouseEnter={() => setHoverIconValue('3')}
                    onMouseLeave={() => setHoverIconValue('0')}
                >
                    <ColoredNeautral
                        style={iconStyle( '3', checkedIconValue )}
                        className={iconClassNames( '3', checkedIconValue, hoverIconValue )}
                    />
                </div>
            </label>
            <label className='mx-auto'>
                <input name={name} className='hidden' type='radio' value={4} ref={ref} />
                <div
                    onMouseEnter={() => setHoverIconValue('4')}
                    onMouseLeave={() => setHoverIconValue('0')}
                >
                    <ColoredGood
                        style={iconStyle( '4', checkedIconValue )}
                        className={iconClassNames( '4', checkedIconValue, hoverIconValue )}
                    />
                </div>
            </label>
            <label className='mx-auto'>
                <input name={name} className='hidden' type='radio' value={5} ref={ref} />
                <div
                    onMouseEnter={() => setHoverIconValue('5')}
                    onMouseLeave={() => setHoverIconValue('0')}
                >
                    <ColoredHappy
                        style={iconStyle('5', checkedIconValue)}
                        className={iconClassNames( '5', checkedIconValue, hoverIconValue )}
                    />
                </div>
            </label>
        </div>
    );
});

FiveLevelCandidate.displayName = 'FiveLevelCandidate';

export default FiveLevelCandidate;
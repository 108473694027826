/* eslint-disable sort-keys */
import React, { useEffect, VFC } from 'react';

import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Space,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

import { getMoment } from 'constants/GlobalConfig';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { SubjectListState } from './hooks/useSubjectList';

const moment = getMoment();

type Props = {
    currentTabKey: TabKeys | ''
    isLoading: boolean
    onSearch: (value: FormValues) => void,
    subjectList: SubjectListState['items']
}

export const SearchForm: VFC<Props> = ({
    currentTabKey,
    isLoading,
    onSearch,
    subjectList,
}) => {
    const [form] = Form.useForm<FormValues>();
    const termType = Form.useWatch('termType', form);

    useEffect(() => {
        form.setFieldsValue(INIT_FORM_VALUES);
    }, [form]);

    const handleReset = () => {
        form.resetFields();
        form.setFieldsValue(INIT_FORM_VALUES);
    };

    const handleChangeDateType = React.useCallback(() => {
        const newDate = moment();
        form.setFieldsValue({ date: newDate });
    }, [form]);

    return (
        <Card className='search-form'>
            <Form form={form} onFinish={onSearch}>
                <Row gutter={[16, 8]}>
                    <Col span={12}>
                        <Form.Item label='表示期間' name='termType' {...TowColFormItem}>
                            <Select onChange={handleChangeDateType}>
                                <Select.Option value='month'>月</Select.Option>
                                <Select.Option value='week'>週</Select.Option>
                                <Select.Option value='day'>日</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name='date' wrapperCol={{ span: 24 }}>
                            <DatePicker
                                allowClear={false}
                                disabledDate={disabledDate}
                                format={formatFuncCreator(termType)}
                                locale={jaJP}
                                picker={termType === 'day' ? 'date' : termType}
                                placeholder='日付を選択してください'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 8]}>
                    <Col span={12}>
                        <Form.Item label='教科' name='subject' {...TowColFormItem}>
                            <Select allowClear loading={isLoading}>
                                {subjectList.map(({ id, name }) => (
                                    <Select.Option key={id} value={id}>{name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>

                        <Form.Item label='平均正答率' name='answerRate' {...TowColFormItem}>
                            <Select allowClear>
                                {[...Array(5)].map((_, i) => {
                                    const lessThan = i === 4 ? '' : '未満';
                                    return (
                                        <Select.Option key={i} value={i}>
                                            {`${i * 20} ~ ${(i + 1) * 20}%${lessThan}`}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item label='タイトル' name='title'  {...OneColFormItem}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                {/* 全体管理では表示する( currentTabKey === '' )のときにも表示されるように */}
                {currentTabKey !== 'board' && (
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <Form.Item name='isOnlyDeliverySelf' noStyle valuePropName='checked'>
                                <Checkbox>
                                    自分が配信したテストグループまたはテストのみ表示する
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                <Row gutter={24} style={{ justifyContent: 'end' }}>
                    <Space wrap>
                        <Button htmlType='button' onClick={handleReset}>
                            リセット
                        </Button>
                        <Button htmlType='submit' type='primary'>
                            検索
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Card>
    );
};

const TowColFormItem = {
    labelCol: {
        sm: { span: 6 },
        md: { span: 6 },
        xl: { span: 6 },
        xxl: { span: 4 },
    },
    wrapperCol: {
        sm: { span: 18 },
        md: { span: 18 },
        xl: { span: 18 },
        xxl: { span: 20 },
    },
};

const OneColFormItem = {
    labelCol: {
        sm: { span: 3 },
        md: { span: 3 },
        xl: { span: 3 },
        xxl: { span: 2 },
    },
    wrapperCol: {
        sm: { span: 21 },
        md: { span: 21 },
        xl: { span: 21 },
        xxl: { span: 22 },
    },
};


export const TERM_TYPE = {
    MONTH: 'month' as const,
    WEEK: 'week' as const,
    DATE: 'day' as const,
};

export type TermTypes = typeof TERM_TYPE[keyof typeof TERM_TYPE];

export type FormValues = {
    answerRate: string | undefined,
    date: moment.Moment
    isOnlyDeliverySelf: boolean,
    subject: string | undefined,
    termType: TermTypes,
    title: string | undefined,
};

export const INIT_FORM_VALUES: FormValues = {
    answerRate: undefined,
    date: moment().startOf('month'),
    isOnlyDeliverySelf: false,
    subject: undefined,
    termType: TERM_TYPE.MONTH,
    title: undefined,
};


const formatFuncCreator = (termType: TermTypes) => (value: moment.Moment) => {
    switch (termType) {
        case TERM_TYPE.MONTH:
            return value.format('YYYY年MM月');
        case TERM_TYPE.WEEK: {
            const start = moment(value).startOf(TERM_TYPE.WEEK).format('YYYY年MM月DD日');
            const end = moment(value).endOf(TERM_TYPE.WEEK).format('YYYY年MM月DD日');
            return `${start} ~ ${end}`;
        }
        case TERM_TYPE.DATE:
            return value.format('YYYY年MM月DD日');
        default:
            return '';
    }
};

const disabledDate = (current: moment.Moment) => {
    return current <= moment('2024-01-01').subtract(1, 'days').endOf('day') || moment().endOf('day') <= current;
};
import { Select, Tag } from 'antd';

import { getMoment } from 'constants/GlobalConfig';

import { ActionLogDetailQueryBuilder } from './ActionLogDetailQueryBuilder';
import { ActionLogTotalQueryBuilder } from './ActionLogTotalQueryBuilder';
import { Source, TARGET_TO_TEXT, TERM_TYPE } from './UsageDataProvider';
const { Option } = Select;
const moment = getMoment();

const TIME_KEY_FORMAT_TYPE_MONTH = 'M月';
const TIME_KEY_FORMAT_TYPE_DAY = 'MM/DD (ddd)';
const TIME_KEY_FORMAT_TYPE_TWO_ROW_DAY = 'MM/DD(ddd)';

const BACKEND_TIME_KEY_FORMAT_TYPE_MONTH = 'M月';
const BACKEND_TIME_KEY_FORMAT_TYPE_DAY = 'YYYY-MM-DD';

type TotalQueryParameters = {
    formValues?: {
        edumall: {
            text: string;
            value: string;
        },
        responseAll: number,
        target: string,
        targetDetails: { app: string, group: string }[],
        term: moment.Moment,
        termType: typeof TERM_TYPE[keyof typeof TERM_TYPE],
    },
    hierarchy?: Source['hierarchy'],
    source: Source,
}

export const createActionLogTotalQuery = ({ source, formValues, hierarchy }: TotalQueryParameters) => {

    const builder = new ActionLogTotalQueryBuilder();
    const resource = formValues ?? source;

    builder.term = resource.term;
    builder.termType = resource.termType;
    builder.hierarchy = hierarchy ?? source.hierarchy;
    builder.target = resource.target;
    builder.targetDetails = resource.targetDetails;
    builder.edumall = resource.edumall;
    builder.organizationAppLists = source.organizationAppLists;
    builder.isControllableUser = source.isControllableUser;
    builder.responseAll = resource.responseAll;

    return builder.getResult();
};

type DetailQueryParameters = {
    formValues?: {
        edumall: {
            text: string;
            value: string;
        },
        responseAll: number,
        target: string,
        targetDetails: { app: string, group: string }[],
        term: moment.Moment,
        termType: typeof TERM_TYPE[keyof typeof TERM_TYPE],
    },
    hierarchy?: Source['hierarchy'],
    page: number,
    pageSize: number,
    source: Source,
}

export const createActionLogDetailQuery = ({ source, formValues, hierarchy, pageSize, page }: DetailQueryParameters) => {
    const builder = new ActionLogDetailQueryBuilder();
    const resource = formValues ? formValues : source;

    builder.term = resource.term;
    builder.termType = resource.termType;
    builder.target = resource.target;
    builder.targetDetails = resource.targetDetails;
    builder.edumall = resource.edumall;
    builder.hierarchy = hierarchy ?? source.hierarchy;
    builder.organizationAppLists = source.organizationAppLists;
    builder.pageSize = pageSize;
    builder.page = page;

    return builder.getResult();
};

export const createTimeKeys = (term: moment.Moment, termType: typeof TERM_TYPE[keyof typeof TERM_TYPE]) => {
    const start = term.startOf(termType);

    switch (termType) {
        case TERM_TYPE.YEAR:
            return [...Array(12)].map((_, i) => [
                // 4月始まりにするための+3
                moment(start).add(i + 3, 'month').format(BACKEND_TIME_KEY_FORMAT_TYPE_MONTH),
                moment(start).add(i + 3, 'month').format(TIME_KEY_FORMAT_TYPE_MONTH),
            ]);
        case TERM_TYPE.MONTH:
            return [...Array(moment(start).daysInMonth())].map((_, i) => [
                moment(start).add(i, 'days').format(BACKEND_TIME_KEY_FORMAT_TYPE_DAY),
                moment(start).add(i, 'days').format(TIME_KEY_FORMAT_TYPE_TWO_ROW_DAY),
            ]);
        case TERM_TYPE.WEEK:
            return [...Array(7)].map((_, i) => [
                moment(start).add(i, 'days').format(BACKEND_TIME_KEY_FORMAT_TYPE_DAY),
                moment(start).add(i, 'days').format(TIME_KEY_FORMAT_TYPE_DAY),
            ]);
        case TERM_TYPE.DATE:
            return [...Array(12)].map((_, i) =>
                [
                    `${2 * i}-${2 * (i + 1)}時`,
                    `${2 * i}-${2 * (i + 1)}時`,
                ]
            );
        default:
            return [];
    }
};

export const createSummaryProps = (source: Source) => {
    let term = '';
    switch (source.termType) {
        case TERM_TYPE.YEAR:
            term = source.term.format('YYYY年度');
            break;
        case TERM_TYPE.MONTH:
            term = source.term.format('YYYY年MM月');
            break;
        case TERM_TYPE.WEEK: {
            const startDate = moment(source.term).startOf('week');
            const endDate = moment(source.term).endOf('week');
            const termEndFormat = startDate.format('M') === endDate.format('M') ? 'DD日' : 'MM月DD日';

            const termStart = startDate.format('YYYY年MM月DD日');
            const termEnd = endDate.endOf('week').format(termEndFormat);
            term = `${termStart}-${termEnd}`;
            break;
        }
        case TERM_TYPE.DATE:
            term = source.term.format('YYYY年度MM月DD日');
            break;
        default:
            break;
    }

    return {
        appName: TARGET_TO_TEXT[source.target],
        current: moment().format('YYYY年MM月DD日 時点'),
        data: source.summary.data.items,
        loading: source.total.loading,
        mean: source.summary.data.mean,
        term: term,
    };
};

export const createAppSelectOptions = (organizationAppInfoList: Source['organizationAppLists'][string]['list']) => {
    const list = organizationAppInfoList.map(appInfo => {
        const categoryName = appInfo.category.length > 20 ?
            appInfo.category.substring(0, 20) + '...' :
            appInfo.category;
        return (
            <Option key={appInfo.value} value={appInfo.value}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    {/* overflow について、https://qiita.com/teppeita/items/b2df3f73c66883ab49be */}
                    <div style={{ marginRight: '0.5rem', overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }}>{appInfo.title}</div>
                    <Tag color='#EDF2F6' style={{ borderRadius: '5px', color: 'black', textAlign: 'center' }}>
                        {categoryName}
                    </Tag>
                </div>
            </Option>
        );
    });

    if (organizationAppInfoList.length > 0) {
        list.unshift(
            <Option key='all' value='all'>すべて</Option>
        );
    }
    return list;
};

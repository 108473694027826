import { createSelector } from 'reselect';
import { selectors } from 'flex/Selectors';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';

export const disabledCsvExport = createSelector(
    [
        selectors.examCategoryTab,
        selectors.taoExamCategoryDownloadCsvConnection,
        selectors.taoExamCategoryControlDownloadCsvConnection,
        selectors.jobInfoMap,
    ],
    (
        examCategoryTab,
        taoExamCategoryDownloadCsvConnection,
        taoExamCategoryControlDownloadCsvConnection,
        jobInfoMap,
        ) => {
        return taoExamCategoryDownloadCsvConnection.meta.fetch
            || taoExamCategoryControlDownloadCsvConnection.meta.fetch
            || Object.values(jobInfoMap)
                .filter(jobInfo => jobInfo.someUuid === examCategoryTab.modal.categoryRecord?.uuid)
                .some(
                    jobInfo => jobInfo.job.type === BACKGROUND_JOB_TYPE.EXAM_RESULT_CSV_EXPORT
                        || jobInfo.job.type === BACKGROUND_JOB_TYPE.CONTROL_EXAM_RESULT_CSV_EXPORT
                );
    }
);

export const listLoading = createSelector(
    [
        selectors.taoExamCategorySearchConnection,
        selectors.taoExamCategoryDeleteConnection,
        selectors.taoExamCategoryEditConnection,
        selectors.taoExamCategoryControlEditConnection,
        selectors.taoExamCategoryControlDeleteConnection,
        selectors.taoExamCategoryControlSearchConnection
    ],
    (
        taoExamCategorySearchConnection,
        taoExamCategoryDeleteConnection,
        taoExamCategoryEditConnection,
        taoExamCategoryControlEditConnection,
        taoExamCategoryControlDeleteConnection,
        taoExamCategoryControlSearchConnection
    ) =>
        taoExamCategorySearchConnection.meta.fetch ||
        taoExamCategoryDeleteConnection.meta.fetch ||
        taoExamCategoryEditConnection.meta.fetch ||
        taoExamCategoryControlEditConnection.meta.fetch ||
        taoExamCategoryControlDeleteConnection.meta.fetch ||
        taoExamCategoryControlSearchConnection.meta.fetch
);

export const statusListLoading = createSelector(
    [selectors.taoExamCategoryResultConnection, selectors.taoExamCategoryControlResultConnection],
    (taoExamCategoryResultConnection, taoExamCategoryControlResultConnection) =>
        taoExamCategoryResultConnection.meta.fetch || taoExamCategoryControlResultConnection.meta.fetch
);

export const statusListOptionalLoading = createSelector(
    [selectors.roleSearchConnection, selectors.classSearchConnection, selectors.taoExamCategoryResultConnection, selectors.taoExamCategoryControlResultConnection],
    (roleSearchConnection, classSearchConnection, taoExamCategoryResultConnection, taoExamCategoryControlResultConnection) =>
        roleSearchConnection.meta.fetch || classSearchConnection.meta.fetch || taoExamCategoryResultConnection.meta.fetch || taoExamCategoryControlResultConnection.meta.fetch
);

export const iframeFooterButtonsLoading = createSelector(
    [selectors.taoExamEditConnection],
    (taoExamEditConnection) => taoExamEditConnection.meta.fetch
);

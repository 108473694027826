import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { additionDisplayName, getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { FormValues } from '../SearchForm';

const moment = getMoment();


// TODO: 一旦、スネークケースのままとする。
// あとでキャメルケースに変更する。
type ExamCategoryWithResult = Expand<
    Omit<ChangeUserToWithDisplayName<ResponseExamCategory, 'created_by' | 'updated_by'>, 'exams'> & {
        exams: ChangeUserToWithDisplayName<ResponseExam, 'created_by'>[]
    }
>;

export type SearchExamCategoryWithResultState = {
    isLoading: boolean
    itemCount: number
    items: ExamCategoryWithResult[]
    pageSize: number
    pageTotal: number
};


type Meta = TaoExamCategorySearchWithResultConnection['meta'] | TaoExamCategoryControlSearchWithResultConnection['meta'];
type payload = TaoExamCategorySearchWithResultConnection['payload'] | TaoExamCategoryControlSearchWithResultConnection['payload'];

export const useSearchWithResultExamCategory = (
    tab: TabKeys | '',
    // ここでは引数は使わないが、他でここの実装を真似する際のためにそれっぽくしておく
    successCallback?: (meta: Meta, payload: payload, isControl: boolean) => void,
    failureCallback?: (meta: Meta, payload: payload, isControl: boolean) => void,

) => {
    const [state, setState] = useState<SearchExamCategoryWithResultState>({
        isLoading: false,
        itemCount: 0,
        items: [],
        pageSize: 10,
        pageTotal: 0,
    });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamCategorySearchWithResultConnection = useAppSelector(state => state.taoExamCategorySearchWithResultConnection);
    const taoExamCategoryControlSearchWithResultConnection = useAppSelector(state => state.taoExamCategoryControlSearchWithResultConnection);

    const dispatch = useDispatch();


    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategorySearchWithResultConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;
        // 許可されたタブの通信でない === 意図しないタイミング
        if (tab === undefined || !['school'].includes(tab)) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const examCategoryList = result.items.map(convertToAdminExamCategoryFormat);

                setState({
                    isLoading: false,
                    itemCount: result.item_count,
                    items: examCategoryList,
                    pageSize: result.page_size,
                    pageTotal: result.page_total,
                });
                successCallback && successCallback(meta, result, false);
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                failureCallback && failureCallback(meta, result, false);
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategorySearchWithResultConnection]);


    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryControlSearchWithResultConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;
        // 許可されたタブの通信でない === 意図しないタイミング
        if (tab === undefined || !['board', ''].includes(tab)) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const examCategoryList = result.items.map(convertToAdminExamCategoryFormat);

                setState({
                    isLoading: false,
                    itemCount: result.item_count,
                    items: examCategoryList,
                    pageSize: result.page_size,
                    pageTotal: result.page_total,
                });
                successCallback && successCallback(meta, result, true);
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                failureCallback && failureCallback(meta, result, true);
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlSearchWithResultConnection]);


    const fetchData = useCallback((value: FormValues, page = 1, pageSize = 10) => {
        setState({
            isLoading: true,
            itemCount: 0,
            items: [],
            pageSize: 0,
            pageTotal: 0,
        });

        const { answerRate, date, isOnlyDeliverySelf, subject, termType, title } = value;

        const average_per_end = answerRate !== undefined ? (Number(answerRate) + 1) * 20 : undefined;
        const average_per_start = answerRate !== undefined ? Number(answerRate) * 20 : undefined;
        const start_at = date !== undefined ? moment(date).startOf(termType).unix() : undefined;
        const end_at = date !== undefined ? moment(date).endOf(termType).unix() : undefined;

        const data = {
            average_per_end,
            average_per_start,
            end_at,
            is_mine: isOnlyDeliverySelf ? 1 as const : 0 as const,
            page_size: pageSize,
            start_at,
            subject_id: subject,
            time_unit: termType,
            title,
        };

        const searchWithResultAction = isControllableUser ?
            Actions.http.connection.tao.examCategory.controlSearchWithResult :
            tab === 'board' ?
                Actions.http.connection.tao.examCategory.controlSearchWithResult :
                Actions.http.connection.tao.examCategory.searchWithResult;

        dispatch(searchWithResultAction(data, page));

    }, [dispatch, isControllableUser, tab]);

    return [state, fetchData] as const;
};

type ResponseExamCategory = Http.Connection.Response.ExamCategory.SearchWitchResult['result']['items'][number];
type ResponseExam = ResponseExamCategory['exams'][number];

/**
 * レスポンスに含まれる Exam を
 * プログラムで使いやすい形式に変換する
 */
const convertToExamFormat = (exam: ResponseExam): ChangeUserToWithDisplayName<ResponseExam, 'created_by'> => ({
    ...exam,
    created_by: additionDisplayName(exam.created_by),
    max_attempts: exam.max_attempts ?? 0,
});

/**
 * レスポンスに含まれる ExamCategory を
 * プログラムで使いやすい形式に変換する
 */
const convertToAdminExamCategoryFormat = (
    examCategory: ResponseExamCategory
): ChangeUserToWithDisplayName<ResponseExamCategory, 'created_by' | 'updated_by'> => {
    const exams = examCategory.exams.map(convertToExamFormat);
    const created_by = additionDisplayName(examCategory.created_by);
    const updated_by = examCategory.updated_by ?
        additionDisplayName(examCategory.updated_by) :
        created_by;

    return {
        ...examCategory,
        created_by,
        exams,
        updated_by,
    };
};

import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import {
    ColumnsType,
    TablePaginationConfig,
} from 'antd/lib/table';
import {
    BaseTable,
    BaseTableProps,
} from 'components/modules/BaseTable';
import {
    PageSizeOptions,
} from 'constants/GlobalConfig';
import SimpleGraph from './SimpleGraph';
import { createResultSubTotalColumns } from '../../../utils/ResultAnswerUtils';
import Breadcrumb from './Breadcrumb';
import { Button } from 'antd';
import TermNameTag from 'components/modules/TermNameTag';

type Props = Omit<
    BaseTableProps<EverydayNote.SubTotalTableRecord>,
    'emptyDescription' | 'pagination' | 'loading'
> & {
    currentPage: number
    initialPagination: Partial<TablePaginationConfig>
    isControllableUser: boolean
    loading: boolean
    onClickAnswerNumber: (data: any, record: EverydayNote.SubTotalTableRecord) => void
    onPageChange: (page: number, pageSize: number) => void
    onSearch: (formValue: Partial<EverydayNote.AnswerResultModal.SearchCondition>) => void
    pageSize: number
    question: EverydayNote.Question | undefined
    term: moment.Moment
    termType: TermTypes
    total: number
    hierarchy: { uuid: string | undefined, name: string }[]
};

export const TotalDataTable: React.VFC<Props> = props => {
    const {
        currentPage,
        isControllableUser,
        initialPagination,
        loading,
        onClickAnswerNumber,
        onPageChange,
        onSearch,
        pageSize,
        question,
        term,
        termType,
        total,
        hierarchy,
        dataSource,
    } = props;
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: pageSize,
            }));
            onPageChange(page, pageSize);
        },
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return `全${total}件中 ${range[0]}-${range[1]} 件`;
        },
        total,
        ...initialPagination,
    });

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            current: currentPage,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize: pageSize,
                }));
                onPageChange(page, pageSize);
            },
        }));
    }, [currentPage, pageSize, total, onPageChange]);

    const handleSearchInDeep = (scopeUuid: string, name: string) => {
        onSearch({
            current: 1,
            hierarchy: hierarchy.concat([{ uuid: scopeUuid, name }]),
        });
    };

    const handleSearchInBreadcrumb = (hierarchy: EverydayNote.AnswerResultModal.SearchCondition['hierarchy']) => {
        onSearch({
            current: 1,
            hierarchy,
        });
    };

    const max = useMemo(() => {
        if(dataSource === undefined) return 0;
        return dataSource.reduce((prev, current) => (current.max > prev ? current.max : prev), 0);
    },[dataSource]);

    const columns: ColumnsType<EverydayNote.SubTotalTableRecord> = [
        {
            title: createTitle(isControllableUser, hierarchy),
            width: termType === 'month' ? 200 : termType === 'week' ? 200 : 50,
            key: 'name',
            render: (_, record) => {
                if (isControllableUser ? hierarchy.length > 2 : hierarchy.length > 1) {
                    return <>{record.name}</>;
                }

                return (
                    <Button onClick={() => handleSearchInDeep(record.uuid, record.name)} type='link'>
                        {record.term_name && <TermNameTag termName={<>{record.term_name}</>} />}
                        {record.name}
                    </Button>
                );
            }
        },
        ...createResultSubTotalColumns(term, termType, (date, timeSeries, record) => {
            if (timeSeries[0] === undefined) return null;

            const key = Object.keys(timeSeries[0])[0];
            const dataCount = Object.values(timeSeries[0]).reduce((a, b) => a + b.count, 0);

            if (dataCount === 0) {
                return <div className='table-item' />;
            }

            if (question?.type === 3) {
                if (isControllableUser && hierarchy.length === 1) {
                    return <div className='table-item with-text'>{timeSeries[0][key].count}</div>;
                } else {
                    return (
                        <Button
                            className='table-item'
                            onClick={() => onClickAnswerNumber(date, record)}
                            type='link'
                        >
                            {timeSeries[0][key].count}
                        </Button>
                    );
                }
            } else {
                return (
                    <SimpleGraph
                        data={timeSeries}
                        loading={loading}
                        max={max}
                        questionType={question?.type}
                        style={{ margin: 'auto' }}
                    />
                );
            }
        }),
    ];

    return (
        <div style={{ position: 'relative' }}>
            <BaseTable<EverydayNote.SubTotalTableRecord>
                {...props}
                style={{
                    // データがないとき、つまりページネーションが表示されないときに
                    // ページネーション分の高さを確保する
                    top: dataSource && dataSource.length > 0 ? 'unset' : '56px',
                    position: 'absolute',
                    width: '100%',
                }}
                emptyDescription={`${createTargetName(isControllableUser, hierarchy)}はありません`}
                columns={columns}
                scroll={{ x: termType === 'month' ? 3500 : termType === 'week' ? 1000 : 500 }}
                rowKey='uuid'
                pagination={pagination}
            />
            <Breadcrumb
                hierarchy={hierarchy}
                onSearch={handleSearchInBreadcrumb}
                style={{
                    // データがないとき、つまりページネーションが表示されないときに
                    // 通常と同様の高さを確保する
                    top: dataSource && dataSource.length > 0 ? '12px' : 0,
                    position: 'absolute'
                }}
            />
        </div>
    );
};


const createTargetName = (isControllableUser: boolean, hierarchy: unknown[]) => {
    if (isControllableUser) {
        switch (hierarchy.length) {
            case 1:
                return '対象学校';
            case 2:
                return '対象クラス';
            case 3:
                return '対象生徒';
            default:
                return 'データ';
        }
    } else {
        switch (hierarchy.length) {
            case 1:
                return '対象クラス';
            case 2:
                return '対象生徒';
            default:
                return 'データ';
        }
    }
};

const createTitle = (isControllableUser: boolean, hierarchy: unknown[]) => {
    if (isControllableUser) {
        switch (hierarchy.length) {
            case 1:
                return '学校名';
            case 2:
                return 'クラス名';
            case 3:
                return '名前';
            default:
                return 'タイトル';
        }
    } else {
        switch (hierarchy.length) {
            case 1:
                return 'クラス名';
            case 2:
                return '名前';
            default:
                return 'タイトル';
        }
    }
};
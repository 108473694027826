import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import {
    Button,
    FormInstance,
} from 'antd';
import {
    CalendarOutlined,
} from '@ant-design/icons';
import BaseModal from 'components/modules/BaseModal';
import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import {
    getMoment,
    getCurrentTerm,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import AnswerResultSearch from './AnswerResultSearch';
import Summary from './Summary';
import { TotalDataTable } from './TotalDataTable';
import { getNextTerm, getPrevTerm, TERM_TYPE } from '../../../utils/TermOperators';
import { ConvertRequestableSearchCondition } from '../../../utils/convertRequestableSearchCondition';
import { useResultTotalQuestionnaire } from '../../..//utils/CustomHooks/useResultTotalQuestionnaire';
import { useResultSubTotalQuestionnaire } from '../../..//utils/CustomHooks/useResultSubTotalQuestionnaire';
import { useDownloadResultCsv } from '../../..//utils/CustomHooks/useDownloadResultCsv';
import DetailAnswerModal from '../DetailAnswerModal';

const moment = getMoment();


const INIT_PAGINATION = {
    current: 1,
    pageSize: Number(PageSizeOptions[0]),
};

type Props = {
    convertRequestableSearchCondition: ConvertRequestableSearchCondition
    loading: boolean
    onClose: () => void
    questionnaire: EverydayNote.Questionnaire | undefined
    tabName?: 'school' | 'board'
    visible: boolean
};

type PropsWithTenant = Props & { tenant: Tenant };
type PropsWithOrganization = Props & { organization: Organization };

const ConfirmAnswerResultModal: React.VFC<PropsWithTenant | PropsWithOrganization> = (props) => {
    const {
        convertRequestableSearchCondition,
        loading,
        onClose,
        questionnaire,
        tabName,
        visible,
    } = props;

    const tenant = 'tenant' in props ? props.tenant : undefined;
    const organization = 'organization' in props ? props.organization : undefined;

    const hierarchyData = { tenant, organization };

    const [
        resultTotal,
        dispatchResultTotal,
        resetResultTotal,
    ] = useResultTotalQuestionnaire();
    const [
        resultSubTotal,
        dispatchResultSubTotal,
        resetResultSubTotal,
    ] = useResultSubTotalQuestionnaire();
    const [
        ,
        dispatchResultCsv,
        resetResultCsv,
    ] = useDownloadResultCsv();

    const jobInfoMap = useAppSelector(state => state.jobInfoMap);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const { termList } = useAppSelector(selectors.globalState);
    const currentTermUuid = getCurrentTerm(termList).uuid;
    const [searchCondition, setSearchCondition] = useState<EverydayNote.AnswerResultModal.SearchCondition>({
        ...generateInitialSearchCondition(questionnaire, hierarchyData),
        ...INIT_PAGINATION,
        // termUuid: currentTermUuid,
        termUuid: undefined,
    });
    const [isDisplayGraph, setIsDisplayGraph] = useState(true);
    const [isOpenDetailAnswer, setIsOpenDetailAnswer] = useState(false);
    const [scopeUuid, setScopeUuid] = useState<string>();
    const [userUuid, setUserUuid] = useState<string>();
    const [detailDate, setDetailDate] = useState<moment.Moment>();

    const isExportingCsv = Object.values(jobInfoMap)
        .filter(jobInfo => jobInfo?.job?.type === BACKGROUND_JOB_TYPE.CONTROL_EVERYDAY_NOTE_RESULT_CSV_EXPORT ||
            jobInfo?.job?.type === BACKGROUND_JOB_TYPE.EVERYDAY_NOTE_RESULT_CSV_EXPORT)
        .filter(jobInfo => jobInfo?.someUuid === questionnaire?.uuid)
        .length > 0;

    const handleReset = (form: FormInstance) => {
        form.setFieldsValue({
            ...generateInitialSearchCondition(questionnaire, hierarchyData),
            target: questionnaire?.items?.[0]?.uuid ?? '',
            // termUuid: currentTermUuid,
            termUuid: undefined,
        });
    };

    const handleSearch = (formValue: Partial<EverydayNote.AnswerResultModal.SearchCondition>) => {
        if (questionnaire === undefined) {
            console.error('毎日の記録の取得に失敗しました.');
            return;
        };

        const newSearchCondition = {
            ...searchCondition,
            ...formValue,
        };

        const question = questionnaire.items.find(question => question.uuid === newSearchCondition.target);
        if (question === undefined) {
            console.error('設問の取得に失敗しました.');
            return;
        }

        setIsDisplayGraph(question.type !== 3);
        setSearchCondition(newSearchCondition);

        const data = convertRequestableSearchCondition(
            newSearchCondition,
            {
                pageSize: newSearchCondition.pageSize,
                termUuid: newSearchCondition.termUuid
            }
        );

        if (data === undefined) {
            console.error('検索情報の取得に失敗しました.');
            return;
        };

        const { page_size, ...pageSizeLessData } = data;

        dispatchResultTotal(
            questionnaire.uuid,
            question,
            pageSizeLessData,
            newSearchCondition.termType,
            tabName,
        );

        dispatchResultSubTotal(
            questionnaire.uuid,
            question,
            data,
            newSearchCondition.termType,
            newSearchCondition.current,
            tabName,
        );
    };

    const handlePageChange = useCallback((page: number, pageSize: number) => {
        if (questionnaire === undefined) {
            console.error('毎日の記録の取得に失敗しました.');
            return;
        }

        const question = questionnaire.items.find(question => question.uuid === searchCondition.target);
        if (question === undefined) {
            console.error('設問の取得に失敗しました.');
            return;
        }

        setSearchCondition({
            ...searchCondition,
            current: page,
            pageSize,
        });

        const data = convertRequestableSearchCondition(
            searchCondition,
            { pageSize, termUuid: searchCondition.termUuid }
        );

        if (data === undefined) {
            console.error('検索情報の取得に失敗しました.');
            return;
        };

        dispatchResultSubTotal(
            questionnaire.uuid,
            question,
            data,
            searchCondition.termType,
            page,
            tabName,
        );
    }, [convertRequestableSearchCondition, dispatchResultSubTotal, questionnaire, searchCondition, tabName]);

    const handleCsvDownload = (encoding: Encoding) => {
        if (questionnaire === undefined) return;

        resetResultCsv();

        const data = convertRequestableSearchCondition(
            searchCondition,
            { encoding }
        );
        if (data === undefined) return;

        dispatchResultCsv(questionnaire.uuid, data, tabName);
    };

    const handleClickPrevTerm = () => {
        const term = getPrevTerm(searchCondition.term, searchCondition.termType);
        handleSearch({ term });
    };

    const handleClickNextTerm = () => {
        const term = getNextTerm(searchCondition.term, searchCondition.termType);
        handleSearch({ term });
    };

    const handleOpenDetailAnswerModal = (date: string, record: EverydayNote.SubTotalTableRecord) => {
        setIsOpenDetailAnswer(true);

        const classUuid = isControllableUser ?
            searchCondition.hierarchy.length === 3 ?
                searchCondition.hierarchy.slice(-1)[0].uuid :
                record.uuid :
            searchCondition.hierarchy.length === 2 ?
                searchCondition.hierarchy.slice(-1)[0].uuid :
                record.uuid;

        const userUuid = isControllableUser ?
            searchCondition.hierarchy.length === 3 ?
                record.uuid :
                undefined :
            searchCondition.hierarchy.length === 2 ?
                record.uuid :
                undefined;

        setScopeUuid(classUuid);
        setUserUuid(userUuid);
        const year = searchCondition.term.year();

        setDetailDate(moment(`${year}/${date}`, 'YYYY/MM/DD (ddd)'));
    };

    const handleCloseDetailAnswerModal = () => setIsOpenDetailAnswer(false);

    useEffect(() => {// 展開時初回検索
        if (!visible || !currentTermUuid) return;

        handleSearch({
            ...generateInitialSearchCondition(questionnaire, hierarchyData),
            // termUuid: currentTermUuid,
            termUuid: undefined,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, currentTermUuid]);

    useEffect(() => {// 初期化
        if (visible) return;

        resetResultTotal();
        resetResultSubTotal();
        setSearchCondition({
            ...generateInitialSearchCondition(questionnaire, hierarchyData),
            ...INIT_PAGINATION,
            // termUuid: currentTermUuid,
            termUuid: undefined,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const question = (questionnaire?.items ?? []).find(question => question.uuid === searchCondition.target);

    return (
        <BaseModal
            className='common-modal everyday-note-modal confirm-answer-result-modal'
            bodyStyle={{ height: '90vh' }}
            title={<span><CalendarOutlined className='icon-r' /> {resultTotal.questionnaire?.name}</span>}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button
                    key='close'
                    size='large'
                    loading={loading}
                    disabled={loading}
                    onClick={onClose}
                >
                    閉じる
                </Button>,
            ]}
        >
            <AnswerResultSearch
                loading={resultTotal.isLoading}
                onSearch={handleSearch}
                onReset={handleReset}
                questions={questionnaire?.items ?? []}
                searchCondition={searchCondition}
                visible={visible}
            />

            <div className='flex-right-container gutter-bottom'>
                <DownloadCSVConfirm
                    disabled={loading || isExportingCsv}
                    disabledMessage={
                        <p>
                            CSVエクスポートはすでに実行済みです。<br />
                            CSVのダウンロード状況については、画面左下からご確認ください
                        </p>
                    }
                    handleOk={handleCsvDownload}
                    loading={loading}
                />
            </div>

            {isDisplayGraph && (
                <Summary
                    data={resultTotal.timeSeries}
                    term={searchCondition.term}
                    termType={searchCondition.termType}
                    loading={resultTotal.isLoading}
                    onClickPrevTerm={handleClickPrevTerm}
                    onClickNextTerm={handleClickNextTerm}
                    questionType={question?.type}
                />
            )}

            <TotalDataTable
                currentPage={searchCondition.current}
                isControllableUser={isControllableUser}
                dataSource={resultSubTotal.tableSource}
                loading={resultSubTotal.isLoading}
                onClickAnswerNumber={handleOpenDetailAnswerModal}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                hierarchy={searchCondition.hierarchy}
                pageSize={searchCondition.pageSize}
                question={question}
                term={searchCondition.term}
                termType={searchCondition.termType}
                total={resultSubTotal.numberOfAllItems}
                initialPagination={INIT_PAGINATION}
            />

            <DetailAnswerModal
                loading={false}
                onClose={handleCloseDetailAnswerModal}
                visible={isOpenDetailAnswer}
                classUuid={scopeUuid ?? ''}
                userUuid={userUuid}
                questionnaireUuid={questionnaire?.uuid}
                question={question}
                data={convertRequestableSearchCondition(
                    { ...searchCondition, term: detailDate ?? moment(), termType: 'date' },
                    { pageSize: INIT_PAGINATION.pageSize }
                )}
                tabName={tabName}
            />
        </BaseModal>
    );
};

export default ConfirmAnswerResultModal;


const generateInitialSearchCondition = (
    questionnaire: EverydayNote.ControlQuestionnaire | EverydayNote.AdminQuestionnaire | undefined,
    hierarchyData: { tenant?: Tenant, organization?: Organization },
) => {
    const { tenant, organization } = hierarchyData;

    const hierarchy = tenant ?
        [{
            uuid: tenant.uuid,
            name: tenant.name
        }] :
        organization ?
            [{
                uuid: organization.uuid,
                name: organization.name,
            }] :
            [];


    return {
        target: questionnaire?.items?.[0]?.uuid ?? '',
        hierarchy,
        termType: TERM_TYPE.WEEK,
        term: moment(),
        items: [{}],
        conditions: [],
    };
};
import * as React from 'react';
import { Checkbox, Spin, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';

type TableContent = {
    header: string | React.ReactNode
    data?: string | React.ReactNode
}[]

type Props = {
    className?: string
    isLoading?: boolean
    onReloadResult: (latest_result_only: boolean) => void
    style?: React.CSSProperties
    tableContent: TableContent
};

/***
 * テーブルのヘッダが横に表示されるテーブル
 * @param props
 * @constructor
 */
export const SideHeadTable: React.VFC<Props> = ({
    className,
    isLoading = false,
    onReloadResult,
    style,
    tableContent,
}) => {

    const body = tableContent.map((content, index) => {
        return content.data !== undefined ? (
            <tr key={index} >
                <th className='ant-table-cell'>{content.header}</th>
                <td className='ant-table-cell'>{content.data}</td>
            </tr>
        ) : (
            <tr key={index}>
                <th className='ant-table-cell' colSpan={2}>{content.header}</th>
            </tr>
        )
    })

    return (
        <div className={className} style={style}>

            <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                    <Typography.Text style={{ margin: '0.5rem' }}> 集計結果 </Typography.Text>
                    <Tooltip title='集計結果は過去に転出したユーザーの結果も含みます。'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                <div>
                    <Checkbox
                        defaultChecked={false}
                        onChange={(e) => onReloadResult(e.target.checked)}
                    >
                        最新の結果のみで集計
                    </Checkbox>
                </div>
            </div>

            <Spin
                indicator={<LoadingOutlined style={{ fontSize: '35px' }} />}
                spinning={isLoading}
            >
                <table className='side-head-table'>
                    <tbody className='ant-table-tbody ant-table-thead'>
                        {body}
                    </tbody>
                </table>
            </Spin>
        </div>
    )
}

import { useEffect, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';
import {
    ColumnsType,
    TablePaginationConfig,
    TableRowSelection,
} from 'antd/lib/table/interface';
import {
    getMoment,
    getPermissionNameFromCode,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { BaseTable } from 'components/modules/BaseTable';
const moment = getMoment();


type Props = {
    currentRoleList: Role[],
    onSelected: (roleList: Role[]) => void
};

type Pagination = PartOfRequired<
    TablePaginationConfig,
    'pageSize' | 'current'
>;

const RoleTab: VFC<Props> = ({
    currentRoleList,
    onSelected,
}) => {
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState<Role[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: roleList.length,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: false,
        pageSizeOptions: PageSizeOptions,
        pageSize: 50,
        onChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size ?? 50
            }));
            dispatch(Actions.http.connection.role.search({ page_size }, page));
        },
        current: 1,
    });
    const roleSearchConnection = useAppSelector(state => state.roleSearchConnection);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedRows(currentRoleList);
    }, [currentRoleList]);

    useEffect(() => {
        dispatch(Actions.http.connection.role.search({ page_size: 500 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { meta, payload } = roleSearchConnection;

        setLoading(meta.fetch);

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setRoleList(payload.result?.items ?? []);
                setPagination(pagination => ({
                    ...pagination,
                    total: payload.result?.item_count ?? 0,
                }));
                break;
            default:
                break;
        }
    }, [roleSearchConnection]);

    const columns: ColumnsType<Role> = [
        {
            title: '役割名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '権限',
            dataIndex: 'role',
            key: 'role',
            render: (_, record) => {
                return getPermissionNameFromCode(record.permission_code);
            }
        },
        {
            title: '作成時間/更新時間',
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            )
        }
    ];

    const rowSelection: TableRowSelection<Role> = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
            onSelected(selectedRows);
        },
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows.map(row => row.uuid),
        type: 'checkbox',
    };

    return (
        <div className='group-list container'>
            <BaseTable
                loading={loading}
                emptyDescription='設定された役割はまだありません'
                pagination={pagination}
                columns={columns}
                dataSource={roleList}
                rowSelection={rowSelection}
            />
        </div>
    );
};

export default RoleTab;

import { createAction } from 'redux-actions';
import {
    API_APP_CATEGORY,
    API_CONTROL_APP_CATEGORY,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/APPS/CATEGORY';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const parameter = createAction(
    createActionType('PARAMETER' as const),
    () => ({ method: 'get', api: API_APP_CATEGORY }),
    () => ({ fetch: true })
);

export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return ({ method: 'get', data, api: `${API_APP_CATEGORY}/${pageNum}` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlSearch = createAction(
    createActionType('CONTROL_SEARCH' as const),
    (data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return ({ method: 'get', data, api: `${API_CONTROL_APP_CATEGORY}/${pageNum}` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_APP_CATEGORY + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlCreate = createAction(
    createActionType('CONTROL_CREATE' as const),
    (data) => ({ method: 'put', api: API_CONTROL_APP_CATEGORY + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_APP_CATEGORY + '/update/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlUpdate = createAction(
    createActionType('CONTROL_UPDATE' as const),
    (uuid, data) => ({ method: 'post', api: API_CONTROL_APP_CATEGORY + '/update/' + uuid, data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_APP_CATEGORY + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deleteAction as delete };

export const controlDelete = createAction(
    createActionType('CONTROL_DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_CONTROL_APP_CATEGORY + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const changeOrder = createAction(
    createActionType('CHANGE_ORDER' as const),
    (data) => ({
        method: 'post',
        api: API_APP_CATEGORY + '/change-category-order/',
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlChangeOrder = createAction(
    createActionType('CONTROL_CHANGE_ORDER' as const),
    (data) => ({
        method: 'post',
        api: API_CONTROL_APP_CATEGORY + '/change-category-order/',
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const view = createAction(
    createActionType('VIEW' as const),
    (uuid, mapKey, viewName) => ({ method: 'get', api: API_APP_CATEGORY + '/view/' + uuid }),
    (uuid, mapKey, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName, mapKey })
);

export const controlView = createAction(
    createActionType('CONTROL_VIEW' as const),
    (uuid, mapKey, viewName) => ({ method: 'get', api: API_CONTROL_APP_CATEGORY + '/view/' + uuid }),
    (uuid, mapKey, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName, mapKey })
);

export const result = createAction(
    createActionType('RESULT' as const),
    (uuid, data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return {
            method: 'get',
            data,
            api: API_APP_CATEGORY + '/result/' + uuid + '/' + pageNum,
        };
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlResult = createAction(
    createActionType('CONTROL_RESULT' as const),
    (uuid, data) => {
        return {
            method: 'get',
            data,
            api: API_CONTROL_APP_CATEGORY + '/result/' + uuid
        };
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const targetSchoolClass = createAction(
    createActionType('RESULT' as const),
    (uuid) => {
        return {
            api: `${API_APP_CATEGORY}/target-school-classes/${uuid}`,
            method: 'get',
        };
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
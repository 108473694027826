import { createSelector } from 'reselect';

const questionnaireEditableItemsSelector = state => state.editQuestionnaire.editableItems;
const questionnaireSelector = state => state.editQuestionnaire.questionnaire;
const questionnaireSelectCategorySelector = state => state.editQuestionnaire.selectCategory;
const questionnaireCategoryListSelector = state => state.editQuestionnaire.questionnaireCategoryList;

export const editQuestionnaireSelector = {
    disabledItems: createSelector(
        [questionnaireEditableItemsSelector, questionnaireSelector],
        (editableItems, questionnaire) => {
            const isLocked = questionnaire?.is_locked;

            const getChildrenItems = (item) => editableItems.filter((v) => v.parentTmpItemId === item.parentTmpItemId);

            const disabledUpDownMap = editableItems.reduce((disableObj, item) => {
                const childrenItems = getChildrenItems(item);
                const indexInChildren = childrenItems.findIndex((v) => v.tmpItemId === item.tmpItemId);
                return ({
                    ...disableObj,
                    [item.tmpItemId]: { upDisabled: isLocked || indexInChildren === 0, downDisabled: isLocked || indexInChildren >= childrenItems.length - 1 }
                })
            }, {});

            return {
                disabledUpDownMap,
                disabledEditButtons: isLocked,
                disabledAnonymous: isLocked,
            }
        }
    ),
    disabledForcedAnswer: createSelector(
        [questionnaireSelectCategorySelector, questionnaireCategoryListSelector],
        (selectCategory, categoryList) => {
            const selectedCategory = categoryList.find((category) => category.uuid === selectCategory);
            return selectedCategory ? !(selectedCategory.can_set_forced) : true;
        }),
}

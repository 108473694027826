import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { LoadingOutlined } from '@ant-design/icons';

const Graph = ({
    data,
    loading = false,
    mean,
}) => {
    const axisColor = '#A3A3A3';
    const gridColor = '#F0F0F0';
    const barColor = '#16C2C2';
    const meanLineColor = '#004C97';

    const mdLgBoundary = 1024;
    const mdXlBoundary = 1280;
    const xAxisAngle = data.length > 12
        ? window.innerWidth >= mdXlBoundary ? 330 : 270
        : window.innerWidth >= mdLgBoundary ? 330 : 270;

    const customizedData = data.map((d) => {
        const labelHasSlash = d.name.split('/');
        if (labelHasSlash.length === 1) return { count: d.count, name: labelHasSlash[0] }

        const dayText = labelHasSlash[1];
        return { count: d.count, name: dayText }
    });

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
                className='summary-graph'
            >
                <LoadingOutlined style={{ fontSize: '35px', color: '#004C97' }} />
            </div>
        );
    }

    return (
        <ResponsiveContainer>
            <BarChart data={customizedData}>
                <CartesianGrid stroke={gridColor} vertical={false} />
                <XAxis
                    angle={xAxisAngle}
                    axisLine={false}
                    dataKey='name'
                    height={80}
                    interval={0}
                    stroke={axisColor}
                    tick={{ fontSize: 12, dy: 20, dx: -5 }}
                    tickLine={false}
                    tickMargin={4}
                />
                <YAxis
                    dataKey='count'
                    axisLine={false}
                    tickLine={false}
                    stroke={axisColor}
                    width={30}
                />
                <Bar dataKey='count' fill={barColor} />
                <ReferenceLine
                    stroke={meanLineColor}
                    strokeDasharray='8 8'
                    strokeWidth={3}
                    y={mean < 1 ? mean : Math.round(mean)}
                >
                    <Label
                        position='top'
                        style={{ fontWeight: 'bold', fill: meanLineColor }}
                        value={`平均利用回数 ${mean < 1 ? '1回未満' : String(Math.round(mean)) + '回'}`}
                    />
                </ReferenceLine>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default Graph;

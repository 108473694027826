import { PlusCircleFilled } from '@ant-design/icons';
import { Button, TablePaginationConfig } from 'antd';
import * as React from 'react';
import QuestionnaireSearch from './QuestionnaireSearch';
import { QuestionnaireTable } from './QuestionnaireTable';

type Props<T> = {
    currentPage: number
    currentTab?: 'school' | 'board'
    dataSource: T[]
    initialPagination: Partial<TablePaginationConfig>
    isControllableUser: boolean
    loading: boolean
    onAnswerResultClick: (questionnaire: T) => void
    onChangeSearchMode: () => void
    onCopyClick?: (questionnaire: T) => void
    onCreateClick?: () => void
    onDeleteClick?: (uuid: string) => void
    onSearch: (condition: Partial<EverydayNote.SearchConditions>) => void
    onUpdateClick?: (questionnaire: T) => void
    totalDataCount: number
};

const Template = <T extends EverydayNote.Questionnaire>({
    currentPage,
    currentTab,
    dataSource,
    initialPagination,
    isControllableUser,
    loading,
    onAnswerResultClick,
    onChangeSearchMode,
    onCopyClick,
    onCreateClick,
    onDeleteClick,
    onSearch,
    onUpdateClick,
    totalDataCount,
}: Props<T>) => {
    const handlePageChange = (page: number, pageSize: number) => {
        onSearch({ page, pageSize });
    };

    return (
        <div className='container'>
            <QuestionnaireSearch
                loading={loading}
                onChangeSearchMode={onChangeSearchMode}
                onSearch={onSearch}
            />

            <div className='flex-right-container gutter-bottom'>
                <Button
                    type='primary'
                    icon={<PlusCircleFilled />}
                    disabled={onCreateClick === undefined}
                    onClick={onCreateClick}
                >
                    新規作成
                </Button>
            </div>

            <QuestionnaireTable<T>
                currentPage={currentPage}
                currentTab={currentTab}
                dataSource={dataSource}
                loading={loading}
                onAnswerResultClick={onAnswerResultClick}
                onCopyClick={onCopyClick}
                onDeleteClick={onDeleteClick}
                onEditClick={onUpdateClick}
                onPageChange={handlePageChange}
                total={totalDataCount}
                initialPagination={initialPagination}
                isControllableUser={isControllableUser}
            />
        </div>
    );
};

export default Template;
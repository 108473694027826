import { createAction } from 'redux-actions';
import {
    API_NOTIFICATION_CATEGORY,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/NOTIFICATION/CATEGORY';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const parameter = createAction(
    createActionType('PARAMETER' as const),
    () => ({ method: 'get', api: API_NOTIFICATION_CATEGORY }),
    () => ({ fetch: true })
);

export const search = createAction(
    createActionType('SEARCH' as const),
    (query, page) => {
        const pageNum = typeof page === 'undefined' ? '1' : page;
        return ({ method: 'get', query, api: `${API_NOTIFICATION_CATEGORY}/${pageNum}` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_NOTIFICATION_CATEGORY + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({
        method: 'post',
        api: API_NOTIFICATION_CATEGORY + '/update/' + uuid,
        data
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const deletes = createAction(
    createActionType('DELETE' as const),
    (uuid) => ({ method: 'delete', api: API_NOTIFICATION_CATEGORY + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
export { deletes as delete };
import { createAction } from 'redux-actions';

import { API_EVERYDAY_NOTE } from 'constants/endpoints';

import { StatusEnum } from '../../StatusEnum';
import { controlActionTypeCreator, Meta, metaCreator, Payload } from '../../utils';

const createActionType = controlActionTypeCreator;


export const search = createAction(
    createActionType(API_EVERYDAY_NOTE, 'search'),
    (data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return {
            api: `${API_EVERYDAY_NOTE}/${pageNum}`,
            data,
            method: 'get',
        };
    },
    metaCreator
);

export const create = createAction(
    createActionType(API_EVERYDAY_NOTE, 'create'),
    (data) => ({
        api: `${API_EVERYDAY_NOTE}/create`,
        data,
        method: 'put',
    }),
    metaCreator
);

export const update = createAction(
    createActionType(API_EVERYDAY_NOTE, 'update'),
    (data) => {
        const { uuid, ...restData } = data;
        return {
            api: `${API_EVERYDAY_NOTE}/update/${uuid}`,
            data: restData,
            method: 'post',
        };
    },
    metaCreator
);

const deleteAction = createAction(
    createActionType(API_EVERYDAY_NOTE, 'delete'),
    (uuid) => ({
        api: `${API_EVERYDAY_NOTE}/delete/${uuid}`,
        method: 'delete',
    }),
    metaCreator
);
export { deleteAction as delete };

export const resultCsv = createAction(
    createActionType(API_EVERYDAY_NOTE, 'result-csv'),
    (questionnaireUuid, data) => ({
        api: `${API_EVERYDAY_NOTE}/result-csv/${questionnaireUuid}`,
        data,
        method: 'get',
    }),
    (questionnaireUuid) => ({
        fetch: true,
        status: StatusEnum.REQUEST,
        uuid: questionnaireUuid,
    })
);

type SearchParameter = Http.Connection.Request.Parameter.EverydayNote.ResultTotal;
export const resultTotal = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.EverydayNote.ResultTotal,
    Meta,
    string,
    string,
    SearchParameter
>(
    createActionType(API_EVERYDAY_NOTE, 'result-total'),
    (questionnaireUuid, itemUuid, data) => ({
        api: `${API_EVERYDAY_NOTE}/${questionnaireUuid}/${itemUuid}/result-total`,
        data,
        method: 'get',
    }),
    metaCreator
);

export const resultSubItems = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.EverydayNote.ResultSubItems,
    Meta,
    string,
    string,
    SearchParameter,
    number
>(
    createActionType(API_EVERYDAY_NOTE, 'result-sub-items'),
    (questionnaireUuid, itemUuid, data, page) => ({
        api: `${API_EVERYDAY_NOTE}/${questionnaireUuid}/${itemUuid}/${page}/result-sub-items`,
        data,
        method: 'get',
    }),
    metaCreator
);

export const resultSubTotal = createAction<
    Payload<{ data: SearchParameter }> | Http.Connection.Response.EverydayNote.ResultSubTotal,
    Meta,
    string,
    string,
    SearchParameter,
    number
>(
    createActionType(API_EVERYDAY_NOTE, 'result-sub-total'),
    (questionnaireUuid, itemUuid, data, page) => ({
        api: `${API_EVERYDAY_NOTE}/${questionnaireUuid}/${itemUuid}/${page}/result-sub-total`,
        data,
        method: 'get',
    }),
    metaCreator
);

export const view = createAction(
    createActionType(API_EVERYDAY_NOTE, 'view' as const),
    (uuid) => ({
        api: `${API_EVERYDAY_NOTE}/view/${uuid}`,
        method: 'get',
    }),
    metaCreator
);

export const targetOrganization = createAction(
    createActionType(API_EVERYDAY_NOTE, 'target-organization'),
    (noteUuid) => ({
        api: `${API_EVERYDAY_NOTE}/target-organization/${noteUuid}`,
        method: 'get',
    }),
    metaCreator
);

export const targetSchoolClass = createAction(
    createActionType(API_EVERYDAY_NOTE, 'target-school-class'),
    (noteUuid) => ({
        api: `${API_EVERYDAY_NOTE}/target-school-class/${noteUuid}`,
        method: 'get',
    }),
    metaCreator
);
import { useState } from 'react';
import { LargeSpin } from 'components/modules/Spin';
import IntervalTimer from "react-interval-timer";
import { checkEnvironmentIsTao } from 'constants/GlobalConfig';


function GateProgress() {
    const [isLoading, setIsLoading] = useState(true);
    return (
        <div className='loading-wrapper'>
            {isLoading ?
                <LargeSpin tip='読み込み中'/>
                :
                <div>
                    <h3>サインアウトしました</h3>
                    <p>ブラウザを閉じてください</p>
                    <a href='/'>
                        <img
                            alt='application_logo_img'
                            style={{ objectFit: 'cover', height: 200, width: 200 }}
                            src={checkEnvironmentIsTao() ? '/logo512-m.png' : '/logo512.png'}
                        />
                    </a>
                </div>
            }
            <IntervalTimer  enabled={true}
                repeat={false} callback={()=> {setIsLoading(false);}} timeout={4000}/>
        </div>
    );
}

export default GateProgress;
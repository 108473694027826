import * as React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';

import { ReportDetailState } from '../../hooks/useReportDetail';

type Props = {
    reportDetail: ReportDetailState
}

export const InfoTable: React.VFC<Props> = ({
    reportDetail,
}) => {
    const data = reportDetail.result;
    return (
        <div>
            <div>
                <Typography.Text style={{ margin: '0.5rem' }}>
                    テストの情報
                </Typography.Text>
                <Tooltip
                    title='MEXCBTで公開されているこのテストの情報です。'
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <table className='test-info-table'>
                <tbody>
                    <TableRow label='教科' value={data?.subjectName ?? '-'} />
                    <TableRow label='科目' value={data?.subjectChildName ?? '-'} />
                    <TableRow label='学年名' value={data?.gradeName ?? '-'} />
                    <TableRow label='平均正答率' value={`${data?.averagePer ?? '-'}%`} />
                    <TableRow label='難易度' value={data?.difficulty ?? '-'} />
                    <TableRow label='想定所要時間' value={`${data?.testTimeMin ?? '-'}`} />
                </tbody>
            </table>
        </div>
    );
};


type TableRosProps = {
    label: string
    value?: string
}

const TableRow: React.VFC<TableRosProps> = ({
    label,
    value,
}) => {
    return value === undefined ?
        (
            <tr>
                <th colSpan={2}>{label}</th>
            </tr>
        ) :
        (
            <tr>
                <th>{label}</th>
                <td>{value}</td>
            </tr>
        );
};
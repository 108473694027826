import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Table,
    Typography,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { getMoment } from 'constants/GlobalConfig';
import { useUsageData } from '../Common/UsageDataProvider';
import { createActionLogDetailQuery } from '../Common/utils';
import { usePagination } from '../Common/usePagination';
const moment = getMoment();
const { Text } = Typography;

const DetailHistory = () => {
    const [source, setSource] = useUsageData();
    const [pagination, setPagination] = usePagination({
        pageSize: source.pagination.pageSize,
        position: ['bottomRight'],
        total: source.detail.data.item_count,
    });
    // tableLoading の更新用
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {// 初回検索
        setSource(source => ({
            ...source,
            pagination: {
                ...source.pagination,
                current: 1,
            },
        }));

        const detailQueries = createActionLogDetailQuery({
            source,
            hierarchy: source.hierarchy,
            page: 1,
            pageSize: source.pagination.pageSize,
        });

        dispatch(Actions.http.connection.usage.detail(detailQueries));
    }, []);// eslint-disable-line

    useEffect(() => {// paginationの更新
        setLoading(source.detail.loading);
        setPagination({
            ...pagination,
            page: source.pagination.current,
            pageSize: source.pagination.pageSize,
            total: source.detail.data.item_count,
        })
    }, [source.detail.loading]);// eslint-disable-line

    const tableLoading = {
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: '35px' }} />,
    };

    const sourceDetailDataItems = source.detail.data.items;
    const restrictionData = sourceDetailDataItems.map((e, i) => {
        return {
            key: i,
            description: e.description,
            datetime: moment.unix(e.created_at).format('YYYY/MM/DD HH:mm:ss'),
        };
    });

    return (
        <div>
            <div className='detail-history'>
                <span>利用履歴詳細</span>
            </div>
            <Table
                size='small'
                rowKey='key'
                columns={createDetailHistoryColumns(restrictionData)}
                dataSource={restrictionData}
                loading={tableLoading}
                pagination={pagination}
            />
        </div>
    );
};

const createDetailHistoryColumns = (sourceData) => {
    if (sourceData[0] === undefined) return;
    const dataKeys = ['datetime', 'description'];
    return dataKeys.map(key => {
        return {
            align: 'left',
            title: key === 'datetime' ? '日時' : '詳細',
            dataIndex: key,
            render: (text) => {
                return {
                    children: <Text>{text}</Text>,
                }
            },
        };
    });
};

export default DetailHistory;

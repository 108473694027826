import { useDispatch } from 'react-redux';

import { Popover } from 'antd';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { useBackgroundJobInfoSource } from './BackgroundJobProvider';
import { BackgroundJobStatus } from './BackgroundJobStatus';
import { getJobStatus } from './BackgroundJobUtils';
import { BackgroundJobCard } from './JobCard/BackgroundJobCard';
import { createJobFooterButton } from './JobParts/JobFooterButton';
import { getJobMessage } from './JobParts/JobMessage';
import { JobTitle } from './JobParts/JobTitle';


/** BackgroundJobの情報を示すカードの一覧の表示  */
export const BackgroundJobMenu = ({ collapsed }) => {
    const { 'sync-group': isSyncGroup } = useAppSelector(state => state.tenant.payload.features);
    const dispatch = useDispatch();
    const [jobInfoMap, removeJobInfo, setLoadingJobInfo] = useBackgroundJobInfoSource();
    const jobInfoList = Object.values(jobInfoMap);

    if (!jobInfoList || jobInfoList.length === 0) {
        return null;
    }

    return (
        <div className='background_job_menu' style={{position:'relative'}}>
            <Popover
                content={(
                    <div style={{ height: 'auto', overflowY: 'auto', maxHeight: '80vh', padding: '0 10px', width: '480px' }}>
                        {jobInfoList.map(jobInfo => {
                            return (
                                <BackgroundJobCard
                                    buttons={createButtons(
                                        dispatch,
                                        jobInfo,
                                        isSyncGroup,
                                        removeJobInfo,
                                        setLoadingJobInfo
                                    )}
                                    created_at={jobInfo.job.created_at}
                                    done_at={jobInfo.job.done_at}
                                    key={jobInfo.job.uuid}
                                    message={getJobMessage(jobInfo)}
                                    status={getJobStatus(jobInfo.job)}
                                    title={<JobTitle job={jobInfo.job} />}
                                    updated_at={jobInfo.gmt}
                                />
                            );
                        })}
                    </div>
                )}
                getPopupContainer={() => document.getElementsByClassName('background_job_menu')[0]}
                placement='topLeft'
                trigger='click'
            >
                {/* div を消すと Popover が表示されなくなる */}
                <div>
                    <BackgroundJobStatus collapsed={collapsed} jobInfoList={jobInfoList} />
                </div>
            </Popover>
        </div>
    );
};

const createButtons = (dispatch, jobInfo, isSyncGroup, removeJobInfo, setLoadingJobInfo) => {
    function executeGroup() { dispatch(Actions.http.connection.sync.executeGroup(jobInfo.job.uuid)); setLoadingJobInfo(jobInfo.job.uuid); }
    function dryRunUser() { dispatch(Actions.http.connection.sync.dryRunUser(jobInfo.job.uuid)); setLoadingJobInfo(jobInfo.job.uuid); }
    function executeUser() { dispatch(Actions.http.connection.sync.executeUser({ update_password: 0 }, jobInfo.job.uuid)); setLoadingJobInfo(jobInfo.job.uuid); }
    function dryRunGroupMember() { dispatch(Actions.http.connection.sync.dryRunGroupMember(jobInfo.job.uuid)); setLoadingJobInfo(jobInfo.job.uuid); }
    function executeGroupMember() { dispatch(Actions.http.connection.sync.executeGroupMember(jobInfo.job.uuid)); setLoadingJobInfo(jobInfo.job.uuid); }
    function executeDeleteUser() { dispatch(Actions.http.connection.sync.executeDeleteUser(jobInfo.job.uuid)); setLoadingJobInfo(jobInfo.job.uuid); }

    return createJobFooterButton({
        dryRunGroupMember,
        dryRunUser,
        executeDeleteUser,
        executeGroup,
        executeGroupMember,
        executeUser,
        isSyncGroup,
        jobInfo,
        onCancel: () => { removeJobInfo(jobInfo.job.uuid); },
    });
};

import { useContext, useState, useEffect, } from 'react';
import {
    Col,
    Divider,
    Row,
    Tooltip,
    Typography,
} from 'antd';
import {
    InfoCircleOutlined,
    SelectOutlined,
} from '@ant-design/icons';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/Table';
import { getMoment } from 'constants/GlobalConfig';
import { ScoringStatusTag } from './ScoringStatusTag';
import { ApplicationInfomationTable } from './ApplicationInfomationTable';
import { BaseTable } from 'components/modules/BaseTable';
import { useApplicationViewData } from '../utils/useApplicationViewData';
import { useAppCategoryResult } from '../utils/useAppCategoryResult';
import { BreadCrumbContext, SearchContext } from './index';

const moment = getMoment();

// antd の Tree data の表示を使うための型
type DataSource = AppStatusModal.ApplicationView['users'][number];

type Props = {
    category: App.AppsCategory,
    onSearch: ReturnType<typeof useAppCategoryResult>[1],
};

export const ApplicationView: React.VFC<Props> = ({
    category,
    onSearch
}) => {
    const { breadcrumb, setBreadcrumb } = useContext(BreadCrumbContext);
    const { search, } = useContext(SearchContext);
    const [applicationView] = useApplicationViewData(breadcrumb.uuid);

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        ...applicationView.pagination,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize,
            }));
            onSearch(category.uuid, search, page);
        }
    });

    useEffect(() => {
        onSearch(category.uuid, search, 1);
        // 初回のみ実行
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            total: applicationView.pagination.total,
        }));
    }, [applicationView.pagination]);

    const handleShowStudentView = (name: string, uuid: string) => {
        setBreadcrumb({ name: name, uuid: uuid, type: 'Student' });
    };

    const columnsTest: ColumnsType<DataSource> = [
        {
            title: '氏名',
            render: (_, record) => {
                const existApplicationResult = record.score != null; // null undefined以外はtrue
                return (
                    <Tooltip
                        mouseEnterDelay={1}
                        title={existApplicationResult ? '生徒ごとのビューを開く' : '学習結果が存在しません'}
                    >
                        <button
                            disabled={!existApplicationResult}
                            className={existApplicationResult ? 'text-button' : 'text-button disabled'}
                            onClick={() => {
                                handleShowStudentView(record.name, record.uuid);
                            }}
                        >
                            {record.name}
                        </button>
                    </Tooltip>
                );
            }
        },
        {
            title: (
                <>
                    <span style={{ marginRight: '0.5em' }}>
                        得点/満点
                    </span>
                    <Tooltip title='複数回解答した場合、最新の得点・満点が表示されます'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </>
            ),
            render: (_, record) => {
                return (
                    <>
                        <span style={{ marginRight: '1rem' }}>
                            {`${record.score ?? '-'} / ${record.maxScore ?? '-'}`}
                        </span>
                        {isDisplayDetailResultLink(record) &&
                            <a href={record.resultUrl} target='_blank' rel='noreferrer'>
                                <Tooltip title='複数回この教材・アプリに解答した場合、最後の解答内容が表示されます'>
                                    <SelectOutlined />
                                </Tooltip>
                            </a>
                        }
                    </>
                );
            }
        },
        {
            title: '',
            key: 'gradingProgress',
            render: (_, record) => (
                <ScoringStatusTag status={record.gradingProgress} />
            ),
        },
        {
            title: '完了時刻',
            dataIndex: 'createdAt',
            render: (createdAt) => {
                if (createdAt) {
                    return (<span>{moment.unix(createdAt).format('YYYY/MM/DD HH:mm')}</span>);
                } else {
                    return '-';
                }
            }
        }
    ];

    return (
        <div>
            <Typography.Title level={3}>
                {applicationView.application?.title}
            </Typography.Title>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '2rem' }} />

            <Row gutter={16}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={16}
                >
                    <Typography.Text style={{ margin: '0.5rem' }}> 解答状況一覧 </Typography.Text>

                    <BaseTable
                        rowKey='uuid'
                        columns={columnsTest}
                        dataSource={applicationView.users}
                        emptyDescription={''} // 通常空になることはない
                        pagination={pagination}
                        loading={applicationView.loading}
                    />
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={8}
                >
                    <ApplicationInfomationTable
                        title={applicationView.application?.title ?? ''}
                        publishStart={applicationView.application?.publish_start_at ?? 0}
                        publishEnd={applicationView.application?.publish_end_at ?? 0}
                        fullScore={applicationView.application?.max_score ?? 0}
                        isUsable={!!applicationView.application?.result_url}
                        maxAttempts={applicationView.application?.max_attempts ?? 0}
                    />
                </Col>
            </Row>
        </div>
    );
};

/**
 * 詳細な結果を開くリンクを表示するか
 */
// 一度でも教材・アプリを受けていてなおかつ result_url がある場合に表示
const isDisplayDetailResultLink = (record: DataSource) => {
    return !!record.createdAt && !!record.resultUrl;
};

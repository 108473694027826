import { useEffect, useState } from 'react';
import classNames from 'classnames';
import './StudentModal.css';

import PropTypes from 'prop-types';
import { LabelImportance } from './LabelImportance'

import Transition from './Transition'
import { Button, buttonColorEnum } from 'components/modules/Student/Button';

export const ModalHeaderTemplate = (props) => {
    const {
        children,
        status = undefined,
        option = undefined,
        loading = false,
    } = props;

    const header = loading
        ? <span className='text-sm'>　</span>
        : <div className='flex flex-row items-center w-full'>
            {!!status && status}
            <p
                style={{ marginBottom: 0 }} //antdの影響で変なマージンが入ってしまうので無理やり無効化
                className='text-sm sm:text-base text-white ml-3 break-all'
            >
                {children}
            </p>
            {!!option && option}
        </div>;

    return header;
};

export const StudentModal = props => {
    const {
        children = null,
        footerElement = null,
        headerElement =
        <LabelImportance type='pills' className='bg-lg-red'>
            大切
        </LabelImportance>,
        zIndex = 10,
        closeModal = () => { },
    } = props;


    useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen])
    const [isOpen, setIsOpen] = useState(props.isOpen)
    return (
        <Transition show={isOpen}>
            <div
                className={classNames(
                    'StudentModal',
                    'fixed bottom-0 inset-x-0 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:min-h-full',
                    { 'z-10': zIndex === 10 }, // `z-${props.zIndex}` などの書き方だと,
                    { 'z-20': zIndex === 20 }, // tailwind のビルドに z-10 などが載ってくれないので,
                    { 'z-30': zIndex === 30 }, // このような書き方をしている.
                    { 'z-40': zIndex === 40 },
                    { 'z-50': zIndex === 50 },
                    { 'z-10': zIndex % 10 !== 0 || zIndex < 10 || 50 < zIndex },
                )}>
                <Transition
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'>
                    <div className='fixed inset-0 transition-opacity'>
                        <div className='absolute inset-0 bg-gray-500 opacity-75' />
                    </div>
                </Transition>

                <Transition
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                    <div
                        className='bg-white rounded-lg shadow-xl transform transition-all sm:w-3/4 max-h-screen sm:max-h-3/4 flex flex-col'
                        role='dialog'
                        aria-modal='true'
                        aria-labelledby='modal-headline'
                        style={{
                            height: `calc(${visualViewport.height}px - 16px)`,
                            maxHeight: '75vh',
                            width: ' calc(100vw - 16px)'
                        }}

                    >
                        <div className='bg-lg-blue min-h-15 w-full flex flex-row rounded-t-lg items-center p-3'>
                            {headerElement}
                        </div>
                        <div className='bg-white flex-1 overflow-auto'>
                            {children}
                        </div>
                        <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg'>
                            {footerElement
                                ? footerElement
                                : <Button
                                    color={buttonColorEnum.WHITE}
                                    title='とじる'
                                    onClick={closeModal}
                                    className='w-full sm:w-auto shadow-sm text-lg font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                />
                            }
                        </div>
                    </div>
                </Transition>
            </div>
        </Transition>
    );
};

StudentModal.propTypes = {
    children: PropTypes.any,
    closeModal: PropTypes.func,
    escClosable: PropTypes.bool,
    footerElement: PropTypes.node,
    headerElement: PropTypes.node,
    isOpen: PropTypes.bool,
    maskClosable: PropTypes.bool,
    zIndex: PropTypes.number,
};

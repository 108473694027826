import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Payload = QuestionnaireViewConnection['payload'] | QuestionnaireControlViewConnection['payload']
type Meta = QuestionnaireViewConnection['meta'] | QuestionnaireControlViewConnection['meta']

type CallbackFn = (meta: Meta, payload: Payload, isControl: boolean) => void

type AnswerResultByItemState = {
    isLoading: boolean
    questionnaire: Questionnaire | undefined
}

const useViewQuestion = (
    successCallback: CallbackFn = () => undefined,
    failureCallback: CallbackFn = () => undefined,
) => {
    const dispatch = useDispatch();
    const [state, setState] = useState<AnswerResultByItemState>({
        isLoading: false,
        questionnaire: undefined,
    });
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const questionnaireViewConnection = useAppSelector(state => state.questionnaireViewConnection);
    const questionnaireControlViewConnection = useAppSelector(state => state.questionnaireControlViewConnection);

    const effect = useCallback((connection: QuestionnaireViewConnection | QuestionnaireControlViewConnection) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback(meta, payload, true);
                const questionnaire = questionnaireConvert(payload.result, isControllableUser);
                setState(state => ({
                    ...state,
                    isLoading: false,
                    questionnaire,
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback(meta, payload, false);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
        }
    }, [failureCallback, isControllableUser, state.isLoading, successCallback]);

    useEffect(
        () => effect(questionnaireViewConnection),
        [effect, questionnaireViewConnection],
    );
    useEffect(
        () => effect(questionnaireControlViewConnection),
        [effect, questionnaireControlViewConnection],
    );

    const request = useCallback((
        questionnaireUuid: string,
        questionUuid: string,
    ) => {
        const action = isControllableUser ?
            Actions.http.connection.questionnaires.controlView :
            Actions.http.connection.questionnaires.view;

        dispatch(action(questionnaireUuid, questionUuid));
        setState(state => ({
            ...state,
            isLoading: true,
        }));

    }, [dispatch, isControllableUser]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
            questionnaire: undefined,
        });
    }, []);

    return [state, request, reset] as const;
};

export default useViewQuestion;

type ResponseResult = Http.Connection.Response.Questionnaire.Control.View['result'] |
    Http.Connection.Response.Questionnaire.Admin.View['result']


const questionConvert = (question: ResponseResult['items'][number]): Question => {
    return {
        answerCandidates: question.answer_candidates.map(c => ({
            answerCount: c.answer_count ?? undefined,
            content: c.content,
            createdAt: c.created_at,
            order: c.order,
            updatedAt: c.updated_at,
            uuid: c.uuid,
        })),
        answerCount: question.answer_count ?? undefined,
        answers: question.answers,
        content: question.content,
        createdAt: question.created_at,
        detail: question.detail,
        file: question.file ?
            {
                fileName: question.file.file_name,
                uuid: question.file.uuid,
            } :
            undefined,
        isRequired: question.is_required,
        order: question.order,
        questionItemBranchChoices: question.question_item_branch_choices,
        type: question.type,
        updatedAt: question.updated_at,
        uuid: question.uuid,
    };
};

const questionnaireConvert = (questionnaire: ResponseResult, isControllableUser: boolean): Questionnaire => {
    const baseCategory = {
        canSetForced: questionnaire.category.can_set_forced,
        createdAt: questionnaire.category.created_at,
        createdBy: questionnaire.category.created_by,
        description: questionnaire.category.description,
        name: questionnaire.category.name,
        order: questionnaire.category.order ?? undefined,
        updatedAt: questionnaire.category.updated_at ?? undefined,
        updatedBy: questionnaire.category.updated_by,
        uuid: questionnaire.category.uuid,
    };

    const base = {
        afterMessage: questionnaire.after_message,
        canBeAnsweredOnlyOnce: questionnaire.can_be_answered_only_once,
        content: questionnaire.content,
        createdAt: questionnaire.created_at,
        createdBy: questionnaire.created_by,
        isAnonymous: questionnaire.is_anonymous,
        isEditable: questionnaire.is_editable,
        isForced: questionnaire.is_forced,
        isLocked: questionnaire.is_locked,
        isPublic: questionnaire.is_public,
        isPublicResult: questionnaire.is_public_result,
        items: questionnaire.items.map(questionConvert),
        memo: questionnaire.memo ?? undefined,
        name: questionnaire.name,
        performEndAt: questionnaire.perform_end_at,
        performStartAt: questionnaire.perform_start_at,
        publishEndAt: questionnaire.publish_end_at,
        publishFrom: questionnaire.publish_from,
        publishResultEndAt: questionnaire.publish_result_end_at,
        publishResultStartAt: questionnaire.publish_result_start_at,
        publishStartAt: questionnaire.publish_start_at,
        statusPeriod: questionnaire.status_period,
        updatedAt: questionnaire.updated_at,
        updatedBy: questionnaire.updated_by,
        uuid: questionnaire.uuid,
    };

    if(isControllableUser) {
        const q = questionnaire as Http.Connection.Response.Questionnaire.Control.View['result'];
        return {
            ...base,
            canAdminSetPublic: q.can_admin_set_public ?? false,
            category: {
                ...baseCategory,
                kind: 'control',
            },
            kind: 'control',
            publishToAllOrganizations: q.publish_to_all_organizations,
            targetOrganizations: q.target_organizations,
            targetPermissions: q.target_permissions,
        };
    } else {
        const q = questionnaire as Http.Connection.Response.Questionnaire.Admin.View['result'];
        return {
            ...base,
            canAdminSetPublic: q.can_admin_set_public ?? undefined,
            category: {
                ...baseCategory,
                isPublishedItem: q.category.is_published_item,
                kind: 'admin',
            },
            isPublishedItem: q.is_published_item,
            kind: 'admin',
            targetRoles: q.target_roles,
            targetSchoolClasses: q.target_school_classes,
            targetUsers: q.target_users,
        };
    }
};
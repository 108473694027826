import { useContext, useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import { getDefaultLayout } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';

import { SearchContext } from './OrganizationList';

const Form = BaseForm;
const layout = getDefaultLayout();

const OrganizationSearch = () => {
    const [search, setSearch] = useContext(SearchContext);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);

    useEffect(() => {
        setLoading(organizationSearchConnection.meta.fetch);
    }, [organizationSearchConnection]);

    function onFinish(values: Http.Connection.Request.Parameter.Organization.Search) {
        const params = {
            ...search,
            ...values,
            page: 1,
        };
        setSearch(params);
    }

    function onReset() {
        form.resetFields();
    }

    return (
        <div className='card general-search notification-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-notification-search' onFinish={onFinish}>
                <Form.Item label='学校名' name='name'>
                    <Input placeholder='学校名を入力してください' />
                </Form.Item>
                <div className='submit-container'>
                    <Button htmlType='button'
                        loading={loading}
                        onClick={onReset}
                    >
                        リセット
                    </Button>
                    <Button htmlType='submit'
                        icon={<SearchOutlined />}
                        loading={loading}
                        type='primary'
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default OrganizationSearch;
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PlusCircleFilled, } from '@ant-design/icons';
import { Button } from 'antd';
import { useRouteMatch } from 'react-router-dom';

import 'styles/pages/questionnaire.scss';
import { BaseTable } from 'components/modules/BaseTable';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { questionnaireTabSelectors } from 'flex/view/Questionnaire/QuestionnaireTab/selectors';

import EditQuestionnaireModal from '../Modal/EditQuestionnaireModal';
import FullScreenResultQuestionnaireModal from '../Modal/FullScreenResultQuestionnaireModal';
import QuestionnaireStatusModal from '../Modal/QuestionnaireStatusModal';
import ResultQuestionnaireModal from '../Modal/ResultQuestionnaireModal';
import QuestionnaireSearch from '../QuestionnaireSearch';
import { createColumns } from './utils/createColumns';
import { dataSourceConvert } from './utils/dataSourceConvert';


const QuestionnaireList = ({
    hasTouchScreen,
}) => {
    const match = useRouteMatch('/questionnaire/result/:uuid');
    const tabSelector = useAppSelector(state => state.questionnaireTab);
    const {
        isTargetOpen,
        uuid,
        isAnonymous,
    } = useAppSelector(state => state.questionnaireTab.modal);
    const { tableLoading } = useAppSelector(questionnaireTabSelectors.loading);
    const questionnaireDeleteConnection = useAppSelector(state => state.questionnaireDeleteConnection);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const dispatch = useDispatch();
    const [isOpenFullScreenResult, setIsOpenFullScreenResult] = useState(false);
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [uuidOfFullScreenResult, setUuidOfFullScreenResult] = useState('');
    const [selectedUuid, setSelectedUuid] = useState('');
    const [isCreate, setIsCreate] = useState(false);

    useEffect(() => {
        if (match?.params?.uuid) {
            setUuidOfFullScreenResult(match.params.uuid);
            setIsOpenFullScreenResult(true);
        } else {
            dispatch(Actions.view.viewLogic.questionnaireTab.prepareView());
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const { status } = questionnaireDeleteConnection.meta;
        if (status !== Actions.statusEnum.SUCCESS) return;

        dispatch(Actions.view.viewLogic.questionnaireTab.submitSearch(tabSelector.search));
    }, [dispatch, questionnaireDeleteConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    function handleCreateClick() {
        setIsCreate(true);
        setSelectedUuid('');
        setIsOpenEditModal(true);
        dispatch(Actions.view.viewLogic.questionnaireTab.openCreate());// TODO: 剥がしたい
    }

    function handleEditClick(record) {
        setIsCreate(false);
        setSelectedUuid(record.key);
        setIsOpenEditModal(true);
        dispatch(Actions.view.viewLogic.questionnaireTab.openEdit(record.key));// TODO: 剥がしたい
    }

    function handleCopyClick(record) {
        setIsCreate(true);
        setSelectedUuid(record.key);
        setIsOpenEditModal(true);
        dispatch(Actions.view.viewLogic.questionnaireTab.openCopy(record.key));// TODO: 剥がしたい
    }

    function handleEditModalCancel() {
        setIsOpenEditModal(false);
        dispatch(Actions.view.viewLogic.questionnaireTab.cancelModal());// TODO: 剥がしたい
    }

    function handleResultClick(record) {
        dispatch(Actions.view.viewLogic.questionnaireTab.openResult(record.key));
    }

    function handleFullScreenResultClick(uuid) {
        setIsOpenFullScreenResult(true);
        setUuidOfFullScreenResult(uuid);
    }

    function handleTargetClick(record) {
        dispatch(Actions.view.viewLogic.questionnaireTab.openTarget(record));
    }

    function handleTargetCancel() {
        dispatch(Actions.view.viewLogic.questionnaireTab.cancelModal());
    }

    function handleDeleteClick(uuid) {
        dispatch(Actions.view.viewLogic.questionnaireTab.submitDelete(uuid));
    }

    function handleEditSubmit() {
        setIsOpenEditModal(false);
        dispatch(Actions.view.viewLogic.questionnaireTab.submitEdit());
    }

    function handleModalCancel() {
        dispatch(Actions.view.viewLogic.questionnaireTab.cancelModal());
    }

    function handleFullScreenResultCancel() {
        setIsOpenFullScreenResult(false);
        dispatch(Actions.view.viewLogic.questionnaireTab.cancelModal());
        dispatch(Actions.view.viewLogic.questionnaireTab.prepareView());
    }

    const columns = createColumns(
        hasTouchScreen,
        handleEditClick,
        handleCopyClick,
        handleResultClick,
        handleTargetClick,
        handleDeleteClick,
        isControllableUser
    );

    return (
        <div className='notifications-list container'>
            <QuestionnaireSearch />

            <div className='flex-right-container gutter-bottom'>
                <Button
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                    type='primary'
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                className='mt-1'
                columns={columns}
                dataSource={dataSourceConvert(tabSelector.questionnaireList)}
                emptyDescription='配信されているアンケートはありません'
                loading={tableLoading}
                pagination={{
                    ...tabSelector.pagination,
                    onChange: (page, page_size) => {
                        dispatch(Actions.view.viewLogic.questionnaireTab.submitPage(page, page_size));
                    },
                    showTotal: (total, range) => {
                        return (`全${total}件中 ${range[0]}-${range[1]} 件`);
                    },
                }}
                rowKey='key'
                scroll={{ x: 2400 }}
            />
            <EditQuestionnaireModal
                isOpen={isOpenEditModal}
                onCancel={handleEditModalCancel}
                onOk={handleEditSubmit}
                shouldCreate={isCreate}
                categoryUuid={selectedUuid}
            />
            <ResultQuestionnaireModal
                onCancel={handleModalCancel}
                onClickFullScreen={handleFullScreenResultClick}
            />
            <FullScreenResultQuestionnaireModal
                isOpen={isOpenFullScreenResult}
                onCancel={handleFullScreenResultCancel}
                uuid={uuidOfFullScreenResult}
            />
            <QuestionnaireStatusModal
                isAnonymous={isAnonymous}
                onCancel={handleTargetCancel}
                uuid={uuid}
                visible={isTargetOpen}
            />
        </div>
    );
};

export default QuestionnaireList;

import React, { useEffect, useState } from 'react';
import { Collapse, CollapseProps, CollapsePanelProps } from 'antd';
import classNames from 'classnames';
import './FormCard.css';

const { Panel } = Collapse;

const DEFAULT_KEY = 'form';

type Props = Omit<CollapseProps, 'defaultActiveKey' | 'activeKey' | 'accordion' | 'children'> & {
    noStyle?: boolean
    title: string
    panelProps?: Omit<CollapsePanelProps, 'isActive' | 'header'>
    isView?: boolean
    noHeaderStyle: boolean
    isOpen?: boolean
};

export const FormCard: React.FC<Props> = ({
    children,
    noStyle = false,
    title,
    panelProps,
    isView = true,
    noHeaderStyle = false,
    isOpen = false,
    ...collapseProps
}) => {

    const [activeKey, setActiveKey] = useState<string | string[]>(DEFAULT_KEY);

    useEffect(() => {
        if(!isView) setActiveKey('')
        if (isOpen) setActiveKey(DEFAULT_KEY);
    }, [isOpen, isView]);

    if (!isView) return <></>;

    if (noStyle) return <>{children}</>;

    return (
        <Collapse
            {...collapseProps}
            className={classNames(
                'form-card',
                collapseProps.className,
                { 'no-header-style': noHeaderStyle }
            )}
            activeKey={activeKey}
            onChange={key => setActiveKey(key)}
        >
            <Panel
                {...panelProps}
                forceRender
                header={title}
                key={DEFAULT_KEY}
                className={classNames(
                    'form-card-panel',
                    panelProps?.className,
                )}
            >
                {children}
            </Panel>
        </Collapse>
    );
};

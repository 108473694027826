import * as Actions from 'flex/Actions';

export const answerResultModalViewLogic = (store, next, action) => {
    const modalStateInParent = store.getState()['resultQuestionnaire']['modal'];
    const item = modalStateInParent['item'];
    const viewState = store.getState()['answerResultModal'];
    const pagination = viewState['pagination'];
    const isControllableUser = store.getState()['isControllableUser'];

    switch (action.type) {
        case Actions.view.viewLogic.answerResultModal.prepareView.toString():
            if (item) {
                if (item.type === 3) {
                    const textResultAction = isControllableUser.payload ?
                        Actions.http.connection.questionnaires.controlTextResult:
                        Actions.http.connection.questionnaires.textResult;

                    next(textResultAction(
                        item.uuid,
                        { page_size: pagination.pageSize },
                        pagination.current,
                        'answerResultModal'
                    ));
                } else if (item.type === 4) {
                    const fileResultAction = isControllableUser.payload ?
                        Actions.http.connection.questionnaires.controlFileResult:
                        Actions.http.connection.questionnaires.fileResult;
                    next(fileResultAction(
                        item.uuid,
                        { page_size: pagination.pageSize },
                        pagination.current,
                        'answerResultModal'
                    ));
                }
            }
            break;

        case Actions.view.viewLogic.answerResultModal.submitPage.toString():
            if (item) {
                next(
                    Actions.view.viewLogic.answerResultModal.savePage(
                        action.payload.page,
                        action.payload.page_size
                    )
                )
                if (item.type === 3) {
                    next(
                        Actions.http.connection.questionnaires.textResult(
                            item.uuid,
                            { page_size: action.payload.page_size },
                            action.payload.page,
                            'answerResultModal'
                        )
                    )
                } else if (item.type === 4) {
                    next(
                        Actions.http.connection.questionnaires.fileResult(
                            item.uuid,
                            { page_size: action.payload.page_size },
                            action.payload.page,
                            'answerResultModal'
                        )
                    )
                }
            }
            break;

        default:
            next(action)
            break;
    }
}


export const editUserInSavingData = (store, next, action) => {

	const editUserState = store.getState()["editUser"]
	const globalStateSelector = store.getState()["globalState"]

	if(action.type.match(/SELECT_TERMS/)){
		let selectTerms = editUserState.selectTerms.slice()
		switch (action.payload.operationType) {
			case "ADD":
				selectTerms.push(globalStateSelector.currentTerm)
				break;
			case "REMOVE":
				selectTerms.splice(action.payload.index, 1)
				break;
			case "REPLACE":
				selectTerms.splice(action.payload.index, 1, action.payload.term_uuid)
				break;
			case "INITIALIZE":
				const record = store.getState()['userManagementTab']["editUser"]["userRecord"]
				const belongTerms = record && record.belongs.length !== 0 ?
					record.belongs.map(value => value.school_class.term.uuid):[]
				selectTerms = belongTerms
				break;
			default:
				throw 'operationType is not valid'
		}
		next({
			type: "editUser",
			...editUserState,
			selectTerms: selectTerms
		})
	}
	if(action.type.match(/SELECT_ORGANIZATION/)){
		next({
			...editUserState,
			// 年度や、役割は初期化する
			classListMap: {},
			classListMapItemCount : {},
			classListMapListCount :0,
			roleList : [],
			roleListItemCount: 0,
			selectOrganization: action.payload.organization_uuid,
			type: "editUser",
		})
	}
	if(action.type.match(/RESET_CACHE/)){

		const newState =  {
			...editUserState,
			isLocal: false,
			selectTerms:[],
			classListMap: {},
			classListMapItemCount : {},
			classListMapListCount :0,
			roleList : [],
			roleListItemCount: 0,
			selectOrganization: '',
			type: "editUser",
		}
		next(newState)

	}
	if(action.type.match(/SET_IS_LOCAL/)){
		next({
			...editUserState,
			isLocal: action.payload.isLocal,
		})
	}
}

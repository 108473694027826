import { createAction } from 'redux-actions';
import {
    API_LTI_APP_TEMPLATE,
    API_LTI_APP_TEMPLATE_FOR_ADMIN,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/APPS/TEMPLATE';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page, viewName) => ({ method: 'get', api: `${API_LTI_APP_TEMPLATE}/${page}`, data }),
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const searchForAdmin = createAction(
    createActionType('SEARCH_FOR_ADMIN' as const),
    (data, page, viewName) => ({ method: 'get', api: `${API_LTI_APP_TEMPLATE_FOR_ADMIN}/${page}`, data }),
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);

export const createForAdmin = createAction(
    createActionType('CREATE_FOR_ADMIN' as const),
    (data) => {
        const {baseTemplateUuid, ...body} = data;
        return { method: 'put', api: `${API_LTI_APP_TEMPLATE_FOR_ADMIN}/create/${baseTemplateUuid}`, data: body };
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const updateForAdmin = createAction(
    createActionType('UPDATE_FOR_ADMIN' as const),
    (uuid, data) => ({ method: 'post', api: API_LTI_APP_TEMPLATE_FOR_ADMIN + '/update/' + uuid, data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const deleteForAdmin = createAction(
    createActionType('DELETE_FOR_ADMIN' as const),
    (uuid) => ({ method: 'delete', api: API_LTI_APP_TEMPLATE_FOR_ADMIN + '/delete/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const viewForAdmin = createAction(
    createActionType('VIEW_FOR_ADMIN' as const),
    (uuid) => ({ method: 'get', api: API_LTI_APP_TEMPLATE_FOR_ADMIN + '/view/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
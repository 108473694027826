import * as Actions from "../../../Actions"


export const editUserInViewLogic = (store, next, action) => {

	const editUserState = store.getState()["editUser"]
	const isControllableUser = store.getState()["isControllableUser"]

	if(action.type.match(/OPEN_VIEW/)){
		const editUser =  store.getState()['userManagementTab']["editUser"]
		if(editUser.isEditOpen){
			const record = editUser["userRecord"]

			if(isControllableUser.payload){
				const organization_uuid = record?.belongs?.[0]?.school_class?.organization?.uuid ?? '';
				if(organization_uuid) {
					next(Actions.view.viewLogic.editUser.selectOrganization(organization_uuid));
					store.dispatch(Actions.view.viewLogic.editUser.searchRole(organization_uuid));
				}
			} else {
				store.dispatch(Actions.view.viewLogic.editUser.searchRole());
			}

			if(record){
				store.dispatch(Actions.view.viewLogic.editUser.selectTerms('INITIALIZE'))
				next(Actions.view.viewLogic.editUser.setIsLocal(record.is_local))
			}
		}
	}
	if(action.type.match(/SELECT_TERMS/)){
		next(action)

		const classListMap = editUserState['classListMap']
		const selectOrganization = editUserState['selectOrganization']
		const globalStateSelector = store.getState()["globalState"]

		let selectTerms = Array.isArray(editUserState.selectTerms) ? editUserState.selectTerms.slice() : []
		switch (action.payload.operationType) {
			case "ADD":
				selectTerms.push(globalStateSelector.currentTerm)
				break;
			case "REMOVE":
				break;
			case "REPLACE":
				selectTerms.splice(action.payload.index, 1, action.payload.term_uuid)
				break;
			case "INITIALIZE":
				const record = store.getState()['userManagementTab']["editUser"]["userRecord"]
				const belongTerms = record.belongs.length !== 0 ?
					record.belongs.map(value => value.school_class.term.uuid):[]
				selectTerms = belongTerms
				break;
			default:
				throw 'operationType is not valid'
		}
		const requestedTermUuidList = []
		selectTerms.forEach((term_uuid) => {
			if(!classListMap || (!classListMap[term_uuid] && !requestedTermUuidList.includes(term_uuid))){
				if (selectOrganization) {
					next(Actions.http.connection.classes.searchForListMap(
						{
							page_size: "1000",
							organization_uuid: selectOrganization,
							term_uuid: term_uuid
						},
						undefined,
						term_uuid,
						'editUser'
					));
				}else{
					next(Actions.http.connection.classes.searchForListMap(
						{
							page_size: "1000",
							term_uuid: term_uuid
						},
						undefined,
						term_uuid,
						'editUser'
					));
				}
				requestedTermUuidList.push(term_uuid)
			}
		})
	}
	if(action.type.match(/SEARCH_ROLE/)){
		if (action.payload.uuid) {
			next(Actions.http.connection.role.search({page_size: "1000", organization_uuid: action.payload.uuid}, undefined, 'editUser'));
		} else {
			next(Actions.http.connection.role.search({page_size: "1000"}, undefined, 'editUser'));
		}
	}
	if(action.type.match(/RESET_CACHE/)){
		next(action)
	}
	if(action.type.match(/SET_IS_LOCAL/)){
		next(action)
	}
	if(action.type.match(/SELECT_ORGANIZATION/)){
		next(action)
	}
	if(action.type.match(/SUBMIT/)){
		const values = Object.assign({},action.payload.values)
		if (!values.password) {
			delete values.password;
		}
		const editUserInTab = store.getState()['userManagementTab']["editUser"]
		if (editUserInTab.shouldCreate) {
			next(Actions.http.connection.users.create(values));
		} else {
			next(Actions.http.connection.users.update(editUserInTab.userRecord.uuid, values));
		}
	}
}

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { FormValues, TERM_TYPE, TermTypes } from '../SearchForm';

const moment = getMoment();

export type SearchExamCategoryWithResultState = {
    result: {
        name: string
        count: number
    }[]
    isLoading: boolean
    term: moment.Moment | undefined
    termType: TermTypes | undefined
};


export const useGraphExamCategory = (tab: TabKeys | '') => {
    const [state, setState] = useState<SearchExamCategoryWithResultState>({
        isLoading: false,
        result: [],
        term: undefined,
        termType: undefined,
    });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamCategoryGraphConnection = useAppSelector(state => state.taoExamCategoryGraphConnection);
    const taoExamCategoryControlGraphConnection = useAppSelector(state => state.taoExamCategoryControlGraphConnection);

    const dispatch = useDispatch();


    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryGraphConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;
        // 許可されたタブの通信でない === 意図しないタイミング
        if (tab === undefined || !['school'].includes(tab)) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: state.term && state.termType ?
                        createTimeKeys(state.term, state.termType).map(([key, val]) => ({
                            count: result.items[key] ?? 0,
                            name: val,
                        })) :
                        [],
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryGraphConnection]);

    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryControlGraphConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;
        // 許可されたタブの通信でない === 意図しないタイミング
        if (tab === undefined || !['board', ''].includes(tab)) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: state.term && state.termType ?
                        createTimeKeys(state.term, state.termType).map(([key, val]) => ({
                            count: result.items[key] ?? 0,
                            name: val,
                        })) :
                        [],
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlGraphConnection]);


    const fetchData = useCallback((value: FormValues, page = 1, pageSize = 10) => {
        setState({
            isLoading: true,
            result: [],
            term: value.date,
            termType: value.termType,
        });

        const { answerRate, date, isOnlyDeliverySelf, subject, termType, title } = value;

        const average_per_end = answerRate !== undefined ? (Number(answerRate) + 1) * 20 : undefined;
        const average_per_start = answerRate !== undefined ? Number(answerRate) * 20 : undefined;
        const start_at = date !== undefined ? moment(date).startOf(termType).unix() : undefined;
        const end_at = date !== undefined ? moment(date).endOf(termType).unix() : undefined;

        const data = {
            average_per_end,
            average_per_start,
            end_at,
            is_mine: isOnlyDeliverySelf ? 1 as const : 0 as const,
            page_size: pageSize,
            start_at,
            subject_id: subject,
            time_unit: termType,
            title,
        };

        const graphAction = isControllableUser ?
            Actions.http.connection.tao.examCategory.controlGraph :
            tab === 'board' ?
                Actions.http.connection.tao.examCategory.controlGraph :
                Actions.http.connection.tao.examCategory.graph;

        dispatch(graphAction(data, page));

    }, [dispatch, isControllableUser, tab]);

    return [state, fetchData] as const;
};


const TIME_KEY_FORMAT_TYPE_DAY = 'MM/DD(ddd)';
const BACKEND_TIME_KEY_FORMAT_TYPE_DAY = 'YYYY-MM-DD';


// src\components\presentational\pages\Usage\Common\utils.js
export const createTimeKeys = (term: moment.Moment, termType: TermTypes) => {
    const start = term.startOf(termType);

    switch (termType) {
        case TERM_TYPE.MONTH: {
            const s = moment(term).startOf('month').date();
            const e = moment(term).endOf('month').date();

            const diff = e - s + 1;
            return [...Array(diff)].map((_, i) => [
                moment(start).add(i, 'days').format(BACKEND_TIME_KEY_FORMAT_TYPE_DAY),
                moment(start).add(i, 'days').format(TIME_KEY_FORMAT_TYPE_DAY),
            ]);
        }
        case TERM_TYPE.WEEK:
            return [...Array(7)].map((_, i) => [
                moment(start).add(i, 'days').format(BACKEND_TIME_KEY_FORMAT_TYPE_DAY),
                moment(start).add(i, 'days').format(TIME_KEY_FORMAT_TYPE_DAY),
            ]);
        case TERM_TYPE.DATE:
            return [...Array(12)].map((_, i) =>
                [
                    `${2 * i}-${2 * (i + 1)}時`,
                    `${2 * i}-${2 * (i + 1)}時`,
                ]
            );
        default:
            return [];
    }
};
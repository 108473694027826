import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

const moment = getMoment();

export type SaveCommentState = {
    isLoading: boolean
    result: {
        text: string | undefined
        updatedAt: string | undefined
    } | undefined,
};

type Connection = TaoExamSaveCommentConnection | TaoExamControlSaveCommentConnection;

export const useSaveComment = () => {
    const [state, setState] = useState<SaveCommentState>({
        isLoading: false,
        result: undefined,
    });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamSaveCommentConnection = useAppSelector(state => state.taoExamSaveCommentConnection);
    const taoExamControlSaveCommentConnection = useAppSelector(state => state.taoExamControlSaveCommentConnection);
    const dispatch = useDispatch();


    const effect = useCallback((connection: Connection, state: SaveCommentState) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState({
                    isLoading: false,
                    result: {
                        text: payload.result.comment ?? '',
                        updatedAt: payload.result.updated_at ?
                            moment.unix(payload.result.updated_at).format('YYYY/MM/DD HH:mm:ss') :
                            undefined,
                    },
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => effect(taoExamSaveCommentConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamSaveCommentConnection]
    );
    useEffect(
        () => effect(taoExamControlSaveCommentConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlSaveCommentConnection]
    );


    const fetchData = useCallback((
        examUuid: string,
        userUuid: string,
        historyUuid: string,
        data: { comment: string | undefined },
        tab: TabKeys | ''
    ) => {
        setState({
            isLoading: true,
            result: undefined,
        });

        const action = isControllableUser ?
            Actions.http.connection.tao.exam.controlSaveComment :
            tab === 'board' ?
                Actions.http.connection.tao.exam.controlSaveComment :
                Actions.http.connection.tao.exam.saveComment;

        dispatch(action(examUuid, userUuid, historyUuid, data));

    }, [dispatch, isControllableUser]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
            result: undefined,
        });
    }, []);

    return [state, fetchData, reset] as const;
};

import { useState } from 'react';

import { TablePaginationConfig } from 'antd';

const INIT_STATE: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '50', '100'],
    position: ['topRight', 'bottomRight'],
    showSizeChanger: true,
    showTotal: (total, range) => `全${total}件中 ${range[0]}-${range[1]} 件`,
    total: 0,
};


export const usePagination = (initPagination: TablePaginationConfig) => {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        ...INIT_STATE,
        ...initPagination,
    });

    // initPagination を保持し、リセットのタイミングで使用するための state
    const [init] = useState<TablePaginationConfig>({
        ...INIT_STATE,
        ...initPagination,
    });

    /**
     * ページネーションを最初に指定された値で初期化する
     */
    const reset = () => setPagination(init);

    return [pagination, setPagination, reset] as const;
};

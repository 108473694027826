
import * as Actions from 'flex/Actions';

/***
 * @param store
 * @param next
 * @param action
 */
export const editQuestionSavingData = (store, next, action) => {
    const viewState = store.getState()['editQuestion'];
    const editQuestionInParent = store.getState()['editQuestionnaire']['editQuestion'];
    const editableItems = store.getState()['editQuestionnaire']['editableItems'];

    switch (action.type) {
        case Actions.view.viewLogic.editQuestion.prepareView.toString(): {
            const selectParent = editQuestionInParent.parentTmpItemId ?
                editQuestionInParent.parentTmpItemId :
                null;
            const selectParentCandidateOptions = selectParent ?
                editableItems.find(item => item.tmpItemId === selectParent).answer_candidates :
                [];

            next({
                ...viewState,
                selectParent,
                selectParentCandidateOptions,
                type: 'editQuestion'
            })
            break
        }
        case Actions.view.viewLogic.editQuestion.selectParent.toString():
            const selectParent = action.payload.parentTmpItemId;
            const selectParentCandidateOptions = selectParent ?
                editableItems.find(item => item.tmpItemId === selectParent).answer_candidates :
                [];

            next({
                ...viewState,
                selectParent,
                selectParentCandidateOptions,
                type: 'editQuestion'
            })
            break
        default:
            break
    }
}

import { ColumnsType } from 'antd/lib/table';

import { getMoment } from 'constants/GlobalConfig';

const moment = getMoment();

type TimeSeriesCountData = Record<string, Record<string, { count: number, name: string }>>;
type TargetTotalsInfo = Http.Connection.Response.EverydayNote.ResultSubTotal['result']['items'][number];
type TotalTimeSeries = Http.Connection.Response.EverydayNote.ResultTotal['result']['items'];
type Candidate = TotalTimeSeries[string][number];
type TermInfo = {
    base: number;
    type: EverydayNote.ResultTotal.TermTypes;
};

export const FIVE_LEVEL_CONTENTS = ['とても良い', '良い', '普通', '良くない', 'とても良くない'];

const KEY_FORMAT_TYPE = 'MM/DD (ddd)';
const BACKEND_KEY_FORMAT_TYPE = 'YYYY-MM-DD';

const CANDIDATE_PREFIX: EverydayNote.ResultTotal.CandidatePrefix = 'candidate___';

/**
 * string 型になっている選択肢の選択された数を number 型に変換し、
 * object の key を 「CandidatePrefix + 選択肢のUUID」に変換する
 */
const convertCandidateData = (candidate: Candidate | EverydayNote.Candidate, index?: number) => {
    const count = 'count' in candidate ? Number(candidate.count) : 0;
    const uuid = candidate.uuid ?? '';

    return {
        [`${CANDIDATE_PREFIX}${uuid === '' ? index : uuid}`]: {
            count,
            name: candidate.content,
        },
    };
};

/**
 * 顔マーク5段階はバックエンドからの返り値が string 型の数値なので、
 * フロントエンドで使いやすい文章での表現に変換する
 */
const convertFiveLevelContent = (questionType: EverydayNote.QuestionTypes) => (
    candidate: Candidate | EverydayNote.Candidate
) => {
    if (questionType !== 4) return candidate;

    const numOfContent = Number(candidate.content);

    if (isNaN(numOfContent)) {
        return {
            ...candidate,
            content: '不明',
        };
    }

    return {
        ...candidate,
        content: FIVE_LEVEL_CONTENTS[5 - numOfContent],
    };
};

/**
 * 足りていない設問を補完する
 */
const completedCandidates = (candidates: Candidate[], question: EverydayNote.Question) => {
    switch (question.type) {
        case 1:
        case 2:
        case 7:
            return question.candidates.map(candidate => {
                const findCandidate = candidates.find(c => candidate.uuid === c.uuid);
                if (findCandidate === undefined) {
                    return {
                        content: candidate.content,
                        count: '0',
                        uuid: candidate.uuid,
                    };
                } else {
                    return findCandidate;
                }
            });
        case 3: {
            // 自由記述は UUID を含まず、content も入力内容次第なので、両方無視してカウントする。
            const data = candidates.reduce((prev, current) => {
                const count = Number(current.count);
                if (isNaN(count)) return prev;

                return {
                    ...prev,
                    count: `${Number(prev.count) + count}`,
                };
            }, { content: '', count: '0', uuid: '' });
            return [data];
        }
        case 4:
            // 顔マーク5段階は UUID を含まないので、 content で判断する必要がある。
            return ['5', '4', '3', '2', '1'].map(content => {
                const findCandidate = candidates.find(c => content === c.content);
                if (findCandidate === undefined) {
                    return {
                        content: content,
                        count: '0',
                        uuid: '',
                    };
                } else {
                    return findCandidate;
                }
            });
        case 5:
            // 使ってない
            return [];
        case 6: {
            const scale = Number(question.evaluationScaleMax ?? '0');

            return [...Array(scale)]
                .map((_, i) => `${i + 1}`)
                .map(content => {
                    const findCandidate = candidates.find(c => content === c.content);
                    if (findCandidate === undefined) {
                        return {
                            content,
                            count: '0',
                            uuid: '',
                        };
                    } else {
                        return findCandidate;
                    }
                });
        }
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = question.type;
            return [];
        }
    }
};

/**
 * バックエンドからのレスポンスをグラフ用に変換する
 *
 * この時点だと回答ががない日付のデータは抜けたままになる
 *
 * ### サンプル
 * ```
 * {
 *      '2022-12-20': [
 *          { content: '4', count: '3', uuid: '...' },
 *          { content: '3', count: '1', uuid: '...' },
 *          { content: '2', count: '1', uuid: '...' },
 *          { content: '1', count: '1', uuid: '...' },
 *      ],
 *      '2022-12-21': [...],
 *      ...
 * }
 * ```
 *          ↓ 変換
 *
 * ```
 * {
 *      '12/20 (火)': {
 *          'candidate___とても良い': 0,
 *          'candidate___良い': 3,
 *          'candidate___普通': 1,
 *          'candidate___良くない': 1,
 *          'candidate___とても良くない': 1,
 *      },
 *      '12/21 (水)': [...],
 *      ...
 * }
 * ```
 */
const convertTotalTimeSeriesToGraph = (
    totalTimeSeries: TotalTimeSeries,
    question: EverydayNote.Question,
) => {
    const entries = Object.entries(totalTimeSeries)
        .map(([key, candidates]) => {
            const time = moment(key, BACKEND_KEY_FORMAT_TYPE);

            if (!time.isValid()) return undefined;

            const newKey = time.format(KEY_FORMAT_TYPE);

            const value = completedCandidates(candidates, question)
                .map(convertFiveLevelContent(question.type))
                .map(convertCandidateData)
                .reduce((pre, current) => ({ ...pre, ...current }), {});

            return [newKey, value] as const;
        })
        .filter(e => e) as [string, { [x: string]: { count: number, name: string }; }][];

    return Object.fromEntries(entries) as TimeSeriesCountData;
};

/**
 * `term` を含む 1 `termType` (`month`, `week`, `date`) 分のラベルの配列を生成する
 */
const createXAxisLabelsOfTimeSeries = (term: moment.Moment, termType: TermTypes) => {
    switch (termType) {
        case 'month': {
            const startAt = moment(term).startOf('month');
            const dayCount = Number(term.endOf('month').format('D'));
            if (isNaN(dayCount)) return [];

            return [...Array(dayCount)].map((_, i) => {
                return moment(startAt).add(i, 'day').format(KEY_FORMAT_TYPE);
            });
        }
        case 'week': {
            const startAt = moment(term).startOf('week');
            return [...Array(7)].map((_, i) => {
                return moment(startAt).add(i, 'day').format(KEY_FORMAT_TYPE);
            });
        }
        case 'date': {
            return [moment(term).format(KEY_FORMAT_TYPE)];
        }
        default:
            return [];
    }
};

/**
 * バックエンドからのレスポンスを元にグラフ用のデータを生成する
 */
export const createTimeSeries = (
    totalTimeSeries: TotalTimeSeries,
    question: EverydayNote.Question,
    term: TermInfo | undefined,
): EverydayNote.ResultTotal.TimeSeries => {

    const keyChangedData = convertTotalTimeSeriesToGraph(totalTimeSeries, question);

    const base = term?.base ? moment.unix(term.base) : moment();
    const type = term?.type ?? 'week';

    const timeSeries = createXAxisLabelsOfTimeSeries(base, type);

    // 日表示の場合、棒グラフの幅を広くなってしまうので、無理矢理調整する
    const formattedTimeSeries = type === 'date' ?
        ['', '', ...timeSeries, '', ''] :
        timeSeries;

    const data = createDataSource(question)
        .map(convertCandidateData)
        .reduce((pre, current) => ({ ...pre, ...current }), {});

    return formattedTimeSeries.map(label => {
        // グラフに表示する際には、月を含めるとラベルが入り切らなくなってしまうので、
        // 日 と 曜日 だけ表示するようにする。 #2414
        const newLabel = label.split('/')[1] ?? '';

        if (keyChangedData[label]) {
            return {
                name: newLabel,
                ...keyChangedData[label],
            };
        } else {
            return {
                name: newLabel,
                ...data,
            };
        }
    });
};

/**
 * /result-sub-total のレスポンスうち時系列部分を
 * ConfirmAnswerResultModal におけるテーブルの表示に適した形式に変換する
 */
const createSubTotalData = (
    totals: TargetTotalsInfo['totals'],
    question: EverydayNote.Question,
    term: TermInfo,
): TimeSeriesCountData => {
    if (Array.isArray(totals)) return {};
    const keyChangedData = convertTotalTimeSeriesToGraph(totals, question);

    const dataSource = createDataSource(question);

    const entries = createXAxisLabelsOfTimeSeries(moment.unix(term.base), term.type).map(label => {
        if (keyChangedData[label]) {
            return [label, keyChangedData[label]];
        } else {
            const countOfCandidateObj = dataSource
                .map(convertCandidateData)
                .reduce((pre, current) => ({ ...pre, ...current }), {});

            return [label, countOfCandidateObj];
        }
    });

    return Object.fromEntries(entries);
};

const createDataSource = (question: EverydayNote.Question) => {
    switch (question.type) {
        case 1:
        case 2:
        case 7:
            return question.candidates;
        case 4:
            return FIVE_LEVEL_CONTENTS.map((content, i) => ({
                content,
                order: i,
                uuid: '',
            })) as EverydayNote.Candidate[];
        case 3:
        case 5:
            return [
                {
                    content: question.content,
                    order: question.order,
                    uuid: question.uuid,
                },
            ];
        case 6: {
            const scale = Number(question.evaluationScaleMax ?? '0');

            return [...Array(scale)].map((_, i) => ({
                content: `${i + 1}`,
                order: i,
                uuid: '',
            })) as EverydayNote.Candidate[];
        }
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = question.type;
            return [];
        }
    }
};

/**
 * /result-sub-total のレスポンスを
 * ConfirmAnswerResultModal におけるテーブルの表示に適した形式に変換する
 */
export const convertTableSource = (
    items: TargetTotalsInfo[],
    question: EverydayNote.Question,
    term: TermInfo,
): EverydayNote.SubTotalTableRecord[] => {
    return items.map(item => {
        const totals = Array.isArray(item.totals) ? {} : item.totals;

        const counts = Object.values(totals)
            .flat()
            .map(answer => Number(answer.count))
            .filter(n => !isNaN(n));
        const max = Math.max(0, ...counts);

        const name = 'login_id' in item ?
            item.name === '' ?
                item.login_id :
                item.login_id?.match(/@.+?_.+$/) ?
                    '削除済みユーザー' :
                    item.name :
            item.name;

        const term_name = ('term_name' in item) ?
            item.term_name :
            undefined;

        return {
            max,
            name,
            term_name,
            totals: item.totals,
            uuid: item.uuid,
            ...createSubTotalData(totals, question, term),
        };
    });
};

/**
 * ConfirmAnswerResultModal におけるテーブルのカラム情報を作成する
 */
export const createResultSubTotalColumns = (
    term: moment.Moment,
    termType: TermTypes,
    render: (
        date: string,
        timeSeries: [Record<string, { count: number, name: string }>],
        record: EverydayNote.SubTotalTableRecord
    ) => JSX.Element | null
): ColumnsType<EverydayNote.SubTotalTableRecord> => {
    return createXAxisLabelsOfTimeSeries(term, termType).map(date => {
        return {
            align: 'center',
            dataIndex: date,
            key: date,
            render: (timeSeries: Record<string, { count: number, name: string }>, record) => {
                return render(date, [timeSeries], record);
            },
            title: date,
            width: 10,
        };
    });
};

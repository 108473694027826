import { handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const securityGetConnection = handleAction(
    Actions.http.connection.security.get,
    defaultReducer,
    defaultState
);

export const securityUpdateConnection = handleAction(
    Actions.http.connection.security.update,
    defaultReducer,
    defaultState
);
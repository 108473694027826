import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { convertExamItem, convertExamItemWithResult } from '../utils/convertExamItem';

const moment = getMoment();

export type UserItemResultState = {
    result: {
        comment: {
            text: string | undefined
            updatedAt: string | undefined
        }
        items: {
            createdAt: number
            // 前者だけで十分かもしれないが、確証がないので一応後者とのユニオン型にしておく
            examItem: ReturnType<typeof convertExamItemWithResult> | ReturnType<typeof convertExamItem>
            totalAveragePer: number
            unitAveragePer: number | undefined
            updatedAt: number
        }[]
    } | undefined
    isLoading: boolean
};

type Connection = TaoExamUserItemResultConnection | TaoExamControlUserItemResultConnection;


export const useUserItemResult = () => {
    const [state, setState] = useState<UserItemResultState>({
        isLoading: false,
        result: undefined,
    });

    const taoExamUserItemResultConnection = useAppSelector(state => state.taoExamUserItemResultConnection);
    const taoExamControlUserItemResultConnection = useAppSelector(state => state.taoExamControlUserItemResultConnection);

    const dispatch = useDispatch();


    const effect = useCallback((connection: Connection, state: UserItemResultState) => {
        const { meta, payload } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {

                const updatedAt = payload.result.comment?.updated_at;
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: {
                        comment: {
                            text: payload.result.comment?.comment ?? undefined,
                            updatedAt: updatedAt ? moment.unix(updatedAt).format('YYYY/MM/DD HH:mm:ss') : undefined,
                        },
                        items: payload.result.items.map(item => {
                            return {
                                createdAt: item.created_at,
                                examItem: 'result' in item.exam_item ?
                                    convertExamItemWithResult(item.exam_item) :
                                    convertExamItem(item.exam_item),
                                totalAveragePer: item.total_average_per,
                                unitAveragePer: item.unit_average_per ?? undefined,
                                updatedAt: item.updated_at,
                            };
                        }),
                    },
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(
        () => effect(taoExamUserItemResultConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamUserItemResultConnection]
    );
    useEffect(
        () => effect(taoExamControlUserItemResultConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlUserItemResultConnection]
    );


    const fetchData = useCallback((
        examUuid: string,
        userUuid: string,
        historyUuid: string,
        tab: TabKeys | '',
        schoolClassUuid?: string,
    ) => {
        setState({
            isLoading: true,
            result: undefined,
        });

        const action = tab === 'board' ?
            Actions.http.connection.tao.exam.controlUserItemResult :
            Actions.http.connection.tao.exam.userItemResult;

        dispatch(action(examUuid, userUuid, historyUuid, schoolClassUuid));

    }, [dispatch]);

    return [state, fetchData] as const;
};

export const source = [
    {
        url: 'https://www.office.com/launch/excel',
        image: {
            name: 'Excel.png',
            url: '/static/cooperation_url_icon/Excel.png',
        },
    },
    {
        url: 'https://classroom.google.com/',
        image: {
            name: 'Google Classroom.png',
            url: '/static/cooperation_url_icon/Google Classroom.png',
        },
    },
    {
        url: 'https://www.nhk.or.jp/school/',
        image: {
            name: 'NHK for School.png',
            url: '/static/cooperation_url_icon/NHK for School.png',
        },
    },
    {
        url: 'https://www.office.com/',
        image: {
            name: 'Office.png',
            url: '/static/cooperation_url_icon/Office.png',
        },
    },
    {
        url: 'https://www.office.com/launch/onenote',
        image: {
            name: 'OneNote.png',
            url: '/static/cooperation_url_icon/OneNote.png',
        },
    },
    {
        url: 'https://outlook.office.com/mail/',
        image: {
            name: 'Outlook.png',
            url: '/static/cooperation_url_icon/Outlook.png',
        },
    },
    {
        url: 'https://www.office.com/launch/powerpoint',
        image: {
            name: 'PowerPoint.png',
            url: '/static/cooperation_url_icon/PowerPoint.png',
        },
    },
    {
        url: 'https://www.office.com/launch/word',
        image: {
            name: 'Word.png',
            url: '/static/cooperation_url_icon/Word.png',
        },
    },
    {
        url: 'https://www.mext.go.jp/studxstyle/',
        image: {
            name: 'StuDX Style.png',
            url: '/static/cooperation_url_icon/StuDX Style.png',
        },
    },
    {
        url: 'https://apps.uchida-science.com/',
        image: {
            name: 'Uchida Science Web.png',
            url: '/static/cooperation_url_icon/Uchida Science Web.png',
        },
    },
    {
        url: 'https://kids.yahoo.co.jp/',
        image: {
            name: 'yahoo kids.png',
            url: '/static/cooperation_url_icon/yahoo kids.png',
        },
    },
    {
        url: 'https://typing.playgram.jp/',
        image: {
            name: 'Playgram Typing.jpg',
            url: '/static/cooperation_url_icon/Playgram Typing.jpg',
        },
    },
    {
        url: 'https://www.mext.go.jp/a_menu/ikusei/gakusyushien/index_00001.htm',
        image: {
            name: 'Children Learning Support.jpg',
            url: '/static/cooperation_url_icon/Children Learning Support.jpg',
        },
    },
    {
        url: 'https://www.soumu.go.jp/use_the_internet_wisely/trouble/',
        image: {
            name: 'Internet trouble casebook 2022.png',
            url: '/static/cooperation_url_icon/Internet trouble casebook 2022.png',
        },
    },
    {
        url: 'https://www.mext.go.jp/moral/#/',
        image: {
            name: 'Information moral learning.png',
            url: '/static/cooperation_url_icon/Information moral learning.png',
        },
    },
    {
        url: 'https://www.mext.go.jp/kids/',
        image: {
            name: 'Word.mext kids.jpg',
            url: '/static/cooperation_url_icon/mext kids.jpg',
        },
    },
    {
        url: 'https://kakomon.obunsha.co.jp/c/search-univ',
        image: {
            name: 'obunsha kakomon.png',
            url: '/static/cooperation_url_icon/obunsha kakomon.png',
        },
    },
    {
        url: 'https://dcontents.daj.co.jp/',
        image: {
            name: 'dcontents.png',
            url: '/static/cooperation_url_icon/dcontents.png',
        },
    }
];
import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/APP_TEMPLATE_TAB';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * 画面を開いたときに呼び出す。
 * 全体管理者の作成したアプリテンプレートの一覧とテナント管理者のアプリテンプレートの一覧を取得する。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    () => ({}),
    () => ({})
);

/** 編集モーダルをリクエストする */
export const submitEdit = createAction(
    createActionType('SUBMIT_EDIT' as const),
    () => ({}),
    () => ({})
);


/**
 * 編集モーダルをリクエストする。
 * */
export const submitSearch = createAction(
    createActionType('SUBMIT_SEARCH' as const),
    (values, page, page_size) => ({ values, page, page_size }),
    () => ({})
);

/**
 * ページネーションでリクエストをする。
 * */
export const submitPage = createAction(
    createActionType('SUBMIT_PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

/**
 * 検索を保存する。
 * */
export const saveSearch = createAction(
    createActionType('SAVE_SEARCH' as const),
    (values) => ({ values }),
    () => ({})
);

/**
 * ページネーションを保存する。
 * */
export const savePage = createAction(
    createActionType('SAVE_PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

import { useContext, useEffect, useState } from "react";
import {Table, Button, Input, Col, Tooltip, Popconfirm, Row, Empty} from 'antd'
import {EditOutlined, DeleteOutlined, PlusCircleFilled} from "@ant-design/icons";
import {ListContext} from "./index";
import EditNotification from "./EditNotification";
import EditNotificationCategory from "./EditNotificationCategory";
import * as Actions from "../../../../flex/Actions";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultLayout} from "../../../../constants/GlobalConfig";
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;


const layout = getDefaultLayout()

const NotificationsCategoryList = props => {
    const dispatch = useDispatch();
    const [list, setList] = useContext(ListContext);
    const [form] = Form.useForm();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [shouldCreate, setShouldCreate] = useState(true);
    const [record, setRecord] = useState(null);
    const notificationCategoryEditConnection = useSelector(state => state.notificationCategoryEditConnection);

    useEffect(()=> {
        if(notificationCategoryEditConnection.meta.status === Actions.statusEnum.SUCCESS) {
            setIsEditOpen(false);
            dispatch(Actions.http.connection.notification.category.search());
        }
    },[notificationCategoryEditConnection,dispatch])


    const [pagination, ] = useState({
            showTotal: (total, range) => {
                return (`全${total}件中 ${range[0]}-${range[1]} 件`)
            },
            pageSize: 100,
            total: 0,
            position: ["topRight", "bottomRight"]
        }
    );

    const columns = [
        {
            title: 'カテゴリ名称',
            width: "50%",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '概要',
            width: "40%",
            dataIndex: 'summary',
            key: 'summary',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: "10%",
            fixed: "right",
            render: (_, record) => (

                <><Row type="flex" justify="space-around" align="middle">
                    <Col>
                        <Tooltip title="編集">
                            <Button className="mr-2" shape="circle" size="small" icon={<EditOutlined/>}
                                    onClick={() => handleEditClick(record)}/>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title="カテゴリ削除">
                            <Popconfirm
                                okText="削除"
                                cancelText="キャンセル"
                                onConfirm={() => handleDeleteClick(record.uuid)}
                                title={
                                    <>
                                        <span>カテゴリを削除しますか？</span>
                                    </>
                                }
                            >
                                <Button
                                    className="mr-2" shape="circle" size="small" danger
                                    icon={<DeleteOutlined/>}/>
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>
                </>
            ),
        },
    ];
    function onFinish(values) {
        console.log(values);
    }

    function onReset() {
        form.resetFields();
    }
    function handleCreateClick() {
        setRecord(null)
        setShouldCreate(true)
        setIsEditOpen(true)
    }

    function handleEditClick(record) {
        setRecord(record)
        setShouldCreate(false)
        setIsEditOpen(true)
    }

    function handleDeleteClick(id) {
        dispatch(Actions.http.connection.notification.category.delete(id));
    }

    function handleEditSubmit(postBody) {
        if (shouldCreate) {
            dispatch(Actions.http.connection.notification.category.create(postBody));
        } else {
            dispatch(Actions.http.connection.notification.category.update(record.uuid, postBody));
        }
    }

    function handleEditCancel() {
        setIsEditOpen(false)
    }


    return (
        <div className="notifications-category-list container">
            <div className="card general-search search-form-wrapper">
                <Form {...layout} form={form} name="control-hooks-notification-category-list" onFinish={onFinish}>
                    <Form.Item name="name" label="タイトル">
                        <Input placeholder="カテゴリ名称を入力してください"/>
                    </Form.Item>

                    <Form.Item name="summary" label="タイトル">
                        <Input placeholder="概要を入力してください"/>
                    </Form.Item>
                    <div className={"submit-container"}>
                        <Form.Item>
                            <Button className="mr-4" htmlType="button" onClick={onReset}>
                                キャンセル
                            </Button>
                            <Button type="primary" htmlType="submit">
                                検索
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>

            <div className="flex-right-container gutter-bottom">
                <Button
                    type="primary"
                    icon={<PlusCircleFilled/>}
                    onClick={handleCreateClick}>
                    新規作成
                </Button>
            </div>

            <Table bordered className="mt-1"
                   locale={{
                       filterConfirm: '確定',
                       filterReset: 'リセット',
                       emptyText:  <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={'配信されているカテゴリはありません'}/>,
                   }}
                   columns={columns}
                   width={{x: "100%"}}
                   dataSource={list.categoryList}
                   rowKey={"uuid"}
                   pagination={pagination}/>

            <EditNotificationCategory
                shouldCreate={shouldCreate}
                record={record}
                visible={isEditOpen}
                onOk={handleEditSubmit}
                onCancel={handleEditCancel}
            />
        </div>
    )
}

export default NotificationsCategoryList;

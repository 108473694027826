import { useEffect, useMemo, useState, VFC } from 'react';
import { Select, Button } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { addAllTermAndSort, getCurrentTerm, getMoment, toClassListMapKey, PageSizeOptions } from 'constants/GlobalConfig';
import { BaseTable } from 'components/modules/BaseTable'
import BaseModal from 'components/modules/BaseModal';
import { useAppSelector } from 'flex/utils';
import { ColumnsType, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import { useClassListMap } from '../utils/useClassListMap';

const { Option } = Select;
const moment = getMoment();

type Props = {
    onCancel: () => void,
    onOk: (selectedRows: SchoolClass[]) => void,
    visible: boolean,
};

type Pagination = PartOfRequired<
    TablePaginationConfig,
    'pageSize'
>;

const AddModal: VFC<Props> = ({
    onCancel,
    onOk,
    visible,
}) => {
    const [optionLoading, setOptionLoading] = useState(false);
    const [selectTerm, setSelectTerm] = useState('');
    const [selectedRows, setSelectedRows] = useState<SchoolClass[]>([]);
    const [termList, setTermList] = useState<Term[]>([]);
    const [classListMap, searchClassListMap, ] = useClassListMap();

    const [pagination, setPagination] = useState<Pagination>({
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`)
        },
        total: 0,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        pageSizeOptions: PageSizeOptions,
        pageSize: 50,
        onShowSizeChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                page,
                pageSize: page_size,
            }));
        },
        onChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                page,
                pageSize: page_size ?? 50,
            }));
        }
    });
    const termSearchConnection = useAppSelector(state => state.termSearchConnection);
    const currentTermUuid = useMemo(() => getCurrentTerm(termList).uuid, [termList]);

    useEffect(() => {
        const { meta, payload } = termSearchConnection;

        if (meta.status === Actions.statusEnum.SUCCESS) {
            setTermList(payload.result?.items ?? []);
        }
        if (meta.status === Actions.statusEnum.FAILURE || meta.status === Actions.statusEnum.ERROR) {
            //OPTIMIZE 基本的には汎用エラーコンポーネントがエラーをスポイルしてここでは何も起こらないのが正なので、処理しない
        }
    }, [termSearchConnection]);

    useEffect(() => {
        if (visible) return;

        setPagination(pagination => ({
            ...pagination,
            current: 1,
            page: 1,
            pageSize: 50,
        }));
        setSelectedRows([]);
        if(currentTermUuid) {
            handleSelectTerm(currentTermUuid);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, currentTermUuid]);

    useEffect(() => {
        setOptionLoading(termSearchConnection.meta.fetch);
    }, [termSearchConnection]);


    const columns: ColumnsType<SchoolClass> = [
        {
            title: 'クラス',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '学年',
            dataIndex: 'grade',
            key: 'grade',
            render: (grade: Grade) => {
                return grade.name;
            }
        },
        {
            title: '年度',
            dataIndex: 'term',
            key: 'term',
            render: (term: Term) => (
                <>
                    <span>{term.name}</span>
                    <br />
                    <span>{'(' + moment.unix(term.start_at).format('YYYY/MM/DD HH:mm') + 'から'}</span>
                    <br />
                    <span>{moment.unix(term.end_at).format('YYYY/MM/DD HH:mm') + 'まで)'}</span>
                </>
            )
        },
    ];

    const rowSelection: TableRowSelection<SchoolClass> = {
        selectedRowKeys: selectedRows.map(row => row.uuid),
        preserveSelectedRowKeys: true,
        onChange: (_, selectedRows) => setSelectedRows(selectedRows),
        type: 'checkbox',
    };

    /***
     * 年度プルダウンを選択するごとに、クラスに対して再検索がかかる
     * @param term_uuid
     */
    function handleSelectTerm(term_uuid: string) {
        setSelectTerm(term_uuid);
        setPagination(pagination => ({
            ...pagination,
            total: classListMap.data[toClassListMapKey(term_uuid)]?.totalAmount ?? 0,
            current: 1,
        }));
        searchClassListMap({termUuid: term_uuid, pageSize: 1000});
    }

    const currentClassListMap = classListMap.data[toClassListMapKey(selectTerm)];

    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={<span><TeamOutlined /> 対象を選択</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key='back' size='large' onClick={onCancel}>キャンセル</Button>,
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    loading={classListMap.isLoading}
                    onClick={() => onOk(selectedRows)}
                >
                    保存
                </Button>,
            ]}
        >
            <div style={{ 'position': 'relative' }}>
                <BaseTable
                    style={
                        // 該当年度のクラスリストが無い場合レイアウトが崩れる対策
                        currentClassListMap === undefined || currentClassListMap.totalAmount === 0 ?
                            { paddingTop: '55px' } :
                            {}
                    }
                    loading={classListMap.isLoading}
                    emptyDescription={'クラスがありません'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={currentClassListMap?.items}
                    pagination={pagination}
                />
                <Select
                    style={{ position: 'absolute', top: '8px', left: '0px', minWidth: '130px' }}
                    value={selectTerm}
                    loading={optionLoading}
                    onChange={(value) => { handleSelectTerm(value) }}
                    placeholder='年度を選択してください'
                >
                    {termList.length && addAllTermAndSort(termList).map((value, index) => {
                        return <Option key={index} value={value.uuid}>{value.name}</Option>;
                    })}
                </Select>
            </div>
        </BaseModal>
    );
};

export default AddModal;

import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Radio,
    Row,
    Select,
    Tooltip,
} from 'antd';
import PropTypes from 'prop-types';

import BaseForm from 'components/modules/BaseForm';
import { formRules } from 'constants/GlobalConfig';

import { formItemLayout, formItemLayoutWithoutLabel } from '../EditAppTemplate';
import { DetailOfListItemTable } from './DetailOfListItemTable';
import {
    PredictionParamsPopover,
    PredictedCandidates,
} from './PredictionParams/PredictionParamsPopover';
const Form = BaseForm;
const { Option } = Select;

export const EditUrl = ({
    appTemplate,
    context,
    disabled,
    form,
    layout: propsLayout,
    layoutWithoutLabel: propsLayoutWithoutLabel,
    useOrganizationCode,
    isControllableUser,
    isHidden,
}) => {
    const type = appTemplate?.type;
    const url = appTemplate?.url;
    const layout = propsLayout ?? formItemLayout;
    const layoutWithoutLabel = propsLayoutWithoutLabel ?? formItemLayoutWithoutLabel;
    const isChoices = url?.is_choices;
    const required = [formRules.required({ label: undefined })];
    const urlRule = [formRules.required({ label: undefined }), formRules.range({ max: 1999 })];
    const keyAndSecretRule = [formRules.required({ label: undefined }), formRules.range({ max: 255 })];

    if (!url) return null;

    if (isChoices) {
        return (
            <>
                <Form.Item
                    {...layout}
                    hidden={isHidden}
                    label={url.name}
                    required
                >
                    <Form.Item
                        name='ltiUrlCandidateUuid'
                        rules={required}
                    >
                        <Select
                            disabled={disabled}
                            placeholder={`${url.name}を選択してください`}
                        >
                            {url.candidates.map(candidate => (
                                <Option key={candidate.uuid} value={candidate.uuid}>{candidate.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <DetailOfListItemTable
                        appTemplate={appTemplate}
                        form={form}
                    />
                </Form.Item>

                <Form.Item hidden name='ltiUrlUuid'><Input /></Form.Item>
            </>
        );
    }

    return (
        <>
            {type === 1 && (
                <>
                    <Form.Item
                        hidden={isHidden}
                        label='URL'
                        name='ltiUrlUrl'
                        rules={urlRule}
                        {...layout}
                    >
                        <Input disabled={disabled} placeholder='URLを入力してください' />
                    </Form.Item>
                    <Form.Item
                        hidden={isHidden}
                        label='キー'
                        name='ltiUrlKey'
                        rules={keyAndSecretRule}
                        {...layout}
                    >
                        <Input disabled={disabled} placeholder='キーを入力してください' />
                    </Form.Item>
                    <Form.Item
                        hidden={isHidden}
                        label='シークレット'
                        name='ltiUrlSecret'
                        rules={keyAndSecretRule}
                        {...layout}
                    >
                        <Input disabled={disabled} placeholder='シークレットを入力してください' />
                    </Form.Item>
                </>
            )}
            {type === 2 && (
                <Lti13Url
                    context={context}
                    disabled={disabled}
                    form={form}
                    hidden={isHidden}
                    isControllableUser={isControllableUser}
                    layout={layout}
                    layoutWithoutLabel={layoutWithoutLabel}
                    url={url}
                    useOrganizationCode={useOrganizationCode}
                />
            )}
        </>
    );
};

EditUrl.propTypes = {
    candidates: PropTypes.arrayOf(PropTypes.shape({
        is_default: PropTypes.bool,
        key: PropTypes.string,
        name: PropTypes.string,
        secret: PropTypes.string,
        url: PropTypes.string,
        uuid: PropTypes.string,
    })),
    editableUrl: PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string,
    }),
    form: PropTypes.object,
    layout: PropTypes.object,
};

// learning-gateway-frontend-tenant-management
// src/components/pages/AppTemplates/Modals/ConnectionParamsForm/LTI1_3/index.tsx
// を参考に rules を実装
const Lti13Url = ({
    context,
    disabled,
    form,
    hidden,
    isControllableUser,
    layout: propsLayout,
    layoutWithoutLabel: propsLayoutWithoutLabel,
    url,
    useOrganizationCode: useOrgCodeWithRecord,
}) => {
    const layout = propsLayout ?? formItemLayout;
    const layoutWithoutLabel = propsLayoutWithoutLabel ?? formItemLayoutWithoutLabel;

    const useOrgCodeWithForm = Form.useWatch(['param_json', 'use_organization_code'], form) ?? undefined;

    // 入力フォームを hidden & disabled するかは form で指定されている場合はその値を優先して使用する
    const useOrganizationCode = useOrgCodeWithForm ?? useOrgCodeWithRecord;

    // 全体管理者は、固定値/変数値 かつ 全体管理者の変更が可能 の場合に学校コードを利用するかを変更できる
    const isChangeableUseOrganizationCode = isControllableUser &&
        !url.is_choices &&
        url.changeable_param_control;

    // 全体管理者の場合、固定/変数値の場合は編集可とする
    const isEditableDeploymentId = isControllableUser ?
        !url.is_choices :
        url.changeable_param_admin;

    const isHiddenDevelopmentId = checkIsHiddenOfDeploymentId(isControllableUser, url, useOrganizationCode);

    return (
        <>
            <Form.Item
                hidden={hidden}
                label={
                    <div className='two-row-label'>
                        <div>学習ツール URL</div>
                        <div>…</div>
                        <div>Tool URL</div>
                    </div>
                }
                name={['param_json', 'tool_url']}
                rules={[
                    formRules.required({ label: undefined }),
                    formRules.range({ max: 1999 }),
                    formRules.typeUrl('Tool URL'),
                    formRules.createUrlSizeValidator('Tool URL'),
                ]}
                {...layout}
            >
                <Input disabled={disabled} placeholder='Tool URL を入力してください' />
            </Form.Item>

            <Form.Item
                hidden={hidden}
                label={
                    <div className='two-row-label'>
                        <div>ログイン開始 URL</div>
                        <div>…</div>
                        <div>Initiate Login URL</div>
                    </div>
                }
                name={['param_json', 'initiate_login_url']}
                rules={[
                    formRules.required({ label: undefined }),
                    formRules.range({ max: 1999 }),
                    formRules.typeUrl('Initiate Login URL'),
                    formRules.createUrlSizeValidator('Initiate Login URL'),
                ]}
                {...layout}
            >
                <Input disabled={disabled} placeholder='Initiate Login URL を入力してください' />
            </Form.Item>

            <Form.Item
                hidden={hidden}
                label={
                    <div className='two-row-label'>
                        <div>学習ツール公開鍵 URL</div>
                        <div>…</div>
                        <div>Public Keyset</div>
                    </div>
                }
                name={['param_json', 'public_keyset']}
                rules={[
                    formRules.required({ label: undefined }),
                    formRules.range({ max: 255 }),
                ]}
                {...layout}
            >
                <Input disabled={disabled} placeholder='Public Keyset を入力してください' />
            </Form.Item>

            <Form.List hidden={hidden} name={['param_json', 'redirect_uris']}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                key={field.key} // ESLint による警告回避
                                {...field}
                                {...(index === 0 ? layout : layoutWithoutLabel)}
                                hidden={hidden}
                                label={
                                    index === 0 ?
                                        (
                                            <div className='two-row-label'>
                                                <div>リダイレクト URIs</div>
                                                <div>…</div>
                                                <div>Redirect URIs</div>
                                            </div>
                                        ) :
                                        ''
                                }
                                rules={[
                                    formRules.required({ label: undefined }),
                                    formRules.range({ max: 1999 }),
                                    formRules.typeUrl('Redirect URIs'),
                                    formRules.createUrlSizeValidator('Redirect URIs'),
                                ]}
                            >
                                <Row gutter={[16, 12]} >
                                    <Col span={22}>
                                        <Form.Item
                                            name={index}
                                            noStyle
                                        >
                                            <Input disabled={disabled} placeholder='Redirect URIs を入力してください' />
                                        </Form.Item>
                                    </Col>
                                    {(index > 0) && (
                                        <Col span={2}>
                                            <Button
                                                disabled={disabled}
                                                onClick={() => remove(index)}
                                                type='text'
                                            >
                                                <MinusCircleOutlined style={{ fontSize: '1.5rem' }} />
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </Form.Item>
                        ))}

                        <Form.Item hidden={hidden} {...layoutWithoutLabel}>
                            <Button
                                disabled={disabled}
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                // add をそのまま渡すと, 以下のエラーが発生する.
                                // This synthetic event is reused for performance reasons.
                                type='dashed'
                            >
                                <PlusOutlined />
                                Redirect URIs の追加
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item
                hidden={hidden}
                label={
                    <div className='two-row-label'>
                        <div>ログインヒント</div>
                        <div>…</div>
                        <div>Login Hint</div>
                    </div>
                }
                name={['param_json', 'login_hint']}
                rules={[
                    formRules.required({ label: undefined }),
                    formRules.range({ max: 255 }),
                ]}
                {...layout}
            >
                <Input
                    disabled={disabled}
                    placeholder='Login Hint を入力してください'
                    suffix={
                        <PredictionParamsPopover
                            fieldName={['param_json', 'login_hint']}
                            form={form}
                            options={PredictedCandidates.LoginHint}
                        />
                    }
                />
            </Form.Item>

            {/* useOrgCodeWithRecord は undefined の可能性があるので === で検証する */}
            {(context === 'AppTemplate' || useOrgCodeWithRecord === false) && (
                <Form.Item
                    hidden={hidden || !isChangeableUseOrganizationCode}
                    label={
                        <div className='two-row-label'>
                            <div>デプロイメント ID</div>
                            <div>…</div>
                            <div>Deployment ID</div>
                        </div>
                    }
                    name={['param_json', 'use_organization_code']}
                    rules={[formRules.required({ label: undefined })]}
                    {...layout}
                >
                    <Radio.Group disabled={disabled}>
                        <Radio value={true}>
                            <span style={{ marginRight: '0.5rem' }}>
                                学校コード利用
                            </span>
                            <Tooltip title='全体管理者の場合には自治体コードが利用されます。'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Radio>
                        <Radio value={false}>
                            個別指定
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            )}

            <Form.Item
                hidden={hidden || isHiddenDevelopmentId}
                label={
                    !isChangeableUseOrganizationCode ?
                        (
                            <div className='two-row-label'>
                                <div>デプロイメント ID</div>
                                <div>…</div>
                                <div>Deployment ID</div>
                            </div>
                        ) :
                        ''
                }
                name={['param_json', 'deployment_id']}
                rules={
                    isHiddenDevelopmentId || !isEditableDeploymentId ?
                        [] :
                        [
                            formRules.required({ label: undefined }),
                            formRules.range({ max: 255 }),
                        ]
                }
                {...(isChangeableUseOrganizationCode ? layoutWithoutLabel : layout)}
            >
                <Input
                    disabled={(isHiddenDevelopmentId || !isEditableDeploymentId) && disabled}
                    placeholder='Deployment ID を入力してください'
                />
            </Form.Item>

            <Form.Item
                hidden={hidden}
                label={
                    <div className='two-row-label'>
                        <div>ユーザー情報の連携</div>
                        <div>…</div>
                        <div>
                            <span style={{ marginRight: '0.5rem' }}>
                                PII
                            </span>
                            <Tooltip title='Personally Identifiable Information'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                    </div>
                }
                name={['param_json', 'is_send_user_info']}
                rules={[formRules.required({ label: undefined })]}
                {...layout}
            >
                <Radio.Group disabled={disabled}>
                    <Radio value={true}>
                        ユーザー情報を送信する
                    </Radio>
                    <Radio value={false}>
                        ユーザー情報を送信しない
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item hidden name={['param_json', 'client_id']}>
                <Input />
            </Form.Item>
        </>
    );
};

const checkIsHiddenOfDeploymentId = (
    isControllableUser,
    { changeable_param_control, changeable_param_admin },
    useOrganizationCode,
) => {
    const isSpecifyIndividual = !useOrganizationCode;
    const allowChangeableWithControl = changeable_param_control;
    const allowChangeableWithAdmin = changeable_param_admin;

    if (isControllableUser) {
        if (allowChangeableWithControl && isSpecifyIndividual) {
            return false;
        } else if (allowChangeableWithControl && useOrganizationCode) {
            return true;
        } else if (!allowChangeableWithControl && isSpecifyIndividual) {
            return false;
        } else if (!allowChangeableWithControl && useOrganizationCode) {
            return true;
        } else {
            return true;
        }
    } else {
        return !allowChangeableWithAdmin || useOrganizationCode;
    }
};
import { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message, Modal, Collapse, Button } from 'antd';
import { useAppSelector } from 'flex/utils';
import { selectors } from 'flex/Selectors'
import {
    checkEnvironmentForControllable,
    checkEnvironmentIsTao,
    getMoment,
} from 'constants/GlobalConfig';
import Spin from 'components/modules/Spin';

const { Panel } = Collapse;
const moment = getMoment();

type ErrorModal = ReturnType<typeof Modal.error>;

function Message() {
    const [isOpen, setIsOpen] = useState(false);
    const isFirstRender = useRef(false);
    const failure = useAppSelector(selectors.failure);
    const modalRef = useRef<ErrorModal | null>(null);

    useEffect(() => {//refを使ってfirstRenderを制御
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!failure.meta.isShow) return;

        const contentList: string[] = [];
        if (isEmpty(failure.payload) || !failure.payload.result) {
            contentList.push("サーバーから応答がありませんでした。もう一度お試しください。");
        } else {
            if (failure.payload?.result.message) {
                contentList.push(replaceAlertMessage(failure.payload.result.message));
            }

            if (failure.payload.result.errors) {
                analyzeJSON(failure.payload.result.errors, (rVal) => {
                    contentList.push(replaceAlertMessage(rVal))
                });
            }
        }

        const content = (
            <div>
                <br />
                {contentList.map((v, index) => {
                    const [isAdded, resultMessage] = addJsxToAccountLockMessage(v);
                    if (isAdded) {
                        return resultMessage;
                    } else {
                        return <ContentLine text={v} key={index} />;
                    }
                })}
                <LoadingArea show={failure.meta.loading} />

                <div style={{ marginTop: '1rem' }}>
                    <span>問題が解決しない場合は、</span>
                    {checkEnvironmentIsTao()
                        ? <a href='https://support2.mexcbt.mext.go.jp/contact/' target='_blank' rel='noopener noreferrer'>＜MEXCBTコールセンター＞</a>
                        : <a href='https://www.info.l-gate.net/inquiry_mng/' target='_blank' rel='noopener noreferrer'>＜ヘルプデスク＞</a>
                    }
                    <span>へお問い合わせください。</span>
                </div>

                {failure.payload && createDetailCollapse(failure.payload.details, contentList)}
            </div>
        );

        if (isOpen && modalRef.current) {
            modalRef.current.update(prevConfig => ({
                ...prevConfig,
                content: content,
            }));
            return;
        }

        setIsOpen(true);
        modalRef.current = Modal.error({
            title: 'エラー',
            content: content,
            onOk() {
                setIsOpen(false)
                modalRef.current = null
            },
            width: 600
        });
    }, [failure]);// eslint-disable-line

    useEffect(() => {//refを使ってfirstRenderを制御
        isFirstRender.current = false;
    }, []);

    return null;
};

export default Message;


function isEmpty(obj: {}) {
    return obj ? !Object.keys(obj).length : true;
}

// FIX: dataはstring[]が受け取れれば良いため処理を書き換えて簡潔な型定義で済ませたい
function analyzeJSON(data: any, callback: (arg: string) => void) {
    for (let key in data) {
        if (typeof data[key] === "object") {
            analyzeJSON(data[key], callback);
        } else {
            callback(data[key]);
        }
    }
}

function replaceAlertMessage(string: string) {
    let replacedString = '';
    const loginIdRegex = /ログインID/gi; //ここのログインはサインインにリネームしない
    replacedString = string.replace(loginIdRegex, 'ユーザーID');

    const urlRegex = /Url/gi;
    replacedString = replacedString.replace(urlRegex, 'URL');

    return replacedString;
}

function addJsxToAccountLockMessage(string: string): [boolean, string | JSX.Element] {
    if (!checkEnvironmentForControllable()) return [false, string];
    const addJsx =
        <p>
            <span>ロックを解除するには</span>
            <a href='https://www.info.l-gate.net/inquiry_mng/' target='_blank' rel='noopener noreferrer'>＜ヘルプデスク＞</a>
            <span>へお問い合わせください。</span>
        </p>
    const accountLockRegex = /アカウントがロック*/gi; //ここのログインはサインインにリネームしない
    if (accountLockRegex.test(string)) {
        const addedMessage =
            <>
                <p>{string}</p>
                {addJsx}
            </>;
        return [true, addedMessage];
    }

    return [false, string];
};

/****
 * 詳細情報表示のCollapseの作成
 * @param  detailsObj
 * @param {string[]} contents エラーメッセージの配列
 * @return {JSX.Element}
 */
function createDetailCollapse(detailsObj: Details, contents: string[]) {
    if (!detailsObj) return <></>;

    const details = [];
    details.push({ label: '発生日時:', value: `${detailsObj.date ? moment(detailsObj.date).format('YYYY年M月D日 HH:mm') : 'なし'}` });
    details.push({ label: 'エラーコード:', value: `${detailsObj.code ? detailsObj.code : 'なし'}` });
    details.push({ label: 'リクエストメソッド:', value: `${detailsObj.method ? detailsObj.method : 'なし'}` });
    details.push({ label: 'リクエストURL:', value: `${detailsObj.baseURL}${detailsObj.url}` });
    details.push({ label: '追跡情報:', value: `${detailsObj.trackingInfo ? detailsObj.trackingInfo : 'なし'}` });
    details.push({ label: 'メッセージ:', value: `${contents.length !== 0 ? contents.join(' ') : 'なし'}` });
    details.push({ label: '', value: '' });
    details.push({ label: 'ユーザエージェント:', value: `${detailsObj.userAgent}` });
    details.push({ label: `${checkEnvironmentIsTao() ? '' : 'L-Gate'}バージョン（フロント）:`, value: `${detailsObj.frontAppVersion}` });

    const detailHtml = details.map((value) => {
        return `<b>${value.label}</b> ${value.value}`;
    }).join('<br/>');

    const detailCopyText = details.map((value) => {
        return `${value.label} ${value.value}`;
    }).join('\r\n');

    return (
        <Collapse ghost style={{ marginLeft: '-15px' }}>
            <Panel header='詳細情報' key='1'>
                <p dangerouslySetInnerHTML={{ __html: detailHtml }} />
                <div className='flex-right-container gutter-bottom'>
                    <CopyToClipboard
                        text={detailCopyText}
                        onCopy={() => { message.success('コピーしました') }}
                    >
                        <Button> エラー情報をクリップボードにコピー</Button>
                    </CopyToClipboard>
                </div>
            </Panel>
        </Collapse>
    );
};

/**
 *  決まった文字列に対して、jsxに変換をかけ、textをjsxに変換する
 */
const ContentLine = (props: ContentLineProp) => {
    const { text } = props;
    switch (true) {
        case /&lt;こちら&gt;/.test(text):
            const aroundTexts = text.split('&lt;こちら&gt;');
            const beforeText = aroundTexts[0];
            const afterText = aroundTexts[1];
            return (
                <p>
                    {beforeText}
                    <a href={'https://l-gate-status.info/'} target="_blank" rel="noopener noreferrer">&lt;こちら&gt;</a>
                    {afterText}
                </p>
            );
        default:
            return <p>{text}</p>;
    }
};
type ContentLineProp = {
    text: string
};

const LoadingArea = (props: LoadingAreaProp) => {
    const { show } = props;
    if (!show) return null;

    return (
        <div className='text-center p-18'>
            <Spin />
        </div>
    );
};
type LoadingAreaProp = {
    show: boolean
};

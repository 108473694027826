import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { formRules, getDefaultLayout } from 'constants/GlobalConfig';
import { statusEnum } from 'flex/Actions';
import BaseModal from 'components/modules/BaseModal';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;
const { TextArea } = Input;

const initialValue = {
    'name': '',
    'description': '',
    'can_set_forced': '',
};

const layout = getDefaultLayout();

const EditQuestionnaireCategory = props => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const editQuestionnaireCategory = useSelector(state => state.questionnaireCategoryTab.editQuestionnaireCategory);
    const questionnaireCategoryEditConnection = useSelector(state => state.questionnaireCategoryEditConnection);
    const isControllableUser = useSelector(state => state.isControllableUser);

    // 画面表示時
    useEffect(() => {
        if (editQuestionnaireCategory.isEditOpen) {
            if (editQuestionnaireCategory.record) {
                const record = editQuestionnaireCategory.record;
                form.setFieldsValue({
                    name: record.name,
                    description: record.description,
                    can_set_forced: record.can_set_forced,
                });
            } else {
                form.resetFields();
                form.setFieldsValue(initialValue);
            }
        } else {
            form.resetFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editQuestionnaireCategory.record, editQuestionnaireCategory.isEditOpen]);

    // 保存成功時
    useEffect(() => {
        if (editQuestionnaireCategory.isEditOpen && questionnaireCategoryEditConnection.meta.status === statusEnum.SUCCESS) {
            props.onOk();
        }
        setLoading(questionnaireCategoryEditConnection.meta.fetch);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaireCategoryEditConnection]);

    function onFinish(values) {
        const { shouldCreate } = editQuestionnaireCategory;
        if (shouldCreate) {

            const createActions = isControllableUser.payload ?
                Actions.http.connection.questionnaireCategory.controlCreate :
                Actions.http.connection.questionnaireCategory.create;

            dispatch(createActions({ ...values, can_set_forced: false }));
        } else {

            const updateAction = isControllableUser.payload ?
                Actions.http.connection.questionnaireCategory.controlUpdate :
                Actions.http.connection.questionnaireCategory.update;
            const uuid = editQuestionnaireCategory.record.uuid;

            dispatch(updateAction(uuid, { ...values, can_set_forced: false }));
        }
    }

    const handleSubmit = () => {
        form.submit();
    };

    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={
                <span>
                    <SolutionOutlined />
                    {editQuestionnaireCategory.shouldCreate ?
                        'アンケートカテゴリの新規作成' :
                        'アンケートカテゴリの編集'
                    }
                </span>
            }
            visible={editQuestionnaireCategory.isEditOpen}
            onCancel={props.onCancel}
            footer={[
                <Button
                    key='back'
                    size='large'
                    onClick={props.onCancel}
                    loading={loading}
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    loading={loading}
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
            forceRender
        >
            <Form {...layout}
                form={form}
                name='control-hooks-edit-edit-questionnaire-category'
                onFinish={onFinish}
            >
                <Form.Item
                    name='name'
                    label='カテゴリ'
                    rules={[
                        formRules.required({}),
                        formRules.range({ label: 'カテゴリ', max: 64 })
                    ]}
                >
                    <Input placeholder='カテゴリを入力してください' />
                </Form.Item>

                {/*<Form.Item name={"can_set_forced"} label={"強制回答設定可否"}  rules={[{required: true, message: '必須項目です'}]}>*/}
                {/*    <Radio.Group>*/}
                {/*        <Radio value={false}>設定不可</Radio>*/}
                {/*        <Radio value={true}>設定可能</Radio>*/}
                {/*    </Radio.Group>*/}
                {/*</Form.Item>*/}

                <Form.Item
                    name='description'
                    label='説明'
                    rules={[formRules.range({ max: 10000 }),]}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </BaseModal>
    );
};

export default EditQuestionnaireCategory;

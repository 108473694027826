import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Input, Col } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusCircleFilled,
} from '@ant-design/icons';
import { BaseTable } from 'components/modules/BaseTable';
import BaseForm from 'components/modules/BaseForm';
import CircleButton from 'components/modules/CircleButton';
import { getDefaultLayout } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { statusEnum } from 'flex/Actions';
import { questionnaireCategoryTabLoadingSelector } from 'flex/view/Questionnaire/QuestionnaireCategoryTab/selectors';
import EditQuestionnaireCategory from './EditQuestionnaireCategory';
import {
    createPublishedItemButtonTooltipTitle,
    PublishedItemTitleHint,
} from 'components/modules/PublishedItems';
const Form = BaseForm.ModalForm;


const layout = getDefaultLayout();

const QuestionnaireCategoryList = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabSelector = useSelector(state => state.questionnaireCategoryTab);
    const { tableLoading, buttonLoading } = useSelector(questionnaireCategoryTabLoadingSelector);
    const isFirstRender = useRef(false);
    const questionnaireCategoryEditConnection = useSelector(state => state.questionnaireCategoryEditConnection);
    const questionnaireCategorySearchConnection = useSelector(state => state.questionnaireCategorySearchConnection)
    const [records, setRecords] = useState([]);

    useEffect(() => {
        isFirstRender.current = true;
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.prepareView());
    }, [dispatch]);

    useEffect(() => {
        setRecords(questionnaireCategorySearchConnection.payload?.result?.items ?? []);
    }, [questionnaireCategorySearchConnection]);

    useEffect(() => {
        setRecords(questionnaireCategoryEditConnection.payload?.result?.items ?? []);
    }, [questionnaireCategoryEditConnection]);

    useEffect(() => {
        if (!isFirstRender.current) {
            dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submittedEdit());
        } else {
            isFirstRender.current = false;
        }
    }, [questionnaireCategoryEditConnection, dispatch]);

    const onFinish = (values) => {
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submitSearch(values));
    };

    const onReset = () => {
        form.resetFields();
    };

    const handleEditSubmitted = () => {
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submitEdit());
    };

    const handleEditCancel = () => {
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.cancelEdit());
    };

    const handleCreateClick = () => {
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.openCreate());
    };

    const handleEditClick = (record) => {
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.openEdit(record));
    };

    const handleDeleteClick = (uuid) => {
        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submitDelete(uuid));
    };

    const columns = [
        {
            title: 'カテゴリ',
            dataIndex: 'name',
            // 比率を保つため https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
            ellipsis: true,
            key: 'name',
            render: (name, record) => (
                <span>{name} <PublishedItemTitleHint record={record} /></span>
            ),
        },
        {
            title: '概要',
            dataIndex: 'description',
            ellipsis: true,
            key: 'description',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '88px',
            render: (_, record) => (
                <Row type='flex' justify='space-around' align='middle'>
                    <Col span={6}>
                        <CircleButton
                            size='small'
                            tooltipProps={{ title: props.hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record, '編集') }}
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(record)}
                            disabled={record.is_published_item}
                        />
                    </Col>
                    <Col>
                        <CircleButton
                            tooltipProps={{ title: props.hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record, '削除') }}
                            popconfirmProps={{
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>アンケートカテゴリを削除します</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                ),
                            }}
                            className='mr-2'
                            size='small'
                            danger
                            icon={<DeleteOutlined />}
                            disabled={record.is_published_item}
                        />
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <div className='container'>
            <div className='card general-search'>
                <Form {...layout} form={form} name='control-hooks-questionnaire-category-list' onFinish={onFinish}>

                    <Form.Item name='name' label='カテゴリ'>
                        <Input placeholder='カテゴリを入力してください' />
                    </Form.Item>

                    <Form.Item name='description' label='概要'>
                        <Input placeholder='概要を入力してください' />
                    </Form.Item>

                    <div className='submit-container'>
                        <Button htmlType='button' onClick={onReset} loading={buttonLoading}>
                            リセット
                        </Button>
                        <Button type='primary' htmlType='submit' loading={buttonLoading}>
                            検索
                        </Button>
                    </div>
                </Form>
            </div>

            <div className='flex-right-container gutter-bottom'>
                <Button
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                columns={columns}
                emptyDescription='配信されているアンケートカテゴリはありません'
                loading={tableLoading}
                dataSource={records || []}
                pagination={{
                    ...tabSelector.pagination,
                    showTotal: (total, range) => { return (`全${total}件中 ${range[0]}-${range[1]} 件`); },
                    onChange: (page, page_size) => {
                        dispatch(Actions.view.viewLogic.questionnaireCategoryTab.submitPage(page, page_size));
                    },
                }}
            />

            <EditQuestionnaireCategory
                onOk={handleEditSubmitted}
                onCancel={handleEditCancel}
            />
        </div>
    );
};

export default QuestionnaireCategoryList;

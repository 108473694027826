import { useEffect, useState, VFC } from 'react';
import { Button } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import {
    getNameFromGradeCode,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import OrganizationAddSearch, { OrganizationSearch } from './AddSearch';
import { BaseTable } from 'components/modules/BaseTable';
import BaseModal from 'components/modules/BaseModal';
import { useAppSelector } from 'flex/utils';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';

const INIT_SEARCH: OrganizationSearch = {
    name: '',
    page: 1,
    page_size: 50,
};

type Props = {
    onlyHasSchoolCode: boolean,
    onCancel: () => void,
    onOk: (selectedRows: Organization[]) => void,
    visible: boolean,
    selectedList: Organization[],
};

type Pagination = PartOfRequired<
    TablePaginationConfig,
    'pageSize'
>;


const AddModal: VFC<Props> = ({
    onlyHasSchoolCode,
    onCancel,
    onOk,
    visible,
    selectedList
}) => {
    const [organizationList, setOrganizationList] = useState<Organization[]>([]);
    const [search, setSearch] = useState<OrganizationSearch>({...INIT_SEARCH});
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState<Organization[]>(selectedList);
    const [pagination, setPagination] = useState<Pagination>({
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: 0,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        pageSizeOptions: PageSizeOptions,
        pageSize: 50,
        onShowSizeChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size
            }));
            setSearch(search => ({
                ...search,
                page,
                page_size
            }));
        },
        onChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size,
            }));
            setSearch(search => ({
                ...search,
                page,
                page_size,
            }));
        }
    });
    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);
    const dispatch = useDispatch();

    useEffect(() => {
        if (visible) {
            setSearch({...INIT_SEARCH});
            setSelectedRows(selectedList);
            setPagination(pagination => ({
                ...pagination,
                current: 1,
                pageSize: 50,
            }));
        } else {
            setSelectedRows([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        const { meta, payload } = organizationSearchConnection;

        setLoading(meta.fetch);

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setOrganizationList(payload.result.items);
                setPagination(pagination => ({
                    ...pagination,
                    total: payload.result.item_count,
                }));
                break;
            default:
                break;
        }
    }, [organizationSearchConnection]);

    useEffect(() => {
        if (!visible) return;

        const { page, ...data } = search;
        const newPage = page ?? 1;
        setPagination(pagination => ({
            ...pagination,
            current: newPage,
        }));
        if (!data.page_size) {
            data.page_size = 50;
        }
        if (onlyHasSchoolCode) {
            data.has_school_code = 1;
        }
        dispatch(Actions.http.connection.organization.search(data, page, ''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const columns: ColumnsType<Organization> = [
        {
            title: '学校名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '校種コード',
            dataIndex: 'code',
            key: 'code',
            render: getNameFromGradeCode,
        },
        {
            title: '権限',
            dataIndex: 'type_text',
            key: 'type_text',
            render: (type_text) => type_text,
        },
    ];

    const rowSelection: TableRowSelection<Organization> = {
        selectedRowKeys: selectedRows.map(row => row.uuid),
        preserveSelectedRowKeys: true,
        onChange: (_, selectedRows) => {
            // 配列にundefinedが入り込むことがあるので排除
            setSelectedRows(selectedRows.filter(v => v));
        },
        type: 'checkbox',
    };

    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={<span><TeamOutlined /> 対象を選択</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key='back' size='large' onClick={onCancel}>キャンセル</Button>,
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    loading={loading}
                    onClick={() => onOk(selectedRows)}
                >
                    保存
                </Button>,
            ]}
        >
            <div style={{ 'position': 'relative' }}>
                <OrganizationAddSearch
                    isLoading={organizationSearchConnection.meta.fetch}
                    search={search}
                    onSearch={setSearch}
                />
                <BaseTable
                    loading={loading}
                    emptyDescription='学校がありません'
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={organizationList}
                    pagination={pagination}
                />
            </div>
        </BaseModal>
    );
};

export default AddModal;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

type ChangeExamCategoryOrderState = {
    isLoading: boolean
};

export const useChangeExamOrder = (
    // ここでは引数は使わないが、他でここの実装を真似する際のためにそれっぽくしておく
    successCallback: (meta: unknown, payload: unknown, isControl: boolean) => void,
    failureCallback: (meta: unknown, payload: unknown, isControl: boolean) => void,
) => {
    const dispatch = useDispatch();

    const [state, setState] = useState<ChangeExamCategoryOrderState>({
        isLoading: false,
    });
    const isControllableUser = useAppSelector(selectors.isControllableUser);

    const taoExamCategoryControlChangeOrderConnection = useAppSelector(
        state => state.taoExamCategoryControlChangeOrderConnection
    );
    const taoExamCategoryChangeOrderConnection = useAppSelector(state => state.taoExamCategoryChangeOrderConnection);

    useEffect(() => {
        const { meta, payload } = taoExamCategoryChangeOrderConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryChangeOrderConnection]);

    useEffect(() => {
        const { meta, payload } = taoExamCategoryControlChangeOrderConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlChangeOrderConnection]);

    const request = useCallback((
        uuid: string,
        data: { uuids: string[] }
    ) => {
        const changeOrderAction = isControllableUser ?
            Actions.http.connection.tao.examControlCategory.controlChangeOrder :
            Actions.http.connection.tao.examCategory.changeOrder;

        dispatch(changeOrderAction(uuid, data));
        setState(state => ({
            ...state,
            isLoading: true,
        }));

    }, [dispatch, isControllableUser]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
        });
    }, []);

    return [state, request, reset] as const;
};

import { useContext, useEffect } from 'react';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { ListContext } from './index';

type Props = {
    context?: typeof ListContext;
}

const OrganizationEffectComponent = (props: Props) => {
    const [list, setList] = useContext(props.context ? props.context : ListContext);

    const organizationSearchConnection = useAppSelector(state => state.organizationSearchConnection);

    useEffect(()=>{
        if(organizationSearchConnection.meta.status === Actions.statusEnum.SUCCESS){
            console.log(organizationSearchConnection);
            setList({
                ...list,
                organizationList: organizationSearchConnection.payload.result?.items ?? [],
                organizationListItemCount: organizationSearchConnection.payload.result?.item_count ?? 0,
            });
        }
        if(
            organizationSearchConnection.meta.status === Actions.statusEnum.FAILURE ||
            organizationSearchConnection.meta.status === Actions.statusEnum.ERROR
        ){
            //OPTIMIZE 基本的には汎用エラーコンポーネントがエラーをスポイルしてここでは何も起こらないのが正なので、処理しない
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationSearchConnection]);

    return null;
};

export default OrganizationEffectComponent;
/**
 * /view のレスポンスから、選択されたと思われる candidate を探す
 *
 * バックエンドは、candidate の内容しか返してくれないので、
 * 内容が一致することを確認して、選択したと思われる candidate を探す
 */
export const findCandidate = (record: any) => {
    const appTemplate = record.lti_app_template;
    const url = record.lti_url;

    if (!appTemplate || !url || !appTemplate.url.is_choices) return;

    const urlParamJson = url.param_json;

    const candidate = url.candidates.find((candidate: any) => {
        switch (appTemplate.type) {
            case 1: {
                return candidate.url === url.url
                    && candidate.key === url.key
                    && candidate.secret === url.secret;
            }
            case 2: {
                return candidate.param_json.client_id === urlParamJson.client_id;
            }
            default: {
                return false;
            }
        }
    });

    return candidate;
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

export type CreateExamCategoryState = {
    isLoading: boolean
    status: typeof Actions.statusEnum[keyof typeof Actions.statusEnum] | 'DEFAULT'
};

export const useEditExamCategory = () => {
    const [state, setState] = useState<CreateExamCategoryState>({
        isLoading: false,
        status: 'DEFAULT',
    });

    const taoExamCategoryEditConnection = useAppSelector(state => state.taoExamCategoryEditConnection);
    const taoExamCategoryControlEditConnection = useAppSelector(state => state.taoExamCategoryControlEditConnection);

    const dispatch = useDispatch();


    useEffect(() => {
        const { meta } = taoExamCategoryEditConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryEditConnection]);

    useEffect(() => {
        const { meta } = taoExamCategoryControlEditConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlEditConnection]);

    const requestData = useCallback((
        values: ExamCategory.EditInput,
        shouldCreate: boolean,
        isCopy: boolean,
    ) => {
        setState({
            isLoading: true,
            status: Actions.statusEnum.REQUEST,
        });

        const { description, duration, title, uuid, ...rest } = values;

        const basePostBody = {
            description,
            is_active: true,
            // #1581 全体管理者の場合は強制で false
            is_required: rest.is_controllable ? false : rest.is_required,
            publish_end_at: duration[1]?.unix() ?? 0,
            publish_start_at: duration[0]?.unix() ?? 0,
            title,
        };

        if (rest.is_controllable) {
            const {
                is_designated_term,
                publish_to_all_organizations,
                target_grades,
                target_permissions,
                target_term,
                target,
            } = rest;

            const target_organizations = publish_to_all_organizations ?
                [] :
                target.target_organizations;

            if (!isTargetGrades(target_grades)) return;

            const addPostBody = {
                is_designated_term,
                publish_to_all_organizations,
                target_grades: target_grades,
                target_organizations: target_organizations.map(value => value.uuid),
                target_permissions,
                target_term: is_designated_term ? target_term : '',
            };

            const postBody = {
                ...basePostBody,
                ...addPostBody,
            };

            if (isCopy && uuid) {
                dispatch(Actions.http.connection.tao.examControlCategory.controlCreate(uuid, postBody, ''));
            } else {
                if (!shouldCreate && uuid) {
                    dispatch(Actions.http.connection.tao.examControlCategory.controlUpdate(uuid, postBody, ''));
                } else {
                    dispatch(Actions.http.connection.tao.examControlCategory.controlCreate('', postBody, ''));
                }
            }

        } else {
            const { target_roles, target_school_classes, target_users } = rest.target;

            const addPostBody = {
                target_roles: target_roles.map(value => value.uuid),
                target_school_classes: target_school_classes.map(value => value.uuid),
                target_users: target_users.map(value => value.uuid),
            };

            const postBody = { ...basePostBody, ...addPostBody };

            if (isCopy && uuid) {
                dispatch(Actions.http.connection.tao.examCategory.create(uuid, postBody, ''));
            } else {
                if (!shouldCreate && uuid) {
                    dispatch(Actions.http.connection.tao.examCategory.update(uuid, postBody, ''));
                } else {
                    dispatch(Actions.http.connection.tao.examCategory.create('', postBody, ''));
                }
            }
        }
    }, [dispatch]);


    const resetData = useCallback(() => {
        setState({
            isLoading: false,
            status: 'DEFAULT',
        });
    }, []);

    return [state, requestData, resetData] as const;
};



/**
 * target_grades が初期値の [undefined] でないことを検証する
 */
const isTargetGrades = (target_grades: string[] | [undefined]): target_grades is string[] => {
    return typeof target_grades[0] === 'string';
};
const Ruby = (props: {className: string, children: string}) => {
    const children = props.children;

    const generatedChildren = generateRuby(children)
    return (
        <span
            className={props.className ? props.className: undefined}
            dangerouslySetInnerHTML={{__html: generatedChildren}} />
    )
};

const rubyPattern = /\{(.*?)\|(.*?)\}/g;
const generateRuby = (string: string) => {
    const result = string.replace(rubyPattern,`<ruby>$1<rt>$2</rt></ruby>`)
    return result;
};

export default Ruby;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Result = {
    isLoading: boolean,
    pagination: Table.Pagination,
    organizationList: App.AppStatus.OrganizationListItem[],
    categoryApplicationCount: number,
    targetUsers: number,
}

const INIT_STATE: Result = {
    isLoading: false,
    pagination: {
        total: 0,
        pageSize: 20,
        current: 1,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: false,
    },
    organizationList: [],
    categoryApplicationCount: 0,
    targetUsers: 0,
};

export const useAppCategoryControlResult = () => {
    const [result, setResult] = useState<Result>({ ...INIT_STATE });
    const appsCategoryControlResult = useAppSelector(state => state.appsCategoryControlResultConnection);
    const dispatch = useDispatch();
    const resultAction = Actions.http.connection.apps.category.controlResult;

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback(
        (
            appCategoryUuid: string,
            data: Http.Connection.Request.Parameter.App.ControlGroupStatusSearch,
        ) => {
            dispatch(resultAction(appCategoryUuid, data));
            setResult((result) => ({
                ...result,
                isLoading: true,
            }));
        },
        [dispatch, resultAction]
    );

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = appsCategoryControlResult;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                setResult((result) => ({
                    ...result,
                    isLoading: true,
                }));
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                const total = payloadResult?.item_count ? payloadResult?.item_count : 0;
                const pageSize = payloadResult?.page_size ? payloadResult?.page_size : 0;
                const targetUsers = payloadResult?.target_users ? payloadResult?.target_users : 0;
                const organizationList = payloadResult
                    ? payloadResult.items.map(item => {
                        return {
                            applications: item.applications,
                            applicationCategory: {
                                uuid: item.application_category.uuid,
                            },
                            organization: {
                                name: item.organization.name,
                                uuid: item.organization.uuid,
                            }
                        };
                    })
                    : [];
                const categoryApplicationCount = payloadResult?.items
                    ? payloadResult.items[0].applications.length
                    : 0;

                setResult({
                    pagination: {
                        ...result.pagination,
                        total: total,
                        pageSize: pageSize,
                    },
                    isLoading: false,
                    organizationList: organizationList,
                    categoryApplicationCount: categoryApplicationCount,
                    targetUsers: targetUsers,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsCategoryControlResult]);

    return [result, request, reset] as const;
};

import * as React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';

import { ReportDetailState } from '../../hooks/useReportDetail';


type Props = {
    reportDetail: ReportDetailState
    reportDetail2?: ReportDetailState
}

export const TotalTable: React.VFC<Props> = ({
    reportDetail,
    reportDetail2,
}) => {
    const data = reportDetail.result;
    const data2 = reportDetail2?.result;

    const WrapperRow = data2 ?
        ({ children }: { children: React.ReactNode }) => {
            return (
                <Row>
                    {children}
                </Row>
            );
        } :
        ({ children }: { children: React.ReactNode  }) => {
            return <>{children}</>;
        };

    const WrapperCol = data2 ?
        ({ children }: { children: React.ReactNode  }) => {
            return (
                <Col span={12}>{children}</Col>
            );
        } :
        ({ children }: { children: React.ReactNode }) => {
            return <>{children}</>;
        };

    return (
        <div>
            <div>
                <Typography.Text style={{ margin: '0.5rem' }}>
                    集計結果
                </Typography.Text>
                <Tooltip
                    title='上のプルダウンで表示対象にしているユーザーでの集計結果を表示しています。'
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>

            <WrapperRow>
                <WrapperCol>
                    <table className='test-info-table'>
                        <tbody>
                            <TableRow label='解答者数' value={`${data?.answeredUserCount ?? '-'}名/${data?.targetUserCount ?? '-'}名`} />
                            <TableRow label='解答回数' value={`${data?.resultCount ?? '-'}回`} />
                            <TableRow label='満点' value={`${data?.maxScore ?? '-'}`} />
                            <TableRow label='平均点' value={`${data?.averageScore ?? '-'}`} />
                            <TableRow label='最高点' value={`${data?.highestScore ?? '-'}`} />
                            <TableRow label='最低点' value={`${data?.lowestScore ?? '-'}`} />
                        </tbody>
                    </table>
                </WrapperCol>

                <WrapperCol>
                    {data2 && (
                        <table className='test-info-table'>
                            <tbody>
                                <TableRow label='解答者数' value={`${data2?.answeredUserCount ?? '-'}名/${data2?.targetUserCount ?? '-'}名`} />
                                <TableRow label='解答回数' value={`${data2?.resultCount ?? '-'}回`} />
                                <TableRow label='満点' value={`${data2?.maxScore ?? '-'}`} />
                                <TableRow label='平均点' value={`${data2?.averageScore ?? '-'}`} />
                                <TableRow label='最高点' value={`${data2?.highestScore ?? '-'}`} />
                                <TableRow label='最低点' value={`${data2?.lowestScore ?? '-'}`} />
                            </tbody>
                        </table>
                    )}
                </WrapperCol>
            </WrapperRow>
        </div>
    );
};


type TableRosProps = {
    label: string
    value?: string
}

const TableRow: React.VFC<TableRosProps> = ({
    label,
    value,
}) => {
    return value === undefined ?
        (
            <tr>
                <th colSpan={2}>label</th>
            </tr>
        ) :
        (
            <tr>
                <th>{label}</th>
                <td>{value}</td>
            </tr>
        );
};
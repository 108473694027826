import { createAction } from 'redux-actions';

import { API_CONTROL_NOTIFICATION, API_NOTIFICATION } from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

import type { Meta, Payload } from '../../utils';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/NOTIFICATION';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
};

export const view = createAction(
    createActionType('VIEW' as const),
    (uuid) => ({ method: 'get', api: API_NOTIFICATION + '/view/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlView = createAction(
    createActionType('CONTROL_VIEW' as const),
    (uuid) => ({ method: 'get', api: API_CONTROL_NOTIFICATION + '/view/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return ({ method: 'get', data, api: `${API_NOTIFICATION}/${pageNum}` });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlSearch = createAction(
    createActionType('CONTROL_SEARCH' as const),
    (data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return ({ method: 'get', data, api: `${API_CONTROL_NOTIFICATION}/${pageNum}` });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data) => ({ method: 'put', api: API_NOTIFICATION + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlCreate = createAction(
    createActionType('CONTROL_CREATE' as const),
    (data) => ({ method: 'put', api: API_CONTROL_NOTIFICATION + '/create', data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data) => ({ method: 'post', api: API_NOTIFICATION + '/update/' + uuid, data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlUpdate = createAction(
    createActionType('CONTROL_UPDATE' as const),
    (uuid, data) => ({ method: 'post', api: API_CONTROL_NOTIFICATION + '/update/' + uuid, data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid) => {
        if (Array.isArray(uuid)) {
            if (uuid.length > 1) {
                let uuid_array = '';
                uuid.forEach((value, index) => {
                    uuid_array += value;
                    if (uuid.length > index + 1) {
                        uuid_array += ',';
                    }
                });
                return {
                    method: 'delete',
                    api: API_NOTIFICATION + '/multiple-delete/' + uuid_array,
                };
            } else if (uuid.length === 1) {
                return { method: 'delete', api: API_NOTIFICATION + '/delete/' + uuid[0] };
            }
        } else {
            return { method: 'delete', api: API_NOTIFICATION + '/delete/' + uuid };
        }
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export { deleteAction as delete };

export const controlDelete = createAction(
    createActionType('CONTROL_DELETE' as const),
    (uuid) => {
        return { method: 'delete', api: API_CONTROL_NOTIFICATION + '/delete/' + uuid };
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const target = createAction(
    createActionType('TARGET' as const),
    (uuid, data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return ({
            method: 'get',
            api: `${API_NOTIFICATION}/target-users/${uuid}/${pageNum}`,
            data,
        });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlTarget = createAction(
    createActionType('CONTROL_TARGET' as const),
    (uuid, data, page) => {
        const pageNum = page === undefined ? '1' : page;
        return ({
            method: 'get',
            api: `${API_CONTROL_NOTIFICATION}/target-users/${uuid}/${pageNum}`,
            data,
        });
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlTargetOrganization = createAction<
    Payload | Http.Connection.Response.Notification.Control.ControlTargetOrganization,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_ORGANIZATION' as const),
    (notificationUuid) => {
        return {
            api: `${API_CONTROL_NOTIFICATION}/target-organization/${notificationUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const controlTargetSchoolClass = createAction<
    Payload | Http.Connection.Response.Notification.Control.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_SCHOOL_CLASS' as const),
    (notificationUuid) => {
        return {
            api: `${API_CONTROL_NOTIFICATION}/target-school-class/${notificationUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const targetSchoolClass = createAction<
    // 一旦、 control の型を使う
    // 必要に応じて適切な型に変更する
    Payload | Http.Connection.Response.Notification.Control.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_SCHOOL_CLASS' as const),
    (notificationUuid) => {
        return {
            api: `${API_NOTIFICATION}/target-school-classes/${notificationUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);
import * as React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
    Badge,
    Button,
    Col,
    Popconfirm,
    Row,
    Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { BaseTable } from 'components/modules/BaseTable';
import { getMoment } from 'constants/GlobalConfig';

import TestInfoPopover from './TestInfoPopover';

import 'styles/pages/assessment.scss';

const moment = getMoment();

type Props = {
    dataSource: Exam[]
    onClickDeleteTest: (uuid: string) => void
    operable: boolean
    hasTouchScreen: boolean
}

export const ExamTable: React.VFC<Props> = props => {
    const { dataSource, onClickDeleteTest, operable, hasTouchScreen } = props;

    const columns: ColumnsType<Exam> = [
        {
            dataIndex: 'title',
            key: 'title',
            title: 'タイトル',
        },
        {
            dataIndex: 'author',
            render: (_, record) => {
                if(!record.created_by) return <span> - </span>;

                return (
                    <span>
                        {`${record.created_by.display_name}`}
                    </span>
                );
            },
            title: '作成者',
            width: 170,
        },
        {
            dataIndex: 'publish_period',
            key: 'result',
            render: (_, record) => {
                const isIndefinitePeriod = record.end_at >= 2147483647;
                const start = moment.unix(record.start_at).format('YYYY/MM/DD HH:mm');
                const end = isIndefinitePeriod ? '' : moment.unix(record.end_at).format('YYYY/MM/DD HH:mm');
                return(
                    <span>
                        {`${start} ～ ${end}`}
                    </span>
                );
            },
            title: '公開期間',
            width: 170,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => {
                if (record.result_url) {
                    return <Badge status='success' text='利用可能' />;
                } else {
                    return <Badge status='error' text='利用不可' />;
                }
            },
            title: '解答結果',
            width: 170,
        },
        {
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (_, record) => (
                <Row align='middle' justify='space-around'>
                    <Col>
                        <TestInfoPopover {...record} />
                    </Col>
                    <Col>
                        <Tooltip title={hasTouchScreen ? undefined : '削除'}>
                            <Popconfirm
                                cancelText='キャンセル'
                                disabled={!operable}
                                okText='削除'
                                onConfirm={() => onClickDeleteTest(record.uuid)}
                                title={
                                    <>
                                        <span>テストを削除すると配信は停止されます。実施結果も確認できません。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                }
                            >
                                <Button
                                    className='mr-2'
                                    danger
                                    disabled={!operable}
                                    icon={<DeleteOutlined />}
                                    shape='circle'
                                    size='small'
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>
            ),
            title: '',
            width: 80,
        },
    ];

    return (
        <BaseTable<Exam>
            bordered={false}
            className='all-white-table'
            columns={columns}
            dataSource={dataSource}
            emptyDescription='含まれるテストリンクはありません'
            pagination={false}
            scroll={{ x:1000 }}
            showTopScroll={false}
        />
    );
};
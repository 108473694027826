import { useState, createContext, useContext } from 'react';
import { Tabs } from 'antd';
import NotificationsList from './NotificationsList'
import NotificationsCategoryList from './NotificationsCategoryList'
import NotificationEffectComponent from './NotificationEffectComponent';

const { TabPane } = Tabs;

export const ListContext = createContext([
    {
        notificationList: [],
        categoryList: [],
        notificationListItemCount: 0,
        categoryListItemCount: 0,
        loading: false,
        categoryLoading: false,
        targetUsersLoading: false,
        targetUsersList: {},
    },
    () => { }
]);

function Notifications() {
    const [list, setList] = useState({
        notificationList: [],
        categoryList: [],
        notificationListItemCount: 0,
        categoryListItemCount: 0,
        loading: false,
        categoryLoading: false,
        targetUsersLoading: false,
        targetUsersList: {},
    });

    return (
        <ListContext.Provider value={[list, setList]}>
            <NotificationEffectComponent />
            <div className="page-notifications card-container">
                {/*
                <Tabs defaultActiveKey="/notifications">
                    <TabPane tab="お知らせ一覧" key="/notifications">
                        <NotificationsList/>
                    </TabPane>
                    <TabPane tab="お知らせカテゴリ一覧" key="/notifications/category">
                        <NotificationsCategoryList/>
                    </TabPane>
                </Tabs>
                */}
                <NotificationsList />
            </div>
        </ListContext.Provider>
    );
};

export default Notifications;

import { Badge, Tag, Tooltip } from 'antd';

export const createColumns = () => {
    return [
        {
            dataIndex: 'loginId',
            fixed: 'left',
            key: 'loginId',
            render: (loginId, record) => {
                return (
                    <Tooltip mouseEnterDelay={1} title={`UUID: ${record.uuid}`}>
                        {loginId}
                    </Tooltip>
                );
            },
            title: 'ユーザーID',
            width: 40,
        },
        {
            dataIndex: 'belongs',
            fixed: 'left',
            key: 'organizations',
            render: (row) => {
                return row.map((val, index) => (
                    <div key={`${val.schoolClass.organization.name}-${index}`}>
                        {val.schoolClass.organization.name}
                    </div>
                ));
            },
            title: '学校',
            width: 25,
        },
        {
            children: [
                {
                    dataIndex: 'belongs',
                    fixed: 'left',
                    key: 'class',
                    render: (row) => {
                        return row.map((val, index) => (
                            <div key={`${val.schoolClass.name}-${index}`}>
                                <Tag
                                    color='#AAAAAA'
                                    style={{
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                        width: '75px',
                                    }}
                                >
                                    {val.schoolClass.term.name}
                                </Tag>
                                {val.schoolClass.name}
                            </div>
                        ));
                    },
                    title: 'クラス名',
                    width: 20,
                },
                {
                    dataIndex: 'belongs',
                    fixed: 'left',
                    key: 'roles',
                    render: (row) => {
                        return row.map((val, index) => (
                            <div key={`roles-${index}`}>{val.role.name}</div>
                        ));
                    },
                    title: '役割',
                    width: 25,
                },
                {
                    dataIndex: 'belongs',
                    fixed: 'left',
                    key: 'number',
                    render: (row) => {
                        return row.map((val, index) => (
                            <div key={`school_class_num-${index}`}>{val.schoolClass.number}</div>
                        ));
                    },
                    title: '出席番号',
                    width: 20,
                },
            ],
            title: 'クラス',
        },
        {
            children: [
                {
                    dataIndex: 'lastName',
                    fixed: 'left',
                    key: 'lastName',
                    title: '姓',
                    width: 20,
                },
                {
                    dataIndex: 'lastNameKana',
                    fixed: 'left',
                    key: 'lastNameKana',
                    title: '姓（ふりがな）',
                    width: 25,
                },
                {
                    dataIndex: 'firstName',
                    fixed: 'left',
                    key: 'firstName',
                    title: '名',
                    width: 20,
                },
                {
                    dataIndex: 'firstNameKana',
                    fixed: 'left',
                    key: 'firstNameKana',
                    title: '名（ふりがな）',
                    width: 25,
                },
            ],
            title: '名前',
        },
        {
            dataIndex: 'isRead',
            fixed: 'right',
            key: 'isRead',
            render: (isRead) => {
                return (isRead
                    ? <Badge status='success' text='開封済' />
                    : <Badge status='default' text='未開封' />
                )
            },
            style: 'tag',
            title: '開封状態',
            width: '15px',
        },
    ];
};
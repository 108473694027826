import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Radio,
    Select,
    Col,
    Row,
    message,
} from 'antd';
import moment from 'moment';

import BaseForm from 'components/modules/BaseForm';
import Spin from 'components/modules/Spin';
import * as Actions from 'flex/Actions';

const Form = BaseForm;

const AnnualUpdateCard = () => {
    const [form] = Form.useForm();
    const [dayOptionMax, setDayOptionMax] = useState(28);
    const [isActive, setIsActive] = useState(true);
    const [month, setMonth] = useState(2);
    const [day, setDay] = useState(1);
    const [isActiveSaved, setIsActiveSaved] = useState(true);
    const [monthSaved, setMonthSaved] = useState(2);
    const [daySaved, setDaySaved] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [implementation, setImplementation] = useState('');
    const isFirstRender = useRef(false);
    const dispatch = useDispatch();
    const classCopySettingConnection = useSelector(state => state.classCopySettingConnection);
    const { Option } = Select;

    useEffect(() => {
        // 初回表示用
        isFirstRender.current = true;
        dispatch(Actions.http.connection.classes.copyClassSetting.view());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(classCopySettingConnection.meta.fetch);
    }, [classCopySettingConnection]);

    useEffect(() => {
        if (!isFirstRender.current) {
            // ここでVIEW UPDATE 両方のレスポンスを処理
            if (classCopySettingConnection.meta.status === Actions.statusEnum.SUCCESS) {
                const [resultMonth, resultDay] = classCopySettingConnection.payload.result.date.split('-').map(v => Number(v));
                const resultIsActive = classCopySettingConnection.payload.result.is_active;
                const lastImplementation = classCopySettingConnection.payload.result.last_executed_at;

                setIsActive(resultIsActive);
                handleChangeMonth(resultMonth);
                setDay(resultDay);
                setImplementation(lastImplementation);

                setIsActiveSaved(resultIsActive);
                setMonthSaved(resultMonth);
                setDaySaved(resultDay);

                form.setFieldsValue({
                    is_active: resultIsActive,
                    update_day: resultDay,
                    update_month: resultMonth,
                });

                // 更新成功時のみ成功メッセージ
                if (classCopySettingConnection.type === Actions.http.connection.classes.copyClassSetting.update().type) {
                    message.success('更新に成功しました');
                }
            }
        } else {
            isFirstRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classCopySettingConnection]);

    /***
     * 指定された月の中で一番大きい日を返す。
     * @param {number} month
     * @return {number}
     */
    function getMaxDayInMonth(month) {
        switch (month) {
            case 2:
                return 28;
            case 4:
            case 6:
            case 9:
            case 11:
                return 30;
            default:
                return 31;
        }
    }

    function handleChangeMonth(month) {
        form.setFields([{ name: 'update_day', value: 1 }]);
        setDayOptionMax(getMaxDayInMonth(month));
        setDay(1);
        setMonth(month);
    }

    function handleSubmit() {
        form.submit();
    }

    function onFinish(values) {
        const date = ('0' + values.update_month).slice(-2) + '-' + ('0' + values.update_day).slice(-2);
        const postData = {
            date, is_active: values.is_active,
        };
        dispatch(Actions.http.connection.classes.copyClassSetting.update(postData));
    }

    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 24,
        },
    };

    return (
        <div className='card' style={{ marginBottom: '32px', padding: '32px' }} >
            {isLoading ?
                (
                    <div className='flex-center-container' style={{ height: '200px' }}>
                        <Spin />
                    </div>
                ) :
                (
                    <div>
                        <span
                            className='gutter-bottom'
                            style={{ fontWeight: 'bold' }}
                        >
                            {`クラスの自動複製が有効な場合、毎年「${month}月${day}日」の2:00AMに、今の年度のクラスを複製して次の年度のクラスを自動で作成します。`}
                        </span>
                        <br />

                        {isActive ?
                            (<span className='gutter-bottom' style={{ fontWeight: 'bold' }}>現在、クラスの自動複製は「有効」です。</span>) :
                            (<span className='gutter-bottom' style={{ fontWeight: 'bold' }}>現在、クラスの自動複製は「無効」です。</span>)
                        }

                        <p className='gutter-bottom'>※注意:「変更」ボタンを押すまで、変更は完了しません。</p>

                        <Form {...formItemLayout} form={form} name='control-hooks-edit-user' onFinish={onFinish}>
                            <Form.Item label='クラスの自動複製' name='is_active' required>
                                <Radio.Group onChange={e => setIsActive(e.target.value)} value={isActive}>
                                    <Radio value={true}>有効</Radio>
                                    <Radio value={false}>無効</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label='実施日' required>
                                <Row gutter={[8, 0]}>
                                    <Col span={4}>
                                        <Form.Item name='update_month' rules={[{ required: true }]}>
                                            <Select onChange={handleChangeMonth} value={month} >
                                                {[...Array(12).keys()].map(i => ++i).map(i => (<Option key={i} value={i}>{`${i}月`}</Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name='update_day' rules={[{ required: true }]}>
                                            <Select onChange={(value) => { setDay(value); }} value={day}>
                                                {
                                                    [...Array(dayOptionMax).keys()]
                                                        .map(i => ++i)
                                                        .map(i => (<Option key={i} value={i}>{`${i}日`}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Form>
                    </div>
                )
            }
            <p style={{ color: 'red' }}>※以前の実施日時：{implementation ? moment.unix(implementation).format('YYYY/MM/DD HH:mm') : 'なし'}</p>

            <div className='flex-right-container'>
                <Button
                    className='mb-4'
                    disabled={((isActive === isActiveSaved) && (month === monthSaved) && (day === daySaved))}
                    loading={isLoading}
                    onClick={handleSubmit}
                    type='primary'
                >
                    変更
                </Button>
            </div>

        </div>
    );
};

export default AnnualUpdateCard;

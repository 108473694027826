import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, Input, Button, DatePicker, Row, Col, Select } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { SearchOutlined } from '@ant-design/icons';
import isTouchDevice from 'is-touch-device';

import * as Actions from 'flex/Actions';
import { getDefaultLayout, getMoment } from 'constants/GlobalConfig';
import { INITIAL_SEARCH_RULE, SearchContext } from '.';
import BaseForm from 'components/modules/BaseForm';

const Form = BaseForm;
const { Option } = Select;
const layout = getDefaultLayout();
const moment = getMoment();


const NotificationsSearch = ({
    isControllableUser = false,
}) => {
    const dispatch = useDispatch();
    const [, setSearch] = useContext(SearchContext);
    const [form] = Form.useForm();
    const [isFullSearch, setIsFullSearch] = useState(false)
    const [durationStart, setDurationStart] = useState(null)
    const [durationEnd, setDurationEnd] = useState(null)
    const [loading, setLoading] = useState(false);
    const notificationSearchConnection = useSelector(state => state.notificationSearchConnection);
    const notificationSearch = isControllableUser
        ? Actions.http.connection.notification.controlSearch
        : Actions.http.connection.notification.search;

    useEffect(() => {
        setLoading(notificationSearchConnection.meta.fetch);
    }, [notificationSearchConnection]);

    // function onCategoryChange(value) {
    //     form.setFieldsValue({ category: value });
    // }

    function onStatusPeriodChange(value) {
        form.setFieldsValue({ status_period: value });
    };

    function onFinish(values) {
        const params = isFullSearch
            ? {
                ...values,
                publish_start_at: values.publish_start_at ? moment(values.publish_start_at).unix() : null,
                publish_end_at: values.publish_end_at ? moment(values.publish_end_at).unix() : null,
                page: 1
            }
            : {
                ...values,
                page: 1
            };
        delete params.duration;
        const newSearchRule = {
            ...INITIAL_SEARCH_RULE,
            ...params,
        };
        setSearch(newSearchRule);
        dispatch(notificationSearch(newSearchRule, 1));
    };

    function onReset() {
        form.resetFields();
    };

    function onModeChange(e) {
        setIsFullSearch(e.target.value === 'full')
    };

    function disabledStartDate(currentDate) {
        if (!durationEnd) return false;
        return currentDate.valueOf() >= durationEnd.valueOf();
    };

    function disabledEndDate(currentDate) {
        if (!durationStart) return false;
        return currentDate.valueOf() <= durationStart.valueOf();
    };

    return (
        <div className='card general-search notification-search-form-wrapper'>
            <div className='mb-8'>
                <Radio.Group onChange={onModeChange} defaultValue='simple' buttonStyle='solid'>
                    <Radio.Button value='simple'>シンプルな検索</Radio.Button>
                    <Radio.Button value='full'>詳細検索</Radio.Button>
                </Radio.Group>
            </div>

            <Form {...layout} form={form} name='control-hooks-notification-search' onFinish={onFinish}>

                {/*
                <Form.Item name='category_uuid' label='カテゴリ'>
                    <Select
                        placeholder='カテゴリを選択してください'
                        onChange={onCategoryChange}
                        allowClear
                    >
                        {list.categoryList.map((value, index) => {
                            return <Option key={index} value={value.uuid}>{value.name}</Option>
                        })}
                    </Select>
                </Form.Item>
                */}

                <Form.Item name='title' label='タイトル'>
                    <Input placeholder='タイトルを入力してください' />
                </Form.Item>

                {isFullSearch &&
                    <>
                        <Form.Item name='publish_from' label='発信元'>
                            <Input placeholder='発信元を入力してください' />
                        </Form.Item>

                        <Form.Item name='status_period' label='状態'>
                            <Select allowClear
                                placeholder='状態を選択してください'
                                onChange={onStatusPeriodChange}
                            >
                                <Option value='public'>公開中</Option>
                                <Option value='public_before'>公開前</Option>
                                <Option value='public_after'>公開終了</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label='公開期間' extra='指定した期間に公開中となっているお知らせを対象とします。'>
                            <Input.Group>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item noStyle name='publish_start_at'>
                                            <DatePicker
                                                locale={jaJP}
                                                showTime={{ format: 'HH:mm' }}
                                                format='YYYY/MM/DD HH:mm'
                                                disabledDate={disabledStartDate}
                                                value={durationStart}
                                                placeholder='開始日時'
                                                onChange={setDurationStart}
                                                style={{ width: '100%' }}
                                                inputReadOnly={isTouchDevice()}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item noStyle name='publish_end_at'>
                                            <DatePicker
                                                locale={jaJP}
                                                showTime={{ format: 'HH:mm' }}
                                                format='YYYY/MM/DD HH:mm'
                                                disabledDate={disabledEndDate}
                                                value={durationEnd}
                                                placeholder='終了日時'
                                                onChange={setDurationEnd}
                                                style={{ width: '100%' }}
                                                inputReadOnly={isTouchDevice()}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Input.Group>
                        </Form.Item>
                    </>
                }

                <div className='submit-container'>
                    <Button htmlType='button'
                        onClick={onReset}
                        loading={loading}
                    >
                        リセット
                    </Button>
                    <Button type='primary' htmlType='submit'
                        loading={loading}
                        icon={<SearchOutlined />}
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default NotificationsSearch;

import * as React from 'react';

import { InfoOutlined } from '@ant-design/icons';
import { Popover, Typography } from 'antd';

import { getMoment } from 'constants/GlobalConfig';
const moment = getMoment();
const { Title } = Typography;

type Props = {
    created_at: number,
    end_at: number,
    max_attempts: number | undefined,
    max_score: number,
    result_url: string | null,
    start_at: number,
    title: string,
}

const TestInfoPopover: React.VFC<Props> = props => {
    const {
        created_at,
        end_at,
        max_attempts,
        max_score,
        result_url,
        start_at,
        title,
    } = props;

    const isIndefinitePeriod = end_at >= 2147483647;

    const start = moment.unix(start_at).format('YYYY/MM/DD HH:mm');
    const end = isIndefinitePeriod ? '' : moment.unix(end_at).format('YYYY/MM/DD HH:mm');

    return (
        <Popover
            content={
                <div style={{ padding: '0.5rem' }}>
                    <Title level={4}>{title}</Title>

                    <DisplayItem title='公開期間'>
                        {`${start} ～ ${end}`}
                    </DisplayItem>
                    <DisplayItem title='満点'>
                        {`${max_score} 点`}
                    </DisplayItem>
                    <DisplayItem title='MEXCBT解答結果ページ'>
                        {result_url ? '利用可能' : '利用不可'}
                    </DisplayItem>
                    <DisplayItem title='受検可能回数'>
                        {max_attempts ? `${max_attempts} 回` : '回数制限なし'}
                    </DisplayItem>
                    <DisplayItem title='作成日'>
                        {moment.unix(created_at).format('YYYY/MM/DD HH:mm')}
                    </DisplayItem>
                </div>
            }
            overlayStyle={{
                maxWidth: '700px',
            }}
            placement='topRight'
        >
            <div style={{
                border: '1px solid transparent',
                borderColor: '#d9d9d9',
                borderRadius: '50%',
                fontSize: '14px',
                height: '24px',
                minWidth: '24px',
                padding: '0px 0',
                textAlign: 'center',
                width: '24px',
            }}
            >
                <InfoOutlined />
            </div>
        </Popover>
    );
};


type DisplayItemProps = {
    title: string,
}

const DisplayItem: React.FC<DisplayItemProps> = props => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <Title level={5}>{props.title}</Title>
            <div style={{ minHeight: '1rem', paddingLeft: '1rem' }}>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default TestInfoPopover;
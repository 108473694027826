import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button, Table } from 'antd';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import * as Actions from 'flex/Actions';
import { SourceContext } from './index';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';


const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function EditOrderAppGroups(props) {
    const dispatch = useDispatch();
    const [source,] = useContext(SourceContext);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    const appsCategoryOrderEditConnection = useSelector(state => state.appsCategoryOrderEditConnection);

    const isFirstRender = useRef(false);

    useEffect(() => {
        if (props.visible) {
            dispatch(Actions.http.connection.apps.category.search({ page_size: 500 }, 1));
        }
    }, [props.visible, dispatch]);

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!isFirstRender.current) {
            setDataSource(source.appsList.map((value, index) => {
                return {
                    ...value,
                    index: index
                };
            }));
        }
    }, [source.appsList]);

    useEffect(() => {
        setLoading(source.loading);
    }, [dispatch, source.loading])

    useEffect(() => {
        if (!isFirstRender.current) {
            setLoading(appsCategoryOrderEditConnection.meta.fetch);
            if (appsCategoryOrderEditConnection.meta.status === Actions.statusEnum.SUCCESS) {
                props.onOk()
            }
        } else {
            isFirstRender.current = false;
        }
    }, [appsCategoryOrderEditConnection]); //eslint-disable-line

    function handleSubmit() {
        const data = {
            uuids: dataSource.map((value) => {
                return value.uuid;
            })
        };
        dispatch(Actions.http.connection.apps.category.changeOrder(data));
    }

    const DragHandle = sortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));


    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            setDataSource(newData);
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const data = dataSource;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );
    const columns = [
        {
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        }, {
            title: '教材・アプリグループ名称（小学校３年生以上）',
            dataIndex: 'title',
            width: 250,
            key: 'title',
            className: 'drag-visible',
        }, {
            title: '教材・アプリグループ名称（小学校１・２年生）',
            dataIndex: 'title_for_lower',
            width: 250,
            key: 'title_for_lower',
        }, {
            title: '概要',
            dataIndex: 'description',
            width: 250,
            key: 'description',
        },
    ];

    return (
        <BaseModal
            className='common-modal'
            title={<span>教材・アプリグループ並び順の編集</span>}
            visible={props.visible}
            onCancel={props.onCancel}
            forceRender
            footer={[
                <Button key='back_order'
                    loading={loading}
                    size='large' onClick={props.onCancel}
                >
                    キャンセル
                </Button>,
                <Button key='create_order'
                    loading={loading}
                    type='primary' size='large' onClick={handleSubmit}
                >
                    保存
                </Button>
            ]}
        >
            <div>
                <Spin spinning={loading}>
                    <Table
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey='index'
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                </Spin>
            </div>
        </BaseModal>
    );
};

export default EditOrderAppGroups;

import {
    Breadcrumb as AntdBreadcrumb,
} from 'antd';

interface Props {
    title: string,
    onClick: () => void
}

export const Breadcrumb: React.VFC<Props> = ({ title, onClick }) => {
    return (
        <AntdBreadcrumb style={{ marginBottom: '1rem' }} separator='>'>
            <AntdBreadcrumb.Item>
                <button
                    className='text-button'
                    onClick={onClick}
                >
                    全員・すべての学習
                </button>
            </AntdBreadcrumb.Item>
            <AntdBreadcrumb.Item>
                {title}
            </AntdBreadcrumb.Item>
        </AntdBreadcrumb>
    );
};

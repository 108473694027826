import { additionDisplayName } from 'constants/GlobalConfig';
import { defaultState } from '.';
import * as Actions from '../../../Actions';
import { SavingDataExecutor } from '../../../utils';
const { statusEnum } = Actions;

const addControlSavingData = (
    baseStore: StoreAccessor,
    baseNext: StoreNext<ExamCategoryTabState>,
    baseAction: ActionType<unknown, unknown>,
    executor: SavingDataExecutor<ExamCategoryTabState>
) => {
    const examCategoryTab = baseStore.getState().examCategoryTab;

    executor.addSavingData(Actions.http.connection.tao.exam.controlDelete, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        store.dispatch(Actions.http.connection.tao.examControlCategory.controlSearch(
            {
                ...examCategoryTab.search,
                page_size: examCategoryTab.pagination.pageSize
            },
            examCategoryTab.pagination.current,
            'examCategoryTab'
        ));
    });
}

export const savingDataForConnection = (
    baseStore: StoreAccessor,
    baseNext: StoreNext<ExamCategoryTabState>,
    baseAction: ActionType<unknown, unknown>
) => {
    const executor = new SavingDataExecutor<ExamCategoryTabState>();
    const examCategoryTab = baseStore.getState().examCategoryTab;

    addControlSavingData(baseStore, baseNext, baseAction, executor);

    executor.addSavingData(Actions.http.connection.tao.examCategory.delete, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;

        const pageCount = examCategoryTab.examCategoryList.length > 1 ?
            examCategoryTab.pagination.current :
            examCategoryTab.pagination.current === 1 ?
                1 :
                examCategoryTab.pagination.current - 1

        next({
            ...examCategoryTab,
            pagination: {
                ...examCategoryTab.pagination,
                current: pageCount,
            },
        });

        store.dispatch(Actions.http.connection.tao.examCategory.search(
            {
                ...examCategoryTab.search,
                page_size: examCategoryTab.pagination.pageSize
            },
            pageCount,
            'examCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.examCategory.create, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        next({
            ...examCategoryTab,
            modal: {
                ...defaultState.modal
            },
            pagination: {
                ...examCategoryTab.pagination,
                current: 1,
            },
        });

        store.dispatch(Actions.http.connection.tao.examCategory.search(
            {
                ...examCategoryTab.search,
                page_size: examCategoryTab.pagination.pageSize
            },
            1,
            'examCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.examCategory.update, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        next({
            ...examCategoryTab,
            modal: {
                ...defaultState.modal
            },
            pagination: {
                ...examCategoryTab.pagination,
                current: 1,
            },
        });

        store.dispatch(Actions.http.connection.tao.examCategory.search(
            {
                ...examCategoryTab.search,
                page_size: examCategoryTab.pagination.pageSize,
            },
            1,
            'examCategoryTab'
        ));
    });

    executor.addSavingData(Actions.http.connection.tao.examCategory.search, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        next({
            ...examCategoryTab,
            pagination: {
                ...examCategoryTab.pagination,
                total: action.payload.result?.item_count ?? 0,
            },
            examCategoryList: (action.payload.result?.items ?? []).map(examCategory => {
                const exams = examCategory.exams.map(convertToUsefulExamFormat);

                const created_by = additionDisplayName(examCategory.created_by);
                const updated_by = examCategory.updated_by ?
                    additionDisplayName(examCategory.updated_by) :
                    created_by;

                return {
                    ...examCategory,
                    created_by,
                    updated_by,
                    exams,
                };
            }),
            examCategoryListItemCount: action.payload.result?.item_count,
        });
    });

    executor.addSavingData(Actions.http.connection.tao.exam.delete, (store, next, action) => {
        if (action.meta.status === statusEnum.SUCCESS) {
            store.dispatch(Actions.http.connection.tao.examCategory.search(
                {
                    ...examCategoryTab.search,
                    page_size: examCategoryTab.pagination.pageSize
                },
                examCategoryTab.pagination.current,
                'examCategoryTab'
            ));
        }
    });

    executor.addSavingData(Actions.http.connection.tao.examCategory.view, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;
        if(!examCategoryTab.modal.categoryRecord) return;

        const {target_users, target_school_classes, target_roles} = action.payload.result;

        next({
            ...examCategoryTab,
            modal: {
                ...examCategoryTab.modal,
                categoryRecord: {
                    ...examCategoryTab.modal.categoryRecord as Admin.ExamCategory,
                    target_users,
                    target_school_classes,
                    target_roles,
                },
                isLoading: false,
            },
        });
    });

    executor.execute(baseStore, baseNext, baseAction);
};

type ResponseExam = Http.Connection.Response.ExamCategory.Search['result']['items'][number]['exams'][number];
/**
 * レスポンスに含まれる Exam を
 * プログラムで使いやすいデータ形式に変換する
 */
const convertToUsefulExamFormat = (exam: ResponseExam): Exam => ({
    ...exam,
    created_by: additionDisplayName(exam.created_by),
    max_attempts: exam.max_attempts ?? 0,
});

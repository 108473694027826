import { handleAction } from 'redux-actions';
import * as Actions from 'flex/Actions';

const defaultState = {
    payload: {},
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
};

const defaultReducer = {
    next: (state: any, action: any) => {
        return { ...action };
    },
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


// admin 系

export const taoExamConfirmConnection = handleAction(
    Actions.http.connection.tao.exam.confirm,
    defaultReducer,
    defaultState
);

export const taoExamDeleteConnection = handleAction(
    Actions.http.connection.tao.exam.delete,
    defaultReducer,
    defaultState
);

export const taoExamEditConnection = handleAction(
    Actions.http.connection.tao.exam.save,
    defaultReducer,
    defaultState
);

export const taoExamResultConnection = handleAction(
    Actions.http.connection.tao.exam.result,
    defaultReducer,
    defaultState
);

export const taoExamSaveConnection = handleAction(
    Actions.http.connection.tao.exam.save,
    defaultReducer,
    defaultState
);

export const taoExamSaveReportConnection = handleAction(
    Actions.http.connection.tao.exam.saveReport,
    defaultReducer,
    defaultState
);

export const taoExamStatisticsConnection = handleAction(
    Actions.http.connection.tao.exam.statistics,
    defaultReducer,
    defaultState
);

export const taoExamUserConnection = handleAction(
    Actions.http.connection.tao.exam.user,
    defaultReducer,
    defaultState
);

export const taoExamDistributionGraphConnection = handleAction(
    Actions.http.connection.tao.exam.distributionGraph,
    defaultReducer,
    defaultState
);

export const taoExamControlDistributionGraphConnection = handleAction(
    Actions.http.connection.tao.exam.controlDistributionGraph,
    defaultReducer,
    defaultState
);

export const taoExamGraphConnection = handleAction(
    Actions.http.connection.tao.exam.graph,
    defaultReducer,
    defaultState
);

export const taoExamControlGraphConnection = handleAction(
    Actions.http.connection.tao.exam.controlGraph,
    defaultReducer,
    defaultState
);

export const taoExamResultItemConnection = handleAction(
    Actions.http.connection.tao.exam.resultItem,
    defaultReducer,
    defaultState
);

export const taoExamControlResultItemConnection = handleAction(
    Actions.http.connection.tao.exam.controlResultItem,
    defaultReducer,
    defaultState
);

export const taoExamReportDetailConnection = handleAction(
    Actions.http.connection.tao.exam.reportDetail,
    defaultReducer,
    defaultState
);

export const taoExamControlReportDetailConnection = handleAction(
    Actions.http.connection.tao.exam.controlReportDetail,
    defaultReducer,
    defaultState
);

export const taoExamUserItemResultConnection = handleAction(
    Actions.http.connection.tao.exam.userItemResult,
    defaultReducer,
    defaultState
);

export const taoExamControlUserItemResultConnection = handleAction(
    Actions.http.connection.tao.exam.controlUserItemResult,
    defaultReducer,
    defaultState
);

export const taoExamSaveCommentConnection = handleAction(
    Actions.http.connection.tao.exam.saveComment,
    defaultReducer,
    defaultState
);

export const taoExamControlSaveCommentConnection = handleAction(
    Actions.http.connection.tao.exam.controlSaveComment,
    defaultReducer,
    defaultState
);

export const taoExamTargetSchoolClassConnection = handleAction(
    Actions.http.connection.tao.exam.targetSchoolClass,
    defaultReducer,
    defaultState
);

export const taoExamControlTargetSchoolClassConnection = handleAction(
    Actions.http.connection.tao.exam.controlTargetSchoolClass,
    defaultReducer,
    defaultState
);

export const taoExamControlTargetOrganizationConnection = handleAction(
    Actions.http.connection.tao.exam.controlTargetOrganization,
    defaultReducer,
    defaultState
);


// control 系

export const taoExamControlDeleteConnection = handleAction(
    Actions.http.connection.tao.exam.controlDelete,
    defaultReducer,
    defaultState
);

export const taoControlExamEditConnection = handleAction(
    Actions.http.connection.tao.exam.controlSave,
    defaultReducer,
    defaultState
);

export const taoExamControlResultConnection = handleAction(
    Actions.http.connection.tao.exam.controlResult,
    defaultReducer,
    defaultState
);

export const taoExamControlSaveConnection = handleAction(
    Actions.http.connection.tao.exam.controlSave,
    defaultReducer,
    defaultState
);

export const taoExamControlSaveReportConnection = handleAction(
    Actions.http.connection.tao.exam.controlSaveReport,
    defaultReducer,
    defaultState
);

export const taoExamControlStatisticsConnection = handleAction(
    Actions.http.connection.tao.exam.controlStatistics,
    defaultReducer,
    defaultState
);

export const taoExamControlUserConnection = handleAction(
    Actions.http.connection.tao.exam.controlUser,
    defaultReducer,
    defaultState
);

import { useEffect } from 'react';

import { LargeSpin } from 'components/modules/Spin';

import 'styles/pages/deep_linking_response.scss';

/***
 * テストの選択画面のIFrameで、結果表示用画面
 */
export const DeepLinkingResponse: React.VFC = () => {
    useEffect(() => {
        localStorage.setItem('deeplinkingresponse', 'true');
        window.close();
    }, []);

    return (
        <div className='deep-linking-response'>
            <LargeSpin spinning={true} />
        </div>
    );
};

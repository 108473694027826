import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { generateLtiLaunchDomain } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TAB_NAMES, TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

export type ResultExamState = {
    isLoading: boolean
    result: {
        total: number
        users: ExamCategoryTabState['modal']['testView']['users']
    } | undefined
    exam: {
        uuid: string,
        title: string,
    } | undefined
};

export type RequestResultExam = ReturnType<typeof useResultExam>[1];

type Connection = TaoExamControlResultConnection | TaoExamResultConnection;
type User<T extends Connection> = T['payload']['result']['items'][number];

export const useResultExam = () => {
    const [state, setState] = useState<ResultExamState>({
        exam: undefined,
        isLoading: false,
        result: undefined,
    });
    const [tab, setTab] = useState<TabKeys | '' | undefined>();

    const taoExamControlResultConnection = useAppSelector(state => state.taoExamControlResultConnection);
    const taoExamResultConnection = useAppSelector(state => state.taoExamResultConnection);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const dispatch = useDispatch();

    // ほぼ処理が似ていたので共通化したが、
    // これ以上個別の対応が必要になる場合は共通化をやめたほうがいいと思われる
    const effect = useCallback(<T extends Connection>(connection: T, pickExam: (user: User<T>) => Exam.Confirm) => {
        const { meta, payload: { result } } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState(state => ({
                    ...state,
                    isLoading: meta.fetch,
                    result: {
                        total: result.item_count,
                        users: result.items.map(convertUser(isControllableUser, pickExam, tab)),
                    },
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: undefined,
                }));
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isControllableUser, state.isLoading]);

    useEffect(
        () => effect(taoExamControlResultConnection, user => user.control_exam),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlResultConnection]
    );

    useEffect(
        () => effect(taoExamResultConnection, user => user.exam),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamResultConnection]
    );

    const request = useCallback((
        uuid: string,
        title: string,
        data: { page?: number, pageSize?: number },
        // あくまで学校管理者が操作する場合のみ必要なので、それ以外では使われない
        tabName?: 'board' | 'school' | ''
    ) => {
        // ここで result を初期化すると、
        // ローディング中に table のデータが消えてしまう
        setState(state => ({
            ...state,
            exam: { title, uuid },
            isLoading: true,
        }));
        setTab(tabName);

        const resultAction = tabName === TAB_NAMES.BOARD_OF_EDUCATION ?
            Actions.http.connection.tao.exam.controlResult :
            Actions.http.connection.tao.exam.result;

        dispatch(resultAction(
            uuid,
            {
                page: data.page ?? 1,
                pageSize: data.pageSize ?? 10,
            },
            ''
        ));
    }, [dispatch]);

    const resetState = useCallback(() => {
        setState({
            exam: undefined,
            isLoading: false,
            result: undefined,
        });
    }, []);

    return [state, request, resetState] as const;
};

const convertUser = <T extends Connection>(
    isControllableUser: boolean,
    pickExam: (user: User<T>) => Exam.Confirm,
    tab: TabKeys | '' | undefined
) => (
        item: User<T>
    ) => {
        const exam = pickExam(item);
        const history = exam.result_history[0];

        const userName = item.first_name !== '' || item.last_name !== '' ?
            `${item.last_name} ${item.first_name}` :
            item.login_id;

        const user = {
            createdAt: history?.created_at,
            gradingProgress: history?.grading_progress,
            loginId: item.login_id,
            maxScore: history ? history.max_score ?? 0 : undefined,
            name: userName,
            resultUrl: exam.result_url ?
                tab === TAB_NAMES.BOARD_OF_EDUCATION ?
                    `${generateLtiLaunchDomain(isControllableUser, true)}/control-exam-result/${exam.uuid}/${item.uuid}` :
                    `${generateLtiLaunchDomain(isControllableUser, true)}/exam-result/${exam.uuid}/${item.uuid}` :
                undefined,
            score: history ? history.score ?? 0 : undefined,
            uuid: item.uuid,
        };
        const children = exam.result_history.slice(1).map(history => {
            return {
                activityProgress: history.activity_progress,
                agsTimestamp: history.ags_timestamp,
                comment: history.comment,
                createdAt: history.created_at,
                examResultUuid: history.exam_result_uuid,
                gradingProgress: history.grading_progress,
                maxScore: history.max_score ?? 0,
                score: history.score ?? 0,
            };
        });

        // antd の Table は dataSource に children が存在するか否かで
        // 行を展開するか否かを判断するため, 不要な場合は key からも削除して置かなければならない
        if (children && children.length > 0) {
            return Object.assign(user, { children });
        } else {
            return user;
        }
    };
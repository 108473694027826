export const createSubmit = (
    values,
    initialValue,
    order,
    categoryUuid,
    isCooperationUrl,
    selectAppTemplate
) => {
    const shouldCreate = !initialValue;
    const postBody = {
        title: values.title,
        description: values.description,
        open_new_window: values.openNewWindow,
        files: values.files.map(file => file.uuid).filter(e => e),
        category_uuid: categoryUuid,
        publish_start_at: values.duration[0].unix(),
        publish_end_at: values.duration[1].unix(),
        order: initialValue && initialValue.order !== null ? initialValue.order : order,
    }

    // アプリ連携
    if (!isCooperationUrl) {
        postBody.control_application_template_uuid = values.control_application_template_uuid;
        const urlUuidKeyName = shouldCreate ? 'control_application_url_uuid' : 'uuid';
        postBody.application_url = convertUrlToSubmittable(selectAppTemplate, values, urlUuidKeyName);

        const paramUuidKeyName = shouldCreate ? 'control_application_param_uuid' : 'uuid';
        postBody.application_params = values.params && Array.isArray(values.params)
            ? values.params.map(param => convertParamToSubmittable(selectAppTemplate.params, param, paramUuidKeyName))
            : undefined
    }

    // URL連携
    if (isCooperationUrl) {
        postBody.url = values.url;
    }

    return postBody;
};

const convertUrlToSubmittable = (appTemplate, values, uuidKeyName) => {
    const url = appTemplate.url;
    if(appTemplate.type === 1) {
        const {ltiUrlCandidateUuid, ltiUrlUrl, ltiUrlKey, ltiUrlSecret} = values;
        const candidate = ltiUrlCandidateUuid && url.candidates.find(c=>c.uuid === ltiUrlCandidateUuid);
        return {
            [uuidKeyName]: url.uuid,
            url: candidate ? candidate.url : ltiUrlUrl,
            key: candidate ? candidate.key : ltiUrlKey,
            secret: candidate ? candidate.secret : ltiUrlSecret
        }
    } else if(appTemplate.type === 2) {
        if(url.is_choices) {
            const {ltiUrlCandidateUuid} = values;
            const candidate = ltiUrlCandidateUuid && url.candidates.find(c=>c.uuid === ltiUrlCandidateUuid);

            return {
                [uuidKeyName]: url.uuid,
                param_json: candidate.param_json,
            };
        } else {
            return {
                [uuidKeyName]: url.uuid,
                param_json: values.param_json,
            };
        }
    }
}

const convertParamToSubmittable = (editableParams, paramValue, uuidKeyName) => {
    const { ltiParamUuid, ltiParamCandidateUuid, ltiParamValue } = paramValue;
    const param = editableParams.find(v => v.uuid === ltiParamUuid);
    const candidate = ltiParamCandidateUuid && param.candidates.find(c => c.uuid === ltiParamCandidateUuid);
    return {
        [uuidKeyName]: ltiParamUuid,
        key: param.key,
        value: candidate ? candidate.value : ltiParamValue,
    }
}

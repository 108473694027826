import PropTypes from 'prop-types';
import Ruby from './Ruby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export const buttonColorEnum = {
    BLUE: 'blue',
    ORANGE: 'orange',
    WHITE: 'white',
};

export const Button = props => {
    const {
        className = '',
        color = buttonColorEnum.BLUE,
        isForcedAttachRuby = false,
        disabled = false,
        icon = null,
        id = '',
        loading = false,
        onClick = () => { },
        type = 'button',
        title = 'ボタン',
    } = props;

    let defaultStyle = 'flex flex-row items-center rounded-md';
    if (!(className.search(/p.-/g) > 0)) {
        // paddingが指定されていない場合、基本のpx-5 py-2とする
        defaultStyle = defaultStyle.concat(' ', 'py-2 px-5');
    }
    const textSizeRegex = /text-5xs|text-4xs|text-3xs|text-2xs|text-xs|text-sm|text-base|text-lg|text-xl|text-2xl|text-3xl|text-4xl|text-5xl|text-6xl/;
    if (!(textSizeRegex.test(className))) {
        // font-sizeが指定されていない場合 当初の初期値のtext-xlとする
        defaultStyle = defaultStyle.concat(' ', 'text-xl');
    }

    const blueButton = 'bg-lg-blue text-white border border-transparent';
    const orangeButton = 'bg-lg-orange text-white border border-transparent';
    const whiteButton = 'bg-white text-gray-700 hover:text-gray-500 border-gray-300 border';
    let buttonColor = '';
    switch (color) {
        case buttonColorEnum.WHITE:
            buttonColor = whiteButton;
            break;
        case buttonColorEnum.ORANGE:
            buttonColor = orangeButton;
            break;
        case buttonColorEnum.BLUE:
        default:
            buttonColor = blueButton;
            break;
    }

    return (
        <button
            id={id}
            onClick={onClick}
            className={`
                ${defaultStyle} 
                ${className} 
                ${buttonColor} 
                ${loading || disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-opacity-75'}
            `}
            type={type}
            disabled={disabled}
            style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }} // px-5が効かなかったので無理やり指定
        >
            {loading
                ? <span className='mr-2'>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </span>
                : icon
                    ? <span className='mr-2'>
                        {icon}
                    </span>
                    : null
            }
            <h2 className='w-full'>
                <Ruby isForcedAttach={isForcedAttachRuby}>{title}</Ruby>
            </h2>
        </button>
    );
};

Button.propTypes = {
    icon: PropTypes.element,
    id: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
    isForcedAttachRuby: PropTypes.bool,
};

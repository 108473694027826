import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import BaseModal from 'components/modules/BaseModal';
import { BaseTable } from 'components/modules/BaseTable';
import { PageSizeOptions, } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import 'styles/modules/notification_status_modal.scss';
import { createColumns } from './createColumns';
import { TargetUsersInfo } from './TargetUsersInfo';
import { SearchArea } from './SearchArea';
import { ListContext } from '../index';

const INITIAL_SEARCH_RULE = {
    is_read: undefined,
    organization_uuid: undefined,
    school_class_uuid: undefined,
    term_uuid: undefined,
    page: 1,
    page_size: 50,
};

export const STATUS_KEIES = Object.freeze({
    all: 'all',
    alreadyRead: 'alreadyRead',
    notYetRead: 'notYetRead',
});

export const SearchContext = createContext([INITIAL_SEARCH_RULE, () => { }]);

const NotificationStatusModal = ({
    onCancel,
    uuid = '',
    visible = false,
}) => {
    const dispatch = useDispatch();
    const [{ targetUsersLoading, targetUsersList }] = useContext(ListContext);
    const [dataSource, setDataSource] = useState(undefined);
    const [search, setSearch] = useState(INITIAL_SEARCH_RULE);
    const isControllableUser = useSelector(state => state.isControllableUser.payload);

    const searchNotificationTarget = useCallback((data, page) => {
        if (isControllableUser) {
            dispatch(Actions.http.connection.notification.controlTarget(uuid, data, page));
        } else {
            dispatch(Actions.http.connection.notification.target(uuid, data, page));
        }
    }, [dispatch, isControllableUser, uuid]);

    const onChangePagination = useCallback((page, page_size) => {
        setPagination(pagination => ({
            ...pagination,
            current: page,
            pageSize: page_size,
        }));
        const { page: _, ...restSearchRule } = search;
        const newSearchRule = {
            ...restSearchRule,
            page_size: page_size,
        };
        setSearch({
            ...newSearchRule,
            page: page,
        });
        searchNotificationTarget(newSearchRule, page);
    }, [search, searchNotificationTarget]);// eslint-disable-line react-hooks/exhaustive-deps

    const [pagination, setPagination] = useState({
        onChange: onChangePagination,
        onShowSizeChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size,
            }));
            setSearch(search => ({
                ...search,
                page: page,
                page_size: page_size,
            }));
        },
        pageSize: INITIAL_SEARCH_RULE.page_size,
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: targetUsersList.itemCount || 0,
    });

    useEffect(() => {// onChangePaginationが再作成されたらpaginationを更新
        setPagination((pagination) => ({ ...pagination, onChange: onChangePagination }))
    }, [onChangePagination]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 展開時の初回検索
        if (!visible) return;

        const { page, ...restSearchRule } = INITIAL_SEARCH_RULE;
        setSearch(INITIAL_SEARCH_RULE);
        searchNotificationTarget(restSearchRule, page);
    }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// dataSourceの初期化
        if (visible) return;

        setDataSource(undefined);
    }, [visible]);

    useEffect(() => {// user一覧取得時のデータソースとpaginationの更新
        setDataSource(targetUsersList.items);
        setPagination(pagination => ({
            ...pagination,
            current: search.page,
            pageSize: targetUsersList.pageSize,
            total: targetUsersList.itemCount,
        }));
    }, [targetUsersList]);// eslint-disable-line react-hooks/exhaustive-deps

    // Cellを作成
    const columns = createColumns();

    if (!visible) return null;

    return (
        <BaseModal
            className='common-modal notification-status-modal'
            footer={[<Button key='back' onClick={onCancel} size='large'>キャンセル</Button>]}
            forceRender
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><NotificationOutlined /> 公開対象者と配信状況の確認</span>}
            visible={visible}
        >
            <div className='wrapper'>
                <TargetUsersInfo
                    loading={targetUsersLoading}
                    targetUsersList={targetUsersList}
                />

                <SearchContext.Provider value={[search, setSearch]}>
                    <SearchArea
                        isControl={isControllableUser}
                        targetUsersLoading={targetUsersLoading}
                        notificationUuid={uuid}
                    />
                </SearchContext.Provider>

                <BaseTable
                    bordered
                    className='general-table'
                    columns={columns}
                    dataSource={dataSource}
                    emptyDescription='対象のユーザーはいません'
                    loading={targetUsersLoading}
                    pagination={pagination}
                    rowKey='uuid'
                    size='middle'
                />
            </div>
        </BaseModal>
    );
};

export default NotificationStatusModal;

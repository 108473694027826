import { useContext } from 'react';
import { ApplicationView } from './ApplicationView';
import { StudentView } from './StudentView';
import { Breadcrumb } from './Breadcrumb';
import { BreadCrumbContext, defaultBreadCrumbStatus } from './index';
import { useAppCategoryResult } from '../utils/useAppCategoryResult';

type Props = {
    category: App.AppsCategory,
    onSearch: ReturnType<typeof useAppCategoryResult>[1],
};

export const ResultView: React.VFC<Props> = ({
    category,
    onSearch,
}) => {
    const { breadcrumb, setBreadcrumb } = useContext(BreadCrumbContext);
    const handleClickAllBreadCrumb = () => {
        setBreadcrumb(defaultBreadCrumbStatus);
    };

    return (
        <>
            <Breadcrumb
                title={breadcrumb.name ?? ''}
                onClick={handleClickAllBreadCrumb}
            />
            {breadcrumb.type === 'Student' &&
                <StudentView />
            }
            {breadcrumb.type === 'Application' &&
                <ApplicationView
                    category={category}
                    onSearch={onSearch}
                />
            }
        </>
    );
}
import React from 'react';

import { Button } from 'antd';

type Props = {
    onClickTestName: () => void
    testName: string
    userName?: string
}

export const Header: React.VFC<Props> = ({
    onClickTestName,
    testName,
    userName,
}) => {
    return (
        <div
            style={{
                backgroundColor: '#F4F5F7',
                padding: '16px 24px',
            }}
        >
            <div
                style={{
                    display: 'flex ',
                    flexDirection: 'row',
                    marginBottom: userName !== undefined ? '8px' : '0px',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#627A86',
                        color: 'white',
                        padding: '4px 8px',
                        width: 'fit-content',
                    }}
                >
                    テスト名
                </div>
                {
                    userName !== undefined  ?
                        (
                            <Button
                                className='link'
                                onClick={onClickTestName}
                                style={{ padding: '5px 8px', }}
                                type='link'
                            >
                                {testName}
                            </Button>
                        ) :
                        (
                            <div
                                style={{ padding: '5px 8px' }}
                            >
                                {testName}
                            </div>
                        )
                }
            </div>

            {userName !== undefined && (
                <div
                    style={{
                        display: 'flex ',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#627A86',
                            color: 'white',
                            padding: '5px 8px',
                            width: 'fit-content',
                        }}
                    >
                        ユーザー名
                    </div>
                    <div
                        style={{
                            padding: '5px 8px',
                        }}
                    >
                        {userName}
                    </div>
                </div>
            )}
        </div>
    );
};
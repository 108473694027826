import { Fragment } from 'react';

import ExportOutlined from '@ant-design/icons/lib/icons/ExportOutlined';
import { Button, Progress, Typography } from 'antd';

import { generateFileDomain } from 'constants/GlobalConfig';

import {
    BACKGROUND_JOB_TYPE,
    BACKGROUND_JOB_TYPE_GROUPS as JOB_TYPE_GROUPS,
} from '../BackgroundJobProvider';


export const getJobMessage = (jobInfo) => {
    if (jobInfo.isError || (jobInfo.job.done_at && !jobInfo.job.is_success)) {
        return <ErrorComponent job={jobInfo.job} />;
    }

    const job = jobInfo.job;
    const isFailDryRun = !job?.result?.is_success;
    const isEmpty = job?.result?.is_empty;
    const downloadUuid = job?.result?.file_uuid;

    if (JOB_TYPE_GROUPS.SYNC_DRY_RUN_TYPES.includes(job.type)) {
        if (job.done_at) {
            return (
                <ConfirmTableComponent
                    downloadUuid={downloadUuid}
                    isDryRun={true}
                    isEmpty={isEmpty}
                    isFailDryRun={isFailDryRun}
                />
            );
        } else {
            return null;
        }

    } else if (JOB_TYPE_GROUPS.SYNC_TYPES.includes(job.type)) {
        if (job.done_at) {
            const title = job.type === BACKGROUND_JOB_TYPE.SYNC_GROUP ?
                'クラス情報の同期が完了しました' :
                job.type === BACKGROUND_JOB_TYPE.SYNC_USER ?
                    'ユーザー情報の同期が完了しました' :
                    job.type === BACKGROUND_JOB_TYPE.SYNC_MEMBER ?
                        '所属情報の同期が完了しました' :
                        '';
            return (
                <>
                    <SuccessMessage isFailDryRun={isFailDryRun} title={title} />
                    <ConfirmTableComponent
                        downloadUuid={downloadUuid}
                        isDryRun={false}
                        isEmpty={isEmpty}
                        isFailDryRun={isFailDryRun}
                    />
                </>
            );
        } else {
            return (
                <Typography.Text className='text'>
                    処理を行っています。処理の内容によっては時間がかかることがあります
                </Typography.Text>
            );
        }

    } else if (JOB_TYPE_GROUPS.CSV_IMPORT_TYPES.includes(job.type)) {
        return job.done_at ?
            <SimpleMessageComponent message={job.message} /> :
            <ProgressMessageComponent job={job} />;

    } else if (JOB_TYPE_GROUPS.CSV_EXPORT_TYPES.includes(job.type)) {
        return job.done_at ?
            <SimpleMessageComponent message={job.message} /> :
            null;

    } else if (JOB_TYPE_GROUPS.SAVE_TYPES.includes(job.type)) {
        if (BACKGROUND_JOB_TYPE.SAVE_EXAM_REPORT === job.type) {
            return (
                <InfoWithMessageComponent
                    info={job.message}
                />
            );
        }

        return job.done_at ?
            (
                <InfoWithMessageComponent
                    info={job.message}
                />
            ) :
            null;
    } else {
        return null;
    }
};

const SuccessMessage = ({
    isFailDryRun,
    title,
}) => {
    if (isFailDryRun) return null;

    return (
        <div className='sync-flex center'>
            <Typography.Title className='text' level={4}>
                {title}
            </Typography.Title>
        </div>
    );
};

const ConfirmTableComponent = ({
    downloadUuid,
    isDryRun,
    isEmpty,
    isFailDryRun,
}) => {
    return (
        <div className='sync-flex'>
            {isFailDryRun ?
                <>
                    <h2>同期{isDryRun ? 'チェック' : ''}に失敗しました。</h2>
                    {isDryRun ?
                        <p>{isEmpty ? '該当のエラー情報を確認できません' : '同期処理予定の詳細情報を確認するには結果CSVをエクスポートしてください'}</p> :
                        <p>{isEmpty ? '該当のエラー情報を確認できません' : '同期処理結果の詳細情報を確認するには結果CSVをエクスポートしてください'}</p>
                    }
                </> :
                <>
                    <h2>同期{isDryRun ? 'チェック' : ''}が成功しました。</h2>
                    {isDryRun ?
                        <p>{isEmpty ? '同期処理による変更予定の詳細情報はありません' : '同期処理予定の詳細情報を確認するには結果CSVをエクスポートしてください'}</p> :
                        <p>{isEmpty ? '同期処理により変更された詳細情報はありません' : '同期処理結果の詳細情報を確認するには結果CSVをエクスポートしてください'}</p>
                    }
                </>
            }
            {isFailDryRun ?
                <div className='sync-flex center'>
                    <Typography.Title className='text' level={4}>
                        エラーが発生しました
                    </Typography.Title>
                    <Typography.Text>
                        同期に失敗しました。原因としては以下が考えられます。
                        <br /><br />
                        ・パスワードがセキュリティ要件を満たしていない可能性があります。パスワードを確認してください。
                        <br />
                        ・同期中にクラスまたはユーザーの情報の更新が行われた可能性があります。再度同期を実行してください
                        <br /><br />
                        上記で解決しない場合は、システム管理者までお問い合わせください。
                    </Typography.Text>
                </div> :
                null
            }
            {isEmpty ?
                null :
                <Button
                    className='export-button'
                    icon={<ExportOutlined />}
                    onClick={() => {
                        const urlString = `${generateFileDomain()}/file/view/${downloadUuid}`;
                        window.open(urlString);
                    }}
                    type='primary'
                >
                    CSVエクスポート
                </Button>
            }
        </div>
    );
};

const ErrorComponent = ({ job }) => {
    if (!job) {
        return (
            <div className='sync-flex center'>
                <Typography.Title className='text' level={4}>
                    エラーが発生しました
                </Typography.Title>
            </div>
        );
    }

    if (
        [
            ...JOB_TYPE_GROUPS.SYNC_TYPES,
            ...JOB_TYPE_GROUPS.SYNC_DRY_RUN_TYPES,
        ].includes(job.type)
    ) {
        return (
            <div className='sync-flex center'>
                <Typography.Title className='text' level={4}>
                    エラーが発生しました
                </Typography.Title>
                <Typography.Text>
                    同期に失敗しました。原因としては以下が考えられます。
                    <br /><br />
                    ・パスワードがセキュリティ要件を満たしていない可能性があります。パスワードを確認してください。
                    <br />
                    ・同期中にクラスまたはユーザーの情報の更新が行われた可能性があります。再度同期を実行してください
                    <br /><br />
                    上記で解決しない場合は、システム管理者までお問い合わせください。
                </Typography.Text>
            </div>
        );
    } else if (
        [
            ...JOB_TYPE_GROUPS.CSV_IMPORT_TYPES,
            ...JOB_TYPE_GROUPS.CSV_EXPORT_TYPES,
        ].includes(job.type)
    ) {
        return (
            <div className='sync-flex center'>
                <Typography.Title className='text' level={4}>
                    エラーが発生しました
                </Typography.Title>
                <Typography.Text>
                    {job?.message ? job.message : null}
                    <br />
                    {/*エラー時のresultの中身が少し複雑なので対策をして表示する*/}
                    {Object.values(job.result ?? {}).map((unknownTypeErr, index) => {
                        if (typeof (unknownTypeErr) === 'string') {
                            return <Fragment key={index}> {unknownTypeErr} <br /></Fragment>;
                        }
                        if (Array.isArray(unknownTypeErr)) {
                            return unknownTypeErr.map((err, childIndex) => {
                                return <Fragment key={`${index}_${childIndex}`}> {err} <br /></Fragment>;
                            });
                        }
                        if (typeof (unknownTypeErr) === 'object') {
                            const contentList = [];
                            analyzeJSON(unknownTypeErr, (rVal) => {
                                contentList.push(rVal);
                            });
                            return contentList.map((err, childIndex) => {
                                return <Fragment key={`${index}_${childIndex}`}> {err} <br /></Fragment>;
                            });
                        }
                    })}
                </Typography.Text>
            </div>
        );
    } else if (
        JOB_TYPE_GROUPS.SAVE_TYPES.includes(job.type)
    ) {
        // 本来は split など使わず、値を分けるべきだが面倒なので、一旦これで対応
        const [info, message] = job.message.split('\n\n');
        return (
            <InfoWithMessageComponent
                header='エラーが発生しました'
                info={info}
                message={message}
            />
        );
    } else {
        return (
            <div className='sync-flex center'>
                <Typography.Title className='text' level={4}>
                    エラーが発生しました
                </Typography.Title>
            </div>
        );
    }
};

const SimpleMessageComponent = ({ message }) => {
    return (
        <div className='sync-flex center'>
            <Typography.Text className='text'>
                {message.split('\n').map((line, index) => (
                    <Fragment key={index}>
                        {line}<br />
                    </Fragment>
                ))}
            </Typography.Text>
        </div>
    );
};

const ProgressMessageComponent = ({ job }) => {
    return (
        <div className='job-progress-modal'>
            <p>{job.message}</p>
            {job.result ?
                <div>
                    <Progress
                        percent={Math.floor(job.result.processed_count / job.result.total_count * 100)}
                        status='active'
                    />
                    <p>{job.result.total_count}件中 {job.result.processed_count}件</p>
                </div> :
                null
            }
        </div>
    );
};


const InfoWithMessageComponent = ({ info, header, message }) => {
    return (
        <div className='sync-flex center'>
        {
            header && <Typography.Title className='text' level={4}>
                {header}
            </Typography.Title>
        }
            <Typography.Text>
                {info.split('\n').map((line, index) => (
                    <Fragment key={index}>
                        {line}<br />
                    </Fragment>
                ))}
            </Typography.Text>
            <Typography.Text className='text'>
                {message && message.split('\n').map((line, index) => (
                    <Fragment key={index}>
                        {line}<br />
                    </Fragment>
                ))}
            </Typography.Text>
        </div>
    );
};

function analyzeJSON(data, callback) {
    for (const key in data) {
        if (typeof data[key] === 'object') {
            analyzeJSON(data[key], callback);
        } else {
            callback(`${data[key]}`);
        }
    }
}
import { combineActions, handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    next: (state: any, action: any) => {
        return { ...action };
    },
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const appsListConnection = handleAction(
    Actions.http.connection.apps.search,
    defaultReducer,
    defaultState
);

export const appsViewConnection = handleAction(
    Actions.http.connection.apps.view,
    defaultReducer,
    defaultState
);

export const appsEditConnection = handleAction(
    combineActions(
        Actions.http.connection.apps.delete,
        Actions.http.connection.apps.controlDelete,
        Actions.http.connection.apps.create,
        Actions.http.connection.apps.controlCreate,
        Actions.http.connection.apps.update,
        Actions.http.connection.apps.controlUpdate
    ),
    defaultReducer,
    defaultState
);

export const appsOrderEditConnection = handleAction(
    combineActions(
        Actions.http.connection.apps.changeOrder,
        Actions.http.connection.apps.controlChangeOrder,
    ),
    defaultReducer,
    defaultState
);

export const appsSaveConnection = handleAction(
    Actions.http.connection.apps.save,
    defaultReducer,
    defaultState
);

export const appsControlSaveConnection = handleAction(
    Actions.http.connection.apps.controlSave,
    defaultReducer,
    defaultState
);

export const appsConfirmConnection = handleAction(
    Actions.http.connection.apps.confirm,
    defaultReducer,
    defaultState
);

export const appsControlViewConnection = handleAction(
    Actions.http.connection.apps.controlView,
    defaultReducer,
    defaultState
);

export const appsControlTargetOrganizationConnection = handleAction(
    Actions.http.connection.apps.controlTargetOrganization,
    defaultReducer,
    defaultState
);


export const appsControlTargetSchoolClassConnection = handleAction(
    Actions.http.connection.apps.controlTargetSchoolClass,
    defaultReducer,
    defaultState
);

import { useEffect } from 'react';

import { LargeSpin } from 'components/modules/Spin';
import { useAppSelector } from 'flex/utils';

export const AppDeepLinkingResponse: React.VFC = () => {
    const isControllableUser = useAppSelector((state) => state.isControllableUser.payload);

    useEffect(() => {
        localStorage.setItem(
            isControllableUser ? 'controlAppDeepLinking' : 'appDeepLinking',
            'true'
        );
        window.close();
        // 初回のみ実行
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // 念のためにローディングを表示しているが、
    // 基本的に即座に閉じるので表示されない
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
            }}
        >
            <LargeSpin spinning={true} />
        </div>
    );
};

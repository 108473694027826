import { useEffect } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Form,
    Input,
    Row,
    Col,
    Radio,
    message,
} from 'antd';
import Title from 'antd/lib/typography/Title';

import { useGetIpFilterSettings } from './hooks/useGetIpFilterSettings';
import { useUpdateIpFilterSettings } from './hooks/useUpdateIpFilterSettings';
import { formRules } from 'constants/GlobalConfig';


const SecurityPage = () => {
    const [form] = Form.useForm();
    const ipFilters = Form.useWatch('ipFilters', form);
    const [getState, getIpFilterSettings] = useGetIpFilterSettings(
        (meta, payload) => {
            if (meta.status !== 'SUCCESS') return;

            form.setFieldsValue({
                ipFilters: payload.result.ip_filters.length > 0 ?
                    payload.result.ip_filters.map(e => ({
                        comment: e.comment,
                        ipAddress: e.setting,
                    })) :
                    [
                        {
                            comment: undefined,
                            ipAddress: undefined,
                        },
                    ],
                isEnabled: payload.result.is_enabled,
            });
        }
    );

    const [updateState, updateIpFilterSettings] = useUpdateIpFilterSettings(
        (meta, _payload) => {
            if (meta.status !== 'SUCCESS') return;

            message.success('IP 制限設定を更新しました');
        }
    );

    useEffect(() => {
        getIpFilterSettings();
        form.setFieldsValue({
            ipFilters: [
                {
                    comment: undefined,
                    ipAddress: undefined,
                },
            ],
            isEnabled: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinish = (values: FormValues) => {
        updateIpFilterSettings(
            values.isEnabled,
            values.ipFilters.map(e => ({
                comment: e.comment,
                isActive: true,
                setting: e.ipAddress,
            })),
        );
    };

    const isLoading = getState.isLoading || updateState.isLoading;

    return (
        <div>
            <Card title={<Title level={4}>IP 制限管理</Title>} type='inner'>
                <Form<FormValues> form={form} onFinish={handleFinish} {...layout} >
                    <Form.Item label='IP 制限' name='isEnabled'>
                        <Radio.Group disabled={isLoading}>
                            <Radio value={false}>制限しない</Radio>
                            <Radio value={true}>制限する</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.List name='ipFilters'>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name }) => (
                                    <Form.Item
                                        key={key}
                                        label={name === 0 ? 'IP リスト' : ''}
                                        {...(name === 0 ? {} : layoutWithoutLabel)}
                                    >
                                        <Row gutter={8}>
                                            <Col span={11}>
                                                <Form.Item
                                                    name={[name, 'ipAddress']}
                                                    noStyle
                                                    rules={[
                                                        {
                                                            message: 'IP アドレスを入力してください',
                                                            required: (ipFilters[name]?.comment ?? '') !== '',
                                                        },
                                                        formRules.range({ label: 'IP アドレス', max: 10000 }),
                                                    ]}
                                                >
                                                    <Input disabled={isLoading} placeholder='IP アドレス' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item noStyle
                                                    name={[name, 'comment']}
                                                    rules={[formRules.range({ label: 'コメント', max: 10000 })]}
                                                >
                                                    <Input disabled={isLoading} placeholder='コメント' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2} >
                                                {
                                                    name === 0 ?
                                                        null :
                                                        <Button
                                                            className='mr-2'
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => remove(name)}
                                                            shape='circle'
                                                            size='small'
                                                        />
                                                }
                                            </Col>
                                        </Row>

                                    </Form.Item>
                                ))}
                                <Form.Item {...layoutWithAddButton}>
                                    <Button block icon={<PlusOutlined />} onClick={() => add()} type='dashed' />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <div className='flex-right-container' >
                        <Button disabled={isLoading} htmlType='submit' loading={isLoading} type='primary'>
                            保存
                        </Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default SecurityPage;


type FormValues = {
    isEnabled: boolean;
    ipFilters: {
        ipAddress: string;
        comment: string;
    }[];
};

const layout = {
    labelCol: {
        sm: { span: 6 },
        xl: { span: 5 },
        xxl: { span: 4 },
    },
    wrapperCol: {
        span: 24,
    },
};

const layoutWithoutLabel = {
    wrapperCol: {
        sm: {
            offset: layout.labelCol.sm.span,
        },
        xl: {
            offset: layout.labelCol.xl.span,
        },
        xxl: {
            offset: layout.labelCol.xxl.span,
        },
    },
};

const layoutWithAddButton = {
    wrapperCol: {
        xl: {
            offset: layout.labelCol.xl.span,
        },
        xxl: {
            offset: layout.labelCol.xxl.span,
        },
    },
};
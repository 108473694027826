import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

export type DeleteExamState = {
    isLoading: boolean
    status: typeof Actions.statusEnum[keyof typeof Actions.statusEnum] | 'DEFAULT'
};

export const useDeleteExam = () => {
    const [state, setState] = useState<DeleteExamState>({
        isLoading: false,
        status: 'DEFAULT',
    });

    const taoExamControlDeleteConnection = useAppSelector(state => state.taoExamControlDeleteConnection);
    const taoExamDeleteConnection = useAppSelector(state => state.taoExamDeleteConnection);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta } = taoExamControlDeleteConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamControlDeleteConnection]);

    useEffect(() => {
        const { meta } = taoExamDeleteConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState({
                    isLoading: false,
                    status: meta.status,
                });
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamDeleteConnection]);

    const requestData = useCallback((
        uuid: string,
    ) => {
        setState({
            isLoading: true,
            status: Actions.statusEnum.REQUEST,
        });

        const deleteAction = isControllableUser ?
            Actions.http.connection.tao.exam.controlDelete :
            Actions.http.connection.tao.exam.delete;

        dispatch(deleteAction(uuid, ''));
    }, [dispatch, isControllableUser]);

    const resetData = useCallback(() => {
        setState({
            isLoading: false,
            status: 'DEFAULT',
        });
    }, []);

    return [state, requestData, resetData] as const;
};

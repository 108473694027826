import * as Actions from '../../../Actions';
import { ViewLogicExecutor } from '../../../utils';
import { TAB_NAMES } from './index';

export const viewLogic = (
    baseStore: StoreAccessor,
    baseNext: StoreNext<ExamCategoryTabState>,
    baseAction: ActionType<unknown, unknown>
) => {
    const executor = new ViewLogicExecutor<ExamCategoryTabState>();
    const isControllableUser = baseStore.getState().isControllableUser;

    const {
        modal: {
            categoryRecord: adminModalRecord,
            examCategoryStatus: {
                search: adminStatusSearch,
            },
            isCopy: isAdminCopyModeInModal,
        },
        pagination: adminPagination,
        search,
    } = baseStore.getState().examCategoryTab;

    const {
        modal: {
            categoryRecord: controlModalRecord,
            examCategoryStatus: {
                search: controlStatusSearch,
            },
            isCopy: isControlCopyModeInModal,
        },
        pagination: controlPagination,
    } = baseStore.getState().examControlCategoryTab;

    const modalRecord = isControllableUser.payload ? controlModalRecord : adminModalRecord;
    const statusSearch = isControllableUser.payload ? controlStatusSearch : adminStatusSearch;
    const isCopyModeInModal = isControllableUser.payload ? isControlCopyModeInModal : isAdminCopyModeInModal;
    const pagination = isControllableUser.payload ? controlPagination : adminPagination;

    executor.addViewLogic(Actions.view.viewLogic.examCategoryTab.prepareView, (store, next, action) => {
        if (isControllableUser.payload || action.payload.tabName === TAB_NAMES.BOARD_OF_EDUCATION) {
            next(Actions.http.connection.tao.examControlCategory.controlSearch(
                { ...search, page_size: pagination.pageSize },
                pagination.current,
                'examControlCategoryTab'
            ));
        } else {
            next(Actions.http.connection.tao.examCategory.search(
                { ...search, page_size: pagination.pageSize },
                pagination.current,
                'examCategoryTab'
            ));
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.examCategoryTab.submitEdit, (store, next, action) => {
        const { description, duration, title, uuid, ...rest } = action.payload.values;
        const shouldCreate = action.payload.shouldCreate;
        const isCopy = action.payload.isCopy;

        const basePostBody = {
            description,
            // 注意: 強制でtrue
            is_active: true,
            // #1581 全体管理者の場合は強制で false
            is_required: rest.is_controllable ? false : rest.is_required,
            // 期間は必須なので, この時点で undefined になることはない
            // TODO: 可能であれば ! で強制的に変えるのではなく, 型で表現する
            publish_start_at: duration[0]!.unix(),
            publish_end_at: duration[1]!.unix(),
            title,
        }

        if (rest.is_controllable) {
            const target = rest.publish_to_all_organizations ? [] : rest.target.target_organizations;
            const target_grades = rest.target_grades;
            let target_term = rest.target_term;

            if(!isTargetGrades(target_grades)) return;
            if(rest.is_designated_term) {
                if(typeof target_term !== 'string') return;
            }else{
                target_term = '';
            }

            const addPostBody = {
                is_designated_term: rest.is_designated_term,
                publish_to_all_organizations: rest.publish_to_all_organizations,
                target_grades: target_grades,
                target_organizations: target.map(value => value.uuid),
                target_permissions: rest.target_permissions,
                target_term: target_term,
            }
            const postBody: Http.Connection.Request.Parameter.ExamCategory.ControlCreate = {
                ...basePostBody,
                ...addPostBody,
            };

            if(isCopy && uuid){
                next(Actions.http.connection.tao.examControlCategory.controlCreate(uuid, postBody, 'examControlCategoryTab'));
            }else{
                if (!shouldCreate && uuid) {
                    next(Actions.http.connection.tao.examControlCategory.controlUpdate(uuid, postBody, 'examControlCategoryTab'));
                } else {
                    next(Actions.http.connection.tao.examControlCategory.controlCreate('', postBody, 'examControlCategoryTab'));
                }
            }
        } else {
            const addPostBody = {
                target_users: rest.target.target_users?.map(value => value.uuid),
                target_school_classes: rest.target.target_school_classes?.map(value => value.uuid),
                target_roles: rest.target.target_roles?.map(value => value.uuid),
            }
            const postBody = { ...basePostBody, ...addPostBody };
            if(isCopy && uuid){
                next(Actions.http.connection.tao.examCategory.create(uuid, postBody, 'examCategoryTab'));
            }else{
                if (!shouldCreate && uuid) {
                    next(Actions.http.connection.tao.examCategory.update(uuid, postBody, 'examCategoryTab'));
                } else {
                    next(Actions.http.connection.tao.examCategory.create('', postBody, 'examCategoryTab'));
                }
            }
        }
        next(Actions.view.viewLogic.examCategoryTab.submitEdit(action.payload.values, shouldCreate, isCopy, action.payload.tabName));
    });

    executor.addViewLogic(Actions.view.viewLogic.examCategoryTab.cancelModal, (store, next, action) => {
        next(Actions.view.viewLogic.examCategoryTab.cancelModal());

        if (!isCopyModeInModal || !modalRecord) return;

        if (isControllableUser.payload) {
            next(Actions.http.connection.tao.examControlCategory.controlDelete(
                modalRecord.uuid,
                'examControlCategoryTab'
            ));
        } else {
            next(Actions.http.connection.tao.examCategory.delete(
                modalRecord.uuid,
                'examCategoryTab'
            ));
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.examCategoryTab.submittedSave, (store, next, action) => {
        next(Actions.view.viewLogic.examCategoryTab.cancelModal());
        if (isControllableUser.payload) {
            next(Actions.http.connection.tao.examControlCategory.controlSearch(
                { ...search, page_size: pagination.pageSize },
                pagination.current,
                'examControlCategoryTab'
            ));
        } else {
            next(Actions.http.connection.tao.examCategory.search(
                { ...search, page_size: pagination.pageSize },
                pagination.current,
                'examCategoryTab'
            ));
        }
    });

    executor.addViewLogic(Actions.view.viewLogic.examCategoryTab.submitPageStatusList, (store, next, action) => {
        const page_size = action.payload.page_size;
        const page = action.payload.page;
        if (isControllableUser.payload) {
            next(Actions.http.connection.tao.examControlCategory.controlResult(
                modalRecord?.uuid || '',
                { ...statusSearch, page_size },
                page,
                'examControlCategoryTab'
            ));
        } else {
            if (action.payload.tabName === TAB_NAMES.BOARD_OF_EDUCATION) {
                next(Actions.http.connection.tao.examControlCategory.controlResult(
                    modalRecord?.uuid || '',
                    { ...statusSearch, page_size },
                    page,
                    'examControlCategoryTab'
                ));
            } else {
                next(Actions.http.connection.tao.examCategory.result(
                    modalRecord?.uuid || '',
                    { ...statusSearch, page_size },
                    page,
                    'examCategoryTab'
                ));
            }
        }
        next(Actions.view.viewLogic.examCategoryTab.savePageStatusList(page, page_size));
    });

    executor.execute(baseStore, baseNext, baseAction);
};

/**
 * target_grades が初期値の [undefined] でないことを検証する
 */
const  isTargetGrades = (target_grades: string[] | [undefined]): target_grades is string[] => {
    return typeof target_grades[0] === 'string'
}

import * as React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import sanitizeHtml from 'sanitize-html';

interface Props {
    config?: NonNullable<Parameters<typeof sanitizeHtml>[1]>
    model: string
}

/**
 * sanitize 済みの HTML を表示する Viewer
 */
const RichTextView: React.VFC<Props> = props => {
    const { config = {}, model } = props;

    const options = Object.assign(defaultConfig, config);
    const sanitizedHtml = sanitizeHtml(model, options);

    return (
        <FroalaEditorView
            model={sanitizedHtml}
        />
    )
}

export default RichTextView;

const defaultConfig = {
    allowedTags: [
        'a',
        'br',
        'code',
        'em',
        'hr',
        'p',
        'strong',
        'u'
    ],
};
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Actions from 'flex/Actions';
import { ListContext } from '../index';


function UsersEffectComponent(props) {
    const [list, setList] = useContext(ListContext);

    const usersSearchConnection = useSelector(state => state.usersSearchConnection);

    useEffect(() => {
        console.log(usersSearchConnection);
        if (usersSearchConnection.meta.status === Actions.statusEnum.SUCCESS) {
            setList({
                ...list,
                usersList: usersSearchConnection.payload.result.items,
                usersListItemCount: usersSearchConnection.payload.result.item_count
            });
        }
        if (usersSearchConnection.meta.status === Actions.statusEnum.FAILURE || usersSearchConnection.meta.status === Actions.statusEnum.ERROR) {
            //OPTIMIZE 基本的には汎用エラーコンポーネントがエラーをスポイルしてここでは何も起こらないのが正なので、処理しない
        }
    }, [usersSearchConnection]);// eslint-disable-line

    return null;
};

export default UsersEffectComponent;
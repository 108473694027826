import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Actions from 'flex/Actions';

import { ListContext } from '../index';


const ClassEffectComponent = () => {
    const [list, setList] = useContext(ListContext);
    const classSearchConnection = useSelector(state => state.classSearchConnection);
    const gradeSearchConnection = useSelector(state => state.gradeSearchConnection);

    useEffect(() => {
        if (classSearchConnection.meta.status === Actions.statusEnum.SUCCESS) {
            if (classSearchConnection.meta.mapKey === undefined) {
                setList({
                    ...list,
                    classList: classSearchConnection.payload.result.items,
                    classListItemCount: classSearchConnection.payload.result.item_count,
                });
            } else {
                const { classListMap, classListMapItemCount } = list;
                const newClassListMap = Object.assign(classListMap, {
                    [classSearchConnection.meta.mapKey]: classSearchConnection.payload.result.items,
                });
                const newClassListMapItemCount = Object.assign(classListMapItemCount, {
                    [classSearchConnection.meta.mapKey]: classSearchConnection.payload.result.item_count,
                });
                const newClassListMapListCount = Object.keys(newClassListMap).length;

                setList({
                    ...list,
                    classListMap: newClassListMap,
                    classListMapItemCount: newClassListMapItemCount,
                    classListMapListCount: newClassListMapListCount,
                });
            }
        }
        if (classSearchConnection.meta.status === Actions.statusEnum.FAILURE || classSearchConnection.meta.status === Actions.statusEnum.ERROR) {
            //OPTIMIZE 基本的には汎用エラーコンポーネントがエラーをスポイルしてここでは何も起こらないのが正なので、処理しない
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSearchConnection]);

    useEffect(() => {
        if (gradeSearchConnection.meta.status === Actions.statusEnum.SUCCESS) {
            setList((preList) => ({
                ...preList,
                gradeList: gradeSearchConnection.payload.result.items,
                gradeListItemCount: gradeSearchConnection.payload.result.item_count,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gradeSearchConnection]);

    return null;
};

export default ClassEffectComponent;

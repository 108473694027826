import PropTypes from 'prop-types';
import classNames from 'classnames';
import Ruby from '../Ruby'

export const LabelImportance = props => {
    const {
        children = null,
        className = '',
        textColor = 'text-white',
        isForcedAttachRuby = false,
        type = 'pills',
    } = props;


    switch (type) {
        case 'circle':
            return (
                <div className={classNames('w-16 h-16 mr-6 ml-3 text-sm flex flex-col items-center justify-center rounded-full',
                    className,
                    textColor,
                )}>
                    <Ruby isForcedAttach={isForcedAttachRuby}>{children}</Ruby>
                </div>
            );
        case 'none':
            return <></>;
        case 'pills':
            return (
                <div className={classNames('items-center w-auto px-4 py-1 whitespace-no-wrap rounded-full text-xs inline justify-center',
                    className,
                    textColor,
                )}>
                    <Ruby isForcedAttach={isForcedAttachRuby}>{children}</Ruby>
                </div>
            );
        default:
            return (
                <div className={classNames('items-center w-auto px-4 py-1 whitespace-no-wrap rounded-full text-xs flex h-8',
                    className,
                    textColor,
                )}>
                    <Ruby>{children}</Ruby>
                </div>
            );
    };
};

LabelImportance.propTypes = {
    type: PropTypes.string,
};

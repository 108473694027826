
const IpRestrictions = () => {
    return (
        <div className='loading-wrapper'>
            <p>
                このネットワークからのアクセスは許可されていません。
            </p>
        </div>
    );
};

export default IpRestrictions;
import { useEffect } from 'react';
import QuestionItemCard from './QuestionItemCard';
import CandidateOption from './CandidateOption';
import {useFormContext} from 'react-hook-form';
import Ruby from 'components/modules/Student/Ruby';


const MultipleSelection = props => {
    const {
        candidates,
        content,
        detail,
        file,
        isRequired,
        name,
    } = props;
    const {errors, register, unregister, watch} = useFormContext();

    useEffect(() => {
        const validate = {};
        if(isRequired) {
            validate.required = true;
        }
        register(name, validate);
        return () => unregister(name);
    }, [register, unregister, name, isRequired]);

    let errorMessage = '';
    if(errors[name]?.message !== undefined) {
        switch (errors[name].type) {
            case 'required':
                errorMessage = <div><Ruby>{`${content} を{選択|せんたく}してください。`}</Ruby></div>
                break;

            default:
                errorMessage = <div>{errors[name].message}</div>
                break;
        }
    }

    return (
        <QuestionItemCard
            title={content}
            detail={detail}
            error={errorMessage}
            file={file}
            isRequired={isRequired}
            type={<Ruby>{'{複数回答|ふくすうかいとう}'}</Ruby>}
            watch={watch}
        >
            <div className='grid grid-cols-2 gap-4'>
                {candidates.sort(byOrder).map((candidate, i) => {
                    return (
                        <CandidateOption
                            key={i}
                            type='checkbox'
                            name={name}
                            text={candidate.content}
                            value={candidate.uuid ?? `${candidate.order}`}
                            ref={register}
                            watch={watch}
                        />
                    )
                })}
            </div>
        </QuestionItemCard>
    );
};

const byOrder = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
}

export default MultipleSelection;

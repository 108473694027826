const convertBooleanToInt = (boolean: boolean | undefined) => {
    if (boolean === undefined) return undefined;
    return boolean ? 1 : 0;
};

/**
 * 検索条件をリクエスト可能な形式に変換する
 */
export const convertSubmittableSearchConditions = (
    values?: EverydayNote.SearchConditions
): Http.Connection.Request.Parameter.EverydayNote.Search => {
    if (values === undefined) return {};

    return 'publishFrom' in values ?
        {
            name: values.name,
            publish_from: values.publishFrom,
            is_required: convertBooleanToInt(values.isRequired),
            is_viewed_home: convertBooleanToInt(values.isViewedHome),
            is_once_a_day: convertBooleanToInt(values.isOnceADay),
            is_locked: convertBooleanToInt(values.isLocked),
            publish_start_at: values.publishStartAt?.unix(),
            publish_end_at: values.publishEndAt?.unix(),
            page: values.page,
            page_size: values.pageSize,
        } :
        {
            name: values.name,
            page: values.page,
            page_size: values.pageSize,
        };
};
import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'DATA';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

const failureAction = createAction<
    Failure | Http.Connection.Response.Failure,
    { isShow: boolean; loading: boolean },
    Failure | Http.Connection.Response.Failure,
    boolean,
    boolean
>(
    createActionType('FAILURE' as const),
    (data) => data,
    (data, isShow, loading = false) => ({ isShow, loading })
);
const failureWrapper = (data: Failure | Http.Connection.Response.Failure, isShow: boolean, loading?: boolean) => failureAction(data, isShow, loading ?? false);
failureWrapper.toString = failureAction.toString;
export const failure = failureWrapper;

export const isControllableUser = createAction(
    createActionType('IS_CONTROLLABLE_USER' as const),
    (data: boolean) => data
);

export const jobCallback = createAction(
    createActionType('JOB_CALLBACK' as const),
    // TODO: もしかしたら正しくないかもしれない. 要確認.
    (success: () => void, failure: () => void) => ({
        success,
        failure,
    }));

export const jobData = createAction(
    createActionType('JOB_DATA' as const),
    (data) => data,
    (data, bool, gmt) => ({ jobWaiting: bool, gmt: gmt })
);

export const jobDataSync = createAction(
    createActionType('JOB_DATA_SYNC' as const),
    (data) => data,
    (data, bool) => ({ jobWaiting: bool })
);

export module jobInfoMap {
    /***
     * jobInfoを追加する
     * @param jobResult jobViewのresponseのresult
     * @param gmt   jobViewのresponseのgmt
     * @param isError  jobViewのresponseの!isSuccessful
     * @param someUuid なにかのuuid (例: アンケート回答結果をダウンロードする際、同一のものはダウンロードさせたくないが、他のアンケートはダウンロードさせたいなどのユースケースにおいて、同じjob.typeでも違うものと区別するためなどに利用する)
     * @param showClose 閉じるボタンの表示非表示
     */
    export const add = createAction(
        createActionType('JOB_INFO_MAP/ADD' as const),
        ({ jobResult, gmt, isError = false, someUuid, showClose }) => ({
            jobResult, gmt, isError, someUuid, showClose,
        }),
        () => ({})
    );

    /** jobInfoMapをセットする */
    export const setMap = createAction(
        createActionType('JOB_INFO_MAP/SET_MAP' as const),
        (jobInfoMapData) => (jobInfoMapData),
        () => ({})
    );

    /** 指定したuuidのjobInfoの削除  */
    export const remove = createAction(
        createActionType('JOB_INFO_MAP/REMOVE' as const),
        (uuid) => ({ uuid }),
        () => ({})
    );

    /** 指定したuuidのjobInfoに対して、状態を追加する。
     * 例: status: {loading: true } ジョブメニュー上で、次のジョブに移動する前に、二回クリックされないように対策
     * 例: status: {showClose: true} バックエンドでjobの完了が見込めない同期中のjobに対して、ジョブメニュー上で「閉じる」ボタンを表示させる
     */
    export const setStatus = createAction(
        createActionType('JOB_INFO_MAP/SET_STATUS' as const),
        (uuid, status) => ({ uuid, status }),
        () => ({})
    );
}

export const session = createAction(
    createActionType('SESSION' as const),
    (is_authenticated: boolean, is_timeout = false, is_ip_restricted = false) => ({
        is_authenticated,
        is_checked: true,
        is_ip_restricted,
        is_required_session: process.env.REACT_APP_HAVE_SESSION === 'true',
        is_timeout,
    }));

export const success = createAction(
    createActionType('SUCCESS' as const),
    (data) => data,
    (data, bool) => ({ isShow: bool })
);

export const tenant = createAction(
    createActionType('TENANT' as const),
    (data: Tenant) => data
);

export const user = createAction(
    createActionType('USER' as const),
    (data: User | null) => data
);

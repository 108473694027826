import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import {
    Button,
    Input,
    Row,
    Col,
    Radio,
    Checkbox,
    Tooltip,
} from 'antd';
import {
    NotificationOutlined,
    TeamOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    formRules,
    getDisabledDate,
    getMoment,
    getDefaultFeatures,
    DatePickerRangesIncludeInfinite
} from 'constants/GlobalConfig';
import { texts } from 'constants/texts'
import * as Actions from 'flex/Actions';
import { statusEnum } from 'flex/Actions';
import { allowedExtensions, FileUploader } from 'components/modules/FileUploader';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import RichTextEditor from 'components/modules/RichTextEditor';
import { PublishedItemAlert } from 'components/modules/Announcement/PublishedItemAlert';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';
import Targets from '../Targets';
import NotificationPreviewModal from './NotificationPreviewModal';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;

const moment = getMoment();

const labelSpanXL = 7;
const labelSpanXXL = 6;

const layout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: labelSpanXL },
        xxl: { span: labelSpanXXL },
    },
    wrapperCol: {
        span: 24,
    },
};

const LayoutWithoutLabel = {
    xl: { offset: labelSpanXL },
    xxl: { offset: labelSpanXXL },
};

const initFieldValue = {
    sender: '',
    title: '',
    duration: [
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        undefined,
    ],
    content: '',
    files: [],
    priority: 1,
    push: 2,
    target: {
        target_users: [],
        target_roles: [],
        target_school_classes: [],
        target_organizations: []
    },
    publish_to_all_organizations: true,
    target_permissions: ['administrator', 'teacher'],
    can_admin_set_public: true
};


const EditNotification = ({
    onCancel,
    onOk,
    record,
    shouldCreate,
    visible,
}) => {
    const dispatch = useDispatch();
    const isControllableUser = useSelector(state => state.isControllableUser);
    const [form] = Form.useForm();
    const [isSelectTargetOpen, setIsSelectTargetOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewFormData, setPreviewFormData] = useState(null);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPublishToAllOrganizations, setIsPublishToAllOrganizations] = useState(true);
    const [editableForCanAdminSetPublic, setEditableForCanAdminSetPublic] = useState(false);
    const [disabledForPublishedItem, setDisabledForPublishedItem] = useState(false);
    const target = Form.useWatch('target', form);
    const notificationEditConnection = useSelector(state => state.notificationEditConnection);
    const notificationViewConnection = useSelector(state => state.notificationViewConnection);
    const tenant = useSelector(state => state.tenant);

    const isFirstRender = useRef(false)

    const [detailLoading, setDetailLoading] = useState(false);

    const [, setFeatures] = useState(getDefaultFeatures());

    // Targets が各 key がないとエラーになるので, つけておく
    const currentTarget = {
        target_users: [],
        target_school_classes: [],
        target_roles: [],
        target_organizations: [],
        ...target,
    };

    useEffect(() => {
        if (tenant.payload?.features) {
            setFeatures(tenant.payload.features);
        }
    }, [tenant])


    useEffect(() => {
        isFirstRender.current = true;
    }, [])


    useEffect(() => {
        if (!visible) {// escで閉じた場合プレビューのisOpenがfalseにならないためfalseにしてあげる
            setIsPreviewOpen(false);
            return
        };
        setDisabledForPublishedItem(false);
        setEditableForCanAdminSetPublic(false);
        setIsPublishToAllOrganizations(initFieldValue.publish_to_all_organizations);

        if (record === null) {
            form.resetFields();
            setContent('');
            form.setFieldsValue(initFieldValue);
        } else {
            if (isControllableUser.payload) {
                dispatch(Actions.http.connection.notification.controlView(record.uuid));
            } else {
                dispatch(Actions.http.connection.notification.view(record.uuid));
            }
        }
    }, [record, visible]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!isFirstRender.current) {
            if (notificationViewConnection.meta.status === Actions.statusEnum.SUCCESS) {
                const result = notificationViewConnection.payload.result;
                form.setFieldsValue(convertEditableRecord(result, shouldCreate));
                setContent(result.content)
                if (isControllableUser.payload) {
                    setIsPublishToAllOrganizations(result.publish_to_all_organizations);
                } else {
                    setEditableForCanAdminSetPublic(result && result.can_admin_set_public);
                    setDisabledForPublishedItem(result && result.is_published_item);
                }
            }
            setDetailLoading(notificationViewConnection.meta.fetch);
        }
    }, [notificationViewConnection]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setLoading(notificationEditConnection.meta.fetch);
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            if (notificationEditConnection.meta.status === statusEnum.SUCCESS) {
                //作成完了
                onOk();
            }
        }
    }, [notificationEditConnection]);// eslint-disable-line react-hooks/exhaustive-deps


    function handleSelectTargetSubmit(values) {
        setIsSelectTargetOpen(false);
        form.setFieldsValue({
            target: {
                target_users: values.target_users,
                target_school_classes: values.target_school_classes,
                target_roles: values.target_roles,
                target_organizations: values.target_organizations,
            }
        });
    }

    const handleSelectTargetCancel = () => setIsSelectTargetOpen(false);

    const onFinish = (values) => {
        //最速ローディング
        setLoading(true);
        const postBody = convertSubmittableValues(values);
        if (shouldCreate) {
            //新規作成(コピー含む)時
            if (isControllableUser.payload) {
                dispatch(Actions.http.connection.notification.controlCreate(postBody));
            } else {
                dispatch(Actions.http.connection.notification.create(postBody));
            }
        } else {
            //編集時ccc
            if (isControllableUser.payload) {
                dispatch(Actions.http.connection.notification.controlUpdate(record.uuid, postBody));
            } else {
                dispatch(Actions.http.connection.notification.update(record.uuid, postBody));
            }
        }
    }

    const handleModelChange = (value) => {
        setContent(value);
        form.setFieldsValue({
            content: value
        });
    };

    const handlePreviewOpen = () => {
        setPreviewFormData({ ...form.getFieldsValue(), content });
        setIsPreviewOpen(true);
    }

    const handlePreviewCancel = () => {
        setPreviewFormData(null);
        setIsPreviewOpen(false);
    }

    const handleSubmit = () => form.submit();


    // 学校管理者: 対象クラス選択&対象ユーザ選択, 全体管理者:対象学校選択のみ
    const targetShowTabProps = {
        showClass: !isControllableUser.payload,
        showUser: !isControllableUser.payload,
        showOrganization: isControllableUser.payload,
        showRole: !isControllableUser.payload
    }

    return (
        <BaseModal
            className='common-modal'
            style={{ top: 20, width: '97vw !important' }}
            title={<ModalTitle record={record} shouldCreate={shouldCreate} />}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key='preview' size='large' loading={loading} onClick={handlePreviewOpen}>プレビュー</Button>,
                <Button key='back' size='large' loading={loading} onClick={onCancel}>キャンセル</Button>,
                <Button key='create' type='primary' size='large' loading={loading} disabled={disabledForPublishedItem && !editableForCanAdminSetPublic}
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
            forceRender
        >
            <Spin spinning={detailLoading}>
                <PublishedItemAlert disabledForPublishedItem={disabledForPublishedItem} editableForCanAdminSetPublic={editableForCanAdminSetPublic} />

                <Form {...layout} form={form} name='control-hooks-edit-notifications' onFinish={onFinish}>
                    <Form.Item name='title' label='タイトル'
                        rules={[
                            formRules.required({ label: 'タイトル' }),
                            formRules.range({ label: 'タイトル', max: 128 })
                        ]}
                    >
                        <Input placeholder='タイトルを入力してください' disabled={disabledForPublishedItem} />
                    </Form.Item>

                    <Form.Item name='publish_from' label='発信元'
                        rules={[
                            formRules.required({ label: '発信元' }),
                            formRules.range({ label: '発信元', max: 64 })
                        ]}
                    >
                        <Input placeholder='発信元を入力してください' disabled={disabledForPublishedItem} />
                    </Form.Item>

                    <Form.Item name='duration' label='公開期間'
                        rules={[
                            formRules.requiredDuration({ label: '公開期間' })
                        ]}
                    >
                        <CustomRangePicker
                            form={form}
                            name='duration'
                            locale={jaJP}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY/MM/DD HH:mm'
                            disabledDate={getDisabledDate}
                            ranges={DatePickerRangesIncludeInfinite()}
                            disabled={disabledForPublishedItem && !editableForCanAdminSetPublic}
                        />
                    </Form.Item>

                    <Form.Item name='content' label='内容'
                        rules={[
                            formRules.required({ label: '内容' }),
                        ]}
                    >
                        <RichTextEditor
                            tag='textarea'
                            config={{
                                charCounterMax: 10000,
                                placeholderText: '内容を入力してください',
                            }}
                            model={content}
                            onModelChange={handleModelChange}
                            disabled={disabledForPublishedItem}
                        />
                    </Form.Item>

                    <Form.Item name='files' label='ファイル'>
                        <FileUploader
                            disabled={disabledForPublishedItem}
                            allowedExtension={allowedExtensions.image_movie_zip_doc}
                            canUploadMultiFiles={true}
                        />
                    </Form.Item>

                    <Form.Item name='priority' label={<span>優先順位</span>}>
                        <Radio.Group disabled={disabledForPublishedItem}>
                            <Radio value={1}>通常</Radio>
                            <Radio value={2}>大切なお知らせ</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {isControllableUser.payload && (
                        <Form.Item name='publish_to_all_organizations' label='対象学校'>
                            <Radio.Group onChange={e => { setIsPublishToAllOrganizations(e.target.value); }} value={isPublishToAllOrganizations}>
                                <Radio value={true}>すべて</Radio>
                                <Radio value={false}>個別選択</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    <Form.Item name='target' value={currentTarget} hidden><Input /></Form.Item>

                    {(!isPublishToAllOrganizations || !isControllableUser.payload) && (
                        <Form.Item
                            wrapperCol={isControllableUser.payload && LayoutWithoutLabel}
                            label={!isControllableUser.payload && (<span>配信対象者</span>)}
                        >
                            <Button type='primary' icon={<TeamOutlined />} onClick={() => setIsSelectTargetOpen(true)} disabled={disabledForPublishedItem}>
                                配信対象を選択
                            </Button>
                            <Targets.Detail currentTarget={currentTarget} isPublishedItem={disabledForPublishedItem} />
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <>
                            <Form.Item name={'target_permissions'} label={texts.targetPermissionsTitle} rules={[{ required: true, message: `${texts.targetPermissionsTitle}を選択してください` }]}>
                                <Checkbox.Group style={{ width: '100%' }}>
                                    <Row>
                                        <Col sm={{ span: 7 }} md={{ span: 6 }} xl={{ span: 5 }}>
                                            <Checkbox value='administrator'>{texts.targetPermissionsCheckbox.administrator}</Checkbox>
                                        </Col>
                                        <Col sm={{ span: 7 }} md={{ span: 6 }} xl={{ span: 5 }}>
                                            <Checkbox value='teacher'>{texts.targetPermissionsCheckbox.teacher}</Checkbox>
                                        </Col>
                                        <Col sm={{ span: 7 }} md={{ span: 6 }} xl={{ span: 5 }}>
                                            <Checkbox value='student'>{texts.targetPermissionsCheckbox.student}</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        学校管理画面での公開期間変更
                                        <Tooltip title='配信先の学校で、学校管理者が公開期間を任意に変更できるかどうかを設定できます'>
                                            <QuestionCircleOutlined className='icon-l' />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Form.Item
                                    name='can_admin_set_public'
                                    style={{ display: 'inline-block', marginRight: '1rem' }}
                                    rules={[
                                        { required: true, message: '学校管理者での公開を選択してください' }
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={false}>許可しない</Radio>
                                        <Radio value={true}>許可する</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Form.Item>
                        </>
                    )}

                </Form>
            </Spin>
            <Targets
                {...targetShowTabProps}
                visible={isSelectTargetOpen}
                currentTarget={currentTarget}
                onCancel={handleSelectTargetCancel}
                onSubmit={handleSelectTargetSubmit}
            />
            <NotificationPreviewModal
                isOpen={isPreviewOpen}
                closeModal={handlePreviewCancel}
                data={previewFormData}
            />
        </BaseModal>
    )
}

const ModalTitle = (props) => (
    props.shouldCreate
        ? props.record
            ? <span><NotificationOutlined /> お知らせをコピーして作成</span>
            : <span><NotificationOutlined /> お知らせの新規作成</span>
        : <span><NotificationOutlined /> お知らせの編集</span>
)

/***
 * targetを編集可能な形式に変更する
 * @param record
 */
const convertEditableTarget = (record) => ({
    target_school_classes: record.target_school_classes,
    target_organizations: record.target_organizations,
    target_users: record.target_users,
    target_roles: record.target_roles,
});

/***
 * recordを編集可能な形式に変更する
 * @param record
 */
const convertEditableRecord = (record, shouldCreate) => ({
    ...record,
    title: shouldCreate ? `${record.title}のコピー` : record.title,
    is_send_notification: record.is_send_notification ? 1 : 2,
    duration: [moment.unix(record.publish_start_at), moment.unix(record.publish_end_at)],
    target: convertEditableTarget(record),
    target_permissions: record.target_permissions && record.target_permissions.map(v => v?.code)
});

/***
 * submit可能な形式にonFinishのvalueを変換する
 */
const convertSubmittableValues = (values) => {
    const postBody = {
        ...values,
        publish_department: '',
        publish_start_at: values.duration[0].unix(),
        publish_end_at: values.duration[1].unix(),
        is_read_receipt: false, //todo パラメータ要素についての確認
        is_send_notification: false, //todo パラメータ要素についての確認
        is_active: true, //有効・無効フラグについては常にtrueでヨシッ
        memo: '', //メモは存在しないため一旦空
        files: values.files.map((value) => value.uuid),
        target_users: values.target.target_users && values.target.target_users.map((value) => {
            return value.uuid
        }),
        target_school_classes: values.target.target_school_classes && values.target.target_school_classes.map((value) => {
            return value.uuid
        }),
        target_roles: values.target.target_roles && values.target.target_roles.map((value) => {
            return value.uuid
        }),
        target_organizations: values.target.target_organizations && values.target.target_organizations.map((value) => {
            return value.uuid
        }),
    };
    delete postBody.target;
    delete postBody.duration;

    return postBody;
};

export default EditNotification;

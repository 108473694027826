import React from 'react';

import {
    Bar,
    BarChart,
    CartesianGrid,
    LabelProps,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

import { DistributionGraphState } from '../../hooks/useDistributionGraph';


const gridColor = '#E5E5E5';
const axisColor = '#A6A6A6';
const barColor = '#16C2C2';
const bar2Color = '#FDC63A';
const averageColor = '#004C97';

type Props = {
    height: number
    distributeGraph: DistributionGraphState
    distributeGraph2?: DistributionGraphState
}

export const ScoreDistributionGraph: React.VFC<Props> = ({
    distributeGraph,
    distributeGraph2,
    height,
}) => {
    const isSingleView = distributeGraph2 === undefined;
    const isLoading = distributeGraph.isLoading || (!isSingleView && distributeGraph2.isLoading);

    const data = isLoading ?
        [] :
        isSingleView ?
            distributeGraph.result :
            distributeGraph.result.map((e, i) => {

                if (distributeGraph2.result[i] === undefined) {
                    return {
                        count: 0,
                        count1: 0,
                        count2: 0,
                        name: e.name,
                    };
                }

                return {
                    // max を求めるためのデータ
                    count: e.count > distributeGraph2.result[i].count ? e.count : distributeGraph2.result[i].count,
                    count1: e.count,
                    count2: distributeGraph2.result[i].count,
                    name: e.name,
                };
            });

    const max = data.reduce((acc, cur) => Math.max(acc, cur.count), 0);

    const aveX = data
        .map(e => e.name.split('-'))
        .map(([s, e]) => [Number(s), Number(e)])
        .filter(([s, e]) => s <= distributeGraph.averageScore && distributeGraph.averageScore <= e)
        .at(0)
        ?.join('-') ?? '';

    return (
        <div style={{ height: `${height}px` }}>
            <ResponsiveContainer>
                <BarChart
                    barCategoryGap={10}
                    data={data}
                    // eslint-disable-next-line sort-keys
                    margin={{ top: 30, right: 5, bottom: 5, left: 5 }}
                >
                    <CartesianGrid stroke={gridColor} vertical={false} />
                    <XAxis
                        axisLine={false}
                        dataKey='name'
                        stroke={axisColor}
                        tickLine={false}
                    />
                    <YAxis
                        axisLine={false}
                        domain={[0, Math.floor(max * 1.2)]}
                        stroke={axisColor}
                        tickLine={false}
                    />

                    {isSingleView ?
                        (
                            <Bar dataKey='count' fill={barColor} name='点数分布' />
                        ) :
                        (
                            <>
                                <Bar dataKey='count1' fill={barColor} name='対象クラス1' />
                                <Bar dataKey='count2' fill={bar2Color} name='対象クラス2' />
                            </>
                        )
                    }

                    <Legend
                        formatter={renderBlackLegendText}
                        height={36}
                        iconType='circle'
                        verticalAlign='bottom'
                        wrapperStyle={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    />

                    {isSingleView && (
                        <ReferenceLine
                            label={renderReferenceLabel(distributeGraph.averageScore)}
                            stroke={averageColor}
                            strokeDasharray='5 5'
                            strokeWidth={4}
                            x={aveX}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

const renderReferenceLabel = (ave: number) => {
    const ReferenceLabel = ({
        viewBox,
    }: Pick<LabelProps, 'viewBox'>) => {
        const y = 20;

        return (
            <text
                {...viewBox}
                fill={averageColor}
                fontWeight='700'
                height={30}
                textAnchor='middle'
                width={100}
                y={y}
            >
                <tspan {...viewBox} y={y}>平均: {ave}</tspan>
            </text>
        );
    };
    return ReferenceLabel;
};

const renderBlackLegendText = (value: string) => {
    return <span style={{ color: 'black' }}>{value}</span>;
};

import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
    PlusOutlined,
    QuestionCircleOutlined,
    SolutionOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Select,
    Input,
    Tooltip,
    Radio,
    Row,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

import { PublishedItemAlert } from 'components/modules/Announcement/PublishedItemAlert';
import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import RichTextEditor from 'components/modules/RichTextEditor';
import Spin from 'components/modules/Spin';
import Targets from 'components/presentational/pages/Targets';
import {
    DatePickerRangesIncludeInfinite,
    formRules,
    getDisabledDate,
    getMoment,
} from 'constants/GlobalConfig';
import { texts } from 'constants/texts';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { editQuestionnaireSelector } from 'flex/view/Questionnaire/EditQuestionnaire/selectors';

import EditRecursive from './EditRecursive';
import PreviewQuestionnaireModal from './PreviewQuestionnaireModal';

const Form = BaseForm.ModalForm;
const { statusEnum } = Actions;
const { Option } = Select;

const moment = getMoment();

const labelSpanXL = 7;
const labelSpanXXL = 6;
const layout = {
    labelCol: {
        /* eslint-disable sort-keys */
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: labelSpanXL },
        xxl: { span: labelSpanXXL },
        /* eslint-enable sort-keys */
    },
    wrapperCol: {
        span: 24,
    },
};

const LayoutWithoutLabel = {
    xl: { offset: labelSpanXL },
    xxl: { offset: labelSpanXXL },
};

const initFieldValue = {
    after_message: '',
    can_be_answered_only_once: false,
    category_uuid: '',
    content: '',
    durationPerform: [
        /* eslint-disable sort-keys */
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        /* eslint-enable sort-keys */
        undefined,
    ],
    durationPublish: [
        /* eslint-disable sort-keys */
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        /* eslint-enable sort-keys */
        undefined,
    ],
    durationResult: [
        /* eslint-disable sort-keys */
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        /* eslint-enable sort-keys */
        undefined,
    ],
    is_anonymous: true,
    is_forced: false,
    is_public: true,
    is_public_result: false,
    name: '',
    publish_end_at: null,
    publish_from: '',
    publish_start_at: null,
};

const adminInitFieldValue = {
    target: {
        target_roles: [],
        target_school_classes: [],
        target_users: [],
    },
};

const controlInitFieldValue = {
    can_admin_set_public: false,
    publish_to_all_organizations: true,
    target: {
        target_organizations: [],
    },
    target_permissions: ['administrator', 'teacher', 'student'],
};

const EditQuestionnaireModal = ({
    onCancel,
    onOk,
    shouldCreate = false,
    categoryUuid = '',
    isOpen = false,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const isPublic = Form.useWatch('is_public', form);
    const isPublicResult = Form.useWatch('is_public_result', form);
    const publishToAllOrganizations = Form.useWatch('publish_to_all_organizations', form);
    const target = Form.useWatch('target', form);
    const [previewQuestionnaire, setPreviewQuestionnaire] = useState({});
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const isControllableUser = useAppSelector(state => state.isControllableUser);
    const questionnaireEditConnection = useAppSelector(state => state.questionnaireEditConnection);
    const questionnaireViewConnection = useAppSelector(state => state.questionnaireViewConnection);
    const questionnaireCategorySearchConnection = useAppSelector(state => state.questionnaireCategorySearchConnection);

    const viewAction = isControllableUser.payload ?
        Actions.http.connection.questionnaires.controlView :
        Actions.http.connection.questionnaires.view;
    const searchAction = isControllableUser.payload ?
        Actions.http.connection.questionnaireCategory.controlSearch :
        Actions.http.connection.questionnaireCategory.search;

    const {
        questionnaire,
        editableItems,
        isTargetOpen,
        questionnaireCategoryList,
    } = useAppSelector(state => state.editQuestionnaire);

    const disabledForcedAnswer = useAppSelector(editQuestionnaireSelector.disabledForcedAnswer);

    const {
        disabledEditButtons,
        disabledAnonymous,
    } = useAppSelector(editQuestionnaireSelector.disabledItems);

    // Targets が各 key がないとエラーになるので, つけておく
    const currentTarget = {
        target_organizations: [],
        target_roles: [],
        target_school_classes: [],
        target_users: [],
        ...target,
    };

    const isFirstRender = useRef(false);

    const isDisabled = (() => {
        if (isControllableUser.payload) return false;
        if (shouldCreate) return false;
        if (!questionnaire) return true;

        return !!questionnaire.is_published_item;
    })();

    const filteredQuestionnaireCategoryList = filteringQuestionnaireCategoryList(
        questionnaireCategoryList,
        isDisabled
    );

    /**
     * 公開期間が編集できるか否か
     *
     * 公開期間は全体配信の場合, `can_admin_set_public` が `true` であれば編集可能になる.
     *
     * 公開期間が編集可能な場合, 保存も可能
     * */
    const canEditPublic = !isDisabled || !!questionnaire?.can_admin_set_public;

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {// モーダルの内容の初期化系の処理
        if (!isOpen) {// escで閉じた場合プレビューのisOpenがfalseにならないためfalseにしてあげる
            setIsPreviewOpen(false);
            return;
        }
        form.resetFields();

        const formValue = isControllableUser.payload ?
            { ...initFieldValue, ...controlInitFieldValue } :
            { ...initFieldValue, ...adminInitFieldValue };

        form.setFieldsValue(formValue);
        setPreviewQuestionnaire({});

        // editQuestionnaireのstoreの初期化
        dispatch(Actions.view.viewLogic.editQuestionnaire.prepareView());

        // カテゴリ一覧取得
        dispatch(searchAction({ page_size: 500 }, 1, 'editQuestionnaire'));

        if (categoryUuid) {// categoryUuidがある時はカテゴリ取得
            dispatch(viewAction(categoryUuid, 'editQuestionnaire'));
        }

    }, [isOpen]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 初期値が存在する場合formに初期値をセットする処理
        if (!isOpen) return;
        if (questionnaire === null) return;
        if (questionnaireCategoryList.length === 0) return;

        // 作成済みのアンケート設問を利用できる形にコンバート
        dispatch(Actions.view.viewLogic.editQuestionnaire.prepareViewAfter());

        const value = {
            ...questionnaire,
            category_uuid: questionnaire.category.uuid,
            durationPerform: [
                moment.unix(questionnaire.perform_start_at),
                moment.unix(questionnaire.perform_end_at),
            ],
            durationPublish: [
                moment.unix(questionnaire.publish_start_at),
                moment.unix(questionnaire.publish_end_at),
            ],
            durationResult: [
                moment.unix(questionnaire.publish_result_start_at),
                moment.unix(questionnaire.publish_result_end_at),
            ],
            target: {
                target_organizations: questionnaire.target_organizations,
                target_roles: questionnaire.target_roles,
                target_school_classes: questionnaire.target_school_classes,
                target_users: questionnaire.target_users,
            },
        };
        form.setFieldsValue(value);
    }, [questionnaire, questionnaireCategoryList]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (isFirstRender.current) return;

        const { status: editState, fetch: editFetch } = questionnaireEditConnection.meta;
        const { status: viewState, fetch: viewFetch } = questionnaireViewConnection.meta;
        const { status: categoryState, fetch: categoryFetch } = questionnaireCategorySearchConnection.meta;

        if ([editState, viewState, categoryState].includes(statusEnum.SUCCESS)) {
            setIsLoading(editFetch || viewFetch || categoryFetch);
            setIsLoadingCategory(categoryFetch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaireEditConnection, questionnaireViewConnection, questionnaireCategorySearchConnection]);

    useEffect(() => {
        const { fetch } = questionnaireCategorySearchConnection.meta;

        setIsLoadingCategory(fetch);
    }, [questionnaireCategorySearchConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFirstRender.current) return;
        const { status } = questionnaireEditConnection.meta;

        if (status === statusEnum.SUCCESS) {
            onOk();
        }
    }, [questionnaireEditConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPreviewOpen) {
            setPreviewQuestionnaire(form.getFieldsValue());
        }
    }, [isPreviewOpen]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    const onCategoryChange = (value) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.selectCategory(value));
    };

    const handleIsPublicChange = (e) => {
        if (e.target.value) {
            form.resetFields(['durationPublish', 'durationPerform']);
        }
    };

    const handleIsPublicResultChange = (e) => {
        if (e.target.value) {
            form.setFieldsValue({ durationResult: initFieldValue.durationResult });
        }
    };

    const handleTargetSubmit = (values) => {
        form.setFieldsValue({ target: values });
        dispatch(Actions.view.viewLogic.editQuestionnaire.submitTarget());
    };

    const handleTargetOpen = () => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.openTarget());
    };


    const handleTargetCancel = () => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.cancelTarget());
    };

    const handleAddQuestion = (parentTmpItemId, parentTmpCandidateId) => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.openQuestion(parentTmpItemId, parentTmpCandidateId));
    };

    const handlePreviewOpen = () => setIsPreviewOpen(true);

    const handlePreviewCancel = () => setIsPreviewOpen(false);

    const handleSubmit = () => form.submit();

    const handleCancel = () => {
        dispatch(Actions.view.viewLogic.editQuestionnaire.cancelEdit());
        onCancel();
    };

    const onFinish = (values) => {
        if (editableItems.length === 0) {
            form.setFields([
                {
                    errors: ['設問は少なくとも1つ以上追加してください'],
                    name: 'items',
                },
            ]);
        } else {
            disabledForcedAnswer && delete values.is_forced;
            dispatch(Actions.view.viewLogic.editQuestionnaire.submitEdit(values));
        }
    };

    // 学校管理者: 対象クラス選択&対象ユーザ選択, 全体管理者:対象学校選択のみ
    const targetShowTabProps = {
        showClass: !isControllableUser.payload,
        showOrganization: isControllableUser.payload,
        showRole: !isControllableUser.payload,
        showUser: !isControllableUser.payload,
    };

    return (
        <BaseModal
            className='common-modal'
            footer={[
                <Button
                    disabled={isLoading}
                    key='back'
                    loading={isLoading}
                    onClick={handleCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    disabled={isLoading}
                    key='preview'
                    loading={isLoading}
                    onClick={handlePreviewOpen}
                    size='large'
                >
                    プレビュー
                </Button>,
                <Button
                    disabled={isLoading || !canEditPublic}
                    key='create'
                    loading={isLoading}
                    onClick={handleSubmit}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            onCancel={handleCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={
                <span>
                    <SolutionOutlined />
                    {shouldCreate
                        ? categoryUuid
                            ? ' アンケートをコピーして作成'
                            : ' アンケートの新規作成'
                        : ' アンケートの編集'
                    }
                </span>
            }
            visible={isOpen}
        >
            <Spin spinning={isLoading || isLoadingCategory} tip='しばらくお待ち下さい...'>
                <PublishedItemAlert
                    disabledForPublishedItem={!!questionnaire?.is_published_item}
                    editableForCanAdminSetPublic={!!questionnaire?.can_admin_set_public}
                />
                <Form {...layout} form={form} name='control-hooks-questionnaire' onFinish={onFinish}>
                    <Form.Item
                        label={
                            <span>
                                アンケートカテゴリ&nbsp;&nbsp;
                                <Tooltip title='登録済みのアンケートカテゴリから選択できます。未登録の場合は「アンケートカテゴリ一覧」タブからカテゴリを新規作成してください。'>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </span>
                        }
                        name='category_uuid'
                        rules={[{ message: '配信カテゴリを選択してください', required: true }]}
                    >
                        <Select
                            allowClear
                            disabled={isDisabled || isLoadingCategory}
                            loading={isLoadingCategory}
                            onChange={onCategoryChange}
                            placeholder='カテゴリを選択してください'
                        >
                            {filteredQuestionnaireCategoryList.map((v) =>
                                (<Option key={v.uuid} value={v.uuid}>{v.name}</Option>)
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='タイトル'
                        name='name'
                        rules={[
                            formRules.required({ label: 'タイトル' }),
                            formRules.range({ max: 128 }),
                        ]}
                    >
                        <Input
                            disabled={isDisabled}
                            placeholder='タイトルを入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                発信元&nbsp;&nbsp;
                                <Tooltip title='発信元を入力してください。発信元はアンケート回答画面に表示されます。'>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </span>
                        }
                        name='publish_from'
                        rules={[
                            formRules.required({ label: '発信元' }),
                            formRules.range({ max: 64 }),
                        ]}
                    >
                        <Input
                            disabled={isDisabled}
                            placeholder='発信元を入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        label='アンケート説明文'
                        name='content'
                        rules={[
                            formRules.required({ label: 'アンケート説明文' }),
                        ]}
                        trigger='onModelChange'
                        valuePropName='model'
                    >
                        <RichTextEditor
                            config={{
                                charCounterMax: 10000,
                                placeholderText: 'アンケート説明文を入力してください',
                            }}
                            disabled={isDisabled}
                            tag='textarea'
                        />
                    </Form.Item>

                    <Form.Item
                        label='アンケート回答後メッセージ'
                        name='after_message'
                        rules={[formRules.required({ label: 'アンケート回答後メッセージ' })]}
                        trigger='onModelChange'
                        valuePropName='model'
                    >
                        <RichTextEditor
                            config={{
                                charCounterMax: 10000,
                                placeholderText: 'アンケート回答後メッセージを入力してください',
                            }}
                            disabled={isDisabled}
                            tag='textarea'
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <>
                                <span style={{ marginRight: '0.5rem' }}>アンケート種別</span>
                                {disabledAnonymous && (
                                    <Tooltip title={disabledEditButtons ? '回答済みのため変更できません' : ''}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                )}
                            </>
                        }
                        name='is_anonymous'
                    >
                        <Radio.Group
                            disabled={isDisabled || disabledAnonymous}
                            initialValues={true}
                        >
                            <Radio value={false}>記名式</Radio>
                            <Radio value={true}>無記名式</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        extra='「不可」の場合は回答後の変更ができなくなります'
                        label='回答の再編集'
                        name='can_be_answered_only_once'
                    >
                        <Radio.Group
                            disabled={isDisabled}
                            initialValues={true}
                        >
                            <Radio value={true}>不可</Radio>
                            <Radio value={false}>許可</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        extra={
                            '「非公開」を選択した場合、' +
                            '「公開期間」「実施期間」「結果公開」「結果公開期間」設定にかかわらず、' +
                            '利用者にアンケートは表示されません'
                        }
                        label='アンケート公開設定'
                        name='is_public'
                        rules={[formRules.required({ label: 'アンケート公開設定' })]}
                    >
                        <Radio.Group
                            disabled={isDisabled}
                            onChange={handleIsPublicChange}
                        >
                            <Radio value={true}>公開</Radio>
                            <Radio value={false}>非公開</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {isPublic && (
                        <>
                            <Form.Item
                                label='アンケート公開期間'
                                name='durationPublish'
                                rules={[formRules.requiredDuration({ label: 'アンケート公開期間' })]}
                            >
                                <CustomRangePicker
                                    disabled={!canEditPublic}
                                    disabledDate={getDisabledDate}
                                    form={form}
                                    format='YYYY/MM/DD HH:mm'
                                    locale={jaJP}
                                    name='durationPublish'
                                    ranges={DatePickerRangesIncludeInfinite()}
                                    showTime={{ format: 'HH:mm' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label='アンケート実施期間'
                                name='durationPerform'
                                rules={[formRules.requiredDuration({ label: 'アンケート実施期間' })]}
                            >
                                <CustomRangePicker
                                    disabled={!canEditPublic}
                                    disabledDate={getDisabledDate}
                                    form={form}
                                    format='YYYY/MM/DD HH:mm'
                                    locale={jaJP}
                                    name='durationPerform'
                                    ranges={DatePickerRangesIncludeInfinite()}
                                    showTime={{ format: 'HH:mm' }}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        extra='「非公開」を選択した場合、「結果公開期間」設定にかかわらず、利用者にアンケートの結果は公開されません'
                        label='アンケート結果公開設定'
                        name='is_public_result'
                        rules={[formRules.required({ label: 'アンケート結果公開設定' })]}
                    >
                        <Radio.Group
                            disabled={isDisabled}
                            onChange={handleIsPublicResultChange}
                        >
                            <Radio value={true}>公開</Radio>
                            <Radio value={false}>非公開</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {isPublicResult && (
                        <Form.Item
                            label='アンケート結果公開期間'
                            name='durationResult'
                            rules={[formRules.requiredDuration({ label: 'アンケート結果公開期間' })]}
                        >
                            <CustomRangePicker
                                disabled={isDisabled}
                                disabledDate={getDisabledDate}
                                form={form}
                                format='YYYY/MM/DD HH:mm'
                                locale={jaJP}
                                name='durationResult'
                                ranges={DatePickerRangesIncludeInfinite()}
                                showTime={{ format: 'HH:mm' }}
                            />
                        </Form.Item>
                    )}

                    {isControllableUser.payload && (
                        <>
                            <Form.Item label='対象学校' name='publish_to_all_organizations'>
                                <Radio.Group
                                    disabled={isDisabled}
                                >
                                    <Radio value={true}>すべて</Radio>
                                    <Radio value={false}>個別選択</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {!publishToAllOrganizations && (
                                <Form.Item wrapperCol={LayoutWithoutLabel}>
                                    <Button
                                        disabled={isDisabled}
                                        icon={<TeamOutlined />}
                                        onClick={handleTargetOpen}
                                        type='primary'
                                    >
                                        配信対象を選択
                                    </Button>

                                    <Targets.Detail
                                        currentTarget={currentTarget}
                                        isPublishedItem={[]}
                                    />
                                </Form.Item>
                            )}
                        </>
                    )}

                    {isControllableUser.payload && (
                        <>
                            <Form.Item
                                label={texts.targetPermissionsTitle}
                                name='target_permissions'
                                rules={[formRules.required({ isSelect: true, label: texts.targetPermissionsTitle })]}
                            >
                                <Checkbox.Group
                                    disabled={isDisabled || disabledEditButtons}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        <Col md={{ span: 6 }} sm={{ span: 7 }} xl={{ span: 5 }}>
                                            <Checkbox value='administrator'>
                                                {texts.targetPermissionsCheckbox.administrator}
                                            </Checkbox>
                                        </Col>
                                        <Col md={{ span: 6 }} sm={{ span: 7 }} xl={{ span: 5 }}>
                                            <Checkbox value='teacher'>
                                                {texts.targetPermissionsCheckbox.teacher}
                                            </Checkbox>
                                        </Col>
                                        <Col md={{ span: 6 }} sm={{ span: 7 }} xl={{ span: 5 }}>
                                            <Checkbox value='student'>
                                                {texts.targetPermissionsCheckbox.student}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>

                            <Form.Item
                                label={
                                    <span>
                                        学校管理画面での公開期間変更
                                        <Tooltip title='配信先の学校で、学校管理者が公開期間を任意に変更できるかどうかを設定できます'>
                                            <QuestionCircleOutlined className='ml-2' />
                                        </Tooltip>
                                    </span>
                                }
                                name='can_admin_set_public'
                                rules={[formRules.required({ isSelect: true, label: '学校管理者での公開' })]}
                            >
                                <Radio.Group>
                                    <Radio value={false}>許可しない</Radio>
                                    <Radio value={true}>許可する</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </>
                    )}

                    {!isControllableUser.payload && (
                        <Form.Item label='配信対象者'>
                            <Button
                                disabled={isDisabled || disabledEditButtons}
                                icon={<TeamOutlined />}
                                onClick={handleTargetOpen}
                                type='primary'
                            >
                                配信対象を選択
                            </Button>
                            <Targets.Detail currentTarget={currentTarget} isPublishedItem={isDisabled} />
                        </Form.Item>
                    )}

                    <Form.Item
                        label=''
                        labelCol={0}
                        name='items'
                        rules={[{ message: '設問を追加してください', required: true }]}
                        wrapperCol={24}
                    >
                        <EditRecursive
                            disabled={isDisabled}
                            form={form}
                            key='EditRecursive'
                        />
                        <Tooltip
                            placement='bottom'
                            title={disabledEditButtons ? '回答済みのため設問の追加は行えません' : ''}
                        >
                            <Button
                                block
                                disabled={isDisabled || disabledEditButtons}
                                icon={<PlusOutlined />}
                                onClick={() => handleAddQuestion(0, 0)}
                            >
                                設問の追加
                            </Button>
                        </Tooltip>
                    </Form.Item>

                    <Form.Item hidden name='target'><Input /></Form.Item>
                </Form>

                <Targets
                    {...targetShowTabProps}
                    currentTarget={currentTarget}
                    onCancel={handleTargetCancel}
                    onSubmit={handleTargetSubmit}
                    visible={isTargetOpen}
                />
            </Spin>
            <PreviewQuestionnaireModal
                isOpen={isPreviewOpen}
                onCancel={handlePreviewCancel}
                previewQuestionnaire={previewQuestionnaire}
            />
        </BaseModal>
    );
};

export default EditQuestionnaireModal;

/**
 * 学校管理者からは全体管理者から配信されたアンケートカテゴリーで
 * アンケートを作成できないようにフィルタリングする
 */
const filteringQuestionnaireCategoryList = (questionnaireCategoryList, isDisabled) => {
    if (!questionnaireCategoryList) return [];

    return isDisabled ?
        questionnaireCategoryList :
        questionnaireCategoryList.filter(cat => !cat.is_published_item);
};
import { handleAction } from 'redux-actions';

import * as Actions from 'flex/Actions';

const defaultState = {
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
    payload: {},
};

const defaultReducer = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next: (state: any, action: any) => {
        return { ...action };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const adminEverydayNoteCreateConnection = handleAction(
    Actions.http.connection.everydayNote.admin.create,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteDeleteConnection = handleAction(
    Actions.http.connection.everydayNote.admin.delete,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteResultCsvConnection = handleAction(
    Actions.http.connection.everydayNote.admin.resultCsv,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteResultTotalConnection = handleAction(
    Actions.http.connection.everydayNote.admin.resultTotal,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteResultSubItemsConnection = handleAction(
    Actions.http.connection.everydayNote.admin.resultSubItems,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteResultSubTotalConnection = handleAction(
    Actions.http.connection.everydayNote.admin.resultSubTotal,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteSearchConnection = handleAction(
    Actions.http.connection.everydayNote.admin.search,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteUpdateConnection = handleAction(
    Actions.http.connection.everydayNote.admin.update,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteViewConnection = handleAction(
    Actions.http.connection.everydayNote.admin.view,
    defaultReducer,
    defaultState
);


export const adminEverydayNoteControlResultCsvConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlResultCsv,
    defaultReducer,
    defaultState
);


export const adminEverydayNoteControlResultTotalConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlResultTotal,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteControlResultSubItemsConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlResultSubItems,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteControlResultSubTotalConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlResultSubTotal,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteControlSearchConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlSearch,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteControlUpdateConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlUpdate,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteControlViewConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlView,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteControlTargetSchoolClassConnection = handleAction(
    Actions.http.connection.everydayNote.admin.controlTargetSchoolClass,
    defaultReducer,
    defaultState
);

export const adminEverydayNoteTargetSchoolClassConnection = handleAction(
    Actions.http.connection.everydayNote.admin.targetSchoolClass,
    defaultReducer,
    defaultState
);
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Radio,
    Row,
    Select,
    Tag,
} from 'antd';

import 'styles/modules/test_status_modal.scss';
import BaseForm from 'components/modules/BaseForm';
import {
    addAllTermAndSort,
    ALL_TERM_UUID,
    getCurrentTerm,
    getDefaultLayout,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import { examCategoryTabFlex } from 'flex/view/Assessment/examCategoryTabFlex';

const Form = BaseForm;
const layout = getDefaultLayout();
const { Option } = Select;

type DownloadCsvParameter = Http.Connection.Request.Parameter.ExamCategory.DownloadCsv;

type SearchValues = Expand<
    DownloadCsvParameter & {
        latest_result_only: boolean
    }
>;

type Props = {
    onFinish: (values: SearchValues) => void
    showList: boolean
    tabName: 'board' | 'school' | undefined
}

export const ExamCategoryStatusSearch: React.VFC<Props> = (props) => {
    const [form] = Form.useForm();
    const { termList } = useAppSelector(selectors.globalState);
    const [selectTerm, setSelectTerm] = useState<string | null>(getCurrentTerm(termList).uuid);
    const dispatch = useDispatch();
    const classListMap = useAppSelector(selectors.examCategoryTabClassListMap);
    const roleList = useAppSelector(selectors.examCategoryTabRoleList);
    const optionalLoading = useAppSelector(examCategoryTabFlex.selectors.statusListOptionalLoading);
    const listLoading = useAppSelector(examCategoryTabFlex.selectors.listLoading);
    const isControllableUser = useAppSelector(selectors.isControllableUser);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            latest_result_only: false,
            term_uuid: selectTerm,
        });

        dispatch(Actions.http.connection.classes.searchForListMap(
            { page_size: 500, term_uuid: selectTerm },
            1,
            selectTerm,
            // TODO: いつか消す
            'examCategoryTab'
        ));

        if (!isControllableUser && roleList?.length === 0) {
            dispatch(Actions.http.connection.role.search(
                { page_size: '50' },
                undefined,
                // TODO: いつか消す
                'examCategoryTab'
            ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!props.showList) return null;

    const onReset = () => {
        form.resetFields();
        const currentTerm = getCurrentTerm(termList).uuid;
        setSelectTerm(currentTerm);
        form.setFieldsValue({ term_uuid: currentTerm });
    };

    const handleSelectTerm = (term_uuid: string) => {
        setSelectTerm(term_uuid);
        form.setFieldsValue([{ name: 'term_uuid', value: term_uuid }]);
        form.resetFields(['school_class_uuid']);
        // すべての年度のとき or  取得済みの年度のクラスリストがあるとき はクラスを取得しない
        if (term_uuid === ALL_TERM_UUID || classListMap[term_uuid]) return;
        dispatch(Actions.http.connection.classes.searchForListMap({ page_size: 500, term_uuid: term_uuid }, undefined, term_uuid, 'examCategoryTab'));
    };

    return (
        <div className='card general-search test-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-test-search' onFinish={props.onFinish}>
                <Form.Item label='クラス'>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='term_uuid' style={{ 'marginBottom': '0px' }}>
                                <Select
                                    disabled={optionalLoading}
                                    loading={optionalLoading}
                                    onChange={handleSelectTerm}
                                    placeholder='年度を選択してください'
                                >
                                    {addAllTermAndSort(termList).map((value, index) => {
                                        return <Option key={index} value={value.uuid}>{value.name}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='school_class_uuid' style={{ 'marginBottom': '0px' }}>
                                <Select
                                    disabled={optionalLoading || (selectTerm === ALL_TERM_UUID)}
                                    loading={optionalLoading}
                                    placeholder='クラスを選択してください'
                                >
                                    {selectTerm && classListMap[selectTerm]?.map((value, index) => {
                                        return (
                                            <Option key={index} value={value.uuid}>
                                                <Tag color='#AAAAAA' style={{ borderRadius: '5px', textAlign: 'center', width: '90px' }}>
                                                    {value.term.name}
                                                </Tag>
                                                {value.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label='役割' name='role_uuid'>
                    <Select
                        disabled={optionalLoading}
                        loading={optionalLoading}
                        placeholder='役割を選択してください'
                    >
                        {roleList.map((value, index) => {
                            return (<Option key={index} value={value.uuid}>{value.name}</Option>);
                        })}

                    </Select>
                </Form.Item>

                <Form.Item label='集計方法' name='latest_result_only'>
                    <Radio.Group
                        defaultValue={false}
                        disabled={optionalLoading}
                    >
                        <Radio value={false}>過去の結果を含めて集計</Radio>
                        <Radio value={true}>最新の結果のみで集計</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className='submit-container'>
                    <Button
                        className='mr-4'
                        disabled={optionalLoading || listLoading}
                        htmlType='button'
                        loading={optionalLoading || listLoading}
                        onClick={onReset}
                    >
                        リセット
                    </Button>
                    <Button
                        disabled={optionalLoading || listLoading}
                        htmlType='submit'
                        icon={<SearchOutlined />}
                        loading={optionalLoading || listLoading}
                        type='primary'
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

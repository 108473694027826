import { LargeSpin } from 'components/modules/Spin';

function Progress() {
    return (
        <div className='loading-wrapper'>
            <LargeSpin tip='しばらくお待ちください...' />
        </div>
    );
}

export default Progress;
import React, { useContext, useEffect, useState } from 'react';
import {
    Tag,
    Tooltip,
    TablePaginationConfig,
} from 'antd';
import {
    SelectOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';
import { generateLtiLaunchDomain, getCurrentTerm } from 'constants/GlobalConfig';
import { useAppSelector } from 'flex/utils';
import { BaseTable } from 'components/modules/BaseTable';
import { ScoringStatusTag } from './ScoringStatusTag';
import 'styles/modules/test_status_modal.scss';
import { useAppCategoryResult } from '../utils/useAppCategoryResult';
import { StatusSearch } from './StatusSearch';
import { BreadCrumbContext } from './index';
import { selectors } from 'flex/Selectors';


type DataSource = App.AppStatus.UsersListItem;
type FormValuesKeys = keyof Required<Omit<Http.Connection.Request.Parameter.App.GroupStatusSearch, 'only_ags_application'>>;
type FormValues = Record<FormValuesKeys, string | undefined>;

interface Props {
    category: App.AppsCategory
    categoryResult: ReturnType<typeof useAppCategoryResult>[0],
    onSearch: ReturnType<typeof useAppCategoryResult>[1],
    resetCategoryResult: ReturnType<typeof useAppCategoryResult>[2],
    visible: boolean,
}

const INIT_SEARCH: FormValues = {
    role_uuid: undefined,
    school_class_uuid: undefined,
    term_uuid: undefined,
};

export const StudentList: React.VFC<Props> = ({
    category,
    categoryResult,
    onSearch,
    resetCategoryResult,
    visible,
}) => {
    const { setBreadcrumb } = useContext(BreadCrumbContext);
    const [search, setSearch] = useState<FormValues>(INIT_SEARCH);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        ...categoryResult.pagination,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`)
        },
        onChange: (page, pageSize) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize,
            }));
            onSearch(category.uuid, search, page);
        },
    });
    const { termList } = useAppSelector(selectors.globalState);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const categoryApplications = category?.applications ?? [];

    useEffect(() => {
        if (!visible) return;
        const newSearch = {
            ...INIT_SEARCH,
            term_uuid: getCurrentTerm(termList).uuid ?? '',
        };
        resetCategoryResult();
        setSearch(newSearch);
        onSearch(category.uuid, newSearch, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            total: categoryResult.pagination.total,
        }));
    }, [categoryResult.pagination]);

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            onChange: (page, pageSize) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize,
                }));
                onSearch(category.uuid, search, page);
            },
        }));
        // category?.uuid, searchの監視で正常に動作しているため
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category?.uuid, search]);

    const handleClickUserName = (userName: string, uuid: string) => {
        setBreadcrumb({ name: userName, uuid: uuid, type: 'Student' });
    };

    const handleClickTestName = (title: string, uuid: string) => {
        setBreadcrumb({ name: title, uuid: uuid, type: 'Application' });
    };

    const generateChildren = (): ColumnsType<DataSource> => {
        // usersListが何もないときに、空の配列を渡すとレイアウトが崩れるため、何もないことを表示するオブジェクトを用意
        if (categoryResult.usersList?.length === 0) return [{ title: '-', key: 0, width: 250, render: () => <></> }];
        return categoryApplications.map((value, index) => ({
            title: (
                <Tooltip title={value.title} >
                    <button
                        className='test-result-button text-button'
                        onClick={() => handleClickTestName(value.title, value.uuid)}
                        style={{ marginRight: '0.5rem' }}
                    >
                        {value.title}
                    </button>
                </Tooltip>
            ),
            key: index,
            width: 250,
            className: 'test-result-column',
            render: (row, rowRecord) => {
                const resultType = 'exam-result'

                return (
                    <div>
                        <span style={{ marginRight: '1rem' }}>
                            {createTextOfLatestApplicationResult(rowRecord.applications[index])}
                        </span>

                        {rowRecord.applications[index]?.result_url && rowRecord.applications[index]?.result_histories?.length > 0 && (
                            <a
                                href={`${generateLtiLaunchDomain(isControllableUser, true)}/${resultType}/${rowRecord.applications[index].uuid}/${rowRecord.uuid}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{ marginRight: '1rem' }}
                            >
                                <SelectOutlined />
                            </a>
                        )}

                        <ScoringStatusTag
                            status={rowRecord.applications[index]?.result_histories ?
                                rowRecord.applications[index].result_histories[0]?.grading_progress :
                                undefined
                            }
                        />
                    </div>
                );
            },
        }));
    };
    const dataSource = categoryResult.usersList;

    const columns: ColumnsType<DataSource> = [
        {
            title: '氏名',
            width: 200,
            fixed: 'left',
            key: 'name',
            render: (_, record) => {
                const existApplicationResult = record.applications.length !== 0 && record.applications.filter(app => app.result_histories.length !== 0).length !== 0;
                return (
                    <Tooltip
                        mouseEnterDelay={1}
                        title={existApplicationResult ? '生徒ごとのビューを開く' : '実施結果が存在しません'}
                    >
                        <button
                            disabled={!existApplicationResult}
                            className={`text-button ${!existApplicationResult && 'disabled'}`}
                            onClick={
                                () => {
                                    handleClickUserName(record.name, record.uuid);
                                }
                            }
                        >
                            {record.name}
                        </button>
                    </Tooltip>
                );
            }
        },
        {
            title: '配信対象者',
            children: [
                {
                    title: 'クラス名',
                    width: 200,
                    dataIndex: 'belongs',
                    key: 'class',
                    render: (belongs: DataSource['belongs']) => (
                        belongs.map((val, index) => (
                            <div key={`${val.school_class.name}-${index}`}>
                                <Tag color='#AAAAAA' style={{
                                    width: '75px',
                                    textAlign: 'center',
                                    borderRadius: '5px',
                                    marginRight: '0.5rem'
                                }}>
                                    {val.school_class.term.name}
                                </Tag>
                                {val.school_class.name}
                            </div>
                        ))
                    )
                },
                {
                    title: '出席番号',
                    width: 150,
                    dataIndex: 'belongs',
                    key: 'roles',
                    render: (belongs: DataSource['belongs']) => (
                        belongs.map((val, index) => (
                            <div key={index}>{val.number}</div>
                        ))
                    )
                },
                {
                    title: '学習の進捗',
                    width: 200,
                    dataIndex: 'applications',
                    key: 'progress',
                    render: (applications: DataSource['applications']) => {
                        const totalExams = applications.length;
                        const finishedExams = applications.reduce((prev, app) => {
                            const progress = app.result_histories[0]?.activity_progress;
                            return progress === 'Submitted' || progress === 'Completed' ? ++prev : prev
                        }, 0) || 0;
                        return <div>{totalExams ? `${finishedExams} / ${totalExams} 学習完了` : '- / -'}</div>;
                    }
                }
            ]
        },
        {
            title: <div style={{ position: 'sticky', maxWidth: '500px', left: '200px' }}>実施結果</div>,
            align: 'center',
            width: 250 * categoryApplications.length,
            children: generateChildren()
        },
    ];

    const calculateScrollWidth = () => {
        return 550 + (100 * categoryApplications.length);
    };

    const handleSearch = (values: FormValues) => {
        onSearch(category.uuid, values, 1);
        setSearch(values);
        setPagination(pagination => ({
            ...pagination,
            current: 1,
        }));
    };

    return (
        <div>
            <StatusSearch
                values={search}
                onSearch={handleSearch}
                loading={categoryResult.isLoading}
                visible={visible}
            />

            <BaseTable
                loading={categoryResult.isLoading}
                columns={columns}
                dataSource={dataSource}
                emptyDescription='対象の学習結果が存在しません'
                size='middle'
                pagination={pagination}
                scroll={{ x: calculateScrollWidth() }}
                className='all-white-table'
            />
        </div>
    );
};

const createTextOfLatestApplicationResult = (application: DataSource['applications'][number] | undefined) => {
    // result_historiesの0が最新なのでそれを表示
    const result_histories = application?.result_histories[0];
    return result_histories ? `${result_histories.score} / ${result_histories.max_score}` : '―';
};

/***
 * すべてのselector (定義されていなければ追記)
 */
export const selectors = {
    globalState: (state: Store) => state.globalState,
    user: (state: Store) => state.user.payload,
    tenant: (state: Store) => state.tenant.payload,
    isControllableUser: (state: Store) => state.isControllableUser.payload,
    failure: (state: Store) => state.failure,
    //////////////
    // connection
    //////////////
    announcementGetConnection: (state: Store) => state.announcementGetConnection,
    appsCategorySearchConnection: (state: Store) => state.appsCategorySearchConnection,
    appsCategoryViewConnection: (state: Store) => state.appsCategoryViewConnection,
    appsCategoryResultConnection: (state: Store) => state.appsCategoryResultConnection,
    appsEditConnection: (state: Store) => state.appsEditConnection,
    appsListConnection: (state: Store) => state.appsListConnection,
    appsOrderEditConnection: (state: Store) => state.appsOrderEditConnection,
    appsTemplateSearchConnection:  (state: Store) => state.appsTemplateSearchConnection,
    appsTemplateSearchForAdminConnection:  (state: Store) => state.appsTemplateSearchForAdminConnection,
    appsControlViewConnection: (state: Store) => state.appsControlViewConnection,
    gradeSearchConnection: (state: Store) => state.gradeSearchConnection,
    usersExportConnection: (state: Store) => state.usersExportConnection,
    usersImportConnection: (state: Store) => state.usersImportConnection,
    usersMultipleUpdateFlagConnection: (state: Store) => state.usersMultipleUpdateFlagConnection,
    usersAccountUnlockConnection: (state: Store) => state.usersAccountUnlockConnection,
    organizationSearchConnection: (state: Store) => state.organizationSearchConnection,
    taoExamCategoryDownloadCsvConnection: (state: Store) => state.taoExamCategoryDownloadCsvConnection,
    taoExamCategoryControlDownloadCsvConnection: (state: Store) => state.taoExamCategoryControlDownloadCsvConnection,
    taoExamCategorySearchConnection: (state: Store) => state.taoExamCategorySearchConnection,
    taoExamCategoryResultConnection: (state: Store) => state.taoExamCategoryResultConnection,
    taoExamCategoryDeleteConnection: (state: Store) => state.taoExamCategoryDeleteConnection,
    taoExamCategoryEditConnection: (state: Store) => state.taoExamCategoryEditConnection,
    taoExamCategoryViewConnection: (state: Store) => state.taoExamCategoryViewConnection,
    taoExamCategoryControlEditConnection: (state: Store) => state.taoExamCategoryControlEditConnection,
    taoExamCategoryControlDeleteConnection: (state: Store) => state.taoExamCategoryControlDeleteConnection,
    taoExamCategoryControlResultConnection: (state: Store) => state.taoExamCategoryControlResultConnection,
    taoExamCategoryControlChangeCategoryOrderConnection: (state: Store) => state.taoExamCategoryControlChangeCategoryOrderConnection,
    taoExamCategoryControlSearchConnection: (state: Store) => state.taoExamCategoryControlSearchConnection,
    taoExamCategoryControlViewConnection: (state: Store) => state.taoExamCategoryControlViewConnection,
    taoExamConfirmConnection: (state: Store) => state.taoExamConfirmConnection,
    taoExamEditConnection: (state: Store) => state.taoExamEditConnection,
    taoExamStatisticsConnection: (state: Store) => state.taoExamStatisticsConnection,
    termSearchConnection:  (state: Store) => state.termSearchConnection,
    ogpViewDataConnection:  (state: Store) => state.ogpViewDataConnection,

    // 役割
    roleSearchConnection: (state: Store) =>state.roleSearchConnection,
    // クラス
    classSearchConnection: (state: Store) => state.classSearchConnection,
    // ユーザ
    usersSearchConnection: (state: Store) => state.usersSearchConnection,
    usersEditConnection: (state: Store) => state.usersEditConnection,
    //アンケート
    questionnaireEditConnection:(state: Store) => state.questionnaireEditConnection,
    questionnaireDeleteConnection:(state: Store) => state.questionnaireDeleteConnection,
    questionnaireSearchConnection: (state: Store) => state.questionnaireSearchConnection,
    questionnaireCategorySearchConnection: (state: Store) => state.questionnaireCategorySearchConnection,
    jobInfoMap: (state: Store)  => state.jobInfoMap,


    ///////
    // view
    ///////
    examCategoryTab: (state: Store) => state.examCategoryTab,
    examCategoryTabClassListMap: (state: Store) => state.examCategoryTab.classListMap,
    examCategoryTabRoleList: (state: Store) => state.examCategoryTab.roleList,
    examCategoryTabModal: (state: Store) => state.examCategoryTab.modal,
    examControlCategoryTab: (state: Store) => state.examControlCategoryTab,
    examControlCategoryTabClassListMap: (state: Store) => state.examControlCategoryTab.classListMap,
    examControlCategoryTabRoleList: (state: Store) => state.examControlCategoryTab.roleList,
    examControlCategoryTabModal: (state: Store) => state.examControlCategoryTab.modal,
    userManagementTab: (state: Store) => state.userManagementTab,
    userManagementTabSelectOrganization: (state: Store) => state.userManagementTab.selectOrganization,
    userManagementTabSelectTerm: (state: Store) => state.userManagementTab.selectTerm,
    userManagementTabEditUser: (state: Store) => state.userManagementTab.editUser,
    editUserIsLocal:(state: Store) => state.editUser.isLocal,
    editUserSelectOrganization: (state: Store) => state.editUser.selectOrganization,
    editUserRoleList: (state: Store) => state.editUser.roleList,
    editUserSelectTerms:(state: Store) => state.editUser.selectTerms,
    editUserClassListMap:(state: Store) => state.editUser.classListMap
}

import { useContext, useEffect, useState } from 'react';
import {Badge, Button, Col, Empty, Popconfirm, Row, Table, Tooltip} from "antd";
import {BarChartOutlined, DeleteOutlined, EditOutlined, FileAddOutlined} from "@ant-design/icons";
import {DetailContext, ListContext, SearchContext} from "./JobListEffectComponent";
import * as GlobalConfig from "../../../../constants/GlobalConfig";
import {useDispatch} from "react-redux";
import * as Actions from "../../../../flex/Actions";
import moment from "moment";

JobList.propTypes = {};

function JobList(props) {
    const [list, setList] = useContext(ListContext);
    const [search, setSearch] = useContext(SearchContext);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
            showTotal: (total, range) => {
                return (`全${total}件中 ${range[0]}-${range[1]} 件`)
            },
            pageSize: 50,
            total: list.notificationListItemCount,
            position: ["topRight", "bottomRight"],
            showSizeChanger: true,
            pageSizeOptions: GlobalConfig.PageSizeOptions,
            onShowSizeChange: (page, page_size) => {
                setPagination(pagination => ({
                    ...pagination,
                    page,
                    pageSize: page_size
                }));
                setSearch(search => ({
                    ...search,
                    page,
                    page_size
                }))
            },
            onChange: (page, page_size) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize: page_size
                }));
                setSearch(search => ({
                    ...search,
                    page,
                    page_size
                }))
            }
        }
    );
    const dispatch = useDispatch();

    useEffect(() => {
    }, [dispatch]);

    useEffect(() => {
        setPagination(pagination => ({
            ...pagination,
            total: list.jobListItemCount
        }));
    }, [dispatch, list.jobList, list.jobListItemCount])

    useEffect(() => {
        setLoading(list.loading);
    }, [list.loading])


    useEffect(() => {
        const data = search;
        const page = search?.page || 1;
        setPagination(pagination => ({
            ...pagination,
            current: page
        }));
        if (!data?.page_size) {
            data.page_size = 50;
        }
        delete data.page;
        dispatch(Actions.http.connection.job.search(data, page));
    }, [dispatch, search]);


    function analyzeJSON(data, callback) {
        for (let key in data) {
            if (typeof data[key] === "object") {
                analyzeJSON(data[key], callback);
            } else {
                callback(`${key}:${data[key]}`)
            }
        }
    }

    /**
     * ミリ秒を時分秒へ変換
     * ms ミリ秒
     */
    function computeDuration(ms) {
        let h = String(Math.floor(ms / 3600000) + 100).substring(1);
        let m = String(Math.floor((ms - h * 3600000) / 60000) + 100).substring(1);
        let s = String(Math.round((ms - h * 3600000 - m * 60000) / 1000) + 100).substring(1);
        if(h !== "00"){
            return h + '時間' + m + '分' + s + '秒';
        }else if(m !== "00"){
            return m + '分' + s + '秒';
        }else{
            return s + '秒';
        }
    }

    const columns = [
        {
            title: '種別',
            width: "150px",
            dataIndex: 'type_name',
            key: 'type_name',
        },
        {
            title: '状態',
            width: "80px",
            dataIndex: 'is_success',
            key: 'is_success',
            render: (priority) => {
                switch (priority) {
                    case true: {
                        return <Badge status="success" text="成功"/>;
                    }
                    case false: {
                        return <Badge status="error" text="失敗"/>;
                    }
                    case null: {
                        return <Badge status="processing" text="実施中"/>;
                    }
                    default: {
                        return <Badge status="default" text="不明"/>;
                    }
                }
            }
        },
        {
            title: '詳細情報',
            width: "270px",
            dataIndex: 'result',
            key: 'result',
            render: (_, record) => {
                let content = [];
                analyzeJSON(_, (rVal) => {
                    content.push(rVal)
                })
                return (
                    <div>
                        {record.is_success === null && record.message}
                        <br/>
                        {content.map((v, index) => {
                            return <p key={index}>{v}</p>
                        })}
                    </div>
                )
            }
        },
        {
            title: '作成/更新',
            width: "180px",
            key: 'duration',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format("YYYY/MM/DD HH:mm")} から</span>
                    <br/>
                    <span>{moment.unix(record.updated_at).format("YYYY/MM/DD HH:mm")} まで</span>
                </>
            )
        },
        {
            title: '終了/経過時間',
            width: "150px",
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => {
                return (
                    <>
                        {record.is_success &&
                        <span>
                        {moment.unix(record.done_at).format("YYYY/MM/DD HH:mm")}
                            <br/>
                        処理時間: {computeDuration(moment.unix(record.done_at).diff(moment.unix(record.created_at)))}
                    </span>}
                    </>
                )
            }
        }
    ];


    return (
        <div className="notifications-list container">
            <Table
                locale={{
                    filterConfirm: '確定',
                    filterReset: 'リセット',
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={'実施している処理はありません'}/>,
                }}
                rowKey={"uuid"}
                bordered
                size="small"
                columns={columns}
                dataSource={list.jobList}
                scroll={{x: 830}}
                pagination={pagination}
                className="general-table"
                loading={list.loading}
            />
        </div>
    );
}

export default JobList;
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {notificationSearchConnection, user} from "../../../../flex/Reducers";
import {useSelector} from "react-redux";
import {notification} from "antd";
import * as Actions from "../../../../flex/Actions";
import {ListContext} from "./index";
import { additionDisplayName } from 'constants/GlobalConfig';


function NotificationEffectComponent(props) {
    const [list, setList] = useContext(ListContext);

    const notificationSearchConnection = useSelector(state => state.notificationSearchConnection);
    const notificationCategorySearchConnection = useSelector(state => state.notificationCategorySearchConnection);
    const notificationTargetConnection = useSelector(state => state.notificationTargetConnection);

    useEffect(()=>{
        setList(preview => ({
            ...preview,
            loading: notificationSearchConnection.meta.fetch
        }))
        if(notificationSearchConnection.meta.status=== Actions.statusEnum.SUCCESS){
            setList(preview => ({
                ...preview,
                notificationList: notificationSearchConnection.payload.result.items.map(item => {
                    const created_by = additionDisplayName(item.created_by);
                    const updated_by = item.updated_by ?
                        additionDisplayName(item.updated_by) :
                        created_by;

                    return {
                        ...item,
                        created_by,
                        updated_by,
                    }
                }),
                notificationListItemCount: notificationSearchConnection.payload.result.item_count
            }))
        }
        if(notificationSearchConnection.meta.status=== Actions.statusEnum.FAILURE || notificationSearchConnection.meta.status=== Actions.statusEnum.ERROR){
            //OPTIMIZE 基本的には汎用エラーコンポーネントがエラーをスポイルしてここでは何も起こらないのが正なので、処理しない
        }
    },[notificationSearchConnection, setList]);

    useEffect(() => {
        setList(preview => ({
            ...preview,
            categoryLoading: notificationCategorySearchConnection.meta.fetch
        }))

        if(notificationCategorySearchConnection.meta.status === Actions.statusEnum.SUCCESS){
            setList(preview => ({
                ...preview,
                categoryList: notificationCategorySearchConnection.payload.result.items,
                categoryListItemCount: notificationCategorySearchConnection.payload.result.item_count
            }));
        }
        if(notificationCategorySearchConnection.meta.status === Actions.statusEnum.FAILURE || notificationCategorySearchConnection.meta.status === Actions.statusEnum.ERROR){

        }
    },[notificationCategorySearchConnection, setList])

    useEffect(() => {
        switch (notificationTargetConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
                setList(preview => ({
                    ...preview,
                    targetUsersLoading: notificationTargetConnection.meta.fetch,
                }))
                break;
            case Actions.statusEnum.SUCCESS:
                const result = notificationTargetConnection.payload.result;
                const items = result.items.map(user=> {
                    const belongs = user.belongs.map(belong => {
                        return {
                            endAt: belong.end_at,
                            isCurrent: belong.is_current,
                            role: {name: belong.role.name},
                            schoolClass: {
                                name: belong.school_class.name,
                                number: belong.school_class.number,
                                term: {name: belong.school_class.term.name},
                                organization: {
                                    name: belong.school_class.organization.name,
                                    uuid: belong.school_class.organization.uuid
                                }
                            },
                            startAt: belong.start_at,
                            updatedAt: belong.updated_at,
                        }
                    });
                    return {
                        belongs,
                        email: user.email,
                        firstName: user.first_name,
                        firstNameKana: user.first_name_kana,
                        isActive: user.is_active,
                        isLocal: user.is_local,
                        isPlannedToDelete: user.is_planned_to_delete,
                        isRead: user.is_read,
                        lastName: user.last_name,
                        lastNameKana: user.last_name_kana,
                        loginId: user.login_id,
                        uuid: user.uuid,
                    }
                });
                setList(preview => ({
                    ...preview,
                    targetUsersLoading: notificationTargetConnection.meta.fetch,
                    targetUsersList: {
                        itemCount: result.item_count,
                        items,
                        pageSize: result.page_size,
                        pageTotal: result.page_total,
                        readCount: result.read_count,
                        totalCount: result.total_count,
                        unreadCount: result.unread_count,
                    }
                }));

                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            default:
                break;
        }
    },[notificationTargetConnection, setList])

    return null;
}

export default NotificationEffectComponent;
import * as React from 'react';

import { Button, Input, Typography, Row, Col } from 'antd';

import { UserItemResultState } from '../../hooks/useUserItemResult';

const { TextArea } = Input;

type Props = {
    comment: NonNullable<UserItemResultState['result']>['comment'] | undefined
    isLoading: boolean
    onClickSaveComment: (comment: string | undefined) => void
}

export const TeachersComment: React.VFC<Props> = ({
    comment: defaultComment,
    isLoading,
    onClickSaveComment,
}) => {
    const [comment, setComment] = React.useState<string>();

    React.useEffect(() => {
        setComment(defaultComment?.text);
    }, [defaultComment]);

    const handleReset = () => {
        setComment('');
    };

    const handleSave = () => {
        onClickSaveComment(comment ?? '');
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div>
                <Typography.Text style={{ margin: '0.5rem' }}>
                    先生からのコメント
                </Typography.Text>
            </div>
            <TextArea
                disabled={isLoading}
                maxLength={10000}
                onChange={e => setComment(e.target.value)}
                placeholder='コメントを記入してください'
                style={{
                    flexGrow: 1,
                    marginBottom: '0.5rem',
                }}
                value={comment}
            />
            <Row gutter={[8, 16]}>
                <Col lg={12} md={24}>
                    <div style={{ alignItems: 'center', display: 'flex', flexGrow: 1, flexWrap: 'wrap', verticalAlign: 'middle' }}>
                        <span style={{ marginRight: '0.5rem', whiteSpace: 'nowrap' }}>コメント更新日時:</span>
                        <span style={{ whiteSpace: 'nowrap' }}>{isLoading ? '' : defaultComment?.updatedAt ?? '-'}</span>
                    </div>
                </Col>
                <Col lg={12} md={24}>
                    <Row gutter={[16, 4]} justify='end'>
                        <Col><Button onClick={handleReset}>リセット</Button></Col>
                        <Col><Button onClick={handleSave} type='primary'>保存</Button></Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

import * as React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';

type Props = {
    category: ExamCategory | undefined
    count: number | undefined
    isControllableUser: boolean
    loading: boolean
    targetUsersCount?: number
}

export const Summary: React.VFC<Props> = (props) => {
    const { category, count, isControllableUser, loading, targetUsersCount } = props;

    const title = category?.title ?? '';
    const targetString = isControllableUser ? '配信対象校' : '配信対象者';
    const measureWord = isControllableUser ? '校' : '名';

    const targetPermissions = category && 'target_permissions' in category ?
        category?.target_permissions ?? [] :
        [];

    const targetPermissionList = targetPermissions.map(role => <span key={role.code}>・{role.name}</span>);

    const targetGrades = category && 'target_grades' in category ?
        category?.target_grades ?? [] :
        [];

    const targetGradeList = targetGrades.map(klass => <span key={klass.code}>・{klass.name}</span>);

    // 一斉配信の場合、 /view のレスポンスに以下の2つが含まれているはずなので、長さは 0 よりも大きくなるはず
    const inBoardOfEducation = targetPermissionList.length > 0 && targetGradeList.length > 0;

    return (
        <div className='wrapper'>
            <div className='test-status-modal-card-wrapper'>
                <Row gutter={16}>
                    <Col span={24}>
                        <Card bordered={false} className='test-status-modal-card'>
                            {loading ?
                                <Row
                                    align='middle'
                                    justify='space-around'
                                    style={{ textAlign: 'center' }}
                                >
                                    <Col span={24}>
                                        <span><LoadingOutlined /> 読み込み中...</span>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col span={12}>
                                        <Row align='middle'>
                                            <Col span={24}>
                                                <div className='test-status-modal-title'>テストグループ名</div>
                                                <div className='test-status-modal-value'>{title}</div>
                                            </Col>
                                        </Row>
                                        <Row align='middle'>
                                            <Col span={6}>
                                                <div className='test-status-modal-title'>{targetString}</div>
                                                <div className='test-status-modal-value'>{`${count ?? '-'} ${measureWord}`}</div>
                                            </Col>

                                            {isControllableUser && (
                                                <Col span={6}>
                                                    <div className='test-status-modal-title'>配信対象者</div>
                                                    {/* 管理者権限で開いたときの配信対象校と挙動を同じにするために, undefined のときの表示を 0 としている */}
                                                    <div className='test-status-modal-value'>{`${targetUsersCount ?? 0} 名`}</div>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                    {(!isControllableUser && inBoardOfEducation) && (
                                        <Col span={12}>
                                            <Row align='middle'>
                                                <Col span={24}>
                                                    <div className='test-status-modal-title'>公開対象権限</div>
                                                    <div className='test-status-modal-value list'>{targetPermissionList}</div>
                                                </Col>
                                            </Row>
                                            <Row align='middle'>
                                                <Col span={24}>
                                                    <div className='test-status-modal-title'>公開対象学年コード</div>
                                                    <div className='test-status-modal-value list'>{targetGradeList}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

import { createAction } from 'redux-actions';

import { API_SECURITY } from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/SECURITY';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
};

type DefaultPayload = { method: HttpMethods, api: string };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

type Payload<EXTRA extends Record<string, unknown> = Record<string, never>> =
    EXTRA extends Record<string, never> ?
    DefaultPayload :
    Expand<DefaultPayload & EXTRA>;

type Meta<EXTRA extends Record<string, unknown> = Record<string, never>> =
    EXTRA extends Record<string, never> ?
    DefaultMeta :
    Expand<DefaultMeta & EXTRA>;

export const get = createAction(
    createActionType('GET' as const),
    () => ({
        api: `${API_SECURITY}/ip-filter-setting`,
        method: 'get',
    }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const update = createAction<
    Payload,
    Meta,
    {
        is_enabled: boolean;
        ip_filters: {
            setting: string,
            comment: string,
            is_active: boolean,
        }[];
    }
>(
    createActionType('UPDATE' as const),
    (data) => ({
        api: `${API_SECURITY}/ip-filter-setting`,
        data,
        method: 'post',
    }),
    (_data) => ({ fetch: true, status: StatusEnum.REQUEST })
);

import * as Actions from "../Actions";
import {viewNameInSavingData} from "../view/ViewName/savingData";
import {editUserInSavingData} from "../view/UsersManagement/EditUser/savingData";
import {questionnaireCategoryTabSavingData} from "../view/Questionnaire/QuestionnaireCategoryTab/savingData";
import {questionnaireTabSavingData} from "../view/Questionnaire/QuestionnaireTab/savingData";
import {editQuestionnaireSavingData} from "../view/Questionnaire/EditQuestionnaire/savingData";
import {editQuestionSavingData} from "../view/Questionnaire/EditQuestion/savingData";
import {resultQuestionnaireSavingData} from "../view/Questionnaire/ResultQuestionnaire/savingData";
import {answerResultSavingData} from "../view/Questionnaire/AnswerResultModal/savingData";
import {examCategoryTabFlex,examControlCategoryTabFlex} from '../view/Assessment/examCategoryTabFlex'
import { userManagementTabFlex } from "flex/view/UsersManagement/UserManagementTab";
import { savingDataForConnection as questionnaireSavingDataForConnection} from 'flex/view/Questionnaire/savingDataForConnection.ts';



const savingData = store => next => action => {

	if(!action.type.match("^VIEW_LOGIC")) {
		// 基本的には、これまでの互換性を維持するため、CONNECTIONなどの処理はここで一度storeに保存される
		next(action)
	} else {
		if (action.type.match(/APP_TEMPLATE_TAB/)) {
			next(action)
		}
		if (action.type.match(/USER_MANAGEMENT_TAB/)) {
			next(action)
		}
		if (action.type.match(/EDIT_USER/)) {
			editUserInSavingData(store,next,action)
		}
		if (action.type.match(/QUESTIONNAIRE_TAB/)) {
			questionnaireTabSavingData(store,next,action)
		}
		if (action.type.match(/EDIT_QUESTIONNAIRE\//)) {
			editQuestionnaireSavingData(store,next,action)
		}
		if (action.type.match(/EDIT_QUESTION\//)) {
			editQuestionSavingData(store,next,action)
		}
		if (action.type.match(/RESULT_QUESTIONNAIRE\//)) {
			resultQuestionnaireSavingData(store,next,action)
		}
		if (action.type.match(/ANSWER_RESULT_MODAL\//)) {
			answerResultSavingData(store,next,action)
		}
		if (action.type.match(/QUESTIONNAIRE_CATEGORY_TAB/)) {
			questionnaireCategoryTabSavingData(store,next,action)
		}
		if(action.type.match(/EXAM_CATEGORY_TAB/)){
			next(action)
		}
	}

	if(action.meta && action.meta.viewName  && !action.meta.fetch && action.meta.status === Actions.statusEnum.SUCCESS){
		viewNameInSavingData(store,next,action)
		questionnaireSavingDataForConnection(store, next, action);

		if(action.meta.viewName === 'examCategoryTab'){
			examCategoryTabFlex.savingDataForConnection(store, next, action)
		}

		if(action.meta.viewName === 'examControlCategoryTab'){
			examControlCategoryTabFlex.controlSavingDataForConnection(store, next, action)
		}

		if (action.meta.viewName === 'userManagementTab') {
			userManagementTabFlex.savingDataForConnection(store, next, action)
		}
	}
}

export default savingData

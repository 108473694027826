import { PageSizeOptions } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

export const resultQuestionnaireViewLogic = (store, next, action) => {
    const modalState = store.getState()['questionnaireTab']['modal'];
    const isControllableUser = store.getState()['isControllableUser'];

    switch (action.type) {
        case Actions.view.viewLogic.resultQuestionnaire.prepareView.toString(): {
            next({
                modal: {
                    isOpen: false,
                    item: {},
                },
                pagination: {
                    current: 1,
                    pageSize: 50,
                    pageSizeOptions: PageSizeOptions,
                    position: ['topRight', 'bottomRight'],
                    showSizeChanger: true,
                    total: 0,
                },
                questionnaire: null,
                resultList: [],
                resultListItemCount: 0,
                type: 'resultQuestionnaire',
            });

            const uuid = modalState['uuid'];
            if (!uuid) break;

            const action = isControllableUser.payload ?
                Actions.http.connection.questionnaires.controlTarget :
                Actions.http.connection.questionnaires.target;

            next(action(
                uuid,
                // page_size が 0 だとバックエンドでエラーとなる
                { page_size: 1 },
                1,
                'resultQuestionnaire'
            ));
            break;
        }
        case Actions.view.viewLogic.resultQuestionnaire.downloadCsv.toString(): {
            const userResultCsvAction = isControllableUser.payload ?
                Actions.http.connection.questionnaires.controlUserResultCsv :
                Actions.http.connection.questionnaires.userResultCsv;

            next(userResultCsvAction(
                modalState['uuid'],
                { encoding: action.payload.encoding }
            ));
            break;
        }
        default:
            next(action);
            break;
    }
};

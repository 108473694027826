import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type ExamHistory = {
    activityProgress: Exam.History['activity_progress']
    agsTimestamp: Exam.History['ags_timestamp']
    comment: NonNullable<Exam.History['comment']> | undefined
    createdAt: Exam.History['created_at']
    examResultUuid: Exam.History['exam_result_uuid']
    gradingProgress: Exam.History['grading_progress']
    maxScore: Exam.History['max_score']
    score: Exam.History['score']
}

type ExamWithHistory = {
    resultHistory: ExamHistory[]
    createdAt: Exam.WithHistory['created_at']
    createdBy: Exam.WithHistory['created_by']
    description: Exam.WithHistory['description']
    endAt: Exam.WithHistory['end_at']
    examCategoryUuid: Exam.WithHistory['exam_category_uuid']
    maxAttempts: Exam.WithHistory['max_attempts']
    maxScore: Exam.WithHistory['max_score']
    resultUrl: NonNullable<Exam.WithHistory['result_url']> | undefined
    startAt: Exam.WithHistory['start_at']
    title: Exam.WithHistory['title']
    url: Exam.WithHistory['url']
    uuid: Exam.WithHistory['uuid']
}

export type ResultExamCategoryState = {
    isLoading: boolean
    result: {
        exams: ExamWithHistory[] | Exam.ControlResult[]
        targetUsersCount: number | undefined,
        usersList: Expand<Pick<User, 'belongs' | 'uuid'> & {
            /** 氏名に表示する要素 */
            name: string,
            exams: ExamWithHistory[]
        }>[],
        usersListItemCount: number,
    } | {
        exams: ExamWithHistory[] | Exam.ControlResult[]
        organizationsList: ExamCategory.ControlResult[]
        organizationsListItemCount: number
        targetUsersCount: number | undefined
    } | undefined
};

export type RequestResultExamCategory = ReturnType<typeof useResultExamCategory>[1];

export const useResultExamCategory = () => {
    const [state, setState] = useState<ResultExamCategoryState>({
        isLoading: false,
        result: undefined,
    });

    const taoExamCategoryControlResultConnection = useAppSelector(state => state.taoExamCategoryControlResultConnection);
    const taoExamCategoryResultConnection = useAppSelector(state => state.taoExamCategoryResultConnection);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryControlResultConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const convertedItems = result.items.map(item => {
                    if ('exams' in item) {
                        return {
                            exams: item.exams.map(exam => ({
                                answeredCount: exam.answered_count ?? undefined,
                                answeredUserCount: exam.answered_user_count ?? undefined,
                                averageScore: exam.average_score ?? undefined,
                                highestScore: exam.highest_score ?? undefined,
                                lowestScore: exam.lowest_score ?? undefined,
                                maxScore: exam.max_score ?? undefined,
                                targetUsers: exam.target_users,
                                title: exam.title,
                            })),
                            organization: item.organization,
                        };
                    } else {
                        const userName = item.first_name !== '' || item.last_name !== '' ?
                            `${item.last_name} ${item.first_name}` :
                            item.login_id;

                        const test = {
                            belongs: item.belongs,
                            exams: item.control_exams?.map(convertExam) ?? [],
                            name: userName,
                            uuid: item.uuid,
                        };
                        return test;
                    }
                });

                const convertedItem = convertedItems[0];

                if (convertedItem === undefined) {
                    setState({
                        isLoading: false,
                        result: {
                            exams: [],
                            organizationsList: [],
                            organizationsListItemCount: 0,
                            targetUsersCount: 0,
                            usersList: [],
                            usersListItemCount: 0,
                        },
                    });
                    return;
                }

                if ('organization' in convertedItem) {
                    // 型変換が面倒なので, 型アサーションで無理やり変換する
                    // TODO: 型アサーションを使わない形に修正
                    const typeSafeItems = convertedItems as (typeof convertedItem)[];

                    // 型推論のためのガード.
                    // convertedItem が 'organization' を含む場合、この return は実行されない.
                    if (!('target_users' in result)) return;

                    setState({
                        isLoading: false,
                        result: {
                            // 対象学校が "すべて" のときに items の中身が空になる
                            exams: typeSafeItems[0]?.exams ?? [],
                            organizationsList: typeSafeItems,
                            organizationsListItemCount: result.item_count,
                            targetUsersCount: result.target_users,
                        },
                    });
                } else {
                    // 型変換が面倒なので, 型アサーションで無理やり変換する
                    // TODO: 型アサーションを使わない形に修正
                    const typeSafeItems = convertedItems as (typeof convertedItem)[];

                    setState({
                        isLoading: false,
                        result: {
                            // 対象学校が "すべて" のときに items の中身が空になる
                            exams: typeSafeItems[0]?.exams ?? [],
                            targetUsersCount: result.target_users,
                            usersList: typeSafeItems,
                            usersListItemCount: result.item_count,
                        },
                    });
                }
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState({
                    isLoading: false,
                    result: undefined,
                });
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlResultConnection]);

    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryResultConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                const usersList = result.items.map(item => {
                    const userName = item.first_name !== '' || item.last_name !== '' ?
                        `${item.last_name} ${item.first_name}` :
                        item.login_id;

                    return {
                        belongs: item.belongs,
                        exams: item.exams?.map(convertExam) ?? [],
                        name: userName,
                        uuid: item.uuid,
                    };
                });

                const usersListItemCount = result.item_count ?? 0;

                setState({
                    isLoading: false,
                    result: {
                        exams: result.items.at(0)?.exams?.map(convertExam) ?? [],
                        targetUsersCount: result.target_users,
                        usersList,
                        usersListItemCount,
                    },
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState({
                    isLoading: false,
                    result: undefined,
                });
                return;
            }
            default: {
                setState(state => ({ ...state, status: meta.status }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryResultConnection]);

    type Params = Expand<Omit<Http.Connection.Request.Parameter.ExamCategory.Result, 'latest_result_only'>  & {
        latest_result_only?: boolean
        page?: number
    }>

    const request = useCallback((
        uuid: string,
        data: Params,
        // あくまで学校管理者が操作する場合のみ必要なので、それ以外では使われない
        requestType?: 'control' | 'default'
    ) => {
        // ここで result を初期化すると、
        // ローディング中に table のデータが消えてしまう
        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const resultAction = isControllableUser ?
            Actions.http.connection.tao.examControlCategory.controlResult :
            requestType === 'control' ?
                Actions.http.connection.tao.examControlCategory.controlResult :
                Actions.http.connection.tao.examCategory.result;

        const { page, latest_result_only, ...restData } = data;

        dispatch(resultAction(
            uuid,
            {
                ...restData,
                latest_result_only: latest_result_only ? '1' : '0',
            },
            page ?? 1,
            ''
        ));
    }, [dispatch, isControllableUser]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
            result: undefined,
        });
    }, []);

    return [state, request, resetState] as const;
};

const convertExam = (exam: Exam.WithHistory): ExamWithHistory => {
    return {
        createdAt: exam.created_at,
        createdBy: exam.created_by,
        description: exam.description,
        endAt: exam.end_at,
        examCategoryUuid: exam.exam_category_uuid,
        maxAttempts: exam.max_attempts,
        maxScore: exam.max_score,
        resultHistory: exam.result_history.map(history => ({
            activityProgress: history.activity_progress,
            agsTimestamp: history.ags_timestamp,
            comment: history.comment ?? undefined,
            createdAt: history.created_at,
            examResultUuid: history.exam_result_uuid,
            gradingProgress: history.grading_progress,
            maxScore: history.max_score,
            score: history.score ?? 0,
        })),
        resultUrl: exam.result_url ?? undefined,
        startAt: exam.start_at,
        title: exam.title,
        url: exam.url,
        uuid: exam.uuid,
    };
};
import { getMoment } from 'constants/GlobalConfig';
import { StudentModal, ModalHeaderTemplate } from 'components/modules/Student/StudentModal';
import { LabelImportance } from 'components/modules/Student/LabelImportance';
import Ruby from 'components/modules/Ruby';
import SingleSelection from 'components/modules/Student/QuestionItem/SingleSelection';
import MultipleSelection from 'components/modules/Student/QuestionItem/MultipleSelection';
import FreeDescription from 'components/modules/Student/QuestionItem/FreeDescription';
import FileSelection from 'components/modules/Student/QuestionItem/FileSelection';
import QuestionItemCard from 'components/modules/Student/QuestionItem/QuestionItemCard';
import { FormProvider, useForm } from 'react-hook-form';
import RichTextView from 'components/modules/RichTextView';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/ja.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/themes/gray.min.css';

const moment = getMoment();


export const QUESTION_TYPE = {
    SINGLE: 1,
    MULTIPLE: 2,
    FREE_DESCRIPTION: 3,
    ATTACHING_FILE: 4,
};

export const QUESTIONNAIRE_STATUS = {
    UNANSWERED: 1,
    ANSWERED: 2,
    REANSWERED: 4,
    RESULT: 8,
};


const PreviewQuestionnaireModal = ({
    isOpen,
    previewQuestionnaire,
    onCancel = () => {
        console.error("need function 'closeModal'");
    },
}) => {
    const questionnaire = convertQuestionnaireToStudent(previewQuestionnaire);

    const methods = useForm();
    const { handleSubmit } = methods;

    const onSubmit = () => { };
    const onAccept = () => () => { };

    const headerElement =
        <ModalHeaderTemplate
            status={
                <LabelImportance type='pills' className='bg-lg-red'>
                    未回答
                </LabelImportance>
            }
        >
            {questionnaire.title}
        </ModalHeaderTemplate>;

    if (!isOpen) return null;

    return (
        <StudentModal isOpen={isOpen} closeModal={onCancel} data={questionnaire} headerElement={headerElement}>
            <MetaInfo questionnaire={questionnaire}></MetaInfo>
            <Detail questionnaire={questionnaire}></Detail>
            <FormProvider {...methods} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='bg-white p-4 sm:p-5 grid grid-cols-1 gap-4'>
                        {questionnaire.items?.map((item, i) => {
                            item.uuid = item.uuid ?? String(i);
                            switch (item.type) {
                                case QUESTION_TYPE.SINGLE:
                                    return (
                                        <SingleSelection
                                            key={i}
                                            name={item.uuid}
                                            {...item}
                                        />
                                    );
                                case QUESTION_TYPE.MULTIPLE:
                                    return (
                                        <MultipleSelection
                                            key={i}
                                            name={item.uuid}
                                            {...item}
                                        />
                                    );
                                case QUESTION_TYPE.FREE_DESCRIPTION:
                                    return (
                                        <FreeDescription
                                            key={i}
                                            name={item.uuid}
                                            {...item}
                                        />
                                    );
                                case QUESTION_TYPE.ATTACHING_FILE:
                                    return (
                                        <FileSelection
                                            key={i}
                                            name={item.uuid}
                                            onAccept={onAccept(item.uuid)}
                                            {...item}
                                        />
                                    );
                                default:
                                    return (
                                        <QuestionItemCard key={i} title='エラー'>
                                            <div>予期しないデータを取得しました.</div>
                                        </QuestionItemCard>
                                    );
                            }
                        })}
                    </div>
                </form>
            </FormProvider>
        </StudentModal>
    );
};


/***
 * 実施機関や公開期間などのアンケートについての情報を表示する
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const MetaInfo = ({ questionnaire }) => {
    const perform = questionnaire.performText ?
        <Ruby>{questionnaire.performText}</Ruby> :
        <span className='skeleton-box h-4 w-80 inline-block mr-2' />;
    const publishFrom = questionnaire.publishFrom ?
        questionnaire.publishFrom :
        <span className='skeleton-box h-4 w-32 inline-block mr-2' />;

    return (
        <div className='bg-beaver-100 p-4 sm:p-5'>
            <div>
                <div className='inline-block w-24'>
                    <Ruby>{'{回答期限|かいとうきげん}'}</Ruby>
                </div>
                <span> : </span>
                {perform}
            </div>
            <div>
                <div className='inline-block w-24'>
                    <Ruby>{'{発信元|はっしんもと}'}</Ruby>
                </div>
                <span> : </span>
                {publishFrom}
            </div>
        </div>
    );
};


const Detail = ({ questionnaire }) => (
    <div className='bg-white py-6 px-4 sm:p-5'>
        <div className='text-xl text-gray-700 froala-wrapper break-all'>
            <RichTextView
                model={questionnaire?.content ?? ''}
            />
        </div>
    </div>
);


const performsToText = (performStartAt, performEndAt) => {
    const BACKEND_PERFORM_START_AT = moment('1970-1-1 00:00', 'YYYY-MM-DD HH:mm');
    const BACKEND_PERFORM_END_AT = moment('2037-12-31 23:59', 'YYYY-MM-DD HH:mm');

    if (performStartAt.isSame(BACKEND_PERFORM_START_AT) && performEndAt.isSame(BACKEND_PERFORM_END_AT)) {
        return '{無期限|むきげん}';
    }

    const performStartText = performStartAt?.format('YYYY{年|ねん}MM{月|がつ}DD{日|にち} HH:mm') ?? '';
    const performEndText = performEndAt?.format('YYYY{年|ねん}MM{月|がつ}DD{日|にち} HH:mm') ?? '';
    return `${performStartText} ~ ${performEndText}`;
};

const convertQuestionnaireToStudent = (questionnaire) => {
    const items = questionnaire?.items ? questionnaire.items.map(item => {

        const candidates = item.answer_candidates?.map((candidate, i) => ({
            uuid: candidate.uuid ?? String(i),
            content: candidate.content,
            order: candidate.order ?? i,
        })) ?? [];

        return {
            uuid: item.uuid,
            type: item.type,
            content: item.content,
            detail: item.detail,
            file: {
                name: item.file?.name,
                uuid: item.file?.uuid,
            },
            isRequired: item.is_required,
            candidates,
        };
    }) : [];

    return {
        uuid: questionnaire.uuid,
        performStartAt: questionnaire.durationPerform && questionnaire.durationPerform[0],
        performEndAt: questionnaire.durationPerform && questionnaire.durationPerform[1],
        performText: questionnaire.durationPerform && performsToText(questionnaire.durationPerform[0], questionnaire.durationPerform[1]),
        publishFrom: questionnaire.publish_from,
        content: questionnaire.content,
        items: items,
        title: questionnaire.name,
    };
};


export default PreviewQuestionnaireModal;

import {InboxOutlined} from '@ant-design/icons';
import {message, Upload} from 'antd';
import {
    Fragment,
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    useImperativeHandle,
    useMemo,
} from 'react';
import Spin from 'components/modules/Spin';
import { useAppSelector } from 'flex/utils';
import { DraggerProps } from 'antd/lib/upload';
import { useFileManager } from 'constants/CustomHooks/useFileManager';

type Props = {
    /** アップロード可能拡張子 */
    allowedExtension: {
        extensions: string[]
        hint: string
    }

    /** 複数のファイルをアップロード可能かどうか  */
    canUploadMultiFiles?: boolean

    /**
     * 非活性にするか否か
     */
    disabled?: boolean

    /**
     * 生のデータで、受け渡しするかどうか
     * (生のデータを扱う場合ファイルの最大アップロード数は1つまで)
     */
    useRawData?: boolean

    /**
     * このコンポーネントを antd の Form で使えるようにするためのもの
     * https://ant.design/components/form/#components-form-demo-customized-form-controls
     *
     * 明示的に渡すことはしない
     */
    onChange?: (fileList: any[]) => void

    /**
     * このコンポーネントを antd の Form で使えるようにするためのもの
     * https://ant.design/components/form/#components-form-demo-customized-form-controls
     *
     * 明示的に渡すことはしない
     */
    value?: any[]
}

export type FileUploaderHandler = {
    add: (file: any) => void
    remove: (removedFile: any) => void
}

/** ファイルアップロード  */
// ForwardRefRenderFunction を使うと props の型が
// PropsWithChildren になってしまうので, Props で上書きしている
const FileUploader: ForwardRefRenderFunction<FileUploaderHandler, Props> = (props, ref) => {
    const {
        allowedExtension,
        canUploadMultiFiles = false,
        disabled = false,
        useRawData,
        onChange = () => ({}),
        value = [],
    } = props;

    // Antdのファイルの形式のファイルリスト
    // props.onUploaded更新 → props.uploadedFiles変更される → setAntdFileList代入 の流れで更新
    const fileConnection = useAppSelector(state => state.fileConnection);
    const [add, remove, reset, files] = useFileManager(
        {
            allowedExtensions: allowedExtension.extensions,
            isUploadImmediately: !useRawData,
            fileSizeOfMax: 50000000,
            fileSizeOfMin: 0,
            stockSize: canUploadMultiFiles ? 0 : 1,
        },
        onChange,
    );

    useImperativeHandle(ref, ()=>({
        add,
        remove,
    }));

    // Object の配列を依存配列にそのまま入れると常に effect が実行されてしまうので, string に変更している.
    const newUuids = joinFilesUuid(value);
    useEffect(() => {
        const oldUuids = joinFilesUuid(files);
        if(oldUuids === newUuids) return;

        reset(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newUuids]);

    const fileUploadProps: Pick<DraggerProps, 'onRemove' | 'beforeUpload' | 'fileList' | 'listType'> = useMemo(() => ({
        onRemove: (removedFile) => {
            remove(removedFile);
        },
        beforeUpload: (file) => {
            try {
                return add(file);
            } catch (error) {
                if(error instanceof  Error) {
                    message.error(error.message);
                }
            }
            return false;
        },
        fileList: files.map(files => {
            const {url, ...rest} = files;
            return disabled ? rest : files;
        }),
        listType: 'picture'
    }), [add, disabled, files, remove]);

    const disabledStyle = disabled ? {color:'#b8b8b8'}: {}
    return (
        <Fragment>
            <Spin
                tip='アップロード中'
                spinning={fileConnection.meta.fetch}
            >
                <Upload.Dragger name={'files'} {...fileUploadProps} disabled={disabled}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={disabledStyle}/>
                    </p>
                    <p className="ant-upload-text" style={disabledStyle}>
                        ファイルをドラッグ＆ドロップするか、このエリアをクリックして選択してください
                    </p>
                    <p className="ant-upload-hint" style={disabledStyle}>
                        サイズ制限 : 最大50MBまで
                    </p>
                    <p className="ant-upload-hint"style={disabledStyle}>
                        対応形式 : [{allowedExtension.hint}]
                    </p>
                </Upload.Dragger>
            </Spin>
        </Fragment>
    );
};

const rename = forwardRef(FileUploader);
export { rename as FileUploader};


/** propsのallowedExtensionに利用する  */
export const allowedExtensions = {
    image_movie_zip_doc:{
        hint: '画像(jpg,jpeg,png,gif,bmp),文書(pdf),動画/音声(mp3,mp4,avi,mov,wmv,mpg,mpeg),圧縮ファイル(zip,lzh,gz),テキスト(txt,csv),オフィスドキュメント(doc,docx,xls,xlsx,ppt,pptx)',
        extensions: [
            'jpg','jpeg','png','gif','bmp','pdf',                   // 画像系
            'mp3','mp4','avi','mov','wmv','mpg','mpeg',             // 動画・音声系
            'zip','lzh','gz',                                       // 圧縮ファイル
            'txt','csv','doc','docx','xls','xlsx','ppt','pptx',     // 文章ファイル系
        ]
    },
    image: {
        hint: '画像(jpg/png)',
        extensions: ['jpg', 'jpeg', 'png'],
    },
    csv:{
        hint: 'CSVファイル',
        extensions: ['csv','application/vnd.ms-excel','application/vnd.ms-excel']
    }
}

/** Object のまま useEffect の依存配列に入れると, 想定外の動作をするので, string に変換する */
const joinFilesUuid = (files: any[]) => {
    return [...files]
        .map(file => file?.uuid)
        .filter(v => v)
        .sort()
        .join('');
}
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type GetIpFilterSettingsState = {
    isLoading: boolean
};

type Meta = SecurityGetConnection['meta'];
type Payload = SecurityGetConnection['payload'];

export const useGetIpFilterSettings = (
    // ここでは引数は使わないが、他でここの実装を真似する際のためにそれっぽくしておく
    successCallback: (meta: Meta, payload: Payload, isControl: boolean) => void = () => undefined,
    failureCallback: (meta: Meta, payload: Payload, isControl: boolean) => void = () => undefined,
) => {
    const [state, setState] = useState<GetIpFilterSettingsState>({
        isLoading: false,
    });

    const securityGetConnection = useAppSelector(state => state.securityGetConnection);

    const dispatch = useDispatch();


    useEffect(() => {
        const { meta, payload } = securityGetConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                successCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                failureCallback(meta, payload, true);
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityGetConnection]);

    const request = useCallback(() => {
        dispatch(Actions.http.connection.security.get());
        setState(state => ({
            ...state,
            isLoading: true,
        }));
    }, [dispatch]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
        });
    }, []);

    return [state, request, reset] as const;
};

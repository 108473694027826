import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/QUESTIONNAIRE_CATEGORY_TAB';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * カテゴリを開いた際に呼び出す。
 * アンケートカテゴリの一覧を取得する。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    (data) => ({ data }),
    () => ({})
);

/**
 * 検索のリクエストをして、検索をStoreに保存する。
 * */
export const submitSearch = createAction(
    createActionType('SUBMIT_SEARCH' as const),
    (values) => ({ values }),
    () => ({})
);

/**
 * ページネーションをリクエストする。
 * */
export const submitPage = createAction(
    createActionType('SUBMIT_PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

/**
 * ページネーションをStoreの保存する。
 * */
export const savePage = createAction(
    createActionType('SAVE_PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

/**
 * カテゴリの編集モーダルを開く。
 * */
export const openEdit = createAction(
    createActionType('OPEN_EDIT' as const),
    (record) => ({ record }),
    () => ({})
);

/**
 * カテゴリの削除をリクエストをする。
 * */
export const submitDelete = createAction(
    createActionType('SUBMIT_DELETE' as const),
    (uuid) => ({ uuid }),
    () => ({})
);

/**
 * カテゴリの編集後に呼び出す。
 * 一覧を更新するリクエストをする。
 * */
// 編集通信の完了後に行う
export const submittedEdit = createAction(
    createActionType('SUBMITTED_EDIT' as const),
    () => ({}),
    () => ({})
);

/**
 * 新規作成で、編集画面モーダルを開く。
 * */
export const openCreate = createAction(
    createActionType('OPEN_CREATE' as const),
    () => ({}),
    () => ({})
);

/**
 * 編集画面モーダルを閉じる。
 * */
export const cancelEdit = createAction(
    createActionType('CANCEL_EDIT' as const),
    () => ({}),
    () => ({})
);

/**
 * 編集モーダルの内容をリクエストする。
 * */
export const submitEdit = createAction(
    createActionType('SUBMIT_EDIT' as const),
    () => ({}),
    () => ({})
);

import { useEffect, useState, VFC } from 'react';
import { Button, Col, Popconfirm, Row, Tooltip } from 'antd';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { getNameFromGradeCode } from 'constants/GlobalConfig';
import { PlusCircleFilled } from '@ant-design/icons';
import AddModal from './AddModal';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import { BaseTable } from 'components/modules/BaseTable';
import { ColumnsType } from 'antd/lib/table';


type Props = {
    currentOrganizationList: Organization[],
    onlyHasSchoolCode: boolean,
    onSave: (userList: Organization[]) => void,
}

const OrganizationTab: VFC<Props> = ({
    currentOrganizationList,
    onlyHasSchoolCode,
    onSave,
}) => {
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedList, setSelectedList] = useState<Organization[]>(currentOrganizationList);

    useEffect(() => {
        setSelectedList(currentOrganizationList);
    }, [currentOrganizationList]);

    const columns: ColumnsType<Organization> = [
        {
            title: '学校名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '校種コード',
            dataIndex: 'code',
            key: 'code',
            render: (code) => {
                return getNameFromGradeCode(code);
            },
        },
        {
            title: '権限',
            dataIndex: 'type_text',
            key: 'type_text',
            render: (type_text) => {
                return type_text;
            },
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            fixed: 'right',
            ellipsis: true,
            render: (_, record) => (
                <Row justify='space-around' align='middle'>
                    <Col>
                        <Tooltip title='除外'>
                            <Popconfirm
                                okText='除外'
                                cancelText='キャンセル'
                                onConfirm={
                                    () => {
                                        const newArray = selectedList.concat();
                                        newArray.forEach((v, i) => {
                                            if (v.uuid === record.uuid) newArray.splice(i, 1);
                                        });
                                        updateSelectList(newArray);
                                    }
                                }
                                title={
                                    <>
                                        <span>対象から学校を除外します</span>
                                        <br />
                                    </>
                                }
                            >
                                <Button
                                    className='mr-2' shape='circle' size='small' danger
                                    icon={<CloseOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>
            ),
        }
    ];

    function updateSelectList(newValue: Organization[]) {
        setSelectedList(newValue);
        onSave(newValue);
    }

    function mergeSelectList(newValue: Organization[]) {
        const tmp = [...newValue, ...selectedList]
            .reduce<Record<string, Organization>>((obj, it) => {
                obj[it.uuid] = it;
                return obj;
            }, {});
        return Object.values(tmp);
    }

    function handleAddOrganization(values: Organization[]) {
        updateSelectList(mergeSelectList(values));
        setIsEditOpen(false);
    }

    function handleAddCancel() {
        setIsEditOpen(false)
    }

    return (
        <div className='group-list container'>
            <div className='flex-right-container gutter-bottom'>
                <Popconfirm
                    okText='除外'
                    cancelText='キャンセル'
                    onConfirm={() => updateSelectList([])}
                    title={
                        <>
                            <span>現在対象になっている学校を一括除外します</span>
                            <br />
                            <span style={{ fontWeight: 'bold' }}>保存しないと適用されません</span>
                        </>
                    }
                >
                    <Button
                        className='mb-4'
                        type='primary'
                        icon={<MinusCircleOutlined />}
                    >
                        全て除外
                    </Button>
                </Popconfirm>
                <Button
                    className='mb-4'
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={() => setIsEditOpen(true)}
                >
                    追加
                </Button>
            </div>
            <BaseTable
                emptyDescription='設定された学校はまだありません'
                columns={columns}
                dataSource={selectedList}
            />
            <AddModal
                onlyHasSchoolCode={onlyHasSchoolCode}
                onCancel={handleAddCancel}
                onOk={handleAddOrganization}
                visible={isEditOpen}
                selectedList={selectedList}
            />
        </div>
    );
};

export default OrganizationTab;

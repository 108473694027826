import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import { addAllTermAndSort, getCurrentTerm, getDefaultLayout } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import { ListContext } from '../../index';
import { SearchRuleContext } from '.';

const Form = BaseForm;
const { Option } = Select;
const layout = getDefaultLayout();

const Search = ({ onSearch }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [search,] = useContext(SearchRuleContext);
    const [list, setList] = useContext(ListContext);
    const [loading, setLoading] = useState(false);
    const [optionalLoading, setOptionalLoading] = useState(false);
    const termSearchConnection = useSelector(state => state.termSearchConnection);
    const classSearchConnection = useSelector(state => state.classSearchConnection);
    const organizationSearchConnection = useSelector(state => state.organizationSearchConnection);

    useEffect(() => {
        dispatch(Actions.http.connection.term.search({ page_size: '50' }));
        dispatch(Actions.http.connection.organization.search({ page_size: 5000 }, undefined, ''));
    }, [dispatch]);

    useEffect(() => {
        setLoading(termSearchConnection.meta.fetch);
        setOptionalLoading(termSearchConnection.meta.fetch);
        const currentTermUuid = getCurrentTerm(list.termList).uuid;

        form.setFieldsValue({ term_uuid: currentTermUuid });
        if (currentTermUuid) {
            onSearch({ term_uuid: currentTermUuid });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [termSearchConnection]);

    useEffect(() => {
        if (organizationSearchConnection.meta.status !== Actions.statusEnum.SUCCESS) return;
        setList(list => ({
            ...list,
            organizationList: organizationSearchConnection.payload.result.items,
            organizationListItemCount: organizationSearchConnection.payload.result.item_count,
        }));
    }, [organizationSearchConnection, setList]);

    useEffect(() => {
        setLoading(classSearchConnection.meta.fetch);
    }, [classSearchConnection]);

    const onFinish = () => {
        onSearch({
            ...search,
            current_page: 1,
            organization_uuid: form.getFieldValue('organization_uuid'),
            term_uuid: form.getFieldValue('term_uuid'),
        });
    };

    const onReset = () => form.resetFields();

    return (
        <div className='card general-search notification-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-users-search' onFinish={onFinish}>
                <Form.Item label='学校' name='organization_uuid'>
                    <Select
                        allowClear
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder='学校を選択してください'
                        showSearch
                    >
                        {list.organizationList.map((value, index) => {
                            return <Option key={index} value={value.uuid}>{value.name}</Option>;
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label='年度' name='term_uuid'>
                    <Select
                        allowClear
                        disabled={optionalLoading}
                        loading={optionalLoading}
                        placeholder='年度を選択してください'
                    >
                        {list.termListItemCount && addAllTermAndSort(list.termList).map((value, index) => {
                            return <Option key={index} value={value.uuid}>{value.name}</Option>;
                        })}
                    </Select>
                </Form.Item>

                <div className='submit-container'>
                    <Button htmlType='button'
                        onClick={onReset}
                    >
                        リセット
                    </Button>
                    <Button htmlType='submit'
                        disabled={loading}
                        icon={<SearchOutlined />}
                        loading={loading}
                        type='primary'
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Search;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TablePaginationConfig } from 'antd';

import * as GlobalConfig from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import { Source, useUsageData } from './UsageDataProvider';
import { createActionLogDetailQuery } from './utils';


type Parameters = {
    pageSize: number,
    position: TablePaginationConfig['position'],
    total: number;
}

export const usePagination = ({ pageSize, position, total }: Parameters) => {
    const dispatch = useDispatch();
    const [source, setSource] = useUsageData();

    const dispatchUsageDetail = (source: Source, page: number, pageSize: number) => {
        setSource({
            ...source,
            pagination: {
                current: page,
                pageSize: pageSize,
            },
        });
        const detailQueries = createActionLogDetailQuery({
            hierarchy: source.hierarchy,
            page,
            pageSize,
            source,
        });
        dispatch(Actions.http.connection.usage.detail(detailQueries));
    };

    useEffect(() => {
        setPagination({
            ...pagination,
            onChange: (page, pageSize) => {
                dispatchUsageDetail(source, page, pageSize);
            },
            onShowSizeChange: (page, pageSize) => {
                dispatchUsageDetail(source, page, pageSize);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        onChange: (page, pageSize) => {
            dispatchUsageDetail(source, page, pageSize);
        },
        onShowSizeChange: (page, pageSize) => {
            dispatchUsageDetail(source, page, pageSize);
        },
        pageSize,
        pageSizeOptions: GlobalConfig.PageSizeOptions,
        position,
        showSizeChanger: true,
        showTotal: (total: number, range: [number, number]) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total,
    }
    );
    return [pagination, setPagination];
};

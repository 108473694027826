import classNames from 'classnames';
import {generateFileDomain} from 'constants/GlobalConfig'


const AttachedFileView = props => {
    const {className, fileName, fileUuid} = props;

    return (
        <table className={classNames(
            'w-full border border-gray-300',
            className,
        )}>
            <thead>
                <tr>
                    <td colSpan='2' className='w-full px-4 py-2 text-base border border-gray-300 bg-gray-100'>
                        ファイル
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='w-4/5 px-4 py-2 text-sm border border-gray-300 bg-white'>
                        {fileName}
                    </td>
                    <td className='w-1/5 px-4 py-2 text-sm text-center border border-gray-300 bg-white'>
                        <a
                            href={`${generateFileDomain()}/file/view/${fileUuid}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            ダウンロード
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default AttachedFileView;

import { useContext, useEffect, useState } from 'react';
import {
    Button,
    Row,
    Col,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusCircleFilled,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { ListContext, TabKeyEnum } from '../index';
import EditRole from './EditRole';
import { getMoment } from 'constants/GlobalConfig';
import CircleButton from 'components/modules/CircleButton';
import { BaseTable } from 'components/modules/BaseTable';
const moment = getMoment();


const RoleList = ({
    hasTouchScreen,
    tabActiveKey,
}) => {
    const [list,] = useContext(ListContext);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [shouldCreate, setShouldCreate] = useState(true);
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: list.roleListItemCount,
        pageSize: 20,
        position: ['topRight', 'bottomRight'],
        onChange: (page, pageSize) => {
            searchRole(page, pageSize);
        },
    });
    const dispatch = useDispatch();

    const roleSearchConnection = useAppSelector(state => state.roleSearchConnection);

    const searchRole = (page, pageSize) => {
        const newPage = page ?? pagination.current;
        const data = { page_size: pageSize ?? pagination.pageSize };

        setPagination(pagination => ({
            ...pagination,
            current: page,
            pageSize,
        }));

        dispatch(Actions.http.connection.role.search(data, newPage));
    };

    useEffect(() => {
        if (tabActiveKey !== TabKeyEnum.ROLES) return;
        searchRole(1, 20);
        // tabActiveKeyのみ監視したいため
    }, [tabActiveKey]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const newPagination = {
            ...pagination,
            total: list.roleListItemCount
        };
        setPagination(newPagination);
        //roleListのみ監視したいため
    }, [list.roleList, list.roleListItemCount])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (roleSearchConnection.meta.status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING:
                setLoading(true);
                break;
            case Actions.statusEnum.SUCCESS:
                setLoading(false);
                break;
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
                setLoading(false);
                break;
            default:
                break;
        }
    }, [roleSearchConnection]);

    const columns = [
        {
            title: '役割名',
            width: 40,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '権限',
            width: 40,
            dataIndex: 'permission',
            key: 'permission',
            render: (permission) => {
                return permission.name
            }
        },
        {
            title: '作成時間/更新時間',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 20,
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 10,
            fixed: 'right',
            render: (_, record) => (
                <Row type='flex' justify='space-around' align='middle'>
                    <Col>
                        <CircleButton
                            tooltipProps={{
                                title: hasTouchScreen
                                    ? undefined
                                    : !record.is_initial ? '編集' : '編集不可',
                            }}
                            className='mr-2'
                            disabled={record.is_initial}
                            size='small'
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(record)}
                        />
                    </Col>
                    <Col>
                        <CircleButton
                            tooltipProps={{
                                title: hasTouchScreen
                                    ? undefined
                                    : !record.is_initial ? '削除' : '削除不可',
                            }}
                            popconfirmProps={{
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: <span>役割を削除しますか？</span>,
                            }}
                            className='mr-2'
                            disabled={record.is_initial}
                            size='small'
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Col>
                </Row>
            ),
        },
    ];

    const handleCreateClick = () => {
        setRecord(null);
        setShouldCreate(true);
        setIsEditOpen(true);
    }

    const handleEditClick = (record) => {
        setRecord(record);
        setShouldCreate(false);
        setIsEditOpen(true);
    }

    const handleDeleteClick = (id) => {
        dispatch(Actions.http.connection.role.delete(id));
    }

    const handleEditSubmit = () => {
        setIsEditOpen(false);
        searchRole();
    }

    const handleEditCancel = () => setIsEditOpen(false);

    return (
        <div className='roles-list container'>
            <div className='flex-right-container gutter-bottom'>
                <Button
                    type='primary'
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                emptyDescription='役割はありません'
                columns={columns}
                dataSource={list.roleList}
                pagination={pagination}
                className='general-table'
                loading={loading}
            />

            <EditRole
                shouldCreate={shouldCreate}
                record={record}
                visible={isEditOpen}
                onOk={handleEditSubmit}
                onCancel={handleEditCancel}
            />
        </div>
    );
}

export default RoleList;

import {Space} from 'antd'
import PropTypes from 'prop-types';

/**
 * ボタンと詳細情報を表示するためのカードボディ。ボタンと詳細情報が無いと表示されない
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export const BackgroundJobCardBody = (props) => {

    if(!props.message && !props.buttons) return (<></>);

    return (
        <div className='card-body'>
            {props.message && (<div className={'message'}>詳細情報: {props.message}</div>)}
            <div className='button-container'>
                <Space align={'end'} direction={'horizontal'}>
                    {props.buttons}
                </Space>
            </div>
        </div>
    )
}

BackgroundJobCardBody.protoType = {
    message: PropTypes.object,
    buttons: PropTypes.arrayOf(PropTypes.object)
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Tag } from 'antd';
import 'styles/modules/test_status_modal.scss';
import {
    addAllTermAndSort,
    ALL_TERM_UUID,
    getCurrentTerm,
    getDefaultLayout,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;

const layout = getDefaultLayout();
const { Option } = Select;

interface Props {
    loading: boolean
    onSearch: (values: FormValues) => void
    values: FormValues
    visible: boolean
}

type FormValuesKeys = keyof Required<Omit<Http.Connection.Request.Parameter.App.GroupStatusSearch, 'only_ags_application'>>;
type FormValues = Record<FormValuesKeys, string | undefined>;

export const StatusSearch: React.VFC<Props> = ({
    loading,
    onSearch,
    values,
    visible,
}) => {
    const [form] = Form.useForm<FormValues>();
    const { termList } = useAppSelector(selectors.globalState)
    const [selectTerm, setSelectTerm] = useState('');
    const [classListMap, setClassListMap] = useState<Record<string, SchoolClass[]>>({});
    const dispatch = useDispatch();
    const classSearchConnection = useAppSelector(selectors.classSearchConnection);
    const roleSearchConnection = useAppSelector(selectors.roleSearchConnection);
    const roleList: Role[] = ('result' in roleSearchConnection.payload)
        && roleSearchConnection.payload?.result?.items
            ? roleSearchConnection.payload?.result?.items
            : [];

    useEffect(() => {// 受け取った値のセット
        form.setFieldsValue(values);
    }, [values]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 初期値の取得
        if(!visible) return;

        const currentTerm = getCurrentTerm(termList).uuid;
        handleSelectTerm(currentTerm);
        dispatch(Actions.http.connection.role.search(
            { page_size: 500 },
            undefined,
        ));
    }, [visible]);// eslint-disable-line

    useEffect(() => {// クラス一覧のセット
        if (!visible) return;
        const { meta, payload: { result } } = classSearchConnection;
        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setClassListMap(prev => {
                    return {
                        ...prev,
                        [selectTerm]: result.items,
                    };
                });
                break;
            default:
                break;
        }
    }, [classSearchConnection]);// eslint-disable-line

    const onReset = () => {
        form.resetFields();
        const currentTerm = getCurrentTerm(termList).uuid;
        setSelectTerm(currentTerm);
        form.setFieldsValue({ term_uuid: currentTerm });
    };

    const handleSelectTerm = (term_uuid: string) => {
        form.resetFields(['school_class_uuid']);
        form.setFieldsValue({ ...values, term_uuid: term_uuid });
        setSelectTerm(term_uuid);
        // すべての年度のとき or  取得済みの年度のクラスリストがあるとき はクラスを取得しない
        // if (term_uuid === ALL_TERM_UUID || classListMap[term_uuid]) return;
        dispatch(Actions.http.connection.classes.searchForListMap(
            { page_size: 500, term_uuid: term_uuid },
            undefined,
            term_uuid,
        ));
    };

    return (
        <div className='card general-search test-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-test-search' onFinish={onSearch}>
                <Form.Item label='クラス'>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item name='term_uuid' noStyle>
                                <Select
                                    placeholder='年度を選択してください'
                                    onChange={handleSelectTerm}
                                    disabled={loading}
                                    loading={loading}
                                >
                                    {addAllTermAndSort(termList).map((value, index) => {
                                        return <Option key={index} value={value.uuid}>{value.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='school_class_uuid' noStyle>
                                <Select
                                    placeholder='クラスを選択してください'
                                    disabled={loading || (selectTerm === ALL_TERM_UUID)}
                                    loading={loading}
                                >
                                    {selectTerm && classListMap[selectTerm]?.map((value, index) => {
                                        return (
                                            <Option key={index} value={value.uuid}>
                                                <Tag color='#AAAAAA' style={{ width: '90px', textAlign: 'center', borderRadius: '5px' }}>
                                                    {value.term.name}
                                                </Tag>
                                                {value.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item name='role_uuid' label='役割'>
                    <Select
                        placeholder='役割を選択してください'
                        disabled={loading}
                        loading={loading}
                    >
                        {roleList.map((value, index) => {
                            return (<Option value={value.uuid} key={index}>{value.name}</Option>)
                        })}
                    </Select>
                </Form.Item>
                <div className='submit-container'>
                    <Button
                        className='mr-4'
                        htmlType='button'
                        onClick={onReset}
                        loading={loading}
                        disabled={loading}
                    >
                        リセット
                    </Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                        disabled={loading}
                        icon={<SearchOutlined />}
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

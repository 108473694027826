import * as React from 'react';

import { Tabs } from 'antd';

import { useFeatures } from 'constants/CustomHooks/useFeatures';
import { useTabManagement } from 'constants/CustomHooks/useTabManagement';

import { default as ExamDeliveryContents, TABS as DELIVERY_TABS } from './ExamDeliveryContents';
import { default as ExamResultContents, TABS as RESULT_TABS } from './ExamResultContents';

const { TabPane } = Tabs;


const AdminContents: React.VFC = () => {
    const [tabKey, setTabKey] = useTabManagement(
        { ...DELIVERY_TABS, ...RESULT_TABS },
        DELIVERY_TABS.delivery_school
    );
    const { studylog: enabledStudyLog, isLoading: isFeatureLoading } = useFeatures();

    if (isFeatureLoading) return <></>;

    if (!enabledStudyLog) {
        return (
            <ExamDeliveryContents
                activeKey={tabKey === 'db' ? 'db' : 'ds'}
                onChangTab={setTabKey}
            />
        );
    }

    const localTab = Object.values<typeof tabKey>(DELIVERY_TABS).includes(tabKey) ?
        'delivery' :
        'result';

    const onChange = (activeKey: string) => {
        const tab = activeKey === 'delivery' ?
            DELIVERY_TABS.delivery_school :
            RESULT_TABS.result_school;
        setTabKey(tab);
    };

    return (
        <Tabs
            activeKey={localTab}
            onChange={onChange}
            type='card'
        >
            <TabPane
                key='delivery'
                tab='テスト配信'
            >
                <ExamDeliveryContents
                    activeKey={tabKey === 'db' ? 'db' : 'ds'}
                    onChangTab={setTabKey}
                />
            </TabPane>

            <TabPane
                key='result'
                tab='まなびログ'
            >
                <ExamResultContents
                    activeKey={tabKey}
                    onChangTab={setTabKey}
                />
            </TabPane>
        </Tabs>
    );
};

export default AdminContents;

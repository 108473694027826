import {
    BarChartOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
    FileAddOutlined,
    SortAscendingOutlined,
} from '@ant-design/icons';
import { Badge, Button, Col, Row, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/Table';

import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import { getMoment } from 'constants/GlobalConfig';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import { TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import { ExamTable } from './ExamTable';

import 'styles/pages/assessment.scss';

const moment = getMoment();

type Props = {
    dataSource: Admin.ExamCategory[] | Control.ExamCategory[]
    isLoading: boolean
    pagination: TablePaginationConfig
    onClickAddTest: (record: ExamCategory) => void
    onClickCopy: (record: ExamCategory) => void
    onClickDelete: (uuid: string) => void
    onClickDeleteTest: (uuid: string) => void
    onClickEdit: (record: ExamCategory) => void
    onClickStatus: (record: ExamCategory) => void
    onClickEditAppOrder: (record: ExamCategory) => void
    operable: boolean
    tabName: 'board' | 'school' | undefined
}

export const ExamCategoryTable: React.VFC<Props> = props => {
    const {
        dataSource,
        isLoading,
        pagination,
        onClickAddTest,
        onClickCopy,
        onClickDelete,
        onClickDeleteTest,
        onClickEdit,
        onClickStatus,
        onClickEditAppOrder,
        operable,
        tabName,
    } = props;


    const isControllableUser = useAppSelector(selectors.isControllableUser);
    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();

    const columns: ColumnsType<ExamCategory> = [
        {
            dataIndex: 'title',
            key: 'title',
            title: 'テストグループ名称',
            width: 200,
        },
        {
            dataIndex: 'is_required',
            key: 'is_required',
            render: (isRequired) => {
                if(tabName === TAB_NAMES.BOARD_OF_EDUCATION) {
                    return <Badge status='processing' text='一斉配信' />;
                }

                if (isRequired) {
                    return <Badge status='warning' text='必修' />;
                } else {
                    return <Badge status='success' text='自習' />;
                }
            },
            title: 'テスト種別',
            width: 90,
        },
        {
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                switch (status) {
                    case ('before'): {
                        return <Badge status='processing' text='解答期間前' />;
                    }
                    case ('ongoing'): {
                        return <Badge status='success' text='解答期間中' />;
                    }
                    case ('finished'): {
                        return <Badge status='default' text='解答期間終了' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            },
            title: '状態',
            width: 140,
        },
        {
            key: 'duration',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.publish_start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(record.publish_end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
            title: '解答期間',
            width: 190,
        },
        {
            dataIndex: 'result_exists',
            key: 'result_exists',
            render: (resultExists) => {
                if (resultExists) {
                    return <Badge status='processing' text='解答あり' />;
                } else {
                    return <Badge status='default' text='解答なし' />;
                }
            },
            title: '解答状態',
            width: 140,
        },
        {
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                return (
                    <>
                        <span>{record.created_by.display_name}</span>
                        <br />
                        <span>{record.updated_by.display_name}</span>
                    </>
                );
            },
            title: '作成者/更新者',
            width: 140,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: 170,
        },
        {
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (_, record) => (
                <Row align='middle' justify='space-around'>
                    <Col>
                        <Tooltip title={hasTouchScreen ? undefined : '編集'}>
                            <Button
                                className='mr-2'
                                disabled={!operable}
                                icon={<EditOutlined />}
                                onClick={() => onClickEdit(record)}
                                shape='circle'
                                size='small'
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title={ hasTouchScreen ? undefined : 'コピーして作成'}>
                            <Button
                                className='mr-2'
                                disabled={!operable}
                                icon={<FileAddOutlined />}
                                onClick={() => onClickCopy(record)}
                                shape='circle'
                                size='small'
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title={ hasTouchScreen ? undefined : `対象${isControllableUser ? '校' : '者'}と実施結果を確認`}>
                            <Button
                                className='mr-2'
                                icon={<BarChartOutlined />}
                                onClick={() => onClickStatus(record)}
                                shape='circle'
                                size='small'
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <CircleButton
                            className='mr-2'
                            danger
                            disabled={!operable}
                            icon={<DeleteOutlined/>}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                disabled: !operable,
                                okText: '削除',
                                onConfirm: () => onClickDelete(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>テストを削除すると配信は停止されます。実施結果も確認できません。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                ),
                            }}
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '削除' }}
                        />
                    </Col>
                </Row>
            ),
            title: '',
            width: 130,
        },
    ];

    const filteredColumns = columns.filter(column => !isControllableUser || column.key !== 'is_required');

    const expandedRowRender = (categoryRecord: ExamCategory) => {
        return (
            <div>
                <div className='flex-right-container gutter-bottom'>
                    <Button
                        className='mb-4'
                        disabled={!operable}
                        icon={<SortAscendingOutlined />}
                        onClick={() => onClickEditAppOrder(categoryRecord)}
                    >
                        並び替え
                    </Button>
                    <Button
                        disabled={!operable}
                        icon={<PlusCircleFilled />}
                        onClick={() => onClickAddTest(categoryRecord)}
                        type='primary'
                    >
                        テストを追加
                    </Button>
                </div>
                <ExamTable
                    dataSource={categoryRecord.exams}
                    hasTouchScreen={hasTouchScreen}
                    onClickDeleteTest={onClickDeleteTest}
                    operable={operable}
                />
            </div>
        );
    };

    return (
        <BaseTable<ExamCategory>
            columns={filteredColumns}
            dataSource={dataSource}
            emptyDescription='配信されているテストはありません'
            expandable={{ expandedRowRender }}
            loading={isLoading}
            pagination={pagination}
            scroll={{ x: 1500 }}
            size='small'
        />
    );
};
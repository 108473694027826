import {
    Tag,
} from 'antd';

const TermNameTag = ({termName}: {termName: JSX.Element}) => {
        return <Tag color="#AAAAAA"
            style={{
                width: "75px",
                textAlign: "center",
                borderRadius: "5px",
                marginRight: "5px",
            }}
        >
            {termName}
        </Tag>
}

export default TermNameTag;

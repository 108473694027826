import * as Actions from 'flex/Actions';

export const resultQuestionnaireSavingData = (store, next, action) => {
    const viewState = store.getState()['resultQuestionnaire'];
    const answerResultModal = store.getState()['answerResultModal'];

    switch (action.type) {
        case Actions.view.viewLogic.resultQuestionnaire.openDetail.toString():
            const item = action.payload.item
            next({
                ...viewState,
                modal: {
                    item: item,
                    isOpen: true,
                },
                type: 'resultQuestionnaire'
            })
            break
        case Actions.view.viewLogic.resultQuestionnaire.closeDetail.toString():
            next({
                ...viewState,
                modal: {
                    item: null,
                    isOpen: false,
                },
                type: 'resultQuestionnaire'
            })
            next({
                ...answerResultModal,
                resultList: [],
                type: 'answerResultModal',
            });
            break
        default:
            break;
    }

}

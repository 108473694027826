import { useState, useEffect } from 'react';
import * as React from 'react';

import {
    ArrowRightOutlined,
    CalendarOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import {
    Button,
    Input,
    Tooltip,
    TimePicker,
    Radio,
    Checkbox,
    Select,
    RadioChangeEvent,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import RichTextEditor from 'components/modules/RichTextEditor';
import Spin from 'components/modules/Spin';
import Targets from 'components/presentational/pages/Targets';
import {
    DatePickerRangesIncludeInfinite,
    defaultGradePreset,
    formRules,
    getDisabledDate,
    getMoment,
} from 'constants/GlobalConfig';
import { texts } from 'constants/texts';
import { useAppSelector } from 'flex/utils';

import QuestionModal from '../QuestionModal';
import QuestionnairePreviewModal from '../QuestionnairePreviewModal';
import QuestionsListForm from './QuestionsListForm';

const Form = BaseForm.ModalForm;
const moment = getMoment();
const { Option } = Select;

const layout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 24 },
        xl: { span: 7 },
        xxl: { span: 6 },
    },
    wrapperCol: {
        span: 24,
    },
};

// CustomRangePicker における range attribute の型に合うように型を調整する.
const rangePickerRanges = DatePickerRangesIncludeInfinite() as unknown as Record<string, [moment.Moment, moment.Moment]>;

// TODO: ここの型定義はあまりよくないので修正する。
const INIT_FORM_VALUE: EverydayNote.Form.Control.Questionnaire & EverydayNote.Form.Admin.AdminQuestionnaire = {
    afterMessage: '',
    allowPastDateAnswer: false,
    canAdminSetPublic: true,
    description: '',
    duration: [
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        undefined,
    ],
    isDesignatedTerm: false,
    isOnceADay: false,
    isOverwritePublish: [false],
    isRequired: false,
    isSetRecordableTime: false,
    isViewedHome: true,
    items: [],
    name: '',
    publishFrom: '',
    publishToAll: true,
    recordableDayOfWeeks: [0, 1, 2, 3, 4, 5, 6],
    recordableTimeEnd: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
    recordableTimeStart: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    targetGrades: [],
    targetOrganizations: [],
    targetPermissions: ['administrator', 'teacher', 'student'],
    targetRoles: [],
    targetSchoolClasses: [],
    targetUsers: [],
    type: 1,
};

type Props = {
    isAnswered?: boolean
    isCopy?: boolean
    isEditAdmin?: boolean
    loading: boolean
    /** キャンセルボタンをクリックしたときに実行される関数 */
    onCancel: () => void
    /** OK ボタンをクリックしたときに実行される関数 */
    onOk: (values: EverydayNote.FormValue) => void
    questionnaire: EverydayNote.FormValue | undefined
    tabName?: 'board' | 'school'
    visible: boolean
}

const QuestionnaireModal: React.VFC<Props> = ({
    isAnswered = false,
    isCopy = false,
    isEditAdmin = false,
    loading,
    onCancel,
    onOk,
    questionnaire,
    tabName = 'board',
    visible,
}) => {
    const [form] = Form.useForm<EverydayNote.FormValue>();
    const [editQuestion, setEditQuestion] = useState<EverydayNote.Form.Question>();
    const [isCopyQuestion, setIsCopyQuestion] = useState(false);
    const [isOpenQuestion, setIsOpenQuestion] = useState(false);
    const [isOpenTarget, setIsOpenTarget] = useState(false);
    const [isOpenPreview, setIsOpenPreview] = useState(false);

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const termList = useAppSelector(state => state.globalState.termList);

    const isCreate = !questionnaire;
    const isUpdate = !isCreate && !isCopy;

    const canAdminSetPublic = Form.useWatch('canAdminSetPublic', form) ?? INIT_FORM_VALUE.canAdminSetPublic;
    const targetOrganizations = Form.useWatch('targetOrganizations', form) ?? []; //INIT_FORM_VALUE.targetOrganizations;
    const targetRoles = Form.useWatch('targetRoles', form) ?? INIT_FORM_VALUE.targetRoles;
    const targetUsers = Form.useWatch('targetUsers', form) ?? INIT_FORM_VALUE.targetUsers;
    const targetSchoolClasses = Form.useWatch('targetSchoolClasses', form) ?? INIT_FORM_VALUE.targetSchoolClasses;
    const isDesignatedTerm = Form.useWatch('isDesignatedTerm', form) ?? INIT_FORM_VALUE.isDesignatedTerm;
    const publishToAll = Form.useWatch('publishToAll', form) ?? INIT_FORM_VALUE.publishToAll;
    const isSetRecordableTime = Form.useWatch('isSetRecordableTime', form) ?? INIT_FORM_VALUE.isSetRecordableTime;

    // Targets コンポーネントがスネークケースを使っているので, 一部スネークケースを使っている.
    const target = isControllableUser ?
        { target_organizations: targetOrganizations } :
        {
            target_roles: targetRoles,
            target_school_classes: targetSchoolClasses,
            target_users: targetUsers,
        };
    const questionsCount = (Form.useWatch('items', form) ?? []).length;

    // 学校管理者: 対象クラス選択&対象ユーザ選択, 全体管理者:対象学校選択のみ
    const targetShowTabProps = isControllableUser ?
        {
            showClass: false,
            showOrganization: true,
            showRole: false,
            showUser: false,
        } :
        {
            showClass: true,
            showOrganization: false,
            showRole: true,
            showUser: true,
        };

    const convertCopyQuestionnaire = (
        questionnaire: EverydayNote.FormValue | undefined,
        isCopy: boolean
    ) => ({
        ...questionnaire,
        name: isCopy ? `${questionnaire?.name}のコピー` : questionnaire?.name,
    });

    const handlePreviewOpen = () => setIsOpenPreview(true);

    const handleSubmit = form.submit;

    const handleTargetCancel = () => setIsOpenTarget(false);

    const handleTargetOpen = () => setIsOpenTarget(true);

    const handleTargets = () => setIsOpenTarget(true);

    type ControllableTargets = { target_organizations?: EverydayNote.Form.Control.Questionnaire['targetOrganizations'] };
    type NonControllableTargets = {
        target_school_classes?: EverydayNote.Form.Admin.AdminQuestionnaire['targetSchoolClasses']
        target_users?: EverydayNote.Form.Admin.AdminQuestionnaire['targetUsers']
        target_roles?: EverydayNote.Form.Admin.AdminQuestionnaire['targetRoles']
    };
    const handleTargetSubmit = (target: ControllableTargets | NonControllableTargets) => {
        // Targets コンポーネントがスネークケースを使っているので, 一部スネークケースを使っている.

        if (isControllableUser) {
            const typedTarget = target as ControllableTargets;

            form.setFieldsValue({
                targetOrganizations: typedTarget.target_organizations,
            });
        } else {
            const typedTarget = target as NonControllableTargets;

            form.setFieldsValue({
                targetRoles: typedTarget.target_roles ?? [],
                targetSchoolClasses: typedTarget.target_school_classes ?? [],
                targetUsers: typedTarget.target_users ?? [],
            });
        }
        setIsOpenTarget(false);
    };

    const handlePreviewClose = () => setIsOpenPreview(false);

    const handleQuestionCopy = (question: EverydayNote.Form.Question) => {
        const {
            candidates,
            content,
            // 削除
            uuid: _uuid,
            order: _order,
            ...formValue
        } = question;

        const newCandidates = candidates.map(candidate => {
            // uuid の削除
            const { uuid: _, ...rest } = candidate;

            return rest;
        });

        const regex = /^\d+?\./;
        const newContent = content.replace(regex, `${questionsCount + 1}.`);

        const newQuestion = {
            ...formValue,
            candidates: newCandidates,
            content: newContent,
        };

        setEditQuestion(newQuestion);
        setIsCopyQuestion(true);
    };

    const handleQuestionCancel = () => {
        setIsCopyQuestion(false);
        setIsOpenQuestion(false);
        setEditQuestion(undefined);
    };

    const handleQuestionDelete = (question: EverydayNote.Form.Question) => {
        const items = form.getFieldsValue().items;

        const regex = /^\d+?\./;
        const newItems = items
            .filter(item => item.order !== question.order)
            .map((item, newOrder) => ({
                ...item,
                content: item.content.replace(regex, `${newOrder + 1}.`),
                order: newOrder,
            }));

        form.setFieldsValue({ items: newItems.filter(e => e) });
    };

    const handleQuestionOpen = () => setIsOpenQuestion(true);

    const handleQuestionSubmit = (newQuestion: EverydayNote.Form.Question) => {
        const items = form.getFieldsValue().items;
        const index = items.findIndex(item => item.order === newQuestion.order);
        const newItems = index >= 0 ?
            items.reduce((prev, current, idx) => [...prev, idx === index ? newQuestion : current], [] as EverydayNote.Form.Question[]) :
            items.concat([newQuestion]);

        form.setFieldsValue({ items: newItems.filter(e => e) });
        setIsCopyQuestion(false);
        setIsOpenQuestion(false);
        setEditQuestion(undefined);
    };

    const handleChangeIsDesignatedTerm = (e: RadioChangeEvent) => {
        if (e.target.value) return;

        form.setFieldsValue({ targetTerm: undefined });
    };

    useEffect(() => {
        // モーダル表示直後に初期化
        if (!visible) {// escで閉じた場合プレビューのisOpenがfalseにならないためfalseにしてあげる
            setIsOpenPreview(false);
            return;
        };

        form.resetFields();

        const fieldsValue = isControllableUser ?
            { ...INIT_FORM_VALUE, isOverwritePublish: false } :
            INIT_FORM_VALUE;

        form.setFieldsValue(fieldsValue);
        // visibleのみ監視
    }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // 編集時に、編集対象のアンケートをフォームにセット
        if (!visible || loading || isCreate) return;

        const baseFieldsValue = convertCopyQuestionnaire(questionnaire, isCopy);

        const fieldsValue = isControllableUser ?
            { ...baseFieldsValue, isOverwritePublish: false } :
            baseFieldsValue;

        form.setFieldsValue(fieldsValue);

        // questionnaireのみ監視
    }, [questionnaire]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // 公開対象学年コード実装前の毎日の記録は、公開対象学年コードが空になっているので、
        // 編集時にすべてのコードを初期値としてセットする
        if (!visible || loading || isCreate || !isAnswered) return;

        const targetGrades = form.getFieldValue('targetGrades');

        if (targetGrades !== undefined && targetGrades.length > 0) return;

        form.setFieldsValue({
            targetGrades: defaultGradePreset.map(value => value.code),
        });

    }, [questionnaire]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!editQuestion) return;
        setIsOpenQuestion(true);
    }, [editQuestion]);

    useEffect(() => {
        if (!isUpdate || !isControllableUser) return;
        form.setFieldsValue({
            isOverwritePublish: [!canAdminSetPublic],
        });
    }, [canAdminSetPublic]);// eslint-disable-line react-hooks/exhaustive-deps

    const recordableDayOfWeeks = [
        { label: '月', value: 1 },
        { label: '火', value: 2 },
        { label: '水', value: 3 },
        { label: '木', value: 4 },
        { label: '金', value: 5 },
        { label: '土', value: 6 },
        { label: '日', value: 0 },
    ];

    const titlePrase = isCreate ?
        '毎日の記録の新規作成' :
        isCopy ?
            '毎日の記録をコピーして作成' :
            '毎日の記録の編集';

    return (
        <BaseModal
            className='common-modal everyday-note-modal questionnaire-modal'
            footer={[
                <Button
                    disabled={loading}
                    key='cancel'
                    loading={loading}
                    onClick={onCancel}
                    size='large'
                >
                    キャンセル
                </Button>,
                <Button
                    disabled={loading}
                    key='preview'
                    loading={loading}
                    onClick={handlePreviewOpen}
                    size='large'
                >
                    プレビュー
                </Button>,
                <Button
                    disabled={loading}
                    key='submit'
                    loading={loading}
                    onClick={handleSubmit}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            onCancel={onCancel}
            title={
                <span>
                    <CalendarOutlined className='icon-r' />
                    {titlePrase}
                </span>
            }
            visible={visible}
        >
            <Spin spinning={loading} tip='しばらくお待ち下さい...'>
                <Form {...layout} form={form} onFinish={onOk}>
                    <Form.Item hidden name='uuid'><input /></Form.Item>

                    <Form.Item
                        label='タイトル'
                        name='name'
                        rules={[
                            formRules.required({ label: 'タイトル' }),
                            formRules.range({ max: 64 }),
                        ]}
                    >
                        <Input
                            disabled={isEditAdmin}
                            placeholder='タイトルを入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                発信元
                                <Tooltip title='発信元を入力してください。発信元は毎日の記録回答画面に表示されます。'>
                                    <QuestionCircleOutlined className='icon-l' />
                                </Tooltip>
                            </span>
                        }
                        name='publishFrom'
                        rules={[
                            formRules.required({ label: '発信元' }),
                            formRules.range({ max: 128 }),
                        ]}
                    >
                        <Input
                            disabled={isEditAdmin}
                            placeholder='発信元を入力してください'
                        />
                    </Form.Item>

                    <Form.Item
                        label='回答後メッセージ'
                        name='afterMessage'
                        rules={[
                            formRules.required({ label: '回答後メッセージ' }),
                        ]}
                    >
                        <RichTextEditor
                            config={{
                                charCounterMax: 10000,
                                placeholderText: '回答後メッセージを入力してください',
                            }}
                            disabled={isEditAdmin}
                            tag='textarea'
                        />
                    </Form.Item>

                    <Form.Item
                        label='回答を必須にする'
                        name='isRequired'
                    >
                        <Radio.Group disabled={isEditAdmin}>
                            <Radio value={true}>必須にする</Radio>
                            <Radio value={false}>必須にしない</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label='ホーム画面に表示する' name='isViewedHome'>
                        <Radio.Group disabled={isEditAdmin}>
                            <Radio value={true}>表示</Radio>
                            <Radio value={false}>非表示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label='日毎の複数回答' name='isOnceADay'>
                        <Radio.Group disabled={isAnswered || isEditAdmin}>
                            <Radio value={false}>許容する</Radio>
                            <Radio value={true}>許容しない</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label='過去の日付の回答許可' name='allowPastDateAnswer'>
                        <Radio.Group disabled={isEditAdmin}>
                            <Radio value={true}>許容する</Radio>
                            <Radio value={false}>許容しない</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label='実施期間'
                        name='duration'
                        rules={[formRules.requiredDuration({ label: '実施期間' })]}
                    >
                        <CustomRangePicker
                            disabledDate={getDisabledDate}
                            form={form}
                            format='YYYY/MM/DD HH:mm'
                            locale={jaJP}
                            name='duration'
                            ranges={rangePickerRanges}
                            showTime={{ format: 'HH:mm' }}
                        />
                    </Form.Item>

                    {isControllableUser && (
                        <Form.Item
                            label={
                                <span>
                                    学校管理画面での実施期間変更
                                    <Tooltip title='配信先の学校で、学校管理者が実施期間を任意に変更できるかどうかを設定できます'>
                                        <QuestionCircleOutlined className='icon-l' />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Form.Item
                                name='canAdminSetPublic'
                                rules={[formRules.required({ isSelect: true, label: '学校管理者での実施' })]}
                                style={{ display: 'inline-block', marginBottom: 0, marginRight: '1rem' }}
                            >
                                <Radio.Group>
                                    <Radio value={false}>許可しない</Radio>
                                    <Radio value={true}>許可する</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {(isUpdate && isControllableUser) && (
                                <Form.Item
                                    name='isOverwritePublish'
                                    style={{ display: 'inline-block', marginBottom: 0 }}
                                >
                                    <Checkbox.Group disabled={!canAdminSetPublic}>
                                        <Checkbox value={true}>
                                            既存の実施期間を上書きする

                                            <Tooltip title='「許可しない」から「許可する」に変更した際は、この選択に関わらず学校管理画面での実施期間も変更されます。'>
                                                <QuestionCircleOutlined className='icon-l' />
                                            </Tooltip>
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            )}
                        </Form.Item>
                    )}

                    <Form.Item
                        label='記録可能な時間の指定'
                        name='isSetRecordableTime'
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>する</Radio>
                            <Radio value={false}>しない</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label='記録可能な時間'
                        style={{
                            // style を指定してると hidden が効かない?
                            display: isSetRecordableTime ? 'flex' : 'none',
                            flexDirection: 'row',
                        }}
                    >
                        <Form.Item
                            hidden={!isSetRecordableTime}
                            name='recordableTimeStart'
                            noStyle
                            rules={[
                                {
                                    message: '開始時間と終了時間を入力してください',
                                    required: true,
                                },
                                {
                                    message: '開始時間は終了時間より前に設定してください',
                                    validator: (_, value) => {
                                        const end = form.getFieldsValue().recordableTimeEnd;
                                        if (value && end && value.isBefore(end)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject('開始時間は終了時間より前に設定してください');
                                        }
                                    }
                                }
                            ]}
                        >
                            <TimePicker
                                format='HH:mm'
                                style={{ marginRight: '1rem' }}
                                disabledTime={() => {
                                    const time = form.getFieldsValue().recordableTimeEnd;
                                    const hour = time?.hour() ?? 24;
                                    const minute = time?.minute() ?? 60;

                                    return {
                                        // 時間は同じ時間を許容するので等号をつけない
                                        disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter(h => hour < h),
                                        disabledMinutes: (selectedHour) => Array.from({ length: 60 }, (_, i) => i).filter(m => selectedHour === hour && minute <= m),
                                    };
                                }}
                            />
                        </Form.Item>
                        <ArrowRightOutlined style={{ marginRight: '1rem' }} />
                        <Form.Item
                            hidden={!isSetRecordableTime}
                            name='recordableTimeEnd'
                            noStyle
                            rules={[
                                {
                                    message: '開始時間と終了時間を入力してください',
                                    required: true,
                                },
                                {
                                    message: '終了時間は開始時間より後に設定してください',
                                    validator: (_, value) => {
                                        const start = form.getFieldsValue().recordableTimeStart;
                                        if (value && start && value.isAfter(start)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject('終了時間は開始時間より後に設定してください');
                                        }
                                    }
                                }
                            ]}
                        >
                            <TimePicker
                                format='HH:mm'
                                style={{ marginRight: '1rem' }}
                                disabledTime={() => {
                                    const time = form.getFieldsValue().recordableTimeStart;
                                    const hour = time?.hour() ?? 0;
                                    const minute = time?.minute() ?? 0;

                                    return {
                                        // 時間は同じ時間を許容するので等号をつけない
                                        disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter(h => h < hour),
                                        disabledMinutes: (selectedHour) => Array.from({ length: 60 }, (_, i) => i).filter(m => selectedHour === hour && m <= minute),
                                    };
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            hidden={!isSetRecordableTime}
                            name='recordableDayOfWeeks'
                            noStyle
                        >
                            <Checkbox.Group
                                options={recordableDayOfWeeks}
                            />
                        </Form.Item>
                    </Form.Item>

                    {(isControllableUser || tabName === 'board') && (
                        <Form.Item label='配信対象学校' name='publishToAll'>
                            <Radio.Group disabled={isEditAdmin}>
                                <Radio value={true}>すべて</Radio>
                                <Radio value={false}>個別選択</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    {(isControllableUser || tabName === 'board') && (
                        <Form.Item
                            hidden={publishToAll}
                            label={<span>配信対象学校</span>}
                            name='targetOrganizations'
                            rules={(!publishToAll && !isEditAdmin) ?
                                [
                                    { message: '', required: true },
                                    formRules.targetValidator('配信対象学校を選択してください', 'targetOrganizations'),
                                ] :
                                []
                            }
                        >
                            <TargetFormItem
                                disabled={isEditAdmin}
                                onClick={handleTargetOpen}
                            />
                        </Form.Item>
                    )}

                    {isControllableUser && (
                        <Form.Item
                            label={texts.targetPermissionsTitle}
                            name='targetPermissions'
                            rules={[{ required: false }]}
                        >
                            <Checkbox.Group disabled={isAnswered || isEditAdmin} style={{ width: '100%' }}>
                                <Checkbox value='administrator'>{texts.targetPermissionsCheckbox.administrator}</Checkbox>
                                <Checkbox value='teacher'>{texts.targetPermissionsCheckbox.teacher}</Checkbox>
                                <Checkbox value='student'>{texts.targetPermissionsCheckbox.student}</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    )}


                    {isControllableUser && (
                        <Form.Item
                            label='公開対象学年コード'
                            name='targetGrades'
                            rules={[
                                formRules.required({ isSelect: true, label: '公開対象学年コード' }),
                                formRules.targetGradesValidator,
                            ]}
                        >
                            <Select
                                allowClear
                                disabled={isAnswered}
                                mode='multiple'
                                onChange={() => { form.validateFields(['target_grades']); }}
                                placeholder='学年コードを選択してください'
                                showSearch={false}
                            >
                                {defaultGradePreset.map((value, index) => (
                                    <Option
                                        key={index}
                                        value={value.code}
                                    >
                                        {value.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {isControllableUser && (
                        <Form.Item label='年度指定' name='isDesignatedTerm'>
                            <Radio.Group onChange={handleChangeIsDesignatedTerm}>
                                <Radio value={true}>あり</Radio>
                                <Radio value={false}>なし</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    {isControllableUser && (
                        <Form.Item
                            hidden={!isDesignatedTerm}
                            label='年度選択'
                            name='targetTerm'
                            rules={isDesignatedTerm ?
                                [formRules.required({ isSelect: true, label: '年度' })] :
                                []
                            }
                        >
                            <Select
                                allowClear
                                placeholder='年度を選択してください'
                                showSearch={false}
                            >
                                {termList.map((value, index) => (
                                    <Option key={index} value={value.uuid}>
                                        {value.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {(!isControllableUser && tabName === 'school') && (
                        <Form.Item
                            label={<span>配信対象</span>}
                            name='targets'
                            required
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator() {
                                        const targetRoles = getFieldValue('targetRoles');
                                        const targetSchoolClasses = getFieldValue('targetSchoolClasses');
                                        const targetUsers = getFieldValue('targetUsers');

                                        if (targetRoles.length > 0 || targetSchoolClasses.length > 0 || targetUsers.length > 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('配信対象を選択してください'));
                                    },
                                }),
                            ]}
                        >
                            <TargetFormItem
                                disabled={isAnswered || isEditAdmin}
                                onClick={handleTargets}
                                targets={{
                                    target_roles: targetRoles,
                                    target_school_classes: targetSchoolClasses,
                                    target_users: targetUsers,
                                }}
                            />
                        </Form.Item>
                    )}

                    {(!isControllableUser && tabName === 'school') && (
                        <>
                            <Form.Item hidden name='targetRoles'><input /></Form.Item>
                            <Form.Item hidden name='targetSchoolClasses'><input /></Form.Item>
                            <Form.Item hidden name='targetUsers'><input /></Form.Item>
                        </>
                    )}

                    <Form.Item
                        name='items'
                        rules={[{ message: '設問を追加してください', required: true }]}
                        wrapperCol={{ xl: { offset: layout.labelCol.xl.span } }}
                    >
                        <QuestionsListForm
                            disabled={isEditAdmin}
                            isAnswered={isAnswered}
                            name='items'
                            onAddQuestion={handleQuestionOpen}
                            onCopy={handleQuestionCopy}
                            onDelete={handleQuestionDelete}
                            onEdit={setEditQuestion}
                            questionsCount={questionsCount}
                        />
                    </Form.Item>
                </Form>

                <Targets
                    {...targetShowTabProps}
                    currentTarget={target}
                    onCancel={handleTargetCancel}
                    onSubmit={handleTargetSubmit}
                    visible={isOpenTarget}
                />

                <QuestionModal
                    isCopy={isCopyQuestion}
                    onCancel={handleQuestionCancel}
                    onOk={handleQuestionSubmit}
                    question={editQuestion}
                    questionsCount={questionsCount}
                    visible={isOpenQuestion}
                />
            </Spin>
            <QuestionnairePreviewModal
                isOpen={isOpenPreview}
                note={form.getFieldsValue()}
                onClose={handlePreviewClose}
            />
        </BaseModal>
    );
};

export default QuestionnaireModal;


type TargetFormItemProps = {
    disabled: boolean
    onClick: () => void
    /** antd 用 */
    value?: Organization[]
    // Admin の場合、複数の要素(targetRoles と targetUsers など)を組み合わせる必要があり、
    // value だけでは不十分なため、targets を受け取るようにする
    targets?: React.ComponentProps<typeof Targets.Detail>['currentTarget']
}

const TargetFormItem: React.VFC<TargetFormItemProps> = ({
    disabled,
    onClick,
    value,
    targets: propsTargets,
}) => {
    // form の値を TargetDetails に適する形に変更する
    const targets = propsTargets === undefined ?
        {
            target_organizations: value ?? [],
        } :
        {
            target_roles: propsTargets.target_roles ?? [],
            target_school_classes: propsTargets.target_school_classes ?? [],
            target_users: propsTargets.target_users ?? [],
        };

    return (
        <>
            <Button
                disabled={disabled}
                icon={<TeamOutlined />}
                onClick={onClick}
                type='primary'
            >
                配信対象を選択
            </Button>

            <Targets.Detail currentTarget={targets} />
        </>
    );
};

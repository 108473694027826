import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import QuestionItemCard from './QuestionItemCard';
import Ruby from '../Ruby';
import { Select } from '../Select';

export const PullDown = props => {
    const {
        candidates = [],
        content = '',
        detail = '',
        file = [],
        isRequired = false,
        name = '',
        disabled = false,
    } = props;
    const { errors, register, unregister, watch, setValue } = useFormContext();

    useEffect(() => {
        const validate = {};
        if (isRequired) {
            validate.required = true;
        }
        register(name, validate);
        return () => unregister(name);
    }, [register, unregister, name, isRequired]);

    let errorMessage = '';
    if (errors[name]?.message !== undefined) {
        switch (errors[name].type) {
            case 'required':
                errorMessage = <div><Ruby>{'{回答|かいとう}が{必要|ひつよう}です。'}</Ruby></div>;
                break;
            default:
                errorMessage = <div>{errors[name].message}</div>;
                break;
        }
    }

    return (
        <QuestionItemCard
            title={content}
            detail={detail}
            error={errorMessage}
            file={file}
            type={<Ruby>{'{単一回答|たんいつかいとう}'}</Ruby>}
            isRequired={isRequired}
            watch={watch}
        >
            <div className='grid grid-cols-2 gap-4'>
                <Select
                    disabled={disabled}
                    name={name}
                    ref={register}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                >
                    {candidates.map((candidate, index) => {
                        return (
                            <option
                                key={`pulldown-${index}`}
                                value={candidate?.uuid}
                            >
                                {candidate.content}
                            </option>
                        );
                    })}
                </Select>
            </div>
        </QuestionItemCard>
    );
};

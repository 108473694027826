import { combineActions, handleAction } from 'redux-actions';
import * as Actions from 'flex/Actions';

const defaultState = {
    payload: {},
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
};

const defaultReducer = {
    next: (state: any, action: any) => {
        return { ...action };
    },
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const questionnaireSearchConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.search,
        Actions.http.connection.questionnaires.controlSearch,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireEditConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.create,
        Actions.http.connection.questionnaires.update,
        Actions.http.connection.questionnaires.controlCreate,
        Actions.http.connection.questionnaires.controlUpdate,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireDeleteConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.delete,
        Actions.http.connection.questionnaires.controlDelete,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireViewConnection = handleAction(
    Actions.http.connection.questionnaires.view,
    defaultReducer,
    defaultState
);

export const questionnaireControlViewConnection = handleAction(
    Actions.http.connection.questionnaires.controlView,
    defaultReducer,
    defaultState
);

export const questionnaireAnswerResultConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.controlAnswerResult,
        Actions.http.connection.questionnaires.answerResult,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireTextFileResultConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.controlTextResult,
        Actions.http.connection.questionnaires.textResult,
        Actions.http.connection.questionnaires.controlFileResult,
        Actions.http.connection.questionnaires.fileResult,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireTargetConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.controlTarget,
        Actions.http.connection.questionnaires.target,
    ),
    defaultReducer,
    defaultState
);

export const questionnaireExportConnection = handleAction(
    combineActions(
        Actions.http.connection.questionnaires.controlUserResultCsv,
        Actions.http.connection.questionnaires.userResultCsv,
        Actions.http.connection.questionnaires.controlDownloadCsv,
        Actions.http.connection.questionnaires.downloadCsv
    ),
    defaultReducer,
    defaultState
);

export const questionnaireAnswerResultByItemConnection = handleAction(
    Actions.http.connection.questionnaires.answerResultByItem,
    defaultReducer,
    defaultState
);

export const questionnaireControlAnswerResultByItemConnection = handleAction(
    Actions.http.connection.questionnaires.controlAnswerResultByItem,
    defaultReducer,
    defaultState
);

export const questionnaireControlTargetOrganizationConnection = handleAction(
    Actions.http.connection.questionnaires.controlTargetOrganization,
    defaultReducer,
    defaultState
);

export const questionnaireControlTargetSchoolClassConnection = handleAction(
    Actions.http.connection.questionnaires.controlTargetSchoolClass,
    defaultReducer,
    defaultState
);

export const questionnaireTargetSchoolClassConnection = handleAction(
    Actions.http.connection.questionnaires.targetSchoolClass,
    defaultReducer,
    defaultState
);
import { Badge, Tag, Tooltip } from 'antd';

export const createColumns = () => {
    return [
        {
            dataIndex: 'login_id',
            key: 'login_id',
            render: (login_id, record) => {
                return (
                    <Tooltip mouseEnterDelay={1} title={`UUID:${record.uuid}`}>
                        {login_id}
                    </Tooltip>
                );
            },
            title: 'ユーザーID',
            width: 20,
        },
        {
            children: [
                {
                    dataIndex: 'belongs',
                    key: 'class',
                    render: (row) => {
                        return row.map((val, index) => (
                            <div key={`${val.school_class.name}-${index}`}>
                                <Tag
                                    color='#AAAAAA'
                                    style={{
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                        width: '75px',
                                    }}
                                >
                                    {val.school_class.term.name}
                                </Tag>
                                {val.school_class.name}
                            </div>
                        )
                        );
                    },
                    title: 'クラス名',
                    width: 20,
                },
                {
                    dataIndex: 'belongs',
                    key: 'roles',
                    render: (row) => {
                        return row.map((val, index) => (
                            <div key={`roles-${index}`}>{val.role.name}</div>
                        ));
                    },
                    title: '役割',
                    width: 20,
                },
                {
                    dataIndex: 'belongs',
                    key: 'num',
                    render: (row) => {
                        return row.map((val, index) => (
                            <div key={`school_class_num-${index}`}>{val.school_class.number}</div>
                        ));
                    },
                    title: '出席番号',
                    width: 15,
                },
            ],
            title: 'クラス',
        },
        {
            children: [
                {
                    dataIndex: 'last_name',
                    key: 'last_name',
                    title: '姓',
                    width: 30,
                },
                {
                    dataIndex: 'last_name_kana',
                    key: 'last_name_kana',
                    title: '姓（ふりがな）',
                    width: 30,
                },
                {
                    dataIndex: 'first_name',
                    key: 'first_name',
                    title: '名',
                    width: 30,
                },
                {
                    dataIndex: 'first_name_kana',
                    key: 'first_name_kana',
                    title: '名（ふりがな）',
                    width: 30,
                },
            ],
            title: '名前',
        },
        {
            dataIndex: 'is_answered',
            key: 'is_answered',
            render: (is_answered) => {
                if (is_answered === true) {
                    return <Badge status='success' text='回答済' />;
                }
                return <Badge status='default' text='未回答' />;
            },
            style: 'tag',
            title: '回答状態',
            width: '25px',
        },
    ];
};
import * as Actions from 'flex/Actions';

/***
 * action.typeに VIEW_LOGIC/USER_MANAGEMENT_TAB を含むactionをSavingData middlewareで処理する際に呼び出す
 * @param store
 * @param next
 * @param action
 */
export const questionnaireCategoryTabSavingData = (store, next, action) => {
    const tabState = store.getState()['questionnaireCategoryTab'];
    const search = tabState['search'];
    const pagination = tabState['pagination'];

    switch (action.type) {
        case Actions.view.viewLogic.questionnaireCategoryTab.submitSearch.toString():
            next({
                ...tabState,
                search: {
                    ...search,
                    ...action.payload.values,
                },
                type: 'questionnaireCategoryTab'
            })
            break
        case Actions.view.viewLogic.questionnaireCategoryTab.savePage.toString():
            next({
                ...tabState,
                pagination: {
                    ...pagination,
                    pageSize: action.payload.page_size,
                    current: action.payload.page,
                },
                type: 'questionnaireCategoryTab'
            })
            break
        case Actions.view.viewLogic.questionnaireCategoryTab.openEdit.toString():
            next({
                ...tabState,
                editQuestionnaireCategory: {
                    record: action.payload.record,
                    isEditOpen: true,
                    shouldCreate: false,
                },
                type: 'questionnaireCategoryTab'
            })
            break
        case Actions.view.viewLogic.questionnaireCategoryTab.openCreate.toString():
            next({
                ...tabState,
                editQuestionnaireCategory: {
                    record: null,
                    isEditOpen: true,
                    shouldCreate: true,
                },
                type: 'questionnaireCategoryTab'
            })
            break
        case Actions.view.viewLogic.questionnaireCategoryTab.cancelEdit.toString():
            next({
                ...tabState,
                editQuestionnaireCategory: {
                    userRecord: null,
                    isEditOpen: false,
                    shouldCreate: false,
                },
                type: 'questionnaireCategoryTab'
            })
            break
        case Actions.view.viewLogic.questionnaireCategoryTab.submitEdit.toString():
            next({
                ...tabState,
                editQuestionnaireCategory: {
                    userRecord: null,
                    isEditOpen: false,
                    shouldCreate: false,
                },
                type: 'questionnaireCategoryTab'
            })
            break;
        default:
            break;
    }

}

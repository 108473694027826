import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { BaseTable } from 'components/modules/BaseTable';
import TermNameTag from 'components/modules/TermNameTag';
import * as Actions from 'flex/Actions';

import { useUsageData } from '../Common/UsageDataProvider';
import { createTimeKeys, createActionLogTotalQuery } from '../Common/utils';
import TotalBreadcrumb from './TotalBreadcrumb';

const { Text } = Typography;

const TotalTable = props => {
    const { isAllLinkDisabled } = props;
    const [source, setSource] = useUsageData();
    // tableLoading の更新用
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(()=>{
        setLoading(source.total.loading);
    }, [source.total.loading]);

    const tableLoading = {
        indicator:<LoadingOutlined style={{ fontSize: '35px' }}/>,
        spinning: loading,
    };

    const onClick = (uuid, name) => () => {
        const hierarchy = source.hierarchy.slice(0);
        hierarchy.push({ name, uuid });
        setSource(source => ({ ...source, hierarchy }));
        const queries = createActionLogTotalQuery({ hierarchy, source });
        dispatch(Actions.http.connection.usage.total(queries));
    };

    const columns = createColumns(source) ?? [];

    return (
        <div className='total'>
            <TotalBreadcrumb
                hierarchy={source.hierarchy}
                isAllLinkDisabled={isAllLinkDisabled}
            />
            <BaseTable
                bordered
                columns={columns}
                dataSource={createTableData(source, location.pathname, onClick, isAllLinkDisabled)}
                loading={tableLoading}
                pagination={false}
                rowKey='key'
                scroll={{ x: columns.length > 20 ? 2048 : 1024 }}
                showTopScroll={true}
            />
        </div>
    );
};

const cellClassName = (source, text) => {
    const num = parseInt(text);
    if(isNaN(num)) return 'total-table-cell-undefined';

    const rank = source
        .rankThresholds
        .filter(threshold => threshold < num)
        .length;

    return `total-table-cell-level-${rank}`;
};

const createColumns = (source) => {
    const sourceData = source.total.data;
    if(sourceData[0] === undefined) return;

    let nameColumnTitle = undefined;

    if(source.isControllableUser){
        switch (source.hierarchy.length) {
            case 1: // 学校別
                nameColumnTitle = '学校名';
                break;
            case 2: // クラス別
                nameColumnTitle = 'クラス名';
                break;
            case 3: // ユーザー別
            case 4: // ユーザー
                nameColumnTitle = '名前';
                break;
            default:
                break;
        }
    }else{
        switch (source.hierarchy.length) {
            case 1: // クラス別
                nameColumnTitle = 'クラス名';
                break;
            case 2: // ユーザー別
            case 3: // ユーザー
                nameColumnTitle = '名前';
                break;
            default:
                break;
        }
    }

    const keys = createTimeKeys(source.term, source.termType);

    const nameColumn = [
        {
            dataIndex: 'name',
            fixed: true,
            title: nameColumnTitle,
            width: keys.length > 20 ? 300 : 200,
        },
    ];

    return nameColumn.concat(
        keys.map(([key, val])=>({
            align: 'center',
            dataIndex: key,
            render: (text) => ({
                children: <Text>{text === undefined ? '-' : text}</Text>,
                props: { className: cellClassName(source, text) },
            }),
            title: val.replace('</br >', ''),
            width: keys.length > 20 ? 100 : 60,
        }))
    );
};

const createTableData = (source, pathname, onClick, isAllLinkDisabled) => {
    const style = { marginLeft:'1rem' };
    return source.total.data.map((obj, idx)=>{
        const copy = Object.assign({}, obj);
        if(isAllLinkDisabled) {
            copy.name = (
                <div style={idx === 0 ? {} : style}>
                    <div className={ idx === 0 ? undefined : 'dummy-text'}>
                        {copy.term_name && <TermNameTag termName={copy.term_name} />}
                        {copy.name}
                        {copy.login_id ? <br /> : undefined}
                        {copy.login_id ? copy.login_id : undefined}
                    </div>
                </div>
            );
            return { key: idx, ...copy, ...copy.totals };
        }

        copy.name = copy.isLink ?
            (
                <div style={idx === 0 ? {} : style}>
                    <Link
                        onClick={onClick(copy.uuid, copy.name)}
                        style={{ overflowWrap: 'anywhere' }}
                        to={`${pathname}/${copy.uuid}`}
                    >
                        {copy.term_name && <TermNameTag termName={copy.term_name} />}
                        {copy.name}
                        {copy.login_id ? <br /> : undefined}
                        {copy.login_id ? copy.login_id : undefined}
                    </Link>
                </div>
            ) :
            (
                <div style={idx === 0 ? {} : style}>
                    <span>
                        {copy.name}
                        {copy.login_id ? <br /> : undefined}
                        {copy.login_id ? copy.login_id : undefined}
                    </span>
                </div>
            );

        return { key: idx, ...copy, ...copy.totals };
    });
};

export default TotalTable;

import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/QUESTIONNAIRE_TAB';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * アンケートタブの準備をするため、アンケートの一覧とアンケートのカテゴリをリクエストする。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    (data) => ({ data }),
    () => ({})
);

/**
 * 検索のリクエストとStoreへの保存を行う。
 * */
export const submitSearch = createAction(
    createActionType('SUBMIT_SEARCH' as const),
    (values) => ({ values }),
    () => ({})
);

/**
 * 引数のページ情報で、リクエストと、ページ情報のStoreへの保存を行う。
 * */
export const submitPage = createAction(
    createActionType('SUBMIT_PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

/**
 * ページを保存する。
 * */
export const savePage = createAction(
    createActionType('SAVE_PAGE' as const),
    (page, page_size) => ({ page, page_size }),
    () => ({})
);

/**
 * 編集で、編集モーダルを開く。
 * */
export const openEdit = createAction(
    createActionType('OPEN_EDIT' as const),
    (uuid) => ({ uuid }),
    () => ({})
);

/**
 *  新規作成で、編集モーダルを開く。
 * */
export const openCreate = createAction(
    createActionType('OPEN_CREATE' as const),
    () => ({}),
    () => ({})
);

/**
 * コピーで、編集モーダルを開く。
 * */
export const openCopy = createAction(
    createActionType('OPEN_COPY' as const),
    (uuid) => ({ uuid }),
    () => ({})
);

/**
 * アンケートの回答結果画面を開く。
 * */
export const openResult = createAction(
    createActionType('OPEN_RESULT' as const),
    (uuid) => ({ uuid }),
    () => ({})
);

/**
 * アンケートの配信先の対象状況モーダルを開く。
 * */
export const openTarget = createAction(
    createActionType('OPEN_TARGET' as const),
    (record) => ({ uuid: record.key, isAnonymous: record.isAnonymous }),
    () => ({})
);

/**
 * アンケートを削除する。
 * */
export const submitDelete = createAction(
    createActionType('SUBMIT_DELETE' as const),
    (uuid) => ({ uuid }),
    () => ({})
);

/**
 * すべてのモーダルを閉じる。
 * */
export const cancelModal = createAction(
    createActionType('CANCEL_MODAL' as const),
    () => ({}),
    () => ({})
);

/**
 * 編集が完了した際によびだす。
 * アンケートの一覧を再取得して、モーダルを閉じる。
 * */
export const submitEdit = createAction(
    createActionType('SUBMIT_EDIT' as const),
    () => ({}),
    () => ({})
);

import { getMoment } from 'constants/GlobalConfig';
const moment = getMoment();

type FormValue = {
    target: string
    termType: EverydayNote.ResultTotal.TermTypes
    term: moment.Moment
    items: { question?: string, candidate?: string }[]
    hierarchy: { uuid: string | undefined, name: string }[],
    scopeUuid?: string
    conditions: { candidate: string, question: string }[]
};


type Parameter = Http.Connection.Request.Parameter.EverydayNote.ResultSubTotal &
    Http.Connection.Request.Parameter.EverydayNote.ResultCsv;
type Request = Omit<Parameter, 'page_size' | 'encoding' | 'conditions'> & { conditions: Record<string, string>[] };
type Options = { encoding?: Encoding, pageSize?: number, termUuid?: string };
type RequestOption<T extends Options> = T extends { encoding: Encoding }
    ? T extends { pageSize: number }
        ? T extends { termUuid: string } ? { encoding: Encoding, page_size: number, termUuid: string } : { encoding: Encoding, page_size: number }
        : T extends { termUuid: string } ? { encoding: Encoding, termUuid: string } : { encoding: Encoding }
    : T extends { pageSize: number }
        ? T extends { termUuid: string } ? { page_size: number, termUuid: string } : { page_size: number }
        : T extends { termUuid: string } ? { termUuid: string } : {}

export type ConvertRequestableSearchCondition = ReturnType<typeof genConvertRequestableSearchCondition>;

/**
 * ConfirmAnswerResultModal 内の検索条件をリクエスト可能な形式に変換する関数を返す
 */
export const genConvertRequestableSearchCondition = (isControllableUser: boolean) => <T extends Options>(
    searchCondition: FormValue,
    options?: T,
): Expand<Request & RequestOption<T>> | undefined => {
    const startAt = moment(searchCondition.term).startOf(searchCondition.termType).unix();
    const endAt = moment(searchCondition.term).endOf(searchCondition.termType).unix();

    const scope = isControllableUser ?
        controlScope(searchCondition.hierarchy.length) :
        adminScope(searchCondition.hierarchy.length);

    if (scope === undefined) return;

    const scopeUuid = searchCondition.hierarchy.slice(-1)[0]?.uuid;

    const conditions = searchCondition.conditions?.map(condition => {
        return { [condition.question]: condition.candidate };
    }) ?? [];


    const newOptions = options !== undefined ?
        Object.entries({
            encoding: 'encoding' in options ? options.encoding : undefined,
            page_size: 'pageSize' in options ? options.pageSize : undefined,
            term_uuid: 'termUuid' in options ? options.termUuid : undefined,
        }).reduce<{ encoding?: Encoding, page_size?: number, term_uuid?: string }>((acc, [k, v]) => {
            return typeof v === 'undefined' ? acc : { ...acc, [k]: v }
        }, {}) :
        {};

    return {
        end_at: endAt,
        scope_uuid: scopeUuid,
        scope: scope,
        start_at: startAt,
        conditions,
        ...newOptions,
        // TODO: ちゃんと型補完されるように修正
        // ちょっとうまくいかないので、 as で強制する
    } as Expand<Request & RequestOption<T>>;
};

const controlScope = (hierarchyLength: number) => {
    return hierarchyLength === 1 ?
        'tenant' :
        hierarchyLength === 2 ?
            'organization' :
            hierarchyLength === 3 ?
                'school-class' :
                undefined;
};

const adminScope = (hierarchyLength: number) => {
    return hierarchyLength === 1 ?
        'organization' :
        hierarchyLength === 2 ?
            'school-class' :
            undefined;
};
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Actions from 'flex/Actions';
import { ListContext } from '../index';


const RoleEffectComponent = (props) => {
    const [list, setList] = useContext(props.context ? props.context : ListContext)
    const roleSearchConnection = useSelector(state => state.roleSearchConnection);

    useEffect(() => {
        if (roleSearchConnection.meta.status === Actions.statusEnum.SUCCESS) {
            setList({
                ...list,
                roleList: roleSearchConnection.payload.result.items,
                roleListItemCount: roleSearchConnection.payload.result.item_count
            })
        }
        if (roleSearchConnection.meta.status === Actions.statusEnum.FAILURE || roleSearchConnection.meta.status === Actions.statusEnum.ERROR) {
            //todo エラー繁用処理
        }
    }, [roleSearchConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    return null;
}

export default RoleEffectComponent;
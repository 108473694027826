import { createAction } from 'redux-actions';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'VIEW_LOGIC/ANSWER_RESULT_MODAL';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


/**
 * 解答結果の詳細のモーダルを開いた際に呼び出す。
 * ファイルの回答結果一覧や記述式回答結果の一覧のリクエストをする。
 * */
export const prepareView = createAction(
    createActionType('PREPARE_VIEW' as const),
    () => ({}),
    () => ({})
);

/**
 * 使われていない。
 * */
export const cancelDetail = createAction(
    createActionType('CANCEL_DETAIL' as const),
    () => ({}),
    () => ({})
);

/**
 * ページネーションをStoreに保存する。
 * */
export const savePage = createAction(
    createActionType('SAVE_PAGE' as const),
    (page, page_size) => ({page, page_size}),
    () => ({})
);

/**
 * ファイルの回答結果一覧or記述式回答結果の一覧のページネーションをリクエストして、ページネーションをStoreに保存する。
 * */
export const submitPage = createAction(
    createActionType('SUBMIT_PAGE' as const),
    (page, page_size) => ({page, page_size}),
    () => ({})
);

import { useDispatch, useSelector } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import { getDefaultLayout } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { appTemplateTabSelectors } from 'flex/view/AppTemplate/AppTemplateTab/selectors';

const Form = BaseForm;

const layout = getDefaultLayout();

const AppTemplateSearch = () => {
    const [form] = Form.useForm();
    const loading = useSelector(appTemplateTabSelectors.loading);
    const dispatch = useDispatch();
    const onFinish = (values) => {
        dispatch(Actions.view.viewLogic.appTemplateTab.submitSearch(values));
    };

    return (
        <div className='card general-search app-templates-search-form-wrapper'>
            <Form {...layout} form={form} name='control-hooks-app-templates-search' onFinish={onFinish}>
                <Form.Item label='アプリ名称' name='name'>
                    <Input disabled={loading} placeholder='アプリ名称を入力してください' />
                </Form.Item>

                <div className='submit-container'>
                    <Button htmlType='button'
                        disabled={loading}
                        loading={loading}
                        onClick={() => { form.resetFields(); }}
                    >
                        リセット
                    </Button>
                    <Button htmlType='submit' type='primary'
                        disabled={loading}
                        icon={<SearchOutlined />}
                        loading={loading}
                    >
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default AppTemplateSearch;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Empty, SpinProps, Table, TableProps } from 'antd';

import TopScrollBar from './BaseTable/TopScrollBar';

export type BaseTableProps<RecordType> = TableProps<RecordType> & {
    emptyDescription: React.ReactNode
    showTopScroll?: boolean
}

/**
 * ローディングがサークルのスピナーで、sizeがsmallの基本的なテーブル
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export const BaseTable = <RecordType extends object = any>({
    className,
    dataSource,
    emptyDescription,
    loading,
    showTopScroll: propsShowScroll = true,
    size = 'small',
    ...restProps
}: BaseTableProps<RecordType>) => {
    const [showTopScroll, setShowTopScroll] = useState(false);
    const thisRef = useRef<HTMLDivElement | null>(null);

    const resizeEventHandler = useCallback(() => {
        setShowTopScroll(
            checkShowTopScroll<RecordType>(
                thisRef.current,
                propsShowScroll,
                loading,
                dataSource
            )
        );
    }, [dataSource, loading, propsShowScroll]);

    useEffect(() => {
        window.addEventListener('resize', resizeEventHandler);
        return () => {
            window.removeEventListener('resize', resizeEventHandler);
        };
    }, [resizeEventHandler]);

    // データ読み込み完了時にスクロールバーの表示を判定
    useEffect(() => {
        setShowTopScroll(
            checkShowTopScroll<RecordType>(
                thisRef.current,
                propsShowScroll,
                loading,
                dataSource
            )
        );
    }, [loading, dataSource, propsShowScroll]);

    // スクロールの同期
    useEffect(() => {
        if (!showTopScroll || !thisRef.current) return;

        const tableContainer = thisRef.current
            .getElementsByClassName('ant-table-content')[0];
        const topScrollWrapper = thisRef.current
            .getElementsByClassName('top-scroll-wrapper')[0];

        tableContainer.addEventListener('scroll', () => {
            topScrollWrapper.scrollLeft = tableContainer.scrollLeft;
        });
        topScrollWrapper.addEventListener('scroll', () => {
            tableContainer.scrollLeft = topScrollWrapper.scrollLeft;
        });
        // テーブルとトップスクロールのコンテンツのサイズを合わせる
        const table = thisRef.current
            .getElementsByTagName('table')[0];
        const topScrollContent = thisRef.current
            .getElementsByClassName('top-scroll-content')[0] as HTMLElement;
        topScrollContent.style.width = `${table.clientWidth}px`;
    }, [showTopScroll]);

    // 検索時のスクロールバーの位置の同期
    useEffect(() => {
        if (!showTopScroll || !thisRef.current) return;

        const tableContainer = thisRef.current
            .getElementsByClassName('ant-table-content')[0];
        const topScrollWrapper = thisRef.current
            .getElementsByClassName('top-scroll-wrapper')[0];

        topScrollWrapper.scrollLeft = tableContainer.scrollLeft;
    }, [loading, showTopScroll]);

    let tableLoading: SpinProps | undefined;
    if (typeof loading === 'boolean') {
        tableLoading = {
            indicator: <LoadingOutlined style={{ fontSize: '35px' }} />,
            spinning: loading ? loading : false,
        };
    } else {
        tableLoading = loading;
    }

    return (
        <div
            className={showTopScroll ? 'show-top-scroll' : ''}
            ref={thisRef}
            style={{ 'position': 'relative' }}
        >
            <Table<RecordType>
                bordered
                className={className ? className : 'general-table'}
                dataSource={dataSource}
                loading={tableLoading}
                locale={{
                    emptyText: <Empty description={emptyDescription} image={Empty.PRESENTED_IMAGE_DEFAULT} />,
                    filterConfirm: '確定',
                    filterReset: 'リセット',
                }}
                rowKey='uuid'
                scroll={{ x: '100%' }}
                size={size}
                {...restProps}
            />

            {/* 表示順序の関係でスクロルバーの操作が効かなくなってしまうので,
              * TopScrollBar という名前だが Table の後ろに置く必要がある.
              */}
            <TopScrollBar
                hasPagination={!!restProps.pagination}
                showTopScroll={showTopScroll}
                size={size}
            />
        </div>
    );
};

/**
 * 表上部にスクロールバーを表示するかを判定する
 */
const checkShowTopScroll = <RecordType, >(
    element: HTMLDivElement | null,
    showScroll: BaseTableProps<RecordType>['showTopScroll'] = false,
    loading: BaseTableProps<RecordType>['loading'] = false,
    dataSource: BaseTableProps<RecordType>['dataSource'] = []
) => {
    if (!element) return false;

    const tableContainerWidth = element
        .getElementsByClassName('ant-table-content')?.[0]
        ?.clientWidth;
    const tableWidth = element
        .getElementsByTagName('table')?.[0]
        ?.clientWidth;

    const isLoading = typeof loading === 'boolean' ?
        loading :
        loading.spinning ?? true;

    return (
        showScroll &&
        !isLoading &&
        dataSource.length > 0 &&
        tableContainerWidth < tableWidth
    );
};
import { useEffect, useState } from 'react';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type Student = {
    uuid: string,
    name: string
}

const INIT_STATE: AppStatusModal.StudentView = {
    student: {
        uuid: '',
        name: '',
    },
    loading: false,
    applications: [],
};

export const useStudentViewData = (student: Student) => {
    const [studentView, setStudentView] = useState<AppStatusModal.StudentView>({ ...INIT_STATE });
    const appsCategoryResult = useAppSelector(state => state.appsCategoryResultConnection);

    useEffect(() => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = appsCategoryResult;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                const applications = payloadResult
                    ? payloadResult.items.find(item => item.uuid === student.uuid)?.applications
                    : undefined;
                const generatedApplications = applications
                    ? applications.map((app) => {
                        const latestHistory = app.result_histories[0] ?? {};
                        const children = app.result_histories.slice(1).map(history => {
                            return {
                                activityProgress: history!.activity_progress,
                                agsTimestamp: history!.ags_timestamp,
                                comment: history!.comment,
                                createdAt: history!.created_at,
                                applicationResultUuid: history!.application_result_uuid,
                                gradingProgress: history!.grading_progress,
                                maxScore: history!.max_score ?? 0,
                                score: history!.score ?? 0,
                            }
                        })
                        return {
                            title: app.title,
                            uuid: app.uuid,
                            activityProgress: latestHistory!.activity_progress ?? '',
                            gradingProgress: latestHistory!.grading_progress ?? '',
                            score: latestHistory!.score ?? 0,
                            maxScore:  Object.keys(latestHistory).length ? latestHistory.max_score : app.max_score,
                            createdAt: latestHistory!.created_at ?? 0,
                            resultUrl: app.result_url ?? '',
                            children,
                        }
                    })
                    : [];

                setStudentView({
                    student: student,
                    loading: false,
                    applications: generatedApplications
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setStudentView({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // appsCategoryResultのみ監視対象としたいため
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsCategoryResult]);

    return [studentView] as const;
};
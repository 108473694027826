import { useState, useEffect, useMemo } from 'react';
import { Button, Tabs } from 'antd';
import { IdcardOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import BaseModal from 'components/modules/BaseModal';
import GroupTab from './GroupTab';
import OrganizationTab from './OrganizationTab';
import RoleTab from './RoleTab';
import UsersTab from './UserTab';
import { TargetDetails } from './TargetDetails';
import MainTeacher from './MainTeacher';

/** 対象選択モーダルのタブ キー */
const TabKeyEnum = {
    GROUP: '/group',
    ORGANIZATION: '/organization',
    ROLE: '/role',
    USER: '/user',
};

type Target = Partial<{
    target_organizations: Organization[],
    target_roles: Role[],
    target_school_classes: SchoolClass[],
    target_users: User[],
}>

type Props = {
    currentTarget: Target,
    onCancel: () => void,
    onlyHasSchoolCode?: boolean,
    onSubmit: (values: Target) => void,
    showClass?: boolean,
    showOrganization?: boolean,
    showRole?: boolean,
    showUser?: boolean,
    visible: boolean,
};

type SelectedListType = {
    targetClassesList: SchoolClass[],
    targetOrganizationList: Organization[],
    targetRolesList: Role[],
    targetUsersList: User[],
};

const Targets: React.VFC<Props> & {
    Detail: typeof TargetDetails,
    MainTeacher: typeof MainTeacher,
} = ({
    currentTarget,
    onCancel,
    onlyHasSchoolCode = false,
    onSubmit,
    showClass = true,
    showOrganization = false,
    showRole = false,
    showUser = true,
    visible = false,
}) => {
    const [tabActiveKey, setTabActiveKey] = useState(selectInitialTab({
        currentTarget,
        showClass,
        showOrganization,
        showRole,
        showUser,
    }));

    const [selectedList, setSelectedList] = useState<SelectedListType>({
        targetUsersList: [],
        targetClassesList: [],
        targetRolesList: [],
        targetOrganizationList: [],
    });

    const targetOrganizationList = useMemo(() => {
        return currentTarget.target_organizations ?? [];
    }, [currentTarget.target_organizations]);

    const targetClassesList = useMemo(() => {
        return currentTarget.target_school_classes ?? [];
    }, [currentTarget.target_school_classes]);

    const targetUsersList = useMemo(() => {
        return currentTarget.target_users ?? [];
    }, [currentTarget.target_users]);

    const targetRolesList = useMemo(() => {
        return currentTarget.target_roles ?? [];
    }, [currentTarget.target_roles]);

    useEffect(() => {
        if (!visible) return;

        setSelectedList({
            targetUsersList: targetUsersList,
            targetClassesList: targetClassesList,
            targetRolesList: targetRolesList,
            targetOrganizationList: targetOrganizationList,
        });

        setTabActiveKey(selectInitialTab({
            currentTarget,
            showClass,
            showOrganization,
            showRole,
            showUser,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (visible) return;

        setSelectedList({
            targetUsersList: [],
            targetClassesList: [],
            targetRolesList: [],
            targetOrganizationList: [],
        });
    }, [visible]);

    function handleSubmit() {
        const targets: Target = {};

        if (showClass) {
            targets.target_school_classes = selectedList.targetClassesList;
        }

        if (showUser) {
            targets.target_users = selectedList.targetUsersList;
        }

        if (showRole) {
            targets.target_roles = selectedList.targetRolesList;
        }

        if (showOrganization) {
            targets.target_organizations = selectedList.targetOrganizationList;
        }

        onSubmit(targets);
    }

    return (
        <BaseModal
            className='common-modal'
            title={<span><TeamOutlined /> 対象を選択</span>}
            visible={visible}
            style={{ top: 20, width: '97vw !important' }}
            onCancel={onCancel}
            footer={[
                <Button key='back' size='large' onClick={onCancel}>キャンセル</Button>,
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    onClick={handleSubmit}
                >
                    保存
                </Button>,
            ]}
        >
            <Tabs size='small' activeKey={tabActiveKey} onChange={(activeKey) => setTabActiveKey(activeKey)}>
                {showClass && (
                    <Tabs.TabPane
                        tab={<span><TeamOutlined /> 対象クラス</span>}
                        key={TabKeyEnum.GROUP}
                    >
                        <GroupTab
                            currentGroupList={targetClassesList}
                            onSave={(classList: SchoolClass[]) => (setSelectedList(list => ({
                                ...list,
                                targetClassesList: classList,
                            })))}
                        />
                    </Tabs.TabPane>
                )}

                {showUser && (
                    <Tabs.TabPane
                        tab={<span><UserOutlined /> 対象ユーザー</span>}
                        key={TabKeyEnum.USER}
                    >
                        <UsersTab
                            currentUserList={targetUsersList}
                            onSave={(userList: User[]) => (setSelectedList(list => ({
                                ...list,
                                targetUsersList: userList,
                            })))}
                        />
                    </Tabs.TabPane>
                )}

                {showRole && (
                    <Tabs.TabPane
                        tab={<span><IdcardOutlined /> 対象役割</span>}
                        key={TabKeyEnum.ROLE}
                    >
                        <RoleTab
                            currentRoleList={targetRolesList}
                            onSelected={(roleList: Role[]) => (setSelectedList(list => ({
                                ...list,
                                targetRolesList: roleList,
                            })))}
                        />
                    </Tabs.TabPane>
                )}

                {showOrganization && (
                    <Tabs.TabPane
                        tab={<span> <SchoolOutline /> 対象学校</span>}
                        key={TabKeyEnum.ORGANIZATION}
                    >
                        <OrganizationTab
                            currentOrganizationList={targetOrganizationList}
                            onlyHasSchoolCode={onlyHasSchoolCode}
                            onSave={(organizationList: Organization[]) => (setSelectedList(list => ({
                                ...list,
                                targetOrganizationList: organizationList,
                            })))}
                        />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </BaseModal>
    );
};

Targets.Detail = TargetDetails;
Targets.MainTeacher = MainTeacher;

export default Targets;

const SchoolOutline = () => (
    <FontAwesomeIcon
        style={{ marginRight: 10, height: 14, width: 14 }}
        icon={faSchool}
    />
);

const selectInitialTab = ({
    showClass,
    showOrganization,
    showRole,
    showUser,
    currentTarget
}: Pick<Props, 'showClass' | 'showOrganization' | 'showRole' | 'showUser' | 'currentTarget'>) => {
    const {
        target_organizations,
        target_roles,
        target_school_classes,
        target_users,
    } = currentTarget;

    if (target_organizations && target_organizations.length > 0 && showOrganization) return TabKeyEnum.ORGANIZATION;
    if (target_school_classes && target_school_classes.length > 0 && showClass) return TabKeyEnum.GROUP;
    if (target_users && target_users.length > 0 && showUser) return TabKeyEnum.USER;
    if (target_roles && target_roles.length > 0 && showRole) return TabKeyEnum.ROLE;

    if (showClass) return TabKeyEnum.GROUP;
    if (showUser) return TabKeyEnum.USER;
    if (showRole) return TabKeyEnum.ROLE;
    if (showOrganization) return TabKeyEnum.ORGANIZATION;

    return TabKeyEnum.GROUP;
};

import { useEffect, forwardRef } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    XAxis,
    YAxis,
    DefaultLegendContent,
    DefaultLegendContentProps,
} from 'recharts';
import { LoadingOutlined } from '@ant-design/icons';

const axisColor = '#A3A3A3';
const gridColor = '#F0F0F0';
const BarColors = [
    '#005AFF',
    '#FF4B00',
    '#FFF100',
    '#990099',
    '#4DC4FF',
    '#03AF7A',
    '#F6AA00',
    '#003699',
    '#992D00',
    '#999100',
    '#330033',
    '#2E7699',
    '#014A34',
    '#8F6300',
    '#669CFF',
    '#FF9366',
    '#FFF766',
    '#993D99',
    '#B2E6FF',
    '#4AAF90',
    '#F6C862',
    '#0048CC',
    '#CC3C00',
    '#CCC100',
    '#660066',
    '#3E9DCC',
    '#027D57',
    '#C28600',
    '#99BDFF',
    '#FFB799',
    '#FFF999',
    '#995C99',
    '#E5F7FF',
    '#6DAF9B',
    '#F6D894',
];

const FiveLevelColors = [
    '#ED2100',
    '#FFB800',
    '#FAFF00',
    '#63D20A',
    '#006E18',
];

type Props = {
    data: EverydayNote.ResultTotal.TimeSeries
    loading: boolean
    questionType: EverydayNote.QuestionTypes | undefined
    updater: () => void
}

const SummaryGraph = forwardRef<HTMLDivElement, Props>(({
    data,
    loading,
    questionType,
    updater,
}, ref) => {

    useEffect(() => {
        // data が更新された直後だと、まだレンダリングされていないことがあるので、
        // 少し待ってから updater を呼ぶ。
        setTimeout(() => updater(), 500);
    }, [data]);// eslint-disable-line

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(300px - 20px - 16px)',
                width: '100%',
            }}
            >
                <LoadingOutlined style={{ fontSize: '35px', color: '#004C97' }} />
            </div>
        );
    }

    const keys = Object.keys(data[0] ?? {}).filter(key => key !== 'name') as Key[];
    const dataKeys = questionType === 4 ? keys.reverse() : keys;
    const fill = questionType === 4 ? FiveLevelColors : BarColors;
    const colorsNum = fill.length;
    const dataSource = data.map((d) => {
        return Object.fromEntries([...dataKeys, 'name' as const].map((key) => (
            key === 'name' ? [key, d.name] : [key, d[key]?.count ?? 0]
        )));
    });

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={dataSource}>
                <CartesianGrid stroke={gridColor} vertical={false} />
                <XAxis
                    axisLine={false}
                    dataKey='name'
                    height={40 + 30}
                    interval={0}
                    stroke={axisColor}
                    tick={CustomizedAxisTick}
                    tickLine={false}
                />
                <YAxis
                    allowDecimals={false}
                    axisLine={false}
                    tickLine={false}
                    stroke={axisColor}
                />
                <Legend
                    align='right'
                    content={renderLegend(ref)}
                    formatter={renderLegendText}
                    iconSize={10}
                    iconType='circle'
                />

                {dataKeys.map((dataKey, i) => (
                    <Bar
                        dataKey={dataKey}
                        fill={fill[i % colorsNum]}
                        key={dataKey}
                        name={data[0][dataKey].name}
                        stackId='a'
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
});

export default SummaryGraph;


type Key = keyof Omit<EverydayNote.ResultTotal.TimeSeries[0], 'name'>;

const renderLegendText = (value: string, entry: any) => {
    return <span style={{ color: 'black' }}>{entry?.payload?.name ?? ''}</span>;
};

const CustomizedAxisTick = (props: { x: number; y: number; payload: { value: string } }) => {
    const { x, y, payload } = props;
    const dayOfWeekTextHeadIndex = payload.value.indexOf('(');
    const dayText = payload.value.split('(')[0].trim();
    const dayOfWeekText = payload.value.substring(dayOfWeekTextHeadIndex).trim();

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill='#888'>
                <tspan textAnchor='middle' x='0'>{dayText}</tspan>
                <tspan textAnchor='middle' x='0' dy='20'>{dayOfWeekText}</tspan>
            </text>
        </g>
    );
};


/**
 * Recharts の Legend をカスタマイズし、Legend の領域を ref で取得できるようにするためのコンポーネント
 */
const CustomLegendContent = forwardRef<
    HTMLDivElement,
    Omit<DefaultLegendContentProps, 'ref'>
>((props, ref) => {
    return (
        <div ref={ref}>
            <DefaultLegendContent {...props} />
        </div>
    );
});

type RenderLegend = (ref: React.ForwardedRef<HTMLDivElement>) => React.VFC<DefaultLegendContentProps>;

/**
 * Legend の contents に渡す関数を返す関数
 */
const renderLegend: RenderLegend = (ref) => (props) => {
    const { payload } = props;

    if (!payload) return <></>;

    const { ref: _, ...rest } = props;

    return (
        <CustomLegendContent {...rest} ref={ref} />
    );
}
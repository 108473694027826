import { Fragment } from 'react';

import { Button, Popover } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
    Bar,
    BarChart,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
} from 'recharts';

import { generateFileDomain } from 'constants/GlobalConfig';

/**
 * answer_count が NaN でない Candidate
 */
type NonNanCandidate = {
    answerCount: number
    content: string
    createdAt: number
    order: number
    updatedAt: number
    uuid: string
}

// グラフの色
// https://oku.edu.mie-u.ac.jp/~okumura/stat/colors.html
const COLORS = [
    '#ff4b00',
    '#fff100',
    '#03af7a',
    '#005aff',
    '#4dc4ff',
    '#ff8082',
    '#f6aa00',
    '#990099',
    '#804000',
    '#c8c8cb',
];

/***
 * すべての設問の回答を表示する
 */
const QuestionResults = ({
    items,
    onClickAnswerDetail,
}: { items: Question[], onClickAnswerDetail: (item: Question) => void }) => {
    if (!items || items.length === 0) return <></>;

    return (
        <>
            {items.map((item, index) => {
                return <QuestionResult item={item} key={index} onClickAnswerDetail={onClickAnswerDetail} />;
            })}
        </>
    );
};

export const QuestionResult = ({
    item,
    onClickAnswerDetail,
}: { item: Question, onClickAnswerDetail: (item: Question) => void }) => {
    const handleDownloadClick = (uuid: string) => {
        window.open(`${generateFileDomain()}/file/view/${uuid}`);
    };

    return (
        <div className='question-preview-items'>
            <h3>{item.content}</h3>
            <h4 className='break-all'>{item.detail}</h4>

            {item.file && (
                <Button
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => handleDownloadClick(item.file!.uuid)}
                    type='primary'
                >
                    {item.file.fileName} をダウンロードする
                </Button>
            )}

            <div className='answer-result'>
                <AnswerCount item={item} />
                <AnswerGraph
                    item={item}
                    legendProps={{
                        iconSize: 15,
                        lineHeight: 28,
                    }}
                />
                <AnswerDetailButton
                    className='answer-result-choice-right'
                    item={item}
                    onClickAnswerDetail={onClickAnswerDetail}
                />
            </div>
        </div>
    );
};

const AnswerCount = ({ item }: { item: Question }) => {
    const allAnswersCount = item.type === 1 || item.type === 2 ?
        item.answerCandidates.reduce((s, c) => s + (c.answerCount ?? 0), 0) :
        item.answerCount ?? 0;

    switch (item.type) {
        case 1:
        case 2:
            return (
                <div className='answer-result-choice'>
                    <b>全回答数: {allAnswersCount}件</b>

                    {item.answerCandidates.map((candidate) => (
                        <div key={candidate.uuid} style={{ marginBottom: '8px' }}>
                            <b key={candidate.uuid}>{`■選択肢${candidate.order}:${candidate.content}`}</b><br />
                            回答数: {candidate.answerCount ?? 0}件
                        </div>
                    ))}
                </div>
            );
        case 3:
            return (
                <div className='answer-result-choice'>
                    <b>全回答数: {allAnswersCount}件</b><br />
                </div>
            );
        case 4:
            return (
                <div className='answer-result-choice'>
                    <b>全回答数: {allAnswersCount}件</b><br />
                </div>
            );
        default:
            return <></>;
    }
};


/**
 * Legend の iconSize を変更可能とする
 */
export const AnswerGraph = ({
    item,
    isAnimation = true,
    isResponsive = false,
    legendProps = {},
}: { item: Question, isAnimation?: boolean, isResponsive?: boolean, legendProps?: Record<string, unknown> }) => {
    if (item.type !== 1 && item.type !== 2) return <></>;

    const allAnswersCount = item
        .answerCandidates
        .filter(byAnswerCountOverZero)
        .reduce((sum, candidate) => sum + candidate.answerCount, 0);

    const options = item
        .answerCandidates
        .filter(byAnswerCountOverZero)
        .sort(byAnswerCount)
        .map((value) => ({
            content: value.content,
            name: `選択肢${value.order}`,
            uuid: value.uuid,
            value: value.answerCount ?? 0,
        }));

    /**
     * 回答がある選択肢の数
     */
    const candidatesWithAnswerCount = item.answerCandidates.filter(byAnswerCountOverZero).length;

    /**
     * 図表全体の幅 兼 円グラフの領域幅(= 高さ)
     *
     * src/styles/pages/questionnaire.scss における
     *
     * .questionnaire-result > .answer-result > .chart-box
     *
     * の max-width を参照
     */
    const chartWidth = 200;

    /**
     * 選択肢の 1 行あたりの高さ
     */
    const heightPerOneLine = legendProps.lineHeight as number;

    /**
     * Legend の高さ
     */
    const candidatesHeight = candidatesWithAnswerCount * heightPerOneLine;

    /**
     * 円グラフの半径
     *
     * ( 円グラフの領域幅 - ( 左マージン(10px) + 右マージン(20px) ) ) /2
     */
    const chartRadius = (chartWidth - 10 * 3) / 2;

    /**
     * 円グラフの中心の x 座標
     *
     * マージン分( 10px ) + 円グラフの半径
     */
    const chartCenterX = 10 + chartRadius;

    /**
     * 円グラフの中心の y 座標
     *
     * マージン分( 10px ) + 円グラフの半径
     */
    const chartCenterY = 10 + chartRadius;

    const Container = isResponsive ?
        ResponsiveContainer :
        Fragment;
    const barChartProps = isResponsive ?
        { height: candidatesHeight } :
        {};
    const pieChartProps = isResponsive ?
        { aspect: 1 } :
        {};

    return (
        <div className='chart-box'>

            {allAnswersCount !== 0 ?
                <>
                    <Container {...pieChartProps}>
                        <PieChart
                            height={isResponsive ? undefined : chartWidth}
                            width={isResponsive ? undefined : chartWidth}
                        >
                            <Pie
                                cx={isResponsive ? undefined : chartCenterX}
                                cy={isResponsive ? undefined : chartCenterY}
                                data={options}
                                dataKey='value'
                                endAngle={-270}
                                isAnimationActive={isAnimation}
                                labelLine={false}
                                outerRadius={isResponsive ? undefined : chartRadius}
                                startAngle={90}
                            >
                                {
                                    options.map((entry, index) => (
                                        <Cell fill={COLORS[index % COLORS.length]} key={index} />
                                    ))
                                }
                            </Pie>
                        </PieChart>
                    </Container>

                    <Container {...barChartProps}>
                        <BarChart
                            height={candidatesHeight}
                            width={isResponsive ? undefined : chartWidth}
                        >
                            {options.map((_, index) => (
                                <Bar dataKey={index} fill={COLORS[index % COLORS.length]} key={index} />
                            ))}
                            <Legend
                                align='left'
                                formatter={legendFormatter(options, allAnswersCount)}
                                iconSize={15}
                            />
                        </BarChart >
                    </Container>
                </> :
                <span>回答は現在存在しません</span>
            }
        </div>
    );
};

/***
 * 自由記述やファイルでの回答結果の一覧を表示するためのボタン
 */
export const AnswerDetailButton = ({
    item,
    onClickAnswerDetail,
    size = 'middle',
    className,
}: { item: Question, onClickAnswerDetail: (item: Question) => void, size?: SizeType, className?: string }) => {
    if (item.type !== 3 && item.type !== 4) return <></>;

    const buttonName = item.type === 3 ? '自由記述を見る' : '添付ファイルを見る';

    const handleClick = () => onClickAnswerDetail(item);

    return (
        <div className={className}>
            <Button onClick={handleClick} size={size} type='primary'>
                {buttonName}
            </Button>
        </div>
    );
};

const byAnswerCountOverZero = (e: { answerCount: null | undefined | number }): e is NonNanCandidate => {
    if (e.answerCount === null) return false;
    if (e.answerCount === undefined) return false;
    if (e.answerCount === 0) return false;
    return true;
};

const byAnswerCount = (a: NonNanCandidate, b: NonNanCandidate) => {
    if (a.answerCount > b.answerCount) return -1;
    if (a.answerCount < b.answerCount) return 1;
    return 0;
};

// Rechart における Legend の formatter の型が正しくなく,
// 正確な型付けは大変なので型は any とする.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const legendFormatter = (options: Record<string, any>[], sum: number) => function (value: any, entry: any, index: number) {
    return (
        <>
            <span className='content'>
                <Popover
                    content={options[index].content}
                >
                    {options[index].content}
                </Popover>
            </span>

            <span className='percent'>
                {Math.round((options[index].value / sum) * 100)}%
            </span>
        </>
    );
};

export default QuestionResults;

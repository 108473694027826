import * as React from 'react';
import QuestionForm from './QuestionForm';
import BaseForm from 'components/modules/BaseForm';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const Form = BaseForm;

type Props = {
    disabled?: boolean
    isAnswered: boolean
    name: string
    onAddQuestion: () => void
    onChange?: (value: EverydayNote.Form.Question[]) => void
    onCopy: (question: EverydayNote.Form.Question) => void
    onDelete: (question: EverydayNote.Form.Question) => void
    onEdit: (question: EverydayNote.Form.Question) => void
    questionsCount: number
    value?: EverydayNote.Form.Question[]
};

const QuestionsListForm: React.VFC<Props> = ({
    disabled = false,
    isAnswered,
    name,
    onAddQuestion,
    onChange,
    onCopy,
    onDelete,
    onEdit,
    value,
}) => {
    const handleChangeOrder = (order: number, delta: number) => {
        if (!value || !onChange) return;

        const targetOrder = order;
        const sourceOrder = order + delta;

        const regex = /^\d+?\./;

        const newValue = value.map(question => {
            switch (question.order) {
                case targetOrder: {
                    return {
                        ...question,
                        order: sourceOrder,
                        content: question.content.replace(regex, `${sourceOrder + 1}.`),
                    };
                }
                case sourceOrder: {
                    return {
                        ...question,
                        order: targetOrder,
                        content: question.content.replace(regex, `${targetOrder + 1}.`),
                    };
                }
                default: {
                    return question;
                }
            }
        });

        onChange(newValue);
    };

    return (
        <>
            <Form.List name={name}>
                {fields => fields.map(field => {
                    const question = value?.find(v => v.order === field.name);
                    if (question === undefined) return null;

                    return (
                        <QuestionForm
                            {...field}
                            disabled={disabled}
                            isAnswered={isAnswered}
                            itemCount={value?.length ?? 0}
                            onChangeOrder={handleChangeOrder}
                            onClickCopy={onCopy}
                            onClickDelete={onDelete}
                            onClickEdit={onEdit}
                            question={question}
                        />
                    );
                })}

            </Form.List>

            <Form.Item noStyle>
                <Button
                    block
                    disabled={disabled || isAnswered}
                    icon={<PlusOutlined />}
                    onClick={onAddQuestion}
                >
                    設問の追加
                </Button>
            </Form.Item>
        </>
    );
};

export default QuestionsListForm;

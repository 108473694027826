import { useEffect } from 'react';
import * as React from 'react';
import {
    Button,
    Col,
    DatePicker,
    FormInstance,
    Row,
    Select,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import isTouchDevice from 'is-touch-device';
import {
    getMoment,
    getDefaultLayout,
} from 'constants/GlobalConfig';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';
import ConditionFormItem from './ConditionFormItem';
import { formatFuncCreator, TERM_TYPE_LIST } from '../../../utils/TermOperators';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm;
const { Option } = Select;
const moment = getMoment();
const layout = getDefaultLayout();

const NON_LABEL_LAYOUT = {
    xl: { span: 20, offset: 4 },
};

type Props = {
    loading: boolean
    onSearch: (condition: Partial<EverydayNote.AnswerResultModal.SearchCondition>) => void
    onReset: (form: FormInstance) => void
    searchCondition: EverydayNote.AnswerResultModal.FormValue
    visible: boolean
    questions: EverydayNote.Question[]
}

const AnswerResultSearch: React.VFC<Props> = ({
    loading,
    onReset,
    onSearch,
    searchCondition,
    visible,
    questions,
}) => {
    const [form] = Form.useForm<EverydayNote.AnswerResultModal.FormValue>();
    const termType = Form.useWatch('termType', form);
    const conditions = Form.useWatch('conditions', form) ?? [];
    const maxConditionsLength = questions.length > 3 ? 3 : questions.length;
    const { termList } = useAppSelector(selectors.globalState);

    const handleReset = () => {
        form.resetFields();
        onReset(form);
    };

    const handleFinish = (value: EverydayNote.AnswerResultModal.FormValue) => {
        onSearch({ ...value, current: 1 });
    };

    useEffect(() => {
        form.setFieldsValue(searchCondition);
    }, [questions, form, visible, searchCondition]);

    useEffect(() => {
        // termType が変更された際に、直近の日付が表示されるように設定する
        if (termType === 'month') return;

        form.setFieldsValue({ term: moment() });
    }, [form, termType]);

    return (
        <div className='card general-search'>
            <Form {...layout} form={form} onFinish={handleFinish}>
                <Form.Item label='期間'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name='termType'>
                                <Select
                                    placeholder='表示期間を選択してください'
                                    disabled={loading}
                                    loading={loading}
                                >
                                    {TERM_TYPE_LIST.map(term => {
                                        return <Option key={term.value} value={term.value}>{term.text}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name='term'
                                dependencies={['termType']}
                            >
                                <DatePicker
                                    allowClear={false}
                                    format={formatFuncCreator(termType)}
                                    disabled={loading}
                                    locale={jaJP}
                                    name='term'
                                    style={{ width: '100%' }}
                                    picker={termType}
                                    placeholder='日付を選択してください'
                                    inputReadOnly={isTouchDevice()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                {/* #4075 一時的に塞ぐ 戻す時はhidden外してSelectの方を採用*/}
                <Form.Item hidden label='年度' name='termUuid'>
                    {true
                        ? <input value={undefined} />
                        : <Select allowClear
                            placeholder='年度を選択してください'
                        >
                            {termList.map((org) => (
                                <Option key={org.uuid} value={org.uuid}>{org.name}</Option>
                            ))}
                        </Select>
                    }
                </Form.Item>

                <Form.Item
                    label='表示対象'
                    name='target'
                >
                    <Select
                        placeholder='表示対象を選択してください'
                        disabled={loading}
                        loading={loading}
                    >
                        {questions.map(question => {
                            return <Option key={question.uuid} value={question.uuid}>
                                {question.content}
                            </Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.List name='conditions' >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    {...field}
                                    label={index === 0 ? '条件' : ''}
                                    wrapperCol={index === 0 ? {} : NON_LABEL_LAYOUT}
                                    style={{ marginBottom: 0 }}
                                >
                                    <ConditionFormItem
                                        name={field.name}
                                        loading={loading}
                                        items={questions}
                                        onRemove={() => remove(index)}
                                    />
                                </Form.Item>
                            ))}

                            {maxConditionsLength > conditions.length && (
                                <Form.Item wrapperCol={NON_LABEL_LAYOUT}>
                                    <Button
                                        type='dashed'
                                        style={{ width: '100%' }}
                                        // add をそのまま渡すと, 以下のエラーが発生する.
                                        // This synthetic event is reused for performance reasons.
                                        onClick={() => add({})}
                                    >
                                        <PlusOutlined />
                                        条件の追加
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>

                <Form.Item wrapperCol={NON_LABEL_LAYOUT} className='submit-container'>
                    <Button htmlType='button' onClick={handleReset} loading={loading}>
                        リセット
                    </Button>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        検索
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AnswerResultSearch;

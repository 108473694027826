import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

type ResultInLoading = {
    isLoading: boolean
    createdAt: undefined
    message: undefined
    type: undefined
    typeName: undefined
    updatedAt: undefined
    uuid: undefined
};

type Result = {
    isLoading: boolean
    createdAt: number
    message: string
    type: Job.Type
    typeName: string
    updatedAt: number
    uuid: string
};

const INIT_STATE: ResultInLoading = {
    isLoading: false,
    createdAt: undefined,
    message: undefined,
    type: undefined,
    typeName: undefined,
    updatedAt: undefined,
    uuid: undefined,
};

export const useDownloadResultCsv = () => {
    const [result, setResult] = useState<Result | ResultInLoading>({ ...INIT_STATE });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const controlEverydayNoteResultCsvConnection = useAppSelector(state => state.controlEverydayNoteResultCsvConnection);
    const adminEverydayNoteControlResultCsvConnection = useAppSelector(state => state.adminEverydayNoteControlResultCsvConnection);
    const adminEverydayNoteResultCsvConnection = useAppSelector(state => state.adminEverydayNoteResultCsvConnection);

    const dispatch = useDispatch();

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setResult({ ...INIT_STATE }), []);

    /** 通信を行う */
    const request = useCallback((
        questionnaireUuid: string,
        condition?: Omit<Http.Connection.Request.Parameter.EverydayNote.ResultSubTotal, 'page_size'> & { encoding: Encoding },
        tabName?: 'board' | 'school'
    ) => {

        const resultCsvAction = isControllableUser ?
            Actions.http.connection.everydayNote.control.resultCsv :
            tabName === 'board' ?
                Actions.http.connection.everydayNote.admin.controlResultCsv :
                Actions.http.connection.everydayNote.admin.resultCsv;

        dispatch(resultCsvAction(questionnaireUuid, condition));
        setResult(state => ({ ...state, isLoading: true }));
    }, [dispatch]);// eslint-disable-line

    type Connection = EverydayNoteResultCsvConnection | EverydayNoteControlResultCsvConnection;
    const connectionEffect = (connection: Connection) => () => {
        const {
            meta: { status },
            payload: { result: payloadResult },
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!result.isLoading) break;

                setResult({
                    isLoading: false,
                    createdAt: payloadResult.created_at,
                    message: payloadResult.message,
                    type: payloadResult.type,
                    typeName: payloadResult.type_name,
                    updatedAt: payloadResult.updated_at,
                    uuid: payloadResult.uuid,
                });
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setResult({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
    };

    useEffect(
        connectionEffect(controlEverydayNoteResultCsvConnection),
        [controlEverydayNoteResultCsvConnection]
    );
    useEffect(
        connectionEffect(adminEverydayNoteControlResultCsvConnection),
        [adminEverydayNoteControlResultCsvConnection]
    );
    useEffect(
        connectionEffect(adminEverydayNoteResultCsvConnection),
        [adminEverydayNoteResultCsvConnection]
    );

    return [result, request, reset] as const;
};

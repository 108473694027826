import {
    CopyOutlined,
    DeleteOutlined,
    FileAddOutlined,
    LineChartOutlined,
    SolutionOutlined,
} from '@ant-design/icons';
import { Badge, Col, Row, Tag, message } from 'antd';
import { generateUserDomain, getMoment } from 'constants/GlobalConfig';
import CircleButton from 'components/modules/CircleButton';
import {
    createPublishedItemButtonTooltipTitle,
    createPublishedItemEditOrInfoButtonTooltipTitle,
    EditOrInfoIcon,
    PublishedItemTitleHint,
} from 'components/modules/PublishedItems';
const moment = getMoment();


export const createColumns = (
    hasTouchScreen = false,
    handleEditClick,
    handleCopyClick,
    handleResultClick,
    handleTargetClick,
    handleDeleteClick,
    isControl = false,
) => {
    return [
        {
            dataIndex: 'title',
            key: 'title',
            render: (title, record) => (
                <span>{title} <PublishedItemTitleHint record={record} /></span>
            ),
            title: 'タイトル',
            width: 40,
        },
        {
            dataIndex: 'category',
            key: 'category',
            title: 'カテゴリ',
            width: 30,
        },
        {
            dataIndex: 'sender',
            key: 'sender',
            title: '発信元',
            width: 30,
        },
        {
            dataIndex: 'rewritable',
            key: 'rewritable',
            render: (rewritable) => {
                return (
                    rewritable ? <Tag color='success'>許可</Tag> : <Tag color='default'>不可</Tag>
                );
            },
            title: '回答再編集',
            width: 20,
        },
        {
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                switch (status) {
                    case 'disable': {
                        return <Badge status='warning' text='無効' />;
                    }
                    case 'public': {
                        return <Badge status='processing' text='公開中' />;
                    }
                    case 'public_before': {
                        return <Badge status='success' text='公開前' />;
                    }
                    case 'public_after': {
                        return <Badge status='default' text='公開終了' />;
                    }
                    default: {
                        return <Badge status='error' text='不明' />;
                    }
                }
            },
            title: '状態',
            width: 20,
        },
        {
            dataIndex: 'isPublic',
            key: 'isPublic',
            render: (isPublic) => {
                return (
                    isPublic ? <Badge status='success' text='公開' /> : <Badge status='default' text='非公開' />
                );
            },
            title: '公開設定',
            width: 20,
        },
        {
            dataIndex: 'isPublicResult',
            key: 'isPublicResult',
            render: (isPublicResult) => {
                return (
                    isPublicResult ? <Badge status='success' text='公開' /> : <Badge status='default' text='非公開' />
                );
            },
            title: '結果公開設定',
            width: 20,
        },
        {
            dataIndex: 'durationPublish',
            key: 'durationPublish',
            render: ({ start_at, end_at }) => (
                <>
                    <span>{moment.unix(start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
            title: '公開期間',
            width: 25,
        },
        {
            dataIndex: 'durationPerform',
            key: 'durationPerform',
            render: ({ start_at, end_at }) => (
                <>
                    <span>{moment.unix(start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
            title: '実施期間',
            width: 25,
        },
        {
            dataIndex: 'durationResult',
            key: 'durationResult',
            render: ({ start_at, end_at }) => (
                <>
                    <span>{moment.unix(start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
            title: '集計公開期間',
            width: 25,
        },
        {
            dataIndex: 'isLocked',
            key: 'isLocked',
            render: (isLocked) => {
                return isLocked ?
                    <Badge status='processing' text='回答あり' /> :
                    <Badge status='default' text='回答なし' />;
            },
            title: '回答状態',
            width: 25,
        },
        {
            dataIndex: 'author',
            key: 'author',
            render: ({ create, update }) => (
                <>
                    <span>{create}</span>
                    <br />
                    <span>{update}</span>
                </>
            ),
            title: '作成者/更新者',
            width: 25,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: ({ created_at, updated_at }) => (
                <>
                    <span>{moment.unix(created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: 25,
        },
        {
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (_, record) => (
                <Row
                    align='middle'
                    justify='space-around'
                >
                    <Col span={6}>
                        <CircleButton
                            icon={<EditOrInfoIcon record={record} shouldCheckCanAdminSetPublic={true} />}
                            onClick={() => handleEditClick(record)}
                            size='small'
                            tooltipProps={{
                                title: hasTouchScreen ?
                                    undefined :
                                    createPublishedItemEditOrInfoButtonTooltipTitle(record, true, '編集'),
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <CircleButton
                            disabled={record.isPublishedItem}
                            icon={<FileAddOutlined />}
                            onClick={() => handleCopyClick(record)}
                            size='small'
                            tooltipProps={{
                                title: hasTouchScreen ?
                                    undefined :
                                    createPublishedItemButtonTooltipTitle(record, 'コピーして作成'),
                            }}
                        />
                    </Col>
                    {isControl
                        ? null
                        : <Col span={6}>
                            <CircleButton size='small'
                                icon={<CopyOutlined />}
                                onClick={genHandleClickNewQuestionnaire(record.key)}
                                tooltipProps={{ title: hasTouchScreen ? undefined : 'アンケート URL のコピー' }}
                            />
                        </Col>
                    }
                    <Col span={6}>
                        <CircleButton size='small'
                            icon={<LineChartOutlined />}
                            onClick={() => handleResultClick(record)}
                            tooltipProps={{ title: hasTouchScreen ? undefined : '回答結果の確認' }}
                        />
                    </Col>
                    <Col span={6}>
                        <CircleButton size='small'
                            icon={<SolutionOutlined />}
                            onClick={() => handleTargetClick(record)}
                            tooltipProps={{ title: hasTouchScreen ? undefined : '公開対象者の確認' }}
                        />
                    </Col>
                    <Col span={6}>
                        <CircleButton danger size='small'
                            disabled={record.isPublishedItem}
                            icon={<DeleteOutlined />}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                okText: '削除',
                                onConfirm: () => handleDeleteClick(record.key),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>アンケート結果も全て削除されます。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>
                                            削除すると復元することはできません。削除してもよろしいですか？
                                        </span>
                                    </>
                                ),
                            }}
                            tooltipProps={{
                                title: hasTouchScreen ?
                                    undefined :
                                    createPublishedItemButtonTooltipTitle(record, '削除'),
                            }}
                        />
                    </Col>
                </Row>
            ),
            title: '',
            width: 20,
        },
    ];
};

const genHandleClickNewQuestionnaire = (uuid) => () => {
    navigator.clipboard
        .writeText(`${generateUserDomain()}/questionnaire/direct-answer/${uuid}`)
        .then(
            () => { message.success('URL をコピーしました。'); },
            () => { message.error('URL のコピーに失敗しました。'); }
        );
};

import * as React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';

import Spin from 'components/modules/Spin';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

import { useStatisticsExam } from '../../hooks/useStatisticsExam';

type Props = {
    latestResultOnly: boolean
    tabName: 'board' | 'school' | undefined
    title: string
    uuid: string
}

const ExamStatisticsPopover: React.VFC<Props> = ({
    latestResultOnly,
    tabName,
    title,
    uuid,
}) => {
    // TODO: こいつを削除
    const { modal: {
        examCategoryStatus: { search },
    } } = useAppSelector(selectors.examCategoryTab);

    const [statisticsExam, requestStatisticsExam] = useStatisticsExam();


    const fetchStatics = () => {
        const param = {
            latest_result_only: latestResultOnly,
            role_uuid: search.role_uuid,
            school_class_uuid: search.school_class_uuid,
        };

        requestStatisticsExam(uuid, param, tabName === 'board' ? 'control' : 'default');
    };

    const getStatics = () => {
        const statistics = statisticsExam.result[uuid];
        (statistics === undefined || statistics.latestResultOnly !== latestResultOnly) && fetchStatics();
    };

    const statisticsToView = statisticsExam.result[uuid]?.statistics;
    return (
        <Popover
            content={
                <div
                    style={{ overflowWrap: 'normal', width: '300px' }}
                >
                    <Spin spinning={statisticsExam.isLoading}>
                        <p><b>{title}</b></p>
                        <p>集計結果</p>
                        <Row style={{ margin: '12px' }}>
                            <Col span={6}>解答者数</Col>
                            <Col span={6}>{statisticsToView?.resultCount}</Col>
                            <Col span={6}>最高点</Col>
                            <Col span={6}>{statisticsToView?.highestScore}</Col>
                            <Col span={6}>満点</Col>
                            <Col span={6}>{statisticsToView?.maxScore}</Col>
                            <Col span={6}>最低点</Col>
                            <Col span={6}>{statisticsToView?.lowestScore}</Col>
                            <Col span={6}>平均点</Col>
                            <Col span={6}>{statisticsToView?.averageScore} </Col>
                        </Row>
                    </Spin>
                </div >
            }
            onVisibleChange={(visible) => visible && getStatics()}
        >
            <InfoCircleOutlined />
        </Popover >
    );
};

export default ExamStatisticsPopover;
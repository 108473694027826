import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

/**
 * クエリ文字列を Object(Key をパラメーター, Value を値として持つ) にしたものを返す
 */
export const useUrlParameters = () => {
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.sort();
    const [retObj, setRetObj] = useState<Record<string, string>>(
        Array.from(urlSearchParams).reduce((acc, [key, val]) => {
            acc[key] = val;
            return acc;
        }, {} as Record<string, string>)
    );

    useEffect(() => {
        setRetObj(
            Array.from(urlSearchParams).reduce((acc, [key, val]) => {
                acc[key] = val;
                return acc;
            }, {} as Record<string, string>)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return retObj;
};

/**
 * クエリ文字列のパラメーター名をまとめたもの
 */
// あんまりここに書くのはよくなさそう
// あまりにも多くなる場合は使う場所でまとめるなどしたほうが良い
export const QUERY_STRING_PARAMS = {
    applicationGroup: 'ag' as const,
    currentTab: 'ct' as const,
    isRequired: 'isRequired' as const,
    resultExists: 'resultExists' as const,
    status: 'status' as const,
    testGroup: 'tg' as const,
    title: 'title' as const,
};

import { handleAction } from 'redux-actions';
import * as Actions from 'flex/Actions';

const defaultState = {
    payload: {},
    meta: {
        fetch: false,
        status: '',
        viewName: '',
    },
};

const defaultReducer = {
    next: (state: any, action: any) => {
        return { ...action };
    },
    throw: (state: any, action: any) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE,
            },
        }));
    },
};


export const ogpViewDataConnection = handleAction(
    Actions.http.connection.ogp.viewData,
    defaultReducer,
    defaultState
);
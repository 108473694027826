import * as Actions from 'flex/Actions';

// 本来であれば, 各 ViewLogic ごとに savingDataForConnection を作成するべきだが,
// 今後 ViewLogic を廃止する方針に則り, あくまでアンケート関連の処理をわかりやすくすることのみを意識し,
// アンケート関連の通信をまとめた SavingData を作成した.
// 上記の方針に則り, 型付けも最低限としている.
export const savingDataForConnection = (
    store: StoreAccessor,
    next: StoreNext<unknown>,
    // TODO: レスポンスに対する型が定義されておらず,
    // 現状で対応することは手間がかかりすぎるので, 一旦 any として対応する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: ActionType<{ result: any }, { viewName: string }>,
) => {
    // TODO: 適切な型付けが不可能なので, 仕方なく強制的に動く形で型アサーションしている
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentState = (store.getState() as unknown as Record<string, any>)[action.meta.viewName];
    const isControllableUser = store.getState().isControllableUser.payload;

    switch (action.type) {
        case Actions.http.connection.questionnaires.controlSearch().type:
        case Actions.http.connection.questionnaires.search().type: {
            next({
                type: action.meta.viewName,
                ...currentState,
                pagination: currentState['pagination'] && {
                    ...currentState['pagination'],
                    total: action.payload.result.item_count,
                },
                questionnaireItemCount: action.payload.result.item_count,
                questionnaireList: action.payload.result.items,
            });
            break;
        }
        case Actions.http.connection.questionnaires.controlView().type: {
            const result = action.payload.result as Http.Connection.Response.Questionnaire.Control.View['result'];
            const {
                publish_to_all_organizations: publishToAllOrganizations,
                target_permissions: targetPermissions,
                ...rest
            } = result;

            const questionnaire = {
                ...rest,
                // TODO: 実装当時は true ならば 1, false ならば 0 が帰ってきていたが,
                // もしかしたら今後 boolean で返ってくるように変更されるかもしれないかもしれないので, 二重否定を使用している
                publish_to_all_organizations: !!publishToAllOrganizations,
                target_permissions: targetPermissions.map(p => p.code),
            };

            if (action.meta.viewName === 'editQuestionnaire') {
                const modalState = store.getState().questionnaireTab.modal;
                const { uuid, shouldCreate } = modalState;
                const isCopy = uuid && shouldCreate;
                const converter: typeof convertCopiedQuestionnaire = isCopy ? convertCopiedQuestionnaire : e => e;

                next({
                    ...currentState,
                    questionnaire: converter(questionnaire),
                    type: action.meta.viewName,
                });
                break;
            } else if (action.meta.viewName === 'resultQuestionnaire') {
                const uuid = result.uuid;
                const THRESHOLD = 20000;

                const questionCount = result.items.length;
                const answeredCount = store.getState()['resultQuestionnaire'].source.answeredCount;
                const value = answeredCount * questionCount;

                if ( value <= THRESHOLD ) {
                    store.dispatch(Actions.http.connection.questionnaires.controlAnswerResult(
                        uuid,
                        'resultQuestionnaire',
                    ));
                } else {
                    next({
                        type: action.meta.viewName,
                        ...currentState,
                        questionnaire: {
                            ...questionnaireConvert(result, isControllableUser),
                            isListView: false,
                        },
                    });
                }
                break;
            }

            next({
                ...currentState,
                questionnaire: questionnaireConvert(result, isControllableUser),
                type: action.meta.viewName,
            });

            break;
        }
        case Actions.http.connection.questionnaires.view().type: {
            const questionnaire = action.payload.result as Http.Connection.Response.Questionnaire.Admin.View['result'];

            if (action.meta.viewName === 'editQuestionnaire') {
                const modalState = store.getState().questionnaireTab.modal;
                const { uuid, shouldCreate } = modalState;
                const isCopy = uuid && shouldCreate;

                next({
                    ...currentState,
                    questionnaire: isCopy ?
                        convertCopiedQuestionnaire(questionnaire) :
                        questionnaire,
                    type: action.meta.viewName,
                });
                break;
            } else if (action.meta.viewName === 'resultQuestionnaire') {
                const uuid = questionnaire.uuid;
                const THRESHOLD = 20000;

                const questionCount = questionnaire.items.length;
                const answeredCount = store.getState()['resultQuestionnaire'].source.answeredCount;
                const value = answeredCount * questionCount;

                if (value <= THRESHOLD) {
                    store.dispatch(Actions.http.connection.questionnaires.answerResult(
                        uuid,
                        'resultQuestionnaire',
                    ));
                } else {
                    next({
                        type: action.meta.viewName,
                        ...currentState,
                        questionnaire: {
                            ...questionnaireConvert(questionnaire, isControllableUser),
                            isListView: false,
                        },
                    });
                }
                break;
            }

            next({
                ...currentState,
                questionnaire,
                type: action.meta.viewName,
            });
            break;
        }
        case Actions.http.connection.questionnaires.controlAnswerResult().type:{
            const result = action.payload.result as Http.Connection.Response.Questionnaire.Control.AnswerResult['result'];
            next({
                ...currentState,
                questionnaire: {
                    ...questionnaireConvert(result, isControllableUser),
                    isListView: true,
                },
                type: action.meta.viewName,
            });
            break;
        }
        case Actions.http.connection.questionnaires.answerResult().type: {
            const result = action.payload.result as Http.Connection.Response.Questionnaire.Admin.AnswerResult['result'];
            next({
                ...currentState,
                questionnaire: {
                    ...questionnaireConvert(result, isControllableUser),
                    isListView: true,
                },
                type: action.meta.viewName,
            });
            break;
        }
        case Actions.http.connection.questionnaires.controlFileResult().type:
        case Actions.http.connection.questionnaires.controlTextResult().type:
        case Actions.http.connection.questionnaires.fileResult().type:
        case Actions.http.connection.questionnaires.textResult().type: {
            next({
                type: action.meta.viewName,
                ...currentState,
                pagination: currentState['pagination'] && {
                    ...currentState['pagination'],
                    total: action.payload.result.item_count,
                },
                resultList: action.payload.result.items,
                resultListItemCount: action.payload.result.items.item_count,
            });
            break;
        }
        case Actions.http.connection.questionnaires.target().type :{
            const {
                answered_count: answeredCount,
                total_count: totalCount,
            } = action.payload.result;
            // action.meta の型付けが適当なので, 型アサーションする.
            const { uuid, viewName } = action.meta as { uuid: string, viewName: string };

            next({
                type: action.meta.viewName,
                ...currentState,
                source: {
                    answeredCount: answeredCount,
                    totalCount: totalCount,
                },
            });

            // controlView と controlAnswerResult はレスポンスの形式は同じだが,
            // controlAnswerResult では回答数が計測されている点から,
            // レスポンスが返ってくるまでに controlView の数十倍の時間がかかることがあるので,
            // controlView で設問数の確認を行なう.
            store.dispatch(Actions.http.connection.questionnaires.view(
                uuid,
                viewName,
            ));
            break;
        }
        case Actions.http.connection.questionnaires.controlTarget().type: {
            const {
                answered_count: answeredCount,
                total_count: totalCount,
            } = action.payload.result;
            // action.meta の型付けが適当なので, 型アサーションする.
            const { uuid, viewName } = action.meta as { uuid: string, viewName: string };

            next({
                type: action.meta.viewName,
                ...currentState,
                source: {
                    answeredCount: answeredCount,
                    totalCount: totalCount,
                },
            });

            // controlView と controlAnswerResult はレスポンスの形式は同じだが,
            // controlAnswerResult では回答数が計測されている点から,
            // レスポンスが返ってくるまでに controlView の数十倍の時間がかかることがあるので,
            // controlView で設問数の確認を行なう.
            store.dispatch(Actions.http.connection.questionnaires.controlView(
                uuid,
                viewName,
            ));
            break;
        }
        case Actions.http.connection.questionnaireCategory.controlSearch().type:
        case Actions.http.connection.questionnaireCategory.search().type: {
            switch (action.meta.viewName) {
                case 'editQuestionnaire':
                case 'questionnaireTab': {
                    next({
                        type: action.meta.viewName,
                        ...currentState,
                        questionnaireCategoryList: action.payload.result.items,
                        questionnaireCategoryListItemCount: action.payload.result.item_count,
                    });
                    break;
                }
                case 'questionnaireCategoryTab': {
                    next({
                        type: action.meta.viewName,
                        ...currentState,
                        pagination: currentState['pagination'] && {
                            ...currentState['pagination'],
                            total: action.payload.result.item_count,
                        },
                        questionnaireCategoryList: action.payload.result.items,
                        questionnaireCategoryListItemCount: action.payload.result.item_count,
                    });
                    break;
                }
                default: {
                    break;
                }
            }
            break;
        }
        default:
            break;
    }
};

/***
 * questionnaire のパラメータをコピー用に変換する
 */
// TODO: 型付けが面倒なので一旦 any とする
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertCopiedQuestionnaire = (questionnaire: any) => {
    // 不要なデータを取り除く
    const {
        /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention */
        uuid,
        created_by,
        updated_by,
        created_at,
        updated_at,
        is_locked,
        ...copyQuestionnaire
    } = questionnaire;
    /* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention */

    copyQuestionnaire.name = copyQuestionnaire.name + 'のコピー';
    return copyQuestionnaire;
};


type ResponseResult = Http.Connection.Response.Questionnaire.Control.View['result'] |
    Http.Connection.Response.Questionnaire.Admin.View['result']

const questionConvert = (question: ResponseResult['items'][0]): Question => {
    return {
        answerCandidates: question.answer_candidates.map(c => ({
            answerCount: c.answer_count ?? undefined,
            content: c.content,
            createdAt: c.created_at,
            order: c.order,
            updatedAt: c.updated_at,
            uuid: c.uuid,
        })),
        answerCount: question.answer_count ?? undefined,
        answers: question.answers,
        content: question.content,
        createdAt: question.created_at,
        detail: question.detail,
        file: question.file ?
            {
                fileName: question.file.file_name,
                uuid: question.file.uuid,
            } :
            undefined,
        isRequired: question.is_required,
        order: question.order,
        questionItemBranchChoices: question.question_item_branch_choices,
        type: question.type,
        updatedAt: question.updated_at,
        uuid: question.uuid,
    };
};

const questionnaireConvert = (questionnaire: ResponseResult, isControllableUser: boolean): Questionnaire => {
    const baseCategory = {
        canSetForced: questionnaire.category.can_set_forced,
        createdAt: questionnaire.category.created_at,
        createdBy: questionnaire.category.created_by,
        description: questionnaire.category.description,
        name: questionnaire.category.name,
        order: questionnaire.category.order ?? undefined,
        updatedAt: questionnaire.category.updated_at ?? undefined,
        updatedBy: questionnaire.category.updated_by,
        uuid: questionnaire.category.uuid,
    };

    const base = {
        afterMessage: questionnaire.after_message,
        canBeAnsweredOnlyOnce: questionnaire.can_be_answered_only_once,
        content: questionnaire.content,
        createdAt: questionnaire.created_at,
        createdBy: questionnaire.created_by,
        isAnonymous: questionnaire.is_anonymous,
        isEditable: questionnaire.is_editable,
        isForced:questionnaire.is_forced,
        isLocked: questionnaire.is_locked,
        isPublic: questionnaire.is_public,
        isPublicResult: questionnaire.is_public_result,
        items: questionnaire.items.map(questionConvert),
        memo: questionnaire.memo,
        name: questionnaire.name,
        performEndAt: questionnaire.perform_end_at,
        performStartAt: questionnaire.perform_start_at,
        publishEndAt: questionnaire.publish_end_at,
        publishFrom: questionnaire.publish_from,
        publishResultEndAt: questionnaire.publish_result_end_at,
        publishResultStartAt: questionnaire.publish_result_start_at,
        publishStartAt: questionnaire.publish_start_at,
        statusPeriod: questionnaire.status_period,
        updatedAt: questionnaire.updated_at,
        updatedBy: questionnaire.updated_by,
        uuid: questionnaire.uuid,
    };

    if(isControllableUser) {
        const q = questionnaire as Http.Connection.Response.Questionnaire.Control.View['result'];
        return {
            ...base,
            canAdminSetPublic: q.can_admin_set_public ?? false,
            category: {
                ...baseCategory,
                kind: 'control',
            },
            kind: 'control',
            publishToAllOrganizations: q.publish_to_all_organizations,
            targetOrganizations: q.target_organizations,
            targetPermissions: q.target_permissions,
        };
    } else {
        const q = questionnaire as Http.Connection.Response.Questionnaire.Admin.View['result'];
        return {
            ...base,
            canAdminSetPublic: q.can_admin_set_public ?? undefined,
            category: {
                ...baseCategory,
                isPublishedItem: q.category.is_published_item,
                kind: 'admin',
            },
            isPublishedItem: undefined,
            kind: 'admin',
            targetRoles: q.target_roles,
            targetSchoolClasses: q.target_school_classes,
            targetUsers: q.target_users,
        };
    }
};
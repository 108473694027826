import {
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import {
    DeleteOutlined,
    PlusCircleFilled,
    BarChartOutlined,
    SortAscendingOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';

import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import {
    createPublishedItemButtonTooltipTitle,
    EditOrInfoIcon,
    PublishedItemTitleHint,
} from 'components/modules/PublishedItems';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import { QUERY_STRING_PARAMS, useUrlParameters } from 'constants/CustomHooks/useUrlParameters';
import { generateFileDomain, generateLtiLaunchDomain, getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

import { AppGroupStatus } from './AppGroupStatus';
import AppsSearch from './AppsSearch';
import EditApp from './EditApp';
import EditAppGroups from './EditAppGroups';
import EditOrderAppGroups from './EditOrderAppGroups';
import EditOrderApps from './EditOrderApps';

import { SourceContext } from './index';
const moment = getMoment();


const AppsList = () => {
    const [source, setSource] = useContext(SourceContext);
    const dispatch = useDispatch();
    const urlParameters = useUrlParameters();
    const applicationGroupUuid = urlParameters[QUERY_STRING_PARAMS.applicationGroup];
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isEditAppOpen, setIsEditAppOpen] = useState(false);
    const [isEditAppCategoryOrderOpen, setIsEditAppCategoryOrderOpen] = useState(false);
    const [isEditAppOrderOpen, setIsEditAppOrderOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [shouldCreate, setShouldCreate] = useState(true);
    const [record, setRecord] = useState(null);
    const [groupRecord, setGroupRecord] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentCategoryApplications, setCurrentCategoryApplications] = useState([]);
    const subWindowRef = useRef(null);
    const appsSaveConnection = useAppSelector(state => state.appsSaveConnection);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    /**
     * URL の param を元に実施状況確認モーダルを表示したかを確認するための Ref
     *
     * 一度開いたらもう開かないようにする.
     */
    const isOpenStatusModalFromUrlParams = useRef(false);
    const isViewGet = useRef(false);

    const currentApplicationCategory = useMemo(() => {
        return source.appsList.find(item => item.uuid === currentCategory?.uuid);
        // currentCategoryUuidのみ監視したいため
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCategory?.uuid]);

    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();

    const changeLocalStorage = useCallback((event) => {
        if (!currentCategory?.uuid) return;

        const appDeepLinkingItem = event.storageArea.getItem('appDeepLinking');
        const deeplinkingresponseItem = event.storageArea.getItem('deeplinkingresponse');
        if (appDeepLinkingItem === 'true' || deeplinkingresponseItem === 'true') {
            setSource(source => ({ ...source, loading: true }));
            dispatch(Actions.http.connection.apps.save());
        }
        // currentCategoryUuidのみ監視したいため
    }, [currentCategory?.uuid]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('storage', changeLocalStorage);
        return () => {
            window.removeEventListener('storage', changeLocalStorage);
        };
    }, [changeLocalStorage]);

    useEffect(() => {
        if (appsSaveConnection.meta.status !== Actions.statusEnum.SUCCESS) return;

        const appDeepLinkingItem = localStorage.getItem('appDeepLinking');
        const deeplinkingresponseItem = localStorage.getItem('deeplinkingresponse');

        if (appDeepLinkingItem === 'true') {
            localStorage.removeItem('appDeepLinking');
        }
        if (deeplinkingresponseItem === 'true') {
            localStorage.removeItem('deeplinkingresponse');
        }
        if (appDeepLinkingItem === 'true' || deeplinkingresponseItem === 'true') {
            setSource(source => ({ ...source, loading: false }));
            dispatch(Actions.http.connection.apps.category.view(currentCategory.uuid, currentCategory.uuid));
            setCurrentCategory(null);
        }
        // appsSaveConnectionのみ監視したいため
    }, [appsSaveConnection]);// eslint-disable-line react-hooks/exhaustive-deps

    const pagination = {
        ...source.pagination,
        onChange: (current, pageSize) => {
            setSource({ ...source, pagination: { current, pageSize } });
            handleSearch(source.search, current, pageSize);
        },
        position: ['topRight', 'bottomRight'],
        showSizeChanger: false, // 明示的にfalse
        showTotal: (total, range) => (`全${total}件中 ${range[0]}-${range[1]} 件`),
        total: source.appsListItemCount,
    };

    const handleSearch = (search, page, pageSize) => {
        const newSearch = search ?? source.search;
        const newPage = page ?? 1;
        const newPageSize = pageSize ?? source.pagination.pageSize;
        const params = { ...newSearch, page_size: newPageSize };
        const newPagination = {
            ...source.pagination,
            current: newPage,
            pageSize: newPageSize,
        };

        setSource({
            ...source,
            categorySearch: { loading: true },
            pagination: newPagination,
            search: newSearch,
        });

        dispatch(Actions.http.connection.apps.category.search(params, newPage));
    };

    useEffect(() => {
        if (isOpenStatusModalFromUrlParams.current) return; //代理ログインでの初回モーダル表示をまだ行っていなければ通過
        if (!applicationGroupUuid) return; //urlからアプリケーションカテゴリのUUIDを受け取っていたら通過
        if (!isViewGet.current) return; //代理ログイン時の対象カテゴリのviewが実行されてていれば通過

        if (!source.appCategoryMap.applicationGroupUuid?.loading) {
            setGroupRecord(source.appCategoryMap[applicationGroupUuid].item);
            setIsStatusOpen(true);
            isOpenStatusModalFromUrlParams.current = true;
        }
        // sourceのみ監視したいため
    }, [source]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleSearch();

        // 代理ログイン時の処理
        if (isOpenStatusModalFromUrlParams.current) return; //代理ログインでの初回モーダル表示をまだ行っていなければ通過
        if (!applicationGroupUuid) return; //urlからアプリケーションカテゴリのUUIDを受け取っていたら通過

        dispatch(Actions.http.connection.apps.category.view(applicationGroupUuid, applicationGroupUuid));
        // 初回のみ実行のため
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // 代理ログイン時対象カテゴリのviewが実行されたかどうかを判断する
    useEffect(() => {
        if (isOpenStatusModalFromUrlParams.current) return; //代理ログインでの初回モーダル表示をまだ行っていなければ通過
        if (!applicationGroupUuid) return; //urlからアプリケーションカテゴリのUUIDを受け取っていたら通過
        if (!isViewGet.current && (applicationGroupUuid in source.appCategoryMap)) { //viewが行われapplicationGroupUuidのプロパティが作成されたら通過
            isViewGet.current = true;
        }
        // sourceのみ監視したいため
    }, [source]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleCreateClick = () => {
        setGroupRecord(null);
        setShouldCreate(true);
        setIsEditOpen(true);
    };

    const handleEditClick = (record) => {
        setGroupRecord(record);
        setShouldCreate(false);
        setIsEditOpen(true);
    };

    const handleDeleteClick = (id) => {
        dispatch(Actions.http.connection.apps.category.delete(id));
    };

    const handleStatusClick = (record) => {
        setGroupRecord(source.appCategoryMap[record.uuid] ?
            source.appCategoryMap[record.uuid].item :
            record
        );
        setIsStatusOpen(true);
    };

    const handleEditSubmitted = () => {
        handleSearch();
        setIsEditOpen(false);
    };
    const handleEditCancel = () => setIsEditOpen(false);

    const handleCreateAppClick = (categoryRecord) => {
        setRecord(null);
        setShouldCreate(true);
        setCurrentCategoryApplications(categoryRecord.applications);
        setCurrentCategory(categoryRecord);

        if (categoryRecord.use_deep_linking) {
            subWindowRef.current = window.open(
                `${generateLtiLaunchDomain(isControllableUser, true)}/init-app-deep-linking/${categoryRecord.uuid}`,
                '_blank'
            );
        } else {
            setIsEditAppOpen(true);
        }
    };

    const handleEditAppOrderClick = (categoryRecord) => {
        setRecord(null);
        const category = source.appCategoryMap[categoryRecord.uuid];
        setCurrentCategoryApplications(category?.item?.applications || []);
        setCurrentCategory(categoryRecord);
        setIsEditAppOrderOpen(true);
    };

    const handleDeleteAppClick = (id, categoryUuid) => {
        dispatch(Actions.http.connection.apps.delete(id, categoryUuid));
    };

    const handleEditAppClick = (record, categoryRecord) => {
        setShouldCreate(false);
        setCurrentCategory(categoryRecord);
        setRecord(record);
        setIsEditAppOpen(true);
    };

    const handleEditAppSubmitted = () => setIsEditAppOpen(false);
    const handleEditAppCancel = () => setIsEditAppOpen(false);

    const handleEditAppGroupsOrderSubmitted = () => {
        handleSearch();
        setIsEditAppCategoryOrderOpen(false);
    };
    const handleEditOrder = () => setIsEditAppCategoryOrderOpen(true);

    const handleEditAppGroupsOrderCancel = () => {
        // 並び替えモーダルを開いた際に page_size=500 が実行されるため、
        // 一覧の件数も更新されてしまうので、キャンセルしたときにも再更新が必要となる
        handleSearch();
        setIsEditAppCategoryOrderOpen(false);
    };

    const handleEditAppOrderSubmitted = () => setIsEditAppOrderOpen(false);
    const handleEditAppOrderCancel = () => setIsEditAppOrderOpen(false);

    const handleStatusCancel = () => setIsStatusOpen(false);

    const columns = [
        {
            dataIndex: 'title',
            key: 'title',
            render: (title, record) => (
                <span>{title} <PublishedItemTitleHint record={record} /></span>
            ),
            title: <span>教材・アプリグループ名称<br />（小学校３年生以上）</span>,
            width: 250,
        },
        {
            dataIndex: 'title_for_lower',
            key: 'title_for_lower',
            title: <span>教材・アプリグループ名称<br />（小学校１・２年生）</span>,
            width: 250,
        },
        {
            dataIndex: 'description',
            key: 'description',
            title: '概要',
            width: 190,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.publish_start_at).format('YYYY/MM/DD HH:mm')} から</span>
                    <br />
                    <span>{moment.unix(record.publish_end_at).format('YYYY/MM/DD HH:mm')} まで</span>
                </>
            ),
            title: '公開期間',
            width: 180,
        },
        {
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                return (
                    <>
                        <span>{record.created_by.display_name}</span>
                        <br />
                        <span>{record.updated_by.display_name}</span>
                    </>
                );
            },
            title: '作成者/更新者',
            width: 150,
        },
        {
            dataIndex: 'datetime',
            key: 'datetime',
            render: (_, record) => (
                <>
                    <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    <br />
                    <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                </>
            ),
            title: '作成時間/更新時間',
            width: 160,
        },
        {
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (text, record) => (
                <Row align='middle' justify='space-around' type='flex'>
                    <Col>
                        <CircleButton
                            icon={<EditOrInfoIcon record={record} shouldCheckCanAdminSetPublic={true} />}
                            onClick={() => handleEditClick(record)}
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record, '編集') }}
                        />
                    </Col>
                    <Col>
                        <CircleButton
                            danger
                            disabled={record.is_published_item}
                            icon={<DeleteOutlined />}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                okText: '削除',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: (
                                    <>
                                        <span>アプリグループを削除します。よろしいですか。</span>
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                    </>
                                ),
                            }}
                            shape='circle'
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record) }}
                        />
                    </Col>
                    {record.use_ags &&
                        <Col>
                            <CircleButton
                                icon={<BarChartOutlined />}
                                onClick={() => handleStatusClick(record)}
                                size='small'
                                tooltipProps={{ title: hasTouchScreen ? undefined : '対象者と実施結果を確認' }}
                            />
                        </Col>
                    }
                </Row>
            ),
            title: '',
            width: 120,
        },
    ];
    const expandedRowRender = (categoryRecord) => {
        const categoryMapData = source.appCategoryMap[categoryRecord.uuid];
        const appListLoading = categoryMapData?.loading;
        const applications = categoryMapData?.item?.applications ? categoryMapData?.item?.applications : [];

        const columns = [
            { dataIndex: 'title', key: 'title', title: 'タイトル', width: 350 },
            { dataIndex: 'description', key: 'description', title: '概要', width: 250 },
            {
                dataIndex: 'files',
                key: 'files',
                render: (row) => {
                    return row.map((value, index) => (
                        <img
                            alt='application_logo_img'
                            className='application_logo_img'
                            key={index}
                            src={`${generateFileDomain()}/file/view/${value.uuid}`}
                        />
                    )
                    );
                },
                title: 'アプリサムネイル',
                width: 200,
            },
            {
                dataIndex: 'operation',
                fixed: 'right',
                key: 'operation',
                render: (text, record) => (
                    <Row align='middle' justify='space-around' type='flex'>
                        <Col>
                            <CircleButton
                                icon={<EditOrInfoIcon record={record} />}
                                onClick={() => handleEditAppClick(record, categoryRecord)}
                                size='small'
                                tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record, '編集') }}
                            />
                        </Col>
                        <Col>
                            <CircleButton
                                className='mr-2'
                                danger
                                disabled={record.is_published_item}
                                icon={<DeleteOutlined />}
                                popconfirmProps={{
                                    cancelText: 'キャンセル',
                                    okText: '削除',
                                    onConfirm: () => handleDeleteAppClick(record.uuid, categoryRecord.uuid),
                                    placement: 'topRight',
                                    title: (
                                        <>
                                            <span>教材・アプリを削除します。よろしいですか。</span>
                                            <br />
                                            <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                                        </>
                                    ),
                                }}
                                shape='circle'
                                size='small'
                                tooltipProps={{ title: hasTouchScreen ? undefined : createPublishedItemButtonTooltipTitle(record) }}
                            />
                        </Col>
                    </Row>
                ),
                width: 120,
            },
        ];

        return (
            <div>
                <div className='flex-right-container gutter-bottom'>
                    <Button
                        className='mb-4'
                        icon={<SortAscendingOutlined />}
                        onClick={() => handleEditAppOrderClick(categoryRecord)}
                    >
                        並び替え
                    </Button>
                    <Tooltip title={createPublishedItemButtonTooltipTitle(categoryRecord, '')}>
                        <Button
                            className='mb-4'
                            disabled={categoryRecord.is_published_item}
                            icon={<PlusCircleFilled />}
                            onClick={() => handleCreateAppClick(categoryRecord)}
                            type='primary'
                        >
                            教材・アプリを追加
                        </Button>
                    </Tooltip>
                </div>

                <BaseTable
                    columns={columns}
                    dataSource={applications}
                    emptyDescription='配信されている教材・アプリはありません'
                    loading={appListLoading}
                    scroll={{ x: 1000 }}
                    showTopScroll={false}
                    size='large'
                />
            </div>
        );
    };

    const setSearch = (values) => {
        const newSearch = {
            ...source.search,
            ...values,
            publish_end_at: values.publish_end_at ? moment(values.publish_end_at).unix() : null,
            publish_start_at: values.publish_start_at ? moment(values.publish_start_at).unix() : null,
        };

        handleSearch(newSearch);
    };

    const onExpand = (expanded, record) => {
        if (expanded) {
            dispatch(Actions.http.connection.apps.category.view(record.uuid, record.uuid));
        }
    };

    return (
        <div className='page-apps container'>
            <AppsSearch loading={source.categorySearch.loading} setSearch={setSearch} />

            <div className='flex-right-container gutter-bottom'>
                <Button
                    icon={<SortAscendingOutlined />}
                    onClick={handleEditOrder}
                >
                    並び替え
                </Button>
                <Button
                    icon={<PlusCircleFilled />}
                    onClick={handleCreateClick}
                    type='primary'
                >
                    新規作成
                </Button>
            </div>

            <BaseTable
                columns={columns}
                dataSource={source.appsList}
                emptyDescription='配信されている教材・アプリグループはありません'
                expandable={{
                    expandedRowRender, onExpand,
                }}
                loading={source.categorySearch.loading || source.categoryEdit.loading}
                pagination={pagination}
                scroll={{ x: 1000 }}
                size='small'
            />

            <EditAppGroups
                onCancel={handleEditCancel}
                onOk={handleEditSubmitted}
                shouldCreate={shouldCreate}
                uuid={groupRecord && groupRecord.uuid}
                visible={isEditOpen}
            />

            <EditApp
                applications={currentCategoryApplications}
                category={currentCategory}
                onCancel={handleEditAppCancel}
                onOk={handleEditAppSubmitted}
                record={record}
                shouldCreate={shouldCreate}
                useDeepLinking={!!currentApplicationCategory?.use_deep_linking}
                visible={isEditAppOpen}
            />

            <EditOrderAppGroups
                onCancel={handleEditAppGroupsOrderCancel}
                onOk={handleEditAppGroupsOrderSubmitted}
                visible={isEditAppCategoryOrderOpen}
            />

            <EditOrderApps
                applications={currentCategoryApplications}
                onCancel={handleEditAppOrderCancel}
                onOk={handleEditAppOrderSubmitted}
                uuid={currentCategory?.uuid}
                visible={isEditAppOrderOpen}
            />

            <AppGroupStatus
                category={groupRecord}
                isOnlyAgsApplication={groupRecord && groupRecord.use_deep_linking}
                onCancel={handleStatusCancel}
                visible={isStatusOpen}
            />
        </div>
    );
};

export default AppsList;

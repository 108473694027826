import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table } from 'antd';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import * as Actions from 'flex/Actions';
import { useSource, createInitAppCategoryOrderEdit, ORDER_APP_CATEGORIES_PAGE_SIZE } from '../SourceProvider';
import BaseModal from 'components/modules/BaseModal';
import Spin from 'components/modules/Spin';

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const AppGroupsOrderModal = ({
    onOk,
    onCancel,
    visible = false,
}) => {
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([])
    const [source, setSource] = useSource();

    useEffect(() => {
        setDataSource(source.orderAppCategories.data.items.map((value, index) => ({
            ...value,
            index,
        })));
    }, [source.orderAppCategories.data.items]);

    useEffect(() => {
        if (source.appCategoryOrderEdit.loading) return;
        if (source.appCategoryOrderEdit.data.message === undefined) return;
        if (source.appCategoryOrderEdit.data.message.length === 0) return;
        onOk();
        setSource({
            ...source,
            appCategoryOrderEdit: createInitAppCategoryOrderEdit(false),
        });
    }, [source.appCategoryOrderEdit.data.message]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// 展開時の初回検索
        if (!visible) return;

        dispatch(Actions.http.connection.apps.category.controlSearch({ page_size: ORDER_APP_CATEGORIES_PAGE_SIZE }, 1));
    }, [visible])// eslint-disable-line react-hooks/exhaustive-deps


    const handleSubmit = () => {
        const data = {
            uuids: dataSource.map(value => value.uuid),
        }
        dispatch(Actions.http.connection.apps.category.controlChangeOrder(data));
    }

    const DragHandle = sortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        setDataSource(newData);
    };

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            helperClass='row-dragging'
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const columns = [
        {
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        }, {
            title: '教材・アプリグループ名称（小学校３年生以上）',
            dataIndex: 'title',
            width: 250,
            key: 'title',
            className: 'drag-visible',
        }, {
            title: '教材・アプリグループ名称（小学校１・２年生）',
            dataIndex: 'titleForLower',
            width: 250,
            key: 'titleForLower',
        }, {
            title: '概要',
            dataIndex: 'description',
            width: 250,
            key: 'description',
        },
    ];

    return (
        <BaseModal
            className='common-modal'
            title={<span>教材・アプリグループ並び順の編集</span>}
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button
                    key='back_order'
                    loading={source.appCategoryOrderEdit.loading}
                    size='large'
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='create_order'
                    loading={source.appCategoryOrderEdit.loading}
                    type='primary'
                    size='large'
                    onClick={handleSubmit}
                >
                    保存
                </Button>
            ]}>
            <div>
                <Spin spinning={source.orderAppCategories.loading}>
                    <Table
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey='index'
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                </Spin>
            </div>
        </BaseModal>
    );
}

export default AppGroupsOrderModal;

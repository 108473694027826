/* eslint-disable sort-keys */
import React, { useEffect, VFC } from 'react';

import {
    Button,
    Card,
    ColProps,
    Form,
    FormItemProps,
    Radio,
    RadioChangeEvent,
    Select,
    Tag,
} from 'antd';

import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { useTargetOrganization } from '../../hooks/useTargetOrganization';
import { useTargetSchoolClass } from '../../hooks/useTargetSchoolClass';

const { Option } = Select;

export const ALL_SCHOOL_VALUE = 'all-school';
export const ALL_CLASS_VALUE = 'all-class';

type Props = {
    currentTabKey: TabKeys | ''
    examUuid: string
    isControllableUser: boolean
    onFinish: (values: FormValues) => void
    value: FormValues
};

export const SearchForm: VFC<Props> = ({
    currentTabKey,
    examUuid,
    isControllableUser,
    onFinish,
    value,
}) => {
    if (isControllableUser) {
        return (
            <ControlSearchForm
                currentTabKey={currentTabKey}
                examUuid={examUuid}
                onFinish={onFinish}
                value={value}
            />
        );
    } else {
        return (
            <AdminSearchForm
                currentTabKey={currentTabKey}
                examUuid={examUuid}
                onFinish={onFinish}
                value={value}
            />
        );
    }
};

const AdminSearchForm: VFC<Omit<Props, 'isControllableUser'>> = ({
    currentTabKey,
    examUuid,
    onFinish,
    value,
}) => {
    const [form] = Form.useForm<FormValues>();
    const [searchClass, fetchClass] = useTargetSchoolClass();
    const [isErrorOfTargetClass2, setIsErrorOfTargetClass2] = React.useState(false);

    const viewType = Form.useWatch('viewType', form);

    useEffect(() => {
        fetchClass(examUuid, currentTabKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examUuid]);

    useEffect(() => {
        form.setFieldsValue(value);
        setIsErrorOfTargetClass2(false);
    }, [form, value]);


    const handleChangeViewType = (e: RadioChangeEvent) => {
        form.setFieldsValue(
            e.target.value === 'one' ?
                {
                    indicatorType: 'school',
                } :
                {
                    indicatorType1: 'school',
                    indicatorType2: 'school',
                    target2: undefined,
                }
        );
    };

    const handleFinish = (values: FormValues) => {
        if (
            values.viewType === 'two' && 'target1' in values && values.target2 === undefined
        ) {
            setIsErrorOfTargetClass2(true);
        } else {
            setIsErrorOfTargetClass2(false);
            onFinish(values);
        }
    };

    return (
        <Card className='search-form'>
            <Form {...FORM_LAYOUT} form={form} onFinish={handleFinish}>
                <Form.Item label='表示形式' name='viewType'>
                    <Radio.Group onChange={handleChangeViewType}>
                        <Radio value='one'>対象表示</Radio>
                        <Radio value='two'>比較表示</Radio>
                    </Radio.Group>
                </Form.Item>

                {viewType === 'one' && (
                    <TargetClassFormItem
                        listState={searchClass}
                        name='target1'
                    />
                )}

                {viewType === 'two' && (
                    <>
                        <TargetClassFormItem
                            index={1}
                            listState={searchClass}
                            name='target1'
                        />

                        <TargetClassFormItem
                            index={2}
                            isErrored={isErrorOfTargetClass2}
                            listState={searchClass}
                            name='target2'
                            onUpdateError={setIsErrorOfTargetClass2}
                        />
                    </>
                )}

                <div className='submit-container'>
                    <Button htmlType='submit' type='primary' >
                        表示
                    </Button>
                </div>
            </Form>
        </Card>
    );
};

const ControlSearchForm: VFC<Omit<Props, 'isControllableUser'>> = ({
    currentTabKey,
    examUuid,
    onFinish,
    value,
}) => {
    const [form] = Form.useForm<FormValues>();

    const [searchSchool, fetchSchool] = useTargetOrganization();
    const [searchClass, fetchClass] = useTargetSchoolClass();
    const [searchClass2, fetchClass2] = useTargetSchoolClass();
    const [isErrorOfTargetSchool2, setIsErrorOfTargetSchool2] = React.useState(false);

    const viewType = Form.useWatch('viewType', form);

    useEffect(() => {
        fetchSchool(examUuid);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examUuid]);

    useEffect(() => {
        form.setFieldsValue(value);
        setIsErrorOfTargetSchool2(false);
    }, [form, value]);

    const handleFinish = (values: FormValues) => {
        if (
            values.viewType === 'two' && 'target2_school' in values && values.target2_school === undefined
        ) {
            setIsErrorOfTargetSchool2(true);
        } else {
            setIsErrorOfTargetSchool2(false);
            onFinish(values);
        }
    };

    const handleChangeViewType = (e: RadioChangeEvent) => {
        if ('target1' in value || 'target2' in value) {
            form.setFieldsValue(
                e.target.value === 'one' ?
                    {
                        indicatorType: 'school',
                    } :
                    {
                        indicatorType1: 'school',
                        indicatorType2: 'school',
                        target2: undefined,
                    }
            );
        } else {
            form.setFieldsValue(
                e.target.value === 'one' ?
                    {
                        indicatorType: 'school',
                    } :
                    {
                        indicatorType1: 'school',
                        indicatorType2: 'school',
                        target2_class: undefined,
                        target2_school: undefined,
                    }
            );
        }
    };

    const handleChangeSchool1 = (uuid: string) => {
        if (uuid !== ALL_SCHOOL_VALUE) {
            fetchClass(examUuid, currentTabKey, uuid);
            form.setFieldsValue({
                target1_school: uuid,
                target1_class: ALL_CLASS_VALUE,
            });
        } else {
            form.setFieldsValue({
                target1_class: undefined,
            });
        }
    };
    const handleChangeSchool2 = (uuid: string) => {
        if (uuid !== ALL_SCHOOL_VALUE) {
            fetchClass2(examUuid, currentTabKey, uuid);
            form.setFieldsValue({
                target2_school: uuid,
                target2_class: ALL_CLASS_VALUE,
            });
        } else {
            form.setFieldsValue({
                target2_class: undefined,
            });
        }
    };

    return (
        <Card className='search-form'>
            <Form {...FORM_LAYOUT} form={form} onFinish={handleFinish}>
                <Form.Item label='表示形式' name='viewType'>
                    <Radio.Group onChange={handleChangeViewType}>
                        <Radio value='one'>対象表示</Radio>
                        <Radio value='two'>比較表示</Radio>
                    </Radio.Group>
                </Form.Item>

                {viewType === 'one' && (
                    <TargetSchoolFormItem
                        classListState={searchClass}
                        name='target1'
                        onChangeClass={(uuid) => form.setFieldsValue({
                            target1_class: uuid,
                        })}
                        onChangeSchool={handleChangeSchool1}
                        schoolListState={searchSchool}
                    />
                )}

                {viewType === 'two' && (
                    <>
                        <TargetSchoolFormItem
                            classListState={searchClass}
                            index={1}
                            name='target1'
                            onChangeClass={(uuid) => form.setFieldsValue({
                                target1_class: uuid,
                            })}
                            onChangeSchool={handleChangeSchool1}
                            schoolListState={searchSchool}
                        />

                        <TargetSchoolFormItem
                            classListState={searchClass2}
                            index={2}
                            isErrored={isErrorOfTargetSchool2}
                            name='target2'
                            onChangeClass={(uuid) => form.setFieldsValue({
                                target2_class: uuid,
                            })}
                            onChangeSchool={handleChangeSchool2}
                            onUpdateError={setIsErrorOfTargetSchool2}
                            schoolListState={searchSchool}
                        />
                    </>
                )}

                <div className='submit-container'>
                    <Button htmlType='submit' type='primary' >
                        表示
                    </Button>
                </div>
            </Form>
        </Card>
    );
};


type TargetClassFormItemProps = {
    index?: number
    isErrored?: boolean
    listState: ReturnType<typeof useTargetOrganization>[0] | ReturnType<typeof useTargetSchoolClass>[0],
    name: string
    onChange?: (uuid: string) => void
    value?: string
    onUpdateError?: (isErrored: boolean) => void
} & Omit<FormItemProps, 'name'>;

const TargetClassFormItem: VFC<TargetClassFormItemProps> = ({
    index,
    isErrored = false,
    label: propsLabel,
    listState,
    name,
    onChange,
    onUpdateError,
    value,
}) => {
    const list = listState.result?.items?.map(item => ({
        name: item.name,
        termName: 'termName' in item ? item.termName : '',
        uuid: item.uuid,
    }));

    const options = [{ uuid: ALL_CLASS_VALUE, name: '全クラス', termName: '' }].concat(list ?? []);
    const label = propsLabel ?? `対象${index ?? ''}`;

    const handleChange = (uuid: string) => {
        onChange?.(uuid);
        onUpdateError?.(false);
    };

    return (
        <Form.Item
            help={isErrored ? '対象2を選択してください' : undefined}
            label={label}
            name={name}
            validateStatus={isErrored ? 'error' : undefined}
        >
            <Select
                disabled={listState.isLoading}
                filterOption={(input, option) => !!option?.children?.at(1)?.includes(input)}
                loading={listState.isLoading}
                onChange={handleChange}
                placeholder={`${label}を選択してください`}
                showSearch
                value={value}
            >
                {options.map((value, index) => {
                    return (
                        <Option key={index} value={value.uuid}>
                            <Tag color='#AAAAAA' style={{  margin:'0 5px', width: '90px', textAlign: 'center', borderRadius: '5px' }}>
                                {value.termName}
                            </Tag>
                            {value.name}
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
};


type TargetSchoolFormItemProps = {
    classListState: ReturnType<typeof useTargetOrganization>[0] | ReturnType<typeof useTargetSchoolClass>[0],
    index?: number
    isErrored?: boolean
    name: string
    onChangeClass?: (uuid: string) => void
    onChangeSchool?: (uuid: string) => void
    onUpdateError?: (isErrored: boolean) => void
    schoolListState: ReturnType<typeof useTargetOrganization>[0] | ReturnType<typeof useTargetSchoolClass>[0],
    value?: string
} & Omit<FormItemProps, 'name'>;

const TargetSchoolFormItem: VFC<TargetSchoolFormItemProps> = ({
    classListState,
    label: propsLabel,
    name,
    index,
    isErrored,
    onChangeClass,
    onChangeSchool,
    onUpdateError,
    schoolListState,
}) => {
    const list = classListState.result?.items?.map(item => ({
        name: item.name,
        termName: 'termName' in item ? item.termName : '',
        uuid: item.uuid,
    }));

    const schoolList = [{ uuid: ALL_SCHOOL_VALUE, name: '全学校' }].concat(schoolListState.result?.items ?? []);
    const classList = [{ uuid: ALL_CLASS_VALUE, name: '学校全体', termName: '' }].concat(list ?? []);

    const schoolValue = Form.useWatch(`${name}_school`);
    const classValue = Form.useWatch(`${name}_class`);
    const label = propsLabel ?? `対象${index ?? ''}`;

    const handleChange = (uuid: string) => {
        onChangeSchool?.(uuid);
        onUpdateError?.(false);
    };

    return (
        <Form.Item label={label}>
            <Form.Item
                help={isErrored ? '学校を選択してください' : undefined}
                name={`${name}_school`}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }}
                validateStatus={isErrored ? 'error' : undefined}
            >
                <Select
                    disabled={schoolListState.isLoading}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    loading={schoolListState.isLoading}
                    onChange={handleChange}
                    options={schoolList.map((item) => ({
                        value: item.uuid,
                        label: item.name,
                    }))}
                    placeholder='学校を選択してください'
                    showSearch
                    value={schoolValue}
                />
            </Form.Item>

            <Form.Item
                name={`${name}_class`}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
                <Select
                    disabled={schoolListState.isLoading || classListState.isLoading || schoolValue === ALL_SCHOOL_VALUE}
                    filterOption={(input, option) => !!option?.children?.at(1)?.includes(input)}
                    loading={schoolListState.isLoading || classListState.isLoading}
                    onChange={onChangeClass}
                    placeholder='クラスを選択してください'
                    showSearch
                    value={classValue}
                >
                    {classList.map((value, index) => {
                        return (
                            <Option key={index} value={value.uuid}>
                                {value.termName !== '' &&
                                    <Tag color='#AAAAAA' style={{ margin: '0 5px', width: '90px', textAlign: 'center', borderRadius: '5px' }}>
                                        {value.termName}
                                    </Tag>
                                }
                                {value.name}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Form.Item>
    );
};


type Layout = {
    labelCol: ColProps,
    wrapperCol: ColProps,
};

export type FormValues = {
    indicatorType: 'school' | 'class'
    target1: string | undefined
    viewType: 'one'
} | {
    indicatorType1: 'school' | 'class'
    indicatorType2: 'school' | 'class'
    target1: string | undefined
    target2: string | undefined
    viewType: 'two'
} | {
    indicatorType: 'school' | 'class'
    target1_school: string | undefined
    target1_class: string | undefined
    viewType: 'one'
} | {
    indicatorType1: 'school' | 'class'
    indicatorType2: 'school' | 'class'
    target1_school: string | undefined
    target1_class: string | undefined
    target2_school: string | undefined
    target2_class: string | undefined
    viewType: 'two'
};


const FORM_LAYOUT: Layout = {
    labelCol: {
        sm: { span: 6 },
        md: { span: 6 },
        xl: { span: 5 },
        xxl: { span: 4 },
    },
    wrapperCol: {
        sm: { span: 18 },
        md: { span: 18 },
        xl: { span: 19 },
        xxl: { span: 20 },
    },
};

import * as React from 'react';

import { faSchool, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from 'antd';

import { TAB_NAMES } from 'flex/view/Assessment/examCategoryTabFlex';

import ExamDeliveryTemplate from '../../templates/ExamDeliveryTemplate';

const { TabPane } = Tabs;

export const TABS = {
    delivery_school: 'ds' as const,
    delivery_board: 'db' as const,
};

type Props = {
    activeKey: typeof TABS[keyof typeof TABS]
    onChangTab: (tab: string) => void
};

const ExamDeliveryContents: React.VFC<Props> = ({
    activeKey,
    onChangTab,
}) => {
    const localTab = activeKey === TABS.delivery_school ?
        TAB_NAMES.SCHOOL :
        TAB_NAMES.BOARD_OF_EDUCATION

    const onChange = (activeKey: string) => {
        const tab = activeKey === TAB_NAMES.SCHOOL ?
            TABS.delivery_school :
            TABS.delivery_board;
        onChangTab(tab);
    };

    return (
        <Tabs
            activeKey={localTab}
            onChange={onChange}
        >
            <TabPane
                key={TAB_NAMES.SCHOOL}
                tab={
                    <span>
                        <FontAwesomeIcon icon={faSchool} style={{ height: 14, marginRight: 10, width: 14 }} />
                        学校配信
                    </span>
                }
            >
                <ExamDeliveryTemplate
                    currentTabKeys={['delivery', localTab]}
                    isControllableUser={false}
                    isSchoolContents={true}
                    tabName={TAB_NAMES.SCHOOL}
                />
            </TabPane>
            <TabPane
                key={TAB_NAMES.BOARD_OF_EDUCATION}
                tab={
                    <span>
                        <FontAwesomeIcon icon={faSitemap} style={{ height: 14, marginRight: 10, width: 14 }} />
                        一斉配信
                    </span>
                }
            >
                <ExamDeliveryTemplate
                    currentTabKeys={['delivery', localTab]}
                    enableJumpToResult={true}
                    isControllableUser={false}
                    isSchoolContents={false}
                    tabName={TAB_NAMES.BOARD_OF_EDUCATION}
                />
            </TabPane>
        </Tabs>
    );
};

export default ExamDeliveryContents;

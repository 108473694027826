import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { generateLtiLaunchDomain } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';

export type UserExamState = {
    isLoading: boolean
    result: {
        exams: {
            createdAt: number
            children?: {
                activityProgress: 'Submitted' | 'Completed'
                agsTimestamp: string
                comment: string | undefined
                createdAt: number
                examResultUuid: string
                gradingProgress: 'PendingManual' | 'FullyGraded'
                maxScore: number
                score: number
            }[]
            gradingProgress: 'PendingManual' | 'FullyGraded'
            maxScore: number | undefined
            resultUrl: string | undefined
            score: number | undefined
            title: string
            uuid: string
            endAt: number,
            startAt: number,
            maxAttempts: number | undefined,
        }[]
        student: {
            uuid: string
            name: string
        } | undefined
    } | undefined
};

export type RequestUserExam = ReturnType<typeof useUserExam>[1];

export const useUserExam = () => {
    const [state, setState] = useState<UserExamState>({
        isLoading: false,
        result: undefined,
    });
    const [requestType, setRequestType] = useState<'control' | 'default'>();

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamCategoryControlResultConnection = useAppSelector(state => state.taoExamControlUserConnection);
    const taoExamUserConnection = useAppSelector(state => state.taoExamUserConnection);

    const dispatch = useDispatch();

    useEffect(() => {
        const { meta, payload: { result } } = taoExamCategoryControlResultConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;
        if (meta.status !== Actions.statusEnum.SUCCESS) return;
        const studentUuid = state.result?.student?.uuid ?? '';

        const exams = result.exam_results.map(exam => {
            const history = exam.result_history[0];
            const test = {
                createdAt: history?.created_at,
                endAt: exam.end_at,
                gradingProgress: history?.grading_progress,
                maxAttempts: exam.max_attempts ?? undefined,
                maxScore: history ? history.max_score ?? 0 : undefined,
                resultUrl: exam.result_url ?
                    requestType === 'control' ?
                        `${generateLtiLaunchDomain(isControllableUser, true)}/control-exam-result/${exam.uuid}/${studentUuid}` :
                        `${generateLtiLaunchDomain(isControllableUser, true)}/exam-result/${exam.uuid}/${studentUuid}` :
                    undefined,
                score: history ? history.score ?? 0 : undefined,
                startAt: exam.start_at,
                title: exam.title,
                uuid: exam.uuid,
            };
            const children = exam.result_history.slice(1).map(history => {
                return {
                    activityProgress: history.activity_progress,
                    agsTimestamp: history.ags_timestamp,
                    comment: history.comment ?? undefined,
                    createdAt: history.created_at,
                    examResultUuid: history.exam_result_uuid,
                    gradingProgress: history.grading_progress,
                    maxScore: history.max_score ?? 0,
                    score: history.score ?? 0,
                };
            });

            // antd の Table は dataSource に children が存在するか否かで
            // 行を展開するか否かを判断するため, 不要な場合は key からも削除して置かなければならない
            if (children && children.length > 0) {
                return Object.assign(test, { children });
            } else {
                return test;
            }
        });

        const student = {
            name: result.user.first_name + ' ' + result.user.last_name,
            uuid: result.user.uuid,
        };

        setState(state => ({
            ...state,
            isLoading: false,
            result: {
                exams,
                student,
            },
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamCategoryControlResultConnection]);

    useEffect(() => {
        const { meta, payload } = taoExamUserConnection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        if (meta.status !== Actions.statusEnum.SUCCESS) return;
        if (!('result' in payload)) return;

        const studentUuid = state.result?.student?.uuid ?? '';
        const result = payload.result;

        const exams = result.exam_results.map(exam => {
            const history = exam.result_history[0];
            const test = {
                createdAt: history?.created_at,
                endAt: exam.end_at,
                gradingProgress: history?.grading_progress,
                maxAttempts: exam.max_attempts ?? undefined,
                maxScore: history ? history.max_score ?? 0 : undefined,
                resultUrl: exam.result_url ?
                    requestType === 'control' ?
                        `${generateLtiLaunchDomain(isControllableUser, true)}/control-exam-result/${exam.uuid}/${studentUuid}` :
                        `${generateLtiLaunchDomain(isControllableUser, true)}/exam-result/${exam.uuid}/${studentUuid}` :
                    undefined,
                score: history ? history.score ?? 0 : undefined,
                startAt: exam.start_at,
                title: exam.title,
                uuid: exam.uuid,
            };
            const children = exam.result_history.slice(1).map(history => {
                return {
                    activityProgress: history.activity_progress,
                    agsTimestamp: history.ags_timestamp,
                    comment: history.comment ?? undefined,
                    createdAt: history.created_at,
                    examResultUuid: history.exam_result_uuid,
                    gradingProgress: history.grading_progress,
                    maxScore: history.max_score ?? 0,
                    score: history.score ?? 0,
                };
            });

            // antd の Table は dataSource に children が存在するか否かで
            // 行を展開するか否かを判断するため, 不要な場合は key からも削除して置かなければならない
            if (children && children.length > 0) {
                return Object.assign(test, { children });
            } else {
                return test;
            }
        });

        const student = {
            name: result.user.last_name !== '' || result.user.first_name !== '' ?
                result.user.last_name + ' ' + result.user.first_name :
                result.user.login_id,
            uuid: result.user.uuid,
        };

        setState(state => ({
            ...state,
            isLoading: false,
            result: {
                exams,
                student,
            },
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taoExamUserConnection]);

    const request = useCallback((
        examUuid: string,
        studentUuid: string,
        // あくまで学校管理者が操作する場合のみ必要なので、それ以外では使われない
        requestType?: 'control' | 'default'
    ) => {
        setState({
            isLoading: true,
            result: {
                exams: [],
                student: {
                    name: '',
                    uuid: studentUuid,
                },
            },
        });
        setRequestType(requestType);

        const userAction = requestType === 'control' ?
            Actions.http.connection.tao.exam.controlUser :
            Actions.http.connection.tao.exam.user;

        dispatch(userAction(examUuid, studentUuid, ''));
    }, [dispatch]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
            result: undefined,
        });
    }, []);

    return [state, request, resetState] as const;
};

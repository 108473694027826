import { useEffect, useState } from 'react';

import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

type Features = {
    /** 利用履歴の取得 */
    actionLog: boolean
    /** 教材・アプリ管理 */
    application: boolean
    /** アプリ配信管理 */
    applicationTemplate: boolean
    /** 毎日の記録 */
    everydayNote: boolean
    /** 氏名の非表示 */
    hideUserName: boolean
    /** お知らせ管理 */
    information: boolean
    /** features を取得中か否か */
    isLoading: boolean
    /** MEXCBTテスト管理 */
    mexcbt: boolean
    /** 学校管理 */
    organization: boolean
    /** アンケート */
    questionnaire: boolean
    /** セキュリティ設定 */
    security: boolean
    /** 学習記録 */
    studylog: boolean
    /** ユーザー同期 */
    sync: boolean
    /** グループメンバー同期 */
    syncGroup: boolean
    /** 利用者管理 */
    users: boolean
}

const INIT_VALUE: Readonly<Features> = Object.freeze({
    actionLog: false,
    application: false,
    applicationTemplate: false,
    everydayNote: true,
    hideUserName: false,
    information: false,
    isLoading: true,
    mexcbt: false,
    organization: false,
    questionnaire: false,
    security: false,
    studylog: false,
    sync: true,
    syncGroup: true,
    users: false,
});

/**
 * テナントで使用可能な機能の一覧を取得するカスタムフック
 */
export const useFeatures = () => {
    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const tenant = useAppSelector(selectors.tenant);
    const [features, setFeatures] = useState<Features>(generateFeatures(tenant, isControllableUser));

    useEffect(() => {
        if (isFeatureLoading(tenant)) return;

        setFeatures(generateFeatures(tenant, isControllableUser));
    }, [tenant, isControllableUser]);

    return features;
};

const isFeatureLoading = (tenant: Tenant) => {
    const { domain, municipality_id: municipalityId, name, type } = tenant;
    return domain === '' && municipalityId === '' && name === '' && type === '';
};

const generateFeatures = (tenant: Tenant, isControllableUser: boolean): Features => {
    if (isFeatureLoading(tenant)) return INIT_VALUE;

    const {
        'action-log': actionLogFlag,
        'application': applicationFlag,
        'everyday-note': everydayNoteFlag,
        'hide-user-name': hideUserNameFlag,
        'information': informationFlag,
        'mexcbt': mexcbtFlag,
        'questionnaire': questionnaireFlag,
        'studylog': studylogFlag,
        'sync-group': syncGroupFlag,
        'sync': syncFlag,
        ...restValues
    } = tenant.features;

    type CheckingNotExistOfRestType = (keyof typeof restValues) extends [] ? Record<never, unknown> : never;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _: CheckingNotExistOfRestType = restValues;

    // 利用履歴の取得機能が有効な場合, 表示
    const actionLog = actionLogFlag;

    // 教材・アプリ機能が有効な場合, 表示
    const application = applicationFlag;

    // 全体管理者の場合, 表示
    const applicationTemplate = isControllableUser;

    // 氏名の非表示が有効な場合、非表示とする
    const hideUserName = hideUserNameFlag;

    // お知らせ機能が有効な場合, 表示
    const information = informationFlag;

    // 全体管理者 => 自治体コードある場合, 表示
    // 学校管理者 => MEXCBTテスト機能が有効の場合, 表示
    const mexcbt =
        (!isControllableUser || (tenant.code !== null && tenant.code !== '')) &&
        (isControllableUser || mexcbtFlag);

    // アンケート機能が有効の場合, 表示
    const questionnaire = questionnaireFlag;

    // 毎日の記録機能が有効の場合, 表示
    const everydayNote = everydayNoteFlag;

    // 学習記録機能が有効の場合, 表示
    const studylog = studylogFlag;

    // ユーザー同期機能が有効な場合, 表示
    const sync = syncFlag;

    // グループメンバー同期が有効な場合, 表示
    const syncGroup = syncGroupFlag;

    // 全体管理者の場合, 表示
    const organization = isControllableUser;

    // 常に表示
    const users = true;

    // 全体管理者の場合, 表示
    // 一旦塞ぐ
    const security = false;

    return {
        actionLog,
        application,
        applicationTemplate,
        everydayNote,
        hideUserName,
        information,
        isLoading: false,
        mexcbt,
        organization,
        questionnaire,
        security,
        studylog,
        sync,
        syncGroup,
        users,
    };
};

type RequestParameter = Http.Connection.Request.Parameter.EverydayNote.Admin.ControlUpdate
    | Http.Connection.Request.Parameter.EverydayNote.Admin.Create
    | Http.Connection.Request.Parameter.EverydayNote.Admin.Update;

/**
 * フォームの値をバックエンドに送るフォーマットに変換する
 */
export const convertSubmittableValue = (
    values: EverydayNote.Form.Admin.ControlQuestionnaire | EverydayNote.Form.Admin.AdminQuestionnaire,
    isCopy: boolean
): RequestParameter => {
    const {
        afterMessage,
        allowPastDateAnswer,
        duration,
        isOnceADay,
        isRequired,
        isSetRecordableTime,
        isViewedHome,
        items,
        publishFrom,
        recordableTimeEnd,
        recordableTimeStart,
        recordableDayOfWeeks,
        uuid,
        name,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ..._rest
    } = values;

    const base = {
        after_message: afterMessage,
        allow_past_date_answer: allowPastDateAnswer,
        day_of_weeks: isSetRecordableTime ?
            recordableDayOfWeeks :
            [0, 1, 2, 3, 4, 5, 6],
        description: '',
        end_time: isSetRecordableTime ?
            recordableTimeEnd?.format('HH:mm') ?? '23:59' :
            '23:59',
        is_once_a_day: isOnceADay,
        is_required: isRequired,
        is_viewed_home: isViewedHome,
        items: items.map(convertItem(isCopy)),
        name,
        // 保存時には必ず 公開終了時刻 が設定されるため、undefined とはならないが、
        // 適切な型付けが大変なので、一旦強制的に対応する
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        publish_end_at: duration[1]!.unix(),
        publish_from: publishFrom,
        publish_start_at: duration[0].unix(),
        start_time: isSetRecordableTime ?
            recordableTimeStart?.format('HH:mm') ?? '00:00' :
            '00:00',
        // TODO: 現状選択しないので固定値
        type: 1 as EverydayNote.QuestionnaireTypes,
    };

    if ('targetOrganizations' in values) {
        if (uuid === undefined) throw new Error('uuid is required');

        return {
            day_of_weeks: base.day_of_weeks,
            end_time: base.end_time,
            publish_end_at: base.publish_end_at,
            publish_from: base.publish_from,
            publish_start_at: base.publish_start_at,
            start_time: base.start_time,
            uuid,
        };
    } else {
        const adminVal = { ...base, ...convertAdminValue(values) };

        return isCopy || uuid === undefined ?
            adminVal :
            { ...adminVal, uuid };
    }
};

// 学校管理でのみ使われる値を変換する
const convertAdminValue = (
    values: Expand<Omit<EverydayNote.Form.Admin.AdminQuestionnaire, keyof EverydayNote.Form.Admin.ControlQuestionnaire>>,
) => {
    const {
        targetRoles,
        targetSchoolClasses,
        targetUsers,
        ...rest
    } = values;

    // エラーチェック用に型を使うためのダミー
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _: Record<never, never> = rest;

    return {
        target_roles: targetRoles.map(e => e.uuid),
        target_school_classes: targetSchoolClasses.map(e => e.uuid),
        target_users: targetUsers.map(e => e.uuid),
    };
};

const convertItem = (isCopy: boolean) => (
    item: Parameters<typeof convertSubmittableValue>[0]['items'][number],
    index: number,
): Http.Connection.Request.Parameter.EverydayNote.Admin.Create['items'][number] => {
    const {
        candidates,
        evaluationScaleMax,
        isRequired,
        uuid,
        order,
        ...restItem
    } = item;

    return isCopy || uuid === undefined ?
        {
            candidates: candidates.map(candidate => ({
                content: candidate.content,
                order: candidate.order,
                uuid: isCopy ? undefined : candidate.uuid,
            })),
            is_required: isRequired,
            order: order ?? index,
            ...(restItem.type === 6 ? { evaluation_scale_max: evaluationScaleMax } : {}),
            ...restItem,
        } :
        {
            candidates: candidates.map(candidate => ({
                content: candidate.content,
                order: candidate.order,
                uuid: isCopy ? undefined : candidate.uuid,
            })),
            is_required: isRequired,
            order: order ?? index,
            uuid,
            ...(restItem.type === 6 ? { evaluation_scale_max: evaluationScaleMax } : {}),
            ...restItem,
        };
};
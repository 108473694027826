import { createAction } from 'redux-actions';
import {
    API_ANNOUNCEMENT,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/ANNOUNCEMENT';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}

type DefaultPayload = { method: HttpMethods, api: string };
type DefaultMeta = { fetch: boolean, status: RequestStatus };

type Payload<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultPayload
        : Expand<DefaultPayload & EXTRA>;
type Meta<EXTRA extends {} = Record<string, never>> =
    EXTRA extends Record<string, never>
        ? DefaultMeta
        : Expand<DefaultMeta & EXTRA>;


export const get = createAction<
    Payload | Http.Connection.Response.Announcement.Get,
    Meta
>(
    createActionType('GET' as const),
    () => {
        return ({ method: 'get', api: `${API_ANNOUNCEMENT}/get` })
    },
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);
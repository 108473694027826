import { useState } from 'react';
import * as React from 'react';
import {
    Button,
    Popconfirm,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    UpOutlined,
} from '@ant-design/icons';
const { Text } = Typography;

type Props = {
    disabled?: boolean
    isAnswered: boolean
    itemCount: number
    onChangeOrder: (order: number, delta: number) => void
    onClickCopy: (question: EverydayNote.Form.Question) => void
    onClickDelete: (question: EverydayNote.Form.Question) => void
    onClickEdit: (question: EverydayNote.Form.Question) => void
    question: EverydayNote.Form.Question
}

const QuestionForm: React.VFC<Props> = ({
    disabled = false,
    isAnswered,
    itemCount,
    onChangeOrder,
    onClickCopy,
    onClickDelete,
    onClickEdit,
    question,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const upDisabled = question.order === 0;
    const downDisabled = question.order === itemCount - 1;

    const handleCopyQuestion = () => onClickCopy(question);

    const handleDeleteQuestion = () => onClickDelete(question);

    const handleDownQuestion = () => onChangeOrder(question.order ?? itemCount, 1);

    const handleEditQuestion = () => onClickEdit(question);

    const handleToggleExpandClick = () => setIsOpen(isOpen => !isOpen);

    const handleUpQuestion = () => onChangeOrder(question.order ?? itemCount, -1);


    return (
        <div className='question-form'>
            <div className='question-control'>
                <Space style={{ width: '100%', wordBreak: 'break-all' }}>
                    <Tooltip title={isAnswered ? '回答済みのため並び替えは行えません' : '上へ移動'}>
                        <Button
                            icon={<UpOutlined />}
                            onClick={handleUpQuestion}
                            disabled={upDisabled || disabled}
                            style={{ border: '0px' }}
                            size='small'
                            type='primary'
                            shape='circle'
                        />
                    </Tooltip>

                    <Tooltip title={isAnswered ? '回答済みのため並び替えは行えません' : '下へ移動'}>
                        <Button
                            icon={<DownOutlined />}
                            onClick={handleDownQuestion}
                            disabled={downDisabled || disabled}
                            style={{ border: '0px' }}
                            size='small'
                            type='primary'
                            shape='circle'
                        />
                    </Tooltip>

                    <Text strong>{question.content}</Text>
                </Space>

                <Tooltip title={isOpen ? '展開された設問を閉じる' : '設問を展開する'}>
                    <Button
                        onClick={handleToggleExpandClick}
                        icon={isOpen ? <UpOutlined /> : <DownOutlined />}
                    >
                        {isOpen ? '閉じる' : '開く'}
                    </Button>
                </Tooltip>
            </div>

            <QuestionDetails
                isOpen={isOpen}
                question={question}
            />

            <div className='control-buttons'>
                <Tooltip title={isAnswered ? '回答済みのため編集は行えません' : '設問を編集'} placement='bottom'>
                    <Button
                        className='mini'
                        icon={<EditOutlined />}
                        onClick={handleEditQuestion}
                        disabled={isAnswered || disabled}
                    >
                        編集
                    </Button>
                </Tooltip>

                <Popconfirm
                    title={
                        <span>
                            <strong>設問のコピー</strong><br />
                            設問をコピーします。
                        </span>
                    }
                    onConfirm={handleCopyQuestion}
                    placement='left'
                    okText='コピー'
                    cancelText='キャンセル'
                    disabled={isAnswered || disabled}
                >
                    <Tooltip
                        title={isAnswered ? '回答済みのためコピーは行えません' : '設問を最下部へコピー'}
                        placement='bottom'
                    >
                        <Button
                            className='mini'
                            icon={<CopyOutlined />}
                            disabled={isAnswered || disabled}
                        >
                            コピー
                        </Button>
                    </Tooltip>
                </Popconfirm>

                <Popconfirm
                    title={
                        <span>
                            <strong>設問の削除</strong><br />
                            設問を削除します。
                        </span>
                    }
                    onConfirm={handleDeleteQuestion}
                    placement='left'
                    okText='削除'
                    cancelText='キャンセル'
                    disabled={isAnswered || disabled}
                >
                    <Tooltip title={isAnswered ? '回答済みのため削除は行えません' : '設問を削除'} placement='bottom'>
                        <Button
                            className='mini'
                            icon={<DeleteOutlined />}
                            disabled={isAnswered || disabled}
                        >
                            削除
                        </Button>
                    </Tooltip>
                </Popconfirm>
            </div>
        </div>
    );
};

export default QuestionForm;


type QuestionDetailsProps = {
    question: EverydayNote.Form.Question
    isOpen: boolean
}

const QuestionDetails: React.VFC<QuestionDetailsProps> = ({
    question,
    isOpen,
}) => {
    if (!isOpen) return <></>;

    return (
        <Space
            direction='vertical'
            className='question-details'
            style={{ wordBreak: 'break-all' }}
        >
            <QuestionDetailCell title='設問詳細'>
                {question.detail}
            </QuestionDetailCell>

            <QuestionDetailCell title='必須回答'>
                {question.isRequired ? '必須回答である' : '必須回答ではない'}
            </QuestionDetailCell>

            <QuestionDetailCell title='回答形式'>
                {
                    <>
                        {(() => {
                            switch (question.type) {
                                case 1:
                                    return '単一選択式';
                                case 2:
                                    return '複数選択式';
                                case 3:
                                    return '自由記述式';
                                case 4:
                                    return '顔マーク5段階';
                                case 5:
                                    return '教科選択';
                                case 6:
                                    return '星マーク';
                                case 7:
                                    return 'プルダウン';
                                default:
                                    return '不明';
                            }
                        })()}
                        {question.candidates?.map(candidate => {
                            return (
                                <div key={candidate.order} style={{ margin: 10 }}>
                                    選択肢{candidate.order + 1}<br />
                                    ・・・{candidate.content}
                                </div>
                            );
                        })}
                        {question.evaluationScaleMax && (
                            <div style={{ margin: 10 }}>
                                段階数・・・{question.evaluationScaleMax}
                            </div>
                        )}
                    </>
                }
            </QuestionDetailCell>
        </Space>
    );
};


type QuestionDetailCellProps = {
    title: string
}

const QuestionDetailCell: React.FC<QuestionDetailCellProps> = ({
    title,
    children,
}) => {
    return (
        <div className='detail-cell'>
            <div className='title'>
                {title}
            </div>
            <div className='children'>
                {children}
            </div>
        </div>
    );
};
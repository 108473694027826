import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DownloadOutlined, FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Empty, Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import BaseModal from 'components/modules/BaseModal';
import { generateFileDomain, getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { answerResultModalLoading } from 'flex/view/Questionnaire/AnswerResultModal/selector';
const moment = getMoment();

/** 自由入力回答やファイル添付回答の一覧を表示する  */
export const AnswerResultModal = ({
    onCancel,
    item,
    isOpen,
    isFullScreen,
    questionnaire,
}: {
    onCancel: () => void;
    item: any;
    isOpen: boolean;
    isFullScreen?: boolean;
    questionnaire: any;
}) => {
    const pagination = useAppSelector(state => state.answerResultModal.pagination);
    const results = useAppSelector(state => state.answerResultModal.resultList);
    const loading = useAppSelector(answerResultModalLoading);
    const dispatch = useDispatch();
    const isFreeInputType = item !== null ? item.type === 3 : false; // 自由記述の回答かどうか

    useEffect(() => {
        if (isOpen) {
            dispatch(Actions.view.viewLogic.answerResultModal.prepareView());
        }
    }, [isOpen, dispatch]);

    const handleCancel = () => {
        // ページ情報のリセット
        dispatch(Actions.view.viewLogic.answerResultModal.savePage(1, 50));
        onCancel();
    };

    const handleDownloadFileClick = (uuid: string) => {
        const urlString = `${generateFileDomain()}/file/view/${uuid}`;
        window.open(urlString);
    };

    const modalTitle = isOpen ?
        (
            <span>
                {isFreeInputType ? (<FileOutlined />) : (<PaperClipOutlined />)}
                {isFreeInputType ? (' 自由入力回答') : (' 添付ファイル一覧')}
            </span>
        ) :
        (<></>);

    const columns: ColumnsType<any> = isFreeInputType ?
        [ // 自由記述の回答時のテーブルカラム
            {
                dataIndex: 'answer',
                key: 'title',
                render: (_, record) => (
                    <>
                        <span>{record.content}</span>
                    </>
                ),
                title: '回答',
                width: questionnaire?.isAnonymous ? '85%' : '55%',
            },
            {
                dataIndex: 'datetime',
                key: 'answered_at',
                render: (_, record) => (
                    <>
                        <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span><br />
                        <span>{moment.unix(record.updated_at).format('YYYY/MM/DD HH:mm')}</span>
                    </>
                ),
                title: '回答日時 / 最終更新日時',
                width: '15%',
            },
        ] :
        [ // 添付ファイルの回答時のテーブルカラム
            {
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => (
                    record.file && (<p>{record.file.file_name}</p>)
                ),
                title: '添付ファイル',
                width: questionnaire?.isAnonymous ? '80%' : '50%',
            },
            {
                dataIndex: 'thumbnail',
                key: 'thumbnail',
                render: (_, { file }) => {
                    if (file.mime_type.match(/image/)) {
                        return <img alt={file.file_name} src={`${generateFileDomain()}/file/view/${file.uuid}`} />;
                    }
                },
                title: 'サムネイル',
                width: '15%',
            },
            {
                dataIndex: 'datetime',
                key: 'answered_at',
                render: (_, record) => (
                    <>
                        <span>{moment.unix(record.created_at).format('YYYY/MM/DD HH:mm')}</span>
                    </>
                ),
                title: '回答日時',
                width: '15%',
            },
            {
                dataIndex: 'download',
                key: 'download',
                render: (_, record) => (
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            icon={<DownloadOutlined />}
                            onClick={() => handleDownloadFileClick(record.file.uuid)}
                            shape='circle'
                        >
                        </Button>
                    </div>
                ),
                title: '',
                width: '5%',
            },
        ];

    // 記名式の場合はカラムを追加
    !questionnaire?.isAnonymous && columns.splice(0, 0,
        {
            dataIndex: 'user',
            render: (user) => (
                <div className='answer-result-login-id'>{user.login_id}</div>
            ),
            title: 'ログインID',
            width: '15%',
        },
        {
            dataIndex: 'user',
            render: (user) => (
                <>
                    <span>{user.last_name} {user.first_name}</span><br />
                    <span>({user.last_name_kana} {user.first_name_kana})</span>
                </>
            ),
            title: '名前',
            width: '15%',
        }
    );

    return (
        <BaseModal
            className={isFullScreen ? 'questionnaire-fullscreen-answer-result' : 'common-modal'}
            footer={[<Button key='back' onClick={handleCancel} size='large'>閉じる</Button>]}
            onCancel={handleCancel}
            onOk={handleCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={modalTitle}
            visible={isOpen}
        >

            <Table
                bordered
                className='general-table'
                columns={columns}
                dataSource={results}
                loading={loading}
                locale={{
                    emptyText: <Empty description='データはありません' image={Empty.PRESENTED_IMAGE_DEFAULT} />,
                    filterConfirm: '確定',
                    filterReset: 'リセット',
                }}
                pagination={{
                    ...pagination,
                    onChange: (page, page_size) => {
                        dispatch(Actions.view.viewLogic.answerResultModal.submitPage(page, page_size));
                    },
                    onShowSizeChange: () => ({}),
                    showTotal: (total, range) => { return (`全${total}件中 ${range[0]}-${range[1]} 件`); },

                }}
                rowKey='uuid'
            />
        </BaseModal>
    );
};

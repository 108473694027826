import { Button } from 'antd';
import RichTextView from 'components/modules/RichTextView';
import BaseModal from 'components/modules/BaseModal';

type Props = {
    detail: Announcement | undefined;
    isOpen: boolean;
    onClose: () => void;
};

const DetailModal = (props: Props) => {
    const { detail, isOpen, onClose } = props;

    return (
        <BaseModal
            className='common-modal'
            title={detail?.title}
            visible={isOpen}
            onCancel={onClose}
            forceRender
            footer={
                <Button
                    key='create'
                    type='primary'
                    size='large'
                    onClick={onClose}
                >
                    閉じる
                </Button>
            }
        >
            <div>
                <div
                    style={{
                        padding: '1.25rem 1rem 1rem 1rem',
                        backgroundColor: '#ffffff',
                    }}
                >
                    <div className='froala-wrapper'
                        style={{
                            lineHeight: '2.5rem',
                            padding: '2rem 0',
                            fontSize: '1.25rem',
                            width: '100%',
                            color: '#374151',
                        }}
                    >
                        <RichTextView
                            model={detail?.content ?? ''}
                        />
                    </div>

                    <div
                        style={{
                            padding: '1.25rem 0 1rem 0',
                            opacity: 0.5,
                            width: '100%',
                        }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>作成者</td>
                                    <td> :</td>
                                    <td>{detail?.publish_from}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};

export default DetailModal;

import { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Input,
    Radio,
    Select,
    Spin,
    Tooltip,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { TeamOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
    applicationFormRules,
    formRules,
    getDisabledDate,
    getMoment,
    getDefaultLayout,
    DatePickerRangesIncludeInfinite
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { statusEnum } from 'flex/Actions';
import CustomRangePicker from 'components/modules/CustomRangePicker';
import { PublishedItemAlert } from 'components/modules/Announcement/PublishedItemAlert';
import BaseModal from 'components/modules/BaseModal';
import Targets from '../Targets';
import { SourceContext } from './index';
import { FormCard } from 'components/presentational/pages/AppTemplate/Forms/FormCard';
import BaseForm from 'components/modules/BaseForm';
const Form = BaseForm.ModalForm;
const { Option } = Select;

const moment = getMoment();
const layout = getDefaultLayout(true);
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const initialAppValue = {
    title: '',
    title_for_lower: '',
    description: '',
    duration: [
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        undefined,
    ],
    useDeepLinking: false,
    target: {
        target_users: [],
        target_school_classes: [],
        target_roles: [],
    },
};

const EditAppGroups = ({
    uuid,
    visible,
    onOk,
    onCancel,
    shouldCreate
}) => {
    const [source] = useContext(SourceContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [isSelectTargetOpen, setIsSelectTargetOpen] = useState(false);
    const [editableForCanAdminSetPublic, setEditableForCanAdminSetPublic] = useState(false);
    const [disabledForPublishedItem, setDisabledForPublishedItem] = useState(false);
    const [order, setOrder] = useState(0);
    const [selectedChoiceTemplate, setSelectedChoiceTemplate] = useState();
    const appsCategoryEditConnection = useSelector(state => state.appsCategoryEditConnection);
    const appsCategoryViewConnection = useSelector(state => state.appsCategoryViewConnection);
    const appsTemplateSearchConnection = useSelector(state => state.appsTemplateSearchConnection);
    const dispatch = useDispatch();
    const isFirstRender = useRef(false);
    const target = Form.useWatch('target', form);
    // Targets が各 key がないとエラーになるので, つけておく
    const currentTarget = {
        target_users: [],
        target_school_classes: [],
        target_roles: [],
        target_organizations: [],
        ...target,
    };
    const useDeepLinking = Form.useWatch('useDeepLinking', form);

    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!visible) return;
        form.resetFields();
        setEditableForCanAdminSetPublic(false);
        setDisabledForPublishedItem(false);
        setOrder(0);
        setSelectedChoiceTemplate(undefined);
        dispatch(Actions.http.connection.apps.template.search({ use_deep_linking: 1, page_size: 500 }, 1));

        if (uuid === null) {
            form.setFieldsValue(initialAppValue);
        }
    }, [dispatch, form, uuid, visible]);

    useEffect(() => {
        setLoading(appsCategoryEditConnection.meta.fetch);
        if (isFirstRender.current) return;

        if (appsCategoryEditConnection.meta.status === statusEnum.SUCCESS) {
            onOk();
        }
        // onOk を含めると, 保存時に 無限ループが発生する
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsCategoryEditConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        const { meta, payload } = appsTemplateSearchConnection;

        setLoading(meta.fetch);
        if (meta.status === statusEnum.SUCCESS) {
            setTemplates(payload.result.items);
            if (uuid) {
                dispatch(Actions.http.connection.apps.category.view(uuid));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsTemplateSearchConnection]);

    useEffect(() => {
        setLoading(appsCategoryViewConnection.meta.fetch);
        if (isFirstRender.current) return;

        if (appsCategoryViewConnection.meta.status !== statusEnum.SUCCESS) return;

        const result = appsCategoryViewConnection.payload.result;
        setEditableForCanAdminSetPublic(result.can_admin_set_public);
        setDisabledForPublishedItem(result.is_published_item);
        setOrder(result.order);

        const selectedChoiceTemplate = findVer13ChangeableChoiceTemplateByUuid(
            templates,
            result.lti_template?.template?.uuid
        );

        setSelectedChoiceTemplate(selectedChoiceTemplate);

        form.setFieldsValue({
            applicationTemplateUuid: result.lti_template?.template?.uuid,
            applicationUrlValueCandidateUuid: result.lti_template?.candidate?.uuid,
            description: result.description,
            duration: [
                moment.unix(result.publish_start_at),
                moment.unix(result.publish_end_at),
            ],
            title: result.title,
            title_for_lower: result.title_for_lower,
            target: {
                target_school_classes: result.target_school_classes ?? [],
                target_users: result.target_users ?? [],
                target_roles: result.target_roles ?? [],
            },
            useDeepLinking: result.use_deep_linking,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsCategoryViewConnection, form]);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    const onFinish = (values) => {
        const postBody = {
            application_template_uuid: values.applicationTemplateUuid,
            application_url_value_candidate_uuid: values.applicationUrlValueCandidateUuid,
            description: values.description,
            order: uuid ? order : source.appsListItemCount + 1,
            publish_end_at: values.duration[1].unix(),
            publish_start_at: values.duration[0].unix(),
            target_school_classes: values.target.target_school_classes.map((value) => {
                return value.uuid;
            }),
            target_users: values.target.target_users.map((value) => {
                return value.uuid;
            }),
            target_roles: values.target.target_roles.map((value) => {
                return value.uuid;
            }),
            title_for_lower: values.title_for_lower,
            title: values.title,
            use_deep_linking: values.useDeepLinking ? 1 : 0,
        };

        if (shouldCreate) {
            dispatch(Actions.http.connection.apps.category.create(postBody));
        } else {
            dispatch(Actions.http.connection.apps.category.update(uuid, postBody));
        }
    }

    const handleSelectTargetSubmit = (target) => {
        setIsSelectTargetOpen(false);
        form.setFieldsValue({ target });
    };

    const handleSelectTargetCancel = () => setIsSelectTargetOpen(false);

    const handleSelectUseDeepLinking = (event) => {
        if (event.target.value) return;

        form.setFieldsValue({
            applicationTemplateUuid: undefined,
            applicationUrlValueCandidateUuid: undefined,
        });
        setSelectedChoiceTemplate(undefined);
    };

    const handleApplicationTemplateUuid = (uuid) => {
        const selectedChoiceTemplate = findVer13ChangeableChoiceTemplateByUuid(templates, uuid);

        setSelectedChoiceTemplate(selectedChoiceTemplate);

        const value = selectedChoiceTemplate ?
            selectedChoiceTemplate.url.candidates.find(candidate => candidate.is_default).uuid :
            undefined;

        form.setFieldsValue({ applicationUrlValueCandidateUuid: value });
    };

    const isHidden = !selectedChoiceTemplate || !selectedChoiceTemplate.url.changeable_param_admin;

    return (
        <BaseModal
            className='common-modal'
            title={
                shouldCreate ?
                    <span>教材・アプリグループの新規作成</span> :
                    <span>教材・アプリグループの編集</span>
            }
            visible={visible}
            onCancel={onCancel}
            forceRender
            footer={[
                <Button
                    key='back'
                    loading={loading}
                    size='large'
                    onClick={onCancel}
                >
                    キャンセル
                </Button>,
                <Button
                    key='create'
                    loading={loading}
                    disabled={disabledForPublishedItem && !editableForCanAdminSetPublic}
                    type='primary'
                    size='large'
                    onClick={form.submit}
                >
                    保存
                </Button>
            ]}
        >
            <PublishedItemAlert
                disabledForPublishedItem={disabledForPublishedItem}
                editableForCanAdminSetPublic={editableForCanAdminSetPublic}
            />

            <Spin indicator={antIcon} spinning={loading}>
                <Form
                    {...layout}
                    form={form}
                    name='control-hooks-edit-app-groups'
                    onFinish={onFinish}
                    initialValues={initialAppValue}
                >
                    <Form.Item
                        name='title'
                        label='教材・アプリグループ名称（小学校3年生以上）'
                        rules={[
                            { required: true, message: '教材・アプリグループ名称（小学校3年生以上）を入力してください' },
                            applicationFormRules.applicationCategoryTitleRange(),
                        ]}
                    >
                        <Input
                            placeholder='教材・アプリグループ名称を入力してください'
                            disabled={disabledForPublishedItem}
                        />
                    </Form.Item>

                    <Form.Item
                        name='title_for_lower'
                        label='教材・アプリグループ名称（小学校1・2年生）'
                        rules={[
                            { required: true, message: '教材・アプリグループ名称（小学校1・2年生）を入力してください' },
                            applicationFormRules.applicationCategoryTitleRange(),
                        ]}
                    >
                        <Input
                            placeholder='教材・アプリグループ名称を入力してください'
                            disabled={disabledForPublishedItem}
                        />
                    </Form.Item>

                    <Form.Item
                        name='description'
                        label='概要'
                        rules={[
                            { required: false, message: '概要を入力してください' },
                            applicationFormRules.applicationCategoryDescriptionRange(),
                        ]}
                    >
                        <Input
                            placeholder='概要を入力してください'
                            disabled={disabledForPublishedItem}
                        />
                    </Form.Item>

                    <Form.Item name='duration' label='公開期間' rules={[formRules.requiredDuration({ label: '公開期間' })]}>
                        <CustomRangePicker
                            form={form}
                            name='duration'
                            locale={jaJP}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY/MM/DD HH:mm'
                            disabled={disabledForPublishedItem && !editableForCanAdminSetPublic}
                            disabledDate={getDisabledDate}
                            ranges={DatePickerRangesIncludeInfinite()}
                        />
                    </Form.Item>

                    <Form.Item label={<span>公開対象者</span>}>
                        <Form.Item name={'target'} hidden><input /></Form.Item>

                        <Button
                            type='primary'
                            icon={<TeamOutlined />}
                            disabled={disabledForPublishedItem}
                            onClick={() => setIsSelectTargetOpen(true)}
                        >
                            公開対象を選択
                        </Button>

                        <Targets.Detail
                            currentTarget={currentTarget}
                            isPublishedItem={disabledForPublishedItem}
                        />
                    </Form.Item>

                    <FormCard
                        ghost
                        title={
                            <div>
                                高度な接続
                                <Tooltip title='LTI Deep Linking を用いた教材・アプリの登録をするときに設定します。'>
                                    <QuestionCircleOutlined style={{ marginLeft: '0.5rem' }} />
                                </Tooltip>
                            </div>
                        }
                        noHeaderStyle
                        isView={visible}
                        isOpen={useDeepLinking}
                    >
                        <Form.Item
                            label='LTI Deep Linking でアプリを追加'
                            name='useDeepLinking'
                            rules={[{ required: true }]}
                        >
                            <Radio.Group
                                disabled={disabledForPublishedItem || !shouldCreate}
                                onChange={handleSelectUseDeepLinking}
                            >
                                <Radio value={false}>しない</Radio>
                                <Radio value={true}>する</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {useDeepLinking && (
                            <Form.Item
                                label='LTI1.3アプリ選択'
                                name='applicationTemplateUuid'
                                rules={[
                                    { required: true, message: 'LTI1.3アプリを選択してください' },
                                ]}
                            >
                                <Select
                                    placeholder='LTI1.3アプリを選択してください'
                                    allowClear
                                    disabled={disabledForPublishedItem || !shouldCreate}
                                    onSelect={handleApplicationTemplateUuid}
                                >
                                    {templates.map(template => (
                                        <Option key={template.uuid} value={template.uuid}>
                                            {template.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        {selectedChoiceTemplate && (
                            <Form.Item
                                hidden={isHidden}
                                label='接続パラメータ'
                                name='applicationUrlValueCandidateUuid'
                                rules={[
                                    { required: true, message: '接続パラメータを選択してください' },
                                ]}
                            >
                                <Select
                                    placeholder='接続パラメータを選択してください'
                                    allowClear
                                    disabled={disabledForPublishedItem || !shouldCreate}
                                >
                                    {selectedChoiceTemplate?.url?.candidates?.map(candidate => (
                                        <Option key={candidate.uuid} value={candidate.uuid}>
                                            {candidate.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </FormCard>
                </Form>
            </Spin>

            <Targets
                showRole
                visible={isSelectTargetOpen}
                currentTarget={currentTarget}
                onCancel={handleSelectTargetCancel}
                onSubmit={handleSelectTargetSubmit}
            />
        </BaseModal>
    );
};

export default EditAppGroups;

/**
 * templates の中から、
 * LTI 1.3 で選択式のもののうち、 uuid が一致するものを見つける
 */
const findVer13ChangeableChoiceTemplateByUuid = (templates, uuid) => {
    return templates
        .filter(temp => temp.type === 2) // LTI 1.3
        .filter(temp => temp.url.is_choices) // 選択式
        .find(temp => temp.uuid === uuid);
};
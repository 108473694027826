import { useEffect, useMemo, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';

import { TeamOutlined } from '@ant-design/icons';
import { Tag, Button, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';

import BaseModal from 'components/modules/BaseModal';
import { BaseTable } from 'components/modules/BaseTable';
import {
    getCurrentTerm,
    getIndefiniteTerm,
    ALL_TERM_UUID,
    PageSizeOptions,
} from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';


import { useClassListMap } from '../utils/useClassListMap';
import AddSearch, { UserSearch } from './AddSearch';

const INIT_SEARCH: UserSearch = {
    page: 1,
    page_size: 50,
};

const ROLE_SEARCH = {
    page_size: 500,
};

const TERM_SEARCH = {
    page_size: 50,
};

type Props = {
    /**
     * チェックボックスを無効する条件
     */
    getCheckboxDisabled?: (user: User, selectTerm: string) => boolean,
    onCancel: () => void,
    onOk: (selectedRows: User[]) => void,
    selectLimit?: number,
    visible: boolean,
    organizationUuid?: string,
};

type Pagination = PartOfRequired<
    TablePaginationConfig,
    'pageSize' | 'onChange'
>;

const AddModal: VFC<Props> = ({
    getCheckboxDisabled,
    onCancel,
    onOk,
    selectLimit = -1,
    visible,
    organizationUuid,
}) => {
    const [classListMap, searchClassListMap] = useClassListMap();

    const [error, setError] = useState('');
    const [userList, setUserList] = useState<User[]>([]);
    const [search, setSearch] = useState<UserSearch>({ ...INIT_SEARCH });
    const [loading, setLoading] = useState(false);
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [selectedRows, setSelectedRows] = useState<User[]>([]);
    const [selectTerm, setSelectTerm] = useState('');
    const [termList, setTermList] = useState<Term[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
        onChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size,
            }));
            setSearch(search => ({
                ...search,
                page,
                page_size: page_size,
            }));
        },
        onShowSizeChange: (page, page_size) => {
            setPagination(pagination => ({
                ...pagination,
                current: page,
                pageSize: page_size,
            }));
            setSearch(search => ({
                ...search,
                page,
                page_size,
            }));
        },
        pageSize: 50,
        pageSizeOptions: PageSizeOptions,
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`);
        },
        total: 0,
    });
    const roleSearchConnection = useAppSelector(state => state.roleSearchConnection);
    const termSearchConnection = useAppSelector(state => state.termSearchConnection);
    const usersSearchConnection = useAppSelector(state => state.usersSearchConnection);
    const dispatch = useDispatch();

    const currentTermUuid = useMemo(() => getCurrentTerm(termList).uuid, [termList]);
    const byTerm = useMemo(() => createFilterByTerm(termList), [termList]);

    useEffect(() => {
        const { meta, payload } = termSearchConnection;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                if (payload.result && payload.result.page_size < TERM_SEARCH.page_size) {
                    dispatch(Actions.http.connection.term.search(TERM_SEARCH));
                    break;
                }

                setTermList(payload.result?.items ?? []);
                break;
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING:
                break;
            default:
                // ここが表示されるまでに一度も通信されていない場合は通信する
                dispatch(Actions.http.connection.term.search(TERM_SEARCH));
                break;
        }
    }, [termSearchConnection, dispatch]);

    useEffect(() => {
        const { meta, payload } = roleSearchConnection;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                if (payload.result && payload.result.page_size < ROLE_SEARCH.page_size) {
                    dispatch(Actions.http.connection.role.search({
                        ...ROLE_SEARCH,
                        organization_uuid: organizationUuid,
                    }));
                }

                setRoleList(payload.result?.items ?? []);
                break;
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING:
                break;
            default:
                // ここが表示されるまでに一度も通信されていない場合は通信する
                dispatch(Actions.http.connection.role.search({
                    ...ROLE_SEARCH,
                    organization_uuid: organizationUuid,
                }));
                break;
        }
    }, [roleSearchConnection, dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const { meta, payload } = usersSearchConnection;

        setLoading(meta.fetch);

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setUserList(payload.result.items);
                setPagination(pagination => ({
                    ...pagination,
                    total: payload.result.item_count,
                }));
                break;
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING:
            default:
                break;
        }
    }, [usersSearchConnection]);

    useEffect(() => {
        if (!visible) return;

        setSearch({
            ...INIT_SEARCH,
            term_uuid: currentTermUuid,
        });
        setPagination(pagination => ({
            ...pagination,
            current: 1,
            pageSize: 50,
        }));
        // visibleのみ監視
    }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (visible) return;

        setUserList([]);
        setSelectedRows([]);
        // visibleのみ監視
    }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!visible) return;

        const { page, ...data } = search;
        const newPage = page ?? 1;
        setPagination(pagination => ({
            ...pagination,
            current: newPage,
        }));

        const newData = { term_uuid: '', ...data } as Parameters<typeof Actions.http.connection.users.search>[0];

        if (organizationUuid !== undefined) {
            newData.organization_uuid = organizationUuid;
        }
        dispatch(Actions.http.connection.users.search(newData, newPage, ''));
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectTerm = (termUuid: string) => {
        setSelectTerm(termUuid);
        // すべての年度のとき or  取得済みの年度のクラスリストがあるとき はクラスを取得しない
        if (termUuid === ALL_TERM_UUID || classListMap.data[termUuid]?.items?.length > 0) return;
        searchClassListMap({
            organizationUuid,
            pageSize: 1000,
            termUuid,
        });
    };

    const columns: ColumnsType<User> = [
        {
            dataIndex: 'login_id',
            key: 'login_id',
            title: 'ユーザーID',
            width: '15%',
        },
        {
            children: [
                {
                    dataIndex: 'belongs',
                    key: 'class',
                    render: (row: UserBelong[]) => {
                        return row.filter(byTerm(search.term_uuid)).map((val, index) => {
                            return (
                                <div key={`${val.school_class.name}-${index}`}>
                                    <Tag
                                        color='#AAAAAA'
                                        style={{
                                            borderRadius: '5px',
                                            marginRight: '0.5rem',
                                            textAlign: 'center',
                                            width: '75px',
                                        }}
                                    >
                                        {val.school_class.term.name}
                                    </Tag>
                                    {val.school_class.name}
                                </div>
                            );
                        });
                    },
                    title: 'クラス名',
                    width: 40,
                },
                {
                    dataIndex: 'belongs',
                    key: 'roles',
                    render: (row: UserBelong[]) => {
                        return row.filter(byTerm(search.term_uuid)).map((val, index) => {
                            return <div key={`roles-${index}`}>{val.role.name}</div>;
                        });
                    },
                    title: '役割',
                    width: 40,
                },
                {
                    dataIndex: 'belongs',
                    key: 'num',
                    render: (row: UserBelong[]) => {
                        return row.filter(byTerm(search.term_uuid)).map((val, index) => {
                            return <div key={`school_class_num-${index}`}>{val.school_class.number}</div>;
                        });
                    },
                    title: '出席番号',
                    width: 20,
                },
            ],
            title: 'クラス',
            width: '40%',
        },
        {
            children: [
                {
                    dataIndex: 'last_name',
                    key: 'last_name',
                    title: '姓',
                    width: 25,
                },
                {
                    dataIndex: 'last_name_kana',
                    key: 'last_name_kana',
                    title: '姓（ふりがな）',
                    width: 25,
                },
                {
                    dataIndex: 'first_name',
                    key: 'first_name',
                    title: '名',
                    width: 25,
                },
                {
                    dataIndex: 'first_name_kana',
                    key: 'first_name_kana',
                    title: '名（ふりがな）',
                    width: 25,
                },
            ],
            title: '名前',
            width: '40%',
        },
    ];

    const rowSelection: TableRowSelection<User> = {
        columnWidth: 20,
        getCheckboxProps: (record) => {
            return {
                disabled: !!getCheckboxDisabled && getCheckboxDisabled(record, selectTerm),
            };
        },
        hideSelectAll: false,
        onChange: (_, selectedRows) => {
            if (selectLimit >= 0 && selectedRows.length > selectLimit) {
                setError(`${selectLimit + 1} つ以上は選択できません。`);
                return;
            }

            setError('');
            setSelectedRows(selectedRows);
        },
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows.map(row => row.uuid),
        type: 'checkbox',
    };

    return (
        <BaseModal
            className='common-modal'
            extraMessage={error !== '' && (
                <div style={{ color: 'red' }}>
                    {error}
                </div>
            )}
            footer={[
                <Button key='back' onClick={onCancel} size='large'>キャンセル</Button>,
                <Button
                    key='create'
                    loading={loading}
                    onClick={() => onOk(selectedRows)}
                    size='large'
                    type='primary'
                >
                    保存
                </Button>,
            ]}
            onCancel={onCancel}
            style={{ top: 20, width: '97vw !important' }}
            title={<span><TeamOutlined /> 対象を選択</span>}
            visible={visible}
        >
            <div className='users-list container'>
                <AddSearch
                    classList={classListMap.data[selectTerm]?.items ?? []}
                    isLoadingClass={classListMap.isLoading}
                    isLoading={usersSearchConnection.meta.fetch}
                    onSearch={setSearch}
                    onSelectTerm={handleSelectTerm}
                    roleList={roleList}
                    search={search}
                    termList={termList}
                />

                <BaseTable
                    columns={columns}
                    dataSource={userList}
                    emptyDescription={
                        <>
                            条件に一致する利用者はいませんでした。 <br />
                            検索条件を変更して再度検索をしてください。
                        </>
                    }
                    loading={loading}
                    pagination={pagination}
                    rowSelection={rowSelection}
                />
            </div>
        </BaseModal>
    );
};

export default AddModal;


/***
 * 検索対象の年度もしくは、無期限の年度の所属で filter する
 */
const createFilterByTerm = (termList: Term[]) => (term: string | undefined) => {
    const infiniteTerm = getIndefiniteTerm(termList).uuid;
    return (val: UserBelong) => term === undefined || term === '' ?
        true :
        val.school_class.term.uuid === term || val.school_class.term.uuid === infiniteTerm;
};
import type {
    SearchTemplate,
    Lti11Form,
    ChoiceLti11Form,
    FixedLti11Form,
    Lti13Form,
    ChoiceLti13Form,
    FixedLti13Form,
} from './type';


const createInitFormValue = (isChoice = true): Lti11Form => {
    const base =  {
        baseTemplateUuid: '',
        is_active: true,
        is_target_all: true,
        name: '',
        params: [],
        target_organization: [],
    };

    if(isChoice) {
        return {
            ...base,
            ltiUrlCandidateUuid: '',
            ltiUrlUuid: '',
        };
    } else {
        return {
            ...base,
            ltiUrlKey: '',
            ltiUrlSecret: '',
            ltiUrlUrl: '',
        };
    }
};

// AppTemplate の name は AppTemplate 自体の名前なので除外
// AppTemplate の is_target_all は｢すべてのテナントに配信するか｣なので除外
// AppTemplate の target_organization は対象学校で、必ず初期値が [] なので除外
type OmitFormItems = 'name' | 'is_target_all' | 'target_organization';

export const createUpdateFormValue = (template: SearchTemplate): Omit<Lti11Form, OmitFormItems> | Omit<Lti13Form, OmitFormItems> => {
    switch (template.type) {
        case 1: {
            const {
                is_active,
                url,
                uuid,
                params,
            } = template;

            if (url.is_choices) {
                const result: Omit<ChoiceLti11Form, OmitFormItems> = {
                    baseTemplateUuid: uuid,
                    is_active,
                    ltiUrlCandidateUuid: url.candidates.find(c => c.is_default)?.uuid,
                    ltiUrlUuid: url.uuid,
                    params: params.map(param => ({
                        ltiParamCandidateUuid: undefined,
                        ltiParamUuid: param.uuid,
                        ltiParamValue: null,
                    })),
                };

                return result;
            } else {
                const result: Omit<FixedLti11Form, OmitFormItems> = {
                    baseTemplateUuid: uuid,
                    is_active,
                    ltiUrlKey: url.key,
                    ltiUrlSecret: url.secret,
                    ltiUrlUrl: url.url,
                    params: params.map(param => ({
                        ltiParamUuid: param.uuid,
                        ltiParamValue: param.value,
                    })),
                };

                return result;
            }

        }
        case 2: {
            const {
                is_active,
                url,
                uuid,
                use_organization_code,
            } = template;

            if (url.is_choices) {
                const result: Omit<ChoiceLti13Form, OmitFormItems> = {
                    baseTemplateUuid: uuid,
                    is_active,
                    ltiUrlCandidateUuid: url.candidates.find(c => c.is_default)?.uuid,
                    ltiUrlUuid: url.uuid,
                    params: [],
                };

                return result;
            } else {
                const { redirect_uris, deployment_id, ...restParamJson } = url.param_json;
                const result: Omit<FixedLti13Form, OmitFormItems> = {
                    baseTemplateUuid: uuid,
                    is_active,
                    param_json: {
                        ...restParamJson,
                        deployment_id: deployment_id ?? undefined,
                        redirect_uris: Array.isArray(redirect_uris) ? redirect_uris : [redirect_uris],
                        use_organization_code: use_organization_code,
                    },
                    params: [],
                };

                return result;
            }
        }
        default:
            return createInitFormValue();
    }
};


import {editUserInViewLogic} from "../view/UsersManagement/EditUser/viewLogic";
import {questionnaireTabViewLogic} from "../view/Questionnaire/QuestionnaireTab/viewLogic";
import {questionnaireCategoryTabViewLogic} from "../view/Questionnaire/QuestionnaireCategoryTab/viewLogic";
import * as Actions from "../Actions";
import {editQuestionnaireViewLogic} from "../view/Questionnaire/EditQuestionnaire/viewLogic";
import {editQuestionViewLogic} from "../view/Questionnaire/EditQuestion/viewLogic";
import {resultQuestionnaireViewLogic} from "../view/Questionnaire/ResultQuestionnaire/viewLogic";
import {answerResultModalViewLogic} from "../view/Questionnaire/AnswerResultModal/viewLogic";
import {userManagementTabFlex} from '../view/UsersManagement/UserManagementTab'
import {appTemplateTabInViewLogic} from '../view/AppTemplate/AppTemplateTab/viewLogic'
import {examCategoryTabFlex} from '../view/Assessment/examCategoryTabFlex'


const viewLogic = store => next => action => {
    console.log(action)

	if(action.type.match("^VIEW_LOGIC")){

		if(action.type.match(/APP_TEMPLATE_TAB/)){
			console.log('viewLogic APP_TEMPLATE_LIST')
			appTemplateTabInViewLogic(store, next, action)
		}
		if(action.type.match(/USER_MANAGEMENT_TAB/)){
			userManagementTabFlex.viewLogic(store, next, action)
		}
		if(action.type.match(/EDIT_USER/)){
			editUserInViewLogic(store, next, action)
		}
		// アンケート
		if(action.type.match(/QUESTIONNAIRE_TAB/)){
			questionnaireTabViewLogic(store, next, action)
		}
		if(action.type.match(/EDIT_QUESTIONNAIRE\//)){
			editQuestionnaireViewLogic(store, next, action)
		}
		if(action.type.match(/RESULT_QUESTIONNAIRE\//)){
			resultQuestionnaireViewLogic(store, next, action)
		}
		if(action.type.match(/ANSWER_RESULT_MODAL\//)){
			answerResultModalViewLogic(store, next, action)
		}
		if(action.type.match(/EDIT_QUESTION\//)){
			editQuestionViewLogic(store, next, action)
		}
		if(action.type.match(/QUESTIONNAIRE_CATEGORY_TAB/)){
			questionnaireCategoryTabViewLogic(store, next, action)
		}
		if(action.type.match(/EXAM_CATEGORY_TAB/)){
			examCategoryTabFlex.viewLogic(store,next,action)
		}


	}else{
		if(action.type === Actions.http.connection.questionnaireCategory.search().type){
			console.log("connection questionnaireCategory")
		}
		next(action);
	}
}

export default viewLogic

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

export type ReportDetailState = {
    result: {
        answeredUserCount: number
        averagePer: number
        averageScore: number
        difficulty: string
        gradeName: string
        highestScore: number
        lowestScore: number
        maxScore: number
        resultCount: number
        subjectChildName: string
        subjectName: string
        targetUserCount: number
        testTimeMin: number
    } | undefined
    isLoading: boolean
};

type Connection = TaoExamReportDetailConnection | TaoExamControlReportDetailConnection;


export const useReportDetail = () => {
    const [state, setState] = useState<ReportDetailState>({
        isLoading: false,
        result: undefined,
    });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamReportDetailConnection = useAppSelector(state => state.taoExamReportDetailConnection);
    const taoExamControlReportDetailConnection = useAppSelector(state => state.taoExamControlReportDetailConnection);

    const dispatch = useDispatch();


    const effect = useCallback((connection: Connection, state: ReportDetailState) => {
        const { meta, payload: { result } } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState({
                    isLoading: false,
                    result: {
                        answeredUserCount: result.answered_user_count,
                        averagePer: result.delivery_average_score,
                        averageScore: result.average_score,
                        difficulty: result.difficulty,
                        gradeName: result.grade_name,
                        highestScore: result.highest_score,
                        lowestScore: result.lowest_score,
                        maxScore: result.max_score,
                        resultCount: result.result_history_count,
                        subjectChildName: result.subject_child_name,
                        subjectName: result.subject_name,
                        targetUserCount: result.target_user_count,
                        testTimeMin: result.test_time_min,
                    },
                });
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
    }, []);


    useEffect(
        () => effect(taoExamReportDetailConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamReportDetailConnection]
    );
    useEffect(
        () => effect(taoExamControlReportDetailConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlReportDetailConnection]
    );


    const fetchData = useCallback((uuid: string, schoolClassUuid: string, tab: TabKeys | '', organizationUuid?: string) => {
        setState({
            isLoading: true,
            result: undefined,
        });

        const reportDetailAction = isControllableUser ?
            Actions.http.connection.tao.exam.controlReportDetail(uuid, { organization_uuid: organizationUuid, school_class_uuid: schoolClassUuid }) :
            tab === 'board' ?
                Actions.http.connection.tao.exam.controlReportDetail(uuid, { school_class_uuid: schoolClassUuid }) :
                Actions.http.connection.tao.exam.reportDetail(uuid, { school_class_uuid: schoolClassUuid });

        dispatch(reportDetailAction);

    }, [dispatch, isControllableUser]);

    const reset = useCallback(() => {
        setState({
            isLoading: false,
            result: undefined,
        });
    }, []);

    return [state, fetchData, reset] as const;
};

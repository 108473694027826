import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { additionDisplayName } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { selectors } from 'flex/Selectors';
import { SourceContext } from './index';

const AppsEffectComponent = () => {
    const [source, setSource] = useContext(SourceContext);
    const dispatch = useDispatch();
    const appsCategorySearchConnection = useSelector(state => state.appsCategorySearchConnection);
    const appsCategoryEditConnection = useSelector(state => state.appsCategoryEditConnection);
    const appsCategoryViewConnection = useAppSelector(selectors.appsCategoryViewConnection);
    const appsOrderEditConnection = useAppSelector(selectors.appsOrderEditConnection);
    const appsEditConnection = useAppSelector(selectors.appsEditConnection);
    const isFirstRender = useRef(true);

    useEffect(() => {
        const status = appsCategorySearchConnection.meta.status;
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                setSource(source => ({
                    ...source,
                    appsList: appsCategorySearchConnection.payload.result.items.map(item => {
                        const created_by = additionDisplayName(item.created_by);
                        const updated_by = item.updated_by ?
                            additionDisplayName(item.updated_by) :
                            created_by;
                        return {
                            ...item,
                            created_by,
                            updated_by,
                        }
                    }),
                    appsListItemCount: appsCategorySearchConnection.payload.result.item_count,
                    categorySearch: { loading: appsCategorySearchConnection.meta.fetch },
                }));
                break;
            }
            case Actions.statusEnum.REQUEST:
                setSource(source => ({
                    ...source,
                    categorySearch: { loading: appsCategorySearchConnection.meta.fetch },
                }));
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                setSource(source => ({
                    ...source,
                    categorySearch: { loading: false },
                }));
                break;
            default:
                break;
        }
    }, [appsCategorySearchConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsEditConnection.meta.status;
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                const uuid = appsEditConnection.meta.mapKey;
                setSource({
                    ...source,
                    appsEdit: { loading: false },
                });
                dispatch(Actions.http.connection.apps.category.view(uuid, uuid));
                break;
            }
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                setSource({
                    ...source,
                    appsEdit: { loading: true },
                });
                break;
            default:
                break;
        }

    }, [appsEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsOrderEditConnection.meta.status;
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                const item = appsOrderEditConnection.payload.result;
                item && setSource({
                    ...source,
                    appCategoryMap: {
                        ...source.appCategoryMap,
                        [item.uuid]: { loading: false, item },
                    }
                });
                break;
            }
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                const mapKey = appsOrderEditConnection.meta.mapKey;
                mapKey && setSource({
                    ...source,
                    appCategoryMap: {
                        ...source.appCategoryMap,
                        [mapKey]: { loading: true, item: undefined },
                    }
                });
                break;
            }
            default:
                break;
        }
    }, [appsOrderEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsCategoryViewConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                const mapKey = appsCategoryViewConnection.meta.mapKey
                const item = appsCategoryViewConnection.payload.result
                mapKey && item && setSource({
                    ...source,
                    appCategoryMap: {
                        ...source.appCategoryMap,
                        [mapKey]: { loading: false, item },
                    }
                });
                break;
            }
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                const mapKey = appsCategoryViewConnection.meta.mapKey;
                mapKey && setSource({
                    ...source,
                    appCategoryMap: {
                        ...source.appCategoryMap,
                        [mapKey]: { loading: true, item: undefined },
                    }
                });
                break;
            }
            default:
                break;
        }
    }, [appsCategoryViewConnection]);// eslint-disable-line

    useEffect(() => {
        if (!isFirstRender.current) {
            if (appsCategoryEditConnection.meta.status === Actions.statusEnum.SUCCESS) {
                switch (appsCategoryEditConnection.type) {
                    case Actions.http.connection.apps.category.delete().type:
                        message.success('教材・アプリグループを削除しました')
                        break;
                    case Actions.http.connection.apps.category.update().type:
                        message.success('教材・アプリグループ情報を編集しました')
                        break;
                    case Actions.http.connection.apps.category.create().type:
                        message.success('教材・アプリグループを作成しました');
                        break;
                    default:
                        break;
                }
            }
            setSource(source => ({
                ...source,
                categoryEdit: { loading: appsCategoryEditConnection.meta.fetch },
            }));
        } else {
            //最終行なので
            isFirstRender.current = false;
        }
    }, [appsCategoryEditConnection]);// eslint-disable-line

    return null;
}

export default AppsEffectComponent;

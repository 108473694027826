import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { additionDisplayName } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';

import { convertExamItem, convertExamItemWithResult } from '../utils/convertExamItem';

export type ResultItemState = {
    result: {
        examItems: ReturnType<typeof convertExamItem>[]
        itemCount: number
        pageSize: number
        pageTotal: number
        totalCount: number
        users: {
            displayName: string
            resultHistories: {
                activityProgress: 'Completed' | 'Submitted'
                agsTimestamp: number
                comment: string | undefined
                correctRate: string
                createdAt: number
                examResultUuid: string
                gradingProgress: 'PendingManual' | 'FullyGraded'
                items: (ReturnType<typeof convertExamItemWithResult> | ReturnType<typeof convertExamItem>)[]
                maxScore: number
                score: number
                uuid: string
            }[]
            uuid: string
        }[]
    } | undefined
    isLoading: boolean
};

type Connection = TaoExamResultItemConnection | TaoExamControlResultItemConnection;


export const useResultItem = () => {
    const [state, setState] = useState<ResultItemState>({
        isLoading: false,
        result: undefined,
    });

    const taoExamResultItemConnection = useAppSelector(state => state.taoExamResultItemConnection);
    const taoExamControlResultItemConnection = useAppSelector(state => state.taoExamControlResultItemConnection);

    const dispatch = useDispatch();

    const effect = useCallback((connection: Connection, state: ResultItemState) => {
        const { meta, payload: { result } } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    result: {
                        examItems: result.exam_items.map(convertExamItem),
                        itemCount: result.item_count,
                        pageSize: result.page_size,
                        pageTotal: result.page_total,
                        totalCount: result.total_count,
                        users: result.items.map(convertResultItemToUsers),
                    },
                }));
                return;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({ ...state, isLoading: false }));
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => effect(taoExamResultItemConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamResultItemConnection]
    );
    useEffect(
        () => effect(taoExamControlResultItemConnection, state),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taoExamControlResultItemConnection]
    );

    const fetchData = useCallback((
        uuid: string,
        classUuid: string | undefined,
        page: number,
        page_size: number,
        tab: TabKeys | '',
    ) => {
        setState({
            isLoading: true,
            result: undefined,
        });

        const resultItemAction = tab === 'board' ?
            Actions.http.connection.tao.exam.controlResultItem :
            Actions.http.connection.tao.exam.resultItem;

        dispatch(resultItemAction(uuid, { page_size, school_class_uuid: classUuid }, page));

    }, [dispatch]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
            result: undefined,
        });
    }, []);

    return [state, fetchData, resetState] as const;
};


const convertResultItemToUsers = (item: Connection['payload']['result']['items'][0]) => {
    return {
        displayName: additionDisplayName(item).display_name,
        resultHistories: item.result_histories.map(history => {
            // 後で有効数字を調整すると浮動小数点数の誤差が出るので、この時点で文字列にしてしまう
            const correctRate = history.max_score === null || history.max_score === 0 ?
                '- %' :
                ((history.score ?? 0) / history.max_score * 100).toFixed(1) + '%';

            return {
                activityProgress: history.activity_progress,
                agsTimestamp: history.ags_timestamp,
                comment: history.comment ?? undefined,
                correctRate,
                createdAt: history.created_at,
                examResultUuid: history.exam_result_uuid,
                gradingProgress: history.grading_progress,
                items: 'items' in history ?
                    history.items.map(item =>
                        item.result !== undefined ?
                            convertExamItemWithResult(item) :
                            convertExamItem(item)) :
                    [],
                maxScore: history.max_score,
                score: history.score,
                uuid: history.uuid,
            };
        }),
        uuid: item.uuid,
    };
};
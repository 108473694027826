export const API_CHECK_LOGGED_IN = `/auth/check-logged-in`;
export const API_AUTH_ADMIN =  `/auth/login`;
export const API_LOGOUT =  `/auth/logout`;
export const API_TENANT = "/auth/my-tenant";

//ファイルアップロード
export const API_UPLOAD_FILE = `/file/create`;

//お知らせ管理者用
export const API_NOTIFICATION = "/information";
export const API_CONTROL_NOTIFICATION = "/control-information";
export const API_NOTIFICATION_CATEGORY = "/information-category";

//アプリ管理
export const API_APP = "/application";
export const API_CONTROL_APP = '/material-application';
export const API_APP_CATEGORY = "/application-category";
export const API_CONTROL_APP_CATEGORY = '/material-application-category';

// アプリテンプレート
export const API_LTI_APP_TEMPLATE = '/lti-app-template'
export const API_LTI_APP_TEMPLATE_FOR_ADMIN = '/lti-app-template-for-admin'

// EduMall
export const API_EDUMALL_CONTENTS = "/edu-mall-contents";

// アンケート
export const API_QUESTIONNAIRES = "/questionnaire"
export const API_CONTROL_QUESTIONNAIRES = "/control-questionnaire"
// アンケート回答
export const API_QUESTIONNAIRES_ANSWER = "/answer"
// アンケート カテゴリー
export const API_QUESTIONNAIRE_CATEGORY = "/questionnaire-category"
export const API_CONTROL_QUESTIONNAIRE_CATEGORY = "/control-questionnaire-category"

//学校管理
export const API_ORGANIZATION = "/organization";

//ユーザー管理
export const API_USER = "/user";

//役割管理
export const API_ROLE = "/role";

//クラス管理
export const API_CLASS = "/school-class";

//年度
export const API_TERM = "/term";

//学園コード
export const API_GRADE = "/grade";

//同期
export const API_SYNC = "/sync";

//非同期job
export const API_JOB = "/job-state";

//taoテスト
export const API_TAO_EXAM_CATEGORY = "/exam-category";
export const API_TAO_CONTROL_EXAM_CATEGORY = "/control-exam-category";
export const API_TAO_EXAM = "/exam";
export const API_TAO_CONTROL_EXAM = "/control-exam";

//利用履歴
export const API_ACTION_LOG = "/action-log";

// メンテナンス・緊急情報
export const API_ANNOUNCEMENT = '/announcement';
// マニュアル
export const API_MANUAL = "/manual";


// LTI Launch
// 基本的に, middleware を経由せずに使うことになるので,
// これだけ, Actions 以外でも使われることになる
export const API_LTI_LAUNCH = "/lti-launch";

/** 毎日の記録 */
export const API_EVERYDAY_NOTE = '/everyday-note';
export const API_CONTROL_EVERYDAY_NOTE = '/control-everyday-note';

// OGP
export const API_OGP = '/ogp/view-data';

// セキュリティ
export const API_SECURITY = '/security';
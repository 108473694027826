import { createAction } from 'redux-actions';

import {
    API_APP,
    API_APP_CATEGORY,
    API_CONTROL_APP,
    API_CONTROL_APP_CATEGORY,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';

import type { Meta, Payload } from '../../utils';

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/APPS';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
};


export const search = createAction(
    createActionType('SEARCH' as const),
    (data) => ({ method: 'get', api: API_APP, data }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const create = createAction(
    createActionType('CREATE' as const),
    (data, mapKey) => ({ method: 'put', api: API_APP + '/create', data }),
    (data, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const controlCreate = createAction(
    createActionType('CONTROL_CREATE' as const),
    (data, mapKey) => ({ method: 'put', api: API_CONTROL_APP + '/create', data }),
    (data, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const update = createAction(
    createActionType('UPDATE' as const),
    (uuid, data, mapKey) => ({
        method: 'post',
        api: API_APP + '/update/' + uuid,
        data,
    }),
    (uuid, data, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const controlUpdate = createAction(
    createActionType('CONTROL_UPDATE' as const),
    (uuid, data, mapKey) => ({
        method: 'post',
        api: API_CONTROL_APP + '/update/' + uuid,
        data,
    }),
    (uuid, data, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const view = createAction(
    createActionType('VIEW' as const),
    (uuid) => ({ method: 'get', api: API_APP + '/view/' + uuid }),
    () => ({ fetch: true, status: StatusEnum.REQUEST })
);

export const controlView = createAction(
    createActionType('CONTROL_VIEW' as const),
    (uuid) => ({
        method: 'get',
        api: `${API_CONTROL_APP}/view/${uuid}`,
    }),
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

const deleteAction = createAction(
    createActionType('DELETE' as const),
    (uuid, mapKey) => ({ method: 'delete', api: API_APP + '/delete/' + uuid }),
    (uuid, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);
export { deleteAction as delete };

export const controlDelete = createAction(
    createActionType('CONTROL_DELETE' as const),
    (uuid, mapKey) => ({ method: 'delete', api: API_CONTROL_APP + '/delete/' + uuid }),
    (uuid, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const changeOrder = createAction(
    createActionType('CHANGE_ORDER' as const),
    (uuid, data, mapKey) => ({
        method: 'post',
        api: API_APP_CATEGORY + '/change-order/' + uuid,
        data,
    }),
    (uuid, data, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const controlChangeOrder = createAction(
    createActionType('CONTROL_CHANGE_ORDER' as const),
    (uuid, data, mapKey) => ({
        method: 'post',
        api: API_CONTROL_APP_CATEGORY + '/change-order/' + uuid,
        data,
    }),
    (uuid, data, mapKey) => ({ fetch: true, status: StatusEnum.REQUEST, mapKey })
);

export const confirm = createAction(
    createActionType('CONFIRM' as const),
    () => ({
        method: 'get',
        api: `${API_APP}/confirm`,
    }),
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const save = createAction(
    createActionType('SAVE' as const),
    () => ({
        method: 'post',
        api: `${API_APP}/save`,
    }),
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const controlSave = createAction(
    createActionType('CONTROL_SAVE' as const),
    () => ({
        method: 'post',
        api: `${API_CONTROL_APP}/save`,
    }),
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const controlTargetOrganization = createAction<
    Payload | Http.Connection.Response.Apps.ControlTargetOrganization,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_ORGANIZATION' as const),
    (appCategoryUuid) => {
        return {
            api: `${API_CONTROL_APP_CATEGORY}/target-organization/${appCategoryUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);

export const controlTargetSchoolClass = createAction<
    Payload | Http.Connection.Response.Apps.ControlTargetSchoolClass,
    Meta,
    string
>(
    createActionType('CONTROL_TARGET_SCHOOL_CLASS' as const),
    (appCategoryUuid) => {
        return {
            api: `${API_CONTROL_APP_CATEGORY}/target-school-class/${appCategoryUuid}`,
            method: 'get',
        };
    },
    () => ({
        fetch: true,
        status: StatusEnum.REQUEST,
    })
);
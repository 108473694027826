import {
    AppstoreAddOutlined,
    BarChartOutlined,
    BookOutlined,
    CalendarOutlined,
    LockOutlined,
    NotificationOutlined,
    SolutionOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Skeleton } from 'antd';
import { ReactComponent as Mexcbt } from 'assets/icon/ic_mexcbt.svg';
import { Link } from 'react-router-dom';

import Announcement from 'components/modules/Announcement';
import Title from 'components/modules/Title';
import { useFeatures } from 'constants/CustomHooks/useFeatures';

import 'styles/pages/home.scss';

const Top = () => {
    const features = useFeatures();
    return (
        <>
            <Title text='トップ' />

            <Announcement />

            <TileContainer loading={features.isLoading}>
                <Tile
                    description='MEXCBTテストを配信します.'
                    icon={<Mexcbt style={{ fill: 'currentColor', height: 55, width: 55 }} />}
                    title='MEXCBTテスト管理'
                    to='/assessment'
                    visible={features.mexcbt}
                />

                <Tile
                    description='お知らせ情報を配信します.'
                    icon={<NotificationOutlined />}
                    title='お知らせ管理'
                    to='/notifications'
                    visible={features.information}
                />

                <Tile
                    description='教材・アプリ管理・アプリグループ構成を設定します.'
                    icon={<AppstoreAddOutlined />}
                    title='教材・アプリ管理'
                    to='/apps'
                    visible={features.application}
                />

                <Tile
                    description='連携しているアプリケーションのテンプレートの管理・編集・削除を設定します.'
                    icon={<BookOutlined />}
                    title='アプリ配信管理'
                    to='/app-templates'
                    visible={features.applicationTemplate}
                />

                <Tile
                    description='アンケート・アンケートカテゴリー構成を設定します.'
                    icon={<SolutionOutlined />}
                    title='アンケート'
                    to='/questionnaires'
                    visible={features.questionnaire}
                />

                <Tile
                    description='利用者情報の管理やクラスと役割を設定します.'
                    icon={<UsergroupAddOutlined />}
                    title='利用者管理'
                    to='/users'
                    visible={features.users}
                />

                <Tile
                    description='テナント内の学校を管理します.'
                    icon={<FontAwesomeIcon icon={faSchool} style={{ height: 55, width: 55 }} />}
                    title='学校管理'
                    to='/organization'
                    visible={features.organization}
                />

                <Tile
                    description='利用履歴を取得します.'
                    icon={<BarChartOutlined />}
                    title='利用履歴の取得'
                    to='/usage'
                    visible={features.actionLog}
                />

                <Tile
                    description='毎日の記録の配信設定・回答結果を確認できます.'
                    icon={<CalendarOutlined />}
                    title='毎日の記録'
                    to='/everyday-note'
                    visible={features.everydayNote}
                />

                <Tile
                    description='セキュリティの設定ができます.'
                    icon={<LockOutlined />}
                    title='テナント設定'
                    to='/security'
                    visible={features.security}
                />
            </TileContainer>
        </>
    );
};

export default Top;


type TitleProps = {
    description: string | JSX.Element;
    icon: JSX.Element;
    title: string | JSX.Element;
    to: string;
    visible: boolean;
};

/**
 * 各画面へのリンクであるタイルコンポーネント
 */
const Tile: React.VFC<TitleProps> = ({ description, icon, title, to, visible }) => {
    if (!visible) return null;

    return (
        <Col span={8}>
            <Link to={to}>
                <div className='home-grid-cell'>
                    <div className='home-gird-icon'>
                        {icon}
                    </div>
                    <div className='home-grid-title'>{title}</div>
                    <div className='home-grid-description'>{description}</div>
                </div>
            </Link>
        </Col>
    );
};

const TileSkelton = [...Array(8)].map((_, index) => (
    <Tile
        description={<Skeleton active paragraph={{ rows: 1, width: '70%' }} title={false} />}
        icon={<Skeleton.Image />}
        key={index}
        title={<Skeleton active paragraph={false} title={{ width: '40%' }} />}
        to='/'
        visible={true}
    />
));

/**
 * タイルコンポーネントを子に持つコンテナとなるコンポーネント
 */
const TileContainer: React.FC<{ loading: boolean }> = ({ children, loading }) => {
    return (
        <div className='home-grid'>
            <Row align='middle' gutter={[12, 12]} justify='start'>
                {loading ? TileSkelton : children}
            </Row>
        </div>
    );
};
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { TabKeys } from 'flex/view/Assessment/examCategoryTabFlex';


export type SaveReportState = {
    isLoading: boolean
};

type Connection = TaoExamSaveReportConnection | TaoExamControlSaveReportConnection;

export const useSaveReport = (tab: TabKeys | '') => {
    const [state, setState] = useState<SaveReportState>({
        isLoading: false,
    });


    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamSaveReportConnection = useAppSelector(state => state.taoExamSaveReportConnection);
    const taoExamControlSaveReportConnection = useAppSelector(state => state.taoExamControlSaveReportConnection);
    const dispatch = useDispatch();

    const effect = useCallback((connection: Connection, state: SaveReportState) => {
        const { meta } = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setState(state => ({
                    ...state,
                    isLoading: meta.fetch,
                }));
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                break;
            }
            default:
                setState(state => ({ ...state, status: meta.status }));
                break;
        }
    }, []);


    useEffect(() => {
        effect(taoExamSaveReportConnection, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, taoExamSaveReportConnection]);

    useEffect(() => {
        effect(taoExamControlSaveReportConnection, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, taoExamControlSaveReportConnection]);


    const request = useCallback((
        uuid: string,
        groupName: string,
        examName: string,
    ) => {
        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const saveReportAction = isControllableUser ?
            Actions.http.connection.tao.exam.controlSaveReport :
            tab === 'board' ?
                Actions.http.connection.tao.exam.controlSaveReport :
                Actions.http.connection.tao.exam.saveReport;

        dispatch(saveReportAction(uuid, groupName, examName));
    }, [dispatch, isControllableUser, tab]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
        });
    }, []);

    return [state, request, resetState] as const;
};
import * as React from 'react';

import {
    BarChartOutlined,
    BookOutlined,
    CalendarOutlined,
    HomeOutlined,
    LockOutlined,
    NotificationOutlined,
    PlusSquareOutlined,
    SolutionOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuProps } from 'antd';
import { ReactComponent as Mexcbt } from 'assets/icon/ic_mexcbt.svg';
import { useLocation, useHistory } from 'react-router-dom';

import { useFeatures } from 'constants/CustomHooks/useFeatures';

import CustomeMenu from './CustomeMenu';

type Props = {
    selectedKey: string
}

const LeftSideMenu: React.VFC<Props> = props => {
    const features = useFeatures();
    const history = useHistory();
    const location = useLocation();
    const [, rootPath] = location.pathname.match(/^(\/(\w|-)+)/) ?? [];
    const [, selectedKey] = props.selectedKey.match(/^(\/(\w|-)+)/) ?? [];

    function handleMenuSelect({ key }: Parameters<Required<MenuProps>['onSelect']>[0]) {
        history.push(`${key}`);
    }

    return (
        <CustomeMenu
            defaultSelectedKeys={[rootPath ?? '/']}
            loading={features.isLoading}
            mode='inline'
            onSelect={handleMenuSelect}
            selectedKeys={[selectedKey ?? '/']}
            theme='dark'
        >
            <Menu.Item icon={<HomeOutlined />} key='/'>
                トップ
            </Menu.Item>

            {features.mexcbt && (
                <Menu.Item icon={<Mexcbt style={{ fill: 'currentColor', height: 15, width: 15 }} />} key='/assessment'>
                    MEXCBTテスト管理
                </Menu.Item>
            )}

            {features.information && (
                <Menu.Item icon={<NotificationOutlined />} key='/notifications'>
                    お知らせ管理
                </Menu.Item>
            )}

            {features.application && (
                <Menu.Item icon={<PlusSquareOutlined />} key='/apps'>
                    教材・アプリ管理
                </Menu.Item>
            )}

            {features.applicationTemplate && (
                <Menu.Item icon={<BookOutlined />} key='/app-templates'>
                    アプリ配信管理
                </Menu.Item>
            )}

            {features.questionnaire && (
                <Menu.Item icon={<SolutionOutlined />} key='/questionnaires'>
                    アンケート
                </Menu.Item>
            )}

            {features.users && (
                <Menu.Item icon={<TeamOutlined />} key='/users'>
                    利用者管理
                </Menu.Item>
            )}

            {features.organization && (
                <Menu.Item
                    icon={<FontAwesomeIcon icon={faSchool} style={{ height: 15, width: 15 }} />}
                    key='/organization'
                >
                    学校管理
                </Menu.Item>
            )}

            {features.actionLog && (
                <Menu.Item icon={<BarChartOutlined />} key='/usage'>
                    利用履歴の取得
                </Menu.Item>
            )}

            {features.everydayNote && (
                <Menu.Item icon={<CalendarOutlined />} key='/everyday-note'>
                    毎日の記録
                </Menu.Item>
            )}

            {features.security && (
                <Menu.Item icon={<LockOutlined />} key='/security'>
                    テナント設定
                </Menu.Item>
            )}
        </CustomeMenu>
    );
};

export default LeftSideMenu;
import { createAction } from 'redux-actions';
import {
    API_TERM,
} from 'constants/endpoints';
import { StatusEnum } from 'flex/actions/http/StatusEnum';;

/**
 * Prefix 付きの ActionType を作成する
 */
const createActionType = <ActionType extends string>(actionType: ActionType) => {
    const prefix = 'CONNECTION/TERM';
    return `${prefix}/${actionType}` as `${typeof prefix}/${ActionType}`;
}


export const search = createAction(
    createActionType('SEARCH' as const),
    (data, page, viewName) => {
        const pageNum = page === undefined ? '' : page;
        return ({ method: 'get', data, api: `${API_TERM}/${pageNum}` })
    },
    (data, page, viewName) => ({ fetch: true, status: StatusEnum.REQUEST, viewName })
);
import {createSelector} from 'reselect'
import {selectors} from "../../Selectors";
import {BACKGROUND_JOB_TYPE} from "../../../components/modules/Job/Background/BackgroundJobProvider";
import {isContainsJobType} from "../../../components/modules/Job/Background/BackgroundJobUtils";


export const organizationManagementTabSelectors = {
    csvImportDisabledMsg: createSelector([selectors.jobInfoMap], (jobInfoMap) => (
            isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.ORGANIZATION_CSV_IMPORT)
                ? (<p>CSVインポートはすでに実行済みです。<br/>進捗状況については、画面左下からご確認ください</p>)
                : ''
        )
    )
}

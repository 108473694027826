import * as Actions from 'flex/Actions';
import { SavingDataExecutor } from 'flex/utils';
const { statusEnum } = Actions;

export const savingDataForConnection = (
    baseStore: StoreAccessor,
    baseNext: StoreNext<UserManagementTabState>,
    baseAction: ActionType<unknown, unknown>
) => {
    const executor = new SavingDataExecutor<UserManagementTabState>();
    const userManagementTab = baseStore.getState().userManagementTab;

    executor.addSavingData(Actions.http.connection.users.view, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        next({
            ...userManagementTab,
            editUser: {
                ...userManagementTab.editUser,
                loading: action.meta.fetch,
                userRecord: action.payload.result,
            },
        });
    });

    executor.addSavingData(Actions.http.connection.users.search, (store, next, action) => {
        if (action.meta.status !== statusEnum.SUCCESS) return;
        if (!('result' in action.payload)) return;

        const displayedRowUuids = action.payload.result.items.map(item => item.uuid);

        store.dispatch(Actions.view.viewLogic.userManagementTab.pullSelectedRows(displayedRowUuids));
    });

    executor.execute(baseStore, baseNext, baseAction);
};

import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SyncOutlined } from '@ant-design/icons';
import { Button, Tag, Alert, Select } from 'antd';

import BaseForm from 'components/modules/BaseForm';
import BaseModal from 'components/modules/BaseModal';
import { getCurrentTerm } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import { ListContext } from '../../index';

const Form = BaseForm.ModalForm;


const AnnualUpdateModal = (props) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const dispatch = useDispatch();
    const [list] = useContext(ListContext);
    const [loading, setLoading] = useState(false);
    const isFirstRender = useRef(false);
    const updateRenewClassConnection = useSelector(state => state.usersUpdateRenewClassConnection);


    useEffect(() => {
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (props.visible) {
            const initialValue = {
                'term_uuid': getCurrentTerm(list.termList).uuid,
            };
            form.setFieldsValue(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    useEffect(() => {
        setLoading(updateRenewClassConnection.meta.fetch);
        if (!isFirstRender.current && props.visible) {
            if (updateRenewClassConnection.meta.status === Actions.statusEnum.SUCCESS) {
                props.onOk();
            }
        } else {
            isFirstRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateRenewClassConnection]);

    function onFinish(values) {
        dispatch(Actions.http.connection.users.updateRenewClass(values.term_uuid));
    }

    function submit() {
        form.submit();
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    return (
        <BaseModal
            className='half-common-modal'
            footer={[
                <Button
                    key='back'
                    loading={loading}
                    onClick={props.onCancel}
                    size='large'
                >キャンセル
                </Button>,
                <Button
                    key='execute'
                    loading={loading}
                    onClick={submit}
                    size='large'
                    type='primary'
                >実行
                </Button>,
            ]}
            forceRender
            onCancel={props.onCancel}
            title={<span><SyncOutlined /> 年次更新</span>}
            visible={props.visible}
        >

            <Alert
                description='対象のユーザーの次回サインイン時に、クラスを選択する画面を表示させます'
                message={<span style={{ fontWeight: 'bold' }} >この操作は取り消せません</span>}
                showIcon
                type='warning'
            />

            <Form {...formItemLayout} form={form} onFinish={onFinish} style={{ margin: '30px' }}>
                <Form.Item
                    label='対象のユーザー'
                    name='term_uuid'
                    rules={[{ message: '年次更新するユーザーを選択してください', required: true }]}
                >
                    <Select>
                        {list.termList.map((value) => {
                            return (
                                <Option key={value.uuid}>
                                    <Tag
                                        color='#AAAAAA'
                                        style={{
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            width: '75px',
                                        }}
                                    >
                                        {value.name}
                                    </Tag> のクラスのユーザー
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </BaseModal>
    );
};

AnnualUpdateModal.defaultProps = {
    onCancel: () => { console.error('propsの設定'); },
    onOk: () => { console.error('propsの設定必須'); },
    visible: true,
};

export default AnnualUpdateModal;

import { getMoment } from 'constants/GlobalConfig';

import { Source, TARGET, TERM_TYPE } from './UsageDataProvider';
const moment = getMoment();

// private とするため `_` をつけている
export class ActionLogDetailQueryBuilder {
    edumall: {
        text: string;
        value: string;
    } | undefined;
    hierarchy: Source['hierarchy'] | undefined;
    isControllableUser: boolean | undefined;
    organizationAppLists: Source['organizationAppLists'] | undefined;
    page: number | undefined;
    pageSize: number | undefined;
    target: string | undefined;
    targetDetails: { app: string, group: string }[] | undefined;
    term: moment.Moment | undefined;
    termType: typeof TERM_TYPE[keyof typeof TERM_TYPE] | undefined;
    user: User | undefined;

    private _contentUuids: string | undefined;
    private _endAt: number | undefined;
    private _page: number | undefined;
    private _pageSize: number | undefined;
    private _startAt: number | undefined;
    private _userUuid: string | undefined;

    constructor() {
        // source を作る上での材料となるもの
        this.edumall = undefined;
        this.hierarchy = undefined;
        this.organizationAppLists = undefined;
        this.page = undefined;
        this.pageSize = undefined;
        this.target = undefined;
        this.targetDetails = undefined;
        this.term = undefined;
        this.termType = undefined;


        // query を作る上でのパーツとなるもの
        this._contentUuids = undefined;
        this._endAt = undefined;
        this._page = undefined;
        this._pageSize = undefined;
        this._startAt = undefined;
        this._userUuid = undefined;
    }

    private _isValid = () => {
        if (this.term === undefined) return false;
        if (this.termType === undefined) return false;
        if (this.user === undefined) return false;
        if (this.target === undefined) return false;

        return true;
    }

    private _createStartAt = () => {
        if (this.termType === undefined) return;

        switch (this.termType) {
            case TERM_TYPE.YEAR:
                this._startAt = moment(this.term).startOf(this.termType).add(3, 'months').unix();
                break;
            case TERM_TYPE.WEEK:
            case TERM_TYPE.DATE:
            default:
                this._startAt = moment(this.term).startOf(this.termType).unix();
                break;
        }
    }

    private _createEndAt = () => {
        if (this.termType === undefined) return;

        switch (this.termType) {
            case TERM_TYPE.YEAR:
                this._endAt = moment(this.term).endOf(this.termType).add(3, 'months').unix();
                break;
            case TERM_TYPE.WEEK:
            case TERM_TYPE.DATE:
            default:
                this._endAt = moment(this.term).endOf(this.termType).unix();
                break;
        }
    }

    private _createUserUuid = () => {
        if (this.hierarchy === undefined) return;

        this._userUuid = this.hierarchy.slice(-1)[0].uuid;
    }

    private _createPageSize = () => {
        this._pageSize = this.pageSize !== undefined ? this.pageSize : 20;
    }

    private _createPage = () => {
        this._page = this.page !== undefined ? this.page : 1;
    }

    private _createContentUuids = () => {
        const uuids = [] as (string | string[] | undefined)[];
        switch (this.target) {
            case TARGET.USE_APP:
                if (this.targetDetails === undefined) return;
                uuids.push(...this.targetDetails.map(e => {
                    if (this.organizationAppLists === undefined) return undefined;

                    if (e.app === 'all') {
                        if (this.isControllableUser) {
                            return this.organizationAppLists[e.group].list.map(e => e.value);
                        } else {
                            // admin であれば自身のアプリにしかアクセスできないはずなので, 1つ取ってくれば良い
                            return Object.values(this.organizationAppLists)[0].list.map(e => e.value);
                        }
                    } else {
                        return e.app;
                    }
                }));
                break;
            case TARGET.EDUMALL:
                if (this.targetDetails === undefined) return;
                uuids.push(...this.targetDetails.map(e => e.app));
                break;
            default:
                break;
        }

        this._contentUuids = Array.from(new Set(uuids.flat())).join(',');
    }

    getResult = () => {
        if (!this._isValid) throw new Error();

        this._createStartAt();
        this._createEndAt();
        this._createUserUuid();
        this._createPageSize();
        this._createPage();
        this._createContentUuids();

        const parameter: Parameter = {
            action: this.target,
            end_at: this._endAt,
            page: this._page,
            page_size: this._pageSize,
            start_at: this._startAt,
            user_uuid: this._userUuid,
        };

        if (this._contentUuids) parameter.content_uuids = this._contentUuids;

        return parameter;
    }
}

type Parameter = {
    start_at: number | undefined
    end_at: number | undefined
    user_uuid: string | undefined
    action: string | undefined
    page_size: number | undefined
    page: number | undefined
    content_uuids?: string | undefined
}

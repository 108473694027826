import RichTextView from 'components/modules/RichTextView';

import QuestionnaireMetaInfo from './QuestionnaireMetaInfo';
import QuestionResults from './QuestionResults';


const ListView = ({
    onClickAnswerDetail,
    questionnaire,
}: {
    onClickAnswerDetail: (item: Question) => void;
    questionnaire: Questionnaire;
}) => {
    return (
        <>
            <QuestionnaireMetaInfo questionnaire={questionnaire} />

            <div className='module-questionnaire-title'>
                <h2 className='title'>{questionnaire.name}</h2>
                <RichTextView model={questionnaire.content} />
            </div>

            <QuestionResults
                items={questionnaire.items}
                onClickAnswerDetail={onClickAnswerDetail}
            />
        </>
    );
};

export default ListView;

import { getMoment } from 'constants/GlobalConfig';

const moment = getMoment();

/**
 * Questionnaire を Form 上で使える形式に変換する
 */
export const convertControlFormValue = (
    values: EverydayNote.ControlQuestionnaire | undefined,
    isCopy: boolean,
): EverydayNote.Form.Control.Questionnaire | undefined => {
    if (values === undefined) return undefined;

    const {
        items,
        publishEndAt,
        publishStartAt,
        recordableDayOfWeeks,
        recordableTimeEnd,
        recordableTimeStart,
        uuid,
        // 以下, 不要なので削除するもの
        createdAt,
        createdBy,
        isLocked,
        updatedAt,
        updatedBy,
        ...restVal
    } = values;

    return {
        ...restVal,
        duration: [
            moment.unix(publishStartAt),
            moment.unix(publishEndAt),
        ],
        isDesignatedTerm: restVal.targetTerm !== undefined,
        isOverwritePublish: [false],
        isSetRecordableTime: `${recordableTimeStart},${recordableTimeEnd}` !== '00:00:00,23:59:59' ||
            recordableDayOfWeeks.sort().join() !== '0,1,2,3,4,5,6',
        items: items.map(item => {
            const {
                candidates,
                evaluationScaleMax,
                uuid,
                ...restItem
            } = item;

            return {
                candidates: candidates.map(candidate => ({
                    content: candidate.content,
                    order: candidate.order,
                    uuid: isCopy ? undefined : candidate.uuid,
                })),
                evaluationScaleMax: evaluationScaleMax ?? undefined,
                uuid: isCopy ? undefined : uuid,
                ...restItem,
            };
        }),
        recordableDayOfWeeks,
        recordableTimeEnd: moment(recordableTimeEnd, 'HH:mm'),
        recordableTimeStart: moment(recordableTimeStart, 'HH:mm'),
        uuid: isCopy ? undefined : uuid,
    };
};


/**
 * Questionnaire を Form 上で使える形式に変換する
 */
export const convertAdminFormValue = (
    values: EverydayNote.AdminQuestionnaire | undefined,
    isCopy: boolean,
): EverydayNote.Form.Admin.ControlQuestionnaire | EverydayNote.Form.Admin.AdminQuestionnaire | undefined => {
    if (values === undefined) return undefined;

    const {
        items,
        publishEndAt,
        publishStartAt,
        recordableDayOfWeeks,
        recordableTimeEnd,
        recordableTimeStart,
        uuid,
        // 以下, 不要なので削除するもの
        createdAt,
        createdBy,
        isLocked,
        updatedAt,
        updatedBy,
        ...restVal
    } = values;

    return {
        ...restVal,
        duration: [
            moment.unix(publishStartAt),
            moment.unix(publishEndAt),
        ],
        isSetRecordableTime: `${recordableTimeStart},${recordableTimeEnd}` !== '00:00:00,23:59:59' ||
            recordableDayOfWeeks.sort().join() !== '0,1,2,3,4,5,6',
        items: items.map(item => {
            const {
                candidates,
                evaluationScaleMax,
                uuid,
                ...restItem
            } = item;

            return {
                candidates: candidates.map(candidate => ({
                    content: candidate.content,
                    order: candidate.order,
                    uuid: isCopy ? undefined : candidate.uuid,
                })),
                evaluationScaleMax: evaluationScaleMax ?? undefined,
                uuid: isCopy ? undefined : uuid,
                ...restItem,
            };
        }),
        recordableDayOfWeeks,
        recordableTimeEnd: moment(recordableTimeEnd, 'HH:mm'),
        recordableTimeStart: moment(recordableTimeStart, 'HH:mm'),
        uuid: isCopy ? undefined : uuid,
    };
};

import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as NoteIcon } from 'assets/icon/ic_note.svg';
import { ReactComponent as ReloadIcon } from 'assets/icon/ic_reload.svg';

import { BaseTable } from 'components/modules/BaseTable';
import { JOB_TYPE } from 'components/modules/Job/JobModal';
import { useAppSelector } from 'flex/utils';

import { SearchExamCategoryWithResultState } from './hooks/useSearchWithResultExamCategory';
import { Rate } from './Rate';


type ExamCategoryWithResult = SearchExamCategoryWithResultState['items'][number];
type ExamWithResult = SearchExamCategoryWithResultState['items'][number]['exams'][number];

type Props = {
    dataSource: ExamWithResult[]
    examCategory: ExamCategoryWithResult
    onClickSaveOrUpdateAllReport: (uuid: string, examUuids: string[], groupName: string) => void
    onClickSaveOrUpdateReport: (uuid: string, groupName: string, examName: string) => void
    onClickViewAllTestResult: (exam: ExamWithResult) => void
}

export const ExamTable: React.VFC<Props> = ({
    dataSource,
    examCategory,
    onClickSaveOrUpdateAllReport,
    onClickSaveOrUpdateReport,
    onClickViewAllTestResult,
}) => {
    // クリック後、即時にボタンを無効化するためのstate
    const [clickedExamUuid, setClickedExamUuid] = React.useState('');
    const [clickedExamCatUuid, setClickedExamUCatUuid] = React.useState('');

    const jobInfoMap = useAppSelector(state => state.jobInfoMap);
    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);

    const uuids = Object.values(jobInfoMap)
        .filter(jobInfo => jobInfo.job.type === JOB_TYPE.SAVE_EXAM_REPORT)
        .map(jobInfo => jobInfo.someUuid);


    const columns: ColumnsType<ExamWithResult> = [
        {
            dataIndex: 'title',
            key: 'title',
            title: 'タイトル',
            width: 300,
        },
        {
            dataIndex: 'author',
            render: (_, record) => {
                if (!record.created_by) return <span> - </span>;

                return (
                    <span>
                        {`${record.created_by.display_name}`}
                    </span>
                );
            },
            title: '作成者',
            width: 170,
        },
        {
            dataIndex: 'subject_name',
            key: 'subject_name',
            render: (subject_name) => {
                return subject_name;
            },
            title: '教科',
            width: 100,
        },
        {
            key: 'answer_count',
            render: (_, record) => {
                const answerCount = record.user_count;
                return `${answerCount} 人`;
            },
            title: '全解答者数',
            width: 100,
        },
        {
            key: 'average_correct_answer_rate',
            render: (_, record) => {
                const percent = Number((record.average_per ?? 0).toFixed(2));
                return (
                    <div className='correct-answer-rate'>
                        <div className='correct-answer-rate-text'>{percent}%</div>
                        <div className='correct-answer-rate-bar'><Rate percent={percent} /></div>
                    </div>
                );
            },
            title: (
                <Tooltip
                    title={
                        isControllableUser ?
                            '指定した表示期間内に実施された受検分での平均正答率を示しています。' :
                            '指定した表示期間内に実施された受検分での平均正答率を示しています。一斉配信のテストの場合は、この学校での平均正答率が表示されます。'
                    }
                >
                    平均正答率
                </Tooltip>
            ),
            width: 200,
        },
        {
            align: 'center',
            key: 'action',
            render: (_, record) => {
                const disabled = (uuids.includes(record.uuid) || clickedExamUuid === record.uuid) ||
                    // まとめてレポート生成されている場合も disabled とする。
                    (uuids.includes(examCategory.uuid) || clickedExamCatUuid === examCategory.uuid);
                const onClickSaveOrUpdate = () => {
                    onClickSaveOrUpdateReport(record.uuid, examCategory.title, record.title);
                    setClickedExamUuid(record.uuid);
                };

                if (record.is_report_generated) {
                    return (
                        <div className='actions' style={{ gap: '16px' }}>
                            <Button
                                className='button with-icon'
                                disabled={disabled}
                                onClick={onClickSaveOrUpdate}
                                style={{ paddingInline: '2px' }}
                                type='link'
                            >
                                <Icon component={ReloadIcon} />
                                <span>レポートを更新</span>
                            </Button>
                            <Button
                                className='button with-icon'
                                onClick={() => onClickViewAllTestResult(record)}
                                style={{ paddingInline: '2px' }}
                                type='link'
                            >
                                <RightOutlined />
                                <span>レポートを見る</span>
                            </Button>
                        </div>
                    );
                } else {
                    return (
                        <div className='actions'>
                            <Button className='button with-icon' disabled={disabled} onClick={onClickSaveOrUpdate} type='link'>
                                <Icon component={NoteIcon} />
                                <span>レポートを生成</span>
                            </Button>
                        </div>
                    );
                }
            },
            title: () => {
                const disabled = uuids.includes(examCategory.uuid) || clickedExamCatUuid === examCategory.uuid;
                const handleClick = () => {
                    onClickSaveOrUpdateAllReport(examCategory.uuid, examCategory.exams.map(e => e.uuid), examCategory.title);
                    setClickedExamUCatUuid(examCategory.uuid);
                };

                return (
                    <Button
                        className='button table-header'
                        disabled={disabled}
                        onClick={handleClick}
                        type='link'
                    >
                        <span>まとめてレポートの生成･更新</span>
                    </Button>
                );
            },
            width: 200,
        },
    ];

    return (
        <div className='table' style={{ width: '100%' }}>
            <BaseTable
                columns={columns}
                dataSource={dataSource}
                emptyDescription='データがありません'
                pagination={false}
            />
        </div>
    );
};
import {createSelector} from 'reselect'
import {selectors} from '../../../Selectors'
import {isContainsJobType} from '../../../../components/modules/Job/Background/BackgroundJobUtils'
import {BACKGROUND_JOB_TYPE} from '../../../../components/modules/Job/Background/BackgroundJobProvider'


export const classesManagementTabSelectors = {
    csvExportDisabledMsg: createSelector([selectors.jobInfoMap], (jobInfoMap) => (
            isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_EXPORT)
                ? (<p>CSVエクスポートはすでに実行済みです。<br/>進捗状況については、画面左下からご確認ください</p>)
                : ''
        )
    ),
    csvImportDisabledMsg: createSelector([selectors.jobInfoMap], (jobInfoMap) => (
            isContainsJobType(jobInfoMap, BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT)
                ? (<p>CSVインポートはすでに実行済みです。<br/>進捗状況については、画面左下からご確認ください</p>)
                : ''
        )
    )
}

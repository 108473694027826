import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import './styles/tailwind/tailwind.css';
import "./styles/antd.less";
import './styles/index.scss';
import App from './App';
import {applyMiddleware, combineReducers, createStore, compose } from "redux";
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import * as Reducers from "./flex/Reducers";

import {ConfigProvider} from 'antd';
import locale from "antd/es/locale/ja_JP";
import {connection} from "./flex/middleware/connection";
import savingData from "./flex/middleware/savingData";
import viewLogic from "./flex/middleware/viewLogic";





const rootReducer = combineReducers({
    ...Reducers
});

const composeEnhancers =
    typeof window === 'object' &&
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const enhancer = composeEnhancers(
    applyMiddleware(viewLogic, connection, savingData),
);

const store = createStore(
    rootReducer,
    enhancer
);

process.env.NODE_ENV !== "development" && (console.log = () => {});

ReactDOM.render(
    <Provider  store={store}>
        <ConfigProvider locale={locale}>
            <App/>
        </ConfigProvider>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover, Timeline } from 'antd';
import PropTypes from 'prop-types';

import { useAppSelector } from 'flex/utils';

import {
    BACKGROUND_JOB_TYPE,
    BACKGROUND_JOB_TYPE_GROUPS as JOB_TYPE_GROUPS,
} from '../BackgroundJobProvider';


export const JobTitle = ({ job }) => {
    const { features } = useAppSelector(state => state.tenant.payload);

    if (
        JOB_TYPE_GROUPS.SYNC_TYPES.includes(job.type) ||
        JOB_TYPE_GROUPS.SYNC_DRY_RUN_TYPES.includes(job.type)
    ) {
        return <SyncTypeTile enableSyncGroup={features['sync-group']} job={job} />;

    } else if (JOB_TYPE_GROUPS.CSV_IMPORT_TYPES.includes(job.type)) {
        return <CsvImportTypeTile job={job} />;

    } else if (JOB_TYPE_GROUPS.CSV_EXPORT_TYPES.includes(job.type)) {
        return <CsvExportTypeTitle job={job} />;

    } else if (JOB_TYPE_GROUPS.SAVE_TYPES.includes(job.type)) {
        return <SaveTypeTitle job={job} />;

    } else {
        return '';
    }
};

JobTitle.propTypes = {
    job: PropTypes.shape({
        done_at: PropTypes.number,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

const SyncTypeTile = ({
    enableSyncGroup,
    job,
}) => {
    // 共通
    switch (job.type) {
        case BACKGROUND_JOB_TYPE.SYNC_DELETED_USER_DRYRUN:
            return !job.done_at ?
                <>ユーザー削除情報の同期チェック中 (1/4) <UserDeleteSyncOverallQuestion step={1} /></> :
                <>ユーザー削除情報の同期チェック結果 (2/4) <UserDeleteSyncOverallQuestion step={2} /></>;
        case BACKGROUND_JOB_TYPE.SYNC_DELETED_USER:
            return !job.done_at ?
                <>ユーザー削除情報の同期中 (3/4) <UserDeleteSyncOverallQuestion step={3} /></> :
                <>ユーザー削除情報の同期結果 (4/4) <UserDeleteSyncOverallQuestion step={4} /></>;
        default:
            break;
    }

    if (enableSyncGroup) {
        switch (job.type) {
            case BACKGROUND_JOB_TYPE.SYNC_GROUP_DRYRUN:
                return !job.done_at ?
                    <>クラス情報の同期チェック中 (1/12) <UserSyncOverallQuestion step={1} /></> :
                    <>クラス情報の同期チェック結果 (2/12) <UserSyncOverallQuestion step={2} /></>;
            case BACKGROUND_JOB_TYPE.SYNC_GROUP:
                return !job.done_at ?
                    <>クラス情報の同期中 (3/12) <UserSyncOverallQuestion step={3} /></> :
                    <>クラス情報の同期結果 (4/12) <UserSyncOverallQuestion step={4} /></>;
            case BACKGROUND_JOB_TYPE.SYNC_USER_DRYRUN:
                return !job.done_at ?
                    <>ユーザー情報の同期チェック中 (5/12) <UserSyncOverallQuestion step={5} /></> :
                    <>ユーザー情報の同期チェック結果 (6/12) <UserSyncOverallQuestion step={6} /></>;
            case BACKGROUND_JOB_TYPE.SYNC_USER:
                return !job.done_at ?
                    <>ユーザー情報の同期中 (7/12) <UserSyncOverallQuestion step={7} /></> :
                    <>ユーザー情報の同期結果 (8/12) <UserSyncOverallQuestion step={8} /></>;
            case BACKGROUND_JOB_TYPE.SYNC_MEMBER_DRYRUN:
                return !job.done_at ?
                    <>所属情報の同期チェック中 (9/12) <UserSyncOverallQuestion step={9} /></> :
                    <>所属情報の同期チェック結果 (10/12) <UserSyncOverallQuestion step={10} /></>;
            case BACKGROUND_JOB_TYPE.SYNC_MEMBER:
                return !job.done_at ?
                    <>所属情報の同期 (11/12) <UserSyncOverallQuestion step={11} /></> :
                    <>所属情報の同期結果 (12/12) <UserSyncOverallQuestion step={12} /></>;
            default:
                return '';
        }
    } else {
        switch (job.type) {
            case BACKGROUND_JOB_TYPE.SYNC_USER_DRYRUN:
                return !job.done_at ?
                    <>ユーザー情報の同期チェック中 (1/4) <UserSyncOverallQuestion step={5} /></> :
                    <>ユーザー情報の同期チェック結果 (2/4) <UserSyncOverallQuestion step={6} /></>;
            case BACKGROUND_JOB_TYPE.SYNC_USER:
                return !job.done_at ?
                    <>ユーザー情報の同期中 (3/4) <UserSyncOverallQuestion step={7} /></> :
                    <>ユーザー情報の同期結果 (4/4) <UserSyncOverallQuestion step={8} /></>;
            default:
                return '';
        }
    }
};

const CsvImportTypeTile = ({
    job,
}) => {
    switch (job.type) {

        case BACKGROUND_JOB_TYPE.USER_CSV_IMPORT:
            return !job.done_at ?
                <>ユーザCSVインポート中</> :
                <>ユーザCSVインポート結果</>;
        case BACKGROUND_JOB_TYPE.DELETE_USER_FROM_CSV_IMPORT:
            return !job.done_at ?
                <>ユーザ削除CSVインポート中</> :
                <>ユーザ削除CSVインポート結果</>;
        case BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT:
            return !job.done_at ?
                <>クラスCSVインポート中</> :
                <>クラスCSVインポート結果</>;
        case BACKGROUND_JOB_TYPE.ORGANIZATION_CSV_IMPORT:
            return !job.done_at ?
                <>学校CSVインポート中</> :
                <>学校CSVインポート結果</>;
        default:
            return '';
    }
};

const CsvExportTypeTitle = ({
    job,
}) => {
    return !job.done_at ?
        <>{job.type_name}中</> :
        <>{job.type_name}結果</>;
};

const SaveTypeTitle = ({
    job,
}) => {
    return !job.done_at ?
        <>{job.type_name}中</> :
        <>{job.type_name}結果</>;
};


const SyncUserOnlyTimeline = ({ step }) => (
    <Timeline style={{ marginBottom: '-48px' }}>
        <Timeline.Item color={step === 5 ? 'blue' : 'gray'} dot={1}>ユーザー情報の同期チェック</Timeline.Item>
        <Timeline.Item color={step === 6 ? 'blue' : 'gray'} dot={2}>ユーザー情報の同期チェック結果</Timeline.Item>
        <Timeline.Item color={step === 7 ? 'blue' : 'gray'} dot={3}>ユーザー情報の同期</Timeline.Item>
        <Timeline.Item color={step === 8 ? 'blue' : 'gray'} dot={4}>ユーザー情報の同期結果</Timeline.Item>
    </Timeline>
);

const SyncAllTimeline = ({ step }) => (
    <Timeline style={{ marginBottom: '-48px' }}>
        <Timeline.Item color={step === 1 ? 'blue' : 'gray'} dot={1}>クラス情報の同期チェック</Timeline.Item>
        <Timeline.Item color={step === 2 ? 'blue' : 'gray'} dot={2}>クラス情報の同期チェック結果</Timeline.Item>
        <Timeline.Item color={step === 3 ? 'blue' : 'gray'} dot={3}>クラス情報の同期</Timeline.Item>
        <Timeline.Item color={step === 4 ? 'blue' : 'gray'} dot={4}>クラス情報の同期結果</Timeline.Item>
        <Timeline.Item color={step === 5 ? 'blue' : 'gray'} dot={5}>ユーザー情報の同期チェック</Timeline.Item>
        <Timeline.Item color={step === 6 ? 'blue' : 'gray'} dot={6}>ユーザー情報の同期チェック結果</Timeline.Item>
        <Timeline.Item color={step === 7 ? 'blue' : 'gray'} dot={7}>ユーザー情報の同期</Timeline.Item>
        <Timeline.Item color={step === 8 ? 'blue' : 'gray'} dot={8}>ユーザー情報の同期結果</Timeline.Item>
        <Timeline.Item color={step === 9 ? 'blue' : 'gray'} dot={9}>所属情報の同期チェック</Timeline.Item>
        <Timeline.Item color={step === 10 ? 'blue' : 'gray'} dot={10}>所属情報の同期チェック結果</Timeline.Item>
        <Timeline.Item color={step === 11 ? 'blue' : 'gray'} dot={11}>所属情報の同期</Timeline.Item>
        <Timeline.Item color={step === 12 ? 'blue' : 'gray'} dot={12}>所属情報の同期結果</Timeline.Item>
    </Timeline>
);

const UserDeleteSyncTimeline = ({ step }) => (
    <Timeline style={{ marginBottom: '-48px' }}>
        <Timeline.Item color={step === 1 ? 'blue' : 'gray'} dot={1}>ユーザー削除情報の同期チェック</Timeline.Item>
        <Timeline.Item color={step === 2 ? 'blue' : 'gray'} dot={2}>ユーザー削除情報の同期チェック結果</Timeline.Item>
        <Timeline.Item color={step === 3 ? 'blue' : 'gray'} dot={3}>ユーザー削除情報の同期</Timeline.Item>
        <Timeline.Item color={step === 4 ? 'blue' : 'gray'} dot={4}>ユーザー削除情報の同期結果</Timeline.Item>
    </Timeline>
);

const UserSyncOverallQuestion = ({ step }) => {
    const { features } = useAppSelector(state => state.tenant.payload);
    const timeline = features['sync-group'] ?
        <SyncAllTimeline step={step} /> :
        <SyncUserOnlyTimeline step={step} />;

    return (
        <Popover
            title={<b>ユーザ同期</b>}
            content={timeline}
        >
            <QuestionCircleOutlined />
        </Popover>
    );
};


const UserDeleteSyncOverallQuestion = (props) => {
    const step = props.step
    return (
        <Popover title={<b>ユーザ削除同期</b>}
            content={
                <Timeline style={{ marginBottom: '-48px' }}>
                    <Timeline.Item dot={1} color={step === 1 ? 'blue' : 'gray'}>ユーザー削除情報の同期チェック</Timeline.Item>
                    <Timeline.Item dot={2} color={step === 2 ? 'blue' : 'gray'}>ユーザー削除情報の同期チェック結果</Timeline.Item>
                    <Timeline.Item dot={3} color={step === 3 ? 'blue' : 'gray'}>ユーザー削除情報の同期</Timeline.Item>
                    <Timeline.Item dot={4} color={step === 4 ? 'blue' : 'gray'}>ユーザー削除情報の同期結果</Timeline.Item>
                </Timeline>} >
            <QuestionCircleOutlined />
        </Popover>
    );
};
import { useDispatch } from 'react-redux';

import { Button, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import { getMoment } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';

import { useUsageData, createInitDisplayValues } from '../Common/UsageDataProvider';
import { createActionLogTotalQuery, createActionLogDetailQuery } from '../Common/utils';
import Graph from './Graph';
import SummaryCard from './SummaryCard';
const { Text } = Typography;
const moment = getMoment();

const Summary = (props) => {
    const { appName, current, data, mean, term, loading, isCopyLayout } = props;
    const [source, setSource] = useUsageData();

    const dispatch = useDispatch();

    const fetchDetailAndSummaryData = (source) => {
        const queries = createActionLogTotalQuery({
            hierarchy: source.hierarchy,
            source,
        });
        dispatch(Actions.http.connection.usage.total(queries));

        if ((source.isControllableUser && source.hierarchy.length === 4) ||
            (!source.isControllableUser && source.hierarchy.length === 3)) {
            const detailQueries = createActionLogDetailQuery({
                hierarchy: source.hierarchy,
                source,
            });
            dispatch(Actions.http.connection.usage.detail(detailQueries));
        }
    };

    const onClickPrevTerm = () => {
        const copySource = Object.assign({}, source, createInitDisplayValues());
        copySource.term = createPrevTerm(copySource.term, copySource.termType);
        setSource(copySource);

        fetchDetailAndSummaryData(copySource);
    };

    const onClickNextTerm = () => {
        const copySource = Object.assign({}, source, createInitDisplayValues());
        copySource.term = createNextTerm(copySource.term, copySource.termType);
        setSource(copySource);

        fetchDetailAndSummaryData(copySource);
    };

    return (
        <div className='summary'>
            <SummaryCard
                appName={appName}
                current={current}
                mean={mean}
                term={term}
            />

            <div className='summary-graphs' style={isCopyLayout ? { width: '450px' } : null}>
                <Row className='summary-title'>
                    <Col className='summary-title-operator-left' span={2}>
                        <Button
                            disabled={source.total.loading || !isClickablePrevButton(source.term, source.termType)}
                            htmlType='button'
                            onClick={onClickPrevTerm}
                        >
                            {'<'}
                        </Button>
                    </Col>
                    <Col className='summary-title-text' span={20}>
                        <Text>{term} 利用履歴</Text>
                    </Col>
                    <Col className='summary-title-operator-right' span={2}>
                        <Button
                            disabled={source.total.loading || !isClickableNextButton(source.term, source.termType)}
                            htmlType='button'
                            onClick={onClickNextTerm}
                        >
                            {'>'}
                        </Button>
                    </Col>
                </Row>
                <Row className='summary-graph'>
                    <Graph data={data} loading={loading} mean={mean} />
                </Row>
            </div>
        </div>
    );
};

Summary.propTypes = {
    appName: PropTypes.string,
    current: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.exact({
        count: PropTypes.number,
        name: PropTypes.string,
    })),
    mean: PropTypes.number,
    term: PropTypes.string,
};

export default Summary;

const convertTermTypeToMomentTermType = (termType) => {
    switch (termType) {
        case 'year':
            return 'years';
        case 'month':
            return 'months';
        case 'week':
            return 'weeks';
        case 'date':
            return 'days';
        default:
            throw new Error('Invalid termType');
    }
};

const createPrevTerm = (term, termType) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    return moment(term).clone().subtract(1, momentTermType);
};

const createNextTerm = (term, termType) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    return moment(term).clone().add(1, momentTermType);
};

const isClickablePrevButton = (term, termType) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    const prevTerm = createPrevTerm(term, termType);
    const inf = moment('2020/1/1').subtract(1, momentTermType).startOf(momentTermType);
    return inf < prevTerm;
};

const isClickableNextButton = (term, termType) => {
    const momentTermType = convertTermTypeToMomentTermType(termType);
    const nextTerm = createNextTerm(term, termType);
    const sup = moment().add(1, momentTermType).startOf(momentTermType);
    return nextTerm < sup;
};

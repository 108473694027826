import * as React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin as AntdSpin, SpinProps } from 'antd';

type Props = Omit<SpinProps, 'indicator' | 'size'>

// このファイル以外から spin にアクセスさせないために,
// 単純にラップしただけのコンポーネントを用意する
/**
 * antd の Spin
 */
export const BaseSpin: React.FC<SpinProps> = props => {
    return (
        <AntdSpin
            {...props}
        />
    );
}

/**
 * デフォルトの indicator が設定されている size が default な Spin
 */
const Spin: React.FC<Props> = props => {
    return (
        <BaseSpin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            size='default'
            {...props}
        />
    );
}

export default Spin;

/**
 * デフォルトの indicator が設定されている size が large な Spin.
 *
 * ページ全体でのローディングなどに使用する.
 */
export const LargeSpin: React.FC<Props> = props => {
    return (
        <BaseSpin
            indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
            size='large'
            {...props}
        />
    );
}

/**
 * デフォルトの indicator が設定されている size が small な Spin
 */
export const SmallSpin: React.FC<Props> = props => {
    return (
        <BaseSpin
            indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
            size='small'
            {...props}
        />
    );
}

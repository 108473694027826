import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ImportOutlined, SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import { BACKGROUND_JOB_TYPE } from 'components/modules/Job/Background/BackgroundJobProvider';
import UploadCsvModal from 'components/modules/UploadCSVModal';
import { getCurrentTerm } from 'constants/GlobalConfig';
import * as Actions from 'flex/Actions';
import { selectors } from 'flex/Selectors';
import { useAppSelector } from 'flex/utils';

import { ListContext } from '../../index';
import { SearchRuleContext } from '.';


const AnnualUpdateCsvOperators = ({
    onClickAnnualUpdate,
    onSearch,
}) => {
    const [search,] = useContext(SearchRuleContext);
    const [list] = useContext(ListContext);
    const [isCsvUploadOpen, setIsCsvUploadOpen] = useState(false);
    const jobInfoMap = useAppSelector(selectors.jobInfoMap);
    const dispatch = useDispatch();

    const importDisabled = !!Object.values(jobInfoMap).find(jobInfo => jobInfo.job.type === BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_IMPORT);
    const exportDisabled = !!Object.values(jobInfoMap).find(jobInfo => jobInfo.job.type === BACKGROUND_JOB_TYPE.SCHOOL_CLASS_CSV_EXPORT);

    // 検索結果をCSVでダウンロードさせる
    const handleCsvDownload = (encoding) => {
        const fieldsValue = {
            encoding,
            ...search,
        };
        delete fieldsValue.page;

        dispatch(Actions.http.connection.classes.export(fieldsValue));
    };

    const handleCsvUploadSubmit = () => {
        setIsCsvUploadOpen(false);
        const currentTermUuid = getCurrentTerm(list.termList).uuid || '';
        onSearch({ term_uuid: currentTermUuid });
    };

    const handleCsvUploadOpen = () => setIsCsvUploadOpen(true);
    const handleCsvUploadCancel = () => setIsCsvUploadOpen(false);

    return (
        <>
            <div className='csv-operators flex-right-container gutter-bottom'>
                <Button
                    icon={<SyncOutlined />}
                    onClick={onClickAnnualUpdate}
                    type='primary'
                >
                    年次更新
                </Button>
                <Button
                    disabled={importDisabled}
                    icon={<ImportOutlined />}
                    onClick={handleCsvUploadOpen}
                    type='primary'
                >
                    CSVインポート
                </Button>
                <DownloadCSVConfirm
                    disabled={list.classListItemCount === 0 || exportDisabled}
                    handleOk={(encoding) => handleCsvDownload(encoding)}
                />
            </div>
            <UploadCsvModal
                isUserType={false}
                onCancel={handleCsvUploadCancel}
                onOk={handleCsvUploadSubmit}
                visible={isCsvUploadOpen}
            />
        </>
    );
};

export default AnnualUpdateCsvOperators;

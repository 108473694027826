import { ComponentProps } from 'react';
import * as React from 'react';
import { Tooltip } from 'antd';
import { EditOutlined, InfoCircleOutlined, InfoOutlined } from '@ant-design/icons';

type HasIsPublishedItem<T = Record<string, unknown>> = Expand<T & { is_published_item: boolean }>;
type HasIsCanAdminSetPublic<T = Record<string, unknown>> = Expand<T & { can_admin_set_public: boolean }>;
type HasIsPublishedItemCamel<T = Record<string, unknown>> = Expand<T & { isPublishedItem: boolean }>;
type HasIsCanAdminSetPublicCamel<T = Record<string, unknown>> = Expand<T & { canAdminSetPublic: boolean }>;

type Props = {
    record: HasIsCanAdminSetPublic<HasIsPublishedItem> | HasIsPublishedItemCamel<HasIsCanAdminSetPublicCamel>
    shouldCheckCanAdminSetPublic: boolean
}

/** 全体管理者に管理されているか */
const isControlled = (
    record: HasIsPublishedItem | HasIsPublishedItemCamel,
) => {
    return record?.is_published_item ?? record?.isPublishedItem;
};

/** 学校管理者が編集可能とされているか */
const isEditableByAdmin = (
    record: HasIsCanAdminSetPublic | HasIsCanAdminSetPublicCamel,
) => {
    return record?.can_admin_set_public ?? record?.canAdminSetPublic;
};

/** 学校管理者が編集可能か */
const isEditable = ({
    record,
    shouldCheckCanAdminSetPublic,
}: Props) => {
    return isControlled(record) ?
        shouldCheckCanAdminSetPublic ?
            isEditableByAdmin(record) :
            false :
        true;
};

export const ControlledIcon = (props: ComponentProps<typeof InfoCircleOutlined>) => {
    return <InfoCircleOutlined style={{ color: '#faad13' }} {...props}/>;
};

export const createPublishedItemButtonTooltipTitle = (
    record: Parameters<typeof isControlled>[0],
    defaultTitle = '削除'
) => {
    return isControlled(record) ?
        '全体管理者で管理されています' :
        defaultTitle;
};

export const PublishedItemTitleHint: React.VFC<{ record: Parameters<typeof isControlled>[0] }> = ({
    record
}) => {
    return isControlled(record) ?
        (
            <Tooltip title='全体管理者で管理されています'>
                <ControlledIcon />
            </Tooltip>
        ) :
        null;
};

export const createPublishedItemEditOrInfoButtonTooltipTitle = (
    record: Props['record'],
    shouldCheckCanAdminSetPublic: Props['shouldCheckCanAdminSetPublic'],
    defaultTitle = '編集'
) => {
    return isEditable({ record, shouldCheckCanAdminSetPublic }) ?
        defaultTitle :
        '全体管理者で管理されています';
};

export const EditOrInfoIcon: React.VFC<Props> = props => {
    return isEditable(props) ?
        <EditOutlined /> :
        <InfoOutlined />;
};
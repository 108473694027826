import {
    Button,
    Empty,
    Select,
} from 'antd';
import {
    DeleteOutlined,
} from '@ant-design/icons';
import { createAppSelectOptions } from '../../Common/utils';
import { BaseSpin } from 'components/modules/Spin';

const CenterSpin = () => (
    <div style={{textAlign: 'center'}}>
        <BaseSpin size='small' />
    </div>
);

const AppSelectWithoutGroup = (props) => {
    const { name, form, organizationUuid, organizationAppLists, onRemove } = props;
    const organizationAppInfo = organizationAppLists[organizationUuid] || {list: [], loading: false};

    const onChangeApp = (value) => {
        const fields = form.getFieldsValue();
        const { targetDetails } = fields;
        Object.assign(targetDetails[name], { app: value });
        form.setFieldsValue({ targetDetails });
    }

    return (
        <div className='search-target-detail'>
            <Select
                notFoundContent={
                    organizationAppInfo?.loading ? <CenterSpin /> :
                    organizationAppInfo.list.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={'アプリがありません'}/> :
                    undefined
                }
                placeholder='対象を選択してください'
                onChange={onChangeApp}
            >
                {createAppSelectOptions(organizationAppInfo.list)}
            </Select>
            <Button
                className="mr-2"
                shape="circle"
                size="small"
                danger
                icon={<DeleteOutlined/>}
                onClick={onRemove}
            />
        </div>
    );
}

export default AppSelectWithoutGroup;

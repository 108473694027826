import { useMemo } from 'react';
import * as React from 'react';

import { TableProps } from 'antd';

type Props = {
    hasPagination: boolean,
    showTopScroll: boolean,
    size: TableProps<unknown>['size'],
}

const TopScrollBar: React.VFC<Props> = ({
    hasPagination,
    showTopScroll,
    size,
}) => {
    const adjustSafariClassName = useMemo(() =>
        getBrowserType() === BrowserType.safari ? 'safari-top-scroll-wrapper' : '',
    []
    );

    if (!showTopScroll) return null;

    const topPx = hasPagination ?
        size === 'small' ?
            '56px' :
            size === 'middle' ?
                '56px' :
                size === 'large' ?
                    '63px' :
                    '-1000px' : // 通常ここは実行されない
        '-18px';

    return (
        <div
            className={`top-scroll-wrapper ${adjustSafariClassName}`}
            style={{
                left: '0px',
                overflowX: 'scroll',
                position: 'absolute',
                top: topPx,
                width: '100%',
            }}
        >
            <div className='top-scroll-content' style={{ height: '1px' }} />
        </div>
    );
};

const BrowserType = {
    chrome: 'chrome' as const,
    edge: 'edge' as const,
    else: 'else' as const,
    firefox: 'firefox' as const,
    ie: 'ie' as const,
    safari: 'safari' as const,
};

const getBrowserType = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
        return BrowserType.ie;
    } else if (userAgent.indexOf('edge') !== -1) {
        return BrowserType.edge;
    } else if (userAgent.indexOf('chrome') !== -1) {
        return BrowserType.chrome;
    } else if (userAgent.indexOf('safari') !== -1) {
        return BrowserType.safari;
    } else if (userAgent.indexOf('firefox') !== -1) {
        return BrowserType.firefox;
    } else {
        return BrowserType.else;
    }
};

export default TopScrollBar;
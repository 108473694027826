import { createContext, useEffect, useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils'
import { selectors } from 'flex/Selectors'
import { applicationUtils } from './ApplicationUtils'

/** アプリカテゴリ並び替え用のページサイズ  */
export const ORDER_APP_CATEGORIES_PAGE_SIZE = 500;

const createInitAppCategories = (loading, status, current, search) => ({
    loading: loading,
    status,
    data: {
        pageTotal: 0,
        current: current || 1, // 現在のページ
        pageSize: 20,
        itemCount: 0,
        items: [],
        search: search ? search : {
            title: undefined, publish_start_at: undefined, publish_end_at: undefined
        }
    },
});


const createInitAppCategoryMap = (appCategoryMap, uuid, loading, appCategory) => ({
    ...appCategoryMap,
    [uuid]: {
        loading,
        item: appCategory
    },
});

const createInitOrderAppCategories = (loading, status, current) => ({
    loading: loading,
    status,
    data: {
        pageTotal: 0,
        current: current || 1, // 現在のページ
        pageSize: 20,
        itemCount: 0,
        items: [],
    },
});

const createInitAppCategory = (loading, status) => ({
    loading: loading,
    status,
});

const createInitApplication = (loading, status) => ({
    loading: loading,
    status
});

const createInitAppTemplates = (loading, status, items = []) => ({
    loading: loading,
    status,
    data: { items }
});

const createInitFiles = (loading) => ({
    loading: loading,
    data: {
        items: [],
    }
});

export const createInitAppOrderEdit = (loading, status) => ({
    loading: loading,
    status,
});

export const createInitAppCategoryOrderEdit = (loading, status) => ({
    loading: loading,
    status,
    data: {
        message: '',
    }
});


const createInitState = () => ({
    appCategories: createInitAppCategories(false),
    appCategoryMap: createInitAppCategoryMap({}),
    orderAppCategories: createInitOrderAppCategories(false),
    appCategory: createInitAppCategory(false),
    application: createInitApplication(false),
    files: createInitFiles(false),
    appOrderEdit: createInitAppOrderEdit(false),
    appCategoryOrderEdit: createInitAppCategoryOrderEdit(false),
    appTemplatesForAdmin: createInitAppTemplates(false),
});

const SourceContext = createContext([
    createInitState(),
    () => { },
]);

// useSelector は基本的にこの中でしか使わず,
// Connection の監視(useEffectで使うこと)もこの中で行なう.
export const SourceProvider = ({ children }) => {
    const isControllableUser = useAppSelector(selectors.isControllableUser);
    const appCategoriesSearchConnection = useSelector(state => state.appsCategorySearchConnection);
    const appCategoriesEditConnection = useSelector(state => state.appsCategoryEditConnection);
    const appsEditConnection = useAppSelector(selectors.appsEditConnection);
    const appsCategoryOrderEditConnection = useSelector(state => state.appsCategoryOrderEditConnection);
    const appsOrderEditConnection = useSelector(state => state.appsOrderEditConnection);
    const appsCategoryViewConnection = useAppSelector(selectors.appsCategoryViewConnection);
    const appsTemplateSearchForAdminConnection = useSelector(state => state.appsTemplateSearchForAdminConnection);

    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const [source, setSource] = useState({
        ...createInitState(),
        isControllableUser,
    });

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appCategoriesSearchConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS:
                const result = appCategoriesSearchConnection.payload.result;
                setSourceWhenAppCategoriesSearchIsSuccess(source, setSource, status, result);
                break;
            case Actions.statusEnum.REQUEST: {
                const pageSize = appCategoriesSearchConnection.payload?.data?.page_size;
                setSourceWhenAppCategoriesSearchIsRequest(source, setSource, pageSize);
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                setSource({
                    ...source,
                    appCategories: createInitAppCategories(false, status, source.appCategories.data.current, source.appCategories.data.search),
                    orderAppCategories: createInitOrderAppCategories(false, status, source.appCategories.data.current),
                });
                break;
            default:
                break;
        }
    }, [appCategoriesSearchConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsCategoryViewConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                const mapKey = appsCategoryViewConnection.meta.mapKey
                const item = appsCategoryViewConnection.payload.result
                mapKey && item && setSource(source => ({
                    ...source,
                    appCategoryMap: createInitAppCategoryMap(
                        source.appCategoryMap,
                        mapKey,
                        false,
                        applicationUtils.convertResponseToApplicationCategory(item)
                    ),
                }));
                break;
            }
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                const mapKey = appsCategoryViewConnection.meta.mapKey
                mapKey && setSource(source => ({
                    ...source, status,
                    appCategoryMap: createInitAppCategoryMap(source.appCategoryMap, mapKey, true),
                }));
                break;
            default:
                break;
        }
    }, [appsCategoryViewConnection]);

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appCategoriesEditConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                showSuccessToastMessage(appCategoriesEditConnection)
                setSource({
                    ...source,
                    appCategory: createInitAppCategory(false, status),
                });
                dispatchAppsCategorySearch();
                break;
            }
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                setSource({
                    ...source, status,
                    appCategory: createInitAppCategory(status === Actions.statusEnum.REQUEST, status),
                });
                break;
            default:
                break;
        }

    }, [appCategoriesEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsEditConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS: {
                showSuccessToastMessage(appsEditConnection)
                setSource({
                    ...source,
                    application: createInitApplication(false, status),
                });
                const uuid = appsEditConnection.meta.mapKey
                dispatch(Actions.http.connection.apps.category.controlView(uuid, uuid))
                break;
            }
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
                setSource({
                    ...source,
                    application: createInitApplication(status === Actions.statusEnum.REQUEST, status),
                });
                break
            default:
                break;
        }
    }, [appsEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsTemplateSearchForAdminConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS:
                setSource(prevState => ({
                    ...prevState,
                    appTemplatesForAdmin: createInitAppTemplates(
                        false,
                        Actions.statusEnum.SUCCESS,
                        appsTemplateSearchForAdminConnection.payload.result.items
                    ),
                }));
                break;
            default:
                setSource(prevState => ({
                    ...prevState,
                    appTemplatesForAdmin: createInitAppTemplates(status === Actions.statusEnum.REQUEST, status),
                }));
                break;
        }
    }, [appsTemplateSearchForAdminConnection])

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsCategoryOrderEditConnection.meta.status
        switch (status) {
            case Actions.statusEnum.SUCCESS:
                const result = appsCategoryOrderEditConnection.payload.result;
                message.success(result.message);
                setSource({
                    ...source,
                    appCategoryOrderEdit: {
                        loading: false,
                        status,
                        data: {
                            message: result.message,
                        },
                    },
                });
                dispatchAppsCategorySearch();
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.REQUEST:
                setSource({
                    ...source,
                    appCategoryOrderEdit: createInitAppCategoryOrderEdit(status === Actions.statusEnum.REQUEST, status),
                });
                break;
            default:
                break;
        }
    }, [appsCategoryOrderEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return;
        const status = appsOrderEditConnection.meta.status
        const categoryUuid = appsOrderEditConnection.meta.mapKey
        switch (status) {
            case Actions.statusEnum.SUCCESS:
                const result = appsOrderEditConnection.payload.result;
                message.success('教材アプリの並び順を更新しました。');
                setSourceWhenOrderingAppIsSuccess(source, setSource, status, result);
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR:
            case Actions.statusEnum.REQUEST:
                setSource({
                    ...source,
                    appOrderEdit: createInitAppOrderEdit(status === Actions.statusEnum.REQUEST, status),
                    appCategoryMap: createInitAppCategoryMap(source.appCategoryMap, categoryUuid, status === Actions.statusEnum.REQUEST)
                });
                break;
            default:
                break;
        }
    }, [appsOrderEditConnection]);// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return
        if (source.appCategories.data.current !== 1) { // ページを戻してcurrentを監視しているuseEffectで検索実行
            setSource({
                ...source,
                appCategories: {
                    ...source.appCategories,
                    data: {
                        ...source.appCategories.data,
                        current: 1
                    }
                }
            })
        } else {
            dispatchAppsCategorySearch();
        }
    }, [source.appCategories.data.search])// eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) return
        dispatchAppsCategorySearch();
    }, [source.appCategories.data.current])// eslint-disable-line


    useEffect(() => {// 初回実行
        isFirstRender.current = false
        dispatchAppsCategorySearch();
    }, []);// eslint-disable-line

    const dispatchAppsCategorySearch = () =>
        dispatchAppCategoryControlSearch(dispatch, source);

    return (
        <SourceContext.Provider value={[source, setSource]}>
            {children}
        </SourceContext.Provider>
    );
}

export const useSource = () => useContext(SourceContext);

/** connectionのtypeごとに成功メッセージのトーストを表示する  */
const showSuccessToastMessage = (connection) => {
    const result = connection.payload.result;

    switch (connection.type) {
        /** アプリカテゴリ  */
        case Actions.http.connection.apps.category.controlDelete().type:
            message.success(result.message);
            break;
        case Actions.http.connection.apps.category.controlUpdate().type:
            message.success('教材・アプリグループ情報を編集しました')
            break;
        case Actions.http.connection.apps.category.controlCreate().type:
            message.success('教材・アプリグループを作成しました');
            break;
        /** 教材アプリ  */
        case Actions.http.connection.apps.controlDelete().type:
            message.success(result.message);
            break;
        case Actions.http.connection.apps.controlUpdate().type:
            message.success('教材・アプリ情報を編集しました');
            break;
        case Actions.http.connection.apps.controlCreate().type:
            message.success('教材・アプリを作成しました');
            break;
        default:
            break;
    }
}

/**
 * アプリ並び替え成功時にsourceをセットする
 * 並び替え対象のアプリカテゴリにresultの並び替え済みのカテゴリを入れ込むことで、カテゴリをサーバーから再取得しなくてすむ
 **/
const setSourceWhenOrderingAppIsSuccess = (source, setSource, status, result) => {
    const categoryPosition = source.appCategories.data.items.findIndex(item => item.uuid === result.uuid);
    const newItems = source.appCategories.data.items.slice();

    newItems.splice(categoryPosition, 1, applicationUtils.convertResponseToApplicationCategory(result));

    setSource({
        ...source,
        appOrderEdit: {
            loading: false,
            status
        },
        appCategoryMap: createInitAppCategoryMap(source.appCategoryMap, result.uuid, false, applicationUtils.convertResponseToApplicationCategory(result))
    });
}

/***
 * AppCategoriesSearchConnectionが成功時呼び出す。
 * page_sizeをみて、orderAppCategoriesかappCategoriesにデータをセットするか判断する。
 ***/
const setSourceWhenAppCategoriesSearchIsSuccess = (source, setSource, status, result) => {
    const newData = {
        pageTotal: result.page_total,
        pageSize: result.page_size,
        current: source.appCategories.data.current,
        itemCount: result.item_count,
        items: result.items.map(appGroup => {
            return applicationUtils.convertResponseToApplicationCategory(appGroup)
        }),
        search: source.appCategories.data.search
    };

    if (result.page_size === ORDER_APP_CATEGORIES_PAGE_SIZE) {
        setSource({
            ...source,
            orderAppCategories: { ...source.orderAppCategories, loading: false, status, data: newData }
        });
    } else if (typeof result.page_size === 'number') {
        setSource({
            ...source,
            appCategories: { ...source.appCategories, loading: false, status, data: newData }
        });
    }
}

/***
 * AppCategoriesSearchConnectionがRequest時呼び出す。
 * page_sizeをみて、orderAppCategoriesかappCategoriesにデータをセットするか判断する。
 ***/
const setSourceWhenAppCategoriesSearchIsRequest = (source, setSource, pageSize) => {
    if (pageSize === ORDER_APP_CATEGORIES_PAGE_SIZE) {
        setSource({
            ...source,
            orderAppCategories: createInitOrderAppCategories(true, Actions.statusEnum.REQUEST, source.appCategories.data.current),
        });
    } else {
        setSource({
            ...source,
            appCategories: createInitAppCategories(true, Actions.statusEnum.REQUEST, source.appCategories.data.current, source.appCategories.data.search),
        });
    }
};


export function dispatchAppCategoryControlSearch(dispatch, source) {
    const page_size = source.appCategories.data.pageSize;
    const page = source.appCategories.data.current
    dispatch(Actions.http.connection.apps.category.controlSearch({ ...source.appCategories.data.search, page_size }, page));
};

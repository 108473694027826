import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'flex/Actions';
import { useAppSelector } from 'flex/utils';
import { toClassListMapKey } from 'constants/GlobalConfig';

type ClassListInfo = {
    items: SchoolClass[],
    totalAmount: number,
}

type ClassListMapResult = {
    isLoading: boolean,
    data: Record<string, ClassListInfo>,
}

const INIT_STATE: ClassListMapResult = {
    isLoading: false,
    data: {},
};

export const useClassListMap = () => {
    const [classListMap, setClassListMap] = useState<ClassListMapResult>({ ...INIT_STATE });
    const connection = useAppSelector(state => state.classSearchConnection);
    const dispatch = useDispatch();

    const search = useCallback(({
        organizationUuid = undefined,
        page = 1,
        pageSize = 10,
        termUuid = '',
    }: {
        organizationUuid?: string
        page?: number
        pageSize?: number
        termUuid?: string
    }) => {
        if (!!classListMap.data[termUuid]) return;

        dispatch(Actions.http.connection.classes.searchForListMap(
            { organization_uuid: organizationUuid, page_size: pageSize, term_uuid: termUuid, },
            page,
            toClassListMapKey(termUuid)
        ));

        setClassListMap(state => ({ ...state, isLoading: true }));
    }, [classListMap, dispatch]);

    /** hook の状態をすべてリセットする */
    const reset = useCallback(() => setClassListMap({ ...INIT_STATE }), []);

    useEffect(() => {
        const {
            meta: { status, mapKey},
            payload: { result: payloadResult },
        } = connection;

        switch (status) {
            case Actions.statusEnum.REQUEST:
            case Actions.statusEnum.LOADING: {
                break;
            }
            case Actions.statusEnum.SUCCESS: {
                if (!classListMap.isLoading) break;
                if (mapKey === undefined) break;

                setClassListMap(classListMap => ({
                    isLoading: false,
                    data: {
                        ...classListMap.data,
                        [mapKey]: {
                            items: payloadResult.items,
                            totalAmount: payloadResult.item_count,
                        },
                    },
                }));
                break;
            }
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setClassListMap({ ...INIT_STATE });
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = status;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    return [classListMap, search, reset] as const;
};

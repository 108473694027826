import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BarChartOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { useReactToPrint } from 'react-to-print';

import DownloadCSVConfirm from 'components/modules/DownloadCSVConfirm';
import * as Actions from 'flex/Actions';

import { useUsageData } from '../Common/UsageDataProvider';
import { createActionLogTotalQuery, createSummaryProps } from '../Common/utils';
import DetailHistory from './DetailHistory';
import PdfViewModal from './PdfViewModal';
import Search from './Search';
import Summary from './Summary';
import TotalTable from './TotalTable';


const UserContents = () => {
    const [source] = useUsageData();
    const [isCopyLayout, setIsCopyLayout] = useState(false);
    const [executedSearch, setExecutedSearch] = useState(false);
    const printTargetRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printTargetRef.current,
        onAfterPrint: () => { setIsCopyLayout(false); },
    });
    const dispatch = useDispatch();

    const summaryProps = createSummaryProps(source);

    // 検索結果をCSVでダウンロードさせる
    const handleCsvDownload = (encoding, { startMonth, endMonth }) => {
        const queries = createActionLogTotalQuery({
            hierarchy: source.hierarchy,
            source,
        });
        let fieldsValue = {};
        if (startMonth && endMonth) {
            fieldsValue = {
                ...queries,
                encoding: encoding,
                end_at: endMonth,
                start_at: startMonth,
            };
        } else {
            fieldsValue = {
                ...queries,
                encoding: encoding,
            };
        }
        dispatch(Actions.http.connection.usage.export.total(fieldsValue));
    };

    const handlePdfExport = () => {
        setIsCopyLayout(true);
        // コピーレイアウトに変更後、ReChartsのグラフが変更されるのを待機
        setTimeout(() => handlePrint(), 3000);
    };

    return (
        <div className='usage'>
            <div className='user-contents' >
                <Search
                    loading={source.loading}
                    onSearch={() => setExecutedSearch(true)}
                />

                {
                    !executedSearch ?
                        <InitialContents /> :
                        (
                            <>
                                <div className='operator'>
                                    <DownloadCSVConfirm
                                        disabled={source.total.data.length === 0 || source.disabledCsvExport}
                                        disabledMessage={<p>CSVエクスポートはすでに実行済みです。<br />CSVのダウンロード状況については、画面左下からご確認ください</p>}
                                        handleOk={(encoding, options) => handleCsvDownload(encoding, options)}
                                        loading={source.loadingCsvExport}
                                        term={summaryProps.term}
                                    />
                                    <Popover content='印刷設定画面が表示されましたら、送信先を「PDFに保存」を選択して、「保存」ボタンをクリックしてください' placement='topRight'>
                                        <Button
                                            disabled={isCopyLayout || source.loading}
                                            loading={isCopyLayout || source.loading}
                                            onClick={handlePdfExport}
                                            type='primary'
                                        >
                                            PDFエクスポート
                                        </Button>
                                    </Popover>
                                </div>
                                <Summary
                                    {...summaryProps}
                                    isCopyLayout={isCopyLayout}
                                />

                                <TotalTable />
                                {
                                    (source.isControllableUser && source.hierarchy.length === 4) ||
                                    (!source.isControllableUser && source.hierarchy.length === 3) ?
                                        <DetailHistory /> :
                                        <></>
                                }
                            </>
                        )
                }
            </div>

            <PdfViewModal
                {...summaryProps}
                ref={printTargetRef}
                visible={isCopyLayout}
            />
        </div>
    );
};

export default UserContents;


const InitialContents = () => {
    return (
        <div className='initial-contents'>
            <BarChartOutlined style={{ fontSize: '64px' }} />
            <span>条件を絞り込むと結果が表示されます。</span>
        </div>
    );
};
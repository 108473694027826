import { createSelector } from 'reselect'
import {selectors} from "../../../Selectors";


// 以下: EditUserモーダル

const editUserLoadingSelector = createSelector(
	[selectors.classSearchConnection, selectors.roleSearchConnection, selectors.termSearchConnection, selectors.usersEditConnection],
	(classSearchConnection,roleSearchConnection,termSearchConnection,usersEditConnection ) =>
		classSearchConnection.meta.fetch || roleSearchConnection.meta.fetch || termSearchConnection.meta.fetch || usersEditConnection.meta.fetch
)

export const editUserSelectors = {
	visible: createSelector(
		[selectors.isControllableUser, selectors.userManagementTabEditUser, selectors.editUserIsLocal],
		(isControllableUser, editUserInUserManagementTab, isLocal) => ({
			passwordInputVisible: editUserInUserManagementTab.shouldCreate || isLocal,
			organizationSelectVisible: isControllableUser
		})
	),
	message: createSelector(
		[selectors.userManagementTabEditUser, selectors.editUserIsLocal],
		(editUserInUserManagementTab, isLocal) => ({
			passwordInputPlaceholder: editUserInUserManagementTab.shouldCreate ? "パスワードを入力してください" : isLocal ? "変更が必要な時のみ入力してください" : "SSOユーザーはパスワードリセットボタンから変更してください"
		})
	),
	loading: editUserLoadingSelector,
	roleList: createSelector(
		[selectors.isControllableUser, selectors.editUserSelectOrganization, selectors.editUserRoleList],
		(isControllableUser, selectOrganization,  rowRoleList)=>{
			if(!rowRoleList || rowRoleList.length === 0 ) return []
			if (selectOrganization && isControllableUser) {
				return (rowRoleList.filter((roleV) => roleV.organization.uuid === selectOrganization))
			} else {
				return rowRoleList;
			}
		}
	),
	classListMap:  createSelector(
		[selectors.isControllableUser, selectors.editUserSelectOrganization, selectors.editUserClassListMap],
		(isControllableUser, selectOrganization,  rowClassListMap)=>{
			if(!rowClassListMap || rowClassListMap.length === 0 ) return []
			if (selectOrganization && isControllableUser) {
				const newFilteredClassListMap = {}
				for (const [key, val] of Object.entries(rowClassListMap)) {
					newFilteredClassListMap[key] = val.filter((classV) => classV.organization.uuid === selectOrganization);
				}
				return newFilteredClassListMap
			} else {
				return rowClassListMap
			}
		}
	),
	disabled: createSelector(
		[selectors.isControllableUser, selectors.editUserSelectOrganization, editUserLoadingSelector, selectors.userManagementTabEditUser],
		(isControllable, selectOrganization, loading, editUser) => (
			{
				loginIdInputDisabled: !editUser.shouldCreate,
				termSelectDisabled: (!selectOrganization && isControllable) || loading,
				classSelectDisabled: (!selectOrganization && isControllable) || loading,
				numberInputDisabled: !selectOrganization && isControllable,
				roleSelectDisabled:  (!selectOrganization && isControllable) || loading,
				addBelongsButtonDisabled:  (!selectOrganization && isControllable) || loading,
			}
		)
	)
}

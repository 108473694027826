import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { ReactComponent as DownloadSVG } from 'assets/icon/ic_download.svg';
import Ruby from '../Ruby'

// forwardRef などを使うと react-dropzone がうまく動かなくなるので
// useFormContext を経由して register などを取得するようにしている
const FileInput = ({
    accept,
    className = '',
    maxFileSize,
    name = '',
    onAccept = () => { },
    onReject = () => { },
}) => {
    const { setError } = useFormContext();

    const validator = useCallback(file => {
        const fileSizeError = fileSizeValidator(maxFileSize)(file)
        if (fileSizeError !== undefined) return fileSizeError;
    }, [accept, maxFileSize, name  /*,setError*/]);// eslint-disable-line

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: accept,
        maxFiles: 1,
        validator,
    });

    useEffect(() => {
        if (fileRejections.length === 0) return;
        const message = createErrorMessage(fileRejections);
        setError(name, { message: message });
    }, [fileRejections, name]);// eslint-disable-line

    useEffect(() => {
        if (acceptedFiles.length === 0) return;
        onAccept(acceptedFiles)
    }, [acceptedFiles]);// eslint-disable-line

    useEffect(() => {
        if (fileRejections.length === 0) return;
        onReject(fileRejections)
    }, [fileRejections]);// eslint-disable-line

    return (
        <section className={classNames(className,
            'container p-4 border-2 border-dashed border-steel-600 rounded-md text-center font-bold',
            fileRejections.length === 0 && 'bg-white',
            fileRejections.length > 0 && 'bg-red-300 bg-opacity-25',
        )}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />

                <DownloadSVG className='h-20 w-20 ml-auto mr-auto fill-current text-steel-600' />

                <div className='text-steel-600'>
                    <p><Ruby>{'ファイルをドラッグ＆ドロップするか、ここをクリックして{選択|せんたく}してください'}</Ruby></p>
                    <p><Ruby>{'サイズ{制限|せいげん} : {最大|さいだい}50MBまで'}</Ruby></p>
                    <p><Ruby>{'{対応形式|たいおうけいしき} : [{画像|がぞう}(jpg/png),{文書|ぶんしょう}(pdf),{動画|どうが}/{音声|おんせい}(mp4),{圧縮|あっしゅく}ファイル(zip),テキスト(txt),オフィスドキュメント(pptx,xlsx,docx)]'}</Ruby></p>
                </div>
                {
                    acceptedFiles.length > 0 && (
                        <div className='text-steel-600 mt-2'>
                            <p><Ruby>{'{入力|にゅうりょく}ファイル{名|めい}'}</Ruby>: {acceptedFiles[0].name}</p>
                        </div>
                    )
                }
            </div>
        </section>
    );
};
export default FileInput;

const fileSizeValidator = (maxFileSize) => (file) => {
    if (file.size > maxFileSize) {
        return {
            code: 'file-size-too-large',
            message: 'ファイルサイズが大きすぎます。'
        };
    }

    return undefined;
};

const createErrorMessage = (fileRejections) => {
    if (fileRejections.length === 0) return undefined;

    // 拡張子の判断を dropzone の判断を流用し対応.
    if (fileRejections[0].errors.some(e => e.code === 'file-invalid-type')) {
        const ext = fileRejections[0].file.name.replace(/^.+\./, '').toLowerCase();
        return <Ruby>{`{拡張子|かくちょうし}が '${ext}' のファイルは{添付|てんぷ}できません。`}</Ruby>;
    }

    return `${fileRejections[0].file.name} は ${fileRejections[0].errors[0].message}`
};
import * as React from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';

import BaseForm from 'components/modules/BaseForm';

import { FIVE_LEVEL_CONTENTS } from '../../../utils/ResultAnswerUtils';
const Form = BaseForm;
const { Option } = Select;

type Props = {
    items: EverydayNote.Question[]
    loading: boolean
    name: string | number
    // TODO: 一時的対応
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void
    onRemove: () => void
    value?: { question: string, candidate: string }
};

const ConditionFormItem: React.VFC<Props> = ({
    items,
    loading,
    name,
    onChange = () => undefined,
    onRemove,
    value = undefined,
}) => {
    const form = Form.useFormInstance<EverydayNote.AnswerResultModal.FormValue>();

    const selectedQuestion = items.find(item => item.uuid === value?.question);
    const type = selectedQuestion?.type;
    const scale = selectedQuestion?.evaluationScaleMax ?? undefined;

    const conditions = Form.useWatch('conditions', form) ?? [];
    const selectedQuestionUuids = conditions.map(condition => condition.question);
    const filteredItems = items.filter(item => {
        return !selectedQuestionUuids.includes(item.uuid) || item.uuid === value?.question;
    });

    return (
        <Row gutter={[16, 12]} >
            <Col span={22}>
                <QuestionSelectFormItem
                    items={filteredItems}
                    loading={loading}
                    name={name}
                    onChange={onChange}
                    scale={scale}
                    type={type}
                    value={value}
                />
            </Col>

            <Col span={2}>
                <Button onClick={onRemove} type='text'>
                    <MinusCircleOutlined style={{ fontSize: '24px' }} />
                </Button>
            </Col>
        </Row>
    );
};

export default ConditionFormItem;


type QuestionSelectFormItemProps = {
    name: string | number
    loading: boolean
    items: EverydayNote.Question[]
    // TODO: 一時的対応
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void
    value?: { question: string, candidate: string }
    type: EverydayNote.QuestionTypes | undefined
    scale?: number
}

const QuestionSelectFormItem: React.VFC<QuestionSelectFormItemProps> = ({
    type,
    scale = 0,
    ...restProps
}) => {

    switch (type) {
        case 1:
        case 2:
        case 5:
        case 7:
            return <ChoiceFormItem {...restProps} />;
        case 3:
            return <FreeDescriptionFormItem {...restProps} />;
        case 4:
            return <FiveLevelFormItem {...restProps} />;
        case 6:
            return <StartFormItem {...restProps} scale={scale} />;
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: undefined = type;
            return <DefaultFormItem {...restProps} />;
        }
    }
};

const ChoiceFormItem: React.VFC<Omit<QuestionSelectFormItemProps, 'type'>> = props => {
    const {
        name,
        loading,
        items,
        value = undefined,
    } = props;
    const candidates = items.find(item => item.uuid === value?.question)?.candidates ?? [];

    return (
        <Row gutter={[16, 12]}>
            <Col span={12}>
                <DefaultFormItem {...props} />
            </Col>

            <Col span={12}>
                <Form.Item
                    name={[name, 'candidate']}
                >
                    <Select
                        disabled={loading}
                        loading={loading}
                        placeholder='選択肢を選択してください'
                        value={value?.candidate}
                    >
                        {candidates.map(candidate => {
                            return (
                                <Option key={candidate.uuid} value={candidate.uuid}>
                                    {candidate.content}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
};

const FreeDescriptionFormItem: React.VFC<Omit<QuestionSelectFormItemProps, 'type'>> = props => {
    const {
        name,
        loading,
    } = props;

    return (
        <Row gutter={[16, 12]}>
            <Col span={12}>
                <DefaultFormItem {...props} />
            </Col>

            <Col span={12}>
                <Form.Item
                    name={[name, 'candidate']}
                >
                    <Input
                        disabled={loading}
                        placeholder='内容を入力してください'
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};

const FiveLevelFormItem: React.VFC<Omit<QuestionSelectFormItemProps, 'type'>> = props => {
    const {
        name,
        loading,
    } = props;

    return (
        <Row gutter={[16, 12]}>
            <Col span={12}>
                <DefaultFormItem {...props} />
            </Col>

            <Col span={12}>
                <Form.Item
                    name={[name, 'candidate']}
                >
                    <Select
                        disabled={loading}
                        loading={loading}
                        placeholder='選択肢を選択してください'
                    >
                        {FIVE_LEVEL_CONTENTS.map((content, i) => (
                            <Option key={i} value={5 - i}>
                                {content}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
};

const StartFormItem: React.VFC<Omit<QuestionSelectFormItemProps, 'type'> & { scale: number }> = ({
    scale,
    ...props
}) => {
    const {
        name,
        loading,
    } = props;

    return (
        <Row gutter={[16, 12]}>
            <Col span={12}>
                <DefaultFormItem {...props} />
            </Col>

            <Col span={12}>
                <Form.Item
                    name={[name, 'candidate']}
                >
                    <Select
                        disabled={loading}
                        loading={loading}
                        placeholder='選択肢を選択してください'
                    >
                        {[...Array(scale)].map((_, i) => (
                            <Option key={i + 1} value={i + 1}>
                                {i + 1}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
};

const DefaultFormItem: React.VFC<Omit<QuestionSelectFormItemProps, 'type'>> = ({
    name,
    loading,
    items,
    onChange = () => undefined,
    value = undefined,
}) => {
    return (
        <Form.Item
            name={[name, 'question']}
        >
            <Select
                disabled={loading}
                loading={loading}
                onChange={(value) => onChange({ question: value })}
                placeholder='設問を選択してください'
                value={value?.question}
            >
                {items.map(item => (
                    <Option key={item.uuid} value={item.uuid}>
                        {item.content}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import * as Actions from 'flex/Actions';
import {useAppSelector} from 'flex/utils';
import {TabKeys} from 'flex/view/Assessment/examCategoryTabFlex';


export type SaveAllReportState = {
    isLoading: boolean
};

type Connection = TaoExamCategorySaveReportConnection | TaoExamCategoryControlSaveReportConnection;

export const useSaveAllReport = (tab: TabKeys | '') => {
    const [state, setState] = useState<SaveAllReportState>({
        isLoading: false,
    });

    const isControllableUser = useAppSelector(state => state.isControllableUser.payload);
    const taoExamCategorySaveReportConnection = useAppSelector(state => state.taoExamCategorySaveReportConnection);
    const taoExamCategoryControlSaveReportConnection = useAppSelector(state => state.taoExamCategoryControlSaveReportConnection);
    const dispatch = useDispatch();

    const effect = useCallback((connection: Connection, state: SaveAllReportState) => {
        const {meta} = connection;

        // 通信中でない === 画面遷移など意図しないタイミング
        if (!state.isLoading) return;

        switch (meta.status) {
            case Actions.statusEnum.SUCCESS:
                setState(state => ({
                    ...state,
                    isLoading: meta.fetch,
                }));
                break;
            case Actions.statusEnum.FAILURE:
            case Actions.statusEnum.ERROR: {
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
                break;
            }
            default:
                setState(state => ({...state, status: meta.status}));
                break;
        }
    }, []);


    useEffect(() => {
        effect(taoExamCategorySaveReportConnection, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, taoExamCategorySaveReportConnection]);

    useEffect(() => {
        effect(taoExamCategoryControlSaveReportConnection, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, taoExamCategoryControlSaveReportConnection]);


    const request = useCallback((
        uuid: string,
        examUuids: string[],
        groupName: string,
    ) => {
        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const saveAllReportAction = isControllableUser ?
            Actions.http.connection.tao.examCategory.controlSaveReport :
            tab === 'board' ?
                Actions.http.connection.tao.examCategory.controlSaveReport :
                Actions.http.connection.tao.examCategory.saveReport;

        dispatch(saveAllReportAction(uuid, { exam_uuids: examUuids }, groupName));
    }, [dispatch, isControllableUser, tab]);

    const resetState = useCallback(() => {
        setState({
            isLoading: false,
        });
    }, []);

    return [state, request, resetState] as const;
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Col,
    Row,
} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
    SortAscendingOutlined,
} from '@ant-design/icons';
import * as Actions from 'flex/Actions';
import { generateFileDomain, generateLtiLaunchDomain } from 'constants/GlobalConfig';
import { useHasTouchScreen } from 'constants/CustomHooks/useHasTouchScreen';
import { BaseTable } from 'components/modules/BaseTable';
import CircleButton from 'components/modules/CircleButton';
import AppModal from './Modals/AppModal';
import AppsOrderModal from './Modals/AppsOrderModal';


const AppsList = ({
    appCategory,
    loading,
    onClickDeepLinkingCreate
}) => {
    const [isOpenAppModal, setIsOpenAppModal] = useState(false);
    const [appModalInitValue, setAppModalInitValue] = useState(undefined);
    const [isOpenAppOrderModal, setIsOpenAppOrderModal] = useState(false);
    const [selectedAppCategoryUuid, setSelectedAppCategoryUuid] = useState(null);
    const dispatch = useDispatch();
    //タッチ機能のあるデバイスかどうか
    const { hasTouchScreen } = useHasTouchScreen();

    const handleCreateClick = () => {
        if (appCategory.useDeepLinking) {
            window.open(
                `${generateLtiLaunchDomain(true, true)}/init-app-deep-linking/${appCategory.uuid}`,
                '_blank'
            );
            onClickDeepLinkingCreate(appCategory.uuid);
        } else {
            setAppModalInitValue(undefined);
            setIsOpenAppModal(true);
        }
    };

    const handleEditClick = (record) => {
        setIsOpenAppModal(true);
        setAppModalInitValue(record);
    };

    const handleEditSubmitted = () => {
        setIsOpenAppModal(false);
        setAppModalInitValue(undefined);
    };

    const handleEditCancel = () => {
        setIsOpenAppModal(false);
        setAppModalInitValue(undefined);
    };

    const handleDeleteClick = (uuid) => {
        dispatch(Actions.http.connection.apps.controlDelete(uuid, appCategory.uuid));
    };

    const handleEditAppOrderClick = (appCategoryUuid) => {
        setIsOpenAppOrderModal(true);
        setSelectedAppCategoryUuid(appCategoryUuid);
    };

    const handleEditOrderSubmitted = () => setIsOpenAppOrderModal(false);
    const handleEditOrderCancel = () => setIsOpenAppOrderModal(false);

    const columns = [
        { title: 'タイトル', dataIndex: 'title', key: 'title' },
        { title: '概要', dataIndex: 'description', key: 'description' },
        {
            title: 'アプリサムネイル',
            key: 'files',
            dataIndex: 'files',
            render: (row) => {
                return row.map((value, index) => <img key={index} className={'application_logo_img'}
                    alt={'application_logo_img'}
                    src={`${generateFileDomain()}/file/view/${value.uuid}`} />)
            }
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            width: 120,
            fixed: 'right',
            render: (_, record, __) => (
                <Row type='flex' justify='space-around' align='middle'>
                    <Col>
                        <CircleButton
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(record)}
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '編集' }}
                        />
                    </Col>
                    <Col>
                        <CircleButton
                            danger
                            icon={<DeleteOutlined />}
                            popconfirmProps={{
                                cancelText: 'キャンセル',
                                okText: '削除',
                                onConfirm: () => handleDeleteClick(record.uuid),
                                placement: 'topRight',
                                title: <span style={{ fontWeight: 'bold' }}>削除すると復元することはできません。</span>
                            }}
                            size='small'
                            tooltipProps={{ title: hasTouchScreen ? undefined : '削除' }}
                        />
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <div>
            <div className='flex-right-container gutter-bottom'>
                <Button
                    className='mb-4'
                    disabled={loading}
                    icon={<SortAscendingOutlined />}
                    loading={loading}
                    onClick={() => handleEditAppOrderClick(appCategory.uuid)}
                >
                    並び替え
                </Button>
                <Button
                    type='primary'
                    disabled={loading}
                    icon={<PlusCircleFilled />}
                    loading={loading}
                    onClick={handleCreateClick}
                >
                    教材・アプリを追加
                </Button>
            </div>
            <BaseTable size='large'
                columns={columns}
                dataSource={appCategory.applications}
                emptyDescription='配信されている教材・アプリはありません'
                loading={loading}
                pagination={false}
                showTopScroll={false}
                scroll={{ x: 1000 }}
            />
            <AppModal
                app={appModalInitValue}
                categoryUuid={appCategory.uuid}
                isUseDeepLinking={appCategory.useDeepLinking}
                onOk={handleEditSubmitted}
                onCancel={handleEditCancel}
                order={appCategory.applications.length + 1}
                visible={isOpenAppModal}
            />
            <AppsOrderModal
                categoryUuid={selectedAppCategoryUuid}
                onOk={handleEditOrderSubmitted}
                onCancel={handleEditOrderCancel}
                visible={isOpenAppOrderModal}
            />
        </div>
    );
}

export default AppsList;
